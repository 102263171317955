import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import { Header, Divider } from 'semantic-ui-react';

import SelectBar from '../../components/AccountSettings/SelectBar';
import Plans from '../../components/AccountSettings/Plans/Plans';
import Account from '../../components/AccountSettings/Account/Account';
import Billing from '../../components/AccountSettings/Billing/Billing';
import Users from '../../components/AccountSettings/Users/Users';
import Payments from '../../components/AccountSettings/Payments/Payments';
import Domain from '../../components/AccountSettings/Domain/Domain';
import { getFeatures, getPlanFeatures } from '../../utils/plans';
import { authActions, planActions } from '../../actions';
import { featuresKeys } from '../../common/plans';

const { logout } = authActions;
const { getSession } = planActions;

const AccountSettings = ({
  history,
  session,
  getSessionA,
  logoutA,
  userRole,
  features,
}) => {
  const [selectedTab, setSelectedTab] = useState(window.location.hash.substring(1));

  useEffect(() => {
    if (isEmpty(session)) {
      getSessionA();
    }

    if (history) {
      history.listen(hashHandler);
    }
  }, []);

  const hashHandler = (e) => {
    if (e.pathname === '/account-settings') {
      setSelectedTab(e.hash.substring(1));
      // could be refactored in future
    }
  };

  const handleTabChange = (tab) => {
    const { push } = history;
    push(`/account-settings#${tab}`);
  };

  return (
    <div className="ris-container ris-container__account-settings centered row">
      <Header as="h1" className="capitalize analytics">
        {selectedTab || 'plans'}
        <button
          type="button"
          onClick={() => logoutA()}
          className="logout-button ui button blue"
        >
          Log out
        </button>
      </Header>
      <SelectBar
        selectedTab={selectedTab}
        features={features}
        handleTabChange={handleTabChange}
        userRole={userRole}
      />
      <Divider className="mgb-0" />
      {(selectedTab === 'plans' || selectedTab === '') && <Plans />}
      {selectedTab === 'account' && <Account />}
      {selectedTab === 'billing' && <Billing />}
      {selectedTab === 'users' && <Users />}
      {selectedTab === 'payments' && <Payments />}
      {((selectedTab === 'domain') && getFeatures(featuresKeys.featureKeys.customDomain, features)) && <Domain />}
    </div>
  );
};

AccountSettings.propTypes = {
  history: PropTypes.object,
  logoutA: PropTypes.func,
  userRole: PropTypes.string,
  getSessionA: PropTypes.func,
  session: PropTypes.object,
  features: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userRole: state.user.role,
  session: state.plans.session,
  features: getPlanFeatures(state.plans.session),
});

const mapDispatchToProps = (dispatch) => ({
  logoutA: bindActionCreators(logout, dispatch),
  getSessionA: bindActionCreators(getSession.request, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSettings));
