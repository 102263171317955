import { useMemo } from 'react';
import PropTypes from 'prop-types';
import ValidationInstructions from './ValidationInstructions';
import { BasicButton } from '../../../Button';

const IdentityItem = ({
  element,
  modal,
  openDomainModal,
  deleteIdentity,
  makeDomainMessageLink,
}) => {
  const {
    id,
    identity,
    identityType,
    verified,
    isDefault,
  } = element;

  const statusText = useMemo(() => {
    if (isDefault) {
      return 'Default';
    }
    if (!verified) {
      return 'Pending';
    }
    if (identityType === 'email') {
      return 'Email Verified';
    }
    if (identityType === 'sms') {
      return 'SMS Domain Verified';
    }
    return 'Email Domain Verified';
  }, [isDefault, verified, identityType]);

  return (
    <div className={`identity${modal ? '--modal' : ''}`}>
      <div className="identity__icon">
        <span className={`identity__icon__${identityType}`} />
      </div>
      <div className="identity__container">
        <div className="identity__header">
          <span className="identity__header__title">
            {identity}
          </span>
          {!modal && (
            <div className="identity__header__actions">
              <span
                className="identity__header__actions__delete"
                onClick={() => deleteIdentity(id, identityType)}
              />
            </div>
          )}
        </div>
        <span className={`identity__validity${verified ? '' : '--pending'}`}>
          {statusText}
        </span>
        {identityType === 'email' && (
          <>
            <div
              className="identity__validate-domain"
            >
              {statusText === 'Pending'
                ? 'Your email verification is pending. Please click the link sent to your email.'
                : 'Your email is verified. Verify your email domain for better deliverability.'}
            </div>
            <div className="identity__domain">
              <div className="identity__domain__actions">
                <BasicButton
                  content="Verify Email Domain"
                  color="blue"
                  size="small"
                  className="invert"
                  onClick={() => openDomainModal(element)}
                />
              </div>
            </div>
          </>
        )}
        {(identityType === 'domain' || identityType === 'sms') && verified && (
          <div className="identity__verified">
            {`Your ${identityType === 'sms' ? 'SMS' : 'email'} domain is verified.`}
          </div>
        )}
        {(identityType === 'domain' || identityType === 'sms') && !verified && (
          <ValidationInstructions element={element} />
        )}
        {identityType === 'sms' && verified && (
          <div className="identity__domain">
            <BasicButton
              content="Set as Default"
              color="blue"
              size="small"
              className="invert"
              disabled={isDefault}
              onClick={() => makeDomainMessageLink(id)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

IdentityItem.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.number,
    identity: PropTypes.string,
    identityType: PropTypes.string,
    verified: PropTypes.bool,
    isDefault: PropTypes.bool,
  }),
  modal: PropTypes.bool,
  openDomainModal: PropTypes.func,
  deleteIdentity: PropTypes.func,
  makeDomainMessageLink: PropTypes.func,
};

export default IdentityItem;
