import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components.
import CreateElementButton from '../../components/CreateElementButton';

// Constants
import { SURVEY } from '../../constants/widgetTypes';

// Context.
import {
  EditorContext,
} from '../../context/EditorContext';

const SurveyWidget = () => {
  const {
    addElement,
    setActiveElement,
  } = useContext(EditorContext);

  const createSurveyElement = () => {
    const elementId = uuidv4();
    const defaultElementPropertiesObj = {
      elementType: SURVEY,
      id: elementId,
      surveyName: `survey-${elementId.substring(0, 5)}`,
      buttonText: 'Done',
      surveyFeedback: '',
      buttonColor: '#000',
      showResponseResults: false,
      questions: [
        {
          questionId: `${uuidv4()}-oneAnswer`,
          type: 'oneAnswer',
          answerRequired: false,
          questionTitle: 'question #',
          quizMode: false,
          answerExample: '',
          ratingValue: '10',
          answersOptions: [
            {
              answerId: uuidv4(),
              title: 'choice #',
              isCorrect: false,
            },
            {
              answerId: uuidv4(),
              title: 'choice #',
              isCorrect: false,
            },
          ],
        },
      ],
    };

    addElement(defaultElementPropertiesObj);
    setActiveElement(defaultElementPropertiesObj);
  };

  return (
    <CreateElementButton
      title="SURVEY"
      iconName="list"
      onClick={() => createSurveyElement()}
    />
  );
};

export default SurveyWidget;
