import PropTypes from 'prop-types';
import { map } from 'lodash';

const UploadErrorMsg = ({ err }) => (
  <div>
    {err && (
    <ul>
      {map(err, (msg, index) => (
        <li key={index}>
          {msg}
        </li>
          ))}
    </ul>
      )}
  </div>
  );

UploadErrorMsg.propTypes = {
  err: PropTypes.array,
};

export default UploadErrorMsg;
