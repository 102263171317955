import {
  REQUEST,
  SUCCESS,
  FAILURE,
  createRequestTypes,
  action,
} from './request';

export const FETCH_RECIPIENT_SURVEYS = createRequestTypes('FETCH_PLANS');

export const getRecipientSurveys = {
  request: (payload) => action(FETCH_RECIPIENT_SURVEYS[REQUEST], { payload }),
  success: (response) => action(FETCH_RECIPIENT_SURVEYS[SUCCESS], { response }),
  failure: (error) => action(FETCH_RECIPIENT_SURVEYS[FAILURE], { error }),
};
