import { createFormAction } from 'redux-form-saga';
import { messagesPaginator } from '../paginators';
import {
  createRequestTypes,
  action,
} from './request';
import { DEFAULT_MESSAGES_PAGE_SIZE } from '../common';
import { ENV } from '../config';

const { API_ENV } = ENV;

export const DELETE_MESSAGE = createRequestTypes('DELETE_MESSAGE');
export const UPDATE_MESSAGE = createRequestTypes('UPDATE_MESSAGE');
export const FETCH_MESSAGE = createRequestTypes('FETCH_MESSAGE');
export const SAVE_MESSAGE = createRequestTypes('SAVE_MESSAGE');
export const CREATE_MESSAGE = createRequestTypes('CREATE_MESSAGE');
export const CREATE_MESSAGE_AUTOMATION = createRequestTypes('CREATE_MESSAGE_AUTOMATION');
export const EDIT_MESSAGE = createRequestTypes('EDIT_MESSAGE');
export const SEND_MESSAGE = createRequestTypes('SEND_MESSAGE');
export const SAVE_MESSAGE_DELIVERY_DATA = createRequestTypes('SAVE_MESSAGE_DELIVERY_DATA');
export const GET_RECIPIENTS_METADATA = createRequestTypes('GET_RECIPIENTS_METADATA');
export const UPDATE_MESSAGE_EDITOR_STATE = 'UPDATE_MESSAGE_EDITOR_STATE';
export const CLEAR_MESSAGE_EDITOR_STATE = 'CLEAR_MESSAGE_EDITOR_STATE';
export const CLEAR_RECIPIENTS = 'CLEAR_RECIPIENTS';
export const REMOVE_SINGLE_RECIPIENT = 'REMOVE_SINGLE_RECIPIENT';
export const CHANGE_MESSAGE_FILTER = 'CHANGE_MESSAGE_FILTER';
export const CREATE_MESSAGE_FILTER = 'CREATE_MESSAGE_FILTER';
export const UPDATE_MESSAGE_DESIGN = 'UPDATE_MESSAGE_DESIGN';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const requestMessagesPage = (pageNo, filter = '') => messagesPaginator.messages.requestPage(pageNo, `message/${API_ENV}/messages?count=${DEFAULT_MESSAGES_PAGE_SIZE}&depth=minimal${filter}`);

export const requestModifiedMessagesPage = (pageNo, filter) => {
  const msgFilter = filter || `message/${API_ENV}/messages?count=${DEFAULT_MESSAGES_PAGE_SIZE}&depth=minimal`;
  return messagesPaginator.messages.requestModifiedPage(pageNo, msgFilter);
};

export const duplicateMessage = createFormAction('DUPLICATE_MESSAGE');

export const deleteMessage = {
  request: (payload) => action(DELETE_MESSAGE.REQUEST, { payload }),
  success: (response, editor) => action(DELETE_MESSAGE.SUCCESS, { response, editor }),
  failure: (error) => action(DELETE_MESSAGE.FAILURE, { error }),
};

export const updateMessage = {
  request: (payload) => action(UPDATE_MESSAGE.REQUEST, { payload }),
  success: (response) => action(UPDATE_MESSAGE.SUCCESS, { response }),
  failure: (error) => action(UPDATE_MESSAGE.FAILURE, { error }),
};

export const fetchMessage = {
  request: (payload) => action(FETCH_MESSAGE.REQUEST, { payload }),
  success: (response) => action(FETCH_MESSAGE.SUCCESS, { response }),
  failure: (error) => action(FETCH_MESSAGE.FAILURE, { error }),
};

export const createMessage = {
  request: (payload) => action(CREATE_MESSAGE.REQUEST, { payload }),
  success: (response) => action(CREATE_MESSAGE.SUCCESS, { response }),
  failure: (error) => action(CREATE_MESSAGE.FAILURE, { error }),
};

export const createMessageAutomation = {
  request: (payload) => action(CREATE_MESSAGE_AUTOMATION.REQUEST, { payload }),
  success: (response) => action(CREATE_MESSAGE_AUTOMATION.SUCCESS, { response }),
  failure: (error) => action(CREATE_MESSAGE_AUTOMATION.FAILURE, { error }),
};

export const editMessage = {
  request: (payload) => action(EDIT_MESSAGE.REQUEST, { payload }),
  success: (response) => action(EDIT_MESSAGE.SUCCESS, { response }),
  failure: (error) => action(EDIT_MESSAGE.FAILURE, { error }),
};

export const saveMessage = {
  request: (payload) => action(SAVE_MESSAGE.REQUEST, { payload }),
  success: (response) => action(SAVE_MESSAGE.SUCCESS, { response }),
  failure: (error) => action(SAVE_MESSAGE.FAILURE, { error }),
};

export const updateMessageEditorState = (payload) => action(UPDATE_MESSAGE_EDITOR_STATE, payload);

export const sendMessage = {
  request: (payload) => action(SEND_MESSAGE.REQUEST, { payload }),
  success: (response) => action(SEND_MESSAGE.SUCCESS, { response }),
  failure: (error) => action(SEND_MESSAGE.FAILURE, { error }),
};

export const saveMessageDeliveryData = {
  request: (payload) => action(SAVE_MESSAGE_DELIVERY_DATA.REQUEST, { payload }),
  success: (response) => action(SAVE_MESSAGE_DELIVERY_DATA.SUCCESS, { response }),
  failure: (error) => action(SAVE_MESSAGE_DELIVERY_DATA.FAILURE, { error }),
};

export const removeSingleRecipient = (payload) => action(REMOVE_SINGLE_RECIPIENT, payload);

export const getRecipientsMetadata = {
  request: (payload) => action(GET_RECIPIENTS_METADATA.REQUEST, { payload }),
  success: (response) => action(GET_RECIPIENTS_METADATA.SUCCESS, { response }),
  failure: (error) => action(GET_RECIPIENTS_METADATA.FAILURE, { error }),
};

export const changeMessageFilter = (payload) => action(CHANGE_MESSAGE_FILTER, payload);

export const createMessageFilter = () => action(CREATE_MESSAGE_FILTER);

export const updateMessageDesign = (response) => action(UPDATE_MESSAGE_DESIGN, { response });

export const clearMessages = () => action(CLEAR_MESSAGES);
