import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  isCurrentPageFetching,
  getCurrentTotalResultsCount,
  getCurrentPageResults,
  getCurrentPageNumber,
} from '../../../modules/redux-paginator';
import { accountActions } from '../../../actions';
import { DataTable } from '../../../containers/Table';
import { PaymentHistoryCols } from '../../TableColumns';
import { fetchInvoice } from '../../../services/invoiceApi';

const { requestPaymentHistoryPage } = accountActions;

const PaymentHistory = ({
  requestPaymentHistoryPageA,
  paymentsPaginator,
  payments,
}) => {
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageResults, setCurrentPageResults] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(0);

  useEffect(() => {
    requestPaymentHistoryPageA(1);
  }, []);

  useEffect(() => {
    const currentPageSize = getCurrentPageResults(payments, paymentsPaginator, 'payments').length;

    setTotalCount(getCurrentTotalResultsCount(paymentsPaginator, 'payments'));
    setLoading(isCurrentPageFetching(paymentsPaginator, 'payments'));
    setCurrentPageResults(getCurrentPageResults(payments, paymentsPaginator, 'payments'));
    setCurrentPageNo(getCurrentPageNumber(paymentsPaginator, 'payments'));
    setPageSize(currentPageSize);
  }, [paymentsPaginator, payments]);

  const onPageChange = (pageNo) => {
    requestPaymentHistoryPageA(pageNo + 1);
  };

  const onDownloadInvoiceClick = (filname) => {
    fetchInvoice(filname).then((res) => window.open(res.response.url, '_blank'));
    return false;
  };

  return (
    <div className="table-page-container">
      <DataTable
        loading={loading}
        total={totalCount}
        data={currentPageResults}
        className="paymentHistory-table"
        onPageChange={onPageChange}
        pageSize={pageSize}
        page={currentPageNo}
        columns={PaymentHistoryCols(onDownloadInvoiceClick)}
      />
    </div>
  );
};

PaymentHistory.propTypes = {
  requestPaymentHistoryPageA: PropTypes.func,
  paymentsPaginator: PropTypes.object,
  payments: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  requestPaymentHistoryPageA: bindActionCreators(requestPaymentHistoryPage, dispatch),
});

const mapStateToProps = (state) => ({
  payments: state.paymentHistory,
  paymentsPaginator: state.paymentsPaginator,
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
