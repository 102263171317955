import {
  memo,
  useContext,
  useMemo,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { isEqual, includes } from 'lodash';
import { AutomationContext } from '../../../context/AutomationContext';

const AutomationMessageItem = ({
  message,
  currentStep,
  setCurrentStep,
  handleRemoveClick,
  openMessageNameEditModal,
}) => {
  const {
    selectedMessageId,
    setSelectedMessageId,
    setKeepEditorOpen,
    usedMessageIds,
  } = useContext(AutomationContext);
  const [messageChanged, setMessageChanged] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedStep, setSelectedStep] = useState('design');

  const isSelected = useMemo(() => selectedMessageId === message.id,
    [message.id, selectedMessageId]);

  const [designClassName, deliveryClassName] = useMemo(() => {
    let deliveryDisabled = '';
    if (message.status === 'sent' || selectedMessageId !== message.id) {
      deliveryDisabled = ' disabled';
    }
    return [
      `message-nav__nav-step${isSelected && currentStep === 'design' ? ' selected' : ''}`,
      `message-nav__nav-step${isSelected && currentStep === 'delivery' ? ' selected' : ''}${deliveryDisabled}`,
    ];
  }, [isSelected, message.status, currentStep]);

  const canDelete = useMemo(() => {
    if (message.id) {
      return !includes(usedMessageIds, message.id);
    }
    return false;
  }, [usedMessageIds, message.id]);

  const blockId = useMemo(() => `message-${message.id}`, [message.id]);

  useEffect(() => {
    if (!selectedMessageId && messageChanged) {
      setSelectedMessageId(selectedId);
    } else if (messageChanged && selectedMessageId === selectedId) {
      setCurrentStep(selectedStep);
      setMessageChanged(false);
    }
  }, [selectedMessageId, messageChanged]);

  useEffect(() => {
    if (selectedMessageId && isSelected) {
      document.getElementById(blockId).scrollIntoView(true);
    }
  }, [selectedMessageId]);

  const handleMessageStepClick = (id, step) => () => {
    if (selectedMessageId === id) {
      setCurrentStep(step);
    } else {
      setKeepEditorOpen(true);
      setSelectedMessageId(undefined);
      setMessageChanged(true);
      setSelectedId(id);
      setSelectedStep(step);
    }
  };

  const handleMessageNameClick = (e) => {
    if (isSelected) {
      e.stopPropagation();
      openMessageNameEditModal(message.name);
    } else {
      handleMessageStepClick(message.id, 'design')();
    }
  };

  return (
    <div className="automation-sidebar__messages__message-block" id={blockId}>
      <div className="input__wrapper">
        <span className="message-name-container">
          <span className={`input-icon input-icon--${message.deliveryMethod || 'sms'}`} />
          <span
            className={`automation-sidebar__messages__message-block--name${isSelected && (message.status !== 'sent' || message.status !== 'active-sending' || message.status !== 'frozen') ? ' edit' : ''}`}
            onClick={handleMessageNameClick}
          >
            {message.name}
          </span>
        </span>
        {canDelete && (
          <span
            className="automation-sidebar__messages__message-block--remove"
            onClick={handleRemoveClick}
          />
        )}
      </div>
      <div className="message-nav">
        <div className={designClassName} onClick={handleMessageStepClick(message.id, 'design')}>
          <span className="icon" />
          <div className="label">{message.status === 'sent' ? 'Preview' : 'Design'}</div>
        </div>
        <div className={deliveryClassName} onClick={handleMessageStepClick(message.id, 'delivery')}>
          <span className="icon" />
          <div className="label">Delivery method</div>
        </div>
      </div>
    </div>
  );
};

AutomationMessageItem.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    deliveryMethod: PropTypes.string,
    name: PropTypes.string,
  }),
  currentStep: PropTypes.string,
  setCurrentStep: PropTypes.func,
  handleRemoveClick: PropTypes.func,
  openMessageNameEditModal: PropTypes.func,
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps.message, nextProps.message)
  && prevProps.currentStep === nextProps.currentStep;

export default memo(AutomationMessageItem, areEqual);
