import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

const PreviewModal = ({
  handleModalState,
  open,
  src,
  alt,
  style,
  windowWidth,
}) => (
  <Modal
    open={open}
    onClose={() => handleModalState()}
    className="message-modal"
    dimmer="inverted"
  >
    <Modal.Header className="contact-modal__header">
      <div className={`close ${windowWidth ? 'modal-close-icon-for-tablet' : 'modal-close-icon'}`} onClick={() => handleModalState()} />
    </Modal.Header>
    <Modal.Content>
      <img src={src} alt={alt} style={style} />
    </Modal.Content>
  </Modal>
);

PreviewModal.propTypes = {
  handleModalState: PropTypes.func,
  open: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.object,
  windowWidth: PropTypes.bool,
};

export default PreviewModal;
