import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form, reduxForm, FieldArray, getFormValues,
} from 'redux-form';
import { PropertiesMatchArray } from '../FieldArray';

const ContactsUploadForm = ({
  onFormSubmit,
  handleSubmit,
  contactProperties,
  formValues,
}) => (
  <Form onSubmit={handleSubmit(onFormSubmit.bind(this))}>
    <FieldArray
      name="headers"
      component={PropertiesMatchArray}
      formValues={formValues}
      contactProperties={contactProperties}
    />
  </Form>
);

ContactsUploadForm.propTypes = {
  handleSubmit: PropTypes.func,
  onFormSubmit: PropTypes.func,
  formValues: PropTypes.shape({
    headers: PropTypes.array,
  }),
  contactProperties: PropTypes.objectOf(PropTypes.object),
};

const formConfig = {
  form: 'contactsUploadForm',
};

const mapStateToProps = (state) => ({
  contactProperties: state.entities.contactProperties,
  formValues: getFormValues('contactsUploadForm')(state),
});

export default connect(mapStateToProps)(reduxForm(formConfig)(ContactsUploadForm));
