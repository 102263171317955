import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Notification } from '../../components/Notification';
import { ConfirmationModal, ModalPortal } from '../Modal';
import { HeaderAutomation } from '../Header';
import { FooterEditor } from '../../components/Footer';
import AutomationContextProvider, { withAutomation } from '../../context/AutomationContext';
import { EditorContextProvider } from '../../editor/context/EditorContext';

const FooterWithContext = withAutomation(FooterEditor);

const AutomationLayout = (props) => {
  const { children } = props;
  return (
    <div className="app-main">
      <Notification />
      <EditorContextProvider>
        <AutomationContextProvider>
          <ConfirmationModal />
          <ModalPortal />
          <HeaderAutomation />
          {children && cloneElement(children, props)}
          <FooterWithContext />
        </AutomationContextProvider>
      </EditorContextProvider>
    </div>
  );
};

AutomationLayout.propTypes = {
  children: PropTypes.element,
};

export default AutomationLayout;
