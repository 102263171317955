import { request } from '../actions';
import { FETCH_ANALYTICS_DATA, CLEAR_ANALYTICS_DATA } from '../actions/analyticsActions';

const { SUCCESS, REQUEST } = request;

const analyticsData = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ANALYTICS_DATA[SUCCESS]: {
      return {
        ...state,
        ...action.response,
      };
    }
    case CLEAR_ANALYTICS_DATA[REQUEST]: {
      return {};
    }
    default:
      return state;
  }
};

export default analyticsData;
