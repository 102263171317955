import { withRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

function MessageUnavailable() {
  return (
    <div>
      <div className="notfound__header">
        <Grid>
          <Grid.Row centered className="notfound__header--big">
            Message is no longer available
          </Grid.Row>
        </Grid>
      </div>
      <div className="notfound__footer" />
    </div>
  );
}

export default withRouter(MessageUnavailable);
