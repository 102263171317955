import { request } from '../actions';

import {
  SEARCH_RECIPIENT_METRICS,
  FETCH_RECIPIENTS_METRICS,
} from '../actions/analyticsActions';

const { SUCCESS } = request;

const formMetrics = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RECIPIENTS_METRICS[SUCCESS]:
    case SEARCH_RECIPIENT_METRICS[SUCCESS]: {
      return {
        ...state,
        ...action.response,
      };
    }
    default:
      return state;
  }
};

export default formMetrics;
