import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Modal, Button, Header } from 'semantic-ui-react';
import { capitalize } from 'lodash';

// Actions.
import { genericActions } from '../../actions';

const { closeConfirmationModal } = genericActions;

const ConfirmationModal = ({
  properties: {
    open,
    actionName,
    itemName,
    callbackFunction,
    id,
    discardCallbackFunction,
    text,
  }, closeConfirmationModalA,
}) => (
  <Modal
    basic
    size="fullscreen"
    open={open}
  >
    <div className="confirmation-modal">
      <div className="confirmation-modal-content">
        <Header size="huge" className={`header${actionName === 'send' ? '--delivery' : ''}`}>
          {text || `Are you sure you want to ${actionName} selected ${itemName}?`}
        </Header>
        <Modal.Actions className="actions__confirmation">
          <Button
            size="massive"
            onClick={() => {
              closeConfirmationModalA();
              if (discardCallbackFunction) {
                discardCallbackFunction();
              }
            }}
            className="button button__inverted--white"
          >
            Cancel
          </Button>
          <Button
            size="massive"
            onClick={() => {
              closeConfirmationModalA();
              if (callbackFunction) {
                callbackFunction(id);
              }
            }}
            className="button button__inverted--green"
          >
            {capitalize(actionName)}
          </Button>
        </Modal.Actions>
      </div>
    </div>
  </Modal>
);

ConfirmationModal.propTypes = {
  properties: PropTypes.shape({
    open: PropTypes.bool,
    actionName: PropTypes.string,
    itemName: PropTypes.string,
    callbackFunction: PropTypes.func,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    discardCallbackFunction: PropTypes.func,
    text: PropTypes.string,
  }),
  closeConfirmationModalA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  properties: state.confirmationModal,
});

const mapDispatchToProps = (dispatch) => ({
  closeConfirmationModalA: bindActionCreators(closeConfirmationModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
