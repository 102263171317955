import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components.
import CreateElementButton from '../../components/CreateElementButton';

// Constants.
import { INITIAL_EDITOR_CONTENT_RAW_STATE } from '../../constants/textEditorRawState';

// Context.
import {
  EditorContext,
} from '../../context/EditorContext';

const TextWidget = () => {
  const {
    addElement,
    setActiveElement,
  } = useContext(EditorContext);

  const createTextElement = () => {
    const elementId = uuidv4();
    const defaultElementPropertiesObj = {
      elementType: 'text',
      id: elementId,
      content: INITIAL_EDITOR_CONTENT_RAW_STATE,
    };
    addElement(defaultElementPropertiesObj);
    setActiveElement(defaultElementPropertiesObj);
  };

  return (
    <CreateElementButton
      title="TEXT"
      iconName="text_format"
      onClick={() => createTextElement()}
    />
  );
};

export default TextWidget;
