import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field,
  Form,
  getFormSyncErrors,
  isSubmitting,
  reduxForm,
  submit,
} from 'redux-form';
import { Popup } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { BasicButton } from '../../components/Button';
import { Input, Dropdown } from '../../components/Field';
import { required, maxLength32 } from '../../utils/validator';
import { contactPropertyCreate } from '../../services/contactApi';
import { contactActions, notificationActions } from '../../actions';
import { stringToCamelCase } from '../../utils/helper';

const { notifySuccess, notifyError } = notificationActions;
const { fetchContactProperties } = contactActions;

const propertyTypeOptions = [
  { text: 'Text', value: 'text' },
  { text: 'Number', value: 'numeric' },
  { text: 'ID', value: 'id' },
];

const ContactsColumnCreatePopup = ({
  submitFormA,
  notifySuccessA,
  notifyErrorA,
  fetchContactPropertiesA,
  handlePopupState,
  open,
  trigger,
  formErrors,
  submitting,
  handleSubmit,
}) => {
  const onSubmitClick = () => {
    submitFormA('createColumn');
  };

  const onSubmit = (values) => {
    const { name, type } = values;

    const body = {
      name: stringToCamelCase(name),
      label: name,
      dataType: type === 'text' ? 'text' : 'numeric',
      displayType: 'text',
      defaultValue: '',
      description: '',
      readOnly: false,
      required: type === 'id',
      checkedValue: '',
      uncheckedValue: '',
      optionList: null,
      groupName: 'information',
      hidden: false,
      formField: true,
      vendorDefined: true,
      weight: 7,
      alias: '',
      type: 'custom',
      validators: [],
    };
    if (type === 'id') {
      body.validators = [
        { name: 'required' },
        { name: 'unique' },
      ];
    } else if (type === 'numeric') {
      body.validators = [
        { name: 'type_numeric' },
      ];
    }

    contactPropertyCreate(body).then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
      } else {
        notifySuccessA(`Column ${values.name} created`);
        fetchContactPropertiesA();
        handlePopupState();
      }
    });
  };

  return (
    <Popup
      trigger={trigger}
      on="click"
      open={open}
      onClose={handlePopupState}
      onOpen={handlePopupState}
      position="bottom right"
      basic={true}
      size="huge"
      className="popup-columns-add"
    >
      <Popup.Content>
        <Form onSubmit={handleSubmit(onSubmit)} className="add-list-form ris-form">
          <span className="add-list-form__title">Add Column</span>
          <Field
            component={Input}
            placeholder="Enter column name"
            name="name"
            size="large"
            validate={[required, maxLength32]}
            className="add-list-form__input ui input"
          />
          <Field
            component={Dropdown}
            placeholder="Select a type"
            name="type"
            size="large"
            validate={required}
            className="add-list-form__select"
            options={propertyTypeOptions}
          />
        </Form>
      </Popup.Content>
      <div className="add-list-form__actions">
        <BasicButton
          type="button"
          content="Cancel"
          size="small"
          color="white"
          onClick={handlePopupState}
        />
        <BasicButton
          type="button"
          content="Add Column"
          size="small"
          color="blue"
          disabled={!isEmpty(formErrors) || submitting}
          onClick={onSubmitClick}
        />
      </div>
    </Popup>
  );
};

ContactsColumnCreatePopup.propTypes = {
  open: PropTypes.bool,
  handlePopupState: PropTypes.func,
  formErrors: PropTypes.object,
  trigger: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  fetchContactPropertiesA: PropTypes.func,
  submitFormA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('createColumn')(state),
  submitting: isSubmitting('createColumn')(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitFormA: bindActionCreators(submit, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  fetchContactPropertiesA: bindActionCreators(fetchContactProperties.request, dispatch),
});

const formConfig = {
  form: 'createColumn',
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm(formConfig)(ContactsColumnCreatePopup));
