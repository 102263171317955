import { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Header as SemanticHeader } from 'semantic-ui-react';

import { DATE_TIME } from '../../common';
import { IconButton } from '../Button';
import { useFeature } from '../../hooks';
import { featuresKeys } from '../../common/plans';

const Header = ({
  analyticsData,
  message,
  openSegmentationModal,
  features,
}) => {
  const showSegmentation = useFeature(featuresKeys.featureKeys.resultBasedSegmentation, features);

  return (
    <SemanticHeader className="message-statistics message-statistics__header">
      <span>{analyticsData && analyticsData.messageName}</span>
      <div className="message-statistics__header__contacts">
        <div className="message-statistics__header__contacts--value">
          {analyticsData && analyticsData.numOfRecipients}
        </div>
        <div className="message-statistics__header__contacts--label">
          Contacts
        </div>
      </div>
      <div className="message-statistics__header__sending">
        <div className="message-statistics__header__sending--label">
          Delivered by:
        </div>
        <div className="message-statistics__header__sending--value">
          {analyticsData && analyticsData.deliveryMethod}
        </div>
      </div>
      <div className="message-statistics__header__sending">
        <div className="message-statistics__header__sending--label">
          Sending time:
        </div>
        <div className="message-statistics__header__sending--value">
          {message && moment(message.scheduledAt).format(DATE_TIME)}
        </div>
      </div>
      {showSegmentation ? (
        <div className="message-statistics__header__button">
          <IconButton
            type="button"
            className="blue-light segmentation-button"
            text="Filter Results"
            iconName="keyboard_arrow_right"
            onClick={() => openSegmentationModal()}
            before={false}
          />
        </div>
      ) : null}
      <div className="message-statistics__navigation__realtime message-statistics__navigation__realtime--mobile">
        <div className="ripple-color" />
        <div>Data shown in Realtime</div>
      </div>
    </SemanticHeader>
  );
};

Header.propTypes = {
  analyticsData: PropTypes.shape({
    messageName: PropTypes.string,
    numOfRecipients: PropTypes.number,
    deliveryMethod: PropTypes.string,
  }),
  message: PropTypes.shape({
    scheduledAt: PropTypes.string,
  }),
  openSegmentationModal: PropTypes.func,
  features: PropTypes.object,
};

export default memo(Header);
