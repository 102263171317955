import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { findSelectedCountry } from '../../../utils/plans';
import { mapPlanCountriesToOptions } from '../../../utils/map';

const PlansPeriodSelection = ({
  countries: { default: defaultValue, countries },
  setPaymentType,
  paymentType,
}) => {
  const [targetMarket, updateTargetMarket] = useState();
  const [selectedCountry, updateSelectedCountry] = useState();

  const handleCountrySelection = (value) => {
    if (value && !isEmpty(countries)) {
      updateTargetMarket(value);
      updateSelectedCountry(findSelectedCountry(countries, value));
    }
  };

  useEffect(() => handleCountrySelection(defaultValue), [defaultValue]);

  return (
    <div className="ris-pricing-menu">
      <p className="ris-pricing-inner-pick-title">
        Pick your target market
      </p>
      {targetMarket ? (
        <Dropdown
          fluid
          selection
          options={mapPlanCountriesToOptions(countries)}
          defaultValue={targetMarket}
          onChange={(e, { value }) => handleCountrySelection(value)}
        />
      ) : null}
      <div className="monthlyannually">
        <div
          className={`pricing-monthly${paymentType === 'month' ? ' pricing-monthly--active' : ''}`}
          onClick={() => setPaymentType('month')}
        >
          Monthly
        </div>
        <div
          className={`pricing-annually${paymentType === 'year' ? ' pricing-annually--active' : ''}`}
          onClick={() => setPaymentType('year')}
        >
          Annually
        </div>
      </div>
      {selectedCountry ? (
        <>
          <div className="ris-save ris-save__line" />
          <div className="ris-save ris-save__text">{`Save ${selectedCountry.discount * 100}% !`}</div>
        </>
      ) : null}
    </div>
  );
};

PlansPeriodSelection.propTypes = {
  setPaymentType: PropTypes.func,
  paymentType: PropTypes.string,
  countries: PropTypes.object,
};

const mapStateToProps = (state) => ({
  countries: state.plans.countries,
  session: state.plans.session,
});

const areEqual = (prevProps, nextProps) => prevProps.paymentType === nextProps.paymentType
  && isEqual(prevProps.countries, nextProps.countries);

export default connect(mapStateToProps)(memo(PlansPeriodSelection, areEqual));
