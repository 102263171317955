import PropTypes from 'prop-types';
import { map } from 'lodash';

const ValidationInstructions = ({
  element: {
    dns,
    identityType,
  },
  modal,
}) => (
  <div className="identity__validation">
    {!modal && (
      <>
        <span className="identity__validation__title">
          VALIDATE YOUR DOMAIN
        </span>
        <span className="identity__validation__instructions">
          To validate your domain you need to add two DNS records in your domain provider&apos;s
          cPanel or Zone Editor. The validation can take up to 48 h,
          check this page again to see if it&apos;s verified.
        </span>
      </>
    )}
    {identityType === 'domain' ? (
      <>
        <span className="identity__validation__list">
          DKIM:
        </span>
        {map(dns, (e) => (
          <span
            className="identity__validation__instructions"
            key={e.value}
          >
            Create a CNAME record for&nbsp;
            <b>{e.name}</b>
            &nbsp;with value:&nbsp;
            <b>{e.value}</b>
          </span>
        ))}
      </>
    ) : (
      <>
        {map(dns, (e) => (
          <span
            className="identity__validation__instructions"
            key={e.value}
          >
            Create a DNS&nbsp;
            <b>{e.type}</b>
            &nbsp;type record, name:&nbsp;
            <b>{e.name}</b>
            &nbsp;, value:&nbsp;
            <b>{e.value}</b>
          </span>
        ))}
      </>
    )}
  </div>
);

ValidationInstructions.propTypes = {
  element: PropTypes.shape({
    dns: PropTypes.array,
    identityType: PropTypes.string,
  }),
  modal: PropTypes.bool,
};

export default ValidationInstructions;
