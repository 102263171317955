import { useContext, memo } from 'react';
import moment from 'moment';
import { AutomationContext } from '../../context/AutomationContext';
import { AutomationStatus } from '../../components/Automation/shared';
import { AutomationTitle } from '../../components/Automation/Editor';
import { DATE_TIME } from '../../common';

const HeaderAutomation = () => {
  const {
    automation,
    actionButtonHandler,
    returnToList,
    automationInfoGet,
  } = useContext(AutomationContext);

  const refreshInfo = () => {
    automationInfoGet(false);
  };

  return (
    <div className="app-header app-header__automation">
      <div className="app-header__automation__heading">
        <div className="site-logo-white" onClick={returnToList} />
        <span className="app-header__automation__heading__divider" />
        <AutomationTitle />
      </div>
      <div className="app-header__automation__info">
        {automation?.lastExecutedAt && (
          <span className="app-header__automation__info--execution-status">
            {`Last time executed at ${moment(automation.lastExecutedAt).format(DATE_TIME)}`}
          </span>
        )}
        {automation?.status && (
          <AutomationStatus
            lastFailure={automation.lastFailure}
            status={automation.status}
            editor
          />
        )}
        {automation?.status === 'in_progress' && (
          <div
            className="app-header__automation__info__button"
            onClick={refreshInfo}
          >
            Refresh data
          </div>
        )}
        <div
          className={`app-header__automation__info__button${automation?.status === 'in_progress' ? ' no-margin' : ''} `}
          onClick={actionButtonHandler}
        >
          {`${automation?.status === 'in_progress' ? 'Pause' : 'Start'} Automation`}
        </div>
      </div>
    </div>
  );
};

export default memo(HeaderAutomation);
