import {
  createContext,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import {
  isEmpty,
  forEach,
  uniq,
  find,
  includes,
  remove,
  isEqual,
  map,
} from 'lodash';
import { api } from '../services';
import { notificationActions, messageActions, genericActions } from '../actions';
import {
  AUTOMATION_STATUS,
  NODE_WIDTH,
  NODE_HEIGHT,
  HORIZONTAL_GAP,
  VERTICAL_GAP,
  EIDITING_DISABLED_STATUS,
} from '../common/automation';
import {
  findTreeWidthAndDepth,
  setNodePosition,
  calculateNodePositions,
  getEdges,
} from '../utils/automationHelper';
import { clearPaginatorPromiseArray } from '../modules/redux-paginator';

const {
  automation: automationApi,
} = api;

const {
  automationItemGet,
  automationItemUpdate,
  automationItemValidate,
  automationItemStatusSet,
  automationItemNodesGet,
  automationItemIssuesGet,
} = automationApi;

const { notifySuccess, notifyError } = notificationActions;
const { openConfirmationModal } = genericActions;

const { requestMessagesPage, deleteMessage } = messageActions;

const MINUTE_IN_MS = 60000;

export const AutomationContext = createContext({
  automation: undefined,
  loading: true,
  actionButtonHandler: () => {},
  returnToList: () => {},
  handleChangeTitle: () => {},
  handleSaveAndExitButton: () => {},
  autosaveMsg: {},
  windowWidth: undefined,
  editorDimensions: undefined,
  getNodes: () => {},
  getPathName: () => {},
  automationInfoGet: () => {},
  splitScreenOpen: false,
  setSplitScreenOpen: () => {},
  messageIds: [],
  selectedMessageId: undefined,
  setSelectedMessageId: () => {},
  addMessageToList: () => {},
  keepEditorOpen: false,
  setKeepEditorOpen: () => {},
  editingDisabled: false,
  usedMessageIds: [],
  removeMessageFromList: () => {},
  messageDelete: () => {},
});

export const withAutomation = (Component) => (
  (props) => (
    <AutomationContext.Consumer>
      {(context) => (
        <Component
          {...context}
          {...props}
        />
      )}
    </AutomationContext.Consumer>
  )
);

const AutomationContextProvider = (props) => {
  const {
    children,
    automationList,
    messages,
    notifySuccessA,
    notifyErrorA,
    requestMessagesPageA,
    openConfirmationModalA,
    deleteMessageA,
  } = props;
  const [automation, setAutomation] = useState(undefined);
  const [splitScreenOpen, setSplitScreenOpen] = useState(false);
  const [autosaveMsg, setAutosave] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(true);
  const [editorDimensions, setEditorDimensions] = useState(undefined);
  const [messageIds, setMessageIds] = useState([]);
  const [selectedMessageId, setSelectedMessageId] = useState(undefined);
  const [addedMessages, setAddedMessages] = useState([]);
  const [keepEditorOpen, setKeepEditorOpen] = useState(false);
  const [usedMessageIds, setUsedMessageIds] = useState([]);

  const nodeAutoUpdateIntervalId = useRef(-1);
  const addedMessagesRef = useRef([]);
  const nodesRef = useRef([]);

  const { push } = useHistory();
  const { id } = useParams();

  useEffect(() => {
    addedMessagesRef.current = addedMessages;
  }, [addedMessages]);

  useEffect(() => {
    if (loading && !isEmpty(messages)) {
      setLoading(false);
    }
  }, [loading, messages]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // function where nodes positioning and editor size is calculated
  const nodesPositionSetup = (nodes) => {
    // finding starting node
    const startNode = find(nodes, (node) => node.start);
    startNode.depth = 1; // setting depth for starting node
    const { id: startId } = startNode;
    // finding automation tree max depth and width
    const [treeWidth, treeDepth] = findTreeWidthAndDepth(startNode, nodes, 2, startId);
    // calculating editor viewport size
    const editorWidthFromTree = ((treeWidth - 1) * (NODE_WIDTH + HORIZONTAL_GAP)) + NODE_WIDTH;
    const editorHeight = (treeDepth * NODE_HEIGHT) + ((treeDepth - 1) * VERTICAL_GAP);
    // if calculated width is smaller than viewport - margins, it's set so
    const editorWidth = editorWidthFromTree < windowWidth ? windowWidth - 80 : editorWidthFromTree;
    setEditorDimensions({ width: editorWidth, height: editorHeight });
    // setting position for start node
    setNodePosition(startNode, (editorWidth - NODE_WIDTH) / 2);
    // setting position for child nodes
    calculateNodePositions(startNode, nodes);
  };

  const getLocalStorageMessageIds = () => {
    const localStorageMessageIds = JSON.parse(localStorage.getItem('automationMessages'));
    if (!localStorageMessageIds) {
      localStorage.setItem('automationMessages', JSON.stringify({}));
      return [];
    }
    if (localStorageMessageIds[id]) {
      return localStorageMessageIds[id];
    }
    return [];
  };

  const setLocalStorageMessageIds = (ids) => {
    let localStorageMessageIds = JSON.parse(localStorage.getItem('automationMessages'));
    if (!localStorageMessageIds) {
      localStorageMessageIds = {};
    }
    localStorageMessageIds[id] = ids;
    localStorage.setItem('automationMessages', JSON.stringify(localStorageMessageIds));
  };

  // function for checking and getting all the messages
  // mentioned in nodes and created in split-screen editor
  const getMessages = (nodes, refreshMessages) => {
    let ids = [];
    forEach(nodes, (node) => {
      const { inputs } = node;
      if (inputs && inputs.messageId) {
        ids.push(inputs.messageId);
      }
    });
    const localStorageMessageIds = getLocalStorageMessageIds();
    setUsedMessageIds(uniq(ids));
    ids = uniq([...addedMessagesRef.current, ...ids, ...localStorageMessageIds]);
    setMessageIds(ids);
    setLocalStorageMessageIds(ids);
    let neededIds = [];
    if (!isEmpty(messages) && !refreshMessages) {
      forEach(ids, (messageId) => {
        const message = messages[messageId];
        if (!message || isEmpty(message)) {
          neededIds.push(messageId);
        }
      });
    } else {
      neededIds = [...ids];
    }
    if (neededIds.length) {
      requestMessagesPageA(1, `&ids=${JSON.stringify(neededIds)}`);
    } else {
      setLoading(false);
    }
  };

  // node positioning and edges calculations are complicated functions
  // thus the need of reducing recalculations for each render cycle
  // as calculateNodePositions function inside nodePositionSetup() is recursive
  // and there is recurring call each minute, where results can be the same
  const shouldUpdateNodes = (nodes, clearPagination) => {
    if (!isEqual(nodes, nodesRef.current)) {
      let edges = [];
      nodesRef.current = map(nodes, (node) => ({ ...node }));
      nodesPositionSetup(nodes);
      edges = getEdges(nodes);
      getMessages(nodes, clearPagination);
      setAutomation((state) => ({
        ...state,
        nodes,
        edges,
      }));
    }
  };

  // function that gets all nodes assigned to the root object
  const getNodes = (autosave = false, clearPagination = false, initial = false) => {
    setLoading(!autosave || initial);
    if (clearPagination) {
      clearPaginatorPromiseArray();
    }
    automationItemNodesGet(id).then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
        setLoading(false);
      } else {
        const { response: { nodes } } = res;
        if (nodes && nodes.length) {
          shouldUpdateNodes(nodes, clearPagination);
        } else {
          // setting editor width to viewport width - margins
          setEditorDimensions({ width: windowWidth - 80, height: 0 });
          setLoading(false);
          setAutomation((state) => ({
            ...state,
            nodes,
            edges: [],
          }));
        }
      }
    });
  };

  const createAutoUpdateInterval = () => {
    nodeAutoUpdateIntervalId.current = setInterval(() => {
      getNodes(true);
    }, MINUTE_IN_MS);
  };

  const automationInfoGet = (initial) => {
    setLoading(true);
    automationItemGet(id).then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
      } else {
        nodesRef.current = null;
        setAutomation(res.response);
        getNodes(false, false, initial);
        if (initial) {
          createAutoUpdateInterval();
        }
      }
    });
  };

  // function that sets the time of save
  const setSaveTime = () => {
    const time = new Date();
    const minutes = `${time.getMinutes() < 10 ? '0' : ''}${time.getMinutes()}`;
    const fullTime = `${time.getHours()}:${minutes}`;
    setAutosave((state) => ({
      ...state,
      updatedAt: fullTime,
    }));
  };

  // callback handler function for API calls related to automation
  const callback = (successMessage, save) => (res) => {
    if (res.error) {
      notifyErrorA(res.error.message);
    } else {
      if (successMessage) {
        notifySuccessA(successMessage);
      }
      setAutomation((state) => ({
        ...state,
        ...res.response,
      }));
      if (save) {
        setSaveTime();
      }
    }
  };

  const getAutomationIssues = () => {
    automationItemIssuesGet(id).then(callback());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    if (automationList && !isEmpty(automationList)
      && automationList[id] && !isEmpty(automationList[id])) {
      setAutomation(automationList[id]);
      getAutomationIssues();
      getNodes(false);
      createAutoUpdateInterval();
    } else {
      automationInfoGet(true);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (nodeAutoUpdateIntervalId.current !== -1) {
        clearInterval(nodeAutoUpdateIntervalId.current);
      }
    };
  }, []);

  // click handler function for changing automation status
  const actionButtonHandler = () => {
    if (automation) {
      const { status } = automation;
      let callbackFunction;
      let actionName;
      if (status === 'in_progress') {
        callbackFunction = () => automationItemStatusSet({ id, status: AUTOMATION_STATUS.PAUSE }).then(callback('Automation paused', true));
        actionName = 'Pause';
      } else {
         callbackFunction = () => automationItemValidate(id).then((res) => {
          if (res.error) {
            notifyErrorA(res.error.message);
          } else {
            automationItemStatusSet({ id, status: AUTOMATION_STATUS.START }).then(callback('Automation started', true));
          }
        });
        actionName = 'Start';
      }
      openConfirmationModalA({
        text: `Are you sure you want to ${actionName.toLowerCase()} automation?`,
        callbackFunction,
        actionName,
      });
    }
  };

  const returnToList = () => {
    push('/automation');
  };

  // handler function for automation title change
  const handleChangeTitle = (updatedTitle) => {
    automationItemUpdate({ id, body: { title: updatedTitle } }).then(callback('Automation title updated', true));
  };

  const handleSaveAndExitButton = () => {
    returnToList();
  };

  const getPathName = (parent, child) => {
    const node = find(automation.nodes, (n) => n.id === parent);
    if (node) {
      const path = find(node.pathList, (p) => p.nodeId === child);
      if (path) {
        return path.name;
      }
    }
    return undefined;
  };

  const addMessageToList = (messageId) => {
    setAddedMessages([messageId, ...addedMessages]);
    setMessageIds([messageId, ...messageIds]);
    setLocalStorageMessageIds([messageId, ...messageIds]);
  };

  const status = automation?.status;

  const editingDisabled = useMemo(() => status
    && includes(EIDITING_DISABLED_STATUS, status),
    [status]);

  const removeMessageFromList = (messageId) => {
    if (messageId === selectedMessageId) {
      setSelectedMessageId(undefined);
    }
    const ids = [...messageIds];
    const addedMessageIdsCopy = [...addedMessages];
    remove(ids, (mId) => mId === messageId);
    remove(addedMessageIdsCopy, (mId) => mId === messageId);
    setAddedMessages(addedMessageIdsCopy);
    setMessageIds(ids);
    setLocalStorageMessageIds(ids);
  };

  const messageDelete = (messageId) => {
    deleteMessageA(messageId);
    removeMessageFromList(messageId);
  };

  return (
    <AutomationContext.Provider
      value={{
        automation,
        loading,
        actionButtonHandler,
        returnToList,
        handleChangeTitle,
        handleSaveAndExitButton,
        autosaveMsg,
        windowWidth,
        editorDimensions,
        getNodes,
        getPathName,
        automationInfoGet,
        splitScreenOpen,
        setSplitScreenOpen,
        messageIds,
        selectedMessageId,
        setSelectedMessageId,
        addMessageToList,
        keepEditorOpen,
        setKeepEditorOpen,
        editingDisabled,
        usedMessageIds,
        removeMessageFromList,
        messageDelete,
      }}
    >
      {children}
    </AutomationContext.Provider>
  );
};

AutomationContextProvider.propTypes = {
  children: PropTypes.array,
  automationList: PropTypes.object,
  messages: PropTypes.object,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  requestMessagesPageA: PropTypes.func,
  openConfirmationModalA: PropTypes.func,
  deleteMessageA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  automationList: state.automationList,
  messages: state.messages,
});

const mapDispatchToProps = (dispatch) => ({
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  requestMessagesPageA: bindActionCreators(requestMessagesPage, dispatch),
  openConfirmationModalA: bindActionCreators(openConfirmationModal, dispatch),
  deleteMessageA: bindActionCreators(deleteMessage.request, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutomationContextProvider);
