import PropTypes from 'prop-types';

const SelectField = ({
  defaultValue,
  text,
}) => (
  <>
    <div
      key={Math.random(10, 1000)}
      className="create-contact-label"
      defaultValue={defaultValue}
    >
      {text}
    </div>
  </>
);

SelectField.propTypes = {
  defaultValue: PropTypes.string,
  text: PropTypes.string,
};

export default SelectField;
