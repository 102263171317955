import PropTypes from 'prop-types';
import { map } from 'lodash';
import SegmentationItem from './SegmentationItem';

const SegmentationItemsArray = ({
  forms,
  segmentation,
  addNewSegment,
  removeSegment,
  changeSegmentValue,
  recipients,
  optionsDisabled,
}) => (
  <>
    <div className="segmentation-row__title">
      {`FILTER CONTACTS (${recipients.length})`}
    </div>
    {map(segmentation, (segment, index) => (
      <SegmentationItem
        forms={forms}
        item={segment}
        index={index}
        key={index}
        removeSegment={removeSegment}
        changeSegmentValue={changeSegmentValue}
      />
    ))}
    {!optionsDisabled && segmentation.length < 15 && (
      <div
        className="segmentation-row__add"
        onClick={() => addNewSegment()}
      >
        Add More Data
      </div>
    )}
  </>
);

SegmentationItemsArray.propTypes = {
  forms: PropTypes.array,
  segmentation: PropTypes.array,
  addNewSegment: PropTypes.func,
  removeSegment: PropTypes.func,
  changeSegmentValue: PropTypes.func,
  recipients: PropTypes.array,
  optionsDisabled: PropTypes.bool,
};

export default SegmentationItemsArray;
