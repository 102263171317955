import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';

import { DataTable } from '../../../containers/Table';
import {
  isCurrentPageFetching,
  getCurrentTotalResultsCount,
  getCurrentPageResults,
  getCurrentPageNumber,
} from '../../../modules/redux-paginator';
import { UserAccessCols } from '../../TableColumns';
import {
  accountActions,
  authActions,
  genericActions,
} from '../../../actions';
import { BasicButton } from '../../Button';
import { email } from '../../../utils/validator';

const { requestUsersPage, getAccount, inviteUser } = accountActions;

const {
  block,
  deleteUser,
  changeRole,
} = authActions;

const { openConfirmationModal } = genericActions;

const UserAccess = ({
  requestUsersPageA,
  getAccountA,
  account,
  usersPaginator,
  users,
  blockA,
  openConfirmationModalA,
  deleteUserA,
  inviteUserA,
  changeRoleA,
  user,
}) => {
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageResults, setCurrentPageResults] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(0);
  const [inviteEmail, setInviteEmail] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    requestUsersPageA(1);
    if (!account.companyName) {
      getAccountA();
    }
  }, []);

  useEffect(() => {
    const currentPageSize = getCurrentPageResults(users, usersPaginator, 'users').length;

    setTotalCount(getCurrentTotalResultsCount(usersPaginator, 'users'));
    setLoading(isCurrentPageFetching(usersPaginator, 'users'));
    setCurrentPageResults(getCurrentPageResults(users, usersPaginator, 'users'));
    setCurrentPageNo(getCurrentPageNumber(usersPaginator, 'users'));
    setPageSize(currentPageSize);
  }, [usersPaginator, users]);

  const onBlockClick = (id) => {
    const selectedUser = currentPageResults.find((_user) => _user.id === id);
    const actionName = selectedUser.block ? 'unblock' : 'block';
    const payload = {
      email: selectedUser.email,
      block: !selectedUser.block,
    };

    openConfirmationModalA({
      actionName,
      callbackFunction: () => blockA({ payload }),
      itemName: 'user',
    });
  };

  const onDeleteClick = (id) => {
    const selectedUser = currentPageResults.find((_user) => _user.id === id);
    const payload = {
      email: selectedUser.email,
    };

    openConfirmationModalA({
      actionName: 'delete',
      callbackFunction: () => deleteUserA({ payload }),
      itemName: 'user',
    });
  };

  const onInviteClick = (ev) => {
    const { companyName } = account;
    const updatedError = email(inviteEmail);

    if (updatedError) {
      setError(updatedError);
    } else {
      const payload = { email: inviteEmail, companyName };
      inviteUserA({ payload });
    }
    ev.preventDefault();
  };

  const onRoleChange = (id, role) => {
    const selectedUser = currentPageResults.find((_user) => _user.id === id);

    if (selectedUser.role === role) {
      return;
    }
    const payload = {
      email: selectedUser.email,
      role,
    };
    changeRoleA({ payload });
  };

  const onPageChange = (pageNo) => {
    requestUsersPageA(pageNo + 1);
  };

  const onInputChange = (data) => {
    setInviteEmail(data.value);
    setError(error);
  };

  const { id } = user;

  return (
    <div className="table-page-container">
      <div className="invite__form">
        <Input
          className="invite__field"
          placeholder="Enter email to invite new user"
          name="invite-user"
          action={(
            <BasicButton
              onClick={onInviteClick}
              content="Invite"
              size="small"
              type="button"
            />
          )}
          onChange={(e, data) => onInputChange(data)}
          error={!!error}
        />
        {error && <small className="error-message invite__error">{error}</small>}
      </div>
      <DataTable
        loading={loading}
        total={totalCount}
        data={currentPageResults}
        onPageChange={onPageChange}
        pageSize={pageSize}
        page={currentPageNo}
        className="userAccess-table"
        columns={
          UserAccessCols({
            onBlockClick,
            onDeleteClick,
            onRoleChange,
          }, 'userAccess', id)
        }
      />
    </div>
  );
};

UserAccess.propTypes = {
  getAccountA: PropTypes.func,
  requestUsersPageA: PropTypes.func,
  deleteUserA: PropTypes.func,
  blockA: PropTypes.func,
  inviteUserA: PropTypes.func,
  openConfirmationModalA: PropTypes.func,
  changeRoleA: PropTypes.func,
  users: PropTypes.object,
  usersPaginator: PropTypes.object,
  account: PropTypes.object,
  user: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  requestUsersPageA: bindActionCreators(requestUsersPage, dispatch),
  blockA: bindActionCreators(block.request, dispatch),
  deleteUserA: bindActionCreators(deleteUser.request, dispatch),
  inviteUserA: bindActionCreators(inviteUser.request, dispatch),
  changeRoleA: bindActionCreators(changeRole.request, dispatch),
  getAccountA: bindActionCreators(getAccount.request, dispatch),
  openConfirmationModalA: bindActionCreators(openConfirmationModal, dispatch),
});

const mapStateToProps = (state) => ({
  user: state.user,
  users: state.users,
  usersPaginator: state.usersPaginator,
  account: state.account,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAccess);
