import PropTypes from 'prop-types';
import { Dropdown, Input } from 'semantic-ui-react';
import { IconButton } from '../Button';
import { ContactFilterPopup } from '../../containers/Popup';

const ContactsMainMenu = ({
  animation,
  openContactFormModal,
  isList,
  openContactUploadModal,
  filterText,
  openContactFilter,
  filterPopupOpen,
  changeFilter,
  list,
  clearSort,
  search,
  handleSearchInput,
  handleFocus,
  handleBlur,
  totalCount,
  currentPageSize,
  currentPageSizes,
  setPageSize,
  disabled,
}) => (
  <div className={`contacts__header contacts__header${animation ? '--up' : '--down'}${disabled ? ' contacts__header--disabled' : ''}`}>
    <div className="contacts__actionbtn">
      <IconButton
        onClick={openContactFormModal.bind(this, undefined)}
        iconName="group_add"
        content={`Add${isList ? ' to List' : ''}`}
        size="small"
        className="contacts__actionbtn__newcon"
      />
      <IconButton
        content={`Upload${isList ? ' to List' : ''}`}
        size="small"
        color="blue"
        onClick={openContactUploadModal}
        iconName="file_upload"
        className="invert"
      />
      <ContactFilterPopup
        trigger={(
          <IconButton
            content={filterText}
            size="small"
            color="blue"
            onClick={openContactFilter}
            iconName="filter_list"
            className={filterText !== 'Filter' ? 'invert active-filter-button' : 'invert'}
          />
        )}
        open={filterPopupOpen}
        handleFilterPopupState={openContactFilter}
        changeFilter={changeFilter}
        list={list}
        clearSort={clearSort}
      />
      <Input
        type="text"
        value={search}
        placeholder="Search"
        onChange={(e, data) => handleSearchInput(data)}
        className="contacts__header__search"
      />
    </div>
    <div className="contacts__filterbox">
      <span className="contacts__amount">
        {`${totalCount || totalCount === 0 ? totalCount : ''} contacts`}
      </span>
      <Dropdown
        value={currentPageSize}
        selection
        options={currentPageSizes}
        onChange={setPageSize}
        className="page-size"
      />
    </div>
  </div>
);

ContactsMainMenu.propTypes = {
  animation: PropTypes.bool,
  openContactFormModal: PropTypes.func,
  isList: PropTypes.bool,
  openContactUploadModal: PropTypes.func,
  filterText: PropTypes.string,
  openContactFilter: PropTypes.func,
  filterPopupOpen: PropTypes.bool,
  changeFilter: PropTypes.func,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  clearSort: PropTypes.func,
  search: PropTypes.string,
  handleSearchInput: PropTypes.func,
  handleFocus: PropTypes.func,
  handleBlur: PropTypes.func,
  totalCount: PropTypes.number,
  currentPageSize: PropTypes.number,
  currentPageSizes: PropTypes.array,
  setPageSize: PropTypes.func,
  disabled: PropTypes.bool,
};


export default ContactsMainMenu;
