import { createFormAction } from 'redux-form-saga';
import { createRequestTypes } from './request';

const action = (type, payload = {}) => ({ type, ...payload });

export const MULTIPLE_COMPANIES_LOGIN = 'MULTIPLE_COMPANIES_LOGIN';
export const LOGOUT = 'LOGOUT';
export const VERIFY = createRequestTypes('VERIFY_USER');
export const BLOCK_USER = createRequestTypes('BLOCK_USER');
export const DELETE_USER = createRequestTypes('DELETE_USER');
export const CHANGE_ROLE = createRequestTypes('CHANGE_ROLE');

export const login = createFormAction('AUTHENTICATE_USER');
export const forgotPassword = createFormAction('FORGOT_PASSWORD');
export const resetPassword = createFormAction('RESET_PASSWORD');
export const changePassword = createFormAction('CHANGE_PASSWORD');

export const logout = () => ({ type: LOGOUT });

export const setCredentialsToLocalStorage = (credentials) => localStorage.setItem('credentials', credentials);

export const verify = {
  request: (body) => action(VERIFY.REQUEST, body),
  success: (response) => action(VERIFY.SUCCESS, response),
  failure: (error) => action(VERIFY.FAILURE, error),
};

export const block = {
  request: (body) => action(BLOCK_USER.REQUEST, body),
  success: (response) => action(BLOCK_USER.SUCCESS, response),
  failure: (error) => action(BLOCK_USER.FAILURE, error),
};

export const deleteUser = {
  request: (body) => action(DELETE_USER.REQUEST, body),
  success: (response) => action(DELETE_USER.SUCCESS, response),
  failure: (error) => action(DELETE_USER.FAILURE, error),
};

export const changeRole = {
  request: (body) => action(CHANGE_ROLE.REQUEST, body),
  success: (response) => action(CHANGE_ROLE.SUCCESS, response),
  failure: (error) => action(CHANGE_ROLE.FAILURE, error),
};
