import React, { useContext } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';

// Components.
import SelectInput from '../../../../components/SelectInput';

// Constants.
import {
  ONE_ANSWER,
  MULTIPLE_ANSWERS,
  NPS,
  OPEN_QUESTION,
  RATING,
  LIKE_DISLIKE,
} from '../../../../constants/answerTypes';

// Local components.
import MultipleAnswerQuestionController from './components/MultipleAnswerQuestionController';
import SingleAnswerQuestionController from './components/SingleAnswerQuestionController';

// Context.
import { EditorContext } from '../../../../context/EditorContext';

import './SurveyQuestionsController.scss';

const surveyQuestionTypes = [
  { text: 'Single choice', value: ONE_ANSWER },
  { text: 'Multiple choice', value: MULTIPLE_ANSWERS },
  { text: 'Net Promoter Score®', value: NPS },
  { text: 'Open question', value: OPEN_QUESTION },
  { text: 'Rating', value: RATING },
  { text: 'Like / Dislike', value: LIKE_DISLIKE },
];

const defineQuestionController = (questionType, props, index) => {
  const questionControllers = {
    [ONE_ANSWER]: <MultipleAnswerQuestionController {...props} index={index} />,
    [MULTIPLE_ANSWERS]: <MultipleAnswerQuestionController {...props} index={index} />,
    [NPS]: <SingleAnswerQuestionController {...props} index={index} />,
    [OPEN_QUESTION]: <SingleAnswerQuestionController {...props} index={index} />,
    [RATING]: <SingleAnswerQuestionController {...props} index={index} />,
    [LIKE_DISLIKE]: <SingleAnswerQuestionController {...props} index={index} />,
  };

  return questionControllers[questionType];
};

const SurveyQuestionsController = () => {
  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);
  const { questions } = activeElement;

  const isMoreThanOneQuestion = questions.length > 1;

  const activateRequiredAnswerOption = (value, id) => {
    const dataObj = { ...activeElement };
    const questionIndex = questions.findIndex((question) => question.questionId === id);

    dataObj.questions[questionIndex].answerRequired = value;

    setActiveElement(dataObj);
  };

  const activateQuizMode = (value, id) => {
    const dataObj = { ...activeElement };
    const questionIndex = dataObj.questions.findIndex((question) => question.questionId === id);

    dataObj.questions[questionIndex].quizMode = value;

    if (!value) {
      // resets correct answers
      const resetAnswers = questions[questionIndex].answersOptions.map((answer) => (
        { ...answer, isCorrect: false }
      ));

      dataObj.questions[questionIndex].answersOptions = resetAnswers;
    } else {
      questions[questionIndex].answersOptions[0].isCorrect = true;
    }

    setActiveElement(dataObj);
  };

  const setQuestionType = (value, id) => {
    const dataObj = { ...activeElement };
    const questionIndex = dataObj.questions.findIndex((question) => question.questionId === id);
    const questionObj = dataObj.questions[questionIndex];

    const resetedAnswers = questionObj.answersOptions.map((answer) => (
      { ...answer, isCorrect: false }
    ));

    dataObj.questions[questionIndex].type = value;
    dataObj.questions[questionIndex].answersOptions = resetedAnswers;
    dataObj.questions[questionIndex].quizMode = false;
    questions[questionIndex].questionId = `${uuidv4()}-${value}`;

    setActiveElement(dataObj);
  };

  const changeQuestionOrder = (value, direction) => {
    const dataObj = { ...activeElement };
    const replaceQuestion = (arr, from, to) => arr.splice(to, 0, arr.splice(from, 1)[0]);

    if (direction === 'up') {
      replaceQuestion(dataObj.questions, value, value - 1);
    }

    if (direction === 'down') {
      replaceQuestion(dataObj.questions, value, value + 1);
    }

    setActiveElement(dataObj);
  };

  const deleteQuestion = (value) => {
    const dataObj = { ...activeElement };
    const { questionId } = value;

    const filteredQuestions = dataObj.questions.filter(
      (question) => question.questionId !== questionId);

    dataObj.questions = filteredQuestions;

    setActiveElement(dataObj);
  };

  return (
    <div className="SurveyQuestionsController">
      {questions.map((question, index) => {
        const {
          questionId,
          type,
          answerRequired,
          quizMode,
        } = question;

        const isQuizModeAvailable = (type === ONE_ANSWER) || (type === MULTIPLE_ANSWERS);

        return (
          <div className="SurveyQuestionsController__question-controller" key={questionId}>
            <div className="SurveyQuestionsController__question-controller-header">
              <div className="SurveyQuestionsController__question-controls">
                <div className="SurveyQuestionsController__question-positioning">
                  <button
                    onClick={() => changeQuestionOrder(index, 'up')}
                    disabled={index === 0}
                    type="button"
                    className="
                      SurveyQuestionsController__question-positioning-button
                      SurveyQuestionsController__question-positioning-button--up"
                  />
                  <button
                    onClick={() => changeQuestionOrder(index, 'down')}
                    disabled={index === questions.length - 1}
                    type="button"
                    className="
                      SurveyQuestionsController__question-positioning-button
                      SurveyQuestionsController__question-positioning-button--down"
                  />
                </div>

                <p className="SurveyQuestionsController__question-name">
                  {`Q${index + 1}`}
                </p>

                <SelectInput
                  defaultValue={type}
                  options={surveyQuestionTypes}
                  onChange={(e, selection) => setQuestionType(selection.value, questionId)}
                />
              </div>
              <div className="SurveyQuestionsController__question-answer-toggle-container">
                <div className="SurveyInfoController__toggle-input-wrapper">
                  <Checkbox
                    toggle
                    checked={answerRequired}
                    onClick={(e, data) => activateRequiredAnswerOption(data.checked, questionId)}
                  />
                  <p className="SurveyInfoController__toggle-input-label">Answer required</p>
                </div>
              </div>
            </div>

            <div className="SurveyQuestionsController__question-controller-body">
              {defineQuestionController(type, question, index)}
            </div>

            {isQuizModeAvailable
            && (
              <div className="SurveyQuestionsController__toggle-input-container">
                <Checkbox
                  toggle
                  checked={quizMode}
                  onClick={(e, data) => activateQuizMode(data.checked, questionId)}
                />
                <p className="SurveyQuestionsController__toggle-input-label">Activate quiz mode</p>
              </div>
            )}

            <div className="SurveyQuestionsController__question-controller-footer">

              {!!isMoreThanOneQuestion && (
                <button
                  className="SurveyQuestionsController__question-delete-button"
                  type="button"
                  onClick={() => deleteQuestion(question)}
                >
                  {`Delete Q${index + 1}`}
                </button>
              )}
            </div>
          </div>
      );
    })}
    </div>
  );
};

export default SurveyQuestionsController;
