import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { genericActions } from '../../actions';

const { openConfirmationModal } = genericActions;

const ListItem = ({
  list: {
    id,
    name,
    count,
  },
  changeView,
  handleListDelete,
  openConfirmationModalA,
  currentList,
}) => (
  <div
    className={`contact-lists__content__list-item ${currentList === id ? 'contact-lists__content__list-item--active' : ''}`}
    onClick={() => {
      changeView(id);
    }}
  >
    <div className="contact-lists__content__list-item__title-wrapper">
      <span className="contact-lists__content__list-item__title">
        {name}
      </span>
      <Dropdown
        pointing="top right"
        className="dropdown-list-menu"
        icon="ellipsis vertical"
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => {
            openConfirmationModalA({
              actionName: 'delete',
              text: `Are you sure you want to delete the list ${name}?`,
              callbackFunction: handleListDelete,
              id,
            });
          }}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
    <span className="contact-lists__content__list-item__count">
      {`${count} contacts`}
    </span>
  </div>
);

ListItem.propTypes = {
  list: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    count: PropTypes.number,
  }),
  changeView: PropTypes.func,
  handleListDelete: PropTypes.func,
  openConfirmationModalA: PropTypes.func,
  currentList: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

const mapDispatchToProps = (dispatch) => ({
  openConfirmationModalA: bindActionCreators(openConfirmationModal, dispatch),
});

export default connect(null, mapDispatchToProps)(ListItem);
