import { usersPaginator } from '../paginators';

const users = (state = {}, action) => {
  switch (action.type) {
    default: {
      const { meta } = action;
      const usersPagination = usersPaginator.itemsReducer(state, action);
      if (meta && meta.name === 'users') {
        return usersPagination;
      }
      return state;
    }
  }
};

export default users;
