import { Container } from 'semantic-ui-react';

const PrivacyPolicy = () => (
  <Container className="policy-page">
    <h1>Privacy Policy</h1>
    <p className="last-updated">Updated August 10th, 2023</p>
    <p>
      Rispons International ApS (“us”, “we”, or “our”) operates the
      {' '}
      <a target="_blank" href="http://www.rispons.com" rel="noreferrer">http://www.rispons.com</a>
      {' '}
      website (the “Service”).
    </p>
    <p>
      This page informs you of our policies regarding the collection, use and
      disclosure of Personal Information when using our Service. We will not use
      or share your information with anyone except as described in this Privacy
      Policy.
    </p>
    <p>
      Your Personal Information is used for providing and improving the Service.
      When using the Service, you agree to the collection and use of information
      in accordance with this policy. Unless otherwise defined in this Privacy
      Policy, terms used in this Privacy Policy have the same meanings as in our
      Terms & Conditions, accessible at
      {' '}
      <a target="_blank" href="http://www.rispons.com" rel="noreferrer">http://www.rispons.com</a>
    </p>

    <h2>Information Collection And Use</h2>
    <p>
      While using our Service, we may ask you to provide us with certain
      personally identifiable information that can be used to contact or
      identify you. Personally identifiable information may include, but is not
      limited to, your email address, name, phone number, other information
      (“Personal Information”).
    </p>

    <h2>Cookies</h2>
    <p>
      Cookies are files with small amount of data, which may include an
      anonymous unique identifier. Cookies are sent to your browser from a web
      site and stored on your computer’s hard drive.
    </p>
    <p>
      We use “cookies” to collect information. You can instruct your browser to
      refuse all cookies or to indicate when a cookie is being sent. However, if
      you do not accept cookies, you may not be able to use some parts of our
      Service.
    </p>

    <h2>Log Data</h2>
    <p>
      We collect information that your browser sends whenever you visit our
      Service (“Log Data”). This Log Data may include information such as your
      computer’s Internet Protocol (“IP”) address, browser type, browser
      version, the pages of our Service that you visit, the time and date of
      your visit, the time spent on those pages and other statistics.
    </p>
    <p>
      In addition, we may use third party services such as Google Analytics that
      collect, monitor and analyze this type of information in order to increase
      our Service’s functionality. These third party service providers have
      their own privacy policies addressing how they use such information.
    </p>

    <h2>Service Providers</h2>
    <p>
      We may employ third party companies and individuals to facilitate our
      Service, to provide the Service on our behalf, to perform Service-related
      services or to assist us in analyzing how our Service is used.
    </p>
    <p>
      These third parties have access to your Personal Information only to
      perform these tasks on our behalf and are obligated not to disclose or use
      it for any other purpose.
    </p>

    <h2>Communications</h2>
    <p>
      We may use your Personal Information to contact you with newsletters,
      marketing or promotional materials and other information that may be of
      interest to you. You may opt out of receiving any, or all, of these
      communications from us by following the unsubscribe link or instructions
      provided in any email or digital communication we send.
    </p>

    <h2>Business Transaction</h2>
    <p>
      If Rispons International ApS is involved in a merger, acquisition or asset
      sale, your Personal Information may be transferred. We will provide notice
      before your Personal Information is transferred and becomes subject to a
      different Privacy Policy.
    </p>

    <h2>Compliance With Laws</h2>
    <p>
      We will disclose your Personal Information where required to do so by law
      or subpoena or if we believe that such action is necessary to comply with
      the law and the reasonable requests of law enforcement or to protect the
      security or integrity of our Service.
    </p>

    <h2>International Transfer</h2>
    <p>
      Your information, including Personal Information, may be transferred to —
      and maintained on — computers located outside of your state, province,
      country or other governmental jurisdiction where the data protection laws
      may differ than those from your jurisdiction.
    </p>
    <p>
      If you are located outside Denmark and choose to provide information to
      us, please note that we transfer the information, including Personal
      Information, to Denmark and process it there.
    </p>
    <p>
      Your consent to this Privacy Policy followed by your submission of such
      information represents your agreement to that transfer.
    </p>

    <h2>Security</h2>
    <p>
      The security of your Personal Information is important to us, but remember
      that no method of transmission over the Internet, or method of electronic
      storage is 100% secure. While we strive to use commercially acceptable
      means to protect your Personal Information, we cannot guarantee its
      absolute security.
    </p>

    <h2>Links To Other Sites</h2>
    <p>
      Our Service may contain links to other sites that are not operated by us.
      If you click on a third party link, you will be directed to that third
      party’s site. We strongly advise you to review the Privacy Policy of every
      site you visit.
    </p>
    <p>
      We have no control over, and assume no responsibility for the content,
      privacy policies or practices of any third party sites or services.
    </p>

    <h2>Children&apos;s Privacy</h2>
    <p>Our Service does not address anyone under the age of 13 (“Children”).</p>
    <p>
      We do not knowingly collect personally identifiable information from
      children under 13. If you are a parent or guardian and you are aware that
      your Children has provided us with Personal Information, please contact
      us. If we discover that a Children under 13 has provided us with Personal
      Information, we will delete such information from our servers.
    </p>

    <h2>Changes To This Privacy Policy</h2>
    <p>
      We may update our Privacy Policy from time to time. We will notify you of
      any changes by posting the new Privacy Policy on this page.
    </p>
    <p>
      You are advised to review this Privacy Policy periodically for any
      changes. Changes to this Privacy Policy are effective when they are posted
      on this page.
    </p>

    <h2>Contact Us</h2>
    <p>
      If you have any questions about this Privacy Policy, please contact us by writing
      to
      {' '}
      <a href="mailto:support@rispons.com">support@rispons.com</a>
      .
    </p>
  </Container>
);

export default PrivacyPolicy;
