import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import MediaLibraryContainer from '../../components/MediaLibrary/MediaLibraryContainer';

const MediaSelectModal = ({
  closeModalPortal,
  open,
  size,
  type,
  selectedMedia,
  handleMediaSelect,
}) => (
  <Modal
    open={open}
    size={size}
    onClose={closeModalPortal}
    className="media-library-modal"
  >
    <MediaLibraryContainer
      modal={true}
      type={type}
      selectedMedia={selectedMedia}
      handleMediaSelect={handleMediaSelect}
      close={closeModalPortal}
    />
  </Modal>
);

MediaSelectModal.propTypes = {
  open: PropTypes.bool,
  size: PropTypes.string,
  closeModalPortal: PropTypes.func,
  selectedMedia: PropTypes.object,
  type: PropTypes.string,
  handleMediaSelect: PropTypes.func,
};

export default MediaSelectModal;
