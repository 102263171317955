import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Header, Grid } from 'semantic-ui-react';
import { map, get, isEmpty } from 'lodash';

// Redux paginator
import {
  getCurrentPageResults,
  getCurrentTotalResultsCount,
  getCurrentPageNumber,
  clearPaginatorPromiseArray,
  isCurrentPageFetching,
} from '../../modules/redux-paginator';

// Components
import MessageItemGenerator from '../Item/MessageItemGenerator';
import { MessageDuplicateModal } from '../Modal';
import { TablePager } from '../../components/Pager';
import { IconButton } from '../../components/Button';
import MessageEmpty from '../../components/MessageEmpty';
import MessageFilterEmpty from '../../components/MessageFilterEmpty';
import { Spinner } from '../../components/Spinner';
import MessageFilter from '../../components/Filters/MessageFilter';

// Redux actions.
import { messageActions, accountActions, genericActions } from '../../actions';
import { notifyError } from '../../actions/notificationActions';

// Analytics
import { analyticsDataPost } from '../../services/eventsApi';

// Utils.
import {
  formatMessageHeader,
  formatMessageMainContent,
  formatMessageSecondaryContent,
} from '../../utils/format';

// Constants.
import { DEFAULT_MESSAGES_PAGE_SIZE, PAGE_LIMIT } from '../../common';

// Hooks.
import usePrevious from '../../hooks/usePrevious';

const {
  requestMessagesPage,
  duplicateMessage,
  deleteMessage,
  updateMessage,
  createMessage,
  changeMessageFilter,
  createMessageFilter,
  clearMessages,
} = messageActions;

const { openConfirmationModal, openModalPortal, closeModalPortal } = genericActions;
const { requestModifiedUsersPage, getAccount } = accountActions;

const Messages = ({
  requestModifiedUsersPageA,
  requestAccountA,
  createMessageFilterA,
  messages,
  messagesPaginator,
  usersPaginator,
  users,
  messageFilter,
  closeModalPortalA,
  requestMessagesPageA,
  history,
  updateMessageA,
  deleteMessageA,
  changeMessageFilterA,
  createMessageA,
  notifyErrorA,
  openModalPortalA,
  openConfirmationModalA,
  clearMessagesA,
}) => {
  const [currentPageResults, setCurrentPageResults] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [initial, setInitial] = useState(true);
  const [finalValues, setFinalValues] = useState({});

  const prevPageResults = usePrevious(currentPageResults);

  useEffect(() => {
    requestModifiedUsersPageA(1, PAGE_LIMIT);
    requestAccountA();

    return () => {
      clearMessagesA();
    };
  }, []);

  useEffect(() => {
    const totalUsersCount = getCurrentTotalResultsCount(usersPaginator, 'users');
    const fetchedUsersCount = Object.keys(users).length;
    if (totalUsersCount > fetchedUsersCount) {
      requestModifiedUsersPageA(1, totalUsersCount);
    }

    if (messageFilter) {
      if (isEmpty(messageFilter)) {
        createMessageFilterA();
      } else if (initial) {
        const {
          distributionFilter,
          typeFilter,
          nameFilterValue,
          originFilter,
        } = messageFilter;
        setInitial(false);
        requestPage(
          currentPageNo,
          distributionFilter,
          typeFilter,
          nameFilterValue,
          originFilter,
        );
      }
    }
    setCurrentPageResults(getCurrentPageResults(messages, messagesPaginator, 'messages'));
    setCurrentPageNo(getCurrentPageNumber(messagesPaginator, 'messages'));
    setTotalCount(getCurrentTotalResultsCount(messagesPaginator, 'messages'));
    setLoading(isCurrentPageFetching(messagesPaginator, 'messages'));
  }, [
    messages,
    messagesPaginator,
    requestModifiedUsersPageA,
    usersPaginator,
    users,
    messageFilter,
  ]);

  useEffect(() => {
    if (isEmpty(prevPageResults) && !isEmpty(currentPageResults)) getIDsAndCallAPI();
  }, [currentPageNo, currentPageResults]);

  const handleMessageDuplication = (body, dispatch) => {
    const { id, ...rest } = body;
    const payload = {
      id,
      body: {
        ...rest,
        origin: 'default',
      },
    };

    duplicateMessage(payload, dispatch).then(() => closeModalPortalA());
  };

  const onPageChange = (pageNo) => {
    const {
      distributionFilter,
      typeFilter,
      nameFilterValue,
      originFilter,
    } = messageFilter;
    if (distributionFilter !== 'all' || nameFilterValue !== 'all' || typeFilter !== 'all' || originFilter !== 'all') {
      requestPage(pageNo + 1, distributionFilter, typeFilter, nameFilterValue, originFilter);
    } else {
      requestMessagesPageA(pageNo + 1);
    }
  };

  const onMessageEditClick = (id) => {
    const { push } = history;

    if (validateUnderlyingAction('edit', id)) {
      push({
        pathname: `message-editor/${id}`,
      });
    } else {
      push({
        pathname: `message-editor/${id}/preview`,
      });
    }
  };

  const onMessageFreezeClick = (id, status) => {
    const payload = {
      id,
      body: {
        status: status === 'Frozen' ? 'sent' : 'frozen',
      },
    };

    updateMessageA(payload);
  };

  const onMessageDeleteClick = (id) => {
    deleteMessageA(id);

    if (currentPageResults.length === 1 && currentPageNo !== 1) {
      requestMessagesPageA(currentPageNo - 1);
    }
  };

  const getIDsAndCallAPI = () => {
    const sentIds = currentPageResults.filter((message) => {
      if (message.status !== 'draft') return message.id;
      return false;
    });
    const mappedIds = sentIds.map((message) => message.id);
    const objectOfIds = {
      ids: [...mappedIds],
    };
    return analyticsDataPost(objectOfIds).then(handleResponse);
  };

  const setFilter = (param, value) => {
    const {
      distributionFilter,
      typeFilter,
      nameFilterValue,
      originFilter,
    } = messageFilter;

    if (value) {
      if (param === 'distribution') {
        changeMessageFilterA({
          distributionFilter: value,
          typeFilter,
          nameFilterValue,
          originFilter,
        });
        requestPage(1, value, typeFilter, nameFilterValue, originFilter);
      }
    }
    if (param === 'type') {
      changeMessageFilterA({
        distributionFilter,
        typeFilter: value,
        nameFilterValue,
        originFilter,
      });
      requestPage(1, distributionFilter, value, nameFilterValue, originFilter);
    }
    if (param === 'name') {
      changeMessageFilterA({
        distributionFilter,
        typeFilter,
        nameFilterValue: value,
        originFilter,
      });
      requestPage(1, distributionFilter, typeFilter, value, originFilter);
    }
    if (param === 'origin') {
      changeMessageFilterA({
        distributionFilter,
        typeFilter,
        nameFilterValue,
        originFilter: value,
      });
      requestPage(1, distributionFilter, typeFilter, nameFilterValue, value);
    }
  };

  const setCurtain = () => {
    const {
      distributionFilter,
      typeFilter,
      nameFilterValue,
      originFilter,
    } = messageFilter;

    if (distributionFilter === 'all' && typeFilter === 'all' && nameFilterValue === '' && originFilter === 'all') {
      return <MessageEmpty createMessageA={createMessageA} />;
    }

    return <MessageFilterEmpty />;
  };

  const handleResponse = (res) => {
    const updatedFinalValues = {};
    if (res.error) {
      notifyErrorA(res.error.message);
    } else if (res.response) {
      res.response.forEach((value) => {
        updatedFinalValues[value.id] = { ...value };
      });
      setFinalValues(updatedFinalValues);
    }
  };

  const requestPage = (page, distributionFilter, typeFilter, nameFilterValue, originFilter) => {
    const generalFilterDistribution = distributionFilter !== 'all' ? `&distribution=${distributionFilter}` : '';
    const generalFilterType = typeFilter !== 'all' ? `&type=${typeFilter}` : '';
    const generalFilterName = nameFilterValue !== '' ? `&name=${nameFilterValue}` : '';
    const generalFilterOrigin = originFilter !== 'all' ? `&origin=${originFilter}` : '';
    clearPaginatorPromiseArray();
    requestMessagesPageA(page, `${generalFilterDistribution}${generalFilterType}${generalFilterName}${generalFilterOrigin}`);
  };

  const handleDuplicateModalState = (id = undefined) => {
    const name = get(messages[id], 'name');

    openModalPortalA({
      content: <MessageDuplicateModal />,
      contentProps: {
        handleDuplicateModalState: closeModalPortalA,
        onFormSubmit: handleMessageDuplication,
        initialValues: { name, id },
      },
    });
  };

  const validateUnderlyingAction = (actionName, id) => {
    const message = messages[id];

    if (actionName === 'edit') {
      return ['draft', 'url'].includes(message.status);
    }

    return true;
  };

  const confirmUnderlayingAction = (callbackFunction, actionName, id) => {
    if (validateUnderlyingAction(actionName, id)) {
      openConfirmationModalA({
        actionName,
        callbackFunction,
        itemName: 'message',
        id,
      });
    }
  };

  return (
    <>
      <div className="message-prompt message-prompt--arrow">
        <span>CREATE YOUR NEXT MESSAGE</span>
        <IconButton
          onClick={() => createMessageA({ name: '', origin: 'default', deliveryMethod: 'sms' })}
          iconName="mail_outline"
          text="New Message"
          className="prompt-button"
        />
      </div>
      <div className="messages-container">
        <div className="messages-header-wrapper">
          <Header as="h1" className="messages-header">Messages</Header>
          <MessageFilter
            distributionFilterValue={(val) => setFilter('distribution', val)}
            typeFilterValue={(val) => setFilter('type', val)}
            changeNameFilterValue={(val) => setFilter('name', val)}
            changeOriginFilterValue={(val) => setFilter('origin', val)}
            messageFilter={messageFilter}
          />
        </div>
        <Spinner loaded={!loading && totalCount >= 0 && !isEmpty(messageFilter)}>
          {!totalCount ? (
            setCurtain()
          ) : (
            <Grid className="centered no-spacing message-wrapper">
              {map(currentPageResults, (message, key) => {
                if (message) {
                  return (
                    <MessageItemGenerator
                      key={key}
                      id={message.id}
                      users={users}
                      message={message}
                      header={formatMessageHeader(message)}
                      mainContent={formatMessageMainContent(message, users)}
                      secondaryContent={formatMessageSecondaryContent(message)}
                      finalValues={finalValues}
                      actions={{
                        edit: onMessageEditClick,
                        duplicate: handleDuplicateModalState,
                        freeze: (id, status) => confirmUnderlayingAction(
                          () => onMessageFreezeClick(id, status),
                          status === 'Frozen' ? 'unfreeze' : 'freeze',
                          id,
                        ),
                        delete: (id) => confirmUnderlayingAction(
                          onMessageDeleteClick,
                          'delete',
                          id,
                        ),
                        preview: (id) => onMessageEditClick(id),
                      }}
                    />
                  );
                }
                return null;
              })}
              <Grid.Row centered>
                <div className="pagination-bottom">
                  <TablePager
                    total={totalCount}
                    page={currentPageNo}
                    pageSize={DEFAULT_MESSAGES_PAGE_SIZE}
                    onPageChange={onPageChange}
                  />
                </div>
              </Grid.Row>
            </Grid>
          )}
        </Spinner>
      </div>
    </>
  );
};

Messages.propTypes = {
  messages: PropTypes.object,
  messagesPaginator: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  deleteMessageA: PropTypes.func,
  updateMessageA: PropTypes.func,
  createMessageA: PropTypes.func,
  openConfirmationModalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  openModalPortalA: PropTypes.func,
  requestMessagesPageA: PropTypes.func,
  requestModifiedUsersPageA: PropTypes.func,
  requestAccountA: PropTypes.func,
  users: PropTypes.object,
  usersPaginator: PropTypes.object,
  changeMessageFilterA: PropTypes.func,
  messageFilter: PropTypes.object,
  createMessageFilterA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  clearMessagesA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  messages: state.messages,
  messagesPaginator: state.messagesPaginator,
  users: state.users,
  usersPaginator: state.usersPaginator,
  messageFilter: state.messageFilter,
});

const mapDispatchToProps = (dispatch) => ({
  requestMessagesPageA: bindActionCreators(requestMessagesPage, dispatch),
  requestModifiedUsersPageA: bindActionCreators(requestModifiedUsersPage, dispatch),
  deleteMessageA: bindActionCreators(deleteMessage.request, dispatch),
  updateMessageA: bindActionCreators(updateMessage.request, dispatch),
  createMessageA: bindActionCreators(createMessage.request, dispatch),
  openConfirmationModalA: bindActionCreators(openConfirmationModal, dispatch),
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
  requestAccountA: bindActionCreators(getAccount.request, dispatch),
  changeMessageFilterA: bindActionCreators(changeMessageFilter, dispatch),
  createMessageFilterA: bindActionCreators(createMessageFilter, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  clearMessagesA: bindActionCreators(clearMessages, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Messages));
