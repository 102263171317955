import { TextArea as SemanticTextArea } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const TextArea = ({
  placeholder,
  disabled,
  input,
  label,
  meta: { touched, error },
  onBlur,
  className,
  maxLength = '800',
}) => (
  <div className="field textarea">
    <div className="ui labeled input">
      <div className="ui label label">
        {label}
      </div>
    </div>
    <SemanticTextArea
      {...input}
      component="textarea"
      className={`ris-textarea ${className}`}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={onBlur}
      data-gramm_editor="false"
      maxLength={maxLength}
    />
    <span className="error">
      {touched && error
        && <span>{error}</span>}
    </span>
  </div>
);

TextArea.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  }),
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    touched: PropTypes.bool,
  }),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  maxLength: PropTypes.string,
};

export default TextArea;
