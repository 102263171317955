import { call, takeLatest } from 'redux-saga/effects';

import { requestSaga } from './genericSagas';
import { surveyActions, request } from '../actions';
import { fetchRecipientSurveys } from '../services/surveyApi';

const { REQUEST } = request;

const {
  FETCH_RECIPIENT_SURVEYS,
  getRecipientSurveys,
} = surveyActions;


function* getRecipientSurveySaga(args) {
  yield call(requestSaga, undefined, fetchRecipientSurveys, { ...args }, getRecipientSurveys);
}

export default function* watchSurveys() {
  yield takeLatest(FETCH_RECIPIENT_SURVEYS[REQUEST], getRecipientSurveySaga);
}
