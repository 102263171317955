import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFormSyncErrors, isSubmitting, submit } from 'redux-form';
import { Modal } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { ListAddForm } from '../Form';
import { BasicButton } from '../../components/Button';

const ListCreateModalContent = ({
  submitFormA,
  open,
  handleModalState,
  size,
  formErrors,
  submitting,
  windowWidth,
}) => {
  const onFilterClick = () => {
    submitFormA('addList');
  };

  return (
    <Modal
      open={open}
      className="contact-filter-modal"
      dimmer="inverted"
      size={size}
      closeOnDocumentClick={true}
      onClose={handleModalState}
    >
      <Modal.Header className="contact-modal__header">
        {windowWidth && <div className="close modal-close-icon-for-tablet" onClick={handleModalState} />}
      </Modal.Header>
      <ListAddForm close={handleModalState} />
      <Modal.Actions>
        <BasicButton
          content="Add"
          size="small"
          color="blue"
          disabled={!isEmpty(formErrors) || submitting}
          onClick={onFilterClick}
        />
      </Modal.Actions>
    </Modal>
  );
};

ListCreateModalContent.propTypes = {
  open: PropTypes.bool,
  handleModalState: PropTypes.func,
  size: PropTypes.string,
  formErrors: PropTypes.object,
  submitFormA: PropTypes.func,
  submitting: PropTypes.bool,
  windowWidth: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('addList')(state),
  submitting: isSubmitting('addList')(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitFormA: bindActionCreators(submit, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListCreateModalContent);
