import {
  isValidElement,
  cloneElement,
  memo,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Progress, Message } from 'semantic-ui-react';
import { map, isEqual } from 'lodash';

const StatusBlock = ({
  header,
  body,
  headerWarning = false,
  bodyWarning = false,
  sms: { sms: usedSmsCount, defaultSmsCount } = {},
  quotePending,
  warning,
}) => {
  const RenderedBody = useMemo(() => {
    const type = typeof body;

    if (type === 'string') {
      return (
        <div className="single">
          <span>{body}</span>
        </div>
      );
    }

    if (type === 'object') {
      if (isValidElement(body)) {
        return cloneElement(body);
      }

      return (
        <div className="multiple">
          {map(body, (text, index) => (
            <span
              key={index}
            >
              {text}
            </span>
          ))}
        </div>
      );
    }

    return null;
  }, [body]);

  return (
    <>
      <div className="current-plan">
        <div className="current-plan__type">
          <div
            className={`current-plan__type__header${headerWarning ? ' current-plan__type__header--warning' : ''}`}
          >
            {header}
            {quotePending && (
              <span>Quote for a plan is being processed</span>
            )}
          </div>
          <div
            className={`current-plan__type__footer${bodyWarning ? ' current-plan__type__footer--warning' : ''}`}
          >
            {RenderedBody}
          </div>
        </div>
        {(usedSmsCount && defaultSmsCount) ? (
          <div className="current-plan__sms-status">
            <Progress
              percent={(usedSmsCount / defaultSmsCount) * 100}
            />
            <div className="current-plan__sms-status__text">
              <span>{usedSmsCount}</span>
              {' '}
              out of
              {' '}
              <span>{defaultSmsCount}</span>
              {' '}
              SMS sent
            </div>
          </div>
        ) : null}
      </div>
      {!!warning && (
        <Message
          warning
        >
          <Message.Header>
            <div className="current-plan__warning">
              {warning}
            </div>
          </Message.Header>
        </Message>
      )}
    </>
  );
};

StatusBlock.propTypes = {
  header: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  headerWarning: PropTypes.bool,
  bodyWarning: PropTypes.bool,
  sms: PropTypes.shape({
    sms: PropTypes.number,
    defaultSmsCount: PropTypes.number,
  }),
  quotePending: PropTypes.bool,
  warning: PropTypes.string,
};

const areEqual = (prevProps, nextProps) => prevProps.header === nextProps.header
  && isEqual(prevProps.body, nextProps.body) && prevProps.headerWarning === nextProps.headerWarning
  && prevProps.bodyWarning === nextProps.bodyWarning && isEqual(prevProps.sms, nextProps.sms);

export default memo(StatusBlock, areEqual);
