import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Popup } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import { forEach, map } from 'lodash';
import { BasicButton } from '../../components/Button';
import { automationNodeContactsSet } from '../../services/automationApi';
import {
  contactFilterCreation,
  contactFilterUpdate,
  contactsFetch,
  contactsSearch,
} from '../../services/contactApi';
import { updateContactFilterValues } from '../../actions/contactActions';
import { notifyError } from '../../actions/notificationActions';
import { DATE_TIME } from '../../common';
import { Spinner } from '../../components/Spinner';

const PAGE_SIZE = 50;

const AutomationNodeContactsPopup = ({
  id,
  contactFilterId,
  updateContactFilterValuesA,
  notifyErrorA,
}) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [contactValues, setContactValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [searchLoading, setSearchLoading] = useState(true);

  const contactsGet = (queryParams, search) => {
    automationNodeContactsSet({
      nodeId: id,
      queryParams,
      body: { search },
    }).then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
        setLoading(false);
        setHasMore(false);
        setSearchLoading(false);
      } else {
        const {
          response: {
            elements,
            page: currentPage,
            total,
          },
        } = res;
        if (elements && elements.length) {
          if (!search) {
            const tempHasMore = ((currentPage - 1) * PAGE_SIZE) + elements.length < total;
            setHasMore(tempHasMore);
          }

          const body = {
            ids: elements.map((item) => item.id),
            properties: [
              'contact.name',
              'contact.surname',
            ],
          };

          contactsSearch(body).then((r) => {
            if (r.error) {
              notifyErrorA(r.error.message);
              setLoading(false);
              setHasMore(false);
              setSearchLoading(false);
            } else {
              forEach(r.response.elements, (v, index) => {
                elements[index].fullName = map(v.properties, (name) => name.value);
              });
              if (currentPage === 1 || !!search) {
                setContactValues(elements);
              } else {
                setContactValues((c) => [...c, ...elements]);
              }
              setLoading(false);
              setSearchLoading(false);
            }
          });
        } else {
          setLoading(false);
          setHasMore(false);
          setContactValues([{ id: null, addedAt: null, fullName: ['No data', ''] }]);
          setSearchLoading(false);
        }
      }
    });
  };

  const handleContactsClick = (nextPage = 1) => {
    setLoading(true);
    setPage(nextPage);
    const queryParams = {
      page: nextPage,
      count: PAGE_SIZE,
    };

    contactsGet(queryParams);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    if (searchInputValue) {
      setSearchLoading(true);
      setLoading(true);
      setHasMore(false);
      const filters = [
        {
          operator: 'contains',
          property: 'contact.name',
          value: searchInputValue,
        },
        {
          operator: 'contains',
          property: 'contact.surname',
          value: searchInputValue,
        },
      ];
      const payload = {
        filters,
        conjunction: 'or',
      };

      const callback = (res) => {
        if (res.error) {
          notifyErrorA(res.error.message);
          setLoading(false);
          setHasMore(false);
        } else {
          updateContactFilterValuesA(res.response);
          const additionalParams = {
            filter: res.response.id,
            count: PAGE_SIZE,
            page: 1,
            order: 'asc',
            type: 'default',
            list: 0,
            property: 0,
          };

          const queryParams = {
            page: 1,
            count: PAGE_SIZE,
          };

          contactsFetch(additionalParams).then((res2) => {
            if (res2.error) {
              notifyErrorA(res2.error.message);
              setLoading(false);
              setHasMore(false);
            } else {
              const { elements } = res2.response;
              const ids = elements.map((element) => element.id);
              contactsGet(queryParams, ids);
            }
          });
        }
      };

      if (contactFilterId) {
        payload.id = contactFilterId;
        contactFilterUpdate(payload).then(callback);
      } else {
        contactFilterCreation(payload).then(callback);
      }
    } else {
      handleContactsClick(id, 1);
    }
  };

  const onInputChange = (data) => {
    setSearchInputValue(data.value);
  };

  return (
    <Popup
      on="click"
      pinned
      className="automation-search-popup"
      trigger={(
        <span
          className="automation-node__container__actions__contacts"
          onClick={() => handleContactsClick()}
        />
      )}
    >
      <div>
        <div className="automation-search-popup__header">Current contacts in step</div>
        <div className="automation-search-popup__content">
          <form onSubmit={handleSearchClick}>
            <Input
              className="automation-search-input"
              placeholder="Search user"
              name="search"
              onChange={(e, data) => onInputChange(data)}
              action={(
                <BasicButton
                  content="Search"
                  size="small"
                  type="submit"
                />
              )}
            />
          </form>
          <div className="automation-search-popup__content__container">
            <Spinner
              loaded={!searchLoading}
              options={{
                type: 'heart',
                size: 'small',
                loadedClassName: 'loaded-content',
                notLoadedClassName: 'spinner__content',
              }}
            >
              <InfiniteScroll
                pageStart={1}
                loadMore={() => handleContactsClick(page + 1)}
                hasMore={!loading && hasMore}
                useWindow={false}
                initialLoad={false}
                threshold={400}
              >
                {map(contactValues, (value, index) => (
                  <div className="automation-search-popup__content__contact" key={index}>
                    <div className="automation-search-popup__content__contact__row">
                      {`${value.fullName[0]}${value.fullName[1] ? ` ${value.fullName[1]}` : ''}`}
                    </div>
                    <div className="automation-search-popup__content__contact__row">
                      {value.addedAt ? `Entered step ${moment(value.addedAt).format(DATE_TIME)}` : ''}
                    </div>
                    {value.error && (
                      <Popup
                        trigger={<span className="automation-search-popup__content__contact__error" />}
                        content={value.error}
                        basic
                        size="small"
                      />
                    )}
                  </div>
                ))}
              </InfiniteScroll>
            </Spinner>
          </div>
        </div>
      </div>
    </Popup>
  );
};

AutomationNodeContactsPopup.propTypes = {
  id: PropTypes.number,
  contactFilterId: PropTypes.string,
  updateContactFilterValuesA: PropTypes.func,
  notifyErrorA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  contactFilterId: state.contactFilter.id,
});

const mapDispatchToProps = (dispatch) => ({
  updateContactFilterValuesA: bindActionCreators(updateContactFilterValues, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutomationNodeContactsPopup);
