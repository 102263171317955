import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Dropdown } from 'semantic-ui-react';
import {
  Field,
  Form,
  getFormSyncErrors,
  getFormValues,
  reduxForm,
} from 'redux-form';
import { isEmpty } from 'lodash';
import AddCard from './AddCard';
import { Input } from '../../Field';
import { required } from '../../../utils/validator';
import { BasicButton } from '../../Button';
import { mapCountriesToOptions } from '../../../utils/map';
import countriesNames from '../../../../countriesNames.json';

const BillingInformation = ({
  getSecret,
  sendSecret,
  checkCardValidity,
  handleSubmit,
  onFormSubmit,
  closeModalPortal,
  cardCompleted,
  formErrors,
  loading,
  country,
  handleCountryChange,
  change,
  label,
  notifyErrorA,
}) => (
  <Form onSubmit={handleSubmit(onFormSubmit)}>
    <Grid className="ris-form">
      <Grid.Row>
        <Grid.Column>
          <AddCard
            getSecret={getSecret}
            sendSecret={sendSecret}
            checkCardValidity={checkCardValidity}
            notifyErrorA={notifyErrorA}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column className="centered">
                <Field
                  className="app-form__field"
                  label="Street Address"
                  component={Input}
                  name="billingAddress"
                  size="large"
                  validate={required}
                  placeholder="e.g 8398 Glenridge St."
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column className="centered" computer={8} tablet={8} mobile={8}>
                <Field
                  className="app-form__field"
                  label="City"
                  component={Input}
                  name="city"
                  validate={required}
                  placeholder="e.g Los Angeles"
                />
              </Grid.Column>
              <Grid.Column className="centered billing_margin" computer={8} tablet={8} mobile={8}>
                <Field
                  className="app-form__field"
                  label="State"
                  component={Input}
                  name="state"
                  placeholder="e.g California"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column className="centered" computer={8} tablet={8} mobile={8}>
                <Field
                  className="app-form__field"
                  label="Zip"
                  component={Input}
                  name="zip"
                  validate={required}
                  placeholder="e.g 90038"
                />
              </Grid.Column>
              <Grid.Column className="centered billing_margin" computer={8} tablet={8} mobile={8}>
                <div className="ui labeled input">
                  <div className="ui label">Country</div>
                  <Dropdown
                    search
                    options={mapCountriesToOptions(countriesNames)}
                    onChange={(param, data) => handleCountryChange(data.value, change)}
                    placeholder="Select Country"
                    className="app-form__field"
                    value={country}
                    scrolling
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field
            className="app-form__field"
            label={`Company ID${label}`}
            component={Input}
            name="taxId"
            placeholder="e.g DK12345678"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="billing-actions">
        <BasicButton
          content="Cancel"
          size="small"
          className="white"
          onClick={closeModalPortal}
        />
        <BasicButton
          disabled={!cardCompleted || !isEmpty(formErrors) || loading || !country}
          loading={loading}
          content="Register"
          size="small"
          color="blue"
          className="segmentation-button"
          type="submit"
        />
      </Grid.Row>
    </Grid>
  </Form>
);

BillingInformation.propTypes = {
  getSecret: PropTypes.bool,
  sendSecret: PropTypes.func,
  checkCardValidity: PropTypes.func,
  handleSubmit: PropTypes.func,
  onFormSubmit: PropTypes.func,
  closeModalPortal: PropTypes.func,
  cardCompleted: PropTypes.bool,
  formErrors: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ])),
  loading: PropTypes.bool,
  country: PropTypes.string,
  handleCountryChange: PropTypes.func,
  change: PropTypes.func,
  label: PropTypes.string,
  notifyErrorA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('billing')(state),
  formValues: getFormValues('billing')(state),
});

const validate = (values) => {
  const errors = {};
  if (values.country && values.country === 'Denmark' && !values.taxId) {
    errors.taxId = 'This field is required';
  }
  return errors;
};

const formConfig = {
  form: 'billing',
  destroyOnUnmount: false,
  validate,
};

export default connect(mapStateToProps)(reduxForm(formConfig)(BillingInformation));
