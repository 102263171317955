/* eslint-disable no-shadow */
import React, { useContext, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';

// Context.
import { EditorContext } from '../../../../context/EditorContext';

// Constants.
import { toolbarConfig } from '../../../../constants/toolbarConfig';

// Styles.
import './OptInFooterController.scss';

const OptInFooterController = () => {
  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const { data } = activeElement;

  const {
    data: {
        footer: { content },
      },
  } = activeElement;

  const [editorState, setEditorState] = useState(
    content
      ? EditorState.createWithContent(convertFromRaw(content))
      : EditorState.createEmpty(),
  );


  const onEditorStateChange = (state) => {
    const dataObj = { ...activeElement };
    const contentState = state.getCurrentContent();
    const contentRawState = convertToRaw(contentState);
    dataObj.data.footer.content = contentRawState;

    setActiveElement(dataObj);
    setEditorState(state);
  };

  const setElementVisibility = () => {
    const dataObj = { ...activeElement };
    const { data } = dataObj;

    data.footer.isVisible = !data.footer.isVisible;

    setActiveElement(dataObj);
  };

  return (
    <div className="OptInFooterController">
      <div className="OptInFooterController__header">
        <p className="OptInFooterController__header-title">Footer</p>
        <Checkbox toggle checked={data.footer.isVisible} onClick={setElementVisibility} />
      </div>

      {data.footer.isVisible && (
      <div className="OptInFooterController__text-editor-wrapper">
        <Editor
          editorState={editorState}
          toolbar={toolbarConfig}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          placeholder="Type your text here"
          onEditorStateChange={onEditorStateChange}
          hashtag={{ hashCharacter: '#', separator: ' ' }}
          stripPastedStyles={true}
        />
      </div>
      )}
    </div>
  );
};

export default OptInFooterController;
