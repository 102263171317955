import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  submit,
  getFormSyncErrors,
  getFormValues,
  initialize,
  destroy,
  isSubmitting,
} from 'redux-form';
import { Modal } from 'semantic-ui-react';
import { get, isEmpty } from 'lodash';
import { contactActions, accountActions } from '../../actions';
import { ContactForm } from '../Form';
import { BasicButton } from '../../components/Button';
import { mapContactViewToProperties, mapContactPropertiesToPayload, mapRequiredCustomFields } from '../../utils/map';
import { isArrayCountValid } from '../../utils/validator';
import { contactToListAdd } from '../../services/contactApi';
import { contactsProperties } from '../../common/constants';

const {
  createContact,
  updateContact,
  fetchLists,
  fetchModifiedContactsPage,
} = contactActions;
const { getAccount } = accountActions;

const ContactFormModalContent = ({
  submitFormA,
  handleFormModalState,
  initialContact,
  contactProperties,
  destroyFormA,
  initializeFormA,
  getAccountA,
  list,
  fetchListsA,
  requestModifiedContactViewPageA,
  contactFilter,
  propertyId,
  orderBy,
  formErrors,
  open,
  size,
  submitting,
  formValues,
}) => {
  const [closeModal, setCloseModal] = useState(true);

  const onActionButtonClick = (operation) => {
    switch (operation) {
      case 'saveAndClose': {
        setCloseModal(true);
        submitFormA('contact');
        break;
      }
      case 'saveAndCreateAnother': {
        setCloseModal(false);
        submitFormA('contact');
        break;
      }
      default:
        break;
    }
  };

  const onFormSubmit = (values, dispatch) => {
    const filterId = get(contactFilter, 'id', undefined);
    const properties = mapContactPropertiesToPayload(values, contactProperties);
    if (initialContact) {
      const payload = {
        properties,
        id: initialContact['contact.id'],
        list,
        filterId,
        propertyId,
        orderBy,
      };
      updateContact(payload, dispatch).then(() => {
        handleFormModalState();
      });
    } else {
      const payload = {
        body: { properties },
        list,
        filterId,
        propertyId,
        orderBy,
      };
      createContact(payload, dispatch).then((res) => {
        if (typeof list === 'number') {
          const { id } = res;
          const body = {
            id: list,
            body: {
              ids: [id],
              excluded: [],
            },
          };

          contactToListAdd(body).then(() => {
            fetchListsA();
            requestModifiedContactViewPageA({
              list,
              propertyId,
              orderBy,
            });
          });
        }
        getAccountA();
        if (closeModal) {
          handleFormModalState();
        } else {
          destroyFormA('contact');
          initializeFormA('contact', mapRequiredCustomFields(contactsProperties, contactProperties));
        }
      });
    }
  };

  const checkFormErrors = () => {
    let disabled = false;
    if (!isEmpty(formErrors)) {
      const { properties } = formErrors;
      for (let i = 0; i < properties.length; i += 1) {
        if (properties[i] && (typeof properties[i] === 'object' && !isEmpty(properties[i]))) {
          disabled = true;
          break;
        }
      }
    }
    return disabled;
  };

  return (
    <Modal
      open={open}
      className="contact-modal ris-modal"
      size={size}
      dimmer="inverted"
      onClose={() => handleFormModalState()}
    >
      <Modal.Header className="contact-modal__header ris-modal__header">
        <p>{initialContact ? 'Update contact' : 'Add Contact'}</p>
        <div className="close modal-close-icon" onClick={() => handleFormModalState()} />
      </Modal.Header>
      <Modal.Content className="contact-modal__content ris-modal__content">
        <ContactForm
          onFormSubmit={onFormSubmit}
          initialValues={mapContactViewToProperties(initialContact, contactProperties)}
        />
      </Modal.Content>
      <Modal.Actions className="ris-modal__actions">
        <BasicButton
          content="Cancel"
          size="small"
          className="no-mobile white"
          onClick={() => handleFormModalState()}
        />
        {!initialContact && (
          <BasicButton
            content="Save and Add"
            size="small"
            className="blue invert"
            disabled={checkFormErrors() || submitting}
            onClick={() => onActionButtonClick('saveAndCreateAnother')}
          />
        )}
        <BasicButton
          content={initialContact ? 'Update' : 'Add'}
          size="small"
          color="blue"
          disabled={
            checkFormErrors()
            || !isArrayCountValid(formValues && formValues.properties, 3, 99)
            || submitting
          }
          onClick={() => onActionButtonClick('saveAndClose')}
        />
      </Modal.Actions>
    </Modal>
  );
};

ContactFormModalContent.propTypes = {
  contactProperties: PropTypes.object,
  open: PropTypes.bool,
  handleFormModalState: PropTypes.func,
  submitFormA: PropTypes.func,
  destroyFormA: PropTypes.func,
  initializeFormA: PropTypes.func,
  formErrors: PropTypes.object,
  initialContact: PropTypes.shape({
    id: PropTypes.number,
    'contact.id': PropTypes.number,
  }),
  formValues: PropTypes.shape({
    properties: PropTypes.array,
  }),
  size: PropTypes.string,
  submitting: PropTypes.bool,
  getAccountA: PropTypes.func,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  fetchListsA: PropTypes.func,
  requestModifiedContactViewPageA: PropTypes.func,
  contactFilter: PropTypes.object,
  propertyId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  orderBy: PropTypes.string,
};

const mapStateToProps = (state) => ({
  contactProperties: state.entities.contactProperties,
  formErrors: getFormSyncErrors('contact')(state),
  formValues: getFormValues('contact')(state),
  submitting: isSubmitting('contact')(state),
  contactFilter: state.contactFilter,
});

const mapDispatchToProps = (dispatch) => ({
  submitFormA: bindActionCreators(submit, dispatch),
  destroyFormA: bindActionCreators(destroy, dispatch),
  initializeFormA: bindActionCreators(initialize, dispatch),
  getAccountA: bindActionCreators(getAccount.request, dispatch),
  fetchListsA: bindActionCreators(fetchLists.request, dispatch),
  requestModifiedContactViewPageA: bindActionCreators(fetchModifiedContactsPage.request, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormModalContent);
