import { callApi } from './callApi';
import { getUrlProperty } from '../utils/helper';

const call = (endpoint, params, _schema) => callApi({ serviceName: 'nodetree', endpoint }, params, _schema);

export const automationListGet = (url) => {
  let pathTemplate = getUrlProperty('pathname', url);
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);

  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};

export const automationItemCreate = (body) => call('/root', {
  method: 'POST',
  body,
}, null);

export const automationItemGet = (id) => call(`/root/${id}`, {
  method: 'GET',
}, null);

export const automationItemUpdate = ({ id, body }) => call(`/root/${id}`, {
  method: 'PUT',
  body,
}, null);

export const automationItemDelete = (id) => call(`/root/${id}`, {
  method: 'DELETE',
}, null);

export const automationItemValidate = (id) => call(`/root/${id}/validate`, {
  method: 'GET',
}, null);

export const automationItemStatusSet = ({ id, status }) => call(`/root/${id}/${status}`, {
  method: 'POST',
}, null);

export const automationItemIssuesGet = (id) => call(`/root/${id}/issues`, {
  method: 'GET',
}, null);

export const automationItemNodesGet = (id) => call(`/root/${id}/nodes`, {
  method: 'GET',
}, null);

export const automationNodeCreate = (body) => call('/node', {
  method: 'POST',
  body,
}, null);

export const automationNodeUpdate = ({ id, body }) => call(`/node/${id}`, {
  method: 'PUT',
  body,
}, null);

export const automationNodeContactsSet = ({
  nodeId,
  queryParams,
  body = { search: [] },
}) => call(`/node/${nodeId}/objects`, {
  method: 'POST',
  body,
  additionalParams: { queryParams },
}, null);


export const automationNodeContactsSearch = (body) => {
  call('/contacts/search', {
    method: 'POST',
    body,
  }, null);
};

export const automationRootNode = ({ id, body }) => call(`/root/${id}/attach/node`, {
  method: 'POST',
  body,
}, null);

export const automationNodeAttach = ({ id, body }) => call(`/node/${id}/attach`, {
  method: 'POST',
  body,
}, null);

export const automationNodeDetach = ({ id, body }) => call(`/node/${id}/detach`, {
  method: 'POST',
  body,
}, null);

export const automationNodeDelete = (id) => call(`/node/${id}`, {
  method: 'DELETE',
}, null);

export const automationFilterNodeDelete = ({ id, branch }) => call(`/node/${id}/${branch}`, {
  method: 'DELETE',
}, null);

export const automationBranchDelete = (id) => call(`/branch/${id}`, {
  method: 'DELETE',
}, null);
