import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { orderBy, isEmpty } from 'lodash';
import { bindActionCreators } from 'redux';
import { Divider } from 'semantic-ui-react';
import { withRouter } from 'react-router';

import {
  PlansPeriodSelection,
  PlansDesktop,
  PlansMobile,
  DisplayStatus,
} from '.';
import { PlanSubscriptionModal } from '../../../containers/Modal';
import { Spinner } from '../../Spinner';
import {
  fetchPlansWeights,
  fetchPlans,
  cancelPlan,
  subscribeToPlan,
} from '../../../services/planApi';
import {
  planActions,
  genericActions,
} from '../../../actions';
import { notifyError, notifySuccess } from '../../../actions/notificationActions';
import { openConfirmationModal } from '../../../actions/genericActions';
import { transformPlans, getPlanStatus } from '../../../utils/plans';

const { openModalPortal, closeModalPortal } = genericActions;
const { setPlans, getSession, getCountries } = planActions;

const Plans = ({
  getSessionA,
  fetchCountriesA,
  setPlansA,
  notifyErrorA,
  notifySuccessA,
  openConfirmationModalA,
  openModalPortalA,
  closeModalPortalA,
  plans,
  session,
}) => {
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState('month');
  const [showDesktopPlans, setShowDesktopPlans] = useState(window.innerWidth > 1399);

  useEffect(() => {
    handlePlans();

    fetchCountriesA();
    getSessionA();

    window.addEventListener('resize', () => resize());
    resize();

    return () => {
      window.removeEventListener('resize', () => resize());
    };
  }, []);

  const resize = () => {
    if (!showDesktopPlans && window.innerWidth > 1399) setShowDesktopPlans(true);
    if (showDesktopPlans && window.innerWidth < 1400) setShowDesktopPlans(false);
  };

  const changePaymentType = (updatedPaymentType) => {
    setPaymentType(updatedPaymentType);
  };

  const handlePlans = () => Promise.all([
      fetchPlans(),
      fetchPlansWeights(),
    ]).then((res) => {
      const fetchedPlans = res[0].response;
      const weights = orderBy(res[1].response, ['weight'], ['desc']);

      if (fetchedPlans && weights) {
        setPlansA(transformPlans(fetchedPlans, weights));
      }
    });

  const handlePlanCancellation = () => {
    setLoading(true);

    cancelPlan()
      .then((res) => {
        const { error } = res;

        if (error) {
          notifyErrorA(error.message);
        } else {
          getSessionA();
          notifySuccessA('Plan cancelled successfully');
        }

        setLoading(false);
      });
  };

  const handlePlanCancelClick = () => {
    openConfirmationModalA({
      text: 'Are you sure you want to cancel your plan?',
      callbackFunction: handlePlanCancellation,
      actionName: 'cancel plan',
    });
  };

  const openPlanSubscriptionModal = (plan) => {
    openModalPortalA({
      content: <PlanSubscriptionModal />,
      contentProps: {
        plan,
        closeModalPortal: closeModalPortalA,
        handlePlanSubscription,
      },
    });
  };

  const handlePlanSubscription = (planName) => {
    setLoading(true);

    subscribeToPlan({
      product: planName,
      period: paymentType,
    }).then((res) => {
      setLoading(false);

      if (res.error) {
        notifyErrorA(res.error.message);
      } else {
        getSessionA();
        notifySuccessA('Plan subscribed successfully');
      }
    });
  };

    const planStatus = getPlanStatus(session);

    if (isEmpty(plans.month) || isEmpty(plans.year)) {
      return (
        <Spinner loaded={false} />
      );
    }

    const componentProps = {
      plans: plans[paymentType],
      openPlanSubscriptionModal,
    };

    return (
      <Spinner loaded={!loading}>
        <div>
          <DisplayStatus session={session} />
          <Divider />
          <div className={`ris-pricing-container${showDesktopPlans ? '' : '-mobile'}`}>
            <PlansPeriodSelection
              paymentType={paymentType}
              setPaymentType={changePaymentType}
            />
            {showDesktopPlans ? (
              <PlansDesktop {...componentProps} />
            ) : (
              <PlansMobile {...componentProps} />
            )}
          </div>
          <Divider />
          {planStatus === 'active_plan' ? (
            <div
              className="plan-cancel"
              onClick={() => handlePlanCancelClick()}
            >
              Cancel subscription
            </div>
          ) : null}
        </div>
      </Spinner>
    );
};

Plans.propTypes = {
  setPlansA: PropTypes.func,
  plans: PropTypes.shape({
    month: PropTypes.array,
    year: PropTypes.array,
  }),
  openConfirmationModalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  openModalPortalA: PropTypes.func,
  getSessionA: PropTypes.func,
  fetchCountriesA: PropTypes.func,
  session: PropTypes.object,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  setPlansA: bindActionCreators(setPlans, dispatch),
  fetchCountriesA: bindActionCreators(getCountries.request, dispatch),
  getSessionA: bindActionCreators(getSession.request, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  openConfirmationModalA: bindActionCreators(openConfirmationModal, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
});

const mapStateToProps = (state) => ({
  plans: state.plans.plans || {},
  session: state.plans.session,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Plans));
