import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFormSyncErrors, isSubmitting, submit } from 'redux-form';
import { Popup } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { BasicButton } from '../../components/Button';
import { ContactFilterForm } from '../Form';

const ContactFilterPopup = ({
  open,
  trigger,
  handleFilterPopupState,
  formErrors,
  submitting,
  changeFilter,
  list,
  clearSort,
  submitFormA,
}) => {
  const onFilterClick = () => {
    submitFormA('contactFilter');
  };

  const additionalClassName = document.getElementsByClassName('low-warning').length > 0 ? ' popup-filter--with-warning' : '';

  return (
    <Popup
      trigger={trigger}
      on="click"
      open={open}
      onClose={handleFilterPopupState}
      onOpen={handleFilterPopupState}
      position="top left"
      basic={true}
      size="tiny"
      offset={[-700, 0]}
      style={{ minWidth: '700px' }}
      className={`popup-filter${additionalClassName}`}
    >
      <Popup.Content>
        <ContactFilterForm changeFilter={changeFilter} list={list} clearSort={clearSort} />
      </Popup.Content>
      <div className="contact-filter-form__actions">
        <BasicButton
          content="Cancel"
          size="small"
          color="white"
          inverted
          onClick={handleFilterPopupState}
        />
        <BasicButton
          content="Filter"
          size="small"
          color="blue"
          disabled={!isEmpty(formErrors) || submitting}
          onClick={onFilterClick}
        />
      </div>
    </Popup>
  );
};

ContactFilterPopup.propTypes = {
  open: PropTypes.bool,
  handleFilterPopupState: PropTypes.func,
  formErrors: PropTypes.object,
  submitFormA: PropTypes.func,
  trigger: PropTypes.object,
  submitting: PropTypes.bool,
  changeFilter: PropTypes.func,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  clearSort: PropTypes.func,
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('contactFilter')(state),
  submitting: isSubmitting('contactFilter')(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitFormA: bindActionCreators(submit, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactFilterPopup);
