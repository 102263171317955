import { cloneElement } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const ModalPortal = ({ properties }) => (
  properties.content
    ? cloneElement(properties.content, {
      ...properties.contentProps,
      open: properties.open,
      size: properties.size,
    }) : <div />
);

ModalPortal.propTypes = {
  properties: PropTypes.object,
};

const mapStateToProps = (state) => ({
  properties: state.modalPortal,
});

export default connect(mapStateToProps)(ModalPortal);
