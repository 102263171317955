import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { clientSecretGet } from '../../../services/accountsApi';

const Checkout = ({
  getSecret,
  checkCardValidity,
  sendSecret,
  stripe,
  notifyErrorA,
}) => {
  const [sent, setSent] = useState(false);
  const element = useRef(null);

  useEffect(() => {
    if (!sent && getSecret) {
      setSent(true);
      handleGetSecret();
    }
  }, [getSecret]);

  const handleGetSecret = () => {
    clientSecretGet().then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
      } else {
        const { response: { clientSecret } } = res;
        stripe.handleCardSetup(clientSecret, element.current).then((result) => {
          if (result.error) {
            notifyErrorA(result.error.message);
          } else {
            sendSecret(clientSecret);
          }
        });
      }
    });
  };

  const handleChange = (event) => {
    if (event.complete) {
      checkCardValidity();
    }
  };

  const handleReady = (e) => {
    element.current = e;
  };

  return (
    <>
      <div className="registration__card-flex">
        <span className="registration__card-flex--input">
          <CardElement
            id="stripe-form"
            onChange={(e) => handleChange(e)}
            onReady={handleReady}
          />
        </span>
      </div>
    </>
  );
};

Checkout.propTypes = {
  stripe: PropTypes.shape({
    handleCardSetup: PropTypes.func,
  }),
  getSecret: PropTypes.bool,
  sendSecret: PropTypes.func,
  checkCardValidity: PropTypes.func,
  notifyErrorA: PropTypes.func,
};

export default injectStripe(Checkout);
