import { createFormAction } from 'redux-form-saga';
import { contactViewPaginator } from '../paginators';
import { createRequestTypes } from './request';
import { ENV } from '../config';
import { DEFAULT_PAGE_SIZE } from '../containers/Table';
import { setContactsType, setListId } from '../utils/helper';

export const CLEAR_CONTACT_FILTER = 'CLEAR_CONTACT_FILTER';
export const PREPROCESS_CONTACTS_LIST_REMOVE = 'PREPROCESS_CONTACTS_LIST_REMOVE';
export const UPDATE_LIST_NAME = 'UPDATE_LIST_NAME';
export const DELETE_LIST = 'DELETE_LIST';
export const DELETE_PROPERTY = 'DELETE_PROPERTY';
export const UPDATE_PROPERTY_LABEL = 'UPDATE_PROPERTY_LABEL';
export const ADD_VIEW = 'ADD_VIEW';
export const UPDATE_CONTACT_FILTER_VALUES = 'UPDATE_CONTACT_FILTER_VALUES';

export const CREATE_CONTACT_TAG = createRequestTypes('CREATE_CONTACT_TAG');
export const DELETE_CONTACT = createRequestTypes('DELETE_CONTACT');
export const FETCH_CONTACT_PROPERTIES = createRequestTypes('CONTACT_PROPERTIES');
export const FETCH_TAGS = createRequestTypes('FETCH_TAGS');
export const MUTATE_CONTACT_TAG = createRequestTypes('MUTATE_CONTACT_PROPERTIES');
export const PREPROCESS_CONTACTS_LIST = createRequestTypes('PREPROCESS_CONTACTS_LIST');
export const FETCH_MODIFIED_CONTACTS = createRequestTypes('FETCH_MODIFIED_CONTACTS');
export const SET_CONTACTS_PAGE_SIZE = createRequestTypes('SET_CONTACTS_PAGE_SIZE');
export const FETCH_LISTS = createRequestTypes('FETCH_LISTS');
export const FETCH_VIEWS = createRequestTypes('FETCH_VIEWS');

export const createContact = createFormAction('CREATE_CONTACT');

export const updateContact = createFormAction('UPDATE_CONTACT');

export const importContactList = createFormAction('IMPORT_CONTACT_LIST');

export const createContactFilter = createFormAction('CONTACT_FILTER_CREATE');

export const updateContactFilter = createFormAction('CONTACT_FILTER_UPDATE');

const { API_ENV } = ENV;

const action = (type, payload = {}) => ({ type, ...payload });

export const fetchContactProperties = {
  request: () => action(FETCH_CONTACT_PROPERTIES.REQUEST),
  success: (response) => action(FETCH_CONTACT_PROPERTIES.SUCCESS, { response }),
  failure: (error) => action(FETCH_CONTACT_PROPERTIES.FAILURE, { error }),
};

export const fetchTags = {
  request: () => action(FETCH_TAGS.REQUEST),
  success: (response) => action(FETCH_TAGS.SUCCESS, { response }),
  failure: (error) => action(FETCH_TAGS.FAILURE, { error }),
};

export const requestContactsPage = (
  pageNo = 1,
  f = '',
  pageSize = DEFAULT_PAGE_SIZE,
  list = 'all',
  propertyId = 0,
  orderBy = 'desc',
) => {
  const filter = f ? `&filter=${f}` : '';
  const sort = `&property=${propertyId}&order=${orderBy}`;
  const listId = setListId(list);
  const type = setContactsType(list);

  return contactViewPaginator.contact.requestModifiedPage(
    pageNo,
    `contact/${API_ENV}/contacts?count=${pageSize}${filter}&list=${listId}${sort}&type=${type}`,
  );
};
/**
 *
 * @param pageNo - page number
 * @param f - filter
 * @param pageSize -
 * @param list - name or id of list
 * @param propertyId - id of property to sort
 * @param orderBy - whether to sort in ascending or descending order
 * @returns {*}
 */
export const requestModifiedContactViewPage = (
  pageNo = 1,
  f = '',
  pageSize = DEFAULT_PAGE_SIZE,
  list = 'all',
  propertyId = 0,
  orderBy = 'desc',
) => {
  const filter = f ? `&filter=${f}` : '';
  const sort = `&property=${propertyId}&order=${orderBy}`;
  const listId = setListId(list);
  const type = setContactsType(list);

  return contactViewPaginator.contact.requestModifiedPage(
    pageNo,
    `contact/${API_ENV}/contacts?count=${pageSize}${filter}&list=${listId}${sort}&type=${type}`,
  );
};

export const mutateContactTag = {
  request: (body) => action(MUTATE_CONTACT_TAG.REQUEST, body),
  success: (response, payload) => action(MUTATE_CONTACT_TAG.SUCCESS, { response, payload }),
  failure: (error) => action(MUTATE_CONTACT_TAG.FAILURE, { error }),
};

export const deleteContact = {
  request: (body) => action(DELETE_CONTACT.REQUEST, body),
  success: (response, payload) => action(DELETE_CONTACT.SUCCESS, { response, payload }),
  failure: (error) => action(DELETE_CONTACT.FAILURE, { error }),
};

export const preprocessContactList = {
  request: (body) => action(PREPROCESS_CONTACTS_LIST.REQUEST, body),
  success: (response) => action(PREPROCESS_CONTACTS_LIST.SUCCESS, { response }),
  failure: (error) => action(PREPROCESS_CONTACTS_LIST.FAILURE, { error }),
};

export const fetchModifiedContactsPage = {
  request: (body) => action(FETCH_MODIFIED_CONTACTS.REQUEST, body),
  success: (response) => action(FETCH_MODIFIED_CONTACTS.SUCCESS, { response }),
  failure: (error) => action(FETCH_MODIFIED_CONTACTS.FAILURE, { error }),
};

export const fetchLists = {
  request: () => action(FETCH_LISTS.REQUEST),
  success: (response) => action(FETCH_LISTS.SUCCESS, { response }),
  failure: (error) => action(FETCH_LISTS.FAILURE, { error }),
};

export const fetchViews = {
  request: () => action(FETCH_VIEWS.REQUEST),
  success: (response) => action(FETCH_VIEWS.SUCCESS, { response }),
  failure: (error) => action(FETCH_VIEWS.FAILURE, { error }),
};

export const clearPreprocessingValues = () => action(PREPROCESS_CONTACTS_LIST_REMOVE);

export const clearContactFilter = (body) => action(CLEAR_CONTACT_FILTER, body);

export const setPageSize = (val) => ({
  type: SET_CONTACTS_PAGE_SIZE,
  payload: val,
});

export const updateListName = (body) => action(UPDATE_LIST_NAME, body);

export const deleteList = (body) => action(DELETE_LIST, body);

export const deleteProperty = (body) => action(DELETE_PROPERTY, body);

export const updatePropertyLabel = (body) => action(UPDATE_PROPERTY_LABEL, body);

export const addView = (body) => action(ADD_VIEW, body);

export const updateContactFilterValues = (body) => action(UPDATE_CONTACT_FILTER_VALUES, body);
