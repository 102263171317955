import { capitalize } from 'lodash';
import { Popup, Grid } from 'semantic-ui-react';
import { ColumnComponent } from './index';
import { roles } from '../../common';

function findActions({ onBlockClick, onDeleteClick }, param, userId) {
  if (param === 'userAccess') {
    return ({
      Header: 'Actions',
      accessor: 'id',
      sortable: false,
      Cell: (row) => (
        <ColumnComponent>
          {row.original.role !== 'root' && row.original.id !== userId && (
            <div>
              <i className="material-icons" onClick={() => onBlockClick(row.value)}>remove_circle</i>
              <i className="material-icons" onClick={() => onDeleteClick(row.value)}>delete</i>
            </div>
          )}
        </ColumnComponent>
      ),
    });
  }
  return 0;
}

export const UserAccessCols = ({ onBlockClick = '', onDeleteClick, onRoleChange }, page, userId) => ([
  {
    Header: 'Full name',
    accessor: 'fullName',
    Cell: (row) => (
      <ColumnComponent>
        <span>{row.value}</span>
      </ColumnComponent>
    ),
  },
  {
    Header: 'Account',
    accessor: 'email',
    Cell: (row) => (
      <ColumnComponent>
        <span>{row.value}</span>
      </ColumnComponent>
    ),
  },
  {
    Header: 'Phone number',
    accessor: 'phone',
    Cell: (row) => (
      <ColumnComponent>
        <span>{row.value}</span>
      </ColumnComponent>
    ),
  },
  {
    Header: 'Role',
    accessor: 'role',
    Cell: (row) => {
      if (row.value === 'root') {
        return <span>Main admin</span>;
      }
      return (
        <span>
          {page === 'userAccess' ? (
            <Popup
              trigger={<span className="user-role">{row.value}</span>}
              on="click"
              size="mini"
            >
              <Grid>
                {roles.map((role, index) => (
                  <Grid.Row
                    key={index}
                    onClick={() => onRoleChange(row.original.id, role.value)}
                    className={`role-option ${role.value === row.value && 'active'}`}
                  >
                    <p className="role-option">{role.text}</p>
                  </Grid.Row>
                ))}
              </Grid>
            </Popup>
          ) : (
            capitalize(row.value)
          )}
        </span>
      );
    },
  },
  {
    Header: 'Activity',
    accessor: 'block',
    Cell: (row) => {
      if (row.value) {
        return (
          <ColumnComponent>
            <span>Blocked</span>
          </ColumnComponent>
        );
      }
      return (
        <ColumnComponent>
          <span>{row.original.status === 'pending' ? 'Pending' : 'Active'}</span>
        </ColumnComponent>
      );
    },
  },
  {
    Header: 'Sent messages',
    accessor: 'sentMessages',
    Cell: (row) => (
      <ColumnComponent>
        <span>{row.value}</span>
      </ColumnComponent>
    ),
  },
  findActions({ onBlockClick, onDeleteClick }, page, userId),
]);
