import PropTypes from 'prop-types';
import { Accordion } from 'semantic-ui-react';
import StatsBlock from '../Stats/StatsBlock';
import Donut from '../Chart/Donut';

const ButtonInfo = ({
  clickRate,
  numOfClicks,
  numOfUniqueClicks,
  activeButtonIndex,
  onClick,
  index,
  innerText,
}) => (
  <Accordion fluid styled className="statistics-accordion">
    <Accordion.Title active={activeButtonIndex[index]} onClick={onClick}>
      {innerText}
    </Accordion.Title>
    <Accordion.Content active={activeButtonIndex[index]} className="message-statistics">
      <div className="overall-statistics__segment-container">
        <div className="overall-statistics__box overall-statistics__segment">
          <div className="overall-statistics__box__header">Clicks</div>
          <div className="overall-statistics__box__content">
            <StatsBlock
              title="Playbacks"
              stats={numOfClicks}
              color="blue"
            />
          </div>
        </div>
        <div className="overall-statistics__box overall-statistics__segment">
          <div className="overall-statistics__box__header">Click rate</div>
          <div className="overall-statistics__box__content">
            {numOfUniqueClicks !== 0 ? (
              <Donut
                label={[
                  'Unique Clicks',
                  'Did Not Click',
                ]}
                percent={clickRate * 100}
                borderColor="#fff"
                data={[
                  numOfUniqueClicks, (numOfUniqueClicks / clickRate) - numOfUniqueClicks,
                ]}
                backgroundColor={[
                  '#1cb59f',
                  '#e0e0e0',
                ]}
                not={numOfClicks - numOfUniqueClicks}
              />
              ) : (
                <div className="overall-statistics__box__content--disabled">
                  No data yet
                </div>
              )}
          </div>
        </div>
      </div>
    </Accordion.Content>
  </Accordion>
);

ButtonInfo.propTypes = {
  activeButtonIndex: PropTypes.array,
  onClick: PropTypes.func,
  numOfClicks: PropTypes.number,
  numOfUniqueClicks: PropTypes.number,
  clickRate: PropTypes.number,
  index: PropTypes.number,
  innerText: PropTypes.string,
};

export default ButtonInfo;
