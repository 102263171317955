import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import { ForgotPasswordForm } from '../Form';
import { getUrlProperty } from '../../utils/helper';
import ResetPasswordForm from '../Form/ResetPasswordForm';

const ForgotPassword = ({ history }) => {
  const query = getUrlProperty('query');
  if (query.email && query.lost) {
    return (
      <Grid>
        <Grid.Column className="ris-form">
          <Grid.Row>
            <Header size="large" className="app-form__header centered">
              Reset your password?
            </Header>
          </Grid.Row>
          <ResetPasswordForm history={history} invert={false} />
        </Grid.Column>
      </Grid>
    );
  }
  return (
    <Grid>
      <Grid.Column className="ris-form">
        <Grid.Row>
          <Header size="large" className="app-form__header centered">
            Forgot your password?
          </Header>
          <Header size="small" className="forgot-text centered">
            Enter your email, which was registered to your account.
            <br />
            We will send it to this address shortly
          </Header>
        </Grid.Row>
        <ForgotPasswordForm />
      </Grid.Column>
    </Grid>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default ForgotPassword;
