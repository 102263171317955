import { Container } from 'semantic-ui-react';

const TermsAndConditions = () => (
  <Container className="policy-page">
    <h1>Terms & Conditions</h1>
    <p className="last-updated">Updated August 10th, 2023</p>
    <p className="read-carefully">
      Please read carefully before using the
      {' '}
      <a target="_blank" href="http://www.rispons.com" rel="noreferrer">http://www.rispons.com</a>
      {' '}
      website (the
      “Service”)
    </p>
    <p>
      Upon acceptance of and compliance with thise terms, you may access and use
      the Service operated by Rispons International ApS (“us”, “we”, or “our”)
    </p>
    <p>
      The Terms apply to all visitors, users and others who access or use the
      Service. By accessing or using the Service you agree to abide by these
      Terms. If you disagree with any part of the terms you may not access the
      Service.
    </p>

    <h2>Accounts</h2>
    <p>
      When creating an account with us, you must provide us information that is
      accurate, complete, and current at all times. Failure to do so constitutes
      a breach of the Terms, which may result in immediate termination of your
      account on our Service.
    </p>
    <p>
      You are responsible for safeguarding the password that you use to access
      the Service and for any activities or actions under your password, whether
      your password is with our Service or a third-party service.
    </p>
    <p>
      You agree not to disclose your password to any third party. You must
      notify us immediately upon becoming aware of any breach of security or
      unauthorised use of your account.
    </p>

    <h2>Content</h2>
    <p>
      The Service allows you to post, link, store, share and otherwise make
      available certain information, text, graphics, videos, or other material
      (“Content”). You are responsible for the Content that you post to the
      Service, including its legality, reliability, and appropriateness.
    </p>
    <p>
      By posting Content to the Service, you grant us the right and license to
      use, modify, publicly perform, publicly display, reproduce, and distribute
      such Content on and through the Service. You retain any and all of your
      rights to any Content you submit, post or display on or through the
      Service and you are responsible for protecting those rights.
    </p>
    <p>
      You represent and warrant that: (i) the Content is owned by you or you
      have the right to use it and grant us the rights and license as provided
      in these Terms, and (ii) the posting of your Content on or through the
      Service does not violate the privacy rights, publicity rights, copyrights,
      contract rights or any other rights of any person.
    </p>

    <h2>Links To Other Web Sites</h2>
    <p>
      Our Service may contain links to third-party web sites or services that
      are not owned or controlled by Rispons International ApS.
    </p>
    <p>
      Rispons International ApS assumes no responsibility for, the content,
      privacy policies, or practices of any third party web sites or services.
      You further acknowledge and agree that Rispons International ApS shall not
      be responsible or liable, directly or indirectly, for any damage or loss
      caused or alleged to be caused by or in connection with use of or reliance
      on any such content, goods or services available on or through any such
      web sites or services.
    </p>
    <p>
      We strongly advise you to read the terms and conditions and privacy
      policies of any third-party web sites or services that you visit.
    </p>

    <h2>Intellectual Property</h2>
    <p>
      The Service and its original content (excluding Content provided by
      users), features and functionality are and will remain the exclusive
      property of Rispons International ApS and its licensors. The Service is
      protected by copyright, trademark, and other laws of both the Denmark and
      foreign countries. Our trademarks and trade dress may not be used in
      connection with any product or service without the prior written consent
      of Rispons International ApS.
    </p>

    <h2>Copyright Policy</h2>
    <p>
      We respect the intellectual property rights of others. It is our policy to
      respond to any claim that Content posted on the Service infringes the
      copyright or other intellectual property infringement (“Infringement”) of
      any person.
    </p>
    <p>
      If you are a copyright owner, or authorised on behalf of one, and you
      believe that the copyrighted work has been copied in a way that
      constitutes copyright infringement that is taking place through the
      Service, you must submit your notice in writing to the attention of
      “Copyright Infringement” of
      {' '}
      <a href="mailto:support@rispons.com">support@rispons.com</a>
      {' '}
      and include in your notice
      a detailed description of the alleged Infringement.
    </p>
    <p>
      You may be held accountable for damages (including costs and attorneys’
      fees) for misrepresenting that any Content is infringing your copyright.
    </p>

    <h2>Limitation Of Liability</h2>
    <p>
      In no event shall Rispons International ApS, nor its directors, employees,
      partners, agents, suppliers, or affiliates, be liable for any indirect,
      incidental, special, consequential or punitive damages, including without
      limitation, loss of profits, data, use, goodwill, or other intangible
      losses, resulting from (i) your access to or use of or inability to access
      or use the Service; (ii) any conduct or content of any third party on the
      Service; (iii) any content obtained from the Service; and (iv)
      unauthorised access, use or alteration of your transmissions or content,
      whether based on warranty, contract, tort (including negligence) or any
      other legal theory, whether or not we have been informed of the
      possibility of such damage, and even if a remedy set forth herein is found
      to have failed of its essential purpose.
    </p>

    <h2>Disclaimer</h2>
    <p>
      Your use of the Service is at your sole risk. The Service is provided on
      an “AS IS” and “AS AVAILABLE” basis. The Service is provided without
      warranties of any kind, whether express or implied, including, but not
      limited to, implied warranties of merchantability, fitness for a
      particular purpose, non-infringement or course of performance.
    </p>
    <p>
      Rispons International ApS its subsidiaries, affiliates, and its licensors
      do not warrant that a) the Service will function uninterrupted, secure or
      available at any particular time or location; b) any errors or defects
      will be corrected; c) the Service is free of viruses or other harmful
      components; or d) the results of using the Service will meet your
      requirements.
    </p>

    <h2>Governing Law</h2>
    <p>
      These Terms shall be governed and construed in accordance with the laws of
      Denmark, without regard to its conflict of law provisions.
    </p>
    <p>
      Our failure to enforce any right or provision of these Terms will not be
      considered a waiver of those rights. If any provision of these Terms is
      held to be invalid or unenforceable by a court, the remaining provisions
      of these Terms will remain in effect. These Terms constitute the entire
      agreement between us regarding our Service, and supersede and replace any
      prior agreements we might have between us regarding the Service.
    </p>
    <p>
      We reserve the right, at our sole discretion, to modify or replace these
      Terms at any time. If a revision is material we will try to provide at
      least 30 days notice prior to any new terms taking effect. What
      constitutes a material change will be determined at our sole discretion.
      By continuing to access or use our Service after those revisions become
      effective, you agree to be bound by the revised terms. If you do not agree
      to the new terms, please stop using the Service.
    </p>

    <h2>Termination</h2>
    <p>
      We may terminate or suspend your account immediately, without prior notice
      or liability, for any reason whatsoever, including and without limitation
      if you breach the Terms. Upon termination, your right to use the Service
      will immediately cease.
    </p>
    <p>
      If you wish to terminate your subscription, you can do so by sending your
      written termination to us at
      {' '}
      <a href="mailto:support@rispons.com">support@rispons.com</a>
      .
    </p>
    <p>
      Termination will take effect if your written termination has been received
      no later than 30 days before the next billing cycle starts. If termination
      is received within 30 days of the next billing cycle, your subscription
      will continue until the end of the following billing cycle.
    </p>

    <h2>Refund Policy</h2>
    <p>
      You can easily cancel your subscription by emailing us at
      {' '}
      <a href="mailto:support@rispons.com">support@rispons.com</a>
      . There are no cancellation fees, though no refunds are
      provided for pro-rated periods.
    </p>

    <h2>Contact Us</h2>
    <p>
      If you have any questions about these Terms, please contact us by writing
      to
      {' '}
      <a href="mailto:support@rispons.com">support@rispons.com</a>
      .
    </p>
  </Container>
);

export default TermsAndConditions;
