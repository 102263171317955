import { contactActions, request } from '../actions';

const { FETCH_TAGS } = contactActions;
const { SUCCESS } = request;

const tags = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TAGS[SUCCESS]: {
      return {
        ...action.response,
      };
    }
    default:
      return state;
  }
};

export default tags;
