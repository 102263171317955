import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { AUTOMATION_STATUS_TEXT } from '../../../common';

const renderStatus = (status, editor) => (
  <span>
    <span className={`automation-item__header__status bordered-status bordered-status--${status}`}>
      <span className={`automation-item__header__status__icon automation-item__header__status__icon--${status}`} />
      <span className={`automation-item__header__status__text${editor ? ' automation-editor' : ''}`}>{AUTOMATION_STATUS_TEXT[status]}</span>
    </span>
  </span>
);

const AutomationStatus = ({
  status,
  editor,
  lastFailure,
}) => {
  if (status === 'failed') {
    return (
      <Popup
        trigger={renderStatus(status, editor)}
        content={lastFailure}
        basic
        size="small"
      />
    );
  }

  return (
    renderStatus(status, editor)
  );
};

AutomationStatus.propTypes = {
  status: PropTypes.string,
  editor: PropTypes.bool,
  lastFailure: PropTypes.string,
};

export default AutomationStatus;
