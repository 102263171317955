import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { round, capitalize } from 'lodash';

import { Spinner } from '../../components/Spinner';
import { notificationActions } from '../../actions';
import { BasicButton } from '../../components/Button';
import { fetchSubscriptionPrice } from '../../services/planApi';

const { notifyError } = notificationActions;

const PlanSubscriptionModal = ({
  notifyErrorA,
  plan: {
    product,
    period,
    cents: fullPrice,
  },
  open,
  closeModalPortal,
  handlePlanSubscription,
}) => {
  const [loading, setLoading] = useState(true);
  const [price, setPrice] = useState();

  useEffect(() => {
    fetchSubscriptionPrice({
      product,
      period,
    })
      .then((res) => {
        setLoading(false);

        if (res.error) {
          notifyErrorA(res.error.message);
        } else {
          setPrice(res.response.cents);
        }
      });
  }, []);

  const quotePlan = fullPrice === -1;

  let pricingInfo = quotePlan ? (
    <div>You will not be charged a prorated fee</div>
  ) : (
    <>
      <div>{`You will currently be charged a prorated fee of $${round(price / 100)}.`}</div>
      <div>The full price will be applied in the next billing cycle.</div>
    </>
  );
  if (quotePlan) {
    pricingInfo = (
      <div>You will be contacted with a plan offer shortly.</div>
    );
  }

  return (
    <Modal
      open={open}
      onClose={() => closeModalPortal()}
      className="subscription-modal"
      dimmer="inverted"
    >
      <Modal.Header>Check your info</Modal.Header>
      <Modal.Content
        className="subscription-modal__content"
      >
        <Spinner loaded={!loading}>
          <div>
            <div className="pricing-info">
              {pricingInfo}
            </div>
            <div className="plan-info">
              <div className="plan-info__header">
                Plan
              </div>
              <div className="plan-info__plan">
                {capitalize(product)}
              </div>
              {!quotePlan && (
                <div className="plan-info__price">
                  {`$${round(fullPrice / 100)}, paid ${period === 'month' ? 'monthly' : 'annually'}`}
                </div>
              )}
            </div>
          </div>
        </Spinner>
      </Modal.Content>
      <Modal.Actions>
        <div className="column-order__modal-actions">
          <BasicButton
            content="Cancel"
            size="small"
            color="white"
            className="white"
            onClick={closeModalPortal}
          />
          <BasicButton
            disabled={loading}
            loading={loading}
            content="Subscribe"
            size="small"
            color="blue"
            className="segmentation-button"
            onClick={() => {
              closeModalPortal();
              handlePlanSubscription(product);
            }}
          />
        </div>
      </Modal.Actions>
    </Modal>
  );
};

PlanSubscriptionModal.propTypes = {
  plan: PropTypes.object,
  open: PropTypes.bool,
  closeModalPortal: PropTypes.func,
  handlePlanSubscription: PropTypes.func,
  notifyErrorA: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default connect(null, mapDispatchToProps)(PlanSubscriptionModal);
