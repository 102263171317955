import MessageWizzardComplete from '../../components/MessageWizzardComplete';

const SuccessPage = () => {
  const id = window.location.href.split('/').pop();
  return (
    <MessageWizzardComplete id={id} />
  );
};

export default SuccessPage;
