import { Grid } from 'semantic-ui-react';
import { AccountSettingsForm, ResetPasswordForm } from '../../../containers/Form';

const Account = () => (
  <Grid>
    <Grid.Row columns={2} className="stackable doubling account-settings-tab">
      <Grid.Column>
        <div className="account-settings">
          <div className="account-settings__subheader">
            <span>Update Account Information</span>
          </div>
          <AccountSettingsForm />
        </div>
      </Grid.Column>
      <Grid.Column>
        <div className="account-settings">
          <div className="account-settings__subheader">
            <span>Change password</span>
          </div>
          <div className="account-settings__reset-pass">
            <ResetPasswordForm invert={true} />
          </div>
        </div>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default Account;
