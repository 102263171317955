import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Creatable from 'react-select/creatable';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { isEmpty } from 'lodash';

// Utilities.
import { required } from '../../../../../utils/validator';
import { mapListsToOptions } from '../../../../../utils/map';

// Redux actions.
import {
  contactActions,
} from '../../../../../actions';
import { notifyError } from '../../../../../actions/notificationActions';

// Context.
import { EditorContext } from '../../../../context/EditorContext';

// API.
import { contactTagCreate } from '../../../../../services/tagApi';

// Styles.
import './OptInTagsController.scss';

const { fetchTags } = contactActions;

const OptInTagsController = ({
  tags,
  fetchTagsA,
}) => {
  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const {
    data: { hiddenInputData },
  } = activeElement;

  useEffect(() => {
    if (isEmpty(tags)) {
      fetchTagsA();
    }
  }, []);

  useEffect(() => {
    // if we're loading old message this procedure is required
    // because we can only get tag values without correct labels.
    if (!isEmpty(tags)) {
      if (hiddenInputData.tags.length && hiddenInputData.tags.every((item) => item.label === 'undefined-tag')) {
        const tagArr = [];
        mapListsToOptions(tags).forEach((newTag) => {
          hiddenInputData.tags.forEach((oldTag) => {
            if (newTag.value === oldTag.value) {
              tagArr.push(newTag);
            }
          });
        });

        if (tagArr.length === hiddenInputData.tags.length) {
          setTagInputValues(tagArr);
        } else {
          setTagInputValues(hiddenInputData.tags);
        }
      }
    }
  }, [tags]);

  const [tagOptions, setTagOptions] = useState(false);

  const setTagInputValues = (tagInputValue) => {
    const dataObj = { ...activeElement };
    const { data } = dataObj;
    data.hiddenInputData.tags = tagInputValue;

    setActiveElement(dataObj);
  };

  const onTagCreate = (newTag) => {
    const isNumber = !isNaN(newTag);

    if (isNumber) {
      const newTagObj = { value: newTag, label: newTag };
      setTagInputValues([...hiddenInputData.tags, newTagObj]);
    } else {
      contactTagCreate({
        name: newTag,
      }).then(({ response }) => {
        const newTagObj = { value: response.id, label: response.name };
        const tagArr = mapListsToOptions(tags);
        setTagOptions([...tagArr, newTagObj]);
        setTagInputValues([...hiddenInputData.tags, newTagObj]);
      }).catch((er) => {
        notifyError(er);
      });
    }
  };

  return (
    <div className="OptInTagsController">
      <p className="OptInTagsController__label">Assign tags:</p>
      <Creatable
        name="tagsInput"
        data-tip="Do not forget to press ↵ Enter after typing the tag"
        id="tagsInput"
        validate={required}
        isMulti={true}
        simpleValues={true}
        components={{
                DropdownIndicator: () => (<i aria-hidden="true" className="dropdown icon optin-delivery-tag__dropdown-icon" />),
                Menu: (props) => (<components.Menu {...props} className="optin-delivery-tag__dropdown-menu" />),
              }}
        multi
        options={tagOptions || mapListsToOptions(tags)}
        placeholder="Select or create new tag"
        className="react-select tag optin-delivery-tag"
        value={hiddenInputData.tags}
        onChange={(newValues) => setTagInputValues(newValues)}
        onCreateOption={(value) => onTagCreate(value)}
      />
    </div>
  );
};

OptInTagsController.propTypes = {
  tags: PropTypes.object.isRequired,
  fetchTagsA: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lists: state.lists.elements,
  messageEditor: state.messageEditor,
  tags: state.tags,
  contactFilter: state.contactFilter,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTagsA: bindActionCreators(fetchTags.request, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptInTagsController);
