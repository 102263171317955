import { request } from '../actions';
import { FETCH_DASHBOARD_METRICS } from '../actions/analyticsActions';

const { SUCCESS } = request;

const dashboardMetrics = (state = { status: {} }, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_METRICS[SUCCESS]: {
      return {
        ...state,
        [action.deliveryMethod]: action.response,
        status: {
          ...state.status,
          [action.deliveryMethod]: 'completed',
        },
      };
    }
    case 'CLEAR_DASHBOARD_STATUS': {
      return {
        ...state,
        status: {},
      };
    }
    default:
      return state;
  }
};

export default dashboardMetrics;
