import { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { MOBILE_MAX_WIDTH } from '../../common';
import { useScreenWidth } from '../../hooks';
import { EditorContext } from '../../editor/context/EditorContext';

const FooterEditor = ({
  handleBackButton,
  handleSaveAndExitButton,
  autosaveMsg,
  currentStep,
}) => {
  const { getCurrentProject } = useContext(EditorContext);
  const { windowWidth } = useScreenWidth();
  const preview = window.location.pathname.split('/').pop() === 'preview';
  const exitText = windowWidth > MOBILE_MAX_WIDTH ? 'Save and Exit' : 'Save';

  const handleExit = () => {
    const projectData = getCurrentProject();
    handleSaveAndExitButton(preview, projectData);
  };

  return (
    <div className="app-footer app-footer--editor-fixed editor">
      <div
        className="footer-editor-button cursored"
      >
        {currentStep !== 'design' && (
          <span
            onClick={() => {
              if (handleBackButton) {
                location.reload();
              }
            }}
            className={preview || !handleBackButton ? 'none' : undefined}
          >
            Back
          </span>
        )}
      </div>
      <div className="editor-footer-actions">
        {windowWidth > MOBILE_MAX_WIDTH && (
          <div className="message-notification--desktop editor-footer-actions__autosave">
            <div className={`message-notification message-notification${autosaveMsg.show ? '--enabled' : '--disabled'}`}>
              <i className="material-icons">
                check_circle_outline
              </i>
              <span>Message Autosaved</span>
            </div>
          </div>
        )}
        {autosaveMsg.updatedAt && (
          <div className="message-notification editor-footer-actions__autosave">
            Last Saved:&nbsp;
            <span className="message-notification__timestamp">{ autosaveMsg.updatedAt }</span>
          </div>
        )}
        <div
          className="footer-editor-button cursored"
          onClick={() => handleExit()}
        >
          {preview ? 'Exit' : exitText}
        </div>
      </div>
    </div>
  );
};

FooterEditor.propTypes = {
  handleBackButton: PropTypes.func,
  handleSaveAndExitButton: PropTypes.func,
  autosaveMsg: PropTypes.object,
  currentStep: PropTypes.string,
};

export default memo(FooterEditor);
