import PropTypes from 'prop-types';
import { has, map } from 'lodash';
import { SortableContainer } from 'react-sortable-hoc';
import ColumnSortableItem from './ColumnSortableItem';

const ColumnOrder = ({
  selected,
  columns,
  handleColumnSelect,
}) => (
  <div className="column-order__order">
    <div className="column-order__order__title">
      ACTIVE COLUMNS
    </div>
    <div className="column-order__order__container">
      {map(selected, (item, index) => {
          if (has(columns[item], 'id')) {
            const disabledRemove = columns[item].required && columns[item].type !== 'custom';
            return (
              <ColumnSortableItem
                key={index}
                index={index}
                item={columns[item]}
                disabledRemove={disabledRemove}
                id={item}
                handleColumnSelect={handleColumnSelect}
              />
            );
          }
          return null;
        })}
    </div>
  </div>
  );

ColumnOrder.propTypes = {
  selected: PropTypes.array,
  columns: PropTypes.array,
  handleColumnSelect: PropTypes.func,
};
export default SortableContainer(ColumnOrder);
