import React from 'react';
import PropTypes from 'prop-types';

// Constants.
import {
  ONE_ANSWER,
  MULTIPLE_ANSWERS,
  NPS,
  OPEN_QUESTION,
  RATING,
  LIKE_DISLIKE,
} from '../../../../constants/answerTypes';

// Styles.
import './QuestionLayout.scss';

const QuestionLayout = ({
  title,
  children,
  type,
  questionIndex,
  answerRequired,
  quizMode,
}) => {
  const classNameByType = {
    [ONE_ANSWER]: MULTIPLE_ANSWERS,
    [MULTIPLE_ANSWERS]: MULTIPLE_ANSWERS,
    [NPS]: NPS,
    [OPEN_QUESTION]: MULTIPLE_ANSWERS,
    [RATING]: RATING,
    [LIKE_DISLIKE]: LIKE_DISLIKE,
  };

  return (
    <div
      id={`${questionIndex}-${type}`}
      className={`device-form__survey--${type}`}
      {...quizMode && { quiz: quizMode }}
    >
      <div
        className={`device-form__survey--${classNameByType[type]}--header`}
        id={`${questionIndex}-${type}-question`}
        weight={questionIndex}
      >
        {title || 'question #'}
        {!!answerRequired && <span className="device-form__survey--required"> *</span>}
      </div>
      {/* each question has different markup */}
      {(type === LIKE_DISLIKE) && <>{children}</>}
      {(type === RATING) && <>{children}</>}
      {(type !== RATING && type !== LIKE_DISLIKE) && (
        <div className={`device-form__survey--${classNameByType[type]}--answers`} id={`${questionIndex}-${type}-answers`}>
          {children}
        </div>
      )}
    </div>
);
};

QuestionLayout.propTypes = {
  quizMode: PropTypes.bool,
  answerRequired: PropTypes.bool,
  questionIndex: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default QuestionLayout;
