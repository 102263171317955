import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

// Components.
import TextInput from '../../../../../../../../components/TextInput';

// Context.
import { EditorContext } from '../../../../../../../../context/EditorContext';

// Styles.
import './OpenQuestionController.scss';

const OpenQuestionController = ({ answerExample, questionId, index }) => {
  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const [
    isAnswerExampleFieldVisible,
    setAnswerExampleFieldVisibility,
  ] = useState(!!answerExample);

  const setAnswerExample = (value) => {
    const dataObj = { ...activeElement };
    const { questions } = dataObj;

    const questionIndex = questions.findIndex((question) => question.questionId === questionId);
    questions[questionIndex].answerExample = value;

    setActiveElement(dataObj);
  };

  return (
    <div className="OpenQuestionController">
      <div className="OpenQuestionController__answer-example-toggle-container">
        <Checkbox
          toggle
          checked={isAnswerExampleFieldVisible}
          onClick={() => setAnswerExampleFieldVisibility(!isAnswerExampleFieldVisible)}
        />
        <p className="OpenQuestionController__answer-example-toggle-label">Add answer example</p>
      </div>

      {isAnswerExampleFieldVisible
      && (
      <TextInput
        name={`question[${index}].questionElements.answer.value`}
        type="text"
        value={answerExample || ''}
        placeholder="Type answer example here..."
        onChange={(e) => setAnswerExample(e.target.value)}
      />
    )}
    </div>
  );
};

OpenQuestionController.propTypes = {
  index: PropTypes.number.isRequired,
  answerExample: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
};

export default OpenQuestionController;
