import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { EMPTY_IMG_SRC } from '../../../config';

// Components.
import CreateElementButton from '../../components/CreateElementButton';

// Constants
import { IMAGE } from '../../constants/widgetTypes';

// Context.
import {
  EditorContext,
} from '../../context/EditorContext';

const PictureWidget = () => {
  const {
    addElement,
    setActiveElement,
  } = useContext(EditorContext);

  const createPictureElement = () => {
    const elementId = uuidv4();
    const defaultElementPropertiesObj = {
      elementType: IMAGE,
      id: elementId,
      src: EMPTY_IMG_SRC,
      width: '100%',
      alt: 'No content',
    };

    addElement(defaultElementPropertiesObj);
    setActiveElement(defaultElementPropertiesObj);
  };

  return (
    <CreateElementButton
      title="PICTURE"
      iconName="photo_library"
      onClick={() => createPictureElement()}
    />
  );
};

export default PictureWidget;
