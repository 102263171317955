import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { orderBy, map, find } from 'lodash';
import { accountActions, notificationActions, genericActions } from '../../../actions';
import {
  identitiesGet,
  identityDelete,
  defaultIdentitySet,
} from '../../../services/accountsApi';
import IdentityItem from './IdentityItem/IdentityItem';
import { IconButton, BasicButton } from '../../Button';
import { VerifyIdentityModal, VerifyIdentityEmailModal } from '../../../containers/Modal';
import { getDomainFromEmail } from '../../../utils/helper';
import { Spinner } from '../../Spinner';
import DomainInfo from './DomainInfo';
import { MOBILE_MAX_WIDTH } from '../../../common';

const {
  fetchIdentities,
  deleteIdentity,
  getAccount,
} = accountActions;
const { notifySuccess, notifyError } = notificationActions;
const { openModalPortal, openConfirmationModal, closeModalPortal } = genericActions;

const DEFAULT_PAGE_SIZE = 10;

const Domain = ({
  fetchIdentitiesA,
  notifyErrorA,
  openModalPortalA,
  closeModalPortalA,
  notifySuccessA,
  deleteIdentityA,
  identities,
  getAccountA,
  openConfirmationModalA,
}) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    getIdentities(pageSize, true, true, true);
    window.addEventListener('resize', resize);
    resize();

    return () => { window.removeEventListener('resize', resize); };
  }, []);

  const getIdentitiesHandler = (res, currentElements, currentTotal) => {
    let showError = false;
    let identityType = false;
    const elements = [...currentElements];
    let total = currentTotal;
    const { response } = res;
    if (response) {
      const {
        elements: resElements,
        total: resTotal,
        pageSize: resPageSize,
      } = response;
      elements.push(...resElements);
      total += resTotal;
      if (resTotal > resPageSize) {
        identityType = true;
      }
    } else {
      showError = true;
    }
    return [elements, identityType, total, showError];
  };

  const getIdentities = (newPageSize, getDomains, getEmails, getSms) => {
    const promises = [];
    let elements = [];
    let total = 0;
    let showError = false;
    let domains = false;
    let emails = false;
    let sms = false;
    if (getDomains) {
      promises.push(identitiesGet({
        type: 'domain',
        page: 1,
        pageCount: newPageSize,
      }).then((res) => {
        ([elements, domains, total, showError] = getIdentitiesHandler(res, elements, total));
      }));
    }
    if (getEmails) {
      promises.push(identitiesGet({
        type: 'email',
        page: 1,
        pageCount: newPageSize,
      }).then((res) => {
        ([elements, emails, total, showError] = getIdentitiesHandler(res, elements, total));
      }));
    }
    if (getSms) {
      promises.push(identitiesGet({
        type: 'sms',
        page: 1,
        pageCount: newPageSize,
      }).then((res) => {
        ([elements, sms, total, showError] = getIdentitiesHandler(res, elements, total));
      }));
    }

    Promise.all(promises).then(() => {
      if (showError) {
        notifyErrorA('Something went wrong... Try again later');
        setLoading(false);
      } else if (domains || emails || sms) {
        setPageSize(total);
        setLoading(true);
        getIdentities(total, domains, emails, sms);
      } else {
        setLoading(false);
      }
      fetchIdentitiesA({
        elements: orderBy(elements, ['id'], ['desc']),
      });
    });
  };

  const resize = () => {
    setIsMobile(window.innerWidth < MOBILE_MAX_WIDTH);
  };

  const openValidationModal = (element) => {
    const type = 'domain';
    let identity = '';

    if (element) {
      ({ identity } = element);
      identity = getDomainFromEmail(identity);
    }

    openModalPortalA({
      content: <VerifyIdentityModal />,
      contentProps: {
        closeModalPortal: () => {
          closeModalPortalA();
          getIdentities(pageSize + DEFAULT_PAGE_SIZE, true, true, true);
        },
        type,
        delivery: false,
        identity,
        element,
      },
    });
  };

  const openValidationEmailModal = (element) => {
    const type = 'email';
    let identity = '';

    if (element) {
      ({ identity } = element);
      identity = getDomainFromEmail(identity);
    }

    openModalPortalA({
      content: <VerifyIdentityEmailModal />,
      contentProps: {
        closeModalPortal: () => {
          closeModalPortalA();
          getIdentities(pageSize + DEFAULT_PAGE_SIZE, true, true, true);
        },
        type,
        identity,
        element,
      },
    });
  };

  const onIdentityDeleteClick = (id, type) => {
    identityDelete(id).then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
      } else {
        const textType = type === 'email' ? 'Email' : 'Domain';
        deleteIdentityA({ id });
        notifySuccessA(`${textType} deleted successfully`);
      }
    });
  };

  const makeDomainMessageLink = (id) => {
    const identity = find(identities, (el) => el.id === id);
    if (identity) {
      defaultIdentitySet(id).then((res) => {
        if (res.error) {
          notifyErrorA(res.error.message);
        } else {
          notifySuccessA(`${identity.identity} is set as default message link`);
          getAccountA();
          getIdentities(pageSize + DEFAULT_PAGE_SIZE, true, true, true);
        }
      });
    } else {
      notifyErrorA('Something went wrong...');
    }
  };

  const confirmUnderlayingAction = (callbackFunction, actionName, id, itemName) => {
    openConfirmationModalA({
      actionName,
      itemName,
      callbackFunction,
      id,
    });
  };

  return (
    <Spinner loaded={!loading}>
      <div className="domain">
        {identities && identities.length ? (
          <>
            <div className="domain__actions">
              <IconButton
                text="Add Domain"
                before={true}
                type="button"
                iconName="add"
                className="domain__actions__add"
                onClick={() => openValidationModal()}
              />
              <IconButton
                text="Add Email address"
                before={true}
                type="button"
                iconName="add"
                className="domain__actions__add"
                onClick={() => openValidationEmailModal()}
              />
            </div>
            <div className="domain__container">
              <div className="domain__container__identities">
                {map(identities, (element) => {
                  const { id } = element;
                  return (
                    <IdentityItem
                      key={id}
                      element={element}
                      openDomainModal={openValidationModal}
                      deleteIdentity={(domainId, itemName) => confirmUnderlayingAction(
                        onIdentityDeleteClick,
                        'delete',
                        domainId,
                        itemName,
                      )}
                      makeDomainMessageLink={makeDomainMessageLink}
                    />
                  );
                })}
              </div>
              {!isMobile && (
                <div className="domain__info__sidebar">
                  <DomainInfo />
                </div>
              )}
            </div>
            {isMobile && (
              <div className="domain__info__bottom">
                <DomainInfo />
              </div>
            )}
          </>
        ) : (
          <div className="domain__empty">
            <span className="domain__empty__text">You have no active domains or email addresses</span>
            <div className="domain__empty__actions">
              <BasicButton
                content="Add your first domain"
                type="button"
                onClick={() => openValidationModal()}
              />
              <BasicButton
                content="Add your first email address"
                type="button"
                onClick={() => openValidationEmailModal()}
              />
            </div>
          </div>
        )}
      </div>
    </Spinner>
  );
};

Domain.propTypes = {
  identities: PropTypes.array,
  fetchIdentitiesA: PropTypes.func,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  deleteIdentityA: PropTypes.func,
  getAccountA: PropTypes.func,
  openConfirmationModalA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  identities: state.identities.elements,
});

const mapDispatchToProps = (dispatch) => ({
  fetchIdentitiesA: bindActionCreators(fetchIdentities, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  openConfirmationModalA: bindActionCreators(openConfirmationModal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
  deleteIdentityA: bindActionCreators(deleteIdentity, dispatch),
  getAccountA: bindActionCreators(getAccount.request, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Domain);
