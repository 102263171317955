import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';

import InjectedCheckoutForm from '../Form/CheckoutForm';

const MyStoreCheckout = ({ addedCard }) => (
  <Elements>
    <InjectedCheckoutForm addedCard={addedCard} />
  </Elements>
);

MyStoreCheckout.propTypes = {
  addedCard: PropTypes.func,
};

export default MyStoreCheckout;
