import React, { useContext } from 'react';
import { Divider } from 'semantic-ui-react';

import TextInput from '../../../../components/TextInput';
import ColorSelector from '../../../../components/ColorSelector';

// Context.
import { EditorContext } from '../../../../context/EditorContext';

// Styles.
import './OptInButtonController.scss';

const OptInButtonController = () => {
  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);
  const {
    data: { button },
  } = activeElement;

  const changeElementProperties = (value, property) => {
    const dataObj = { ...activeElement };
    dataObj.data.button[property] = value;

    setActiveElement(dataObj);
  };

  return (
    <div className="OptInButtonController">
      <div className="OptInButtonController__header">
        <p className="OptInButtonController__header-title">Button</p>
      </div>
      <div className="OptInButtonController__text-editor-wrapper">
        <Divider />
        <div className="OptInButtonController__input-wrapper">
          <ColorSelector
            label="Button color:"
            currentColor={button.bgColor}
            hasInput
            onChange={(value) => changeElementProperties(value, 'bgColor')}
          />
        </div>
        <Divider />
        <div className="OptInButtonController__input-wrapper">
          <ColorSelector
            label="Text color:"
            currentColor={button.textColor}
            hasInput
            onChange={(value) => changeElementProperties(value, 'textColor')}
          />
        </div>
        <Divider />
        <div className="OptInButtonController__input-wrapper">
          <TextInput
            type="text"
            placeholder="Button text"
            label="Button text:"
            onChange={(e) => changeElementProperties(e.target.value, 'text')}
            value={button.text}
          />
        </div>
      </div>
    </div>
  );
};

export default OptInButtonController;
