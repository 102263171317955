import React from 'react';
import PropTypes from 'prop-types';

// Compositions.
import EditorView from '../EditorView';
import WidgetsController from '../WidgetsController';

// Styles.
import './AutomationEditorView.scss';

const AutomationEditorView = ({
  handleAutomationSave,
  handleMessageClose,
  setDisplayAutomationToolbar,
  displayAutomationToolbar,
  preview,
}) => (
  <div className="AutomationEditorView">
    <div className="AutomationEditorView__editor-layout">
      <div className="AutomationEditorView__editor-preview-side">
        <div className="AutomationEditorView__automation-cta-section">
          <button
            type="button"
            className="AutomationEditorView__automation-save-button"
            onClick={handleAutomationSave}
          >
            Save
          </button>
          <button
            type="button"
            className="AutomationEditorView__automation-close-button"
            onClick={handleMessageClose}
          >
            <div className="close" />
          </button>
        </div>
        <EditorView
          isPreview={preview}
          setDisplayAutomationToolbar={setDisplayAutomationToolbar}
          displayAutomationToolbar={displayAutomationToolbar}
        />
      </div>
      <div className="AutomationEditorView__widget-controller-side">

        {(!!displayAutomationToolbar) && (
          <button
            className="AutomationEditorView__hide-toolbar-button"
            type="button"
            onClick={setDisplayAutomationToolbar}
          >
            <div className="close" />
          </button>
        )}

        {displayAutomationToolbar && <WidgetsController />}
      </div>

      {!displayAutomationToolbar
        && (
        <button
          id="automationToolbarButton"
          className="AutomationEditorView__display-toolbar-button"
          type="button"
          onClick={setDisplayAutomationToolbar}
        >
          <p>+</p>
        </button>
      )}

    </div>
  </div>
  );

AutomationEditorView.propTypes = {
  handleAutomationSave: PropTypes.func,
  handleMessageClose: PropTypes.func,
  setDisplayAutomationToolbar: PropTypes.func,
  displayAutomationToolbar: PropTypes.bool,
  preview: PropTypes.bool,
};

export default AutomationEditorView;
