import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import {
  Form,
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  Field,
} from 'redux-form';
import { isEmpty } from 'lodash';
import { Input, PhoneInputField } from '../../components/Field';
import { BasicButton } from '../../components/Button';
import { required, phone, email } from '../../utils/validator';
import { accountActions, notificationActions } from '../../actions';

const { notifySuccess } = notificationActions;
const { getAccount, getAccountUser } = accountActions;

const AccountSettingsForm = ({
  user,
  showSuccessMsg,
  getAccountA,
  getAccountUserA,
  handleSubmit,
  formErrors,
}) => {
  const onSubmit = (values, dispatch) => {
    const updatedValues = { ...values };
    if (values.phone) {
      updatedValues.phone = values.phone.replace(/\s/g, '');
    }
    const changedFlag = user.email !== updatedValues.email;
    const changedFlagName = user.fullName !== updatedValues.fullName;
    const changedFlagPhone = user.phone !== values.phone;
    let newValues = {};

    let successMsg = '';
    switch (user.role) {
      case 'root': {
        const { updateAccount } = accountActions;
        updateAccount(updatedValues, dispatch).then(() => {
          if (changedFlag) {
            successMsg = 'Account updated successfully. Confirm your email by clicking the link we sent to you.';
          } else {
            successMsg = 'Account updated successfully';
          }
          showSuccessMsg(successMsg);
          getAccountA();
          getAccountUserA();
        });
        break;
      }
      case 'user': {
        const { updateAccount } = accountActions;
        newValues = {
          fullName: changedFlagName ? user.fullName : updatedValues.fullName,
          phone: changedFlagPhone ? user.phone : updatedValues.phone,
        };
        updateAccount(newValues, dispatch).then(() => {
          if (changedFlagName || changedFlagPhone) {
            successMsg = 'Account updated successfully.';
          } else {
            successMsg = 'Something went wrong';
          }
          showSuccessMsg(successMsg);
          getAccountA();
          getAccountUserA();
          window.location.reload();
        });
        break;
      }
      default: {
        const { updateUser } = accountActions;

        updateUser(updatedValues, dispatch).then(() => {
          if (changedFlag) {
            successMsg = 'User updated successfully. Confirm your email by clicking the link we sent to you.';
          } else {
            successMsg = 'User updated successfully';
          }
          showSuccessMsg(successMsg);
          getAccountUserA();
        });
        break;
      }
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="nobackground-flex ris-form account-settings-form"
    >
      <Grid className="no-margin account-settings-grid">
        <Grid.Row>
          <Field
            className="ui"
            label="Name"
            component={Input}
            name="fullName"
            size="large"
            validate={required}
            placeholder="Current name"
          />
        </Grid.Row>
        <Grid.Row>
          <Field
            className="ui"
            label="Company name"
            component={Input}
            name="companyName"
            size="large"
            placeholder="Company name"
            disabled={user.role !== 'root'}
          />
        </Grid.Row>
        <Grid.Row>
          <Field
            className="ui"
            label="Email"
            component={Input}
            name="email"
            size="large"
            validate={[email, required]}
            placeholder="currentemail@email.com"
            disabled={user.role !== 'root'}
          />
        </Grid.Row>
        <Grid.Row>
          <Field
            className="app-form__field"
            label="Phone number"
            component={PhoneInputField}
            name="phone"
            validate={[phone, required]}
            placeholder="Current Number"
          />
        </Grid.Row>
        <div
          className={(user.role === 'user' ? 'modal-button-center' : 'account-settings__submit-column')}
        >
          <BasicButton
            content="Update"
            color="blue"
            size="small"
            className="invert"
            disabled={!isEmpty(formErrors)}
          />
        </div>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const tempValues = {
    fullName: state.user.fullName,
    companyName: state.account.companyName,
    email: state.user.email,
    phone: state.user.phone,
  };
  return ({
    formErrors: getFormSyncErrors('accountSettings')(state),
    formValues: getFormValues('accountSettings')(state),
    user: state.user,
    account: state.account,
    initialValues: tempValues,
  });
};

const mapDispatchToProps = (dispatch) => ({
  showSuccessMsg: bindActionCreators(notifySuccess, dispatch),
  getAccountA: bindActionCreators(getAccount.request, dispatch),
  getAccountUserA: bindActionCreators(getAccountUser.request, dispatch),
});

const formConfig = {
  form: 'accountSettings',
  enableReinitialize: true,
};

AccountSettingsForm.propTypes = {
  handleSubmit: PropTypes.func,
  formErrors: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ])),
  user: PropTypes.object,
  showSuccessMsg: PropTypes.func,
  getAccountA: PropTypes.func,
  getAccountUserA: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm(formConfig)(AccountSettingsForm));
