import { get } from 'lodash';
import moment from 'moment';

const sessionManagerMiddleware = () => (next) => (action) => {
  const credentials = JSON.parse(get(localStorage, 'credentials', '{}'));

  let remainingTime;

  if (credentials.Expiration) {
    const expTime = moment(credentials.Expiration);
    remainingTime = expTime.diff(moment(), 'minutes');
  }

  if (remainingTime && remainingTime <= 1) {
    return next({ type: 'LOGOUT', sessionTimeout: true });
  }

  return next(action);
};

export default sessionManagerMiddleware;
