import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { ExportAnalyticsDataForm } from '../Form';
import { BasicButton } from '../../components/Button';

const ExportAnalyticsDataModal = ({
  open,
  close,
}) => (
  <Modal
    className="modal"
    open={open}
    closeOnDocumentClick={true}
    onClose={close}
    size="small"
    dimmer="inverted"
  >
    <Modal.Header>Export data</Modal.Header>
    <Modal.Content>
      <ExportAnalyticsDataForm />
    </Modal.Content>
    <Modal.Actions>
      <BasicButton
        content="Close"
        size="small"
        className="white"
        onClick={close}
      />
    </Modal.Actions>
  </Modal>
  );

ExportAnalyticsDataModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default ExportAnalyticsDataModal;
