import React from 'react';
import PropTypes from 'prop-types';

import './WidgetControllerHeader.scss';

const WidgetControllerHeader = ({ title }) => (
  <div className="WidgetControllerHeader">
    <p>
      {title}
    </p>
  </div>
);

WidgetControllerHeader.propTypes = {
  title: PropTypes.node.isRequired,
};

export default WidgetControllerHeader;

