import {
  call, put, takeLatest, select,
} from 'redux-saga/effects';
import { accountActions, authActions, genericActions } from '../actions';
import { api } from '../services';
import {
  requestSaga,
  showSuccessMessage,
  showGlobalError,
  clearPaginatorSaga,
} from './genericSagas';
import { getCurrentPageNumber } from '../modules/redux-paginator';

const { GO_TO_PAGE } = genericActions;

const {
  createAccount,
  smsCreditCount,
  updateAccount,
  updateUser,
  updateBilling,
  getAccountUser,
  getAccount,
  inviteUser,
  FETCH_ACCOUNT_USER,
  requestModifiedUsersPage,
  requestModifiedAccountsPage,
  blockAccount,
  deleteAccount,
  BLOCK_ACCOUNT,
  DELETE_ACCOUNT,
  FETCH_ACCOUNT_BY_ID,
  FETCH_ACCOUNT,
  INVITE_USER,
  fetchAccountById,
  CREATE_ACCOUNT,
  COUNT_CREDITS,
} = accountActions;

const {
  BLOCK_USER,
  DELETE_USER,
  CHANGE_ROLE,
} = authActions;

const {
  accounts: {
    accountCreate,
    creditCount,
    accountUpdate,
    billingUpdate,
    userUpdate,
    accountGet,
    accountDelete,
    accountBlock,
    fetchAccount,
    accountGetById,
    userInvite,
  },
} = api;

const getUsersPaginator = (state) => state.usersPaginator;
const getAccountPaginator = (state) => state.accountsPaginator;

function* fetchModifiedUsersPage() {
  const usersPaginator = yield select(getUsersPaginator);
  const currentPageNo = yield call(getCurrentPageNumber, usersPaginator, 'users');

  yield call(clearPaginatorSaga, 'usersPaginator');
  yield put(requestModifiedUsersPage(currentPageNo));
}

function* fetchModifiedAccountsPage() {
  const accountsPaginator = yield select(getAccountPaginator);
  const currentPageNo = yield call(getCurrentPageNumber, accountsPaginator, 'accounts');

  yield call(clearPaginatorSaga, 'accountsPaginator');
  yield put(requestModifiedAccountsPage(currentPageNo));
}

function* countCreditsSaga(args) {
  yield call(requestSaga, smsCreditCount, creditCount, args, null, args);
}

function* countCreditsSuccessSaga(args) {
  yield call(requestSaga, smsCreditCount, creditCount, args, null, args);
}

function* createNewAccountSaga(args) {
  yield call(requestSaga, createAccount, accountCreate, args, null, args);
}

function* createNewAccountSuccessSaga(args) {
  if (args.body.payload.token) {
    yield call(showSuccessMessage, 'Thanks for signing up! You can now login to rispons!');
  } else {
    yield call(showSuccessMessage, 'Thanks for signing up! Check your email to confirm your account and login to rispons!');
  }
  yield put({ type: GO_TO_PAGE, route: '/login' });
}

function* updateMyAccountSaga(args) {
  yield call(requestSaga, undefined, accountUpdate, args, { ...updateAccount });
}

function* updateMyUserSaga(args) {
  yield call(requestSaga, undefined, userUpdate, args, { ...updateUser });
}

function* updateMyBillingSaga(args) {
  yield call(requestSaga, undefined, billingUpdate, args, { ...updateBilling });
}

function* fetchAccountUserSaga() {
  yield call(requestSaga, getAccountUser, accountGet, undefined, undefined, 'userAuthorisation');
}

function* redirect(context, args) {
  const { pathname } = context.location;

  if (pathname === '/login') {
    let route = '';
    if (args.role === 'super') {
      route = '/admin/accounts';
    } else if (args.role === 'expired') {
      route = 'account-settings#plans';
    }
    yield put({ type: GO_TO_PAGE, route });
  }
  if (pathname === '/' && args.role === 'super') {
    const route = '/admin/accounts';
    yield put({ type: GO_TO_PAGE, route });
  }
  if (args.role === 'expired' && pathname !== '/account-settings') {
    yield put({ type: GO_TO_PAGE, route: 'account-settings#plans' });
  }
}

function* fetchAccountSaga() {
  yield call(requestSaga, getAccount, fetchAccount);
}

function* blockUserSuccessSaga(args) {
  yield call(showSuccessMessage, `User was ${!args.blocked ? 'un' : ''}blocked successfully`);
  yield call(fetchModifiedUsersPage);
}

function* deleteUserSuccessSaga() {
  yield call(showSuccessMessage, 'User was deleted successfully');
  yield call(fetchModifiedUsersPage);
}

function* changeRoleSuccessSaga() {
  yield call(showSuccessMessage, 'User role was changed successfully');
  yield call(fetchModifiedUsersPage);
}

function* accountDeletionSaga(args) {
  yield call(requestSaga, deleteAccount, accountDelete, args);
}

function* accountDeletionSuccessSaga() {
  yield call(showSuccessMessage, 'Account was deleted successfully');
  yield call(fetchModifiedAccountsPage);
}

function* accountBlockSaga(args) {
  yield call(requestSaga, blockAccount, accountBlock, args);
}

function* accountBlockSuccessSaga(args) {
  yield call(showSuccessMessage, `Account was ${args.blocked ? '' : 'un'}blocked successfully`);
  yield call(fetchModifiedAccountsPage);
}

function* getAccountById(args) {
  yield call(requestSaga, fetchAccountById, accountGetById, args);
}

function* inviteUserSaga(args) {
  yield call(requestSaga, inviteUser, userInvite, args);
}

function* inviteUserSuccessSaga() {
  yield call(showSuccessMessage, 'Invitation was sent successfully');
  yield fetchModifiedUsersPage();
}

export default function* watchAccount(history) {
  yield takeLatest(CREATE_ACCOUNT.REQUEST, createNewAccountSaga);
  yield takeLatest(CREATE_ACCOUNT.SUCCESS, createNewAccountSuccessSaga);
  yield takeLatest(CREATE_ACCOUNT.FAILURE, showGlobalError);

  yield takeLatest(COUNT_CREDITS.REQUEST, countCreditsSaga);
  yield takeLatest(COUNT_CREDITS.SUCCESS, countCreditsSuccessSaga);
  yield takeLatest(COUNT_CREDITS.FAILURE, showGlobalError);

  yield takeLatest(updateAccount.REQUEST, updateMyAccountSaga);
  yield takeLatest(updateUser.REQUEST, updateMyUserSaga);
  yield takeLatest(updateBilling.REQUEST, updateMyBillingSaga);

  yield takeLatest(FETCH_ACCOUNT_USER.REQUEST, fetchAccountUserSaga);
  yield takeLatest(FETCH_ACCOUNT_USER.FAILURE, showGlobalError);
  yield takeLatest(FETCH_ACCOUNT_USER.SUCCESS, redirect, history);

  yield takeLatest(FETCH_ACCOUNT.REQUEST, fetchAccountSaga);
  yield takeLatest(FETCH_ACCOUNT.FAILURE, showGlobalError);

  yield takeLatest(BLOCK_USER.SUCCESS, blockUserSuccessSaga);

  yield takeLatest(DELETE_USER.SUCCESS, deleteUserSuccessSaga);

  yield takeLatest(FETCH_ACCOUNT_BY_ID.REQUEST, getAccountById);
  yield takeLatest(FETCH_ACCOUNT_BY_ID.FAILURE, showGlobalError);

  yield takeLatest(CHANGE_ROLE.SUCCESS, changeRoleSuccessSaga);

  yield takeLatest(DELETE_ACCOUNT.REQUEST, accountDeletionSaga);
  yield takeLatest(DELETE_ACCOUNT.FAILURE, showGlobalError);
  yield takeLatest(DELETE_ACCOUNT.SUCCESS, accountDeletionSuccessSaga);

  yield takeLatest(BLOCK_ACCOUNT.REQUEST, accountBlockSaga);
  yield takeLatest(BLOCK_ACCOUNT.FAILURE, showGlobalError);
  yield takeLatest(BLOCK_ACCOUNT.SUCCESS, accountBlockSuccessSaga);

  yield takeLatest(INVITE_USER.REQUEST, inviteUserSaga);
  yield takeLatest(INVITE_USER.SUCCESS, inviteUserSuccessSaga);
  yield takeLatest(INVITE_USER.FAILURE, showGlobalError);
}
