import { memo } from 'react';
import PropTypes from 'prop-types';
import {
  map,
  capitalize,
  isEqual,
} from 'lodash';

import { featuresKeys } from '../../../../../common/plans';
import { renderUserFeature, calculatePrice } from '../../../../../utils/plans';

const renderHeader = (key, plans, index) => {
  const {
    monthlyParams: { sms },
    params: { email },
    period,
    cents,
  } = plans[index];

  if (key === 'sms') {
    return `${sms}${cents === -1 ? '+' : ''}/${period}`;
  }

  if (key === 'email') {
    if (email === -1) {
      return <div className="ris-checkbox" />;
    }

    return null;
  }

  if (key === 'users') {
    return renderUserFeature(plans, index);
  }

  return null;
};

const PlansHeader = ({ plans, openPlanSubscriptionModal }) => (
  <>
    {map(plans, (plan, index) => {
      const { product, period, cents } = plan;

      return (
        <div
          key={index}
          className="ris-pricing-inner"
        >
          <div
            className={`ris-pricing-inner-box ris-pricing-inner-box--${product}`}
          >
            <p className="ris-pricing-inner-title">{capitalize(product)}</p>
            {cents === -1 ? (
              <p className="ris-pricing-inner-price-value get-quote">Contact us</p>
            ) : (
              <>
                <p className="ris-pricing-inner-price-value">
                  $
                  {calculatePrice(cents, period)}
                </p>
                <p className="payment-type">{`per ${period}`.toUpperCase()}</p>
              </>
            )}
            <button
              onClick={() => openPlanSubscriptionModal(plan)}
              type="button"
              className="ris-pricing-button-start"
            >
              {`Get ${product === 'enterprise' ? 'a quote' : capitalize(product)}`}
            </button>
          </div>
        </div>
      );
    })}
    <div className="ris-features-header">
      {map(featuresKeys.headerFeatures, (feature, key) => (
        <div
          key={key}
          className="ris-row"
        >
          <div className="ris-feature">{feature}</div>
          <div className="ris-feature-inner">
            {map(plans, ({ product }, index) => (
              <div
                key={product}
                className={`ris-feature-inner-box ris-feature-inner-box--${product}`}
              >
                {renderHeader(key, plans, index)}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </>
);

PlansHeader.propTypes = {
  openPlanSubscriptionModal: PropTypes.func,
  plans: PropTypes.array,
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps.plans, nextProps.plans);

export default memo(PlansHeader, areEqual);
