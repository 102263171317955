import URL from 'url-parse';
import parse from 'parse-color';
import {
  get,
  forEach,
  maxBy,
  filter,
  find,
} from 'lodash';
import jwtDecode from 'jwt-decode';
import { parse as parsePhone } from 'libphonenumber-js';
import moment from 'moment';
import ColorHash from 'color-hash';
import {
  textTypeOperators,
  integerTypeOperators,
  selectTypeOperators,
  videosFormats,
  imagesFormats,
  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH, DATE,
} from '../common';
import { Input, SelectField, PhoneInputField } from '../components/Field';
import {
  required,
  email,
  integer,
  float,
  number,
  phone,
  maxLength255,
} from './validator';
import { mapOptionListToOptions } from './map';
import { S3_BUCKET_URL, ENV, buildPathTemplate } from '../config';
import countriesNames from '../../countriesNames.json';

const { API_ENV, MEDIA_PATH_PUBLIC } = ENV;

export const setValidityIconColor = (iconName) => {
  let color = '';
  switch (iconName) {
    case 'check circle': {
      color = 'green';
      break;
    }
    case 'remove circle': {
      color = 'red';
      break;
    }
    default:
      color = 'grey';
  }
  return color;
};

export const setListId = (list) => ((list === 'all' || list === 'unsubscribed') ? 0 : list);

export const setContactsType = (list) => {
  let type;
  switch (list) {
    case 'all': {
      type = 'default';
      break;
    }
    case 'unsubscribed': {
      type = 'unsubscribed';
      break;
    }
    default: {
      type = 'list';
      break;
    }
  }
  return type;
};

const getPropertyParamsByInternalName = (properties, value) => {
  let propertyType;
  const propertyInternalName = get(value, 'property', undefined);
  if (!properties || !propertyInternalName) {
    return propertyType;
  }
  const propertyName = propertyInternalName.split('.')[1];
  return properties[propertyName];
};

const getPropertyParamsByName = (properties, value) => {
  let propertyType;
  const propertyName = get(value, 'name', undefined);
  if (!properties || !value) {
    return propertyType;
  }
  return properties[propertyName];
};

const getPropertyOperators = (type) => {
  switch (type) {
    case 'integer': {
      return integerTypeOperators;
    }
    case 'float': {
      return integerTypeOperators;
    }
    case 'select': {
      return selectTypeOperators;
    }
    default:
      return textTypeOperators;
  }
};

const getInputComponent = (type, operator) => {
  const componentNecessity = operator && operator !== 'isEmpty'
    && operator !== 'isNotEmpty' && operator !== 'hasTag';

  if (componentNecessity) {
    switch (type) {
      case 'select': {
        return SelectField;
      }
      default:
        return Input;
    }
  } else {
    return undefined;
  }
};

const getTextInputProps = (dataType) => {
  switch (dataType) {
    case 'integer': {
      return {
        type: 'number',
        validate: [required, integer, maxLength255],
        step: 1,
      };
    }
    case 'float': {
      return {
        type: 'number',
        validate: [required, float, maxLength255],
        step: 0.01,
      };
    }
    case 'number': {
      return {
        type: 'number',
        validate: [required, number, maxLength255],
      };
    }
    default:
      return {
        type: 'text',
        validate: [required, maxLength255],
      };
  }
};

export const setOperatorOptions = (properties, value) => {
  const property = getPropertyParamsByInternalName(properties, value);
  const propertyDisplayType = property ? property.displayType : undefined;
  return getPropertyOperators(propertyDisplayType);
};

export const setFilterInputComponent = (properties, value) => {
  const property = getPropertyParamsByInternalName(properties, value);
  const propertyDisplayType = property ? property.displayType : undefined;
  const operator = get(value, 'operator', undefined);
  return getInputComponent(propertyDisplayType, operator);
};

export const setFilterInputProps = (properties, value) => {
  const property = getPropertyParamsByInternalName(properties, value);
  const propertyDataType = property ? property.dataType : undefined;
  const propertyDisplayType = property ? property.displayType : undefined;
  switch (propertyDisplayType) {
    case 'select': {
      return {
        validate: required,
        options: mapOptionListToOptions(property.optionList),
      };
    }
    default:
      return getTextInputProps(propertyDataType);
  }
};

export const setPropertyValueComponent = (properties, value) => {
  const property = getPropertyParamsByName(properties, value);
  const propertyDataType = property ? property.dataType : undefined;
  // Phone input component is necessary for this form
  if (propertyDataType === 'phone') {
    return PhoneInputField;
  }
  // true parameter - fake component's necessity
  return getInputComponent(propertyDataType, true);
};

export const setPropertyValueProps = (properties, value, preselectCountry) => {
  const property = getPropertyParamsByName(properties, value);
  const propertyDisplayType = property ? property.displayType : undefined;
  const propertyDataType = property ? property.dataType : undefined;
  switch (propertyDataType) {
    case 'select': {
      return {
        validate: [required],
        options: mapOptionListToOptions(property.optionList),
      };
    }
    case 'email': {
      return {
        validate: [required, email, maxLength255],
      };
    }
    case 'phone': {
      return {
        validate: [required, phone, maxLength255],
        preselectCountry,
        placeholder: '+45 XXX XXXXX',
      };
    }
    default:
      return getTextInputProps(propertyDisplayType);
  }
};

export const getUrlProperty = (property = null, string = window.location.href) => {
  const parsedUrlObject = new URL(string, true);

  return get(parsedUrlObject, property, null);
};

export const parseReactSelectValues = (values) => {
  if (!values) {
    return null;
  }
  const valuesParsed = [];
  forEach(values, (value) => {
    valuesParsed.push(value.value ? value.value : value);
  });
  return valuesParsed;
};

export const convertToHex = (color) => parse(color).hex;

export const decodeJwtToken = (jwt) => jwtDecode(jwt);

export const getEditorViewDisplay = (windowWidth, view) => {
  const displayType = 'mobile';
  const displayView = 'mobile';

  if (windowWidth <= MOBILE_MAX_WIDTH) {
    return {
      displayType,
      displayView: 'desktop',
    };
  }
  if (windowWidth <= TABLET_MAX_WIDTH) {
    if (view === 'tablet' || view === 'desktop') {
      return {
        displayType: 'tablet',
        displayView: 'desktop',
      };
    }
    return {
      displayType,
      displayView,
    };
  }

  return {
    displayType: view,
    displayView: view,
  };
};

export const formatAttachments = (attachments, name, href, id) => {
  let supplementedAttachments = [];
  let queue = 0;

  if (id) {
    attachments.forEach((attachment) => {
      if (attachment.id === id) {
        supplementedAttachments.push({ id, innerHTML: name, href });
      } else {
        supplementedAttachments.push(attachment);
      }
    });
  } else {
    if (attachments.length) {
      const lastGivenId = maxBy(attachments, (attachment) => parseInt(attachment.id.split('-')[1], 10)).id;
      queue = parseInt(lastGivenId.split('-')[1], 10) + 1;
    }

    supplementedAttachments = [
      ...attachments,
      { id: `file-${queue}-name`, innerHTML: name, href },
    ];
  }

  return supplementedAttachments;
};

export const timestampToDate = (timestamp) => (moment(timestamp).format(DATE));

export const parseMediaContents = (contents) => {
  const videos = [];
  const images = [];

  contents.forEach(({ Key }) => {
    const fileExtention = Key.split('.').pop();
    const mediaObj = {
      title: Key,
      mediaUrl: S3_BUCKET_URL.concat(Key),
    };

    if (videosFormats.includes(fileExtention)) {
      videos.push(mediaObj);
    } else if (imagesFormats.includes(fileExtention)) {
      images.push(mediaObj);
    }
  });

  return { videos, images };
};

export const parseS3Response = (res) => res.map((file) => ({
  title: file.filename,
  mediaUrl: `${MEDIA_PATH_PUBLIC}${file.fullpath}`,
}));

export const getCountryName = (countryCode) => {
  if (countriesNames.hasOwnProperty(countryCode)) {
    return countriesNames[countryCode];
  }
  return countryCode;
};

export const addCountryToResults = (results) => {
  const modifiedUsers = results;
  for (let i = 0; i < modifiedUsers.length; i += 1) {
    const countryName = getCountryName(parsePhone(`tel:${results[i].phone};ext:123`).country);
    modifiedUsers[i].country = countryName;
  }
  return modifiedUsers;
};

export const getServicePath = (svc) => {
  if (API_ENV === 'dev') {
    return svc.concat('-dev');
  }
  return svc;
};

export const getFullServicePath = (svc, endpoint) => buildPathTemplate(
  getServicePath(svc),
  endpoint,
);

export const getExtByUrl = (url) => url.split('.').pop().toLowerCase();

export const uniq = (long = 7) => Math.random().toString(36).substring(long);

export const checkInputValue = (data) => {
  let value = Number(data);
  if (data === '') {
    value = 0;
  } else if (data[0] === '0') {
    value = data.replace(/^0+/, '');
    if (String(value) === '') {
      value = 0;
    }
  }
  return value;
};

export const generateColorFromString = (input) => new ColorHash().hex(input);

// Functions to check messages status
export const isFrozen = (status) => (status !== 'Frozen' ? 'off' : '');

export const isDraft = (status) => {
  if (status === 'Draft') {
    return 'Can not freeze drafts';
  }
  return `${status === 'Frozen' ? 'Unf' : 'F'}reeze message`;
};

export const convertFromBytes = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';
  const defaultSize = 1024;
  const numberOfDecimals = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const value = Math.floor(Math.log(bytes) / Math.log(defaultSize));
  return `${parseFloat((bytes / (defaultSize ** value)).toFixed(numberOfDecimals))}${sizes[value]}`;
};

// Function to check if current property is available for upload modal selection
export const checkPropertyAvailability = (currentProperty, values, currentIndex) => {
  const { internalName } = currentProperty;
  let propertyAvailable = true;

  forEach(values, (val, index) => {
    if (index < currentIndex && get(val, 'match.internalName') === internalName) {
      propertyAvailable = false;
    }
  });

  return propertyAvailable;
};

export const calculateDuration = (duration) => {
  const time = Math.round(duration);
  const minutes = Math.floor(time / 60);
  const seconds = time - (minutes * 60);
  return `${minutes}:${seconds}`;
};

export const stringToCamelCase = (str) => str.replace(/(?:^\w|[A-Z]|\b\w)/g,
  (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
  .replace(/(\s|\.|,|;|:)+/g, '');

export const removeTagAndListProperties = (properties, contactsFilter) => {
  const filteredProperties = filter(properties, (property) => {
    if (contactsFilter) {
      return property.name !== 'list';
    }
    return property.name !== 'tag' && property.name !== 'list';
  });
  const filteredPropertiesObject = {};
  forEach(filteredProperties, (property) => {
    const { name } = property;
    filteredPropertiesObject[name] = property;
    if (name === 'email' || name === 'phoneNumber') {
      filteredPropertiesObject[name].required = true;
    }
  });
  return filteredPropertiesObject;
};

export const getDomainFromEmail = (mail) => mail.split('@')[1];

export const getNewestVerifiedEmail = (identities) => {
  const verifiedIdentity = find(identities, (identity) => identity.identityType === 'email' && identity.verified);
  if (verifiedIdentity) {
    return verifiedIdentity.identity;
  }
  return 'default';
};

export const getOptionalLabelByCountry = (country) => {
  if (country === 'Denmark') {
    return '';
  }
  return ' (OPTIONAL)';
};

export const getMessageContacts = ({ messageStatus }, numOfRecipients) => ((messageStatus === 'Sent' && numOfRecipients > 0) ? numOfRecipients : '');

export const getMessageStatusText = ({ messageStatus, messageOrigin }) => {
  if (messageOrigin === 'default') {
    if (messageStatus === 'Active-sending') {
      return 'Automation locked';
    }
    return messageStatus;
  }
  if (messageStatus === 'Draft') {
    return 'Automation draft';
  }
  return 'Automation locked';
};

export const isActiveAnalytics = ({ messageStatus }) => messageStatus !== 'Draft';

export const canFreeze = ({ messageStatus, messageOrigin }) => messageStatus !== 'Draft' && messageOrigin !== 'automation' && messageStatus !== 'Active-sending';

export const delayFunctionCall = (() => {
  let timer = null;
  const delay = (func, ms) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(func, ms);
  };
  return delay;
})();
