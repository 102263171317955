import PropTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';
import { required } from '../../utils/validator';
import { Input } from '../../components/Field';

const NewAutomationForm = ({ handleSubmit, onFormSubmit }) => (
  <Form className="message-form ris-form" onSubmit={handleSubmit(onFormSubmit)}>
    <Field
      className="app-form__field"
      component={Input}
      name="title"
      label="Automation name"
      placeholder="Automation name"
      validate={[required]}
    />
  </Form>
);

const formConfig = {
  form: 'newAutomationForm',
};

NewAutomationForm.propTypes = {
  handleSubmit: PropTypes.func,
  onFormSubmit: PropTypes.func,
};

export default reduxForm(formConfig)(NewAutomationForm);
