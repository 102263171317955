import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoPlayer = ({ src }) => {
  const player = useRef(null);
  const videoWrapperNode = useRef(null);
  const videoNode = useRef(null);

  useEffect(() => {
    const setup = {
      controls: true,
      autoplay: false,
      preload: 'auto',
      resizeManager: false,
      autoSetup: false,
    };
    player.current = videojs(videoNode.current, setup);

    return () => {
      player.current.dispose();
    };
  }, []);

  useEffect(() => {
    if (src) {
      player.current.src(src);
    }
  }, [src]);

  return (
    <div
      data-vjs-player
      ref={(node) => {
        videoWrapperNode.current = node;
      }}
    >
      <video
        ref={(node) => {
          videoNode.current = node;
        }}
        className="video-js"
      >
        {src && <source src={src} />}
        <track
          kind="captions"
          src="sampleCaptions.vtt"
          srcLang="en"
        />
      </video>
    </div>
  );
};

VideoPlayer.propTypes = {
  src: PropTypes.string,
};

export default VideoPlayer;
