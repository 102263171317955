import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Popup,
  Button,
  Label,
} from 'semantic-ui-react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ColorPicker from '../../components/ColorPicker';
import { api } from '../../services';
import { S3UploadModal } from '../Modal';
import { Color, Input } from '../../components/Field';
import { genericActions } from '../../actions';
import { IconButton } from '../../components/Button';
import { visibleContactProperties } from '../../utils/map';

const { openModalPortal, closeModalPortal } = genericActions;
const { S3: { uploadS3Media, uploadSignedFile } } = api;

const DeliveryEmailForm = ({
  logoName: propLogoName,
  color: propColor,
  maxSize,
  subject,
  logo,
  contactProperties,
  openModalPortalA,
  closeModalPortalA,
  change,
}) => {
  const [logoName, setLogoName] = useState(propLogoName);
  const [color, setColor] = useState(propColor);
  const [popupOpen, setPopupOpen] = useState(false);

  const getTextProperties = (text) => {
    const startPoint = text.selectionStart;
    const finishPoint = text.selectionEnd;
    return { startPoint, finishPoint };
  };

  const uploadPicture = (fileBase64, fileName, type, uploadType) => {
    uploadS3Media(type).then((res) => {
      if (res.response.url) {
        const url = new URL(res.response.url);
        const loc = url.origin + url.pathname;
        uploadSignedFile(fileBase64, res.response.url, type).then(() => {
          setTimeout(() => {
            if (uploadType === 'logo') {
              setLogoName(fileName);
            }
          }, 500);
          change(uploadType, loc);
          closeModalPortalA();
        }).catch(closeModalPortalA);
      }
    }).catch(closeModalPortalA);
  };

  const openUploadModal = (which) => {
    const text = which === 'logo' ? 'Logo' : 'Image';

    openModalPortalA({
      content: <S3UploadModal />,
      contentProps: {
        closeModalPortal: closeModalPortalA,
        onUpload: (fileBase64, fileName, type) => uploadPicture(fileBase64, fileName, type, which),
        accept: ['image/*'],
        maxSize,
        modalName: `Upload ${text}`,
      },
    });
  };

  const handleCursorChange = (textProperties, prop, text) => {
    const cursorPosition = textProperties.startPoint + prop.length;
    const textArea = text;
    setTimeout(() => {
      textArea.selectionStart = cursorPosition;
      textArea.selectionEnd = cursorPosition;
    }, 10);
    textArea.focus();
  };

  const handlePersonalizationPropClickSubject = (prop) => {
    let changedProp = prop;
    const text = document.querySelector('input[name="subject"]');
    const textProperties = getTextProperties(text);

    if (textProperties.startPoint > 0 && text.value[textProperties.startPoint - 1] !== ' ') {
      changedProp = ' '.concat(prop);
    }

    const firstPart = subject.slice(0, textProperties.startPoint);
    const lastPart = subject.slice(textProperties.finishPoint);

    const messageTextSupplied = firstPart.concat(changedProp, lastPart);

    change('subject', messageTextSupplied);

    handleCursorChange(textProperties, changedProp, text);
    setPopupOpen(false);
  };

  const changeColor = (value) => {
    change('background', value);
    setColor(value);
  };

  const deletePicture = (type) => {
    change(type, '');
  };

  return (
    <div>
      <div className="delivery-email-input field-subject">
        <Field
          label="Subject"
          component={Input}
          name="subject"
          type="text"
        />
        <Popup
          on="click"
          open={popupOpen}
          onOpen={() => setPopupOpen(true)}
          onClose={() => setPopupOpen(false)}
          position="bottom center"
          className="personalize-popup"
          trigger={(
            <IconButton
              className="tertiary personalize-button"
              size="small"
              color="blue"
              iconName="person"
              text="Personalize"
              type="button"
            />
          )}
        >
          <div>
            <div className="keyword-header-character-limit">
              <span className="keywords-header">Add #keywords to personalize your subject message</span>
            </div>
            <div className="keywords">
              {visibleContactProperties(contactProperties).map((prop, key) => (
                <span
                  className="keyword"
                  key={key}
                  onClick={() => handlePersonalizationPropClickSubject('#'.concat(prop.name, ' '))}
                >
                  {`#${prop.label}`}
                </span>
              ))}
            </div>
            <div className="keyword-example">Example: You sent a message to Joe. In the message you wrote: “Hey, #name. How are you?”. Joe will see: “Hey, Joe. How are you?”</div>
          </div>
        </Popup>
      </div>
      <div className="delivery-email-settings margin-top-10">
        <p>Settings</p>
        <div className="delivery-email-settings__field">
          <Popup
            trigger={(
              <Field
                className="editor-input-color"
                component={Color}
                name="background"
                labelContent="Background color:"
              />
            )}
            position="right center"
          >
            <Popup.Content>
              <ColorPicker
                onChange={changeColor}
                color={color}
                isAbsolute={false}
              />
            </Popup.Content>
          </Popup>
        </div>
        <div className="delivery-email-settings__field">
          <Label content="Company logo" />
          <Button
            color="blue"
            content="Browse"
            type="button"
            onClick={() => openUploadModal('logo')}
          />
          {logo && (
            <p className="company-logo-p">
              <img
                className="image-logo"
                src={logo}
                alt="logo"
              />
              <span className="logo-name-delete-span">
                <span className="logo-name">
                  {logoName}
                </span>
                <span
                  className="delete-logo close modal-close-icon"
                  onClick={() => deletePicture('logo')}
                />
              </span>
            </p>
          )}
        </div>
        <div className="delivery-email-settings__field">
          <Field
            label="Button Text"
            component={Input}
            name="buttonText"
            type="text"
          />
        </div>
      </div>
    </div>
  );
};

DeliveryEmailForm.propTypes = {
  color: PropTypes.string,
  logo: PropTypes.string,
  logoName: PropTypes.string,
  maxSize: PropTypes.number,
  change: PropTypes.func,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  contactProperties: PropTypes.array,
  subject: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
});

export default connect(null, mapDispatchToProps)(DeliveryEmailForm);
