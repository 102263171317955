import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { has } from 'lodash';
import { useScreenWidth } from '../../hooks';
import { calculateDuration } from '../../utils/helper';

const MediaItem = ({
  item,
  index,
  modal,
  handleSelect,
  selected,
  handlePreview,
  handleAdd,
  close,
}) => {
  const {
    fullpath,
    filename,
    width,
    height,
    type,
    duration,
    thumbnail,
  } = item;

  const { isMobile } = useScreenWidth();

  const [isFocused, setIsFocused] = useState(window.innerWidth > 768);

  const clickOutside = useCallback((event) => {
    const box = document.getElementById(index);
    const isClickInsideElement = box ? box.contains(event.target) : undefined;
    if (!isClickInsideElement) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  }, [index, setIsFocused]);

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('click', clickOutside);
    }

    return () => {
      if (isMobile) {
        document.removeEventListener('click', clickOutside);
      }
    };
  }, [isMobile]);

  const processingItem = has(item, 'notification');

  const handleClick = () => {
    if (!modal && !processingItem) {
      handleSelect(index, selected);
    }
  };

  const src = thumbnail || fullpath;

  return (
    <div
      className={`media-library__main__container__list__item${selected ? '--selected' : ''}`}
      onClick={() => handleClick()}
      id={index}
    >
      {type === 'image' ? (
        <img
          className={`media-library__main__container__list__item__img${width < height ? '--vertical' : '--horizontal'}`}
          src={src}
          alt={filename}
        />
      ) : (
        <video className="media-library__main__container__list__item__video" src={fullpath} preload="auto">
          <track
            kind="captions"
            srcLang="en"
          />
        </video>
      )}
      <div className="media-library__main__container__list__item__active">
        {modal && (
          <div
            className="media-library__main__container__list__item__active__add"
            onClick={(e) => {
              e.stopPropagation();
              handleAdd({ mediaUrl: fullpath, title: filename });
              close();
            }}
          >
            Add
          </div>
        )}
        {isFocused && (
          <div
            className="media-library__main__container__list__item__active__preview"
            onClick={(e) => {
              e.stopPropagation();
              handlePreview(index);
            }}
          >
            Preview
          </div>
        )}
      </div>
      {!modal && !processingItem && (
        <div className={`media-library__main__container__list__item__selected${!selected ? '--not' : ''}`} />
      )}
      {type === 'video' && duration && (
        <div className="media-library__main__container__list__item__duration">
          {calculateDuration(duration)}
        </div>
      )}
    </div>
  );
};

MediaItem.propTypes = {
  item: PropTypes.shape({
    fullpath: PropTypes.string,
    filename: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    type: PropTypes.string,
    duration: PropTypes.number,
    thumbnail: PropTypes.string,
  }),
  index: PropTypes.number,
  modal: PropTypes.bool,
  handleSelect: PropTypes.func,
  selected: PropTypes.bool,
  handlePreview: PropTypes.func,
  handleAdd: PropTypes.func,
  close: PropTypes.func,
};

export default MediaItem;
