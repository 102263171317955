import { actionTypes } from './EditorActionTypes';

const setBackgroundColor = (state, payload) => (
  {
    project: {
      ...state.project,
      backgroundColor: payload,
    },
  }
);

const addAttachments = (state, payload) => (
  {
    project: {
      ...state.project,
      attachments: payload,
    },
  }
);

const addElementsData = (state, payload) => (
  {
    project: {
      ...state.project,
      elementsStructureArray: payload,
    },
  }
);

const changeProjectTitle = (state, payload) => (
  {
    project: {
      ...state.project,
      projectTitle: payload,
    },
  }
);

const addElement = (state, payload) => (
  {
    project: {
      ...state.project,
      elementsStructureArray: [...state.project.elementsStructureArray, payload],
    },
  }
);

const setActiveElement = (state, payload) => (
  {
    project: {
      ...state.project,
      activeElement: JSON.parse(JSON.stringify(payload)),
    },
  }
);

const removeElement = (state, payload) => {
  const filteredElement = state.project.elementsStructureArray.filter(
    (element) => element.id !== payload.id);
  return ({
    project: {
      ...state.project,
      elementsStructureArray: filteredElement,
    },
  });
};

const replaceElement = (state, payload) => {
  const modifiedArr = state.project.elementsStructureArray.map(
    (dataArrItem) => (dataArrItem.id !== payload.id ? dataArrItem : payload),
  );
  return ({
    project: {
      ...state.project,
      elementsStructureArray: modifiedArr,
    },
  });
};

const changeUnsubscribe = (state, payload) => ({
  project: {
    ...state.project,
    unsubscribe: payload,
  },
});

const editorReducer = (state, action) => {
  const { type, payload } = action;
  const reducerFunctions = {
    [actionTypes.ADD_PROJECT_ELEMENTS_DATA]: () => addElementsData(state, payload),
    [actionTypes.CHANGE_PROJECT_TITLE]: () => changeProjectTitle(state, payload),
    [actionTypes.ADD_ELEMENT]: () => addElement(state, payload),
    [actionTypes.SET_ACTIVE_ELEMENT]: () => setActiveElement(state, payload),
    [actionTypes.REMOVE_ELEMENT]: () => removeElement(state, payload),
    [actionTypes.REPLACE_ELEMENT]: () => replaceElement(state, payload),
    [actionTypes.ADD_ATTACHMENTS]: () => addAttachments(state, payload),
    [actionTypes.SET_BACKGROUND_COLOR]: () => setBackgroundColor(state, payload),
    [actionTypes.CHANGE_UNSUBSCRIBE]: () => changeUnsubscribe(state, payload),
  };

  if (!action.type) {
    return state;
  }

  return reducerFunctions[type]();
};

export default editorReducer;

