import { createPaginator } from '../modules/redux-paginator';
import { api } from '../services';
import { API_ROOT } from '../config';

const { messages: { messagesGet } } = api;

const messagesPaginator = createPaginator(API_ROOT, ['messages'], {
  resultsKey: 'elements',
  countKey: 'total',
  idKey: 'id',
  pageArgName: 'page',
}, messagesGet);

export default messagesPaginator;
