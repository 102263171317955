import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './WidgetPictureUI.scss';

const WidgetPictureUI = ({
  src,
  width,
  alt,
  className,
}) => (
  <img
    className={className || 'WidgetPictureUI'}
    src={src}
    alt={alt}
    style={{ width }}
  />
);

WidgetPictureUI.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default WidgetPictureUI;
