import PropTypes from 'prop-types';
import { IconButton } from './Button';

const ContactEmpty = ({ openContactFormModal, openContactUploadModal }) => (
  <div>
    <div className="message-prompt">
      <span>There are no contacts yet!</span>
    </div>
    <div className="ct">
      <br />
      <h1>Add Contacts</h1>
      <p>You can either upload your contacts or add them by hand</p>
      <br />
      <IconButton
        onClick={() => openContactFormModal(undefined)}
        iconName="group_add"
        text="New Contact"
      />
      <IconButton
        onClick={openContactUploadModal}
        iconName="file_upload"
        text="Upload Contacts"
      />
    </div>
  </div>
);

ContactEmpty.propTypes = {
  openContactFormModal: PropTypes.func.isRequired,
  openContactUploadModal: PropTypes.func.isRequired,
};
ContactEmpty.defaultProps = {};

export default ContactEmpty;
