import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import AddNodeTypeItem from './AddNodeTypeItem';

const InsertNewNode = ({
  parentId,
  childId,
  hasSiblings,
  handleAddNode,
}) => {
  const triggerId = useMemo(() => `add-trigger-${parentId}-${childId}`, [parentId, childId]);
  const modalId = useMemo(() => `add-modal-${parentId}-${childId}`, [parentId, childId]);
  const [open, setOpen] = useState(false);

  const handleOutsideClick = (e) => {
    const trigger = document.getElementById(triggerId);
    const modal = document.getElementById(modalId);
    if (((trigger && !trigger.contains(e.target)) || !trigger)
      && ((modal && !modal.contains(e.target)) || !modal)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [parentId, childId]);

  const handleClick = (type) => () => {
    setOpen(false);
    handleAddNode(false, type, undefined, parentId, childId);
  };

  const handleTriggerClick = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <div className={`edge__insert${hasSiblings ? '' : ' edge__insert--top'}`}>
      <Modal
        trigger={(
          <span
            className="edge__insert__icon"
            id={triggerId}
            onClick={handleTriggerClick}
          />
        )}
        open={open}
        dimmer="inverted"
        className="edge__new-node-modal"
        id={modalId}
      >
        <Modal.Header>
          What&apos;s your next step?
        </Modal.Header>
        <Modal.Content>
          <div className="edge__new-node-modal__list">
            <AddNodeTypeItem
              iconClassName="action"
              title="Action"
              text="Send a SMS or email, subscribe to list..."
              handleClick={handleClick('action')}
            />
            <AddNodeTypeItem
              iconClassName="filter"
              title="Split"
              text="Split path based on opens, submitted surveys..."
              handleClick={handleClick('filter')}
            />
            <AddNodeTypeItem
              iconClassName="other"
              title="Delay"
              text="Delay time before moving to the next step"
              handleClick={handleClick('other')}
            />
          </div>
        </Modal.Content>
      </Modal>
      <span className="edge__insert__path" />
    </div>
  );
};

InsertNewNode.propTypes = {
  parentId: PropTypes.number,
  childId: PropTypes.number,
  hasSiblings: PropTypes.bool,
  handleAddNode: PropTypes.func,
};

export default InsertNewNode;
