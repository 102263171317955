import PropTypes from 'prop-types';
import ColorPicker from '../ColorPicker';

const RichTextColorPicker = ({
  expanded,
  onExpandEvent,
  onChange,
  currentState,
  doCollapse,
  config: { icon },
}) => {
  const handleChange = (color) => {
    onChange('color', color);
  };

  const renderModal = () => {
    const { color } = currentState;

    return (
      <div className="ColorPickerWrapper" onClick={(e) => e.stopPropagation()}>
        <ColorPicker
          color={color}
          onChange={handleChange}
          onClose={doCollapse}
        />
      </div>
    );
  };

  return (
    <div
      className="rdw-colorpicker-wrapper"
      aria-haspopup="true"
      aria-expanded={expanded}
      aria-label="rdw-color-picker"
    >
      <div className="rdw-option-wrapper" onClick={onExpandEvent}>
        <img src={icon} alt="" />
      </div>
      {expanded && renderModal()}
    </div>
  );
};

RichTextColorPicker.propTypes = {
  expanded: PropTypes.bool,
  onExpandEvent: PropTypes.func,
  onChange: PropTypes.func,
  currentState: PropTypes.object,
  doCollapse: PropTypes.func,
  config: PropTypes.shape({
    icon: PropTypes.string,
  }),
};

export default RichTextColorPicker;
