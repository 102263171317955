import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';

import account from './account';
import accounts from './accounts';
import contactView from './contactView';
import confirmationModal from './confirmationModal';
import dashboardMetrics from './dashboardMetrics';
import modalPortal from './modalPortal';
import entities from './entities';
import user from './user';
import fileUpload from './fileUpload';
import formMetrics from './formMetrics';
import analyticsData from './analyticsData';
import messages from './messages';
import users from './users';
import tags from './tags';
import media from './media';
import plans from './plans';
import messageEditor from './messageEditor';
import paymentHistory from './paymentHistory';
import contactFilter from './contactFilter';
import openQuestions from './openQuestions';
import recipientsMetrics from './recipientsMetrics';
import device from './device';
import lists from './lists';
import messageFilter from './messageFilter';
import views from './views';
import identities from './identities';
import automationList from './automationList';
import billing from './billing';
import {
  contactViewPaginator,
  messagesPaginator,
  paymentHistoryPaginator,
  usersPaginator,
  accountsPaginator,
  automationPaginator,
} from '../paginators';
import { genericActions } from '../actions';

const { CLEAR_STATE_ENTITY, CLEAR_PAGINATOR, CLEAR_REDUX_STATE } = genericActions;

const appReducers = combineReducers({
  account,
  accounts,
  analyticsData,
  confirmationModal,
  contactFilter,
  contactView,
  dashboardMetrics,
  form,
  formMetrics,
  fileUpload,
  tags,
  media,
  modalPortal,
  notifications,
  entities,
  messages,
  openQuestions,
  users,
  user,
  plans,
  paymentHistory,
  messageEditor,
  recipientsMetrics,
  device,
  lists,
  messageFilter,
  views,
  identities,
  automationList,
  billing,
  contactPaginator: contactViewPaginator.reducers,
  paymentsPaginator: paymentHistoryPaginator.reducers,
  messagesPaginator: messagesPaginator.reducers,
  usersPaginator: usersPaginator.reducers,
  accountsPaginator: accountsPaginator.reducers,
  automationPaginator: automationPaginator.reducers,
});

const rootReducer = (state, action) => {
  let newState = state;

  if (action.type === CLEAR_REDUX_STATE) {
    const keys = Object.keys(state);

    keys.forEach((key) => {
      newState = {
        ...newState,
        [key]: {},
        notifications: [],
      };
    });
  }

  if (action.type === CLEAR_STATE_ENTITY) {
    return {
      ...state,
      [action.entityName]: {},
    };
  }

  if (action.type === CLEAR_PAGINATOR) {
    return {
      ...state,
      [action.paginatorName]: {
        params: {},
        pages: {},
        currentPages: {},
      },
    };
  }

  return appReducers(newState, action);
};


export default rootReducer;
