import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  get,
  find,
  remove,
  forEach,
} from 'lodash';
import {
  Dropdown,
  Header,
  Input,
  Popup,
} from 'semantic-ui-react';
import { BasicButton } from '../Button';
import { TABLET_MAX_WIDTH, headerHeight, lowWarningHeight } from '../../common';
import { ContactFilterModal } from '../../containers/Modal';
import { genericActions, notificationActions, contactActions } from '../../actions';
import { listEdit } from '../../services/contactApi';
import { ContactsSelectedMenu, ContactsMainMenu } from './index';
import usePrevious from '../../hooks/usePrevious';
import { delayFunctionCall } from '../../utils/helper';

const { openModalPortal, closeModalPortal } = genericActions;
const { notifyError, notifySuccess } = notificationActions;
const {
  updateListName,
  createContactFilter,
  updateContactFilter,
  clearContactFilter,
} = contactActions;

const CONTACTS_TITLE_DESKTOP = 88;
const CONTACTS_TITLE_REST = 140;
const ANIMATION_DURATION = 700;

const ContactsHeader = ({
  list,
  visible,
  lists,
  openModalPortalA,
  closeModalPortalA,
  windowWidth,
  clearSort,
  handleUnselect,
  contactFilter,
  dispatch,
  clearContactFilterA,
  notifyErrorA,
  notifySuccessA,
  updateListNameA,
  openContactFormModal,
  openContactUploadModal,
  pageSize,
  currentPageSizes,
  setPageSize,
  totalCount,
  handleSelectionVisibility,
  openColumnsModal,
  onDeleteClick,
  selected,
  excluded,
  onMenuItemClick,
  listAction,
}) => {
  const [filterPopupOpen, setFilterPopupOpen] = useState(false);
  const [listName, setListName] = useState('');
  const [focused, setFocused] = useState(false);
  const [_list, setList] = useState('');
  const [search, setSearch] = useState('');
  const [lastSearch, setLastSearch] = useState('');
  const [touched, setTouched] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [selectedVisible, setSelectedVisible] = useState(visible);
  const [headerVisible, setHeaderVisible] = useState(!visible);
  const [animation, setAnimation] = useState(visible);
  const [hidingHeader, setHidingHeader] = useState(false);
  const [hidingSelected, setHidingSelected] = useState(false);

  const prevVisible = usePrevious(visible);

  useEffect(() => {
    window.addEventListener('scroll', () => handleScroll());

    return () => {
      window.removeEventListener('scroll', () => handleScroll());
    };
  }, []);

  useEffect(() => {
    const { id } = _list;

    if (list !== id) {
      setList(list);
      setSearch('');
      setLastSearch('');
      if (typeof list === 'number') {
        setListName(getListName(list));
      }
    }
  }, [list]);

  useEffect(() => {
    if (prevVisible !== undefined && prevVisible !== visible) {
      setAnimationState(visible);
    }
  }, [visible]);

  useEffect(() => {
    delayFunctionCall(() => updateFilters(), 500);
  }, [search]);

  const setAnimationState = (updatedAnimation) => {
    if (updatedAnimation) {
      setSelectedVisible(true);
      setAnimation(updatedAnimation);
      setHidingHeader(true);
      setHidingSelected(false);
      setHeaderVisibleX();
    } else {
      setHeaderVisible(true);
      setAnimation(updatedAnimation);
      setHidingHeader(false);
      setHidingSelected(true);
      setSelectedVisibleState();
    }
  };

  const setSelectedVisibleState = () => {
    if (document.getElementById('contact-select-menu')) {
      document.getElementById('contact-select-menu').classList.add('contact-select-menu--animation');
      setTimeout(() => {
        setSelectedVisible(false);
        setHidingSelected(false);
      }, ANIMATION_DURATION);
    }
  };

  const setHeaderVisibleX = () => {
    if (document.getElementById('contact-select-menu')) {
      document.getElementById('contact-select-menu').classList.remove('contact-select-menu--animation');
      handleScroll();
      setTimeout(() => {
        if (hidingHeader) {
          setHeaderVisible(false);
          setHidingHeader(false);
        }
      }, ANIMATION_DURATION);
    }
  };

  const handleScroll = () => {
    const menu = document.getElementById('contact-select-menu');
    if (menu) {
      let offsetTop = window.innerWidth > 991
        ? headerHeight + CONTACTS_TITLE_DESKTOP : headerHeight + CONTACTS_TITLE_REST;
      if (document.getElementsByClassName('low-warning').length > 0) {
        offsetTop += lowWarningHeight;
      }

      if (window.pageYOffset >= offsetTop) {
        menu.classList.add('sticky-contact-menu');
      } else {
        menu.classList.remove('sticky-contact-menu');
        menu.classList.remove('contact-select-menu--animation');
      }
    }
  };

  const onChange = ({ value }) => {
    let errorMessage;
    if (value.length === 0) {
      errorMessage = 'This field is required';
    } else if (value.length > 50) {
      errorMessage = 'List name should be less than 50 characters';
    }
    setListName(value);
    setError(errorMessage);
  };

  const getListName = (id) => find(lists, (item) => item.id === id).name;

  const getFiltersCount = (filters) => {
    let count = 0;
    forEach(filters, (filter) => {
      if (filter.operator !== 'search' && filter.value) {
        count += 1;
      }
    });
    return count;
  };

  const handleSearchInput = ({ value }) => setSearch(value);

  const openContactFilter = () => {
    // If window width is less than 767 we dispatching action to
    // open contact filter modal although the event is firing from
    // the POPUP trigger. Popup is not showing because it is controled
    // element(open prop remains false)
    if (windowWidth <= TABLET_MAX_WIDTH) {
      openModalPortalA({
        content: <ContactFilterModal />,
        contentProps: {
          size: windowWidth < TABLET_MAX_WIDTH ? 'fullscreen' : 'small',
          handleFilterModalState: closeModalPortalA,
          windowWidth: window.innerWidth < TABLET_MAX_WIDTH,
          changeFilter: () => changeFilter(),
          clearSort: () => clearSort(),
        },
      });
      // We handle popup state
    } else {
      setFilterPopupOpen(!filterPopupOpen);
    }
  };

  const changeFilter = () => {
    // when changing filter, clear currently selected contacts
    handleUnselect(true);
  };

  const combineFilters = (filters, value) => {
    let updatedFilters = [];
    if (filters.id) {
      updatedFilters = [...filters.filters];
    }
    remove(updatedFilters, (filter) => filter.operator === 'search');
    if (value) {
      updatedFilters.push({ operator: 'search', property: 'any', value });
    }
    return updatedFilters;
  };

  const updateFilters = () => {
    if (search !== lastSearch) {
      setLastSearch(search);
      const filters = combineFilters(contactFilter, search);

      if (filters.length) {
        const payload = {
          ...contactFilter,
          conjunction: 'and',
          filters,
          list: _list,
        };

        if (contactFilter.id) {
          updateContactFilter(payload, dispatch);
        } else {
          createContactFilter(payload, dispatch);
        }
      } else if (contactFilter.id) {
        clearContactFilterA({ list: _list });
      }

      changeFilter();
    }
  };

  const handleListNameChange = () => {
    if (listName !== getListName(list)) {
      const payload = {
        id: list,
        body: {
          name: listName,
        },
      };

      listEdit(payload).then((res) => {
        if (!res.error) {
          notifySuccessA(`List ${listName} successfully updated`);
          updateListNameA({ id: list, name: listName });
        } else {
          notifyErrorA('Something went wrong... Please try again later.');
        }
      });
    }
    setFocused(false);
  };

  const handleInputClicked = () => setFocused(true);

  const handleListNameChangeCancel = (listId) => {
    setFocused(false);
    setListName(getListName(listId));
  };

  const renderListName = (isList) => {
    if (!isList) {
      return (
        <Header as="h1">{list === 'all' ? 'All Contacts' : 'Unsubscribed'}</Header>
      );
    }
    if (!focused) {
      return (
        <div
          className="list-name__header"
          onClick={() => handleInputClicked()}
        >
          <Header as="h1">{listName}</Header>
          <i className="material-icons">create</i>
        </div>
      );
    }
    return (
      <div className="list-name__header--input ris-form">
        <div className="field input list-name__header--input__wrapper">
          <Input
            type="text"
            placeholder="List Name"
            value={listName}
            onChange={(e, data) => onChange(data)}
            onFocus={() => setTouched(true)}
            className="list-name__input"
          />
          {
            touched && error && (
              <Popup
                className="error-popup"
                trigger={<i className="material-icons input-error-icon">error</i>}
                position="top right"
                content={error}
              />
            )
          }
        </div>
        <BasicButton
          content="Save"
          color="blue"
          size="small"
          onClick={() => handleListNameChange()}
          disabled={!!error}
        />
        <BasicButton
          content="Cancel"
          color="white"
          size="small"
          onClick={() => handleListNameChangeCancel(list)}
        />
      </div>
    );
  };

    const filterId = get(contactFilter, 'id', undefined);
    const { filters } = contactFilter;

    const numberOfFilters = getFiltersCount(filters);
    const filterCount = filterId && numberOfFilters ? `${numberOfFilters} ` : '';
    const filterPlural = filterId && numberOfFilters % 10 !== 1 && numberOfFilters % 100 === 11 ? 's' : '';
    const filterText = `${filterCount}Filter${filterPlural}`;

    const isList = typeof list === 'number';

    let currentPageSize = pageSize;
    if (totalCount < pageSize) {
      currentPageSize = totalCount < 10 ? totalCount : 10;
    }

    return (
      <div className="contacts">
        {windowWidth <= 991 && (
          <div
            className="contacts-list-expand"
            onClick={() => handleSelectionVisibility()}
          />
        )}
        <div className="contacts-header-container">
          {renderListName(isList)}
          <Dropdown
            className="dropdown-contacts-header"
            trigger={<span>Settings</span>}
          >
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => openColumnsModal()}>
                Change Data
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {headerVisible && (
          <ContactsMainMenu
            animation={animation}
            openContactFormModal={openContactFormModal}
            isList={isList}
            openContactUploadModal={openContactUploadModal}
            filterText={filterText}
            openContactFilter={openContactFilter}
            filterPopupOpen={filterPopupOpen}
            changeFilter={changeFilter}
            list={list}
            clearSort={clearSort}
            search={search}
            handleSearchInput={handleSearchInput}
            totalCount={totalCount}
            currentPageSize={currentPageSize}
            currentPageSizes={currentPageSizes}
            setPageSize={setPageSize}
            disabled={hidingSelected || hidingHeader}
          />
        )}
        {selectedVisible && (
          <ContactsSelectedMenu
            animation={animation}
            totalCount={totalCount}
            selected={selected}
            excluded={excluded}
            listAction={listAction}
            clickMenuItem={onMenuItemClick}
            onDeleteClick={onDeleteClick}
          />
        )}
      </div>
    );
};

ContactsHeader.propTypes = {
  openContactFormModal: PropTypes.func,
  openContactUploadModal: PropTypes.func,
  pageSize: PropTypes.number,
  currentPageSizes: PropTypes.array,
  setPageSize: PropTypes.func,
  totalCount: PropTypes.number,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  windowWidth: PropTypes.number,
  contactFilter: PropTypes.object,
  handleSelectionVisibility: PropTypes.func,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  lists: PropTypes.array,
  handleUnselect: PropTypes.func,
  notifyErrorA: PropTypes.func,
  notifySuccessA: PropTypes.func,
  updateListNameA: PropTypes.func,
  clearSort: PropTypes.func,
  clearContactFilterA: PropTypes.func,
  dispatch: PropTypes.func,
  openColumnsModal: PropTypes.func,
  onDeleteClick: PropTypes.func,
  visible: PropTypes.bool,
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  excluded: PropTypes.array,
  onMenuItemClick: PropTypes.func,
  listAction: PropTypes.func,
};

const mapStateToProps = (state) => ({
  contactFilter: state.contactFilter,
  lists: state.lists.elements,
});

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  updateListNameA: bindActionCreators(updateListName, dispatch),
  clearContactFilterA: bindActionCreators(clearContactFilter, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsHeader);
