import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { BasicButton } from '../../components/Button';

const AutomationMessageRemoveModal = ({
  open,
  close,
  handleRemove,
  handleDelete,
}) => (
  <Modal
    open={open}
    onClose={close}
    className="modal"
    dimmer="inverted"
  >
    <Modal.Header>
      Message remove
    </Modal.Header>
    <Modal.Content>
      <div className="delete-column-modal__content--column">
        <span className="delete-column-modal__content__disclaimer">
          Messages, when no longer used in automation, can be removed from the list
          or deleted.
        </span>
        <span className="delete-column-modal__content__disclaimer">
          Would you like to remove or delete message?
        </span>
      </div>
    </Modal.Content>
    <Modal.Actions>
      <div className="delete-column-modal__actions">
        <BasicButton
          content="Cancel"
          size="small"
          className="white"
          onClick={close}
        />
        <BasicButton
          content="Remove"
          size="small"
          color="blue"
          onClick={handleRemove}
        />
        <BasicButton
          content="Delete"
          size="small"
          color="red"
          onClick={handleDelete}
        />
      </div>
    </Modal.Actions>
  </Modal>
);

AutomationMessageRemoveModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  handleRemove: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default AutomationMessageRemoveModal;
