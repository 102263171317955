import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components.
import SelectInput from '../../../../components/SelectInput';
import TextInput from '../../../../components/TextInput';
import { Icon } from '../../../../../components/Icon';

// Constants.
import { defaultDropdownOptions } from '../../../../constants/defaultDropdownOptions';

// Context.
import { EditorContext } from '../../../../context/EditorContext';

// Styles.
import './OptInInputsController.scss';

const OptInInputsController = () => {
  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);
  const { data } = activeElement;

  const modifyDropdownData = (inputValue, inputData) => {
    const dataObj = { ...activeElement };
    const dropdownValue = inputValue.value;
    const dropdownObj = inputValue.options.find((value) => value.name === dropdownValue);
    const index = dataObj.data.inputFields.findIndex(((obj) => obj.name === inputData.name));

    dataObj.data.inputFields[index].type = dropdownObj.type;
    dataObj.data.inputFields[index].name = dropdownObj.value;
    dataObj.data.inputFields[index].value = dropdownObj.value;
    dataObj.data.inputFields[index].text = dropdownObj.text;

    setActiveElement(dataObj);
  };

  const modifyInputData = (inputValue, inputData) => {
    const dataObj = { ...activeElement };
    const index = dataObj.data.inputFields.findIndex(((obj) => obj.name === inputData.name));
    dataObj.data.inputFields[index].placeholder = inputValue;

    setActiveElement(dataObj);
  };

  const removeInputField = (inputObj) => {
    const { id } = inputObj;
    const dataObj = { ...activeElement };
    const filteredArray = dataObj.data.inputFields.filter((input) => input.id !== id);

    dataObj.data.inputFields = filteredArray;

    setActiveElement(dataObj);
  };

  const addInputField = () => {
    const dataObj = { ...activeElement };
    const inputFieldObj = {
      id: uuidv4(),
      value: '',
      text: '',
      name: '',
      type: 'text',
      placeholder: 'Type your text here',
    };

    const inputFieldArray = dataObj.data.inputFields;
    inputFieldArray.push(inputFieldObj);

    setActiveElement(dataObj);
  };

  const defineDropDownOptions = (option) => {
    const possibleDropdownOptions = defaultDropdownOptions.filter(
      (dropdownOption) => !data.inputFields.find(
        (inputField) => dropdownOption.value === inputField.value),
    );
    if (option.value) {
      return [...possibleDropdownOptions, option];
    }
    return possibleDropdownOptions;
  };

  return (
    <div className="OptInInputsController">
      <div className="OptInInputsController__header">
        <p className="OptInInputsController__header-title">Input fields</p>
      </div>

      {!!data.inputFields.length && (
      <div className="OptInInputsController__inputs-container">

        {data.inputFields.map((input) => (
          <div className="OptInInputsController__input-container" key={input.id}>
            <div className="OptInInputsController__input-inner-container">
              <div className="OptInInputsController__close-button-wrapper">
                <Icon name="close" onClick={() => removeInputField(input)} />
              </div>
              <div className="OptInInputsController__select-input-wrapper">
                <SelectInput
                  placeholder="Type"
                  defaultValue={input.name}
                  options={defineDropDownOptions(input)}
                  onChange={(e, selection) => modifyDropdownData(selection, input)}
                />
              </div>
            </div>
            <div className="OptInInputsController__text-input-wrapper">
              <TextInput
                type="text"
                placeholder={input.placeholder}
                value={input.placeholder}
                onChange={(e) => modifyInputData(e.target.value, input)}
              />
            </div>
          </div>
          ))}
      </div>
      )}
      <div className="OptInInputsController__add-input-container">

        {(data.inputFields.length < 4)
        && (
          <button
            className="OptInInputsController__add-input-button"
            type="button"
            onClick={() => addInputField()}
          >
            Add next field
          </button>
        )}
      </div>
    </div>
  );
};

export default OptInInputsController;
