import { call, takeLatest, takeEvery } from 'redux-saga/effects';
import { analyticsActions, request } from '../actions';
import { api } from '../services';
import { requestSaga } from './genericSagas';

const {
  analytics: {
    openAnswersMetricsGet,
    recipientMetricsSearch,
    getDashboardMetrics,
  },
  events: { recipientsDataGet },
} = api;

const { REQUEST } = request;

const {
  fetchRecipientsMetrics,
  fetchOpenAnswersForms,
  fetchDashboardMetrics,
  searchRecipientMetrics,
  FETCH_RECIPIENTS_METRICS,
  FETCH_OPEN_ANSWERS_FORM_METRICS,
  SEARCH_RECIPIENT_METRICS,
  FETCH_DASHBOARD_METRICS,
} = analyticsActions;

function* getRecipientsMetrics(args) {
  yield call(requestSaga, fetchRecipientsMetrics, recipientsDataGet, args);
}

function* getOpenAnswersMetricsSaga(args) {
  const { form } = args.payload;
  yield call(requestSaga, fetchOpenAnswersForms, openAnswersMetricsGet, args, null, form);
}

function* searchRecipientMetricsSaga(args) {
  yield call(requestSaga, searchRecipientMetrics, recipientMetricsSearch, args);
}

function* getDashboardMetricsSaga(args) {
  const { deliveryMethod } = args.payload;
  yield call(requestSaga, fetchDashboardMetrics, getDashboardMetrics, args, null, deliveryMethod);
}

export default function* watchAnalytics() {
  yield takeLatest(FETCH_RECIPIENTS_METRICS[REQUEST], getRecipientsMetrics);
  yield takeLatest(SEARCH_RECIPIENT_METRICS[REQUEST], searchRecipientMetricsSaga);
  yield takeEvery(FETCH_DASHBOARD_METRICS[REQUEST], getDashboardMetricsSaga);
  yield takeEvery(FETCH_OPEN_ANSWERS_FORM_METRICS[REQUEST], getOpenAnswersMetricsSaga);
}
