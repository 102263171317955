import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, useParams } from 'react-router';
import { destroy, getFormValues } from 'redux-form';
import {
  get,
  isEmpty,
  forEach,
} from 'lodash';

// Modules.
import { render } from 'react-dom';
import { clearPaginatorPromiseArray } from '../../modules/redux-paginator';

// Redux actions.
import {
  genericActions,
  contactActions,
  messageActions,
  notificationActions,
} from '../../actions';

// Api.
import {
  messageUpdate,
} from '../../services/messageApi';

// Utils.
import {
  formatVideoAttachments,
  formatDeliveryDataSavePayload,
 } from '../../utils/format';
import {
  appendCss,
  appendScript,
  appendIco,
  prepareMsgBlocks,
  removeSubmits,
  addWeights,
  createInitAndVideoScript,
} from '../../utils/dom';

// Constants.
import {
  VIDEO_JS_CSS_SRC,
  VIDEO_JS_SRC,
  VIDEO_JS_YOUTUBE_SRC,
  VIDEO_ANALYTICS_SRC,
  MSG_LAYOUT_CSS,
  MESSAGE_FORM_SUBMIT_SRC,
  MESSAGE_OPTIN_SUBMIT_SRC,
  BUTTON_LINK_ATTACHMENT_SRC,
  JQUERY_SRC,
  RATING_SRC,
  HOVER_SRC,
  COLOR_CHANGE_SRC,
  SURVEY_VALIDATION_SRC,
  FONTS_SRC,
  JQUERY_TOUCH,
  MESSAGE_LOAD,
  RISPONS_ICON,
  SURVEY_FEEDBACK_SRC,
} from '../../config';
import { MESSAGE_VIEW_WRAPPER_ID } from '../../common';
import { BUTTON, VIDEO, OPT_IN } from '../../editor/constants/widgetTypes';

import {
  DELIVERY_STEP,
  CONTACTS_STEP,
  DESIGN_STEP,
} from '../../common/constants';

// Context.
import { EditorContext } from '../../editor/context/EditorContext';

// Hooks.
import { useInterval } from '../../hooks/interval';

// Utilities.
import { mapEditorData, mapFileData } from '../../editor/utils/mapEditorData';
import { generateQuizPayload } from '../../editor/utils/generateQuizPayload';
import MessagePreview from '../../editor/compositions/MessagePreview';

const { openConfirmationModal } = genericActions;
const { fetchContactProperties } = contactActions;
const {
  fetchMessage,
  createMessage,
  updateMessageEditorState,
  CLEAR_MESSAGE_EDITOR_STATE,
  saveMessageDeliveryData,
  deleteMessage,
  saveMessage,
  getRecipientsMetadata,
  updateMessageDesign,
  clearMessages,
} = messageActions;
const { notifyError } = notificationActions;

// gets HTML from backend
export const getHTML = (url, callback) => {
  // Feature detection
  if (!window.XMLHttpRequest) return;

  // Create new request
  const xhr = new XMLHttpRequest();

  xhr.open('GET', url);
  // Setup callback
  xhr.onload = (res) => {
    callback(res.target.response);
  };

  // Get the HTML
  xhr.responseType = 'text';
  xhr.setRequestHeader('Cache-Control', 'no-cache');
  xhr.send();
};

export const MessageContext = createContext({
  messageId: undefined,
  blocks: [],
  editorToolbarOpen: false,
  attachments: [],
  currentStep: DESIGN_STEP,
  messageName: '',
  autosaveMsg: { show: false, updatedAt: '' },
  unsubscribe: false,
  canSend: true,
  list: 'all',
  addNewList: false,
  messageEditor: {},
  isAutomation: false,
  setCurrentStep: () => {},
  handleMessageNameChange: () => {},
  handleBackButton: () => {},
  handleLogoClick: () => {},
  handleSaveAndExitButton: () => {},
  turnOnAutoSave: () => {},
  turnOffAutoSave: () => {},
  changeUnsubscription: () => {},
  handleSave: () => {},
});

const MessageContextProvider = ({
  fetchContactPropertiesA,
  openConfirmationModalA,
  children,
  messages,
  fetchMessageA,
  updateMessageEditorStateA,
  messageEditor,
  createMessageA,
  notifyErrorA,
  destroyA,
  clearMsgEditorState,
  saveMessageDeliveryDataA,
  deliveryMethodFormVals,
  account,
  deleteMessageA,
  saveMessageA,
  getRecipientsMetadataA,
  setCurrentStepLayout,
  automationMessageId,
  automationCurrentStep,
  setAutomationCurrentStep,
  automationMessageName,
  setAutomationMessageName,
  updateMessageDesignA,
  isAutomation,
  clearMessagesA,
}) => {
  const {
    addElementsData,
    setBackgroundColor,
    elementsStructureArray,
    isChangesDetected,
    setChangesDetected,
    addAttachments,
    attachments,
    projectData,
    setUnsubscribe: setUnsubscribeEditorContext,
  } = useContext(EditorContext);

  const elementsStructureArrayRef = useRef(null);
  const attachmentsRef = useRef(null);
  const unsubscribeRef = useRef(false);

  useEffect(() => {
    elementsStructureArrayRef.current = elementsStructureArray;
  }, [elementsStructureArray]);

  useEffect(() => {
    attachmentsRef.current = attachments;
  }, [attachments]);

  useEffect(() => {
    unsubscribeRef.current = projectData.unsubscribe;
  }, [projectData.unsubscribe]);

  // Using isChangesDetected as reference value
  // to trigger autosave function (T11959).
  // If we using value directly we're encountering
  // stale closure issue (same as with elementsStructureArray)
  const isChangesDetectedRef = useRef(null);
  isChangesDetectedRef.current = isChangesDetected;

  const messageEditorRef = useRef(null);
  messageEditorRef.current = messageEditor;

  const { goBack, push } = useHistory();
  const { id: mId } = useParams();
  const id = useMemo(() => {
    if (isAutomation) {
      return automationMessageId;
    }
    return mId;
  }, [isAutomation, automationMessageId, mId]);

  const [initial, setInitial] = useState(true);
  const [autosaveMsg, setAutosaveMsg] = useState({ show: false, updatedAt: '' });
  const [{
    list,
    addNewList,
  }, setListState] = useState({
    list: 'all',
    addNewList: false,
  });

  const [currentStep, setCurrentStepState] = useState(DESIGN_STEP);
  const [messageName, setMessageName] = useState('');
  const [createFirst, setCreateFirst] = useState(true);
  const [isMessageLoading, setMessageLoading] = useState(true);

  const [{
    underlyingStep,
    underlyingStepCallback,
  }, setUnderlyingStepState] = useState({
    underlyingStep: null,
    underlyingStepCallback: undefined,
  });

  const [time, setTime] = useState(new Date());
  const [canSend, setCanSend] = useState(true);
  const [delay, setDelay] = useState(undefined);
  const [previousStep, setPreviousStep] = useState(undefined);

  const messageNameRef = useRef('');
  messageNameRef.current = messageName;

  useEffect(() => {
    if (underlyingStepCallback) {
      underlyingStepCallback();
    }
  }, [underlyingStepCallback]);

  const { showAutosaveMsg, resolveUnderlyingStep } = messageEditor;

  // adds <template> element with message data to DOM.
  const addMsgTemplateToDOM = (html) => {
    setMessageLoading(true);
    const reg = /<body[^>]*>([^]*)<\/body/m;
    const dataElementReg = /<data-element[^>]*>([^]*)<\/data-element/m;
    const parsedBody = html.match(reg);

    const dataElement = html.match(dataElementReg);

    const template = document.createElement('template');
    const parsedBodyWrapper = document.createElement('div');

    template.setAttribute('id', 'message-template');
    /* eslint-disable */
    if (!!parsedBody) {
      parsedBodyWrapper.innerHTML = parsedBody[1];
      const preparedMsg = removeSubmits(parsedBodyWrapper.firstChild);
      template.appendChild(addWeights(preparedMsg));
    }
    /* eslint-enable */

    const currentTemplate = document.getElementById('message-template');
    if (currentTemplate) {
      currentTemplate.remove();
    }
    document.body.appendChild(template);

    // checking if out HTML from BE contains node with elements data
    if (dataElement) {
      const dataObj = JSON.parse(dataElement[1]);
      const elementsDataArr = dataObj.elementsStructureArray;
      const attachmentsObj = dataObj.attachments;
      const bgColor = dataObj.backgroundColor;
      const unsubscribe = !!dataObj?.unsubscribe;

      addElementsData(elementsDataArr);
      addAttachments(attachmentsObj);
      setBackgroundColor(bgColor || '#fff');
      setMessageLoading(false);
      setUnsubscribeEditorContext(unsubscribe);
    // else creating compatible elements array from HTML created with old editor
    } else {
      const { compatibleDataArr, isUnsubscribe } = mapEditorData(messages[id]);
      setMessageLoading(false);
      addElementsData(compatibleDataArr);
      addAttachments(mapFileData(messages[id]));
      setBackgroundColor(messages[id].schema.backgroundColor || '#fff');
      setUnsubscribeEditorContext(isUnsubscribe);
    }
  };

  const initializeMessage = (message, nextStep = undefined) => {
    const url = get(message, 's3Data.documentLocation', undefined);

    if (nextStep !== DELIVERY_STEP) {
      setMessageName(message.name);
    }

    setCreateFirst(false);

    getHTML(url, (res) => {
      addMsgTemplateToDOM(res);
    });

    updateMessageEditorStateA({
      payload: {
        ...message,
      },
    });
  };

  // not sure if everytime messages comes empty so just to be sure
  useEffect(() => {
    clearMessagesA();
  }, []);

  useEffect(() => {
    localStorage.setItem('removeMessageTemplate', false);
    fetchContactPropertiesA();
    if (id) {
      fetchMessageA(id);
    }
    if (id) {
      getRecipientsMetadataA({ id });
    }
    setInitial(false);

    return () => {
      const template = document.getElementById('message-template');

      localStorage.setItem('removeMessageTemplate', 'true');
      setChangesDetected(false);
      if (template) {
        template.remove();
      }

      destroyA('DeliveryMethodForm');
      clearPaginatorPromiseArray();
      clearMsgEditorState();
      setInitial(true);
    };
  }, [id]);

  useEffect(() => {
    if (!initial && createFirst && messages[id]) {
      initializeMessage(messages[id]);
    }
  }, [createFirst, messages, id]);

  const showAutosaveMessage = () => {
    const minutes = `${time.getMinutes() < 10 ? '0' : ''}${time.getMinutes()}`;
    const fullTime = `${time.getHours()}:${minutes}`;
    setAutosaveMsg({ show: true, updatedAt: fullTime });
    setTimeout(() => setAutosaveMsg({ ...autosaveMsg, show: false }), 6000);
  };

  useEffect(() => {
    if (!initial && showAutosaveMsg) {
      showAutosaveMessage();
      updateMessageEditorStateA({ payload: { showAutosaveMsg: false } });
    }
  }, [showAutosaveMsg]);

  useEffect(() => {
    if (!initial && underlyingStep && resolveUnderlyingStep !== undefined) {
      if (resolveUnderlyingStep === true) {
        underlyingStep();
        updateMessageEditorStateA({
          payload: { resolveUnderlyingStep: undefined },
        });
      }
      if (resolveUnderlyingStep === false) {
        setUnderlyingStepState((s) => ({ ...s, underlyingStep: null }));
        updateMessageEditorStateA({ payload: { resolveUnderlyingStep: undefined } });
      }
    }
  }, [underlyingStep, resolveUnderlyingStep]);

  // compatible with JSON
  const validateVideoAttachments = () => {
    const videoElements = elementsStructureArrayRef.current.filter(
      (element) => element.elementType === VIDEO);

    if (videoElements.length) {
      const videoSources = videoElements.map((videoElement) => videoElement.data.sources[0].src);

      const isInvalidVideoSourceExists = videoSources.every((item) => !item);

      return { videoError: isInvalidVideoSourceExists, videoErrorMsg: 'Please select a video' };
    }

    return { videoError: false, videoErrorMsg: '' };
  };

  // compatible with JSON
  const validateOptInInputs = () => {
    let isRequiredFieldDefined = true;
    let isEmptyFieldExists = false;

    const errorsObj = {
      requiredFieldNeeded: { optInError: true, optInErrorMsg: 'Opt-in must have either Phone Number or Email field' },
      emptyFieldExists: { optInError: true, optInErrorMsg: 'You haven\'t selected type for the Opt-in field' },
      noError: { optInError: false, optInErrorMsg: '' },
    };

    const optInElements = elementsStructureArrayRef.current.filter(
      (element) => element.elementType === OPT_IN);
    const optInInputFields = optInElements.map((optInElement) => optInElement.data.inputFields);

    optInInputFields.forEach((optInWidget) => {
      const requiredFieldExists = optInWidget.some(
        (item) => item.name === 'contact.phoneNumber' || item.name === 'contact.email');

      const undefinedFieldsExists = optInWidget.some(
        (item) => (item.name === ''));


        if (!requiredFieldExists) {
          isRequiredFieldDefined = false;
        }

        if (undefinedFieldsExists) {
          isEmptyFieldExists = true;
        }
    });

    if (!isRequiredFieldDefined) {
      return errorsObj.requiredFieldNeeded;
    }

    if (isEmptyFieldExists) {
      return errorsObj.emptyFieldExists;
    }

    return errorsObj.noError;
  };

  // compatible with JSON
  const validateButtonLinks = () => {
    // eslint-disable-next-line no-useless-escape
    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    const regex = new RegExp(urlRegex);

    const validLinks = elementsStructureArrayRef.current.filter(
      (element) => element.elementType === BUTTON).map(
        (button) => button.link).every(
          (item) => item.match(regex));

    const errorMessage = 'All buttons must have a valid url';

    return { buttonError: !validLinks, buttonErrorMsg: errorMessage };
  };

  const validateMessageContent = () => {
    const isMessageEmpty = elementsStructureArrayRef.current.length > 0;
    const errorMessage = 'Please make sure message name and valid content exists';

    return { messageEmptyError: !isMessageEmpty, messageEmptyErrorMsg: errorMessage };
  };

  // compatible with JSON
  const printErrorMessage = ({
    editorValidate,
    video,
    optIn,
    button,
    messageContent,
  }, customErrorMessage) => {
    let errorMsg = '';
    switch (currentStep) {
      case DESIGN_STEP: {
        errorMsg = 'Please make sure message name and valid content exists';
        setCurrentStepLayout(DESIGN_STEP);

        if (messageContent) {
          errorMsg = customErrorMessage;
        }
        if (editorValidate) {
          errorMsg = 'No contacts selected';
        }
        if (video) {
          errorMsg = customErrorMessage;
        }
        if (optIn) {
          errorMsg = customErrorMessage;
        }
        if (button) {
          errorMsg = customErrorMessage;
        }
        break;
      }
      case CONTACTS_STEP: {
        errorMsg = 'No contacts selected';
        break;
      }
      default:
        break;
    }

    notifyErrorA(errorMsg);
  };

  // compatible with JSON
  const validateUnderlyingStep = (showErrorMessage, nextStep, autosave) => {
    let validationPass = true;
    let showedError = true;

    if (currentStep !== nextStep) {
      switch (currentStep) {
        case DESIGN_STEP: {
          const {
            buttonError,
            buttonErrorMsg,
          } = validateButtonLinks();

          const {
            optInError,
            optInErrorMsg,
          } = validateOptInInputs();

          const {
            videoError,
            videoErrorMsg,
          } = validateVideoAttachments();

          const {
            messageEmptyError,
            messageEmptyErrorMsg,
          } = validateMessageContent();

          if (optInError || buttonError || videoError || messageEmptyError) {
            validationPass = false;
            showedError = false;
            if (optInError) printErrorMessage({ optIn: true }, optInErrorMsg);
            if (buttonError) printErrorMessage({ button: true }, buttonErrorMsg);
            if (messageEmptyError) {
              printErrorMessage({ messageContent: true }, messageEmptyErrorMsg);
            }
            if (videoError && !autosave) printErrorMessage({ video: true }, videoErrorMsg);
          }
          break;
        }
        default:
          break;
      }

      if (!validationPass && showErrorMessage && showedError) {
        printErrorMessage({});
      }
    }
    return validationPass;
  };

  const allowMessageSave = () => {
    switch (currentStep) {
      case 'design':
        return isChangesDetectedRef.current;
      default:
        return false;
    }
  };

  // generates HTML for template
  const generateHtml = async () => {
    const { companyName } = account;
    const generatedMessageWrapper = document.createElement('div');
    let html = document.implementation.createHTMLDocument(companyName);

    generatedMessageWrapper.setAttribute('id', MESSAGE_VIEW_WRAPPER_ID);
    const renderCache = document.createElement('div');
    await render(
      <MessagePreview projectData={{
        ...projectData,
        elementsStructureArray: elementsStructureArrayRef.current,
        attachments: attachmentsRef.current,
        unsubscribe: unsubscribeRef.current,
      }}
      />,
      renderCache,
    );
    generatedMessageWrapper.innerHTML = renderCache.innerHTML;

    const wrapper = generatedMessageWrapper;
    const playerNodes = Array.from(generatedMessageWrapper.getElementsByTagName('video'));
    const initAndVideoScript = createInitAndVideoScript(playerNodes);

    const wrapperPrepared = prepareMsgBlocks(wrapper);

    const globalVars = document.createTextNode(
      'var rid = {{.rid}};\n'
      + 'var aid = {{.aid}};\n'
      + 'var mid = {{.mid}};\n'
      + 'var vids = {{.vids}};\n'
      + 'var tid = {{.tid}};\n'
      + `var env = "${process.env.NODE_ENV}";\n`
      + `var api = "${process.env.API_ENV}";\n`,
    );
    const globalVarsScript = document.createElement('script');
    globalVarsScript.appendChild(globalVars);

    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0';

    html.head.appendChild(globalVarsScript);
    html = appendCss(html, FONTS_SRC);
    html = appendCss(html, VIDEO_JS_CSS_SRC);
    html = appendCss(html, MSG_LAYOUT_CSS);
    html = appendScript(html, JQUERY_SRC);
    html = appendCss(html, 'https://code.jquery.com/ui/1.10.4/themes/ui-lightness/jquery-ui.css');
    html = appendScript(html, 'https://code.jquery.com/ui/1.10.4/jquery-ui.js');
    html = appendScript(html, VIDEO_JS_SRC);
    html = appendScript(html, VIDEO_JS_YOUTUBE_SRC);
    html = appendScript(html, MESSAGE_FORM_SUBMIT_SRC);
    html = appendScript(html, MESSAGE_OPTIN_SUBMIT_SRC);
    html = appendScript(html, VIDEO_ANALYTICS_SRC);
    html = appendScript(html, BUTTON_LINK_ATTACHMENT_SRC);
    html = appendScript(html, RATING_SRC);
    html = appendScript(html, HOVER_SRC);
    html = appendScript(html, COLOR_CHANGE_SRC);
    html = appendScript(html, SURVEY_VALIDATION_SRC);
    html = appendScript(html, JQUERY_TOUCH);
    html = appendScript(html, MESSAGE_LOAD);
    // script and styles for phone input validation
    html = appendScript(html, 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js');
    html = appendCss(html, 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/css/intlTelInput.css');
    html = appendIco(html, RISPONS_ICON);
    html = appendScript(html, SURVEY_FEEDBACK_SRC);
    html.head.appendChild(initAndVideoScript);
    html.head.appendChild(meta);
    html.body.appendChild(wrapperPrepared);

    return html.documentElement.innerHTML;
  };

  const omitWrongVideoAttachments = (attachmentsArray) => {
    const array = [];
    if (attachmentsArray) {
      forEach(attachmentsArray, (a) => {
        if (a.link || a.ref !== '') {
          array.push(a);
        }
      });
    }

    return array;
  };

  const saveHtmlAndValidation = async (autosave = false, name) => {
    const html = await generateHtml();
    const videoAttachments = formatVideoAttachments(elementsStructureArrayRef.current);

    setChangesDetected(false);
    if (createFirst && !autosave) {
      createMessageA({
        name: name || messageName,
        attachments: videoAttachments,
        deliveryMethod: 'sms',
        origin: 'default',
        html,
        autosave,
      });
    } else {
      const {
        videoError,
        videoErrorMsg,
      } = validateVideoAttachments();

      if (videoError) {
        printErrorMessage({ video: videoError }, videoErrorMsg);
      }
      const { origin } = messageEditorRef.current;
      setCanSend(false);

      messageUpdate({
        id: messageEditorRef.current.id,
        body: {
          name: name || messageNameRef.current,
          attachments: omitWrongVideoAttachments(videoAttachments),
          html,
          messageEditor: messageEditorRef.current,
          autosave,
          origin: origin === 'automation' || isAutomation ? 'automation' : 'default',
        },
      }).then((res) => {
        if (res.error) {
          notifyErrorA(res.error.message);
        } else {
          updateMessageDesignA(res.response);
          if (name) {
            setAutomationMessageName(undefined);
          }
          setCanSend(true);
          saveMessageA({
            html,
            messageEditor: messageEditorRef.current,
            quiz: generateQuizPayload(elementsStructureArrayRef.current),
            origin: origin === 'automation' || isAutomation ? 'automation' : 'default',
          });
          updateMessageEditorStateA({ payload: { showAutosaveMsg: autosave } });
        }
      });
    }
  };

  const saveDeliveryData = () => {
    const { method } = deliveryMethodFormVals;
    const { origin } = messageEditor;

    const payload = {
      id: messageEditor.id,
      body: {
        ...formatDeliveryDataSavePayload(deliveryMethodFormVals),
        name: messageName,
        unsubscribe: unsubscribeRef.current,
        origin: origin === 'automation' || isAutomation ? 'automation' : 'default',
      },
    };

    saveMessageDeliveryDataA(payload);

    if (!isAutomation && (method === 'sms' || method === 'email')) {
      push('/');
    }
  };

  const saveCurrentStep = () => {
    switch (currentStep) {
      case DESIGN_STEP: {
        saveHtmlAndValidation();
        break;
      }
      case DELIVERY_STEP: {
        saveDeliveryData();
        break;
      }
      default:
        break;
    }
  };

  const setRefs = (updatedProjectData) => {
    if (updatedProjectData?.elementsStructureArray) {
      elementsStructureArrayRef.current = updatedProjectData.elementsStructureArray;
    }
    if (updatedProjectData?.attachments) {
      attachmentsRef.current = updatedProjectData.attachments;
    }
    if (updatedProjectData?.unsubscribe) {
      unsubscribeRef.current = updatedProjectData.unsubscribe;
    }
  };

  const setCurrentStep = (
    nextStep,
    listId,
    add = false,
    prevStep,
    updatedProjectData,
  ) => {
    setRefs(updatedProjectData);
    const { pendingError } = messageEditor;
    // To use this, set a string as 4th argument in setCurrentStep, otherwise it will be undefined
    // e.g. see OptinContent.js
    setPreviousStep(prevStep);

    if (setCurrentStepLayout) {
      setCurrentStepLayout(nextStep);
    }

    if (nextStep === CONTACTS_STEP) {
      setListState({ list: listId, addNewList: add });
    }

    if (validateUnderlyingStep(true, nextStep, undefined)) {
      if (validateUnderlyingStep() && allowMessageSave()) {
        saveCurrentStep();
        if (currentStep === CONTACTS_STEP && nextStep === DELIVERY_STEP) {
          saveHtmlAndValidation();
          setChangesDetected(false);
          setCurrentStepState(nextStep);
          if (isAutomation) {
            setAutomationCurrentStep(nextStep);
          }
          setUnderlyingStepState((s) => ({ ...s, underlyingStep: null }));
        } else {
          setUnderlyingStepState((s) => ({
            ...s,
            underlyingStep: () => {
              initializeMessage(messages[id], nextStep);
              setChangesDetected(false);
              setCurrentStepState(nextStep);
              if (isAutomation) {
                setAutomationCurrentStep(nextStep);
              }
              setUnderlyingStepState((state) => ({ ...state, underlyingStep: null }));
            },
          }));
        }
      } else if (typeof pendingError === 'string') {
        notifyErrorA(pendingError);
        if (isAutomation) {
          setAutomationCurrentStep(currentStep);
        }
      } else {
        setCurrentStepState(nextStep);
        if (isAutomation) {
          setAutomationCurrentStep(nextStep);
        }
        setUnderlyingStepState((s) => ({ ...s, underlyingStep: null }));
      }
    } else if (isAutomation) {
      setAutomationCurrentStep(currentStep);
    }
  };

  useEffect(() => {
    if (isAutomation) {
      if (automationCurrentStep !== currentStep) {
        setCurrentStep(automationCurrentStep);
      } else {
        setAutomationCurrentStep(currentStep);
      }
    }
  }, [automationCurrentStep]);

  const handleMessageNameChange = (data) => {
    if (!isChangesDetectedRef.current) {
      setChangesDetected(true);
    }

    setMessageName(data.value);
    if (isAutomation) {
      saveHtmlAndValidation(false, data.value);
    }
  };

  useEffect(() => {
    if (automationMessageName && automationMessageName !== messageName) {
      handleMessageNameChange({ value: automationMessageName });
    }
  }, [automationMessageName]);

  // function deals with confirmation modals
  const confirmUnderlyingAction = (actionType) => {
    switch (actionType) {
      case 'back': {
        openConfirmationModalA({
          text: 'Discard current changes?',
          callbackFunction: () => {
            if ((!attachments || !elementsStructureArrayRef.current.length)
              && !isChangesDetectedRef.current
            ) {
              deleteMessageA({ id: messageEditor.id, editor: true });
            }
            goBack();
          },
          actionName: 'Discard',
        });
        break;
      }
      // didn't find this case.
      case 'home': {
        openConfirmationModalA({
          text: 'Discard current changes?',
          callbackFunction: () => push('/messages'),
          actionName: 'Discard',
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleBackButton = () => {
    switch (currentStep) {
      case CONTACTS_STEP:
        setCurrentStepState(DELIVERY_STEP);
        break;
      case DELIVERY_STEP:
        setCurrentStepState(DESIGN_STEP);
        break;
      case DESIGN_STEP:
        if (!!isChangesDetectedRef.current && elementsStructureArrayRef.current.length > 0) {
          confirmUnderlyingAction('back');
        } else if (elementsStructureArrayRef.current.length > 1 && !isChangesDetectedRef.current) {
          push('/');
        } else {
          deleteMessageA({ id: messageEditor.id, editor: true });
          push('/');
        }
        break;
      default:
        break;
    }
  };

  const handleLogoClick = (preview) => {
    if (preview) {
      push('/');
    }
    if (currentStep === DESIGN_STEP) {
      if (isChangesDetectedRef.current
        && (elementsStructureArrayRef.current.length || attachments)
      ) {
        confirmUnderlyingAction('back');
      } else if (!isChangesDetectedRef.current
          && (elementsStructureArrayRef.current.length || attachments)
        ) {
        push('/');
      } else if (!elementsStructureArrayRef.current.length
          && !isChangesDetectedRef.current && !attachments
        ) {
        deleteMessageA({ id: messageEditor.id, editor: true });
        push('/');
      } else {
        push('/');
      }
    } else {
      push('/');
    }
  };

  const handleSave = (updatedProjectData) => {
    setRefs(updatedProjectData);
    if (validateUnderlyingStep(true)) {
      saveCurrentStep();
    }
  };

  const handleSaveAndExitButton = (preview, updatedProjectData) => {
    setRefs(updatedProjectData);
    // for preview when editor is not active
    if (preview) {
      push('/');

    // we're on editor design
    } else if (validateUnderlyingStep(true)) {
      // saves HTML data;
      saveCurrentStep();
      push('/');

      setUnderlyingStepState({
        underlyingStep: () => push('/'),
        underlyingStepCallback: () => {
          if (currentStep === CONTACTS_STEP && underlyingStep) {
            underlyingStep();
          }
          setUnderlyingStepState((s) => ({ ...s, underlyingStepCallback: undefined }));
        },
      });
    }
  };

  const autoSaveFunc = useCallback(() => {
    if (validateUnderlyingStep(false, null, true) && allowMessageSave()) {
      setTime(new Date());
      saveHtmlAndValidation(true);
    }
  }, []);

  useInterval(autoSaveFunc, delay);

  const turnOnAutoSave = () => {
    setDelay(30000);
  };

  const turnOffAutoSave = () => {
    setDelay(undefined);
  };

  const changeUnsubscription = (unsubscribe) => {
    unsubscribeRef.current = unsubscribe;
    saveHtmlAndValidation();
    setUnsubscribeEditorContext(unsubscribe);
  };

  return (
    <MessageContext.Provider
      value={{
        messageId: id,
        currentStep,
        previousStep,
        messageName,
        autosaveMsg,
        canSend,
        messageEditor,
        list,
        isAutomation,
        addNewList,
        isMessageLoading,
        setCurrentStep,
        handleMessageNameChange,
        handleBackButton,
        handleLogoClick,
        handleSaveAndExitButton,
        turnOnAutoSave,
        turnOffAutoSave,
        changeUnsubscription,
        handleSave,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

MessageContextProvider.propTypes = {
  fetchContactPropertiesA: PropTypes.func,
  openConfirmationModalA: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  messages: PropTypes.object,
  fetchMessageA: PropTypes.func,
  updateMessageEditorStateA: PropTypes.func,
  messageEditor: PropTypes.object,
  createMessageA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  destroyA: PropTypes.func,
  clearMsgEditorState: PropTypes.func,
  saveMessageDeliveryDataA: PropTypes.func,
  deliveryMethodFormVals: PropTypes.object,
  account: PropTypes.object,
  deleteMessageA: PropTypes.func,
  saveMessageA: PropTypes.func,
  getRecipientsMetadataA: PropTypes.func,
  setCurrentStepLayout: PropTypes.func,
  automationMessageId: PropTypes.string,
  automationCurrentStep: PropTypes.string,
  setAutomationCurrentStep: PropTypes.func,
  automationMessageName: PropTypes.string,
  setAutomationMessageName: PropTypes.func,
  updateMessageDesignA: PropTypes.func,
  isAutomation: PropTypes.bool,
  clearMessagesA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  messages: state.messages,
  messageEditor: state.messageEditor,
  deliveryMethodFormVals: getFormValues('DeliveryMethodForm')(state),
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContactPropertiesA: bindActionCreators(fetchContactProperties.request, dispatch),
  openConfirmationModalA: bindActionCreators(openConfirmationModal, dispatch),
  fetchMessageA: bindActionCreators(fetchMessage.request, dispatch),
  createMessageA: bindActionCreators(createMessage.request, dispatch),
  updateMessageEditorStateA: bindActionCreators(updateMessageEditorState, dispatch),
  destroyA: bindActionCreators(destroy, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  clearMsgEditorState: bindActionCreators(() => ({ type: CLEAR_MESSAGE_EDITOR_STATE }), dispatch),
  saveMessageDeliveryDataA: bindActionCreators(saveMessageDeliveryData.request, dispatch),
  deleteMessageA: bindActionCreators(deleteMessage.request, dispatch),
  saveMessageA: bindActionCreators(saveMessage.request, dispatch),
  getRecipientsMetadataA: bindActionCreators(getRecipientsMetadata.request, dispatch),
  updateMessageDesignA: bindActionCreators(updateMessageDesign, dispatch),
  clearMessagesA: bindActionCreators(clearMessages, dispatch),
});

export const withMessage = (Component) => (
  (props) => (
    <MessageContext.Consumer>
      {(context) => (
        <Component
          {...context}
          {...props}
        />
      )}
    </MessageContext.Consumer>
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(MessageContextProvider);
