import PropTypes from 'prop-types';
import { isEmpty, has } from 'lodash';
import MediaItemData from './MediaItemData';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { mediaTypes } from '../../mockData/mediaTypes';

const MediaPreview = ({
  item,
  handleAdd,
  handleDelete,
  handleBack,
  selectedMedia,
  index,
  close,
  modal,
}) => {
  if (isEmpty(selectedMedia) || index >= 0) {
    const {
      fullpath,
      filename,
      type,
    } = item;

    return (
      <div className={`media-library__preview${modal ? '--modal' : ''}`} id="preview">
        <div className="media-library__preview__buttons">
          <div className="media-library__preview__buttons__back" onClick={() => handleBack(-1)}>
            Close
          </div>
          <div className="media-library__preview__buttons__actions">
            {handleAdd && (
              <div
                className="media-library__preview__buttons__actions__add"
                onClick={() => {
                  handleAdd({ mediaUrl: fullpath, title: filename });
                  close();
                }}
              >
                Add
              </div>
            )}
            {!has(item, 'notification') && (
              <div className="media-library__preview__buttons__actions__delete" onClick={() => handleDelete(filename)}>
                Delete
              </div>
            )}
          </div>
        </div>
        {type === 'video' ? (
          <div className="media-library__preview__video">
            <VideoPlayer src={fullpath} />
          </div>
        ) : (
          <img
            src={fullpath}
            alt={filename}
            className="media-library__preview__image"
          />
        )}
        <MediaItemData item={item} />
      </div>
    );
  }

  const { mediaUrl, title } = selectedMedia;

  const src = mediaUrl.split('.');

  const type = mediaUrl ? mediaTypes[src[src.length - 1]] : undefined;

  if (type) {
    return (
      <div className="media-library__preview--modal" id="preview">
        <div className="media-library__preview__buttons">
          <div className="media-library__preview__buttons__back" onClick={() => handleBack(-1)}>
            Close
          </div>
          <div className="media-library__preview__buttons__actions">
            {handleAdd && (
              <div
                className="media-library__preview__buttons__actions__add"
                onClick={() => {
                  handleAdd({ mediaUrl, title });
                  close();
                }}
              >
                Add
              </div>
            )}
            <div className="media-library__preview__buttons__actions__delete" onClick={() => handleDelete(title)}>
              Delete
            </div>
          </div>
        </div>
        {type === 'video' ? (
          <div className="media-library__preview__video">
            <VideoPlayer src={mediaUrl} />
          </div>
        ) : (
          <img
            className="media-library__preview__image"
            src={mediaUrl}
            alt={title}
          />
        )}
      </div>
    );
  }

  return null;
};

MediaPreview.propTypes = {
  item: PropTypes.object,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
  handleBack: PropTypes.func,
  selectedMedia: PropTypes.object,
  index: PropTypes.number,
  close: PropTypes.func,
  modal: PropTypes.bool,
};

export default MediaPreview;
