import { createPaginator } from '../modules/redux-paginator';
import { api } from '../services';
import { API_ROOT } from '../config';

const { accounts: { accountsGet } } = api;

const accountsPaginator = createPaginator(API_ROOT, ['accounts'], {
  resultsKey: 'elements',
  countKey: 'total',
  idKey: 'id',
  pageArgName: 'page',
}, accountsGet);

export default accountsPaginator;
