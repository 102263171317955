import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const ExpiredUserNotification = ({ history }) => (
  <div className="expired-user-warning" onClick={() => history.push('/account-settings#plans')}>
    Your Plan Has Expired. Click Here to Continue Using Rispons
  </div>
);

ExpiredUserNotification.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default withRouter(ExpiredUserNotification);
