import { createPaginator } from '../modules/redux-paginator';
import { api } from '../services';
import { API_ROOT } from '../config';

const { accounts: { paymentHistory } } = api;

const paymentHistoryPaginator = createPaginator(API_ROOT, ['payments'], {
  resultsKey: 'elements',
  countKey: 'total',
  idKey: 'id',
  pageArgName: 'page',
}, paymentHistory);

export default paymentHistoryPaginator;
