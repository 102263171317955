import PropTypes from 'prop-types';
import { AutomationNodeContactsPopup } from '../../../containers/Popup';

const FlowFinalNode = ({
  node: {
    id,
    top,
    left,
    objects,
  },
}) => (
  <div
    id={`automation-node-${id}`}
    className="automation-flow-final-node"
    style={{ top: `${top}px`, left: `${left}px` }}
  >
    <div className="automation-flow-final-node__container">
      <div className="automation-flow-final-node__container__info">
        <span className="automation-node__container__content__info__column__type">DONE</span>
        <span className="automation-node__container__content__info__column__count">{objects}</span>
      </div>
    </div>
    <div className="automation-node__container__actions flow-final">
      <AutomationNodeContactsPopup id={id} />
    </div>
  </div>
);

FlowFinalNode.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number,
    top: PropTypes.number,
    left: PropTypes.number,
    objects: PropTypes.number,
  }),
};

export default FlowFinalNode;
