import { createElement, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { find } from 'lodash';
import { Modal } from 'semantic-ui-react';
import { VerifyDomain, SuccessfulValidation, VerifyDomainType } from '../../components/AccountSettings/Domain/VerifyDomain';
import { BasicButton } from '../../components/Button';
import { getDomainFromEmail } from '../../utils/helper';
import { email, domain } from '../../utils/validator';
import IdentityItem from '../../components/AccountSettings/Domain/IdentityItem/IdentityItem';
import { identityValidate } from '../../services/accountsApi';
import { notificationActions } from '../../actions';
import { Spinner } from '../../components/Spinner';

const { notifySuccess, notifyError } = notificationActions;

const VerifyIdentityModal = ({
  type: propType,
  identity: propIdentity = '',
  element: propElement,
  delivery,
  notifyErrorA,
  notifySuccessA,
  open,
  closeModalPortal,
  identities,
}) => {
  let stateType = propType;
  if (!delivery && !propElement) {
    stateType = 'sms';
  }

  const [type, setType] = useState(stateType);
  const [identity, setIdentity] = useState(propIdentity);
  const [actionText, setActionText] = useState(delivery ? 'Send' : 'Verify');
  const [error, setError] = useState(undefined);
  const [showEmailError, setShowEmailError] = useState(false);
  const [element, setElement] = useState(propElement);
  const [component, setComponent] = useState(() => VerifyDomain);
  const [loading, setLoading] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(false);

  const handleSend = () => {
    setLoading(true);

    const payload = {
      type,
      body: {
        identity,
      },
    };

    identityValidate(payload).then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
        setLoading(false);
      } else {
        const { response } = res;
        const { identityType } = response;
        if (identityType === 'email') {
          notifySuccessA('Verification email sent! Check your email and click the link to verify your email address!');
          closeModalPortal();
        } else {
          setLoading(false);
          notifySuccessA('Your domain is ready for validation. Please confirm domain following provided steps.');
          setComponent(() => VerifyDomainType);
          setActionText('Done');
          setAction('close');
          setType(type);
          setElement({ ...response });
          setSelectDisabled(true);
        }
      }
    });
  };

  const [action, setAction] = useState('send');

  const checkValidity = (value) => {
    let t = type;
    let validationIdentity = find(identities, (i) => i.identity === value);
    if (!validationIdentity && t === 'email') {
      const dom = getDomainFromEmail(value);
      validationIdentity = find(identities, (i) => i.identity === dom);
      if (validationIdentity && t !== 'sms') {
        t = 'domain';
      }
    }
    if (validationIdentity) {
      const { verified } = validationIdentity;
      if (verified) {
        setComponent(() => SuccessfulValidation);
        setActionText('Done');
        setAction('close');
        setType(t);
      } else if (type === 'domain' || type === 'sms') {
        setComponent(() => IdentityItem);
        setActionText('Done');
        setAction('close');
        setType(t);
        setElement(validationIdentity);
      } else {
        setShowEmailError(true);
        setShowValidationError(true);
      }
    } else {
      setShowEmailError(true);
      setShowValidationError(true);
    }
  };

  const changeIdentity = (i) => {
    let e = domain(i);
    let currentType = type;
    if (delivery) {
      const emailError = email(i);
      if (!emailError || delivery) {
        e = emailError;
        currentType = 'email';
      }
    }
    setIdentity(i);
    setError(e);
    setType(currentType);
  };

  const componentProps = useMemo(() => ({
    changeIdentity,
    checkValidity,
    handleTypeChange: setType,
    delivery,
    error,
    showEmailError,
    type,
    element,
    value: identity,
    closeModalPortal,
    modal: true,
    showValidationError,
    selectDisabled,
  }), [
    delivery,
    error,
    showEmailError,
    type,
    element,
    identity,
    showValidationError,
    selectDisabled,
  ]);

  return (
    <Modal
      open={open}
      closeOnDocumentClick={false}
      closeOnDimmerClick={false}
      onClose={closeModalPortal}
      dimmer="inverted"
      className="verify-identity-modal"
    >
      <Modal.Header>{`Verify ${delivery ? 'Email' : 'Domain'}`}</Modal.Header>
      <Spinner loaded={!!identities} modal={true}>
        <div className="verify-identity-modal__content">
          {createElement(component, componentProps)}
        </div>
      </Spinner>
      <Modal.Actions>
        <BasicButton
          content="Cancel"
          className="white"
          type="button"
          onClick={closeModalPortal}
        />
        <BasicButton
          disabled={(!!error || !identity || loading) && (actionText === 'Send' || actionText === 'Verify')}
          loading={loading && (actionText === 'Send' || actionText === 'Verify')}
          content={actionText}
          className="segmentation-button"
          type="button"
          onClick={(action === 'send' && handleSend) || (action === 'close' && closeModalPortal)}
        />
      </Modal.Actions>
    </Modal>
  );
};

VerifyIdentityModal.propTypes = {
  open: PropTypes.bool,
  closeModalPortal: PropTypes.func,
  type: PropTypes.string,
  delivery: PropTypes.bool,
  identities: PropTypes.array,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  identity: PropTypes.string,
  element: PropTypes.object,
};

const mapStateToProps = (state) => ({
  identities: state.identities.elements,
});

const mapDispatchToProps = (dispatch) => ({
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyIdentityModal);
