import { memo } from 'react';

const SuccessfulValidation = () => (
  <div className="successful-validation">
    <div className="successful-validation__header">
      Your email verification was successful.
    </div>
    <div className="successful-validation__text">
      You can use this email address to send your messages.
    </div>
  </div>
);

export default memo(SuccessfulValidation);
