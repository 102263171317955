import React from 'react';
import { MESSAGE_VIEW_UNSUBSCRIBE_BLOCK } from '../../../common';

const UnsubscribeLinkUI = () => (
  <div
    id={MESSAGE_VIEW_UNSUBSCRIBE_BLOCK}
    className="unsubscribe-message content-row"
  >
    <p>
      To unsubscribe click
      {' '}
      <a
        id="unsubscribe"
        href="{{ .UnsubscribeURL }}"
      >
        here
      </a>
    </p>
  </div>
);

export default UnsubscribeLinkUI;
