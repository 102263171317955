import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, reduxForm, FieldArray } from 'redux-form';
import { bindActionCreators } from 'redux';
import {
  omit,
  map,
  find,
  remove,
} from 'lodash';
import { ContactFilterItemsArray } from '../FieldArray';
import { contactActions } from '../../actions';
import { clearPaginatorPromiseArray } from '../../modules/redux-paginator';
import { removeTagAndListProperties } from '../../utils/helper';

const { createContactFilter, updateContactFilter, clearContactFilter } = contactActions;

const mapFilters = (filter, currentFilters) => {
  const filters = map(filter, (f) => {
    if (!f.value) {
      return omit(f, 'value');
    }
    return f;
  });
  const search = find(currentFilters, (f) => f.operator === 'search');
  if (search) {
    filters.push(search);
  }
  return filters;
};

const prepareFiltersArray = (filters) => {
  remove(filters, (filter) => filter.operator === 'search');
  if (filters.length === 0) {
    filters.push({ operator: 'contains', property: 'contact.surname', value: null });
  }
  return map(filters, (filter) => {
    const { property } = filter;
    if (property === 'contact') {
      return {
        ...filter,
        property: 'contact.tag',
      };
    }
    return filter;
  });
};

const formatTagsValue = (filter) => {
  let tagsValue = '';
  for (let i = 0; i < filter.length; i += 1) {
    const x = filter[i];
    if (x.property === 'contact.tag') {
      for (let j = 0; j < x.value.length; j += 1) {
        const y = x.value[j];
        tagsValue = `${tagsValue}${tagsValue ? ',' : ''}${y.value}`;
      }
    }
  }

  return tagsValue;
};

const ContactFilterForm = ({
  contactFilter,
  clearContactFilterA,
  changeFilter,
  list,
  clearSort,
  handleSubmit,
  contactProperties,
  change,
}) => {
  useEffect(() => () => {
    clearPaginatorPromiseArray();
  }, []);

  const onSubmit = (values, dispatch) => {
    let { filter } = values;
    clearSort();

    const tagsValue = formatTagsValue(filter);

    if (filter.length) {
      filter = filter.map((f) => {
        if (f.property === 'contact.tag') {
          return {
            ...f,
            value: tagsValue,
            operator: 'hasTag',
            property: 'contact',
          };
        }
        return f;
      });
      const payload = {
        ...contactFilter,
        conjunction: 'and',
        filters: mapFilters(filter, contactFilter.filters),
        list,
      };

      changeFilter();

      if (contactFilter.id) {
        return updateContactFilter(payload, dispatch);
      }
      return createContactFilter(payload, dispatch);
    }
    if (contactFilter.id) {
      changeFilter();

      return clearContactFilterA({ list });
    }

    return null;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="contact-filter-form ris-form">
      <FieldArray
        name="filter"
        component={ContactFilterItemsArray}
        contactProperties={contactProperties}
        change={change}
      />
    </Form>
  );
};

const formConfig = {
  form: 'contactFilter',
  shouldValidate: () => true,
};

ContactFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  contactProperties: PropTypes.objectOf(PropTypes.object),
  contactFilter: PropTypes.object,
  change: PropTypes.func,
  clearContactFilterA: PropTypes.func,
  changeFilter: PropTypes.func,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  clearSort: PropTypes.func,
};

const mapStateToProps = (state) => ({
  contactProperties: removeTagAndListProperties(state.entities.contactProperties, true),
  contactFilter: state.contactFilter,
  initialValues: {
    filter: prepareFiltersArray([...state.contactFilter.filters]),
  },
});

const mapDispatchToProps = (dispatch) => ({
  clearContactFilterA: bindActionCreators(clearContactFilter, dispatch),
});

export default connect(
  mapStateToProps, mapDispatchToProps)(reduxForm(formConfig)(ContactFilterForm));
