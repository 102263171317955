import 'react-table/react-table.css';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { pick, omit } from 'lodash';
import { TablePager } from '../../components/Pager';

const pagerProps = [
  'total',
  'page',
  'onPageChange',
  'pageSize',
  'pageNumbers',
];

const DataTable = (props) => {
  const { pageSize } = props;
  return (
    <ReactTable
      className="-striped"
      {...omit(props, pagerProps)}
      pageSize={pageSize}
      PaginationComponent={() => <TablePager {...pick(props, pagerProps)} />}
    />
  );
};

DataTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  pageSize: PropTypes.number,
  total: PropTypes.number,
  onPageChange: PropTypes.func,
  columns: PropTypes.array,
  pageNumbers: PropTypes.number,
};

export default DataTable;
