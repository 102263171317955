import PropTypes from 'prop-types';
import { Input as SemanticInput, Popup } from 'semantic-ui-react';

const Input = ({
  input,
  meta: { touched, error },
  label,
  type,
  labelPosition,
  disabled,
  placeholder,
  autoComplete,
  min,
  className = '',
}) => {
  let { value } = input;

  if (value && typeof value === 'string') {
    value = value.replace(/&amp;/g, '&');
    value = value.replace(/&lt;/g, '<');
    value = value.replace(/&gt;/g, '>');

    if (value.includes('<span class="device-form__survey--required"> *</span>')) {
      value = value.slice(0, -53);
    }
  }

  return (
    <div className={`${className} field input`}>
      <SemanticInput
        {...input}
        label={label}
        labelPosition={labelPosition}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        error={touched && !!error}
        autoComplete={autoComplete}
        min={min}
        value={value}
      />
      {touched && error && (
        <Popup
          className="error-popup"
          trigger={<i className="material-icons input-error-icon">error</i>}
          position="top right"
          content={error}
        />
      )}
    </div>
  );
};

Input.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    touched: PropTypes.bool,
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  }),
  label: PropTypes.string,
  autoComplete: PropTypes.bool,
  placeholder: PropTypes.string,
  labelPosition: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  className: PropTypes.string,
};

export default Input;
