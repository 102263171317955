import {
  sortBy,
  isEmpty,
  findIndex,
  filter,
  find,
  round,
} from 'lodash';

export const getFeatures = (
  selectedFeature,
  features = {},
) => features[selectedFeature];

export const getPlanFeatures = (session) => {
  if (isEmpty(session) || isEmpty(session.subscription)
    || isEmpty(session.subscription.activePricing)
    || isEmpty(session.subscription.activePricing[0])) {
    return undefined;
  }
  const { subscription: { activePricing } } = session;
  const { features } = activePricing[0];
  return features;
};

export const getPlanById = (plans, planID) => Object.values(plans)
  .find((plan) => plan.id === planID);

const getPlansByPeriod = (plans, period) => filter(plans, (plan) => (
  plan.period === period
));

const sortPlansByWeight = (plans, weights) => sortBy(plans, (plan) => (
  findIndex(weights, (weight) => weight.name === plan.product)
));

const sortPlansByPeriods = (plans, weights) => ({
  month: sortPlansByWeight(getPlansByPeriod(plans, 'month'), weights),
  year: sortPlansByWeight(getPlansByPeriod(plans, 'year'), weights),
});

export const transformPlans = (plans, weights) => sortPlansByPeriods(plans, weights);

export const findSelectedCountry = (countries, country) => find(countries,
  (c) => c.name === country);

export const getPlanStatus = (session) => {
  if (isEmpty(session)) {
    return '';
  }

  const { subscription, trialEndsAt, cancelAtPeriodEnd } = session;
  const { periodEnd, legacy } = subscription;

  const currentTimestamp = new Date().getTime();

  if (legacy) {
    return 'legacy';
  }

  if (trialEndsAt > 0) {
    if (trialEndsAt * 1000 > currentTimestamp) {
      return 'free';
    }

    return 'free_expired';
  }

  if (cancelAtPeriodEnd > 0) {
    if (cancelAtPeriodEnd * 1000 > currentTimestamp) {
      return 'canceled';
    }

    return 'expired';
  }

  if (new Date(periodEnd).getTime() > currentTimestamp) {
    return 'active_plan';
  }

  return 'expired';
};

export const renderUserFeature = (plans, index) => {
  let userCount;
  const { params: { users } } = plans[index];

  if (users === -1) {
    if (index < plans.length - 1) {
      userCount = `${plans[index + 1].params.users}+`;
    } else {
      userCount = '5+';
    }
  }

  return `${userCount || users}${users === 1 ? ' user' : ' users'}`;
};

export const calculatePrice = (cents, period) => {
  if (period === 'month') {
    return cents / 100;
  }
  return round(cents / 12) / 100;
};
