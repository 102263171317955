import PropTypes from 'prop-types';
import moment from 'moment';
import { AutomationStatus } from '../shared';
import { useScreenWidth } from '../../../hooks';
import { DATE_TIME } from '../../../common';

const AutomationItem = ({
  item: {
    id,
    title,
    status,
    objects,
    messages,
    lastFailure,
    lastExecutedAt,
  },
  handleEdit,
  handleDelete,
}) => {
  const { isMobile } = useScreenWidth();

  const renderInfo = () => (
    <>
      <span className="automation-item__info">
        <span>{`${objects} contacts`}</span>
        <span>{`${messages} messages`}</span>
      </span>
      <span className="automation-item__info">
        {lastExecutedAt && (
          <span>{`Last time executed at ${moment(lastExecutedAt).format(DATE_TIME)}`}</span>
        )}
      </span>
    </>
  );

  const renderActions = () => (
    <span className="automation-item__actions">
      <span
        className="automation-item__actions__edit"
        onClick={() => handleEdit(id)}
      />
      <span
        className="automation-item__actions__delete"
        onClick={() => handleDelete(id)}
      />
    </span>
  );

  if (isMobile) {
    return (
      <div className="automation-item--mobile">
        <div className="automation-item__row">
          <span>{title}</span>
        </div>
        <div className="automation-item__row info-row info-row--mobile">
          <AutomationStatus
            status={status}
            lastFailure={lastFailure}
          />
          {renderInfo()}
        </div>
        <div className="automation-item__row actions-row">
          {renderActions()}
        </div>
      </div>
    );
  }

  return (
    <div className="automation-item">
      <span className="automation-item__header">
        <span>{title}</span>
        <AutomationStatus
          status={status}
          lastFailure={lastFailure}
        />
      </span>
      {renderInfo()}
      {renderActions()}
    </div>
  );
};

AutomationItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    status: PropTypes.string,
    objects: PropTypes.number,
    messages: PropTypes.number,
    lastExecutedAt: PropTypes.string,
    lastFailure: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default AutomationItem;
