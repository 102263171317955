/* eslint-disable */
import { combineReducers } from 'redux';

import {
  params as paramsReducer,
  pages as pagesReducer,
  currentPages as currentPagesReducer,
  items as itemsReducer,
} from './reducers';
import { requestPage, requestModifiedPage } from './actions';

export const onlyForEndpoint = (endpoint, reducer) => (state = {}, action = {}) =>
  typeof action.meta === 'undefined' ? state : action.meta.endpoint === endpoint ? reducer(state, action) : state;


export const requestPageActionCreatorForEndpoint = (
  endpoint,
  name,
  pageArgName,
  idKey,
  initialItem,
  resultsKey,
  countKey,
  callApi,
) =>
  (page, params) => requestPage(
    endpoint,
    name,
    initialItem,
    resultsKey,
    countKey,
    pageArgName,
    idKey,
    page,
    params,
    callApi,
  );

export const requestModifiedPageActionCreatorForEndpoint = (
  endpoint,
  name,
  pageArgName,
  idKey,
  initialItem,
  resultsKey,
  countKey,
  callApi,
) =>
  (page, params) => requestModifiedPage(
    endpoint,
    name,
    initialItem,
    resultsKey,
    countKey,
    pageArgName,
    idKey,
    page,
    params,
    callApi,
  );

export const getRequestPageActionCreatorsFor = (
  endpoint,
  names,
  pageArgName,
  idKey,
  initialItem,
  resultsKey,
  countKey,
  callApi,
) => {
  let actions = {}
  for (let name of names) {
    actions = {
      ...actions,
      [name]: {
        requestPage:requestPageActionCreatorForEndpoint(
          endpoint,
          name,
          pageArgName,
          idKey,
          initialItem,
          resultsKey,
          countKey,
          callApi,
        ),
        requestModifiedPage:requestModifiedPageActionCreatorForEndpoint(
          endpoint,
          name,
          pageArgName,
          idKey,
          initialItem,
          resultsKey,
          countKey,
          callApi,
        ),
      }
    }
  }
  return actions;
};

export const paginator = (itemsReducer, params, pages, currentPages, requestPageActionCreators) => ({
  reducers: combineReducers({
    params,
    pages,
    currentPages,
  }),
  itemsReducer,
  ...requestPageActionCreators,
});


export const createPaginator = (endpoint, names, {
  initialItem,
  resultsKey,
  countKey,
  pageArgName = 'page',
  idKey = 'id'
}, callApi) => {

  const params = onlyForEndpoint(endpoint, paramsReducer)

  const pages = onlyForEndpoint(endpoint, pagesReducer)

  const currentPages = onlyForEndpoint(endpoint, currentPagesReducer)

  const requestPageActionCreators = getRequestPageActionCreatorsFor(
    endpoint,
    names,
    pageArgName,
    idKey,
    initialItem,
    resultsKey,
    countKey,
    callApi,
  )

  return paginator(itemsReducer, params, pages, currentPages, requestPageActionCreators)

}

/* eslint-enable */
