// TODO needs a clean-up refactor for unused code
// Whole code was copy-pasted from VerifyDomain.js
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../Field';

const VerifyEmail = ({
  changeIdentity,
  error,
  value,
}) => {
  const [focused, setFocused] = useState(false);
  const [touched, setTouched] = useState(false);

  const handleChange = (e, { value: val }) => {
    changeIdentity(val);
  };

  const handleFocus = () => {
    setTouched(true);
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <div className="verify-domain">
      <div className="verify-domain__text">
        Before you can use an email address as a &quot;from&quot; in Rispons messages,
        you must verify that the email address belongs to you or your company, or that
        you have access to it. You will receive an email with verification link.
      </div>
      <div className="verify-domain__verification">
        <div className="ris-form verify-domain__verification__input">
          <Input
            input={{
              value,
              onChange: handleChange,
              onFocus: handleFocus,
              onBlur: handleBlur,
            }}
            label="Email address"
            placeholder="example@domain.com"
            meta={{ touched: !focused && touched, error }}
            className="ui"
          />
        </div>
      </div>
      <div className="verify-domain__domain-note verify-domain__domain-note--margin">
        If you have verified DKIM domain, all existing and new email addresses at that domain are
        automatically verified. For example, if you have a DKIM domain for domain.com, new and
        existing email addresses such as john@domain.com or info@domain.com are automatically
        verified.
      </div>
    </div>
  );
};

VerifyEmail.propTypes = {
  value: PropTypes.string,
  changeIdentity: PropTypes.func,
  error: PropTypes.string,
};

export default VerifyEmail;
