import React, { useContext, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';

// Context.
import { EditorContext } from '../../../../context/EditorContext';

// Constants.
import { toolbarConfig } from '../../../../constants/toolbarConfig';

// Styles.
import './OptInBodyController.scss';

const OptInBodyController = () => {
  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const {
    data: {
        body: { content },
      },
  } = activeElement;

  const [editorState, setEditorState] = useState(
    content
      ? EditorState.createWithContent(convertFromRaw(content))
      : EditorState.createEmpty(),
  );

  const onEditorStateChange = (state) => {
    const dataObj = { ...activeElement };
    const contentState = state.getCurrentContent();
    const contentRawState = convertToRaw(contentState);
    dataObj.data.body.content = contentRawState;

    setActiveElement(dataObj);
    setEditorState(state);
  };

  return (
    <div className="OptInBodyController">
      <div className="OptInBodyController__header">
        <p className="OptInBodyController__header-title">Body</p>
      </div>
      <div className="OptInBodyController__text-editor-wrapper">
        <Editor
          editorState={editorState}
          toolbar={toolbarConfig}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          placeholder="Type your text here"
          onEditorStateChange={onEditorStateChange}
          hashtag={{ hashCharacter: '#', separator: ' ' }}
          stripPastedStyles={true}
        />
      </div>
    </div>
  );
};

export default OptInBodyController;
