import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components.
import CreateElementButton from '../../components/CreateElementButton';

// Context.
import {
  EditorContext,
} from '../../context/EditorContext';

// Constants.
import { EMPTY_IMG_SRC } from '../../../config';
import { OPT_IN } from '../../constants/widgetTypes';
import { INITIAL_EDITOR_CONTENT_RAW_STATE } from '../../constants/textEditorRawState';

const OptInWidget = () => {
  const {
    addElement,
    setActiveElement,
  } = useContext(EditorContext);

  const createOptInElement = () => {
    const elementId = uuidv4();
    const defaultElementPropertiesObj = {
      elementType: OPT_IN,
      id: elementId,
      data: {
        hiddenInputData: {
          list: 0,
          tags: [],
        },
        header: {
          isVisible: true,
          img: {
            src: EMPTY_IMG_SRC,
            alt: 'No content',
            width: '100%',
          },
          content: INITIAL_EDITOR_CONTENT_RAW_STATE,
        },
        body: {
          content: INITIAL_EDITOR_CONTENT_RAW_STATE,
        },
        inputFields: [
          {
            id: uuidv4(),
            value: 'contact.name',
            text: 'First name',
            type: 'text',
            name: 'contact.name',
            placeholder: 'Type your text here',
          },
          {
            id: uuidv4(),
            value: 'contact.phoneNumber',
            text: 'Phone number',
            type: 'tel',
            name: 'contact.phoneNumber',
            placeholder: 'Type your text here',
          },
        ],
        checkboxInput: {
          id: uuidv4(),
          isVisible: true,
          content: INITIAL_EDITOR_CONTENT_RAW_STATE,
        },
        button: {
          text: 'Send',
          bgColor: '#0052a1',
          textColor: '#fff',
        },
        footer: {
          isVisible: true,
          content: INITIAL_EDITOR_CONTENT_RAW_STATE,
        },
      },
    };

    addElement(defaultElementPropertiesObj);
    setActiveElement(defaultElementPropertiesObj);
  };

  return (
    <CreateElementButton
      title="OPT-IN"
      iconName="summarize"
      onClick={() => createOptInElement()}
    />
  );
};

export default OptInWidget;
