import PropTypes from 'prop-types';
import { StripeProvider } from 'react-stripe-elements';
import { ENV } from '../../../config';
import StoreCheckout from './StoreCheckout';

const { STRIPE_API_KEY } = ENV;

const AddCard = ({
  getSecret,
  sendSecret,
  checkCardValidity,
  notifyErrorA,
}) => (
  <StripeProvider apiKey={STRIPE_API_KEY}>
    <StoreCheckout
      getSecret={getSecret}
      sendSecret={sendSecret}
      checkCardValidity={checkCardValidity}
      notifyErrorA={notifyErrorA}
    />
  </StripeProvider>
);

AddCard.propTypes = {
  getSecret: PropTypes.bool,
  sendSecret: PropTypes.func,
  checkCardValidity: PropTypes.func,
  notifyErrorA: PropTypes.func,
};

export default AddCard;
