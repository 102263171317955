import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input } from 'semantic-ui-react';
import { BasicButton } from '../../components/Button';

const DeletePropertyModal = ({
  close,
  open,
  deleteProperty,
  loading,
}) => {
  const [value, setValue] = useState('');

  const handleInputChange = ({ value: newValue }) => setValue(newValue);

  const handleClose = (newDeleteProperty) => {
    setValue('');
    if (!newDeleteProperty) {
      close();
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      className="modal delete-column-modal"
      dimmer="inverted"
    >
      <Modal.Header>
        <span className="delete-column-modal__header">
          Are you sure you want to delete this column?
        </span>
      </Modal.Header>
      <Modal.Content>
        <div className="delete-column-modal__content">
          <span className="delete-column-modal__content__disclaimer">
            The data connected with this column will be removed.
            Confirm this action by writing Delete in the field
          </span>
          <div className="ris-form delete-column-modal__content__input">
            <div className="ui field input">
              <Input
                label="Enter Delete"
                onChange={(e, data) => handleInputChange(data)}
                value={value}
                placeholder="Write Delete here"
              />
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className="delete-column-modal__actions">
          <BasicButton
            content="Cancel"
            size="small"
            className="white"
            onClick={() => handleClose()}
          />
          <BasicButton
            disabled={value.toLowerCase() !== 'delete' || loading}
            loading={loading}
            content="Delete"
            size="small"
            color="red"
            onClick={() => {
              handleClose(true);
              deleteProperty();
            }}
          />
        </div>
      </Modal.Actions>
    </Modal>
  );
};

DeletePropertyModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  deleteProperty: PropTypes.func,
  loading: PropTypes.bool,
};

export default DeletePropertyModal;
