import {
  useState,
  useEffect,
  useContext,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  find,
  forEach,
  map,
  filter,
} from 'lodash';
import { Modal, Button, Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import 'moment-timezone';
import { NODE_TYPES, SUBJECT_TYPES } from '../../common/automation';
import { disableAddEditNodeButton, prepareNodeInputsPayload, generateNodeName } from '../../utils/automationHelper';
import { AutomationContext } from '../../context/AutomationContext';
import {
  AnswerSelect,
  DelaySelect,
  MessageSelect,
  QuestionSelect,
  SurveySelect,
  TagListSelect,
  TimeSelect,
} from '../../components/Automation/AddEditNode';
import DelayExplanationModal from './DelayExplanationModal';
import { contactActions } from '../../actions';
import {
  automationNodeCreate,
  automationNodeUpdate,
  automationRootNode,
  automationNodeAttach,
  automationNodeDetach,
  automationBranchDelete,
} from '../../services/automationApi';
import { notifySuccess, notifyError } from '../../actions/notificationActions';

const { fetchTags, fetchLists } = contactActions;

const NodeAddEditModal = ({
  close,
  open,
  automationId,
  type,
  add,
  initial,
  node,
  tags,
  lists,
  fetchTagsA,
  fetchListsA,
  parent,
  child,
  notifySuccessA,
  notifyErrorA,
  messages,
  editingDisabled,
 }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [survey, setSurvey] = useState('');
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState([]);
  const [selectedTags, setSelectedTags] = useState('');
  const [selectedLists, setSelectedLists] = useState('');
  const [delay, setDelay] = useState('');
  const [delayInterval, setDelayInterval] = useState(1);
  const [time, setTime] = useState(moment());
  const [timeZone, setTimeZone] = useState(moment.tz.guess(true));
  const [additionalDecision, setAdditionalDecision] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delayModalOpen, setDelayModalOpen] = useState(false);
  const [createdNodeId, setCreatedNodeId] = useState();

  const typeRef = useRef(type);
  const childRef = useRef(child);

  const { getNodes, getPathName } = useContext(AutomationContext);

  const usersLists = useMemo(() => filter(lists, (list) => !list.temporal), [lists?.length]);

  const prepareStateFromPayload = () => {
    if (node) {
      const {
        actionSubject,
        inputs,
      } = node;
      let sub = actionSubject;
      if (actionSubject === SUBJECT_TYPES.EMAIL) {
        const m = find(messages, (el) => el.id === inputs.messageId);
        if (m && m.deliveryMethod === 'sms') {
          sub = SUBJECT_TYPES.SMS;
        }
      }
      setSubject(sub);
      switch (sub) {
        case SUBJECT_TYPES.OPEN:
        case SUBJECT_TYPES.SMS:
        case SUBJECT_TYPES.EMAIL: {
          setMessage(inputs.messageId);
          break;
        }
        case SUBJECT_TYPES.SURVEY: {
          setMessage(inputs.messageId);
          setSurvey(inputs.surveyName);
          break;
        }
        case SUBJECT_TYPES.QUESTION: {
          setMessage(inputs.messageId);
          setSurvey(inputs.surveyName);
          setQuestion(inputs.inputQuestionId);
          const answerObj = JSON.parse(inputs.inputAnswerValue);
          setAnswers(map(answerObj.paths, (a) => a.comparator));
          break;
        }
        case SUBJECT_TYPES.CONTAINS_TAG:
        case SUBJECT_TYPES.ADD_TAG:
        case SUBJECT_TYPES.REMOVE_TAG: {
          setSelectedTags(inputs.tagName);
          break;
        }
        case SUBJECT_TYPES.IN_LIST:
        case SUBJECT_TYPES.ADD_TO_LIST:
        case SUBJECT_TYPES.REMOVE_FROM_LIST: {
          setSelectedLists(inputs.listName);
          break;
        }
        case SUBJECT_TYPES.DELAY: {
          setDelay(inputs.minutes);
          break;
        }
        case SUBJECT_TYPES.TIME: {
          setTime(moment(inputs.datetime));
          break;
        }
        default:
      }
      if (type === 'filter' && sub !== SUBJECT_TYPES.QUESTION) {
        setAdditionalDecision(inputs.additionalDecision);
      }
    }
  };

  useEffect(() => {
    fetchTagsA();
    fetchListsA();
    prepareStateFromPayload();
  }, []);

  const handleSubjectChange = (e, { value }) => {
    if (subject !== value) {
      setSubject(value);
      setMessage('');
      setSurvey('');
      setQuestion('');
      setAnswers([]);
      setSelectedTags('');
      setSelectedLists('');
      setDelay('');
      setDelayInterval(1);
      setTime(new Date());
      setTimeZone(moment.tz.guess());
    }
  };

  const handleMessageChange = (value) => {
    setMessage(value);
    setSurvey('');
    setQuestion('');
  };

  const handleSurveyChange = (value) => {
    setSurvey(value);
    setQuestion('');
  };

  const handleQuestionChange = (value) => {
    setQuestion(value);
    setAnswers([]);
  };

  const handleListTagChange = (selectedType) => (value) => {
    if (selectedType === 'tag') {
      setSelectedTags(value);
    } else {
      setSelectedLists(value);
    }
  };

  const submitSuccessfulClose = (text) => {
    if (text) {
      notifySuccessA(text);
    }
    getNodes(true);
    close();
  };

  const attachNode = (parentId, childId, path = 'default', enclose = false) => automationNodeAttach({ id: parentId, body: { path, id: childId, enclose } });

  const getChoiceLabelByValue = (value) => {
    const m = find(messages, (el) => el.id === message);
    if (m) {
      const { schema: { forms } } = m;
      const form = find(forms, (f) => f.displayName === survey);
      if (form) {
        const q = find(form.elements, (element) => element.inputName === question);
        if (q) {
          const answer = find(q.choices, (choice) => choice.inputValue === value);
          if (answer) {
            return answer.label;
          }
        }
      }
    }
    return undefined;
  };

  const createAndAttachChoicePaths = (parentId, startIndex, id) => {
    if (id && message) {
      notifySuccessA('Node created successfully');
      getNodes(true);
      setCreatedNodeId(id);
      if (additionalDecision) {
        setDelayModalOpen(true);
      } else close();
    } else {
      submitSuccessfulClose('Node created successfully');
    }
  };

  // TODO: seems ok, check after integration with BE
  const fixChoicePaths = () => {
    const {
      id,
      inputs,
      pathList,
    } = node;
    const oldAnswers = {};
    const newAnswers = {};
    const answersToRemove = {};
    const answerObj = JSON.parse(inputs.inputAnswerValue);
    forEach(answerObj.paths, (a) => {
      oldAnswers[a.comparator] = getChoiceLabelByValue(a.comparator);
    });
    forEach(answers, (answer) => {
      newAnswers[answer] = getChoiceLabelByValue(answer);
    });
    forEach(oldAnswers, (answer, value) => {
      if (!newAnswers[value]) {
        answersToRemove[value] = answer;
      }
    });
    const promises = [];
    forEach(answersToRemove, (answer) => {
      const path = find(pathList, (p) => p.name === answer);
      if (path) {
        promises.push(automationNodeDetach({ id, body: { id: path.nodeId } }).then((detachRes) => {
          if (detachRes.error) {
            notifyErrorA(detachRes.error.message);
            setLoading(false);
            return new Promise();
          }
          return automationBranchDelete(path.nodeId).then((deleteRes) => {
            if (deleteRes.error) {
              notifyErrorA(deleteRes.error.message);
              setLoading(false);
            }
          });
        }));
      }
    });
    Promise.all(promises).then(() => submitSuccessfulClose('Node updated successfully'));
  };

  const handleSubmit = () => {
    if (editingDisabled) {
      close();
    } else {
      setLoading(true);
      const items = prepareNodeInputsPayload(
        subject,
        message,
        survey,
        question,
        answers,
        selectedTags,
        selectedLists,
        delay,
        delayInterval,
        time,
        timeZone,
        additionalDecision,
        usersLists,
        tags,
      );
      const name = generateNodeName(subject, items, messages);
      const nodePayload = node ? {
        ...node,
        name,
        root: automationId,
        inputs: items,
      } : {
        name,
        action: {
          actionType: typeRef.current,
          actionSubject: subject === SUBJECT_TYPES.SMS ? SUBJECT_TYPES.EMAIL : subject,
        },
        root: automationId,
        inputs: items,
      };
      if (add) {
        automationNodeCreate(nodePayload).then((res) => {
          if (res.error) {
            notifyErrorA(res.error.message);
            setLoading(false);
          } else {
            const { id } = res.response;
            if (initial) {
              automationRootNode({ id: automationId, body: { nodeId: id } }).then((r) => {
                if (r.error) {
                  notifyErrorA(r.error.message);
                  setLoading(false);
                } else {
                  submitSuccessfulClose('Initial node created successfully');
                }
              });
            } else if (child) {
              const currentPathName = getPathName(parent, childRef.current);
              attachNode(parent, id, currentPathName).then((r) => {
                if (typeRef.current === 'filter') {
                  if (subject === SUBJECT_TYPES.QUESTION && answers.length > 1) {
                    createAndAttachChoicePaths(id, 1, r.response.id);
                  } else if (message) {
                    notifySuccessA('Node created successfully');
                    getNodes(true);
                    setCreatedNodeId(id);
                    if (additionalDecision) {
                      setDelayModalOpen(true);
                    } else close();
                  } else {
                    submitSuccessfulClose('Node created successfully');
                  }
                } else {
                  submitSuccessfulClose('Node created successfully');
                }
              });
            } else {
              attachNode(parent, id, 'default', typeRef.current !== 'filter').then(() => {
                if (typeRef.current === 'filter') {
                  if (subject === SUBJECT_TYPES.QUESTION && answers.length) {
                    createAndAttachChoicePaths(id, 0);
                  } else if (additionalDecision) {
                    submitSuccessfulClose();
                  } else {
                    submitSuccessfulClose();
                  }
                } else {
                  submitSuccessfulClose('Node created successfully');
                }
              });
            }
          }
        });
      } else {
        automationNodeUpdate({ id: node.id, body: nodePayload }).then((res) => {
          if (res.error) {
            notifyErrorA(res.error.message);
            setLoading(false);
          } else if (typeRef.current === 'filter') {
            const {
              id,
              inputs,
              pathList,
            } = node;
            if (subject === SUBJECT_TYPES.QUESTION) {
              fixChoicePaths();
            } else if (inputs.additionalDecision !== additionalDecision) {
              if (additionalDecision) {
                submitSuccessfulClose('Node updated successfully');
              } else {
                const path = find(pathList, (p) => p.name === 'No');
                if (path) {
                  automationNodeDetach({ id, body: { id: path.nodeId } }).then((detachRes) => {
                    if (detachRes.error) {
                      notifyErrorA(detachRes.error.message);
                      setLoading(false);
                    } else {
                      automationBranchDelete(path.nodeId).then((deleteRes) => {
                        if (deleteRes.error) {
                          notifyErrorA(deleteRes.error.message);
                          setLoading(false);
                        } else {
                          submitSuccessfulClose('Node updated successfully');
                        }
                      });
                    }
                  });
                }
              }
            } else {
              submitSuccessfulClose('Node updated successfully');
            }
          } else {
            submitSuccessfulClose('Node updated successfully');
          }
        });
      }
    }
  };

  const disabled = useMemo(() => disableAddEditNodeButton(
    subject,
    message,
    survey,
    question,
    answers,
    selectedTags,
    selectedLists,
    delay,
    delayInterval,
    time,
    timeZone,
  ), [
    subject,
    message,
    survey,
    question,
    answers,
    selectedTags,
    selectedLists,
    delay,
    delayInterval,
    time,
    timeZone,
  ]);

  const doneButtonText = useMemo(() => {
    if (editingDisabled) {
      return 'Close';
    }
    if (add) {
      return 'Add';
    }
    return 'Save';
  }, [add, editingDisabled]);

  const handleAddDelay = () => {
    setLoading(false);
    setDelayModalOpen(false);
    typeRef.current = 'other';
    childRef.current = createdNodeId;
    handleSubjectChange(undefined, { value: SUBJECT_TYPES.DELAY });
  };

  return (
    <Modal
      open={open}
      onClose={close}
      dimmer="inverted"
      size="large"
      closeOnDocumentClick={false}
      closeOnDimmerClick={false}
      className="node-modal"
    >
      <Modal.Header>
        {initial ? 'How does this automation trigger?' : `${add ? 'Add' : 'Edit'} ${typeRef.current === 'filter' ? 'split' : typeRef.current}`}
      </Modal.Header>
      <Modal.Content>
        <div>
          <DelayExplanationModal
            open={delayModalOpen}
            close={close}
            handleAddDelay={handleAddDelay}
          />
          <div className="add-edit-node-content">
            <Dropdown
              className="node-type-select"
              options={initial ? NODE_TYPES.initial : NODE_TYPES[typeRef.current]}
              placeholder={`Select ${typeRef.current === 'filter' ? 'split' : typeRef.current} node type`}
              value={subject}
              onChange={handleSubjectChange}
              disabled={!add || editingDisabled}
            />
            {(subject === SUBJECT_TYPES.OPEN || subject === SUBJECT_TYPES.SMS
              || subject === SUBJECT_TYPES.EMAIL) && (
              <MessageSelect
                message={message}
                setMessage={setMessage}
                subject={subject}
                disabled={editingDisabled}
              />
            )}
            {subject === SUBJECT_TYPES.SURVEY && (
              <SurveySelect
                subject={subject}
                message={message}
                survey={survey}
                setMessage={handleMessageChange}
                setSurvey={setSurvey}
                disabled={editingDisabled}
              />
            )}
            {subject === SUBJECT_TYPES.QUESTION && (
              <QuestionSelect
                subject={subject}
                message={message}
                survey={survey}
                question={question}
                setMessage={handleMessageChange}
                setSurvey={handleSurveyChange}
                setQuestion={handleQuestionChange}
                disabled={editingDisabled}
              />
            )}
            {(subject === SUBJECT_TYPES.CONTAINS_TAG || subject === SUBJECT_TYPES.ADD_TAG
              || subject === SUBJECT_TYPES.REMOVE_TAG) && (
              <TagListSelect
                type="tag"
                options={tags}
                handleChange={handleListTagChange}
                value={selectedTags}
                disabled={editingDisabled}
                subject={subject}
              />
            )}
            {(subject === SUBJECT_TYPES.IN_LIST || subject === SUBJECT_TYPES.ADD_TO_LIST
              || subject === SUBJECT_TYPES.REMOVE_FROM_LIST) && (
              <TagListSelect
                type="list"
                options={usersLists}
                handleChange={handleListTagChange}
                value={selectedLists}
                disabled={editingDisabled}
                subject={subject}
              />
            )}
            {subject === SUBJECT_TYPES.DELAY && (
              <DelaySelect
                delay={delay}
                delayInterval={delayInterval}
                setDelay={setDelay}
                setDelayInterval={setDelayInterval}
                disabled={editingDisabled}
              />
            )}
            {subject === SUBJECT_TYPES.TIME && (
              <TimeSelect
                time={time}
                timeZone={timeZone}
                setTime={setTime}
                setTimeZone={setTimeZone}
                disabled={editingDisabled}
              />
            )}
          </div>
          {subject === SUBJECT_TYPES.QUESTION && !!question && (
            <AnswerSelect
              message={message}
              survey={survey}
              question={question}
              answers={answers}
              setAnswers={setAnswers}
              disabled={editingDisabled}
            />
          )}
          {typeRef.current === 'filter' && !initial && subject && subject !== SUBJECT_TYPES.QUESTION && (
            <div className="additional-path">
              <span
                className={`additional-path__choice${additionalDecision ? '--selected' : ''}`}
                onClick={() => setAdditionalDecision((path) => !path)}
              >
                Additional path
              </span>
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        {!editingDisabled && (
          <Button
            className="white"
            onClick={close}
            disabled={loading}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={handleSubmit}
          disabled={disabled || loading}
          className="blue"
          loading={loading}
        >
          {doneButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

NodeAddEditModal.propTypes = {
  close: PropTypes.func,
  open: PropTypes.bool,
  automationId: PropTypes.number,
  type: PropTypes.string,
  add: PropTypes.bool,
  initial: PropTypes.bool,
  node: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    actionType: PropTypes.string,
    actionSubject: PropTypes.string,
    inputs: PropTypes.object,
    pathList: PropTypes.array,
  }),
  tags: PropTypes.object,
  lists: PropTypes.array,
  fetchTagsA: PropTypes.func,
  fetchListsA: PropTypes.func,
  parent: PropTypes.number,
  child: PropTypes.number,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  messages: PropTypes.object,
  editingDisabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  tags: state.tags,
  lists: state.lists.elements,
  messages: state.messages,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTagsA: bindActionCreators(fetchTags.request, dispatch),
  fetchListsA: bindActionCreators(fetchLists.request, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NodeAddEditModal);
