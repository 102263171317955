import React from 'react';
import PropTypes from 'prop-types';

const LikeDislikeAnswerUI = ({ index, type, answerRequired }) => (
  <>
    <label
      htmlFor={`${index}-${type}-like`}
      className="LikeDislikeAnswerUI__thumbup-input"
    >
      <input
        id={`${index}-${type}-like`}
        type="radio"
        name={`${index}-${type}`}
        value="1"
        className="thumbup"
        required={answerRequired}
      />
      <span className="device-form__survey--likeDislike--thumbup" />
    </label>
    <label
      htmlFor={`${index}-${type}-dislike`}
      className="LikeDislikeAnswerUI__thumbdown-input"
    >
      <input
        id={`${index}-${type}-dislike`}
        type="radio"
        name={`${index}-${type}`}
        value="0"
        className="thumbdown"
        required={answerRequired}
      />
      <span className="device-form__survey--likeDislike--thumbdown" />
    </label>
  </>
);

LikeDislikeAnswerUI.propTypes = {
  answerRequired: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default LikeDislikeAnswerUI;
