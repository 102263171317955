import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit } from 'redux-form';
import classNames from 'classnames';
import {
  omit,
  get,
  map,
  find,
  forEach,
} from 'lodash';
import { Modal, Segment } from 'semantic-ui-react';
import { FileUpload, UploadErrorMsg } from '../../components/FileUpload';
import { BasicButton } from '../../components/Button';
import { ContactsUploadForm } from '../Form';
import { contactActions, accountActions, notificationActions } from '../../actions';
import { formatMappings, formatArrayBufferToBase64 } from '../../utils/format';
import { contactTagCreate } from '../../services/tagApi';
import { notifyError } from '../../actions/notificationActions';
import { ContactsUploadHeaderActions } from '../../components/Contacts';
import { listCreate } from '../../services/contactApi';
import { Spinner } from '../../components/Spinner';
import UploadGuide from '../../utils/agreements/Rispons Upload Guide.pdf';

const {
  preprocessContactList,
  clearPreprocessingValues,
  importContactList,
  fetchTags,
  fetchLists,
} = contactActions;
const { getAccount } = accountActions;
const { notifySuccess } = notificationActions;

const ContactsUploadModal = ({
  list,
  lists,
  uploadedFile: { contacts },
  submitForm,
  handleUploadModalState,
  preprocessContactListA,
  notifySuccessA,
  user,
  getAccountA,
  fetchTagsA,
  fetchListsA,
  contactFilter,
  propertyId,
  orderBy,
  open,
  clearValues,
  windowWidth,
  tags,
}) => {
  const onDrop = (newFile) => {
    setFile(newFile);
    setModalActions([
      <BasicButton
        content="Cancel"
        className="white"
        onClick={() => handleUploadModalState()}
      />,
      <BasicButton
        content="Upload"
        className="blue"
        onClick={() => onUploadClick(newFile)}
        disabled={false}
      />,
    ]);
  };

  const onUploadClick = (newFile) => {
    const formattedFile = formatArrayBufferToBase64(newFile);
    setLoading(true);
    preprocessContactListA({ payload: { data: formattedFile } });
  };

  const [uploaded, setUploaded] = useState(false);
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [ModalComponent, setModalComponent] = useState({
    Content: FileUpload,
    contentProps: {
      onUploadClick: () => onUploadClick(file),
      onClose: handleUploadModalState,
      onDrop,
      maxSize: 10485760,
      accept: '.csv',
    },
  });
  const [modalActions, setModalActions] = useState([
    <BasicButton
      content="Cancel"
      size="small"
      className="tertiary"
      onClick={() => handleUploadModalState()}
    />,
    <BasicButton
      content="Upload"
      size="small"
      color="blue"
      onClick={() => onUploadClick(file)}
      disabled={true}
    />,
  ]);
  const [tagValues, setTagValues] = useState([]);
  const [listValues, setListValues] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (typeof list === 'number' && lists) {
      const initialList = find(lists, (li) => li.id === list);
      const { id, name } = initialList;
      setListValues([{ value: id, label: name }]);
    }
  }, []);

  useEffect(() => {
    if (contacts) {
      const err = contacts ? getUploadErrors(contacts) : undefined;

      if (err) {
        displayErrorContent(err);
      } else {
        setModalComponent({
          Content: ContactsUploadForm,
          contentProps: {
            initialValues: {
              headers: contacts.csvHeaders,
            },
            onFormSubmit,
          },
        });
        setModalActions([
          <BasicButton
            content="Cancel"
            size="small"
            color="white"
            className="upload-cancel"
            onClick={() => handleUploadModalState()}
          />,
          <BasicButton
            content="Done"
            size="small"
            color="blue"
            onClick={() => submitForm('contactsUploadForm')}
          />,
        ]);
        setUploaded(true);
        setLoading(false);
      }
    }
  }, [contacts, submitForm, handleUploadModalState]);

  const onFormSubmit = (values, dispatch) => {
    const newLists = [];
    const requests = map(listValues, ({ value, label }) => {
      notifySuccessA(`Contacts will be added to ${label} list`);
      if (typeof value === 'string') {
        return listCreate({ name: value }).then((res) => {
          if (res.response && res.response.id) {
            const { id } = res.response;
            newLists.push(id);
          }
        });
      }

      newLists.push(value);
      return value;
    });

    if (tagValues && tagValues.length > 0) {
      const newTags = [];
      forEach(tagValues, (tag) => {
        const { value, label } = tag;
        notifySuccessA(`Contacts will be tagged ${label}`);

        if (typeof value === 'number') {
          newTags.push(value);
          requests.push(label);
        } else {
          requests.push(contactTagCreate({
            name: label,
          }).then(({ response }) => {
            newTags.push(response.id);
          }).catch((er) => {
            notifyError(er);
          }));
        }
      });

      Promise.all(requests).then(() => {
        handleFormSubmit(values, dispatch, newTags, newLists);
      });
    } else {
      Promise.all(requests).then(() => {
        handleFormSubmit(values, dispatch, undefined, newLists);
      });
    }
  };

  const getUploadErrors = (response) => {
    if (!response || get(response, 'error')) {
      return [get(response, 'error.message', 'Something went wrong...')];
    }
    return undefined;
  };

  const handleFormSubmit = (values, dispatch, selectedTags, selectedLists) => {
    const filterId = get(contactFilter, 'id', undefined);

    const body = {
      ...omit(contacts, 'csvHeaders'),
      mappings: formatMappings(values.headers),
      notifyEmail: user.email,
      tags: selectedTags || [],
      lists: selectedLists,
      list,
      filterId,
      propertyId,
      orderBy,
    };

    if (!selectedTags || selectedTags.length === 0) {
      omit(body, 'tags');
    }

    if (!selectedLists || selectedLists.length === 0) {
      omit(body, 'lists');
    }

    importContactList(body, dispatch).then(() => {
      handleUploadModalState();
      clearPreprocessingValues();
      getAccountA();
      fetchTagsA();
      fetchListsA();
    }).catch(() => {
      const err = ['Something went wrong...'];
      displayErrorContent(err);
    });
  };

  const displayErrorContent = (err) => {
    setModalComponent({
      Content: UploadErrorMsg,
      contentProps: { err },
    });
    setModalActions([
      <BasicButton
        content="Cancel"
        className="upload-cancel white"
        onClick={() => handleUploadModalState()}
      />,
    ]);
    setLoading(false);
    setUploaded(false);
    setError(true);
  };

  const handleTagChange = (values) => {
    setTagValues(values);
  };

  const handleListChange = (values) => {
    setListValues(values);
  };

  return (
    <Modal
      open={open}
      onUnmount={clearValues}
      dimmer="inverted"
      className="upload-modal"
      onClose={handleUploadModalState}
    >
      <Modal.Header>
        Upload Contacts
        {windowWidth && <div className="close modal-close-icon-for-tablet" onClick={handleUploadModalState} />}
        {uploaded && <div className="contact-upload-modal__subheader">Match the names of uploaded file’s rows</div>}
      </Modal.Header>
      {!uploaded && (
        <div className="contact-upload-help">
          <div className="contact-upload-help__info">Make sure your contacts upload correctly</div>
          <a
            className="contact-upload-help__link"
            href={UploadGuide}
            target="_blank"
            rel="noopener noreferrer"
            download="Rispons Upload Guide.pdf"
          >
            Check out our upload guide
          </a>
        </div>
      )}
      {!error && (
        <ContactsUploadHeaderActions
          lists={lists}
          listValues={listValues}
          handleListChange={handleListChange}
          tags={tags}
          tagValues={tagValues}
          handleTagChange={handleTagChange}
        />
      )}
      <Modal.Content className={`contact-upload-modal${uploaded ? '--uploaded' : ''}`}>
        <Spinner loaded={!loading} modal={true}>
          <Segment
            className={
              classNames(
                {
                  border: uploaded,
                },
              )
            }
          >
            <ModalComponent.Content {...ModalComponent.contentProps} />
          </Segment>
        </Spinner>
      </Modal.Content>
      <Modal.Actions>
        {modalActions}
      </Modal.Actions>
    </Modal>
  );
};

ContactsUploadModal.propTypes = {
  open: PropTypes.bool,
  handleUploadModalState: PropTypes.func,
  preprocessContactListA: PropTypes.func,
  uploadedFile: PropTypes.shape({
    error: PropTypes.string,
    contacts: PropTypes.shape({
      csvHeaders: PropTypes.array,
    }),
  }),
  submitForm: PropTypes.func,
  clearValues: PropTypes.func,
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
  windowWidth: PropTypes.bool,
  getAccountA: PropTypes.func,
  tags: PropTypes.object,
  fetchTagsA: PropTypes.func,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  lists: PropTypes.array,
  fetchListsA: PropTypes.func,
  contactFilter: PropTypes.object,
  notifySuccessA: PropTypes.func,
  propertyId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  orderBy: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  preprocessContactListA: bindActionCreators(preprocessContactList.request, dispatch),
  submitForm: bindActionCreators(submit, dispatch),
  clearValues: bindActionCreators(clearPreprocessingValues, dispatch),
  getAccountA: bindActionCreators(getAccount.request, dispatch),
  fetchTagsA: bindActionCreators(fetchTags.request, dispatch),
  fetchListsA: bindActionCreators(fetchLists.request, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
});

const mapStateToProps = (state) => ({
  uploadedFile: state.fileUpload,
  user: state.user,
  tags: state.tags,
  lists: state.lists.elements,
  contactFilter: state.contactFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsUploadModal);
