import { callApi } from './callApi';
import { API_ROOT } from '../config';
import { getServicePath, getFullServicePath } from '../utils/helper';

export const call = (endpoint, params, _schema) => callApi({ serviceName: getServicePath('auth'), endpoint }, params, _schema);

export const authentication = (body) => new Promise((resolve, reject) => {
  if (!window.XMLHttpRequest) {
    reject();
  }

  const xhr = new XMLHttpRequest();
  const url = API_ROOT + getFullServicePath('auth', '/login');

  xhr.open('POST', url, true);
  xhr.withCredentials = true;

  xhr.onload = () => {
    if (xhr.status === 200) {
      resolve({
        response: JSON.parse(xhr.response),
      });
    } else {
      resolve({
        error: JSON.parse(xhr.response),
      });
    }
  };

  xhr.onerror = () => {
    reject();
  };

  xhr.send(JSON.stringify(body));
});

export const verification = (body) => call(`/verify/${body.email}/${body.token}`, {
  method: 'GET',
});

export const forgotPasswordAuthentication = (body) => call('/lost', {
  method: 'POST',
  body,
}, null);

export const resetPasswordAuthentication = (body) => {
  const url = body.lost ? '/reset' : '/reset/session';
  return call(url, {
    method: 'POST',
    body,
  }, null);
};

export const changePasswordAuthentication = (body) => call('/changePassword', {
  method: 'POST',
  body,
}, null);

export const userBlock = (body) => call('/block', {
  method: 'POST',
  body,
}, null);

export const userDelete = (body) => call('/delete', {
  method: 'POST',
  body,
}, null);

export const roleChange = (body) => call('/changeRole', {
  method: 'POST',
  body,
}, null);
