import PropTypes from 'prop-types';

const RenderRadio = ({
  input,
  disabled,
  meta: { touched, error },
  labelText,
  checked,
}) => {
  const htmlFor = input.name;

  /* eslint-disable */
  return (
    <div className="ui radio checkbox login-company__checkbox">
      <input
        type="radio"
        name="radio"
        id={htmlFor}
        disabled={disabled}
        {...input}
        checked={checked}
      />
      <label className="login-company__radio-label" htmlFor={htmlFor}>
        {labelText}
      </label>
      {touched && error && <small className="error-message">{error}&nbsp</small>}
    </div>
  );
  /* eslint-enable */
};

RenderRadio.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    name: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  labelText: PropTypes.string,
};

export default RenderRadio;
