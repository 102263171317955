import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Grid, Header, List } from 'semantic-ui-react';
import {
  get,
  pick,
  orderBy,
  omit,
} from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { parse as parsePhone } from 'libphonenumber-js';

import { RegisterForm } from '../Form';
import {
  getUrlProperty,
  decodeJwtToken,
  getCountryName, setValidityIconColor,
} from '../../utils/helper';
import { transformPlans } from '../../utils/plans';
import { accountCreate } from '../../services/accountsApi';
import { fetchPlans, fetchPlansWeights } from '../../services/planApi';
import { genericActions, notificationActions, planActions } from '../../actions';

const { openModalPortal, closeModalPortal } = genericActions;
const { notifyError, notifySuccess } = notificationActions;
const { setPlans } = planActions;

const Register = ({
  setPlansA,
  notifySuccessA,
  history,
  notifyErrorA,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [predefinedValues, setPredefinedValues] = useState();
  const [token, setToken] = useState();

  useEffect(() => {
    const queryParams = getUrlProperty('query');
    const queryToken = get(queryParams, 'token', undefined);
    const selection = get(queryParams, 'selection', undefined);
    const decodedToken = queryToken ? decodeJwtToken(queryToken) : undefined;

    if (decodedToken) {
      setPredefinedValues(pick(decodedToken, ['email', 'companyName']));
      setToken(queryToken);
    }
    if (selection) {
      setSelectedPlan(selection);
    }

    handlePlans();
  }, []);

  const onFormSubmit = (payload) => {
    setLoading(true);
    handleRegister(payload);
  };

  const handlePlans = () => {
    Promise.all([
      fetchPlans(),
      fetchPlansWeights(),
    ]).then((res) => {
      const plans = res[0].response;
      const weights = orderBy(res[1].response, ['weight'], ['desc']);

      if (plans && weights) {
        setPlansA(transformPlans(plans, weights));
      }
    });
  };

  const handleRegister = (val, clientSecret) => {
    const countryFromPhone = getCountryName(parsePhone(`tel:${val.phone};ext:123`).country);
    let updatedValues = { ...val, phone: val.phone.replace(/\s/g, '') };

    if (!token) {
      updatedValues = {
        ...updatedValues,
        plan: selectedPlan.product,
      };

      if (clientSecret) {
        const {
          billingAddress,
          city,
          state,
          zip,
          country,
        } = updatedValues;

        const billing = {
          billingAddress,
          city,
          state,
          zip,
          country,
        };

        updatedValues = {
          ...updatedValues,
          billingAddress: billing,
          clientSecret,
        };

        updatedValues = omit(updatedValues, ['city', 'state', 'zip', 'country']);
      } else {
        updatedValues = {
          ...updatedValues,
          billingAddress: {
            country: countryFromPhone,
          },
        };
      }
    }

    if (updatedValues.billingAddress && !updatedValues.billingAddress.country) {
      updatedValues.billingAddress.country = countryFromPhone;
    }

    const payload = { values: updatedValues, token };

    accountCreate(payload).then((res) => {
      if (!res.error) {
        if (token) {
          notifySuccessA('Thanks for signing up! You can now login to Rispons!');
        } else {
          notifySuccessA('WE SENT YOU AN EMAIL. Please click the activation link in the email before you log in. Don\'t forget to check your SPAM folder!', 0);
        }
        history.push('/login?register=true');
      } else {
        setLoading(false);
        notifyErrorA(res.error.message);
      }
    });
  };

  return (
    <Grid className="registration-form">
      <Grid.Column className="ris-form">
        <Grid.Row>
          <Header size="large" className="app-form__header centered">
            Activate Your Free Trial
          </Header>
          <div className="registration-form__list-wrapper">
            <List className="app-form__list">
              <List.Item>
                <List.Icon name="check circle" color="green" />
                Instant access
              </List.Item>
              <List.Item>
                <List.Icon name="check circle" color="green" />
                No commitment
              </List.Item>
              <List.Item>
                <List.Icon name="check circle" color="green" />
                No credit card required
              </List.Item>
            </List>
          </div>
        </Grid.Row>
        <RegisterForm
          initialValues={predefinedValues}
          onFormSubmit={onFormSubmit}
          loading={loading}
        />
      </Grid.Column>
    </Grid>
  );
};

Register.propTypes = {
  notifyErrorA: PropTypes.func,
  notifySuccessA: PropTypes.func,
  history: PropTypes.object,
  setPlansA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  plans: state.plans.plans,
});

const mapDispatchToProps = (dispatch) => ({
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  setPlansA: bindActionCreators(setPlans, dispatch),
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));
