import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reject } from 'lodash';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { Field, submit } from 'redux-form';

import RenderSelect from './RenderSelect';
import SelectField from './SelectField';
import { required } from '../../utils/validator';
import { mapIdToOptions } from '../../utils/map';

const hasTag = (formValues, index) => formValues.filter[index].property === 'contact.tag';

const ContactFilterItem = ({
  formValues,
  index,
  fields,
  submitFormA,
  contactFilter,
  change,
  name,
  propertyOptions,
  operatorOptions,
  InputComponent,
  inputComponentProps,
  tags,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    hasTag(formValues, index) ? formValues.filter[index].value : null);

  const onRemove = (idx) => {
    fields.remove(idx);

    if (contactFilter.id && contactFilter.length !== fields.length) {
      submitFormA('contactFilter');
    }
  };

  const handleOnChange = (newSelectedOption) => {
    if (!newSelectedOption.length) {
      tagClear();
    }
    setSelectedOption(newSelectedOption);
  };

  const tagClear = () => {
    change(`${name}.value`, '');
  };

  const defaultOptions = reject(operatorOptions, (val) => val.value === 'hasTag');
  const tagsIndex = formValues.filter.map((val) => val.property).indexOf('contact.tag');

  return (
    <Grid.Row columns={4} className="space-arround no-spacing">
      <div
        className="contact-filter-form__custom-column contact-filter-form__custom-column--where"
      >
        <i
          className="material-icons"
          onClick={() => setTimeout(() => onRemove(index), 10)}
        >
          close
        </i>
        Where
      </div>
      <div
        className="contact-filter-form__custom-column contact-filter-form__custom-column--property"
      >
        <Field
          component={SelectField}
          name={`${name}.property`}
          options={propertyOptions}
          placeholder="Choose column"
          validate={required}
          onChange={tagClear}
        />
      </div>
      <div
        className="contact-filter-form__custom-column contact-filter-form__custom-column--operator"
      >
        {tagsIndex === index ? 'has tag' : (
          <Field
            name={`${name}.operator`}
            component={SelectField}
            options={defaultOptions}
            validate={required}
            placeholder="contains"
          />
        )}
      </div>
      <div
        className="contact-filter-form__custom-column contact-filter-form__custom-column--value"
      >
        {hasTag(formValues, index) && tags ? (
          <Field
            name={`${name}.value`}
            component={RenderSelect}
            {...inputComponentProps}
            className="react-select tag"
            data-tip="Do not forget to press ↵ Enter after typing the tag"
            id="tagsInput1"
            validate={required}
            multi
            labelKey="text"
            simpleValue
            options={mapIdToOptions(tags)}
            handleOnChange={handleOnChange}
            placeholder="Enter tags and select from list"
            selectedOption={selectedOption}
          />
        ) : (
          InputComponent && (
            <Field
              name={`${name}.value`}
              component={InputComponent}
              {...inputComponentProps}
              placeholder="Some text"
              className="contact-filter-form__input"
            />
          )
        )}
      </div>
    </Grid.Row>
  );
};

ContactFilterItem.propTypes = {
  name: PropTypes.string,
  propertyOptions: PropTypes.arrayOf(PropTypes.object),
  operatorOptions: PropTypes.arrayOf(PropTypes.object),
  formValues: PropTypes.object,
  InputComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]),
  inputComponentProps: PropTypes.shape({
    validate: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.array,
    ]),
    type: PropTypes.string,
  }),
  fields: PropTypes.shape({
    remove: PropTypes.func,
    length: PropTypes.number,
  }),
  index: PropTypes.number,
  tags: PropTypes.array,
  onRemoveButtonClick: PropTypes.func,
  submitFormA: PropTypes.func,
  contactFilter: PropTypes.object,
  change: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  submitFormA: bindActionCreators(submit, dispatch),
});

const mapStateToProps = (state) => ({
  contactFilter: state.contactFilter,
  tags: Object.values(state.tags),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactFilterItem);
