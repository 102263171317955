import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware, { END } from 'redux-saga';
import { paginatorMiddleware } from '../modules/redux-paginator';
import reducers from '../reducers';
import { sessionManagerMiddleware } from '../middlewares';

/* eslint-disable */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sessionManagerMiddleware, thunk, paginatorMiddleware, sagaMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = composeEnhancers(middlewareEnhancer);

export default function configureStore(initialState) {
  const store = createStore(reducers, initialState, enhancers);
  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}
