import {
  filter,
  find,
  forEach,
  map,
  orderBy,
  remove,
} from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import {
  NODE_WIDTH,
  NODE_HEIGHT,
  HORIZONTAL_GAP,
  VERTICAL_GAP,
  SUBJECT_TYPES,
  MINUTES_IN_DAY,
  MINUTES_IN_HOUR,
  MINUTES_IN_WEEK,
} from '../common/automation';
import { DATE_TIME } from '../common';
import { contactTagCreate } from '../services/tagApi';
import { listCreate } from '../services/contactApi';

// recursive function for calculating max depth and width of automation tree
export const findTreeWidthAndDepth = (currentNode, nodes, depth, parentId) => {
  const node = currentNode;
  const { pathList, id } = node;
  let width = 0;
  let maxDepth = depth - 1;
  forEach(pathList, (path) => {
    const { nodeId } = path;
    const childNode = find(nodes, (n) => n.id === nodeId);
    childNode.depth = depth;
    const [childWidth, childDepth] = findTreeWidthAndDepth(childNode, nodes, depth + 1, id);
    width += childWidth;
    if (childDepth > maxDepth) {
      maxDepth = childDepth;
    }
  });
  if (width === 0) {
    width = 1;
  }
  node.maxWidth = width;
  node.parentId = parentId;
  return [width, maxDepth];
};

// function, where node position in editor is set
export const setNodePosition = (currentNode, calculatedPosition) => {
  const node = currentNode;
  const { depth } = node;
  // node position from the top
  // node height: 105px, vertical gap: 150px
  node.top = (depth - 1) * (NODE_HEIGHT + VERTICAL_GAP);
  // node position from the left
  node.left = calculatedPosition;
};

// function, that sets node's path from parent label position
const setLabelPositions = (nodes, orderedPaths, border, left, hasSiblings) => {
  let currentBorder = border;
  forEach(orderedPaths, (path) => {
    const { nodeId, labelPosition } = path;
    const childNode = find(nodes, (node) => node.id === nodeId);
    // calculating difference between node and border
    const dx = Math.abs(currentBorder - labelPosition) / 2;
    // setting label position to node
    childNode.labelLeft = left
      ? labelPosition + dx
      : currentBorder + dx;
    currentBorder = labelPosition;
    childNode.hasSiblings = hasSiblings;
  });
};

// recursive function, which calls for all child node position calculation
export const calculateNodePositions = (currentNode, nodes) => {
  // NOTE: node width: 600px, horizontal gap: 200px
  const {
    pathList,
    maxWidth: parentMaxWidth,
    left,
  } = currentNode;
  // counter for already taken width positions by children
  let takenWidth = 0;
  // calculating leftmost available position for children in pixels
  const leftMostPosition = left
    - ((parentMaxWidth - 1) * ((NODE_WIDTH + HORIZONTAL_GAP) / 2));
  if (pathList && pathList.length) {
    // array for child label positioning
    const childPositions = [];
    const orderedPathList = orderBy(pathList, [(path) => {
      const nameAsNumber = parseInt(path.name, 10);
      if (Number.isNaN(nameAsNumber) || !currentNode.inputs.messageId) {
        return path.name;
      }
      return nameAsNumber;
    }], ['asc']);
    forEach(orderedPathList, (path) => {
      const { nodeId } = path;
      const childNode = find(nodes, (node) => node.id === nodeId);
      const { maxWidth } = childNode;
      // if there is only 1 child, its position is the same as parent's
      let nodePosition = left;
      if (pathList.length > 1) {
        // otherwise it's calculated by leftmost still available position
        // and its needed width for its children
        nodePosition = leftMostPosition + (takenWidth * (NODE_WIDTH + HORIZONTAL_GAP))
          + (((maxWidth - 1) * ((NODE_WIDTH + HORIZONTAL_GAP) / 2)));
        takenWidth += maxWidth;
      }
      // adding child node id and middle position from left
      childPositions.push({ nodeId, labelPosition: nodePosition + (NODE_WIDTH / 2) });
      setNodePosition(childNode, nodePosition);
      calculateNodePositions(childNode, nodes);
    });
    const hasSiblings = pathList.length > 1;
    // filtering path, that goes straight down
    const middlePath = filter(childPositions,
      (child) => child.labelPosition === left + (NODE_WIDTH / 2));
    // filtering paths, going left
    const pathsToLeft = filter(childPositions,
      (child) => child.labelPosition < left + (NODE_WIDTH / 2));
    // filtering paths, going right
    const pathsToRight = filter(childPositions,
      (child) => child.labelPosition > left + (NODE_WIDTH / 2));
    if (middlePath && middlePath.length) {
      const { nodeId, labelPosition } = middlePath[0];
      const childNode = find(nodes, (node) => node.id === nodeId);
      childNode.labelLeft = labelPosition;
      childNode.hasSiblings = hasSiblings;
    }
    if (pathsToLeft && pathsToLeft.length) {
      // ordering path by position, descending
      const orderedPaths = orderBy(pathsToLeft, ['labelPosition'], ['desc']);
      // border is parent node's middle
      const border = left + (NODE_WIDTH / 2);
      setLabelPositions(nodes, orderedPaths, border, true, hasSiblings);
    }
    if (pathsToRight && pathsToRight.length) {
      // ordering path by position, ascending
      const orderedPaths = orderBy(pathsToRight, ['labelPosition'], ['asc']);
      // border is parent node's middle
      const border = left + (NODE_WIDTH / 2);
      setLabelPositions(nodes, orderedPaths, border, false, hasSiblings);
    }
  }
};

// function to get all edges and connection positions between nodes
export const getEdges = (nodes) => {
  const edges = [];
  // iterating trough all the nodes
  forEach(nodes, (node) => {
    const { pathList, left: parentLeft } = node;
    if (pathList && pathList.length) {
      const nodeEdges = [];
      // if node has paths, iterating trough them
      forEach(pathList, (path) => {
        const { nodeId } = path;
        // assigning default properties to edge
        const edge = { ...path };

        const {
          top,
          left,
          actionType,
          start,
        } = node;
        // calculating parent connecting position
        edge.parentTop = top + NODE_HEIGHT;
        edge.parentLeft = left + (NODE_WIDTH / 2);
        // searching for child node
        const child = find(nodes, (n) => n.id === nodeId);
        const {
          top: childTop,
          left: childLeft,
          labelLeft,
          hasSiblings,
        } = child;
        // calculating child connecting position
        edge.nodeTop = childTop;
        edge.nodeLeft = childLeft + (NODE_WIDTH / 2);
        // checking if node label should be shown
        edge.showName = actionType === 'filter' && !start;
        // adding calculated label position
        edge.labelLeft = labelLeft;
        edge.hasSiblings = hasSiblings;
        nodeEdges.push(edge);
      });
      // ordering edges from center for svg drawing order
      const edgesToLeft = remove(nodeEdges,
        (edge) => edge.labelLeft < parentLeft + (NODE_WIDTH / 2));
      const orderedLeft = orderBy(edgesToLeft, ['labelLeft'], ['asc']);
      const orderedRight = orderBy(nodeEdges, ['labelLeft'], ['desc']);
      if (orderedLeft && orderedLeft.length) {
        edges.push(...orderedLeft);
      }
      edges.push(...orderedRight);
    }
  });
  return edges;
};

export const disableAddEditNodeButton = (
  subject,
  message,
  survey,
  question,
  answers,
  selectedTags,
  selectedLists,
  delay,
  delayInterval,
  time,
  timeZone,
) => {
  switch (subject) {
    case SUBJECT_TYPES.OPEN:
    case SUBJECT_TYPES.SMS:
    case SUBJECT_TYPES.EMAIL:
      return !message;
    case SUBJECT_TYPES.SURVEY:
      return !(message && survey);
    case SUBJECT_TYPES.QUESTION:
      return !(message && survey && question && answers && answers.length);
    case SUBJECT_TYPES.CONTAINS_TAG:
    case SUBJECT_TYPES.ADD_TAG:
    case SUBJECT_TYPES.REMOVE_TAG:
      return !selectedTags;
    case SUBJECT_TYPES.IN_LIST:
    case SUBJECT_TYPES.ADD_TO_LIST:
    case SUBJECT_TYPES.REMOVE_FROM_LIST:
      return !selectedLists;
    case SUBJECT_TYPES.DELAY:
      return !(delay && delayInterval);
    case SUBJECT_TYPES.TIME:
      return !(time && timeZone);
    default:
      return true;
  }
};

const getListTagName = (selected, options, apiCall) => {
  if (typeof selected === 'number') {
    return find(options, (option) => option.id === selected).name;
  } if (typeof selected.value !== 'number') {
    Promise.all([apiCall({ name: selected.label })]);
  }
  return selected.label;
};

export const prepareNodeInputsPayload = (
  subject,
  message,
  survey,
  question,
  answers,
  selectedTags,
  selectedLists,
  delay,
  delayInterval,
  time,
  timeZone,
  additionalDecision,
  lists,
  tags,
) => {
  const inputs = {};
  switch (subject) {
    case SUBJECT_TYPES.SMS:
    case SUBJECT_TYPES.EMAIL: {
      inputs.messageId = message;
      break;
    }
    case SUBJECT_TYPES.OPEN: {
      inputs.messageId = message;
      inputs.additionalDecision = additionalDecision.toString();
      break;
    }
    case SUBJECT_TYPES.SURVEY: {
      inputs.messageId = message;
      inputs.surveyName = survey;
      inputs.additionalDecision = additionalDecision.toString();
      break;
    }
    case SUBJECT_TYPES.QUESTION: {
      inputs.messageId = message;
      inputs.surveyName = survey;
      inputs.inputQuestionId = question;
      const paths = map(answers, (answer) => ({ name: 'IS_EQUAL', comparator: answer }));
      inputs.inputAnswerValue = JSON.stringify({ paths });
      break;
    }
    case SUBJECT_TYPES.ADD_TAG:
    case SUBJECT_TYPES.REMOVE_TAG: {
      inputs.tagName = getListTagName(selectedTags, tags, contactTagCreate);
      break;
    }
    case SUBJECT_TYPES.CONTAINS_TAG: {
      inputs.tagName = getListTagName(selectedTags, tags, contactTagCreate);
      inputs.additionalDecision = additionalDecision.toString();
      break;
    }
    case SUBJECT_TYPES.ADD_TO_LIST:
    case SUBJECT_TYPES.REMOVE_FROM_LIST: {
      inputs.listName = getListTagName(selectedLists, lists, listCreate);
      break;
    }
    case SUBJECT_TYPES.IN_LIST: {
      inputs.listName = getListTagName(selectedLists, lists, listCreate);
      inputs.additionalDecision = additionalDecision.toString();
      break;
    }
    case SUBJECT_TYPES.DELAY: {
      inputs.minutes = (delay * delayInterval).toString();
      break;
    }
    case SUBJECT_TYPES.TIME: {
      let timeStr = moment(time).format();
      timeStr = `${timeStr.slice(0, timeStr.length - 6)}+00:00`;
      const tz = moment(timeStr).tz(timeZone).format().slice(-6);
      timeStr = `${timeStr.slice(0, timeStr.length - 6)}${tz}`;
      inputs.datetime = moment(timeStr).tz('UTC').format();
      break;
    }
    default:
  }
  return inputs;
};

export const generateNodeName = (subject, inputs, messages) => {
  switch (subject) {
    case SUBJECT_TYPES.OPEN:
      return `Message opened "${messages[inputs.messageId].name}"`;
    case SUBJECT_TYPES.CONTAINS_TAG:
      return `Contains tag "${inputs.tagName}"`;
    case SUBJECT_TYPES.IN_LIST:
      return `Added to list "${inputs.listName}"`;
    case SUBJECT_TYPES.SURVEY:
      return `Survey submitted "${inputs.surveyName}"`;
    case SUBJECT_TYPES.QUESTION: {
      const message = messages[inputs.messageId];
      const { schema: { forms } } = message;
      const form = find(forms, (f) => f.displayName === inputs.surveyName);
      const question = find(form.elements,
        (element) => element.inputName === inputs.inputQuestionId);
      return `Question answered "${question.label}"`;
    }
    case SUBJECT_TYPES.SMS:
      return `Send SMS "${messages[inputs.messageId].name}"`;
    case SUBJECT_TYPES.EMAIL:
      return `Send email "${messages[inputs.messageId].name}"`;
    case SUBJECT_TYPES.ADD_TO_LIST:
      return `Add to list "${inputs.listName}"`;
    case SUBJECT_TYPES.REMOVE_FROM_LIST:
      return `Remove from list "${inputs.listName}"`;
    case SUBJECT_TYPES.ADD_TAG:
      return `Add tag "${inputs.tagName}"`;
    case SUBJECT_TYPES.REMOVE_TAG:
      return `Remove tag "${inputs.tagName}"`;
    case SUBJECT_TYPES.DELAY: {
      let delayString = '';
      let { minutes } = inputs;
      if (minutes >= MINUTES_IN_WEEK) {
        const weeks = Math.floor(minutes / MINUTES_IN_WEEK);
        minutes -= weeks * MINUTES_IN_WEEK;
        delayString = `${weeks} week${weeks === 1 ? '' : 's'}`;
      }
      if (minutes >= MINUTES_IN_DAY) {
        const days = Math.floor(minutes / MINUTES_IN_DAY);
        minutes -= days * MINUTES_IN_DAY;
        delayString = `${delayString ? `${delayString} ` : ''}${days} day${days === 1 ? '' : 's'}`;
      }
      if (minutes >= MINUTES_IN_HOUR) {
        const hours = Math.floor(minutes / MINUTES_IN_HOUR);
        minutes -= hours * MINUTES_IN_HOUR;
        delayString = `${delayString ? `${delayString} ` : ''}${hours} hour${hours === 1 ? '' : 's'}`;
      }
      if (minutes > 0) {
        delayString = `${delayString ? `${delayString} ` : ''}${minutes} minute${minutes === 1 ? '' : 's'}`;
      }
      return `Delay for ${delayString}`;
    }
    case SUBJECT_TYPES.TIME:
      return `Resume on ${moment(inputs.datetime).format(DATE_TIME)}`;
    default:
      return '';
  }
};
