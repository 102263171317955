import { memo } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import { featuresKeys } from '../../../../../common/plans';

const PlansFeatures = ({ plans }) => (
  <div className="ris-features">
    <div className="ris-pricing-section-title">
      FEATURES
    </div>
    {map(featuresKeys.features, (feature, key) => (
      <div
        key={key}
        className="ris-row"
      >
        <div className="ris-feature">{feature}</div>
        <div className="ris-feature-inner">
          {map(plans, ({ product, features }) => (
            <div
              key={product}
              className={`ris-feature-inner-box ris-feature-inner-box--${product}`}
            >
              {features[key] ? <div className="ris-checkbox" /> : null}
            </div>
        ))}
        </div>
      </div>
    ))}
  </div>
);

PlansFeatures.propTypes = {
  plans: PropTypes.array,
};

export default memo(PlansFeatures);
