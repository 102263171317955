import formActionSaga from 'redux-form-saga';
import { all, fork } from 'redux-saga/effects';
import { requestPageWatcher } from '../modules/redux-paginator';
import { watchPageRedirection } from './genericSagas';
import watchContacts from './contactsSagas';
import watchLogin from './authSagas';
import watchAnalytics from './analyticsSagas';
import watchAccount from './accountSagas';
import watchMessages from './messageSagas';
import watchForm from './formSagas';
import watchMedia from './mediaSagas';
import watchPlans from './planSagas';
import watchSurveys from './surveySagas';

export default function* root(history) {
  yield all([
    fork(watchContacts),
    fork(watchAccount, history),
    fork(watchLogin),
    fork(watchMessages),
    fork(formActionSaga),
    fork(requestPageWatcher),
    fork(watchForm),
    fork(watchPageRedirection, history),
    fork(watchPlans),
    fork(watchMedia),
    fork(watchAnalytics),
    fork(watchSurveys),
  ]);
}
