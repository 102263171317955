import { memo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

const ChartJsWrapper = ({
  data,
  options,
  component: Component,
  type,
}) => (
  <Component
    data={data}
    options={options}
    type={type}
  />
);

ChartJsWrapper.propTypes = {
  data: PropTypes.object,
  options: PropTypes.object,
  component: PropTypes.any,
  type: PropTypes.string,
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data);

export default memo(ChartJsWrapper, areEqual);
