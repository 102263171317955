import PropTypes from 'prop-types';

import { Statistic } from 'semantic-ui-react';

const StatsBlock = ({
  stats,
  color = 'teal',
  className,
}) => (
  <div className={className}>
    <Statistic color={color} className="ct">
      <Statistic.Value>{stats}</Statistic.Value>
    </Statistic>
  </div>
);

StatsBlock.propTypes = {
  color: PropTypes.string,
  stats: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
};

export default StatsBlock;
