import PropTypes from 'prop-types';
import { Accordion } from 'semantic-ui-react';
import StatsBlock from '../Stats/StatsBlock';
import Donut from '../Chart/Donut';

const VideoInfo = ({
  numOfPlaybacks,
  numOfUniquePlaybacks,
  playbackRate,
  activeIndex,
  onClick,
  index,
}) => (
  <Accordion fluid styled className="statistics-accordion">
    <Accordion.Title active={activeIndex[index]} onClick={onClick}>
      Video-
      {index}
    </Accordion.Title>
    <Accordion.Content active={activeIndex[index]} className="message-statistics">
      <div className="overall-statistics__segment-container">
        <div className="overall-statistics__box overall-statistics__segment">
          <div className="overall-statistics__box__header">Playbacks</div>
          <div className="overall-statistics__box__content">
            <StatsBlock
              title="Playbacks"
              stats={numOfPlaybacks}
              color="blue"
            />
          </div>

        </div>
        <div className="overall-statistics__box overall-statistics__segment">
          <div className="overall-statistics__box__header">Playback rate</div>
          <div className="overall-statistics__box__content">
            <>
              {numOfUniquePlaybacks !== 0 ? (
                <Donut
                  label={[
                    'Unique plays',
                    'Did not play',
                  ]}
                  percent={playbackRate * 100}
                  borderColor="#fff"
                  data={[
                    numOfUniquePlaybacks,
                    (numOfUniquePlaybacks / playbackRate) - numOfUniquePlaybacks,
                  ]}
                  backgroundColor={[
                    '#1cb59f',
                    '#e0e0e0',
                  ]}
                  not={numOfPlaybacks - numOfUniquePlaybacks}
                />
              ) : (
                <div className="overall-statistics__box__content--disabled">
                  No data yet
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </Accordion.Content>
  </Accordion>
);

VideoInfo.propTypes = {
  numOfPlaybacks: PropTypes.number,
  numOfUniquePlaybacks: PropTypes.number,
  playbackRate: PropTypes.number,
  activeIndex: PropTypes.array,
  onClick: PropTypes.func,
  index: PropTypes.number,
};

export default VideoInfo;
