import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Grid } from 'semantic-ui-react';
import { SelectField, Label } from '.';
import { Icon } from '../Icon';
import { required } from '../../utils/validator';
import { useScreenWidth } from '../../hooks';

const Property = ({
  field,
  options,
  valueComponent: ValueComponent,
  valueComponentProps,
  onRemove,
  removable,
  clearValue,
  text,
}) => {
  const { isMobile } = useScreenWidth();

  let component = SelectField;
  let propertyText = text;
  if (options.length === 1 && options[0].value === 'name') {
    component = Label;
    propertyText = options[0].text;
  }

  return (
    <Grid className="no-spacing" centered>
      {!isMobile ? (
        <Grid.Row only="computer tablet" columns={2}>
          <Grid.Column className="contact-modal__input contact-modal__input--desktop" width={1}>
            {removable
              && <Icon name="close field-remove" onClick={onRemove} />}
          </Grid.Column>
          <Grid.Column className="contact-modal__label contact-modal__label--desktop" width={4}>
            <Field
              name={`${field}.name`}
              component={component}
              options={options}
              placeholder="Choose value"
              validate={required}
              onChange={() => clearValue(`${field}.value`)}
              text={propertyText}
              tabIndex={-1}
            />
          </Grid.Column>
          <Grid.Column className="contact-modal__input contact-modal__input--desktop" computer={5} tablet={6}>
            {ValueComponent
              && (
                <Field
                  name={`${field}.value`}
                  component={ValueComponent}
                  {...valueComponentProps}
                />
              )}
          </Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Column only="mobile" width={16}>
          <Grid.Row className="contact-modal__label contact-modal__label--mobile">
            <Field
              name={`${field}.name`}
              component={component}
              options={options}
              placeholder="Choose value"
              validate={required}
              onChange={() => clearValue(`${field}.value`)}
              text={propertyText}
            />
            {removable
            && <Icon name="close field-remove" onClick={onRemove} />}
          </Grid.Row>
          <Grid.Row className="contact-modal__input contact-modal__input--mobile">
            {ValueComponent
            && (
              <Field
                className="app-form__field"
                name={`${field}.value`}
                component={ValueComponent}
                {...valueComponentProps}
              />
            )}
          </Grid.Row>
        </Grid.Column>
)}
    </Grid>
  );
};

Property.propTypes = {
  field: PropTypes.string,
  options: PropTypes.array,
  valueComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.func,
  ]),
  valueComponentProps: PropTypes.object,
  onValueChange: PropTypes.func,
  onRemove: PropTypes.func,
  clearValue: PropTypes.func,
  removable: PropTypes.bool,
  text: PropTypes.string,
};

export default Property;
