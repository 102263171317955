import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { useLocation } from 'react-router';

// Compositions.
import RegularEditorView from './compositions/RegularEditorView';
import SentMessageEditorPreview from './compositions/SentMessageEditorPreview';
import AutomationEditorView from './compositions/AutomationEditorView';

// Context.
import { MessageContext } from '../context/messageEditor';
import { EditorContext } from './context/EditorContext';

// Constants.
import { TABLET_MAX_WIDTH } from '../common/constants';

// Modals.
import { ModalPortal } from '../containers/Modal';

// Hooks.
import { useScreenWidth } from '../hooks';

const Editor = ({ handleMessageClose }) => {
  const {
    activeElement,
    isChangesDetected,
    getCurrentProject,
  } = useContext(EditorContext);

  const { pathname } = useLocation();

  const { windowWidth } = useScreenWidth();

  const isTablet = windowWidth <= TABLET_MAX_WIDTH;

  const getIsPreview = () => pathname.split('/').pop() === 'preview'
    || (status === 'sent')
    || status === 'active-sending';

  const [preview, setPreview] = useState(getIsPreview());
  const [
    displayAutomationToolbar,
    setDisplayAutomationToolbar,
  ] = useState(isAutomation || !preview);

  const [
    displayResponsiveToolbar,
    setDisplayResponsiveToolbar,
  ] = useState(!!isTablet && !!activeElement);

  const {
    messageEditor: { status },
    handleSave,
    isAutomation,
    turnOnAutoSave,
    turnOffAutoSave,
  } = useContext(MessageContext);

  const isRegularResponsiveEditor = !isAutomation && isTablet
    && !!activeElement && !displayResponsiveToolbar;

  useEffect(() => {
    if (isChangesDetected) {
      turnOnAutoSave();
    }

    return () => {
      turnOffAutoSave();
    };
  }, [isChangesDetected]);

  useEffect(() => {
    if (getIsPreview()) {
      setPreview(true);
    }

    return () => {
      if (preview) {
        setPreview(false);
      }
    };
  }, [status, pathname]);

  useEffect(() => {
    if (isRegularResponsiveEditor) {
      setDisplayResponsiveToolbar(true);
    }
  }, [isTablet]);

  const handleAutomationSave = () => {
    const projectData = getCurrentProject();
    handleSave(projectData);
    handleMessageClose();
  };


  return (
    <>
      <ModalPortal />
      <DndProvider options={HTML5toTouch}>
        {/* regular editor with responsive layout */}
        {(!preview && !isAutomation) && (
          <RegularEditorView
            activeElement={activeElement}
            isTablet={isTablet}
            displayResponsiveToolbar={displayResponsiveToolbar}
            setDisplayResponsiveToolbar={
              () => setDisplayResponsiveToolbar(!displayResponsiveToolbar)
            }
          />
        )}

        {/* preview state */}
        {(!!preview && !isAutomation) && (
          <SentMessageEditorPreview preview={preview} />
        )}

        {/* automation */}
        {(!!isAutomation && !preview) && (
          <AutomationEditorView
            handleAutomationSave={() => handleAutomationSave()}
            handleMessageClose={handleMessageClose}
            setDisplayAutomationToolbar={
              () => setDisplayAutomationToolbar(!displayAutomationToolbar)
            }
            displayAutomationToolbar={displayAutomationToolbar}
            preview={preview}
          />
        )}
      </DndProvider>
    </>
  );
};

Editor.propTypes = {
  handleMessageClose: PropTypes.func,
};

export default Editor;
