import { map } from 'lodash';

export const mapTags = (tags) => {
  let tag = [];
  if (tags.length) {
    tag = map(tags, (t) => t.name);
  }
  return tag;
};

export const multiDimensionalUnique = (arr) => arr.map(JSON.stringify)
  .reverse()
  .filter((e, i, a) => a.indexOf(e, i + 1) === -1)
  .reverse()
  .map(JSON.parse);

export const mapTagsToOptions = (tags) => map(tags, (tag) => ({
  value: tag.name,
  label: tag.name,
}));
