import { cloneElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import {
  Notification,
  ExpiredUserNotification,
} from '../../components/Notification';
import { HeaderMain, HeaderExpired } from '../Header';
import { FooterMain } from '../../components/Footer';
import { ConfirmationModal, ModalPortal } from '../Modal';
import { getSession } from '../../actions/planActions';

const MainLayout = (props) => {
  const { children, userRole, getSessionA } = props;
  const location = useLocation();

  useEffect(() => {
    getSessionA();
  }, []);

  return (
    <div className={`app-main${location.pathname === '/contacts' ? ' app-main--contacts' : ''}`}>
      {userRole === 'expired' && <ExpiredUserNotification />}
      {userRole === 'expired' ? <HeaderExpired /> : <HeaderMain />}
      <ConfirmationModal />
      <ModalPortal />
      <Notification />
      <div className="app-content__main">
        {children && cloneElement(children, props)}
      </div>
      <FooterMain userRole={userRole} />
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element,
  userRole: PropTypes.string,
  getSessionA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userRole: state.user.role,
});

const mapDispatchToProps = (dispatch) => ({
  getSessionA: bindActionCreators(getSession.request, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
