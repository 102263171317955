import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { get } from 'lodash';
import { PropertyMatch } from '../../components/Field';
import { mapHeadersToOptions } from '../../utils/map';

const PropertiesMatchArray = ({ fields, contactProperties, formValues }) => (
  <Grid>
    {fields.map((field, index) => (
      <PropertyMatch
        key={index}
        field={field}
        options={mapHeadersToOptions(contactProperties, formValues, index, 'headers')}
        header={formValues && get(formValues, `headers[${index}].header`, '')}
      />
    ))}
  </Grid>
);

PropertiesMatchArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
  }),
  contactProperties: PropTypes.objectOf(PropTypes.object),
  formValues: PropTypes.object,
};

export default PropertiesMatchArray;
