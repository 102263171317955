import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';

import Checkout from './Checkout';

const StoreCheckout = ({
  getSecret,
  sendSecret,
  checkCardValidity,
  notifyErrorA,
}) => (
  <Elements>
    <Checkout
      getSecret={getSecret}
      sendSecret={sendSecret}
      checkCardValidity={checkCardValidity}
      notifyErrorA={notifyErrorA}
    />
  </Elements>
);

StoreCheckout.propTypes = {
  getSecret: PropTypes.bool,
  sendSecret: PropTypes.func,
  checkCardValidity: PropTypes.func,
  notifyErrorA: PropTypes.func,
};

export default StoreCheckout;
