import PropTypes from 'prop-types';

const ContactsSelectedMenu = ({
  animation,
  totalCount,
  selected,
  excluded,
  listAction,
  clickMenuItem,
  onDeleteClick,
}) => (
  <div className="contact-select-menu" id="contact-select-menu">
    <div className="contact-select-menu__content">
      <span className={`contact-select-menu__content__count contact-select-menu--animation${animation ? '-up' : '-down'}`}>
        {`${typeof selected === 'string' ? totalCount - excluded.length : selected.length} selected`}
      </span>
      <span
        className={`contact-select-menu__content__button list contact-select-menu--animation${animation ? '-up' : '-down'}`}
        onClick={() => listAction('add')}
      >
        Add to List
      </span>
      <span
        className={`contact-select-menu__content__button list contact-select-menu--animation${animation ? '-up' : '-down'}`}
        onClick={() => listAction('delete')}
      >
        Remove from List
      </span>
      <span
        className={`contact-select-menu__content__button tag contact-select-menu--animation${animation ? '-up' : '-down'}`}
        onClick={() => clickMenuItem('addTags')}
      >
        Add Tag
      </span>
      <span
        className={`contact-select-menu__content__button tag contact-select-menu--animation${animation ? '-up' : '-down'}`}
        onClick={() => clickMenuItem('removeTags')}
      >
        Remove Tag
      </span>
      <span
        className={`contact-select-menu__content__button delete contact-select-menu--animation${animation ? '-up' : '-down'}`}
        onClick={() => onDeleteClick()}
      >
        Delete
      </span>
    </div>
  </div>
);

ContactsSelectedMenu.propTypes = {
  animation: PropTypes.bool,
  totalCount: PropTypes.number,
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  excluded: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  listAction: PropTypes.func,
  clickMenuItem: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default ContactsSelectedMenu;
