import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { map } from 'lodash';

import Header from '../../../components/Analytics/Header';
import Overview from '../../../components/Analytics/Overview';
import Details from '../../../components/Analytics/Details';
import {
  contactActions,
  messageActions,
  analyticsActions,
  accountActions,
  surveyActions,
  genericActions,
  planActions,
} from '../../../actions';
import { SegmentationModal } from '../../Modal';
import { getPlanFeatures, getFeatures } from '../../../utils/plans';
import { featuresKeys } from '../../../common/plans';

momentDurationFormatSetup(moment);

const {
  fetchContactProperties,
  fetchTags,
  fetchLists,
} = contactActions;

const { fetchMessage } = messageActions;

const {
  fetchAnalyticsData,
  fetchRecipientsMetrics,
  clearAnalyticsData,
} = analyticsActions;

const { requestUsersPage } = accountActions;

const { getRecipientSurveys } = surveyActions;
const { openModalPortal, closeModalPortal } = genericActions;
const { getSession } = planActions;

const MsgStats = ({
  fetchMessageA,
  requestUsersPageA,
  fetchAnalyticsDataA,
  fetchRecipientsMetricsA,
  fetchListsA,
  fetchTagsA,
  getSessionA,
  fetchContactPropertiesA,
  clearAnalyticsDataA,
  forms,
  analyticsData,
  message,
  openModalPortalA,
  closeModalPortalA,
  features,
  match: { params: { id } },
}) => {
  const [openSurveys, setOpenSurveys] = useState([]);
  const [isOverview, setIsOverview] = useState(true);
  const [page, setPage] = useState(1);

  const timer = useRef();

  const clearTimer = () => {
      clearInterval(timer.current);
      timer.current = undefined;
    };

  useEffect(() => {
    timer.current = setInterval(() => {
      fetchAnalyticsDataA({ messageId: id });
    }, 5000);
    fetchMessageA(id);
    requestUsersPageA(1, 200);
    fetchAnalyticsDataA({ messageId: id });
    fetchRecipientsMetricsA({ messageId: id, page, pageSize: 10 });
    fetchListsA();
    fetchTagsA();
    getSessionA();
    fetchContactPropertiesA();

    return () => {
      clearTimer();
      clearAnalyticsDataA();
    };
  }, []);

  const setTimer = (overview) => {
    if (overview) {
      fetchAnalyticsDataA({ messageId: id });
    } else {
      fetchRecipientsMetricsA({ messageId: id, page, pageSize: 10 });
    }

    if (timer.current) {
      clearTimer();
    }

    if (overview) {
      timer.current = setInterval(() => {
        fetchAnalyticsDataA({ messageId: id });
      }, 5000);
    } else {
      timer.current = setInterval(() => {
        fetchRecipientsMetricsA({ messageId: id, page, pageSize: 10 });
      }, 5000);
    }
  };

  useEffect(() => {
    setTimer(false);
  }, [page]);

  useEffect(() => {
    const surveysIds = openSurveys;
    map(forms, (form) => {
      surveysIds.push(form.id);
    });
    setOpenSurveys(surveysIds);
  }, [forms]);

  const openSegmentationModal = () => {
    openModalPortalA({
      content: <SegmentationModal />,
      contentProps: {
        closeModalPortal: () => {
          closeModalPortalA();
          fetchListsA();
          fetchTagsA();
        },
        message,
      },
    });
  };

  const pageChangeHandler = (p) => {
    setPage(p + 1);
  };

  const handleDetailClick = (value) => () => {
    setIsOverview(value);
    setTimer(value);
  };

  return (
    <div>
      <Header
        analyticsData={analyticsData}
        message={message}
        openSegmentationModal={openSegmentationModal}
        features={features}
      />
      <div className="message-statistics message-statistics__navigation">
        <div
          className={`message-statistics__navigation__overview${isOverview ? '--selected' : ''}`}
          onClick={handleDetailClick(true)}
        >
          Overview
        </div>
        {getFeatures(featuresKeys.featureKeys.individualBehaviouralTracking, features) ? (
          <div
            className={`message-statistics__navigation__details${!isOverview ? '--selected' : ''}`}
            onClick={handleDetailClick(false)}
          >
            Details
          </div>
        ) : null}
        <div className="message-statistics__navigation__realtime message-statistics__navigation__realtime--desktop">
          <div className="ripple-color" />
          <div>Data shown in Realtime</div>
        </div>
      </div>
      {isOverview ? <Overview /> : <Details onPageChange={pageChangeHandler} />}
    </div>
  );
};

MsgStats.propTypes = {
  analyticsData: PropTypes.object,
  forms: PropTypes.object,
  fetchMessageA: PropTypes.func,
  fetchAnalyticsDataA: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  message: PropTypes.object,
  requestUsersPageA: PropTypes.func,
  fetchRecipientsMetricsA: PropTypes.func,
  clearAnalyticsDataA: PropTypes.func,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  fetchListsA: PropTypes.func,
  fetchTagsA: PropTypes.func,
  getSessionA: PropTypes.func,
  fetchContactPropertiesA: PropTypes.func,
  features: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  analyticsData: state.analyticsData,
  user: state.user,
  users: state.users,
  message: state.messages[props.match.params.id],
  contactTableHeaders: state.entities.contactProperties,
  forms: state.forms,
  features: getPlanFeatures(state.plans.session),
});

const mapDispatchToProps = (dispatch) => ({
  fetchMessageA: bindActionCreators(fetchMessage.request, dispatch),
  fetchAnalyticsDataA: bindActionCreators(fetchAnalyticsData.request, dispatch),
  fetchRecipientSurveyA: bindActionCreators(getRecipientSurveys.request, dispatch),
  requestUsersPageA: bindActionCreators(requestUsersPage, dispatch),
  fetchContactPropertiesA: bindActionCreators(fetchContactProperties.request, dispatch),
  fetchRecipientsMetricsA: bindActionCreators(fetchRecipientsMetrics.request, dispatch),
  clearAnalyticsDataA: bindActionCreators(clearAnalyticsData.request, dispatch),
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
  fetchListsA: bindActionCreators(fetchLists.request, dispatch),
  fetchTagsA: bindActionCreators(fetchTags.request, dispatch),
  getSessionA: bindActionCreators(getSession.request, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MsgStats));
