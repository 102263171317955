import { contactActions, request } from '../actions';

const { PREPROCESS_CONTACTS_LIST, PREPROCESS_CONTACTS_LIST_REMOVE } = contactActions;
const { SUCCESS, FAILURE } = request;

const fileUpload = (state = {}, action) => {
  switch (action.type) {
    case PREPROCESS_CONTACTS_LIST[SUCCESS]: {
      return {
        contacts: action.response,
      };
    }
    case PREPROCESS_CONTACTS_LIST[FAILURE]: {
      return {
        contacts: {
          error: action.error,
        },
      };
    }
    case PREPROCESS_CONTACTS_LIST_REMOVE: {
      return {};
    }
    default:
      return state;
  }
};

export default fileUpload;
