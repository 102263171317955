import {
 useState, useEffect, useRef, useCallback, useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import {
  filter,
  map,
  forEach,
  isEmpty,
  has,
} from 'lodash';
import MediaLibraryFilter from '../Filters/MediaLibraryFilter';
import { S3UploadModal } from '../../containers/Modal';
import { notificationActions, genericActions } from '../../actions';
import { api } from '../../services';
import { Spinner } from '../Spinner';
import MediaList from './MediaList';
import MediaPreview from './MediaPreview';
import { ENV } from '../../config';
import { mediaTypes } from '../../mockData/mediaTypes';
import { BasicButton } from '../Button';
import {
  headerHeight,
  footerHeight,
  smallFooterHeight,
  lowWarningHeight,
} from '../../common';

const { openModalPortal, closeModalPortal } = genericActions;
const { notifySuccess, notifyError } = notificationActions;
const {
  S3: {
    uploadS3Media,
    uploadSignedFile,
    listS3Media,
    deleteObject,
    deleteMultipleObjects,
    getS3FileHeadObject,
  },
} = api;
const { MEDIA_PATH_PUBLIC } = ENV;

// minimum count of media items to try to load
const minLoadSize = 4;

// size of computer and tablet item size with margin
const bigItem = 195;
// size of mobile item size with margin
const smallItem = 175;

// function to get S3 info from url
const getS3DataFromURL = (url) => {
  const match = url.match(/^https?:\/\/([^.]+).s3.([^.]+).amazonaws.com\/(.*?)\?(.*?)$/);
  if (match) {
    return {
      bucket: match[1],
      key: match[3],
      region: match[2],
    };
  }
  return undefined;
};

const MediaLibraryContainer = ({
  type,
  modal,
  notifySuccessA,
  openModalPortalA,
  closeModalPortalA,
  notifyErrorA,
  handleMediaSelect,
  selectedMedia,
  close,
}) => {
  const previewRef = useRef(-1); // Needed for scroll event listener
  const [preview, setPreviewState] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState('all');
  const [selected, setSelected] = useState([]);
  const [count, setCount] = useState(0);
  const [loadSize, setLoadSize] = useState(0);
  const [total, setTotal] = useState(-1);
  const [media, setMedia] = useState([]);
  const [mobile, setMobile] = useState(false);
  const [closed, setClosed] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [uploadedMedia, setUploadedMedia] = useState(null);

  const setPreview = (value) => {
    setPreviewState(value);
    previewRef.current = value;
  };

  useEffect(() => {
    calculateLoadSize(type);
    setFilterValue(type);

    window.addEventListener('resize', () => calculateLoadSize(type, true));
    if (!modal) {
      window.addEventListener('scroll', () => handleScroll());
    }

    return () => {
      window.removeEventListener('resize', () => calculateLoadSize(type, true));
      if (!modal) {
        window.removeEventListener('scroll', () => handleScroll());
      }
    };
  }, []);

  const getMediaType = (filename) => mediaTypes[filename.split('.')[1]];

  const handleChangeFilter = (updatedFilterValue) => {
    setFilterValue(updatedFilterValue);
    setPreview(-1);
    setTotal(-1);
    setSelected([]);

    fetchMedia(loadSize, updatedFilterValue);
  };

  const handleScroll = () => {
    if (checkPreview() && window.innerWidth > 768) {
      const previewElement = document.getElementById('preview');
      let offsetTop = headerHeight;
      if (document.getElementsByClassName('low-warning').length > 0) {
        offsetTop += lowWarningHeight;
      }
      let offsetBottom = footerHeight;
      if (window.width < 992) {
        offsetBottom += smallFooterHeight;
      }

      if (window.pageYOffset >= offsetTop) {
        if (document.body.scrollHeight - window.innerHeight - window.pageYOffset <= offsetBottom) {
          previewElement.classList.add('sticky-preview--bottom');
          previewElement.classList.remove('sticky-preview');
        } else {
          previewElement.classList.add('sticky-preview');
          previewElement.classList.remove('sticky-preview--bottom');
        }
      } else {
        previewElement.classList.remove('sticky-preview');
        previewElement.classList.remove('sticky-preview--bottom');
      }
    }
  };

  const handleDelete = (title) => {
    setPreview(-1);
    setInitialLoading(true);

    if (title) {
      deleteObject({ title }).then(() => {
        fetchMedia(count, filterValue);
        notifySuccessA('File successfully deleted');
      });
    } else {
      const filenames = [];

      forEach(selected, (id) => {
        const { filename } = media[id];
        filenames.push(filename);
      });

      setSelected([]);

      deleteMultipleObjects({ filenames }).then(() => {
        fetchMedia(count, filterValue);
        notifySuccessA('Selected files deleted successfully');
      });
    }
  };

  const openUploadModal = () => {
    openModalPortalA({
      content: <S3UploadModal />,
      closeModalPortal: closeModalPortalA,
      contentProps: {
        closeModalPortal: () => {
          closeModalPortalA();
        },
        onUpload: (fileBase64, fileName, fileType) => uploadMedia(fileBase64, fileName, fileType),
        modalName: 'Upload Media',
        accept: ['image/*', 'video/mp4', 'video/quicktime'],
        maxSize: 3145728, // 3mb for images
        maxVideoSize: 5368709120, // 5 gb for videos
      },
    });
  };

  const tryS3HeadObjectGet = (bucket, key, load, newFilterValue) => {
    getS3FileHeadObject(bucket, key).then((res) => {
      if (res && !isEmpty(res)) {
        setTimeout(() => {
          notifySuccessA('Uploaded Media Successfully Processed');
          setUploadedMedia(null);
          fetchMedia(load, newFilterValue);
        }, 5000);
      } else {
        setTimeout(() => tryS3HeadObjectGet(bucket, key, load, newFilterValue), 10000);
      }
    }).catch((err) => {
      if (err) {
        if (err.code === 'NotFound') {
          setTimeout(() => tryS3HeadObjectGet(bucket, key, load, newFilterValue), 3000);
        }
      }
    });
  };

  const uploadMedia = (fileBase64, fileName, newFileType) => {
    let load = Math.ceil(count / loadSize) * loadSize;
    if (load === 0) {
      load = loadSize;
    }

    const splitResult = newFileType.split('/');
    const fileType = splitResult[0];
    let fileExt = splitResult[1];

    if (fileType === 'image') {
      if (fileExt === 'jpeg') {
        fileExt = 'jpg';
      }
    }
    if (fileType === 'video') {
      fileExt = 'mov';
    }

    uploadS3Media(fileExt).then((res) => {
      const url = new URL(res.response.url);
      const loc = url.origin + url.pathname;
      uploadSignedFile(fileBase64, res.response.url, fileExt).then(() => {
        closeModalPortalA();
        if (modal) {
          notifySuccessA('Media Uploaded');
          handleMediaSelect({ mediaUrl: loc, title: fileName });
        } else {
          const { bucket, key } = getS3DataFromURL(res.response.url);
          const updatedUploadedMedia = {
            fullpath: `${MEDIA_PATH_PUBLIC}${key}`,
            filename: fileName,
            type: fileType,
            notification: 'Processing Media',
          };
          notifySuccessA('Uploaded Media Processing');
          setLoading(true);
          setUploadedMedia(updatedUploadedMedia);
          fetchMedia(load, filterValue, updatedUploadedMedia);
          tryS3HeadObjectGet(bucket, key, load, filterValue);
        }
      }).catch(() => {
        closeModalPortalA();
        notifyErrorA('Error while uploading signed file');
      });
    }).catch(() => {
      closeModalPortalA();
      notifyErrorA('Error while signing file to s3');
    });
  };

  const handleSelect = (index, unselect) => {
    if (unselect) {
      const newSelected = filter(selected, (i) => i !== index);
      setSelected(newSelected);
    } else {
      setSelected([...selected, index]);
    }
  };

  const handleClickPreview = (index) => {
    if (index === -1) {
      setClosed(true);
    }
    setPreview(index);
  };

  const handleLoadMore = () => {
    fetchMedia(count + loadSize, filterValue);
  };

  // calculating count depending by viewport size minus padding
  const calculateLoadSize = (fileType, resize) => {
    let rows = Math.floor((window.innerHeight - 80) / bigItem);
    let columns = Math.floor((window.innerWidth - 80) / bigItem);

    if (window.innerWidth <= 768) {
      rows = Math.floor((window.innerHeight - 80) / smallItem);
      columns = Math.floor((window.innerWidth - 20) / smallItem);
    }

    const size = rows * columns;

    const newLoadSize = size < minLoadSize ? minLoadSize : size;

    if (!resize) {
      fetchMedia(newLoadSize, fileType);
    }
    setLoadSize(newLoadSize);
    setMobile(window.innerWidth <= 768);
    if (!modal) {
      handleScroll();
    }
  };

  const fetchMedia = (newCount, newType, newUploaded) => {
    setLoading(true);
    const uploaded = newUploaded || uploadedMedia;
    let containsUploaded = false;
    listS3Media({ type: newType, count: newCount }).then((res) => {
      const { response } = res;
      let resTotal = -1;
      if (response.length < newCount) {
        resTotal = response.length;
      }

      let mappedMedia = map(response, (r) => {
        const { filename } = r;
        const fullpath = `${MEDIA_PATH_PUBLIC}${r.fullpath}`;
        if (uploaded && uploaded.fullpath === fullpath) {
          containsUploaded = true;
        }
        return {
          ...r,
          fullpath,
          thumbnail: has(r, 'thumbnail_fullpath') ? `${MEDIA_PATH_PUBLIC}${r.thumbnail_fullpath}` : null,
          type: getMediaType(filename),
        };
      });

      if (containsUploaded) {
        setUploadedMedia(null);
      } else if (uploaded && resTotal >= 0) {
        mappedMedia = [uploaded, ...media];
        if (media.length < newCount) {
          resTotal = media.length;
        }
      }
      setMedia(mappedMedia);
      setCount(mappedMedia.length);
      setTotal(resTotal);
      setLoading(false);
      setInitialLoading(false);
    });
  };

  const checkPreview = () => {
    if (!isEmpty(selectedMedia)) {
      const { title, mediaUrl } = selectedMedia;
      if (type === 'image' && title === 'No content' && previewRef.current === -1) {
        return false;
      }
      if (type === 'video') {
        const src = mediaUrl.split('/');
        if (src[src.length - 1] === 'sampleCaptions.vtt' && previewRef.current === -1) {
          return false;
        }
      }

      if (!closed) {
        return true;
      }
    }

    return previewRef.current >= 0;
  };

  useLayoutEffect(() => {
    handleScroll();
  }, []);

  const previewCheck = checkPreview();

  return (
    <div className={`media-library${modal ? '--modal' : ''}`}>
      {!(previewCheck && mobile) && (
        <div className={`media-library__main${previewCheck ? '--preview' : ''}${modal ? ' media-modal' : ''}`}>
          <div className="media-library__main__header">
            <Header as="h1" className="contacts">Media Library</Header>
          </div>
          <div className="media-library__main__filter">
            {modal ? (
              <span
                className="media-library__main__header__editor"
                onClick={() => close()}
              >
                Back to Editor
              </span>
            ) : (
              <div className="media-library__main__filter__container">
                <MediaLibraryFilter
                  changeFilterValue={handleChangeFilter}
                  filterValue={filterValue}
                />
                {selected.length > 0 && (
                  <div className="media-library__main__filter__delete" onClick={() => handleDelete()}>
                    Delete
                  </div>
                )}
              </div>
            )}
            <span className="media-library__main__header__upload" onClick={() => openUploadModal()}>Upload</span>
          </div>
          <div className="media-library__main__container">
            <Spinner loaded={!initialLoading && media && media.length >= 0}>
              <MediaList
                media={media}
                modal={modal}
                handleSelect={handleSelect}
                selected={selected}
                filterValue={filterValue}
                handlePreview={handleClickPreview}
                openUploadModal={openUploadModal}
                showEmpty={total >= 0}
                handleAdd={handleMediaSelect}
                close={close}
              />
              {total < 0 && (
                <BasicButton
                  fluid
                  content={loading ? (
                    <Spinner
                      loaded={!loading}
                      options={{
                        size: 'small',
                      }}
                    />
                  ) : 'Load more'}
                  className={`media-library__main__container__more${loading ? '--loading' : ''}`}
                  onClick={() => handleLoadMore()}
                />
              )}
            </Spinner>
          </div>
        </div>
      )}
      {previewCheck && (
        <MediaPreview
          item={media[preview]}
          handleAdd={handleMediaSelect}
          handleDelete={handleDelete}
          handleBack={handleClickPreview}
          selectedMedia={selectedMedia}
          index={preview}
          close={close}
          modal={modal}
        />
      )}
    </div>
  );
};

MediaLibraryContainer.propTypes = {
  modal: PropTypes.bool,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  type: PropTypes.string,
  selectedMedia: PropTypes.object,
  handleMediaSelect: PropTypes.func,
  close: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default connect(null, mapDispatchToProps)(MediaLibraryContainer);
