import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components.
import WidgetPictureUI from '../WidgetPictureUI';
import WidgetTextUI from '../WidgetTextUI';

// Styles.
import './WidgetOptInUI.scss';

const WidgetOptInUI = (props) => {
  const {
    index,
    data: {
      header,
      hiddenInputData,
      body,
      inputFields,
      checkboxInput,
      button,
      footer,
    },
  } = props;

  const hiddenInputTags = hiddenInputData.tags.map((item) => item.value).join();

  const dataTypes = {
    'contact.name': 'name',
    'contact.phoneNumber': 'tel',
    'contact.email': 'email',
    'contact.surname': 'lastname',
  };

// due to BE HTML validation specifics currently using "strange" class names.
// when BE will be able to validate JSON instead HTML we will rename "strange" class names.
  return (
    <form
      className="WidgetOptInUI"
      id={`optIn-${index}`}
      action=""
      method="post"
      name={`opt-in-${index}`}
      data-feedback="false"
      success-text=""
    >
      <input type="hidden" id="listId" name="list" value={hiddenInputData.list} />
      <input type="hidden" id="tagsId" name="tags" value={hiddenInputTags} />
      <header className={classNames('header', { hidden: !header.isVisible })}>
        <WidgetPictureUI {...header.img} className="header__logo" />
        <div className="header__headline">
          <WidgetTextUI content={header.content} />
          {/* hidden node for BE validation to create schema */}
          <p className="hidden">
            <span>
              {header.content.blocks[0].text}
            </span>
          </p>
        </div>
      </header>

      <section className="body">
        <div className="body__text">
          <WidgetTextUI content={body.content} />
          {/* hidden node for BE validation to create schema */}
          <p className="hidden">
            <span>
              {body.content.blocks[0].text}
            </span>
          </p>
        </div>
        <div className="body__input-fields">
          {inputFields.map((input) => (
            <input
              id={`body__input-field-${input.id}`}
              className="body__input-field"
              data-type={input.name && dataTypes[input.name]}
              type={input.type}
              placeholder={input.placeholder}
              name={input.name}
              key={input.id}
            />
            ))}
        </div>

        <div className={classNames('body__checkbox', { hidden: !checkboxInput.isVisible })}>
          <input className="WidgetOptInUI__checkbox" id={`optin-checkbox-${checkboxInput.id}`} type="checkbox"></input>
          <label htmlFor={`optin-checkbox-${checkboxInput.id}`}>
            <WidgetTextUI content={checkboxInput.content} />
            {/* hidden node for BE validation to create schema */}
            <p className="hidden">
              <span>{checkboxInput.content.blocks[0].text}</span>
            </p>
          </label>
        </div>
        <input
          className="body__submit-button"
          value={button.text}
          type="submit"
          style={{ color: button.textColor, backgroundColor: button.bgColor }}
        />
      </section>

      <footer className={classNames('footer', { hidden: !footer.isVisible })}>
        <div className="footer__text">
          <WidgetTextUI content={footer.content} />
          {/* hidden node for BE validation to create schema */}
          <p className="hidden">
            <span>
              {footer.content.blocks[0].text}
            </span>
          </p>
        </div>
      </footer>
    </form>
  );
};

const ContentPropTypes = PropTypes.shape({
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      inlineStyleRanges: PropTypes.arrayOf(
        PropTypes.shape({}),
      ),
      text: PropTypes.string,
    }),
  ).isRequired,
  entityMap: PropTypes.shape({}).isRequired,
});

WidgetOptInUI.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.shape({
    header: PropTypes.shape({
      isVisible: PropTypes.bool,
      img: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.string,
      }),
      content: ContentPropTypes,
    }),
    hiddenInputData: PropTypes.shape({
      list: PropTypes.number,
      tags: PropTypes.array,
    }),
    body: PropTypes.shape({
      content: ContentPropTypes,
    }),
    inputFields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        text: PropTypes.string,
        type: PropTypes.string,
        name: PropTypes.string,
        placeholder: PropTypes.string,
      }),
    ),
    checkboxInput: PropTypes.shape({
      id: PropTypes.string,
      isVisible: PropTypes.bool,
      content: ContentPropTypes,
    }),
    button: PropTypes.shape({
      text: PropTypes.string,
      bgColor: PropTypes.string,
      textColor: PropTypes.string,
    }),
    footer: PropTypes.shape({
      isVisible: PropTypes.bool,
      content: ContentPropTypes,
    }),
  }).isRequired,
};

export default WidgetOptInUI;
