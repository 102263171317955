import { createPaginator } from '../modules/redux-paginator';
import { api } from '../services';
import { API_ROOT } from '../config';

const { contact: { contactView } } = api;


const contactViewPaginator = createPaginator(API_ROOT, ['contact'], {
  resultsKey: 'elements',
  countKey: 'total',
  idKey: 'contact.id',
  pageArgName: 'page',
}, contactView);

export default contactViewPaginator;
