import { createFormAction } from 'redux-form-saga';
import { createRequestTypes } from './request';

export const updatePlan = createFormAction('UPDATE_PLAN');
export const savePlan = createFormAction('SAVE_PLAN');
export const addQuote = createFormAction('ADD_QUOTE');
export const FETCH_SESSION = createRequestTypes('FETCH_SESSION');
export const FETCH_COUNTRIES = createRequestTypes('FETCH_COUNTRIES');
export const FETCH_PLANS = createRequestTypes('FETCH_PLANS');

export const SET_PLANS = 'SET_PLANS';

export const deletePlan = createFormAction('DELETE_PLAN');

const action = (type, payload = {}) => ({ type, ...payload });

export const setPlans = (payload) => action(SET_PLANS, payload);

export const getPlans = {
  request: () => action(FETCH_PLANS.REQUEST),
  success: (response) => action(FETCH_PLANS.SUCCESS, response),
  failure: (error) => action(FETCH_PLANS.FAILURE, error),
};

export const getCountries = {
  request: () => action(FETCH_COUNTRIES.REQUEST),
  success: (response) => action(FETCH_COUNTRIES.SUCCESS, response),
  failure: (error) => action(FETCH_COUNTRIES.FAILURE, error),
};

export const getSession = {
  request: () => action(FETCH_SESSION.REQUEST),
  success: (response) => action(FETCH_SESSION.SUCCESS, response),
  failure: (error) => action(FETCH_SESSION.FAILURE, error),
};
