import { isEmpty } from 'lodash';

import { SelectField } from '../components/Field';

export const contactFieldOptions = [
  {
    text: 'Name',
    value: 'name',
    component: SelectField,
    options: () => [
      { text: 'Test', value: 'test' },
    ],
  },
  {
    text: 'Surname',
    value: 'surname',
    component: 'text',
  },
  {
    text: 'Phone number',
    value: 'phoneNumber',
  },
  {
    text: 'Email',
    value: 'emailAddress',
  },
  {
    text: 'Region',
    value: 'region',
  },
  {
    text: 'Country',
    value: 'country',
  },
  {
    text: 'City',
    value: 'city',
  },
  {
    text: 'Department',
    value: 'department',
  },
];

export const buttonSizeOptions = [
  { text: '9px', value: '9px' },
  { text: '10px', value: '10px' },
  { text: '11px', value: '11px' },
  { text: '12px', value: '12px' },
  { text: '13px', value: '13px' },
  { text: '14px', value: '14px' },
  { text: '16px', value: '16px' },
  { text: '18px', value: '18px' },
  { text: '24px', value: '24px' },
  { text: '30px', value: '30px' },
  { text: '36px', value: '36px' },
  { text: '48px', value: '48px' },
  { text: '60px', value: '60px' },
  { text: '72px', value: '72px' },
];

export const distributionOptions = [
  { value: 'all', text: 'SMS and Email' },
  { value: 'sms', text: 'SMS' },
  { value: 'email', text: 'Email' },
];

export const typeOptions = [
  { value: 'all', text: 'All messages' },
  { value: 'sent', text: 'Sent' },
  { value: 'frozen', text: 'Frozen' },
  { value: 'draft', text: 'Draft' },
];

export const originOptions = [
  { value: 'all', text: 'Messages and Automation' },
  { value: 'default', text: 'Messages' },
  { value: 'automation', text: 'Automation' },
];

export const roles = [
  { text: 'user', value: 'user' },
  { text: 'admin', value: 'admin' },
];

export const surveyQuestionTypes = [
  { text: 'Single choice', value: 'oneAnswer' },
  { text: 'Multiple choice', value: 'multipleAnswers' },
  { text: 'Net Promoter Score®', value: 'nps' },
  { text: 'Open question', value: 'openQuestion' },
  { text: 'Rating', value: 'rating' },
  { text: 'Like / Dislike', value: 'likeDislike' },
];

export const deliveryMethodOptions = (session, isAutomation) => {
  if (!isEmpty(session)) {
    const { subscription: { activePricing } } = session;
    const { product } = activePricing[0];

    // Disable URL method for Automation Delivery Method
    if (product !== 'free' && isAutomation) {
      return [
        { text: 'SMS', value: 'sms' },
        { text: 'Email', value: 'email' },
      ];
    }

    if (product !== 'free') {
      return [
        { text: 'SMS', value: 'sms' },
        { text: 'Email', value: 'email' },
        { text: 'URL', value: 'url' },
      ];
    }

    return [
      { text: 'Email', value: 'email' },
    ];
  }

  return [];
};

export const mediaLibraryFilterOptions = [
  { text: 'Images', value: 'image' },
  { text: 'Videos', value: 'video' },
];

export const domainTypeOptions = [
  {
    value: 'sms',
    text: 'SMS',
  },
  {
    value: 'domain',
    text: 'DKIM',
  },
];
