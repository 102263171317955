import moment from 'moment';
import { Checkbox, Popup } from 'semantic-ui-react';
import {
  forEach,
  includes,
  map,
  find,
  has,
} from 'lodash';

import { ColumnComponent } from './index';
import { generateColorFromString } from '../../utils/helper';

const ContactTableCols = (
  headers,
  handleContactSelection,
  onEditClick,
  onDeleteClick,
  selectedItems,
  checkboxSelected,
  lists,
  excluded,
  propertyId,
  orderBy,
  sortTable,
  currentListId,
) => {
  const TagComponent = (propTag, propRow, insidePopup = false) => (
    <div
      className={!propRow.original.emailEnabled && !propRow.original.smsEnabled ? 'ReactTags__tag row-dimmed' : 'ReactTags__tag'}
      key={propTag}
      onClick={(e) => insidePopup && e.stopPropagation()}
      style={{
          backgroundColor: generateColorFromString(String(propTag)),
          borderColor: generateColorFromString(String(propTag)),
        }}
    >
      {String(propTag)}
    </div>
  );

  const columns = [];
  columns.push({
    Header: (
      <ColumnComponent>
        <Checkbox
          checked={checkboxSelected}
          onChange={() => handleContactSelection('*')}
          className="inverted-checkbox-tick-color"
        />
      </ColumnComponent>
    ),
    width: 60,
    accessor: 'id',
    sortable: false,
    Cell: (row) => (
      <ColumnComponent>
        <Checkbox
          checked={(typeof selectedItems === 'string' && !includes(excluded, row.value)) || includes(selectedItems, row.value)}
        />
      </ColumnComponent>
    ),
  });

  const headersWithTags = { ...headers };

  const rand = Math.random();

  forEach(headersWithTags, (h) => {
    const sortable = has(h, 'id');
    let currentColumn;
    let asc;
    let desc;
    if (sortable) {
      const { id } = h;
      if (id === propertyId) {
        currentColumn = true;
        asc = orderBy === 'asc';
        desc = orderBy === 'desc';
      }
    }
    if (h.flags === 0 || (h.flags === 1 && typeof currentListId === 'number')) {
    columns.push({
      Header: (
        <ColumnComponent>
          <span>
            <span
              className={`${sortable ? 'contacts-table-header-column' : ''}${currentColumn ? `--${asc ? 'ascending' : ''}${desc ? 'descending' : ''}` : ''}`}
              id={sortable ? h.id : ''}
              onClick={() => {
                if (sortable) {
                  sortTable(h.id);
                }
              }}
            >
              {h.label}
            </span>
          </span>
        </ColumnComponent>
      ),
      accessor: h.name,
      sortable,
      minWidth: 150,
      Cell: (row) => {
        if (h.name === 'tag') {
          return (
            <ColumnComponent>
              <span title={row.value}>
                <div className="ReactTags" key={`ta${rand}`}>
                  {row.value.map((tag, index) => (
                    <>
                      {(index === 0 && row.value.length > 1) && (
                        <Popup
                          trigger={(
                            <div onClick={(e) => e.stopPropagation()} className="ReactTags__tag ReactTags__tag--interactive">
                              See all
                            </div>
                          )}
                          on="click"
                        >
                          <Popup.Header>
                            All tags
                          </Popup.Header>
                          <Popup.Content>
                            {row.value.map((popupTag) => (
                              TagComponent(popupTag, row, true)
                            ))}
                          </Popup.Content>
                        </Popup>
                      )}
                      {TagComponent(tag, row)}
                    </>
                  ))}
                </div>
              </span>
            </ColumnComponent>
          );
        }
        if (h.name === 'email') {
          return (
            <ColumnComponent>
              <span title={row.value}>
                <div className={!row.original.emailEnabled ? 'row-dimmed' : ''}>{row.value}</div>
              </span>
            </ColumnComponent>
          );
        }
        if (h.name === 'phoneNumber') {
          return (
            <ColumnComponent>
              <span title={row.value}>
                <div className={!row.original.smsEnabled ? 'row-dimmed' : ''}>{row.value}</div>
              </span>
            </ColumnComponent>
          );
        }
        if (h.name === 'list') {
          const { original: { listIds } } = row;
          const listCount = <span className={`contact-table-list${!row.original.emailEnabled && !row.original.smsEnabled ? ' row-dimmed' : ''}`}>{listIds.length}</span>;
          return (
            <ColumnComponent>
              <span onClick={(e) => e.stopPropagation()}>
                {listIds.length === 0 ? (
                  listCount
                ) : (
                  <Popup
                    trigger={listCount}
                    on={['click', 'hover']}
                    position="right center"
                    style={{ textAlign: 'center' }}
                    basic
                    inverted
                  >
                    <Popup.Content>
                      {map(listIds, (id) => {
                        if (lists) {
                          const list = find(lists, (li) => li.id === id);
                          if (list) {
                            const { name } = list;
                            return <div key={id}>{name}</div>;
                          }
                          return null;
                        }
                        return null;
                      })}
                    </Popup.Content>
                  </Popup>
                )}
              </span>
            </ColumnComponent>
          );
        }
        if (h.name === 'listTimestamp') {
          return (
            <ColumnComponent>
              <span title={row.value}>
                <div className={!row.original.emailEnabled && !row.original.smsEnabled ? 'row-dimmed' : ''}>{moment(row.value).format('YYYY-MM-DD HH:MM:SS')}</div>
              </span>
            </ColumnComponent>
          );
        }
        return (
          <ColumnComponent>
            <span title={row.value}>
              <div className={!row.original.emailEnabled && !row.original.smsEnabled ? 'row-dimmed' : ''}>{row.value}</div>
            </span>
          </ColumnComponent>
        );
      },
    });
  }
  });

  columns.push({
    Header: '',
    accessor: 'id',
    sortable: false,
    width: 120,
    Cell: (row) => (
      <ColumnComponent>
        <div>
          <i
            className={!row.original.emailEnabled && !row.original.smsEnabled ? 'material-icons row-dimmed' : 'material-icons'}
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              onEditClick(row.value);
            }}
          >
            mode_edit
          </i>
          <i
            className="material-icons"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              onDeleteClick(row.value);
            }}
          >
            delete
          </i>
        </div>
      </ColumnComponent>
    ),
  });

  return columns;
};

export default ContactTableCols;
