import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteObject, listS3Media } from '../services/S3';
import { mediaActions } from '../actions';
import { requestSaga, showGlobalError } from './genericSagas';

const {
  FETCH_MEDIA,
  DELETE_MEDIA,
  deleteMedia,
} = mediaActions;

function* fetchMediaSaga({ mediaType }) {
  try {
    const s3Media = yield call(listS3Media, mediaType);
    yield put({ type: FETCH_MEDIA.SUCCESS, s3Media, mediaType });
  } catch (error) {
    yield call(showGlobalError, error);
  }
}

function* deleteMediaSaga(args) {
  const { payload: { mediaType, title } } = args;
  yield call(requestSaga, deleteMedia, deleteObject, args, undefined, { mediaType, title });
}

export default function* watchMedia() {
  yield takeLatest(FETCH_MEDIA.REQUEST, fetchMediaSaga);
  yield takeLatest(DELETE_MEDIA.REQUEST, deleteMediaSaga);
  yield takeLatest(DELETE_MEDIA.FAILURE, showGlobalError);
}
