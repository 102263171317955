import { request } from '../actions';
import { FETCH_RECIPIENTS_METRICS, CLEAR_ANALYTICS_DATA } from '../actions/analyticsActions';

const { SUCCESS, REQUEST } = request;

const recipientsMetrics = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RECIPIENTS_METRICS[SUCCESS]:
      return {
        ...state,
        ...action.response,
      };
    case CLEAR_ANALYTICS_DATA[REQUEST]: {
      return {};
    }
    default:
      return state;
  }
};

export default recipientsMetrics;
