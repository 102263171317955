import { find, forEach } from 'lodash';

const getChoiceAnswer = (forms, trackingId, inputName, index) => {
  const form = find(forms, (f) => f.trackingId === trackingId);
  const question = find(form.elements, (el) => el.inputName === inputName);
  return question.choices[index].label;
};

export const getAnswerText = (
  surveys,
  trackingId,
  form,
  forms,
) => {
  const answers = find(surveys, (survey) => survey.TrackingID === trackingId).Answers;
  let text = '-';
  if (answers && answers[0] && answers[0][form.inputName]) {
    const answer = answers[0][form.inputName];
    const type = form.inputName.split('-')[1];
    switch (type) {
      case 'openQuestion': {
        return answer;
      }
      case 'likeDislike': {
        return answer[0] === 1 ? 'Like' : 'Dislike';
      }
      case 'nps':
      case 'rating': {
        return answer[0];
      }
      case 'oneAnswer': {
        return getChoiceAnswer(forms, trackingId, form.inputName, answer[0]);
      }
      case 'multipleAnswers': {
        text = '';
        forEach(answer, (ans) => {
          text = `${text}\n${getChoiceAnswer(forms, trackingId, form.inputName, ans)}`;
        });
        return text.substr(1);
      }
      default:
        return '-';
    }
  }
  return '-';
};
