import React, { useContext, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';

// Context.
import { EditorContext } from '../../../../context/EditorContext';

// Constants.
import { toolbarConfig } from '../../../../constants/toolbarConfig';

// Styles.
import './OptInCheckboxController.scss';

const OptInCheckboxController = () => {
  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const {
    data: {
      checkboxInput,
    },
  } = activeElement;

  const {
    data: {
        checkboxInput: { content },
      },
  } = activeElement;

  const [editorState, setEditorState] = useState(
    content
      ? EditorState.createWithContent(convertFromRaw(content))
      : EditorState.createEmpty(),
  );

  const setElementVisibility = () => {
    const dataObj = { ...activeElement };
    const { data } = dataObj;

    data.checkboxInput.isVisible = !data.checkboxInput.isVisible;

    setActiveElement(dataObj);
  };

  const onEditorStateChange = (state) => {
    const dataObj = { ...activeElement };
    const contentState = state.getCurrentContent();
    const contentRawState = convertToRaw(contentState);
    dataObj.data.checkboxInput.content = contentRawState;

    setActiveElement(dataObj);
    setEditorState(state);
  };

  return (
    <div className="OptInCheckboxController">
      <div className="OptInCheckboxController__header">
        <p className="OptInCheckboxController__header-title">Checkbox</p>
        <Checkbox
          toggle
          checked={checkboxInput.isVisible}
          onClick={setElementVisibility}
        />
      </div>

      {checkboxInput.isVisible && (
        <div className="OptInCheckboxController__text-editor-wrapper">
          <Editor
            editorState={editorState}
            toolbar={toolbarConfig}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            placeholder="Type your text here"
            onEditorStateChange={onEditorStateChange}
            stripPastedStyles={true}
          />
        </div>
      )}
    </div>
  );
};

export default OptInCheckboxController;
