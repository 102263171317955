import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Bar, Line } from 'react-chartjs-2';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { map, forEach } from 'lodash';
import Donut from '../Chart/Donut';
import StatsBlock from '../Stats/StatsBlock';
import SurveyInfo from '../Stats/SurveyInfo';
import VideoInfo from './VideoInfo';
import ButtonInfo from './ButtonInfo';
import FileInfo from './FileInfo';
import ChartJsWrapper from '../Chart/ChartJsWrapper';

momentDurationFormatSetup(moment);

const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (tooltipItems) => map(tooltipItems, (tooltipItem) => `Opened in ${tooltipItem.xLabel}`),
      },
    },
  },
  scales: {
    yAxes: [{
      ticks: {
        stepSize: 1,
      },
    }],
  },
};

const Overview = ({
  forms,
  analyticsData,
  message,
}) => {
  const { leadTime, uniqueLinkClicks, avgLinkLeadTime } = analyticsData;

  const [videoTableIndex, setVideoTableIndex] = useState(false);
  const [buttonTableIndex, setButtonTableIndex] = useState(false);
  const [fileTableIndex, setFileTableIndex] = useState(false);
  const [formTableIndex, setFormTableIndex] = useState(false);
  const [openSurveys, setOpenSurveys] = useState([]);
  const [activeVideoIndex, setActiveVideoIndex] = useState([]);
  const [activeButtonIndex, setActiveButtonIndex] = useState([]);
  const [activeFileIndex, setActiveFileIndex] = useState([]);
  const [activeFormIndex, setActiveFormIndex] = useState([]);

  const generateActiveIndexes = (data, schema, tracking, prevActive) => {
    const activeIndex = prevActive;
    if (data && data.length && schema) {
      data.forEach((d, index) => {
        schema.forEach((sch) => {
          if (sch.trackingPolicy[tracking]
            && d.trackingId === sch.trackingId) {
            if (prevActive[index] === undefined) {
              activeIndex.push(true);
            }
          }
        });
      });
    }
    return activeIndex;
  };

  useEffect(() => {
    if (forms) {
      const surveysIds = openSurveys;
      map(forms, (form) => {
        surveysIds.push(form.id);
      });
      setOpenSurveys(surveysIds);
    }
  }, [forms]);

  useEffect(() => {
    if (analyticsData && message?.schema) {
      const activeForm = generateActiveIndexes(analyticsData.forms,
        message.schema.forms, 'submitTrackingEnabled', activeFormIndex);
      const activeVideo = generateActiveIndexes(analyticsData.videos,
        message.schema.videos, 'playbackTrackingEnabled', activeVideoIndex);
      const activeButton = generateActiveIndexes(analyticsData.buttons,
        message.schema.buttons, 'clickTrackingEnabled', activeButtonIndex);
      const activeFile = generateActiveIndexes(analyticsData.files,
        message.schema.attachments, 'downloadTrackingEnabled', activeFileIndex);
      setActiveFormIndex(activeForm);
      setActiveVideoIndex(activeVideo);
      setActiveButtonIndex(activeButton);
      setActiveFileIndex(activeFile);
    }
  }, [analyticsData, message?.schema]);

  const handleVideoClick = (activeID) => () => {
    activeVideoIndex[activeID] = !activeVideoIndex[activeID];
    setActiveVideoIndex([...activeVideoIndex]);
  };

  const handleFormClick = (activeID) => () => {
    activeFormIndex[activeID] = !activeFormIndex[activeID];
    setActiveFormIndex([...activeFormIndex]);
  };

  const handleButtonClick = (activeID) => () => {
    activeButtonIndex[activeID] = !activeButtonIndex[activeID];
    setActiveButtonIndex([...activeButtonIndex]);
  };

  const handleFileClick = (activeID) => () => {
    activeFileIndex[activeID] = !activeFileIndex[activeID];
    setActiveFileIndex([...activeFileIndex]);
  };

  const handleFormTableOpen = () => {
    setFormTableIndex(!formTableIndex);
  };

  const handleVideoTableOpen = () => {
    setVideoTableIndex(!videoTableIndex);
  };

  const handleButtonTableOpen = () => {
    setButtonTableIndex(!buttonTableIndex);
  };

  const handleFileTableOpen = () => {
    setFileTableIndex(!fileTableIndex);
  };

  const formatTimeToOpenLabels = () => {
    if (leadTime) {
      const leadTimeKeys = Object.keys(leadTime);

      return leadTimeKeys.map((value) => {
        if (moment.duration(parseInt(value, 10), 'seconds')
          .get('hours') !== 0) {
          return moment.duration(parseInt(value, 10), 'seconds')
            .format('hh:mm:ss');
        }
        if (moment.duration(parseInt(value, 10), 'seconds')
          .get('minutes') !== 0) {
          return `00:${moment.duration(parseInt(value, 10), 'seconds')
            .format('hh:mm:ss')}`;
        }
        return `00:00:${moment.duration(parseInt(value, 10), 'seconds')
          .format('hh:mm:ss')}`;
      });
    }

    return [];
  };

  const formatUniqueTimeValues = () => {
    if (uniqueLinkClicks) {
      const uniqueKeys = Object.keys(uniqueLinkClicks);

      return uniqueKeys.map((value) => {
        const timestamp = new Date(parseInt(value, 10) * 1000);
        const hours = timestamp.getHours();
        const minutes = timestamp.getMinutes();
        return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
      });
    }

    return [];
  };

  const formatAvgTime = () => {
    if (avgLinkLeadTime) {
      const time = Math.floor(avgLinkLeadTime);
      if (moment.duration(time, 'seconds').get('hours') !== 0) {
        return moment.duration(time, 'seconds').format('hh:mm:ss');
      }
      if (moment.duration(time, 'seconds').get('minutes') !== 0) {
        return `00:${moment.duration(time, 'seconds').format('hh:mm:ss')}`;
      }
      return `00:00:${moment.duration(time, 'seconds').format('hh:mm:ss')}`;
    }

    return '-';
  };

  const fixedTimeValues = formatTimeToOpenLabels();
  const uniqueTimeValues = formatUniqueTimeValues();

  return (
    <div>
      <div className="message-statistics">
        <div className="overall-statistics">
          <div className="overall-statistics__half-desktop">
            <div className="overall-statistics__box overall-statistics__box--giant">
              <div className="overall-statistics__box__header">
                Click-through rate
              </div>
              <div className="overall-statistics__box__content">
                {message?.schema?.trackingPolicy?.opensTrackingEnabled
                && analyticsData?.uniqueNumOfOpens !== 0 ? (
                  <Donut
                    label={[
                      'Unique Opens',
                      'Did not open',
                    ]}
                    percent={analyticsData.openRate * 100}
                    borderColor="#fff"
                    data={[
                      analyticsData.uniqueNumOfOpens,
                      (analyticsData.uniqueNumOfOpens / analyticsData.openRate)
                      - analyticsData.uniqueNumOfOpens,
                    ]}
                    backgroundColor={[
                      '#1cb59f',
                      '#e0e0e0',
                    ]}
                    not={analyticsData.numOfOpens - analyticsData.uniqueNumOfOpens}
                  />
                ) : (
                  <div className="overall-statistics__box__content--disabled">
                    No data yet
                  </div>
                )}
              </div>
            </div>
            <div className="overall-statistics__box--half-section">
              <div className="overall-statistics__box overall-statistics__box--half-full">
                <div className="overall-statistics__box__header">
                  Time to open
                </div>
                <div className="overall-statistics__box__content">
                  {analyticsData?.leadTime && fixedTimeValues && (
                    <ChartJsWrapper
                      options={options}
                      data={{
                        labels: fixedTimeValues,
                        datasets: [{
                          data: Object.values(analyticsData.leadTime),
                          backgroundColor: '#1CB59F',
                        }],
                      }}
                      type="bar"
                      component={Bar}
                    />
                  )}
                </div>
              </div>
              <div className="overall-statistics__box overall-statistics__box--half-full">
                <div className="overall-statistics__box__header">
                  Average time to open
                </div>
                <div className="overall-statistics__box__content">
                  <div className="smaller-statistics">
                    {formatAvgTime()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overall-statistics__half-desktop">
            <div className="overall-statistics__box--big-section">
              <div className="overall-statistics__box overall-statistics__box--big">
                <div className="overall-statistics__box__header">
                  Total opens
                </div>
                <div className="overall-statistics__box__content">
                  {analyticsData
                  && message?.schema?.trackingPolicy?.opensTrackingEnabled && (
                    <StatsBlock
                      stats={analyticsData.numOfOpens}
                      title=""
                      color="blue"
                    />
                  )}
                </div>
              </div>
              <div className="overall-statistics__box--small-section">
                <div className="overall-statistics__box overall-statistics__box--small">
                  <div className="overall-statistics__box__header">
                    Delivered
                  </div>
                  <div className="overall-statistics__box__content">
                    {analyticsData
                    && message?.schema?.trackingPolicy?.deliveryTrackingEnabled && (
                      <StatsBlock
                        stats={analyticsData.numOfDeliveries}
                        title=""
                        color="blue"
                      />
                    )}
                  </div>
                </div>
                <div className="overall-statistics__box overall-statistics__box--small">
                  <div className="overall-statistics__box__header">
                    Not delivered
                  </div>
                  <div className="overall-statistics__box__content">
                    {analyticsData
                    && message?.schema?.trackingPolicy?.bounceTrackingEnabled && (
                      <StatsBlock
                        stats={analyticsData.numOfBounces}
                        title=""
                        color="blue"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="overall-statistics__box overall-statistics__box--large">
              <div className="overall-statistics__box__header">
                Unique opens
              </div>
              <div className="overall-statistics__box__content">
                {analyticsData?.leadTime && fixedTimeValues && (
                  <ChartJsWrapper
                    options={options}
                    data={{
                      labels: uniqueTimeValues,
                      datasets: [{
                        data: Object.values(analyticsData.uniqueLinkClicks),
                        backgroundColor: '#E1F7F4',
                        borderColor: '#1CB59F',
                      }],
                    }}
                    type="line"
                    component={Line}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion-statistics">
        {message?.schema && analyticsData?.forms
        && analyticsData.forms.length !== 0
        && analyticsData.forms.map((form, index) => {
          const formsImage = [];
          forEach(message.schema.forms, (frm) => {
            if (frm.trackingPolicy.submitTrackingEnabled
              && form.trackingId === frm.trackingId) {
              formsImage.push(
                <SurveyInfo
                  key={index}
                  activeFormIndex={activeFormIndex}
                  onClick={() => handleFormClick(index)}
                  formTableIndex={formTableIndex}
                  onTableClick={handleFormTableOpen}
                  index={index}
                  data={form}
                  schema={frm}
                />,
              );
            }
          });
          return formsImage;
        })}
        {message?.schema && analyticsData?.videos
        && analyticsData.videos.length !== 0
        && analyticsData.videos.map((video, index) => {
          const videosImage = [];
          forEach(message.schema.videos, (vdo) => {
            if (vdo.trackingPolicy.playbackTrackingEnabled
              && video.trackingId === vdo.trackingId) {
              videosImage.push(
                <VideoInfo
                  key={index}
                  numOfPlaybacks={video.numOfPlaybacks}
                  numOfUniquePlaybacks={video.numOfUniquePlaybacks}
                  playbackRate={video.playbackRate}
                  activeIndex={activeVideoIndex}
                  onClick={() => handleVideoClick(index)}
                  videoTableIndex={videoTableIndex}
                  onTableClick={handleVideoTableOpen}
                  index={index}
                />,
              );
            }
          });
          return videosImage;
        })}
        {analyticsData?.buttons && message?.schema
        && analyticsData.buttons.length !== 0
        && analyticsData.buttons.map((button, index) => {
          const buttonsImage = [];
          forEach(message.schema.buttons, (btn) => {
            if (btn.trackingPolicy.clickTrackingEnabled
              && button.trackingId === btn.trackingId) {
              buttonsImage.push(
                <ButtonInfo
                  key={index}
                  activeButtonIndex={activeButtonIndex}
                  onClick={() => handleButtonClick(index)}
                  clickRate={button.clickRate}
                  numOfClicks={button.numOfClicks}
                  numOfUniqueClicks={button.numOfUniqueClicks}
                  buttonTableIndex={buttonTableIndex}
                  onTableClick={handleButtonTableOpen}
                  index={index}
                  message={message}
                  innerText={btn.innerText}
                />,
              );
            }
          });
          return buttonsImage;
        })}
        {analyticsData?.files && message?.schema
        && analyticsData.files.length !== 0
        && analyticsData.files.map((file, index) => {
          const filesImage = [];
          forEach(message.schema.attachments, (attach) => {
            if (attach.trackingPolicy.downloadTrackingEnabled
              && attach.trackingId === file.trackingId) {
              filesImage.push(
                <FileInfo
                  key={index}
                  activeFileIndex={activeFileIndex}
                  onClick={() => handleFileClick(index)}
                  downloadRate={file.downloadRate}
                  numOfDownloads={file.numOfDownloads}
                  numOfUniqueDownloads={file.numOfUniqueDownloads}
                  fileTableIndex={fileTableIndex}
                  onTableClick={handleFileTableOpen}
                  index={index}
                  message={message}
                  displayName={attach.displayName}
                />,
              );
            }
          });
          return filesImage;
        })}
      </div>
    </div>
  );
};

Overview.propTypes = {
  analyticsData: PropTypes.object,
  forms: PropTypes.object,
  message: PropTypes.object,
};

const
  mapStateToProps = (state, props) => ({
    user: state.user,
    users: state.users,
    message: state.messages[props.match.params.id],
    contactTableHeaders: state.entities.contactProperties,
    formMetrics: state.formMetrics,
    analyticsData: state.analyticsData,
    forms: state.forms,
  });

export default withRouter(
  connect(mapStateToProps)(Overview));
