import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { find, map } from 'lodash';
import { mapListsToOptions } from '../../../utils/map';
import { SUBJECT_TYPES } from '../../../common/automation';

const TagListSelect = ({
  options,
  value,
  handleChange,
  type,
  disabled,
  subject,
}) => {
  const [Component, placeholder] = useMemo(() => {
    if (subject === SUBJECT_TYPES.ADD_TAG || subject === SUBJECT_TYPES.ADD_TO_LIST) {
      return [Creatable, `Select or create new ${type}`];
    }
    return [Select, `Select ${type}`];
  }, [subject, type]);
  let valueId = value;
    if (typeof value === 'string') {
      const id = find(options, (option) => option.name === value)?.id;
      if (id) {
        valueId = id;
      } else {
        valueId = value;
      }
    }
  const updatedOptions = typeof valueId === 'string' && valueId !== ''
    ? [{ id: value, name: value }, ...map(options, (o) => o)]
    : options;
  return (
    <span className="subject-input-select">
      <Component
        className="subject-input-select__select"
        options={mapListsToOptions(updatedOptions)}
        placeholder={placeholder}
        value={valueId}
        onChange={handleChange(type)}
        disabled={disabled}
        clearable={false}
        simpleValue
        classNamePrefix="react-select"
      />
    </span>
  );
};

TagListSelect.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  handleChange: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  subject: PropTypes.string,
};

export default TagListSelect;
