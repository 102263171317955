const MessageFilterEmpty = () => (
  <div className="empty-messages">
    <div className="center-no-messages-message">
      <span as="h1" className="empty-messages__text">There are no messages matching your filter criteria</span>
      <br />
      <span as="h2" className="no-filter-messages">Select another criteria and try again</span>
    </div>
  </div>
);
export default MessageFilterEmpty;
