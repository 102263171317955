/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';

// Styles.
import './WidgetTextUI.scss';

const WidgetTextUI = ({ content }) => {
  const textMarkup = draftToHtml(content);

  return (
    <div className="WidgetTextUI" dangerouslySetInnerHTML={{ __html: textMarkup }} />
  );
};

WidgetTextUI.propTypes = {
  content: PropTypes.shape({
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        inlineStyleRanges: PropTypes.arrayOf(
          PropTypes.shape({}),
        ),
        text: PropTypes.string,
      }),
    ).isRequired,
    entityMap: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default WidgetTextUI;
