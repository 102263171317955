import { call, takeLatest } from 'redux-saga/effects';
import { requestSaga } from './genericSagas';
import { analyticsActions, request } from '../actions';

import { analyticsDataGet } from '../services/eventsApi';

const { REQUEST } = request;

const {
  fetchAnalyticsData,
  FETCH_ANALYTICS_DATA,
} = analyticsActions;

function* getAnalyticsData(args) {
  yield call(requestSaga, fetchAnalyticsData, analyticsDataGet, args);
}

export default function* watchforms() {
  yield takeLatest(FETCH_ANALYTICS_DATA[REQUEST], getAnalyticsData);
}
