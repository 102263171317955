import { Grid } from 'semantic-ui-react';
import { withRouter } from 'react-router';

import { HeaderNavigationAdmin, AccountNavigationDesktop } from '../../components/Header';

const HeaderAdmin = () => (
  <Grid columns={4} className="app-header app-header__main">
    <Grid.Column width={2} className="site-logo navigation-header-logo" />
    <Grid.Column width={7} className="navigation-header-div">
      <HeaderNavigationAdmin />
    </Grid.Column>
    <Grid.Column width={3} only="computer">
      <AccountNavigationDesktop />
    </Grid.Column>
  </Grid>
);

export default withRouter(HeaderAdmin);
