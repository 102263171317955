import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { BillingForm } from '../../../containers/Form';
import SubmitCard from '../../../containers/Widgets/CreditCard';

const Billing = ({ addedCard, billingInfo, modal }) => (
  <Grid className={modal ? 'billing-modal' : ''}>
    {modal ? (
      <div>
        <Grid.Row className="stackable account-settings-tab">
          <div className="account-settings">
            <div className="account-settings__subheader">
              <span>Update Billing</span>
            </div>
            <BillingForm
              billingInfo={billingInfo}
            />
          </div>
        </Grid.Row>
        <Grid.Row className="stackable account-settings-tab">
          <div className="account-settings">
            <div className="account-settings__subheader">
              <span>{`${addedCard ? 'Add' : 'Change'} Credit Card`}</span>
            </div>
            <SubmitCard addedCard={addedCard} />
          </div>
        </Grid.Row>
      </div>
    ) : (
      <Grid.Row columns={2} className="stackable account-settings-tab">
        <Grid.Column>
          <div className="account-settings">
            <div className="account-settings__subheader">
              <span>Update Billing</span>
            </div>
            <BillingForm
              billingInfo={billingInfo}
            />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="account-settings">
            <div className="account-settings__subheader">
              <span>{`${addedCard ? 'Add' : 'Change'} Credit Card`}</span>
            </div>
            <SubmitCard addedCard={addedCard} />
          </div>
        </Grid.Column>
      </Grid.Row>
    )}
  </Grid>
);

Billing.propTypes = {
  addedCard: PropTypes.func,
  billingInfo: PropTypes.func,
  modal: PropTypes.bool,
};

export default Billing;
