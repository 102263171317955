import { omit } from 'lodash';
import {
  SET_PLANS,
  FETCH_SESSION,
  FETCH_COUNTRIES,
} from '../actions/planActions';

const initialState = {
  plans: {},
  session: {},
  countries: {},
};

const plans = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLANS:
      return {
        ...state,
        plans: omit(action, ['type']),
      };
    case FETCH_SESSION.SUCCESS: {
      return {
        ...state,
          session: omit(action, ['type', 'args', 'response']),
      };
    }
    case FETCH_COUNTRIES.SUCCESS: {
      return {
        ...state,
        countries: omit(action, ['type', 'args', 'response']),
      };
    }
    default:
      return state;
  }
};

export default plans;
