import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectStripe } from 'react-stripe-elements';
import { paymentAuthorizationGet } from '../../services/accountsApi';
import { notifyError, notifySuccess } from '../../actions/notificationActions';

const fiveMinInMs = 300000;

const RenewPlan = ({ stripe, notifySuccessA, notifyErrorA }) => {
  const handleRenew = () => {
    paymentAuthorizationGet().then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
      } else {
        const { response: { paymentIntentSecret } } = res;
        stripe.handleCardPayment(paymentIntentSecret).then((result) => {
          if (result.error) {
            if (result.error.type === 'api_connection_error' && result.error.type === 'api_error') {
              setTimeout(handleRenew, 5000);
            } else {
              notifyErrorA(result.error.message);
            }
          } else {
            notifySuccessA('Plan subscription renewed successfully');
            localStorage.setItem('subscription', `${new Date()}`);
          }
        });
      }
    });
  };

  const checkLocalStorage = () => {
    const storage = localStorage.getItem('subscription');
    let renew = false;
    if (storage) {
      const subscription = new Date(storage).getTime();
      const now = new Date().getTime();

      if (subscription + fiveMinInMs < now) {
        renew = true;
      }
    } else {
      renew = true;
    }

    if (renew) {
      handleRenew();
    }
  };

  useEffect(() => {
    checkLocalStorage();
  }, []);

  return null;
};

RenewPlan.propTypes = {
  stripe: PropTypes.shape({
    handleCardPayment: PropTypes.func,
  }),
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default injectStripe(connect(null, mapDispatchToProps)(RenewPlan));
