// TODO needs a clean-up refactor for unused code
// Whole code was copy-pasted from VerifyIdentityModal.js

// React
import { createElement, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'semantic-ui-react';

// Hooks
import { email } from '../../utils/validator';
import { identityValidate } from '../../services/accountsApi';
import { notificationActions } from '../../actions';

// Components
import { VerifyEmail } from '../../components/AccountSettings/Domain/VerifyDomain';
import { BasicButton } from '../../components/Button';
import { Spinner } from '../../components/Spinner';

const { notifySuccess, notifyError } = notificationActions;

const VerifyIdentityEmailModal = ({
  type,
  identity: propIdentity = '',
  notifyErrorA,
  notifySuccessA,
  open,
  closeModalPortal,
  identities,
}) => {
  const [identity, setIdentity] = useState(propIdentity);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleSend = () => {
    setLoading(true);

    const payload = {
      type,
      body: {
        identity,
      },
    };

    identityValidate(payload).then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
        setLoading(false);
      } else {
        const { response } = res;
        const { identityType } = response;
        if (identityType === 'email') {
          notifySuccessA('Verification email sent! Check your email and click the link to verify your email address!');
          closeModalPortal();
        }
      }
    });
  };

  const changeIdentity = (input) => {
    const errorMessage = email(input);
    setIdentity(input);
    setError(errorMessage);
  };

  const componentProps = useMemo(() => ({
    changeIdentity,
    error,
    value: identity,
  }), [
    error,
    identity,
  ]);

  return (
    <Modal
      open={open}
      closeOnDocumentClick={false}
      closeOnDimmerClick={false}
      onClose={closeModalPortal}
      dimmer="inverted"
      className="verify-identity-modal"
    >
      <Modal.Header>Verify Email Address</Modal.Header>
      <Spinner loaded={!!identities} modal={true}>
        <div className="verify-identity-modal__content">
          {createElement(VerifyEmail, componentProps)}
        </div>
      </Spinner>
      <Modal.Actions>
        <BasicButton
          content="Cancel"
          className="white"
          type="button"
          onClick={closeModalPortal}
        />
        <BasicButton
          disabled={(!!error || !identity || loading)}
          loading={loading}
          content="Verify"
          className="segmentation-button"
          type="button"
          onClick={handleSend}
        />
      </Modal.Actions>
    </Modal>
  );
};

VerifyIdentityEmailModal.propTypes = {
  open: PropTypes.bool,
  closeModalPortal: PropTypes.func,
  type: PropTypes.string,
  identities: PropTypes.array,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  identity: PropTypes.string,
};

const mapStateToProps = (state) => ({
  identities: state.identities.elements,
});

const mapDispatchToProps = (dispatch) => ({
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyIdentityEmailModal);
