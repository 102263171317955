import {
  memo,
  useContext,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createMessageAutomation } from '../../../actions/messageActions';
import { AutomationContext } from '../../../context/AutomationContext';
import { openModalPortal, closeModalPortal } from '../../../actions/genericActions';
import { MessageDuplicateModal } from '../../../containers/Modal';

const AutomationMessageNew = ({
  messageEditor,
  setCurrentStep,
  createMessageAutomationA,
  openModalPortalA,
  closeModalPortalA,
}) => {
  const {
    selectedMessageId,
    setSelectedMessageId,
    addMessageToList,
  } = useContext(AutomationContext);
  const messageName = useRef(messageEditor.name);
  const messageId = useRef('');

  useEffect(() => {
    if (messageEditor.id && messageEditor.name) {
      if (messageName.current === '') {
        addMessageToList(messageEditor.id);
        setSelectedMessageId(messageEditor.id);
        messageId.current = messageEditor.id;
      }
      messageName.current = messageEditor.name;
    }
  }, [messageEditor.id, messageEditor.name]);

  useEffect(() => {
    if (messageId.current && messageId.current === selectedMessageId) {
      setCurrentStep('design');
    }
  }, [selectedMessageId]);

  const handleCreateMessage = ({ name }) => {
    messageName.current = '';
    messageId.current = '';
    createMessageAutomationA({
      name: !name || name === 'Untitled' ? '' : name,
      deliveryMethod: 'sms',
      status: 'draft',
      origin: 'automation',
    });
    closeModalPortalA();
  };

  const handleOpenMessageNameModal = () => {
    openModalPortalA({
      content: <MessageDuplicateModal />,
      contentProps: {
        handleDuplicateModalState: closeModalPortalA,
        onFormSubmit: handleCreateMessage,
        initialValues: { name: 'Untitled' },
        title: 'Message has been successfully created.',
        description: 'Enter the message name to continue',
        label: 'Enter the name for a new message',
        buttonText: 'Create',
        isAutomation: true,
      },
    });
  };

  return (
    <div
      className="automation-sidebar__messages--new"
      onClick={handleOpenMessageNameModal}
    >
      <span />
      New message
    </div>
  );
};

const mapStateToProps = (state) => ({
  messageEditor: state.messageEditor,
});

const mapDispatchToProps = (dispatch) => ({
  createMessageAutomationA: bindActionCreators(createMessageAutomation.request, dispatch),
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
});

AutomationMessageNew.propTypes = {
  messageEditor: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  setCurrentStep: PropTypes.func,
  createMessageAutomationA: PropTypes.func,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(AutomationMessageNew));
