import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { get } from 'lodash';
import {
  requestSaga,
  showGlobalError,
  showSuccessMessage,
  clearPaginatorSaga,
} from './genericSagas';
import { contactActions } from '../actions';
import { api } from '../services';
import { getCurrentPageNumber } from '../modules/redux-paginator';

// actions
const {
  fetchContactProperties,
  fetchTags,
  requestModifiedContactViewPage,
  preprocessContactList,
  importContactList,
  createContact,
  updateContact,
  mutateContactTag,
  deleteContact,
  createContactFilter,
  updateContactFilter,
  fetchLists,
  fetchViews,
  FETCH_CONTACT_PROPERTIES,
  FETCH_TAGS,
  FETCH_MODIFIED_CONTACTS,
  PREPROCESS_CONTACTS_LIST,
  MUTATE_CONTACT_TAG,
  DELETE_CONTACT,
  CLEAR_CONTACT_FILTER,
  FETCH_LISTS,
  FETCH_VIEWS,
} = contactActions;

// services
const {
  contact: {
    contactProperties,
    contactListPreprocessing,
    contactListImport,
    contactCreation,
    contactEdit,
    contactListMutation,
    contactDeletion,
    contactFilterCreation,
    contactFilterUpdate,
    listsFetch,
    conatctViewsFetch,
  },
  tags: {
    tagsFetchAll,
  },
} = api;

const getContactPaginator = (state) => state.contactPaginator;
const getContactPageSize = (state) => state.contactFilter.pageSize;

function* fetchModifiedContactViewPageSaga(args) {
  const {
    filterId,
    list,
    propertyId,
    orderBy,
  } = args;

  // Getting redux state values
  const contactPaginator = yield select(getContactPaginator);
  const pageSize = yield select(getContactPageSize);

  // Getting current page number
  const currentPageNo = yield call(getCurrentPageNumber, contactPaginator, 'contact');

  // Clearing contacts paginator and feetching contacts
  yield call(clearPaginatorSaga, 'contactPaginator');
  yield put(requestModifiedContactViewPage(currentPageNo, filterId || '', pageSize, list, propertyId, orderBy));
}

function* fetchModifiedContactsSaga(args) {
  const {
    list,
    filterId,
    propertyId,
    orderBy,
  } = args;
  yield call(fetchModifiedContactViewPageSaga, {
    list,
    filterId,
    propertyId,
    orderBy,
  });
}

function* fetchListsSaga() {
  yield call(requestSaga, fetchLists, listsFetch);
}

function* fetchContactPropertiesSaga() {
  yield call(requestSaga, fetchContactProperties, contactProperties);
}

function* contactTagMutationSaga(args) {
  let list = get(args, 'payload.listId', 'all');
  const filterId = get(args, 'payload.filterId', '');
  const propertyId = get(args, 'payload.propertyId', undefined);
  const orderBy = get(args, 'payload.orderBy', undefined);
  list = list === 0 ? 'all' : list;
  yield call(requestSaga, mutateContactTag, contactListMutation, args, undefined,
    {
      list,
      filterId,
      propertyId,
      orderBy,
    });
}

function* contactTagMutationSuccessSaga(args) {
  const {
    payload,
  } = args;
  yield call(fetchModifiedContactViewPageSaga, payload);
  yield call(showSuccessMessage, 'Contacts deleted successfully');
  yield call(fetchListsSaga);
}

function* contactDeletionSaga(args) {
  const list = get(args, 'payload.list', 'all');
  const filterId = get(args, 'payload.filterId', '');
  const propertyId = get(args, 'payload.propertyId', undefined);
  const orderBy = get(args, 'payload.orderBy', undefined);
  yield call(requestSaga, deleteContact, contactDeletion, args, undefined, {
    list,
    filterId,
    propertyId,
    orderBy,
  });
}

function* contactDeletionSuccessSaga(args) {
  const { payload } = args;
  yield call(fetchModifiedContactViewPageSaga, payload);
  yield call(showSuccessMessage, 'Contact deleted successfully');
  yield call(fetchListsSaga);
}

function* createNewContactSaga(args) {
  const filterId = get(args, 'payload.filterId', '');
  const list = get(args, 'payload.list', 'all');
  const propertyId = get(args, 'payload.propertyId', undefined);
  const orderBy = get(args, 'payload.orderBy', undefined);
  yield call(requestSaga, undefined, contactCreation, args, createContact, {
    list,
    filterId,
    propertyId,
    orderBy,
  });
}

function* createNewContactSuccessSaga(args) {
  const { payload: { extraParam } } = args;
  yield call(fetchModifiedContactViewPageSaga, extraParam);
  yield call(showSuccessMessage, 'Contact created successfully');
}

function* updateExistingContactSaga(args) {
  const list = get(args, 'payload.list', 'all');
  const filterId = get(args, 'payload.filterId', '');
  const propertyId = get(args, 'payload.propertyId', undefined);
  const orderBy = get(args, 'payload.orderBy', undefined);
  yield call(requestSaga, undefined, contactEdit, args, updateContact, {
    list,
    filterId,
    propertyId,
    orderBy,
  });
}

function* updateExistingContactSuccessSaga(args) {
  const { payload: { extraParam } } = args;
  yield call(fetchModifiedContactViewPageSaga, extraParam);
  yield call(showSuccessMessage, 'Contact updated successfully');
}

function* preprocessContactsListSaga(args) {
  yield call(requestSaga, preprocessContactList, contactListPreprocessing, args);
}

function* importContactListSaga(args) {
  const list = get(args, 'payload.list', 'all');
  const filterId = get(args, 'payload.filterId', '');
  const propertyId = get(args, 'payload.propertyId', undefined);
  const orderBy = get(args, 'payload.orderBy', undefined);
  yield call(requestSaga, undefined, contactListImport, args, { ...importContactList },
    {
      list,
      filterId,
      propertyId,
      orderBy,
    });
}

function* importContactListSuccessSaga(args) {
  const status = get(args, 'payload.status', undefined);
  if (status === 'DONE') {
    yield call(showSuccessMessage, 'File was successfully uploaded');
    const { payload: { extraParam } } = args;
    yield call(fetchModifiedContactViewPageSaga, extraParam);
  } else {
    yield call(showSuccessMessage, 'File will soon be uploaded');
  }
}

function* contactFilterCreateSaga(args) {
  const list = get(args, 'payload.list', 'all');
  yield call(requestSaga, undefined, contactFilterCreation, args, { ...createContactFilter }, list);
}

function* contactFilterUpdateSaga(args) {
  const list = get(args, 'payload.list', 'all');
  yield call(requestSaga, undefined, contactFilterUpdate, args, { ...updateContactFilter }, list);
}

function* requestFilteredContactsSaga(args) {
  const filter = get(args, 'payload.id', undefined);
  const list = get(args, 'payload.extraParam', 'all');

  yield put(requestModifiedContactViewPage(1, filter, undefined, list));
}

function* tagsFetchSaga() {
  yield call(requestSaga, fetchTags, tagsFetchAll);
}

function* clearFilterSaga(args) {
  const pageSize = get(args, 'pageSize', undefined);
  const list = get(args, 'list', undefined);
  yield put(requestModifiedContactViewPage(1, undefined, pageSize, list));
}

function* fetchViewsSaga() {
  yield call(requestSaga, fetchViews, conatctViewsFetch);
}

export default function* watchContacts() {
  yield takeLatest(FETCH_CONTACT_PROPERTIES.REQUEST, fetchContactPropertiesSaga);
  yield takeLatest(FETCH_CONTACT_PROPERTIES.FAILURE, showGlobalError);

  yield takeLatest(MUTATE_CONTACT_TAG.REQUEST, contactTagMutationSaga);
  yield takeLatest(MUTATE_CONTACT_TAG.SUCCESS, contactTagMutationSuccessSaga);
  yield takeLatest(MUTATE_CONTACT_TAG.FAILURE, showGlobalError);

  yield takeLatest(FETCH_MODIFIED_CONTACTS.REQUEST, fetchModifiedContactsSaga);

  yield takeLatest(DELETE_CONTACT.REQUEST, contactDeletionSaga);
  yield takeLatest(DELETE_CONTACT.SUCCESS, contactDeletionSuccessSaga);
  yield takeLatest(DELETE_CONTACT.FAILURE, showGlobalError);

  yield takeLatest(createContact.REQUEST, createNewContactSaga);
  yield takeLatest(createContact.SUCCESS, createNewContactSuccessSaga);
  yield takeLatest(createContact.FAILURE, showGlobalError);

  yield takeLatest(updateContact.REQUEST, updateExistingContactSaga);
  yield takeLatest(updateContact.SUCCESS, updateExistingContactSuccessSaga);
  yield takeLatest(updateContact.FAILURE, showGlobalError);

  yield takeLatest(PREPROCESS_CONTACTS_LIST.REQUEST, preprocessContactsListSaga);

  yield takeLatest(importContactList.REQUEST, importContactListSaga);
  yield takeLatest(importContactList.SUCCESS, importContactListSuccessSaga);

  yield takeLatest(createContactFilter.REQUEST, contactFilterCreateSaga);
  yield takeLatest(createContactFilter.SUCCESS, requestFilteredContactsSaga);
  yield takeLatest(createContactFilter.FAILURE, showGlobalError);

  yield takeLatest(updateContactFilter.REQUEST, contactFilterUpdateSaga);
  yield takeLatest(updateContactFilter.SUCCESS, requestFilteredContactsSaga);
  yield takeLatest(updateContactFilter.FAILURE, showGlobalError);

  yield takeLatest(FETCH_TAGS.REQUEST, tagsFetchSaga);
  yield takeLatest(FETCH_TAGS.FAILURE, showGlobalError);

  yield takeLatest(CLEAR_CONTACT_FILTER, clearFilterSaga);

  yield takeLatest(FETCH_LISTS.REQUEST, fetchListsSaga);

  yield takeLatest(FETCH_VIEWS.REQUEST, fetchViewsSaga);
}
