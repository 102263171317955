import PropTypes from 'prop-types';

const InitialNode = ({ addNode }) => (
  <div className="initial-node">
    <div
      className="initial-node__container"
      onClick={() => addNode(true, 'filter')}
    >
      How does this automation trigger?
    </div>
  </div>
);

InitialNode.propTypes = {
  addNode: PropTypes.func,
};

export default InitialNode;
