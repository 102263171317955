import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Input, Popup } from 'semantic-ui-react';

const ColumnItem = ({
  editing,
  index,
  handleColumnEdit,
  handleUpdateProperty,
  column: {
    label,
    id,
  },
  handleColumnDelete,
  handleColumnSelect,
  checked,
  disabled,
  custom,
}) => {
  const [name, setName] = useState(label);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (editing && editing !== index) {
      setName(label);
      setEdit(false);
    }
    if (!editing && name !== label) {
      setName(label);
    }
  }, [editing, index, label]);

  const handleEdit = (updatedEdit) => {
    setEdit(updatedEdit);
    const editIndex = updatedEdit ? index : undefined;
    handleColumnEdit(editIndex);
    if (!updatedEdit) {
      setName(label);
    }
  };

  const handleFirstAction = () => {
    if (edit) {
      handleUpdateProperty(name, index);
      handleEdit(false);
    } else {
      handleEdit(true);
    }
  };

 const handleSecondAction = () => {
    if (edit) {
      handleEdit(false);
    } else {
      handleColumnDelete(id, label, index);
    }
  };

  const handleNameChange = ({ value }) => setName(value);

  const renderItemAction = () => {
    if (edit) {
      if (name.length > 32) {
        return (
          <Popup
            className="error-popup"
            trigger={<i className="material-icons input-error-icon">error</i>}
            position="top right"
            content="Length must be equal or less than 32 characters"
          />
        );
      }
      return (
        <i
          className="material-icons"
          onClick={handleFirstAction}
        >
          done
        </i>
      );
    }
    return (
      <i
        className="material-icons"
        onClick={handleFirstAction}
      >
        mode_edit
      </i>
    );
  };

  return (
    <div className="column-order__selector__container__item">
      <div className="column-order__selector__container__item__select">
        <Checkbox
          checked={checked}
          onChange={() => handleColumnSelect(index)}
          disabled={disabled}
        />
        {edit ? (
          <div>
            <Input
              value={name}
              onChange={(event, data) => handleNameChange(data)}
              className="column-order__selector__container__item__select__input"
              autofocus
            />
          </div>
        ) : (
          <span
            className={`column-order__selector__container__item__select__label${disabled
              ? ' column-order__selector__container__item__select__label--disabled' : ''}`}
            onClick={() => {
              if (!disabled) {
                handleColumnSelect(index);
              }
            }}
          >
            {label}
          </span>
        )}
      </div>
      {custom && (
        <div className="column-order__selector__container__item__actions">
          {renderItemAction()}
          <i
            className="material-icons"
            onClick={() => handleSecondAction()}
          >
            {edit ? 'clear' : 'delete'}
          </i>
        </div>
      )}
    </div>
  );
};

ColumnItem.propTypes = {
  handleColumnSelect: PropTypes.func,
  column: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.number,
  }),
  checked: PropTypes.bool,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  custom: PropTypes.bool,
  handleColumnDelete: PropTypes.func,
  editing: PropTypes.number,
  handleColumnEdit: PropTypes.func,
  handleUpdateProperty: PropTypes.func,
};

export default ColumnItem;
