import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './OpenQuestionAnswerUI.scss';

const OpenQuestionAnswerUI = ({ answerExample, index, answerRequired }) => (
  <div className="OpenQuestionAnswerUI" id={`${index}-openQuestion-question`} weight={index}>
    <input
      className="OpenQuestionAnswerUI__open-question-input"
      id={`${index}-openQuestion-answer`}
      type="text"
      name={`${index}-openQuestion-answer`}
      placeholder={answerExample}
      required={answerRequired}
    />
  </div>
  );

OpenQuestionAnswerUI.propTypes = {
  answerRequired: PropTypes.bool.isRequired,
  answerExample: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default OpenQuestionAnswerUI;
