import { get } from 'lodash';

const credentials = JSON.parse(get(localStorage, 'credentials', '{}'));

export const API_ROOT = 'https://api.rispons.com/';
export const EDGE_ROOT = 'https://edge.rispons.com/';
export const S3_BUCKET = 'risbuck-test';
export const ENV = process.env;
const { API_ENV } = ENV;

export const URL = `https://app${API_ENV === 'dev' ? '-test' : ''}.rispons.com/`;
export const S3_BUCKET_URL = 'https://s3-eu-west-1.amazonaws.com/risbuck-test/';
export const AWS_REGION = 'eu-west-1';
export const ACCESS_KEY = get(credentials, 'AccessKeyId', '');
export const SECRET_ACCESS_KEY = get(credentials, 'SecretAccessKey', '');
export const TOKEN = get(credentials, 'SessionToken', '');
export const DEFAULT_VIDEO = '';
export const VIDEO_JS_CSS_SRC = 'https://cdnjs.cloudflare.com/ajax/libs/video.js/6.7.3/video-js.css';
export const VIDEO_ANALYTICS_SRC = `${URL}scripts/video.js`;
export const MESSAGE_FORM_SUBMIT_SRC = `${URL}scripts/formSubmit.js`;
export const MESSAGE_OPTIN_SUBMIT_SRC = `${URL}scripts/optInFeedback.js`;
export const BUTTON_LINK_ATTACHMENT_SRC = `${URL}scripts/buttonLinkAttachment.js`;
export const COLOR_CHANGE_SRC = `${URL}scripts/colorchange.js`;
export const RATING_SRC = `${URL}scripts/rating.js`;
export const EMPTY_IMG_SRC = `${URL}scripts/empty.png`;
export const JQUERY_SRC = 'https://ajax.googleapis.com/ajax/libs/jquery/2.1.3/jquery.min.js';
export const VIDEO_JS_SRC = 'https://cdnjs.cloudflare.com/ajax/libs/video.js/6.7.3/video.js';
export const VIDEO_JS_YOUTUBE_SRC = 'https://cdnjs.cloudflare.com/ajax/libs/videojs-youtube/2.5.0/Youtube.js';
export const MSG_LAYOUT_CSS = `${URL}scripts/msg.css`;
export const SURVEY_VALIDATION_SRC = `${URL}scripts/validation.js`;
export const HOVER_SRC = `${URL}scripts/hover.js`;
export const FONTS_SRC = `${URL}fonts/MyFontsWebfontsKit.css`;
export const JQUERY_TOUCH = `${URL}scripts/jquery.ui.touch-punch.min.js`;
export const MESSAGE_LOAD = `${URL}scripts/messageLoad.js`;
export const RISPONS_ICON = `${URL}scripts/favicon1.png`;
export const SURVEY_FEEDBACK_SRC = `${URL}scripts/surveyFeedback.js`;

export const buildPathTemplate = (service, endpoint, pathTemplate) => {
  if (pathTemplate) {
    return pathTemplate;
  }
  return service.concat('/', API_ENV, endpoint);
};
