import { callApi } from './callApi';
import { getUrlProperty } from '../utils/helper';
import { call as callPlans } from './planApi';

export const call = (endpoint, params, _schema) => callApi({ serviceName: 'account', endpoint }, params, _schema);

export const callAccount = (endpoint, params, _schema) => callApi({ serviceName: 'auth', endpoint }, params, _schema);

export const accountCreate = (body) => {
  const { token } = body;
  let endpoint = '/register';
  endpoint = token ? endpoint.concat('/', token) : endpoint;
  return (
    call(endpoint, {
      method: 'POST',
      body: body.values,
    }, null)
  );
};

export const creditCount = (messageID, text) => {
  const endpoint = `/message/${messageID}/price`;
  const params = {
    method: 'POST',
    body: { messageText: text },
  };
  return callApi({ serviceName: 'message', endpoint }, params, null);
};

export const fetchAccount = (body) => call('/account', {
  method: 'GET',
  body,
}, null);

export const fetchBilling = () => callPlans('/customer/address', {
  method: 'GET',
}, null);

export const accountUpdate = (body) => call('/account', {
  method: 'PUT',
  body,
}, null);

export const billingUpdate = (body) => callPlans('/customer/address', {
  method: 'PUT',
  body,
}, null);

export const userUpdate = (body) => call('/user', {
  method: 'PUT',
  body,
}, null);

// body: { "value": 10, "period": 12 }
export const accountAddSms = (id, plan) => call(`/account/${id}/sms`, {
  method: 'PUT',
  body: { value: parseInt(plan.customSMSPackage, 10) },
}, null);

export const extendTrial = (accountID) => call(`/account/${accountID}/extend`, {
  method: 'GET',
}, null);

// body: { "value": 10, "period": 12 }
export const accountGiveDiscount = (id, plan) => call(`/account/${id}/discount`, {
  method: 'PUT',
  body: {
    value: parseInt(plan.discount, 10),
    period: parseInt(plan.period, 10),
  },
}, null);

export const postStripeCreditCard = (body) => callPlans('/creditCard', {
  method: 'POST',
  body,
}, null);

export const accountAddComment = (accountID, comment) => call(`/account/${accountID}/comment`, {
  method: 'POST',
  body: { comment },
}, null);

export const getStripeCreditCards = (body) => callPlans('/creditCard', {
  method: 'GET',
  body,
}, null);

export const accountGet = () => call('/account/user', {
  method: 'GET',
}, null);

export const accountGetById = (id) => call(`/account/${id}`, {
  method: 'GET',
}, null);


export const usersGet = (url) => {
  let pathTemplate = getUrlProperty('pathname', url);
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);

  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};

export const accountsGet = (url) => {
  let pathTemplate = getUrlProperty('pathname', url);
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);

  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};

export const paymentHistory = (url) => {
  let pathTemplate = getUrlProperty('pathname', url);
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);

  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};

export const accountDelete = (body) => callAccount('/delete', {
  method: 'POST',
  body,
}, null);

export const accountBlock = (body) => callAccount('/block', {
  method: 'POST',
  body,
}, null);

export const accountAccept = (token) => call(`/accept/${token}`, {
  method: 'GET',
}, null);

export const userInvite = (body) => call('/invite', {
  method: 'POST',
  body,
}, null);

export const buyCredits = (body) => call('/purchase', {
  method: 'POST',
  body,
}, null);

export const identityValidate = ({ type, body }) => call(`/identity/${type}/verify`, {
  method: 'POST',
  body,
}, null);

export const defaultIdentitySet = (id) => call(`/identity/${id}/default`, {
  method: 'GET',
}, null);

export const identityDelete = (id) => call(`/identity/${id}`, {
  method: 'DELETE',
}, null);

export const identityUpdate = ({ id, body }) => call(`/identity/${id}`, {
  method: 'PUT',
  body,
}, null);

export const identitiesGet = ({
  type = 'domain',
  page = 1,
  pageCount = 10,
}) => call(`/identity/${type}`, {
  method: 'GET',
  additionalParams: {
    queryParams: {
      page,
      pageCount,
    },
  },
}, null);

export const identityMetadataGet = (id) => call(`/identity/${id}/metadata`, {
  method: 'GET',
}, null);

export const clientSecretGet = () => callPlans('/intent', {
  method: 'GET',
}, null);

export const paymentAuthorizationGet = () => callPlans('/payment/auth', {
  method: 'GET',
}, null);
