import { useState } from 'react';
import { bindActionCreators } from 'redux';
import { Message, Label } from 'semantic-ui-react';
import Creatable from 'react-select/creatable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormSyncErrors, reduxForm } from 'redux-form';
import { get, isEmpty } from 'lodash';
import { notifyError, notifySuccess } from '../../actions/notificationActions';
import RemoveTagsForm from './RemoveTagsForm';
import { contactTagCreate, contactTagAdd } from '../../services/tagApi';
import { required } from '../../utils/validator';
import {
  requestContactsPage,
  fetchModifiedContactsPage,
  fetchTags,
} from '../../actions/contactActions';
import { BasicButton } from '../../components/Button';
import { mapListsToOptions } from '../../utils/map';

const ContactTagForm = ({
  selected,
  excluded,
  list,
  contactFilter,
  notifySuccessA,
  closeModalPortal,
  requestModifiedContactViewPageA,
  fetchTagsA,
  handleUnselect,
  propertyId,
  orderBy,
  type,
  formErrors,
  handleSubmit,
  contacts,
  currentPageResults,
  tags,
  filterId,
}) => {
  const [values, setValues] = useState([]);

  const linkTagToContact = (tagId, name) => {
    const newFilterId = get(contactFilter, 'id', undefined);

    return contactTagAdd(tagId,
      {
        ids: typeof selected === 'string' ? [] : selected,
        filterId: newFilterId,
        listId: typeof list === 'number' ? list : 0,
        excluded,
      },
    ).catch((err) => {
      notifyError(err);
    });
  };

  const handleOnChange = (newValues) => {
    setValues(newValues);
  };

  const isTagsLengthValid = (currentValues) => {
    let isValid = true;

    currentValues.forEach(({ value }) => {
      if (value.length > 40) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleTagSubmit = () => {
    const newFilterId = get(contactFilter, 'id', undefined);

    if (values.length > 0) {
      closeModalPortal();

      const tagNames = [];

      const requests = values.map((tag) => {
        const { value, label } = tag;
        if (typeof value === 'number') {
          linkTagToContact(value, label).then(() => requestModifiedContactViewPageA({
            list,
            filterId: newFilterId,
            propertyId,
            orderBy,
          }));
        } else {
          contactTagCreate({
            name: label,
          }).then(({ response }) => {
            linkTagToContact(response.id, label).then(() => {
              fetchTagsA();
              requestModifiedContactViewPageA({
                list,
                filterId: newFilterId,
                propertyId,
                orderBy,
              });
            });
          }).catch((err) => {
            notifyError(err);
          });
        }
        tagNames.push(label);

        return label;
      });

      return Promise.all(requests).then(() => {
        handleUnselect();
        notifySuccessA(`Contacts have been tagged ${tagNames.join(', ')}`);
      });
    }
    return values;
  };

  const isTagsValid = isTagsLengthValid(values);

  return (
    <form onSubmit={handleSubmit(handleTagSubmit)}>
      {(selected.length < 1)
      && (
      <Message
        error
        header="No Contacts Selected"
        content="First select the contacts you want to add a tag to. Please go back and select the contacts you want to tag"
      />
      )}
      <div className="tag">
        {type === 'add' ? (
          <div>
            <div className="modal--tag__content">
              <Creatable
                name="tagsInput"
                data-tip="Do not forget to press ↵ Enter after typing the tag"
                id="tagsInput"
                validate={required}
                isMulti={true}
                simpleValues={true}
                multi
                options={mapListsToOptions(tags)}
                placeholder="Select or create new tag"
                className="react-select tag"
                value={values}
                onChange={handleOnChange}
              />
              <input id="ids" type="hidden" value={selected} />
              <br className="clear" />
              {!isTagsValid
              && (
                <div className="properties-error">
                  Tag length must be no more than 40 characters
                </div>
              )}
              <Label htmlFor="tagsInput" className="label">
                You can add multiple tags
              </Label>
            </div>
            <div className="actions modal--tag__actions">
              <BasicButton
                content="Cancel"
                color="white"
                size="small"
                onClick={closeModalPortal}
              />
              <BasicButton
                content="Add tags"
                size="small"
                color="blue"
                type="submit"
                disabled={
                  !isEmpty(formErrors)
                  || !selected.length
                  || !isTagsValid
                }
              />
            </div>
          </div>
        ) : (
          selected && (
            <RemoveTagsForm
              closeModalPortal={closeModalPortal}
              contacts={contacts}
              selected={selected}
              currentPageResults={currentPageResults}
              list={list}
              excluded={excluded}
              filterId={filterId}
              tags={tags}
              propertyId={propertyId}
              orderBy={orderBy}
            />
          )
        )}
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('Tag')(state),
  tags: state.tags,
  contactFilter: state.contactFilter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTagsA: bindActionCreators(fetchTags.request, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  requestContactsPageA: bindActionCreators(requestContactsPage, dispatch),
  requestModifiedContactViewPageA: bindActionCreators(fetchModifiedContactsPage.request, dispatch),
});

ContactTagForm.propTypes = {
  closeModalPortal: PropTypes.func,
  contacts: PropTypes.array,
  filterId: PropTypes.string,
  currentPageResults: PropTypes.array.isRequired,
  formErrors: PropTypes.object,
  fetchTagsA: PropTypes.func,
  handleSubmit: PropTypes.func,
  tags: PropTypes.object,
  notifySuccessA: PropTypes.func,
  requestModifiedContactViewPageA: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  excluded: PropTypes.array,
  type: PropTypes.string,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleUnselect: PropTypes.func,
  contactFilter: PropTypes.object,
  propertyId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  orderBy: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'Tag' })(ContactTagForm));
