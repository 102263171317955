import React from 'react';
import PropTypes from 'prop-types';

// Constants.
import {
  NPS,
  OPEN_QUESTION,
  RATING,
  LIKE_DISLIKE,
} from '../../../../constants/answerTypes';

// Local components.
import QuestionLayout from '../QuestionBody';
import LikeDislikeAnswerUI from './components/LikeDislikeAnswerUI';
import NPSAnswerUI from './components/NPSAnswerUI';
import OpenQuestionAnswerUI from './components/OpenQuestionAnswerUI';
import RatingAnswerUI from './components/RatingAnswerUI';

const SingleAnswerQuestionUI = ({
  index,
  questionTitle,
  type,
  answerExample,
  ratingValue,
  answerRequired,
}) => {
  const answersComponents = {
  [NPS]:
  <NPSAnswerUI
    index={index}
    answerRequired={answerRequired}
  />,
  [OPEN_QUESTION]:
  <OpenQuestionAnswerUI
    index={index}
    answerExample={answerExample}
    answerRequired={answerRequired}
  />,
  [RATING]:
  <RatingAnswerUI
    index={index}
    type={type}
    ratingValue={ratingValue}
    answerRequired={answerRequired}
  />,
  [LIKE_DISLIKE]:
  <LikeDislikeAnswerUI
    type={type}
    index={index}
    answerRequired={answerRequired}
  />,
  };

  return (
    <QuestionLayout
      questionNumber={index + 1}
      title={questionTitle}
      id={`${index}-${type}`}
      type={type}
      questionIndex={index}
      answerRequired={answerRequired}
    >
      {answersComponents[type]}
    </QuestionLayout>
  );
};

SingleAnswerQuestionUI.propTypes = {
  answerRequired: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  questionTitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  answerExample: PropTypes.string.isRequired,
  ratingValue: PropTypes.string.isRequired,
};

export default SingleAnswerQuestionUI;

