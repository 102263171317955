import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Styles.
import './ContentAndDesignSwitch.scss';

const LayoutSwitchButton = ({ isActive, title, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={
      classNames(
        'ContentAndDesignSwitch__button',
        {
          'ContentAndDesignSwitch__button--active': isActive,
        },
      )
    }
  >
    {title}
  </button>
);

const ContentAndDesignSwitch = ({ layoutButtons, layoutType }) => (
  <div className="ContentAndDesignSwitch">
    <div className="ContentAndDesignSwitch__buttons-container">
      {
        layoutButtons.map((button, index) => (
          <LayoutSwitchButton
            key={index}
            isActive={layoutType === button.value}
            title={button.title}
            onClick={button.onClick}
          />
          ))
      }
    </div>
  </div>
);

LayoutSwitchButton.propTypes = {
  isActive: PropTypes.bool,
  title: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

ContentAndDesignSwitch.propTypes = {
  layoutButtons: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.node.isRequired,
    value: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  })).isRequired,
  layoutType: PropTypes.string.isRequired,
};

export default ContentAndDesignSwitch;
