import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';

// Components.
import TextInput from '../../../../../../components/TextInput';

// Constants.
import {
  ONE_ANSWER,
  MULTIPLE_ANSWERS,
} from '../../../../../../constants/answerTypes';

// Context.
import { EditorContext } from '../../../../../../context/EditorContext';

// Styles.
import './MultipleAnswerQuestionController.scss';

const MultipleAnswerQuestionController = (props) => {
  const {
    questionTitle,
    answersOptions,
    questionId,
    quizMode,
    type,
    index,
  } = props;

  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const questionIndex = useMemo(() => activeElement.questions.findIndex(
    (question) => question.questionId === questionId), [activeElement]);

  const addAnswerOption = () => {
    const dataObj = { ...activeElement };
    const { questions } = dataObj;
    const answerObj = {
      answerId: uuidv4(),
      title: 'choice #',
      isCorrect: false,
    };
    questions[questionIndex].answersOptions.push(answerObj);

    setActiveElement(dataObj);
  };

  const removeAnswerOption = (id) => {
    const dataObj = { ...activeElement };
    const { questions } = dataObj;

    const filteredAnswerArray = dataObj.questions[questionIndex].answersOptions.filter(
      (answer) => answer.answerId !== id,
    );

    questions[questionIndex].answersOptions = filteredAnswerArray;

    setActiveElement(dataObj);
  };

  const changeQuestionTitle = (value) => {
    const dataObj = { ...activeElement };
    const { questions } = dataObj;

    questions[questionIndex].questionTitle = value;

    setActiveElement(dataObj);
  };

  const changeAnswerTitle = (value, id) => {
    const dataObj = { ...activeElement };
    const { questions } = dataObj;

    const answerIndex = questions[questionIndex].answersOptions.findIndex(
      (answer) => answer.answerId === id);

    questions[questionIndex].answersOptions[answerIndex].title = value;
    setActiveElement(dataObj);
  };

  const setCorrectAnswer = (id) => {
    const dataObj = { ...activeElement };
    const { questions } = dataObj;

    const questionAnswerOptions = questions[questionIndex].answersOptions;

    const answerIndex = questionAnswerOptions.findIndex(
        (answer) => answer.answerId === id);

    if (type === ONE_ANSWER) {
      const correctAnswerIndex = questionAnswerOptions.findIndex(
        (answer) => answer.isCorrect === true);

      // no correct answer defined
      if (correctAnswerIndex === -1) {
        questionAnswerOptions[answerIndex].isCorrect = true;
      } else {
      // correct answer is already defined
        questionAnswerOptions[correctAnswerIndex].isCorrect = false;
        questionAnswerOptions[answerIndex].isCorrect = true;
      }
    }

    if (type === MULTIPLE_ANSWERS) {
      questionAnswerOptions[answerIndex].isCorrect = !questions[
        questionIndex].answersOptions[answerIndex].isCorrect;
    }

    setActiveElement(dataObj);
  };

  return (
    <div className="MultipleAnswerQuestionController">
      <TextInput
        name={`question[${index}].questionElements.question.value`}
        type="text"
        value={questionTitle}
        placeholder="Type your question here..."
        onChange={(e) => changeQuestionTitle(e.target.value)}
      />
      <div className="MultipleAnswerQuestionController__answers-list">

        {answersOptions.map((answer, answerIndex) => {
          const { title, answerId, isCorrect } = answer;
          return (
            <div className="MultipleAnswerQuestionController__answer-container" key={answerId}>
              <TextInput
                name={`question[${index}].questionElements.answers.value[${answerIndex}].innerHTML`}
                type="text"
                className={classNames({ 'MultipleAnswerQuestionController__correct-answer': isCorrect })}
                value={title}
                placeholder="Type your question here..."
                onChange={(e) => changeAnswerTitle(e.target.value, answerId)}
              />
              <button
                className={classNames(`MultipleAnswerQuestionController__answer-button-${type}`, {
                  [`MultipleAnswerQuestionController__answer-button-${type}--correct-answer`]: isCorrect,
                })}
                type="button"
                disabled={!quizMode}
                onClick={() => setCorrectAnswer(answerId)}
              />

              {answersOptions.length > 2
                && (
                  <button
                    className="MultipleAnswerQuestionController__remove-answer-button"
                    type="button"
                    onClick={() => removeAnswerOption(answerId)}
                  >
                    <span
                      className="material-icons"
                    >
                      close
                    </span>
                  </button>
                )}

            </div>
          );
        })}
      </div>
      <div className="MultipleAnswerQuestionController__answers-actions-container">
        <button
          className="MultipleAnswerQuestionController__add-option-button"
          type="button"
          onClick={() => addAnswerOption()}
        >
          + Add another option
        </button>
      </div>
    </div>
  );
};

MultipleAnswerQuestionController.propTypes = {
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  quizMode: PropTypes.bool.isRequired,
  questionId: PropTypes.string.isRequired,
  questionTitle: PropTypes.string.isRequired,
  answersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      answerId: PropTypes.string,
      title: PropTypes.string,
      isCorrect: PropTypes.bool,
    }),
  ).isRequired,
};

export default MultipleAnswerQuestionController;
