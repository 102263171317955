import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, map } from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import MessageSelect from './MessageSelect';

const SurveySelect = ({
  subject,
  message,
  survey,
  setMessage,
  setSurvey,
  multi,
  messages,
  disabled,
}) => {
  const [loading, setLoading] = useState(true);
  const surveyOptions = useMemo(() => {
    const m = find(messages, (el) => el.id === message);
    if (m) {
      const { schema: { forms } } = m;
      setLoading(false);
      return map(forms, (form) => ({ text: form.displayName, value: form.displayName }));
    }
    setLoading(true);
    return [];
  }, [message, messages]);

  return (
    <>
      <MessageSelect
        subject={subject}
        message={message}
        setMessage={setMessage}
        multi={multi ? '--three-column' : '--two-column'}
        disabled={disabled}
      />
      {!!message && (
        <span className={`subject-input-select--${multi ? 'three' : 'two'}-column`}>
          <Dropdown
            className="subject-input-select__dropdown"
            options={surveyOptions}
            placeholder="Select a survey"
            value={survey}
            onChange={(e, { value }) => setSurvey(value)}
            loading={loading}
            disabled={disabled}
          />
        </span>
      )}
    </>
  );
};

SurveySelect.propTypes = {
  subject: PropTypes.string,
  message: PropTypes.string,
  survey: PropTypes.string,
  setMessage: PropTypes.func,
  setSurvey: PropTypes.func,
  multi: PropTypes.bool,
  messages: PropTypes.object,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  messages: state.messages,
});

export default connect(mapStateToProps)(SurveySelect);
