import {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import {
  Grid,
  Header,
  Label,
  Popup,
  Segment,
  Loader,
} from 'semantic-ui-react';
import classNames from 'classnames';
import {
  Field,
  Form,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  find,
  forEach,
  includes,
  isEmpty,
  omit,
  orderBy,
} from 'lodash';
import { useHistory } from 'react-router';
import {
  ContactListDropdown,
  DatePicker,
  Dropdown,
  Input,
  Textarea,
  Toggle,
} from '../../components/Field';
import { BasicButton, IconButton } from '../../components/Button';
import {
  DATE_TIME_ALT,
  deliveryMethodOptions,
} from '../../common';
import {
  mapIdentitiesToOptions,
  mapPropertiesToKeywords,
  visibleContactProperties,
} from '../../utils/map';
import { formatDeliveryMethodInitialValues } from '../../utils/format';
import { email } from '../../utils/validator';
import {
  accountActions,
  contactActions,
  genericActions,
  notificationActions,
  planActions,
} from '../../actions';
import { VerifyIdentityModal } from '../Modal';
import {
  creditCount,
  identitiesGet,
  identityUpdate,
} from '../../services/accountsApi';
import {
  contactUnsubscribeNumber,
  messageRecipientsDeleteAll,
  messageSelectAllRecipients,
  messageSend,
  messageSendTest,
  messageUpdate,
} from '../../services/messageApi';
import {
  contactsMetadataGet,
  contactsSelectAllInfo,
} from '../../services/contactApi';
import DeliveryEmailForm from './DeliveryEmailForm';
import DeliveryUrlForm from './DeliveryUrlForm';
import { getDomainFromEmail, getNewestVerifiedEmail } from '../../utils/helper';
import { MessageContext } from '../../context/messageEditor';
import { getPlanFeatures } from '../../utils/plans';
import { featuresKeys } from '../../common/plans';
import { useFeature, useScreenWidth } from '../../hooks';

const { openModalPortal, closeModalPortal, openConfirmationModal } = genericActions;
const { getAccount, fetchIdentities } = accountActions;
const { getSession } = planActions;
const { notifyError, notifySuccess } = notificationActions;
const { fetchLists } = contactActions;

const defaultText = 'Enter your text here - this will be the message your contacts will receive.';
const linkText = '<link>';

const maxSize = 5242880;

const limit = 800;

const defaultPageSize = 10;

const nameFromRegex = /^[a-zA-Z]{1,11}$/;

const popupStyle = {
  textAlign: 'center',
};

// TODO find a way to refactor asap

const DeliveryMethodForm = ({
  background,
  handleSubmit,
  messageText,
  expiresAt,
  expiration,
  method,
  change,
  openModalPortalA,
  openConfirmationModalA,
  closeModalPortalA,
  contactProperties,
  logo,
  messageEditor,
  subject,
  getAccountA,
  getSessionA,
  notifyErrorA,
  notifySuccessA,
  session,
  image,
  fetchListsA,
  fetchIdentitiesA,
  lists,
  listId,
  emailFrom,
  nameFrom,
  userName,
  features,
  handleMessageClose,
  identities,
  unsubscribe, // form value
}) => {
  const [estimatedSMSCount, setEstimatedSMSCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [placeholder, setPlaceholder] = useState(defaultText);
  const [showNotificationSms, setShowNotificationSms] = useState(0);
  const [showNotificationEmail, setShowNotificationEmail] = useState(0);
  const [focused, setFocused] = useState(false);
  const [currentList, setCurrentList] = useState('none');
  const [s3Key, setS3Key] = useState('');
  const [s3Bucket, setS3Bucket] = useState('');
  const [recipientsTouched, setRecipientsTouched] = useState(false);
  const [totalContacts, setTotalContacts] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [element, setElement] = useState(undefined);
  const [fromOptions, setFromOptions] = useState([]);
  const [checksum, setChecksum] = useState('');
  const [awaitingChecksum, setAwaitingChecksum] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [spinnerState, setSpinnerState] = useState(false);
  const [creditsError, setCreditsError] = useState(false);
  const [creditsErrorMessage, setCreditsErrorMessage] = useState('');
  const [disabledSendOnInput, setDisabledSendOnInput] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  const initial = useRef(true);
  const prevIdentities = useRef();
  const prevMethod = useRef();
  const focusedRef = useRef(false);

  const {
    isAutomation,
    handleSave,
    canSend,
    setCurrentStep,
    changeUnsubscription,
  } = useContext(MessageContext);

  const { windowWidth, isDesktop } = useScreenWidth();
  const isMessageExpirationEnabled = useFeature(
    featuresKeys.featureKeys.messageExpiration,
    features,
  );
  const isPersonalizedMessagingEnabled = useFeature(
    featuresKeys.featureKeys.personalizedMessaging,
    features,
  );

  const { push } = useHistory();

  const handleEmailFromChange = (value, initialElementGet) => {
    if (method === 'email' && !email(value)) {
      let el = find(identities, (identity) => identity.identity === value);
      if (!el) {
        const domain = getDomainFromEmail(value);
        el = find(identities, (identity) => identity.identity === domain);
      }
      if (initialElementGet && el) {
        initial.current = false;
      }
      setElement(el);
    }
  };

  const getIdentities = (size, getDomains, getEmails, reload) => {
    const promises = [];
    let elements = [];
    let total = 0;
    let showError = false;
    let domains = false;
    let emails = false;

    const callback = (res, type) => {
      if (res.response) {
        elements = [...elements, ...res.response.elements];
        total += res.response.total;
        if (res.response.total > res.response.pageSize) {
          if (type === 'emails') {
            emails = true;
          } else {
            domains = true;
          }
        }
      } else {
        showError = true;
      }
    };

    if (getDomains) {
      promises.push(identitiesGet({ type: 'domain', page: 1, pageSize: size }).then((res) => callback(res, 'domains')));
    }
    if (getEmails) {
      promises.push(identitiesGet({ type: 'email', page: 1, pageSize: size }).then((res) => callback(res, 'emails')));
    }

    Promise.all(promises).then(() => {
      if (showError) {
        notifyErrorA('Something went wrong... Try again later');
        setLoading(false);
      } else if (domains || emails) {
        setPageSize(total);
        setLoading(true);
        getIdentities(total, domains, emails);
      } else {
        setLoading(false);
      }
      fetchIdentitiesA({
        elements: orderBy(elements, ['id'], ['desc']),
      });
      if (reload) {
        handleEmailFromChange(emailFrom);
      }
    });
  };

  const creditCountWithLoadingState = (...args) => {
    setSpinnerState(true);
    return creditCount(...args).then((res) => {
      setSpinnerState(false);
      return res;
    });
  };

  const disableCreditsError = () => {
    setCreditsError(false);
    setCreditsErrorMessage('');
  };

  const enableCreditsError = (message) => {
    setCreditsError(true);
    setCreditsErrorMessage(message);
  };

  const temporaryListContactCount = messageEditor.tempCount;

  const findCurrentListCount = () => {
    if (listId > 0 && lists) {
      const foundListCount = find(lists, (list) => list.id === listId)?.count;
      if (foundListCount) {
        return foundListCount;
      }
      if (temporaryListContactCount) {
        return temporaryListContactCount;
      }
      return undefined;
    }
    return undefined;
  };

  const handleContactCount = () => {
    const currentListCount = findCurrentListCount();
    if (currentListCount === 0) {
      enableCreditsError('Trying to send a message that has 0 recipients assigned');
    }
  };

  const handleBlur = (messageID, textValue, changeMethod) => {
    setFocused(false);
    setDisabledSendOnInput(false);
    if (textValue && currentList !== 'none' && (method === 'sms' || changeMethod)) {
      creditCountWithLoadingState(messageID, textValue)
        .then((res) => {
          if (res.error) {
            enableCreditsError(res.error.message);
          } else {
            disableCreditsError();
            setEstimatedSMSCount(res.response);
          }
        });
    } else if (!textValue) {
      enableCreditsError('Trying to send an empty message');
      setPlaceholder(defaultText);
      if (method === 'email') {
        change('messageText', defaultText);
      }
    }
  };

  useEffect(() => {
    contactsMetadataGet().then((res) => setTotalContacts(res.response.total));

    getSessionA();
    getAccountA();
    fetchListsA();
    getIdentities(pageSize, true, true);
  }, []);

  useEffect(() => {
    if (method === 'email' && emailFrom && !element && initial.current) {
      handleEmailFromChange(emailFrom, true);
    }
  }, [method, emailFrom]);

  const setInitialFromOptions = (i, from) => {
    const options = [{ text: 'Default email', value: 'default' }, ...mapIdentitiesToOptions(i)];
    if (from && !email(from)
      && !find(options, (option) => option.value === from)) {
      options.unshift({ text: from, value: from });
    }
    setFromOptions(options);
  };

  const removeFormOptionsDuplicates = (options) => {
    const from = [];
    forEach(options, (option) => {
      if (!find(from, (o) => o.value === option.value)) {
        from.push(option);
      }
    });
    setFromOptions(from);
  };

  useEffect(() => {
    if (identities && prevIdentities.current
      && identities.length !== prevIdentities.current.length) {
      removeFormOptionsDuplicates([...fromOptions, ...mapIdentitiesToOptions(identities)]);
    }

    if ((identities && !prevIdentities.current)
      || (identities && prevIdentities.current && fromOptions.length === 0)) {
      setInitialFromOptions(identities, emailFrom);
    }
    prevIdentities.current = identities;
  }, [identities]);

  useEffect(() => {
    if (identities && identities.length && emailFrom && emailFrom !== 'default' && method === 'email') {
      const currentSelectedEmail = identities.find((chunk) => chunk.identity === emailFrom);
      setShowVerifyEmail(!currentSelectedEmail.verified);
      handleEmailFromChange(currentSelectedEmail.identity);
    }
  }, [identities, emailFrom]);

  const removeLinks = () => {
    const textElem = document.querySelector('.ris-textarea');
    if (textElem) {
      let changedText = textElem.value.replace(/<link>/gi, '');
      if (!changedText.replace(/\s/g, '').length) {
        changedText = changedText.replace(/\s/g, '');
      }
      change('messageText', changedText);
    }
  };

  useEffect(() => {
    // unsubscribe is only available for sms
    if (unsubscribe && (method === 'email' || method === 'url') && prevMethod.current === 'sms') {
      changeUnsubscription(false);
      change('unsubscribe', false);
    }
    if (method !== prevMethod.current) {
      messageUpdate({
        id: messageEditor.id,
        body: { deliveryMethod: method },
      });
      disableCreditsError();
      if (methodTypeEmail) handleContactCount();
      if (prevMethod.current) {
        if (method === 'email') {
          removeLinks();
          const identity = getNewestVerifiedEmail(identities);
          if (identity) {
            setElement(find(identities, (i) => i.identity === identity));
          }
          change('from', identity);
          if (!nameFrom && userName) {
            change('nameFrom', userName);
          }
        } else {
          const { id } = messageEditor;
          let text = messageText;
          let changed = false;
          if (!messageText) {
            text = '<link> ';
            changed = true;
          } else if (!includes(messageText, '<link>')) {
            text = messageText.concat(' <link> ');
            changed = true;
          }
          handleBlur(id, text, true);
          change('from', '');
          if (changed) {
            change('messageText', text);
          }
        }
      } else {
        change('from', emailFrom);
      }
    }
    prevMethod.current = method;
  }, [method]);

  const selectAllHandler = (action, key, bucket, callback, list) => {
    const { id } = messageEditor;

    if (id) {
      const payload = {
        body: {
          action,
          source: {
            type: 's3',
            key,
            bucket,
          },
        },
        id,
      };

      return new Promise((resolve) => {
        messageSelectAllRecipients(payload).then((res) => {
          if (res.error) {
            setAwaitingChecksum(false);
          } else {
            const { checksum: cs } = res.response;
            setAwaitingChecksum(false);
            setChecksum(cs);
            if (callback) {
              callback(list).then(() => resolve());
            } else {
              resolve();
            }
          }
        }).catch(() => setAwaitingChecksum(true));
      });
    }
    return new Promise();
  };

  const generateS3 = (list) => {
    const body = {
      destination: 's3',
      filterId: '',
      listId: list,
      properties: [
        'contact.email',
        'contact.name',
        'contact.surname',
        'contact.phoneNumber',
        'contact.city',
        'contact.country',
        'contact.department',
        'contact.region',
      ],
    };
    setAwaitingChecksum(true);
    return new Promise((resolve) => {
      contactsSelectAllInfo(body).then((res) => {
        if (res.error) {
          setAwaitingChecksum(false);
        } else {
          const { key, bucket } = res.response;
          selectAllHandler('insert', key, bucket).then(() => {
            resolve();
            if (method === 'sms') {
              const { id } = messageEditor;
              creditCountWithLoadingState(id, messageText).then((creditsRes) => {
                if (creditsRes.error) {
                  enableCreditsError(creditsRes.error.message);
                } else {
                  disableCreditsError();
                  setEstimatedSMSCount(creditsRes.response);
                }
                });
            }
          });
          setS3Key(key);
          setS3Bucket(bucket);
        }
      }).catch(() => setAwaitingChecksum(true));
    });
  };

  const checkToShowNotification = (list) => {
    const { id } = messageEditor;
    let notificationSms = 0;
    let notificationEmail = 0;

    const promise = s3Key && s3Bucket
      ? selectAllHandler('delete', s3Key, s3Bucket, generateS3, list)
      : generateS3(list);

    promise.then(() => {
      Promise.all([
        contactUnsubscribeNumber({
          id,
          body: {
            channel: 'sms',
          },
        }).then((res) => {
          if (!isEmpty(res.response)) {
            notificationSms = res.response.count;
          }
        }),
        contactUnsubscribeNumber({
          id,
          body: {
            channel: 'email',
          },
        }).then((res) => {
          if (!isEmpty(res.response)) {
            notificationEmail = res.response.count;
          }
        }),
      ]).then(() => {
        setShowNotificationSms(notificationSms);
        setShowNotificationEmail(notificationEmail);
        setSpinnerState(false);
      });
    });
  };
  useEffect(() => {
    disableCreditsError();
    if (methodTypeEmail) {
      handleContactCount();
    }
    if (typeof listId === 'number') {
      if (currentList !== listId && listId !== 'none') {
        const { id } = messageEditor;
        if (id) {
          setAwaitingChecksum(true);
          setSpinnerState(true);
          messageRecipientsDeleteAll(id).then(() => {
            checkToShowNotification(listId);
          });
          setCurrentList(listId);
        }
      }
    }
  }, [listId]);

  useEffect(() => {
    // referenced from https://stackoverflow.com/a/5926782
    let typingTimer; // timer identifier
    const doneTypingInterval = 500; // time in ms (0.5 seconds)
    const area = document.querySelector('.blurring-textarea');

    const timeoutListener = () => {
      clearTimeout(typingTimer);
      if (!disabledSendOnInput) {
        setDisabledSendOnInput(true);
      }
      if (area) {
        typingTimer = setTimeout(doneTyping, doneTypingInterval);
      }
    };
    area.addEventListener('keyup', timeoutListener);

    const doneTyping = () => {
      handleBlur(messageEditor.id, area.value);
    };

    return () => {
      area.removeEventListener('keyup', timeoutListener);
    };
  }, [currentList]);

  const getTextProperties = (text) => {
    const startPoint = text.selectionStart;
    const finishPoint = text.selectionEnd;
    return { startPoint, finishPoint };
  };

  const openValidationModal = () => {
    openModalPortalA({
      content: <VerifyIdentityModal />,
      contentProps: {
        closeModalPortal: () => {
          closeModalPortalA();
          getIdentities(pageSize + defaultPageSize, true, true, true);
        },
        type: 'email',
        delivery: true,
        identity: emailFrom,
        element,
      },
    });
  };

  useEffect(() => {
    focusedRef.current = focused;
  }, [focused]);


  const focusHandler = () => {
    const ta = document.querySelector('.blurring-textarea');
    if (method === 'email' && (messageText === defaultText || messageText === defaultText.concat(' '))) {
      change('messageText', '');
      setPlaceholder('');
      ta.value = '';
    }
    if (ta.value === '') {
      setPlaceholder('');
    }
  };

  const sendTestMessage = () => {
    if (method === 'sms' && !!nameFrom && !nameFrom.match(nameFromRegex)) {
      notifyErrorA('From field: only letters allowed and no special characters. Maximum characters 11.');
    } else {
      let from = emailFrom;
      if (method === 'sms') {
        if (nameFrom) {
          from = nameFrom;
        } else {
          from = 'Test';
        }
      }

      messageSendTest({
        body: {
          method,
          subject,
          messageText,
          background,
          logo,
          from,
          unsubscribe,
        },
        id: messageEditor.id,
      }).then((res) => {
        if (res.error) {
          const { error: { message } } = res;
          notifyErrorA(message);
        } else {
          notifySuccessA('Messages sent');
        }
      });
    }
  };

  const handleCursorChange = (textProperties, prop, text) => {
    const cursorPosition = textProperties.startPoint + prop.length;
    const ta = text;
    setTimeout(() => {
      ta.selectionStart = cursorPosition;
      ta.selectionEnd = cursorPosition;
    }, 10);
    ta.focus();
  };

  const handlePersonalizationPropClick = (prop) => {
    let changedProp = prop;
    const text = document.querySelector('.ris-textarea');
    const textProperties = getTextProperties(text);

    if (textProperties.startPoint > 0 && text.value[textProperties.startPoint - 1] !== ' ') {
      changedProp = ' '.concat(prop);
    }

    const firstPart = messageText.slice(0, textProperties.startPoint);
    const lastPart = messageText.slice(textProperties.finishPoint);

    const messageTextSupplied = firstPart.concat(changedProp, lastPart);

    change('messageText', messageTextSupplied);

    handleCursorChange(textProperties, changedProp, text);
    setPopupOpen(false);
  };

  const convertToUTC = (date) => moment.utc(date, DATE_TIME_ALT);

  const showError = (error) => {
    const globalError = find(error, { property: '' });

    if (globalError) {
      notifyErrorA(globalError.message);
    } else if (error && error.code && error.message) {
      notifyErrorA(error.message);
    } else {
      notifyErrorA('Something went wrong... Please try again later.');
    }
    setLoading(false);
  };

  const handleSend = (payload) => {
    const { id, origin } = messageEditor;
    if (isAutomation || origin === 'automation') {
      handleSave();
      handleMessageClose();
    } else {
      messageSend(payload).then((res) => {
        if (res.error) {
          const { error } = res;
          showError(error);
        } else {
          notifySuccessA('Messages sent');
          push(`/successPage/${id}`);
        }
      });
    }
  };

  const handleFormSubmit = (values) => {
    const { nameFrom: nameFromVal, from: emailFromVal } = values;

    let updatedValues = values;
    const { method: methodVal } = updatedValues;

    if (expiration && moment(expiresAt).isBefore(moment())) {
      notifyErrorA('You have selected a time that has passed. Select a time for future dates');
      return;
    }

    if (!expiration) {
      updatedValues = omit(updatedValues, 'expiresAt');
    } else {
      const validDate = expiresAt;
      updatedValues.expiresAt = convertToUTC(moment(expiresAt)).format(moment.defaultFormat);
      change('expiresAt', validDate);
    }

    updatedValues = omit(updatedValues, 'expiration');

    if (methodVal === 'sms') {
      updatedValues = { ...updatedValues, unsubscribe };
    } else {
      updatedValues = { ...updatedValues, unsubscribe: false, recipients: [] };
      if (emailFromVal === 'default') {
        updatedValues = { ...updatedValues, from: '' };
      }
    }

    const payload = {
      body: {
        ...updatedValues,
        checksum,
      },
      id: messageEditor.id,
    };

    setLoading(true);
    if (element) {
      const { id } = element;
      identityUpdate({
        id,
        body: {
          name: nameFromVal,
        },
      }).then((res) => {
        if (res.error) {
          const { error } = res;
          showError(error);
        } else {
          handleSend(payload);
        }
      });
    } else {
      handleSend(payload);
    }
  };

  const handleUnderlayingAction = (values) => {
    const { origin } = messageEditor;
    const { method: methodVal, messageText: messageTextVal } = values;
    if (methodVal === 'sms' && !messageTextVal.includes('<link>')) {
      openConfirmationModalA({
        actionName: 'send',
        callbackFunction: handleFormSubmit,
        text: `You are about to ${isAutomation || origin === 'automation' ? 'save' : 'send'} SMS without a link to the message.\nAre You sure You want to send anyway?`,
        id: values,
      });
    } else {
      handleFormSubmit(values);
    }
  };

  const handleAddEmailOption = (value) => {
    setFromOptions([{ text: value, value }, ...fromOptions]);
  };

  const renderContactsCount = (notification, dropdown) => {
    if (listId === 'none') {
      return null;
    }
    const findText = () => {
      if (listId > 0 && lists) {
        const foundListCount = find(lists, (list) => list.id === listId)?.count;
        if (foundListCount) {
          return foundListCount;
        }
        if (temporaryListContactCount) {
          return temporaryListContactCount;
        }
        return totalContacts;
      }

      return totalContacts;
    };

    const text = findText();

    if (dropdown && isDesktop) {
      return (
        <div
          className={`contact-list-selected-count${notification ? ' contact-list-selected-count--margin' : ''}`}
        >
          {`(${text} Contacts)`}
        </div>
      );
    }
    if (!dropdown && !isDesktop) {
      return (
        <div className="contact-list-selected-count__mobile">
          {`${text} Contacts Selected`}
        </div>
      );
    }

    return null;
  };

  const changePublishedState = (value) => {
    setIsPublished(value);
  };

  const methodTypeSms = method === 'sms';
  const methodTypeEmail = method === 'email';
  const methodTypeUrl = method === 'url';

  let showNotification = false;

  if (
    (methodTypeSms && showNotificationSms > 0)
    || (methodTypeEmail && showNotificationEmail > 0)
  ) {
    showNotification = true;
  }

  let unsubscribedContacts = 0;

  if (showNotification) {
    unsubscribedContacts = methodTypeSms
      ? showNotificationSms
      : showNotificationEmail;
  }

  const isSendDisabled = loading
    || awaitingChecksum
    || (!canSend && !(isAutomation || messageEditor.origin === 'automation'))
    || (messageText && messageText.length > limit && methodTypeSms)
    || (listId === 'none'
      && !(isAutomation || messageEditor.origin === 'automation'))
    || (methodTypeEmail
      && ((!element && emailFrom) || (element && !element.verified))
      && emailFrom !== 'default')
    || creditsError
    || spinnerState
    || disabledSendOnInput;

  return (
    <Grid centered verticalAlign="middle">
      <Segment padded={false} className="delivery-segment">
        <Header attached="top" className="delivery-method-header">
          {isAutomation && (
            <div className="select-delivery-method">
              <BasicButton
                className="blue white-bordered"
                content="Close"
                type="button"
                onClick={handleMessageClose}
              />
            </div>
          )}
          <div htmlFor="method" className="select-delivery-method">
            <span className="select-delivery-method-label">
              Choose Delivery Method:
            </span>
            {!isEmpty(session) ? (
              <Field
                className=""
                name="method"
                id="method"
                component={Dropdown}
                selection
                direction="left"
                disabled={isPublished}
                options={deliveryMethodOptions(session, isAutomation)}
              />
            ) : null}
            {isPublished && !loading ? <Label className="publishedUrlDisclaimer" pointing="left">Unpublish to change</Label> : null}
          </div>
          {!methodTypeUrl && (
            <IconButton
              className="blue white-bordered"
              text={`Send a test ${methodTypeSms ? 'SMS' : 'message'}`}
              iconName={methodTypeSms ? 'message' : 'email'}
              type="button"
              onClick={sendTestMessage}
            />
          )}
        </Header>
        <Form
          onSubmit={handleSubmit(handleUnderlayingAction)}
          className="ris-form"
        >
          {!isAutomation && !(messageEditor.origin === 'automation') && !methodTypeUrl && (
            <div className="delivery-contact-count">
              <span className="delivery-contact-count__label">To</span>
              <div className="delivery-list-select">
                <Field
                  name="listId"
                  id="listId"
                  component={ContactListDropdown}
                  lists={lists}
                  recipientsTouched={recipientsTouched}
                  totalContacts={totalContacts}
                  addNewList={() => setCurrentStep('contacts', 0, true)}
                  className={showNotification ? 'dropdown-icon-margin' : ''}
                  onChange={() => !recipientsTouched && setRecipientsTouched(true)}
                  messageEditor={messageEditor}
                />
              </div>
              {renderContactsCount(showNotification, true)}
              {showNotification && (
                <Popup
                  trigger={
                    <div className="delivery-contact-count__value__notification delivery-contact-count__value__notification--info" />
                  }
                  content={`The contacts you have selected contains ${unsubscribedContacts} recipients, that have opted out from this sending method. The message will not be sent to them.`}
                  style={popupStyle}
                  basic
                  inverted
                />
              )}
              <BasicButton
                content="Edit List"
                color="blue"
                size="small"
                className="tertiary delivery-method-lists"
                onClick={() => setCurrentStep('contacts', listId)}
              />
            </div>
          )}
          { !methodTypeUrl && (
            <div className="delivery-email-input">
              {methodTypeEmail && (
              <Field
                component={Input}
                name="nameFrom"
                type="text"
                label="From"
                className="delivery-method-email__name"
                placeholder="Name"
              />
            )}
              {methodTypeSms ? (
                <Field
                  label="From"
                  component={Input}
                  name="from"
                  type="text"
                />
            ) : (
              <div className="delivery-method-email">
                <Field
                  component={Dropdown}
                  name="from"
                  onChange={(e, value) => handleEmailFromChange(value)}
                  placeholder="Email"
                  options={fromOptions}
                  search={true}
                  selection
                  allowAdditions={false}
                  onAddItem={handleAddEmailOption}
                  onBlur={(e) => e.preventDefault()}
                />
              </div>
            )}
              {methodTypeEmail
              && emailFrom
              && emailFrom !== 'default'
              && ((element && !element.verified) || !element) && (
              <BasicButton
                content="Verify"
                color="blue"
                size="small"
                className="tertiary delivery-method-lists"
                type="button"
                onClick={openValidationModal}
              />
            )}
            </div>
          )}
          {methodTypeEmail
          && !element
          && showVerifyEmail
          && emailFrom
          && emailFrom !== 'default' && (
            <Grid.Row>
              <Grid.Column className="from-rules__identity">
                Verify email to send from this address
              </Grid.Column>
            </Grid.Row>
          )}
          {methodTypeEmail
          && element
          && !element.verified
          && emailFrom !== 'default' && (
            <Grid.Row>
              <Grid.Column className="from-rules__verified">
                Verification email sent.&nbsp;
                <span
                  className="from-rules__verified--reload"
                  onClick={() => getIdentities(pageSize, true, true, true)}
                >
                  Click the link in the email and then click here.
                </span>
              </Grid.Column>
            </Grid.Row>
          )}
          {methodTypeSms && (
            <Grid.Row>
              <Grid.Column className="from-rules">
                <div>
                  Maximum characters: 11. Must contain letters and no special
                  characters.
                </div>
              </Grid.Column>
            </Grid.Row>
          )}
          {methodTypeEmail && (
            <DeliveryEmailForm
              color="#FFFFFF"
              logo={logo}
              logoName=""
              image={image}
              imageName=""
              maxSize={maxSize}
              change={change}
              contactProperties={contactProperties}
              subject={subject}
            />
          )}
          {!methodTypeUrl && (
            <div className={methodTypeEmail ? 'delivery-email-settings' : ''}>
              <Field
                label="Message"
                component={Textarea}
                name="messageText"
                type="text"
                id="messageText"
                onFocus={focusHandler}
                className={classNames('blurring-textarea', {
                'delivery-margins': methodTypeEmail,
              })}
                placeholder={placeholder}
              />
            </div>
          )}
          {methodTypeEmail && (
            <div className="personalize-email">
              <Popup
                on="click"
                position="bottom left"
                className="personalize-popup"
                open={popupOpen}
                onOpen={() => setPopupOpen(true)}
                onClose={() => setPopupOpen(false)}
                trigger={(
                  <IconButton
                    className="tertiary personalize-button"
                    size="small"
                    color="blue"
                    iconName="person"
                    text="Personalize"
                    type="button"
                  />
                )}
              >
                <div className="keyword-header-character-limit">
                  <span className="keywords-header">
                    Add #keywords to personalize your message
                  </span>
                </div>
                <div className="keywords">
                  {visibleContactProperties(contactProperties).map(
                    (prop, key) => (
                      <span
                        className="keyword"
                        key={key}
                        onClick={() => handlePersonalizationPropClick(
                          '#'.concat(prop.name, ' '),
                        )}
                      >
                        {`#${prop.label}`}
                      </span>
                    ),
                  )}
                </div>
                <div className="keyword-example">
                  Example: You sent a message to Joe. In the message you
                  wrote: “Hey, #name. How are you?”. Joe will see: “Hey, Joe.
                  How are you?”
                </div>
              </Popup>
              {renderContactsCount(false, false)}
            </div>
          )}
          {methodTypeSms && (
            <div className="delivery-credits__wrapper">
              <Grid.Row className="delivery-characters-preview">
                <span className={`delivery-credits ${creditsError ? 'delivery-credits--hidden' : ''}`}>
                  Estimated SMS to be sent:
                  {spinnerState ? (
                    <>
                    &nbsp;
                      <Loader
                        active={spinnerState}
                        inline={true}
                        size="tiny"
                      />
                    </>
)
                  : (
                    <span className="delivery-credits__values">
                      {`${estimatedSMSCount || 0}`}
                    </span>
)}
                </span>
                <p className="delivery-link">
                  Insert&nbsp;
                  <span
                    className="delivery-link__add"
                    onClick={() => handlePersonalizationPropClick(
                      linkText.concat(' '),
                    )}
                  >
                    &lt;link&gt;
                  </span>
                  &nbsp;where you want the message link to appear
                </p>
              </Grid.Row>
              <Grid.Row className="personalize-sms">
                {isPersonalizedMessagingEnabled && (
                  <div className="personalize-sms__personalize">
                    <Popup
                      on="click"
                      open={popupOpen}
                      onOpen={() => setPopupOpen(true)}
                      onClose={() => setPopupOpen(false)}
                      position={`${
                        windowWidth < 605 ? 'top left' : 'bottom right'
                      }`}
                      className="personalize-popup"
                      trigger={(
                        <IconButton
                          className="tertiary personalize-button"
                          size="small"
                          color="blue"
                          iconName="person"
                          text="Personalize"
                          type="button"
                        />
                      )}
                    >
                      <div className="keyword-header-character-limit">
                        <span className="keywords-header">
                          Add #keywords to personalize your message
                        </span>
                      </div>
                      <div className="keywords">
                        {visibleContactProperties(contactProperties).map(
                          (prop, key) => (
                            <span
                              className="keyword"
                              key={key}
                              onClick={() => handlePersonalizationPropClick(
                                '#'.concat(prop.name, ' '),
                              )}
                            >
                              {`#${prop.label}`}
                            </span>
                          ),
                        )}
                      </div>
                      <div className="keyword-example">
                        Example: You sent a message to Joe. In the message you
                        wrote: “Hey, #name. How are you?”. Joe will see: “Hey,
                        Joe. How are you?”
                      </div>
                    </Popup>
                    {renderContactsCount(false, false)}
                  </div>
                )}
              </Grid.Row>
            </div>
          )}
          {methodTypeUrl && (
            <DeliveryUrlForm changePublishedState={changePublishedState} />
          )}
          {!methodTypeUrl && (
          <Header attached="bottom" className="delivery-method-footer">
            <Grid className="no-spacing">
              {isMessageExpirationEnabled && !(isAutomation || messageEditor.origin === 'automation')
              && (
                <Grid.Row className="delivery-expires">
                  <Field
                    name="expiration"
                    component={Toggle}
                    label="Message expires"
                  />
                  {expiration && (
                    <>
                      <div className="expiration-wrapper">
                        <Field
                          name="expiresAt"
                          component={DatePicker}
                          showTimeSelect={true}
                          dateFormat={DATE_TIME_ALT}
                          className="expirationDate"
                          position="top"
                        />
                      </div>
                      <Popup
                        trigger={<div className="delivery-contact-count__value__notification delivery-contact-count__value__notification--error" />}
                        content="The expiration times may wary depending on the contacts timezone settings."
                        style={popupStyle}
                        basic
                        inverted
                      />
                    </>
                  )}
                </Grid.Row>
              )}
              {methodTypeSms && (
                <Grid.Row className="delivery-expires">
                  <Field
                    name="unsubscribe"
                    component={Toggle}
                    label="Unsubscribe"
                    onChange={(event, newValue) => changeUnsubscription(newValue)}
                  />
                </Grid.Row>
              )}
              <Grid.Row className="delivery__actions">
                {creditsError && (
                <div className="delivery__actions__error-message">
                  {creditsErrorMessage}
                </div>
                )}
                <BasicButton
                  fluid
                  content={isAutomation || messageEditor.origin === 'automation' ? 'SAVE' : 'SEND'}
                  type="submit"
                  className={`blue-light ${spinnerState ? 'delivery-method-send--loading loading' : 'delivery-method-send'}`}
                  disabled={isSendDisabled}
                />
              </Grid.Row>
            </Grid>
          </Header>
          )}
        </Form>
      </Segment>
    </Grid>
  );
};

DeliveryMethodForm.propTypes = {
  background: PropTypes.string,
  handleSubmit: PropTypes.func,
  messageText: PropTypes.string,
  expiresAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  expiration: PropTypes.bool,
  method: PropTypes.string,
  change: PropTypes.func,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  contactProperties: PropTypes.array,
  logo: PropTypes.string,
  messageEditor: PropTypes.object,
  subject: PropTypes.string,
  getAccountA: PropTypes.func,
  getSessionA: PropTypes.func,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  session: PropTypes.object,
  image: PropTypes.string,
  fetchListsA: PropTypes.func,
  lists: PropTypes.array,
  listId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  openConfirmationModalA: PropTypes.func,
  emailFrom: PropTypes.string,
  fetchIdentitiesA: PropTypes.func,
  identities: PropTypes.array,
  nameFrom: PropTypes.string,
  userName: PropTypes.string,
  features: PropTypes.object,
  handleMessageClose: PropTypes.func,
  unsubscribe: PropTypes.bool,
};

const formConfig = {
  form: 'DeliveryMethodForm',
  destroyOnUnmount: false,
};

DeliveryMethodForm.contextType = MessageContext;

const selector = formValueSelector('DeliveryMethodForm');

const mapStateToProps = (state) => ({
    messageEditor: state.messageEditor,
    initialValues: formatDeliveryMethodInitialValues(state.messageEditor),
    messageText: selector(state, 'messageText'),
    expiresAt: selector(state, 'expiresAt'),
    expiration: selector(state, 'expiration'),
    subject: selector(state, 'subject'),
    logo: selector(state, 'logo'),
    image: selector(state, 'image'),
    method: selector(state, 'method'),
    listId: selector(state, 'listId'),
    emailFrom: selector(state, 'from'),
    nameFrom: selector(state, 'nameFrom'),
    background: selector(state, 'background'),
    unsubscribe: selector(state, 'unsubscribe'),
    contactProperties: mapPropertiesToKeywords(state.entities.contactProperties),
    session: state.plans.session,
    lists: state.lists.elements,
    identities: state.identities.elements,
    userName: state.user.fullName,
    features: getPlanFeatures(state.plans.session),
  });

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
  getAccountA: bindActionCreators(getAccount.request, dispatch),
  getSessionA: bindActionCreators(getSession.request, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  fetchListsA: bindActionCreators(fetchLists.request, dispatch),
  openConfirmationModalA: bindActionCreators(openConfirmationModal, dispatch),
  fetchIdentitiesA: bindActionCreators(fetchIdentities, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm(formConfig)(DeliveryMethodForm));
