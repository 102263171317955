import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { includes, isEmpty } from 'lodash';
import { StripeProvider, Elements } from 'react-stripe-elements';

import RenewPlan from './RenewPlan';
import { accountActions, planActions } from '../../actions';
import { ENV } from '../../config';
import { NotFound, Login } from '../../containers/Page';
import { AnonymousLayout } from '../../containers/Layouts';
import { getPlanFeatures } from '../../utils/plans';
import { useFeature } from '../../hooks';

const { STRIPE_API_KEY } = ENV;

const { getAccountUser } = accountActions;
const { getSession } = planActions;

const Authorization = ({
  allowedRoles,
  component: Component,
  layout: Layout,
  user,
  fetchAccountA,
  account,
  session,
  getSessionA,
  selectedFeature,
  features,
}) => {
  const [loading, setLoading] = useState(false);
  const [renewPlan, setRenewPlan] = useState(false);

  const isFeatureAllowed = useFeature(selectedFeature, features);

  useEffect(() => {
    let suspendAccountFetch = false;

    try {
      JSON.parse(localStorage.credentials);
    } catch (e) {
      suspendAccountFetch = true;
    }

    if (allowedRoles !== '*' && !user.role && !suspendAccountFetch) {
      fetchAccountA();
      setLoading(true);
    }

    if (!!selectedFeature && isEmpty(session)) {
      getSessionA();
    }
  }, []);

  useEffect(() => {
    if ((user.id && loading) || (allowedRoles === '*' && loading)) {
      setLoading(false);
    }
  }, [user, loading, allowedRoles]);

  useEffect(() => {
    if (!isEmpty(account)) {
      const { paymentActionRequired } = account;
      setRenewPlan(paymentActionRequired);
    }
  }, [account]);

  if (loading || (!!selectedFeature && isEmpty(session))) {
    return <div />;
  }

  if (allowedRoles !== '*' && !user.role) {
    return (
      <AnonymousLayout>
        <Login />
      </AnonymousLayout>
    );
  }

  if (!!selectedFeature && !isFeatureAllowed) {
    return (
      <AnonymousLayout>
        <NotFound />
      </AnonymousLayout>
    );
  }

  if (allowedRoles === '*' || includes(allowedRoles, user.role)) {
    return (
      <>
        {renewPlan && (
          <StripeProvider apiKey={STRIPE_API_KEY}>
            <Elements>
              <RenewPlan />
            </Elements>
          </StripeProvider>
        )}
        <Layout>
          <Component />
        </Layout>
      </>
    );
  }

  return (
    <AnonymousLayout>
      <NotFound />
    </AnonymousLayout>
  );
};

Authorization.propTypes = {
  allowedRoles: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  component: PropTypes.any.isRequired,
  layout: PropTypes.any,
  user: PropTypes.object,
  fetchAccountA: PropTypes.func,
  account: PropTypes.object,
  getSessionA: PropTypes.func,
  session: PropTypes.object,
  selectedFeature: PropTypes.string,
  features: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  account: state.account,
  session: state.plans.session,
  features: getPlanFeatures(state.plans.session),
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccountA: bindActionCreators(getAccountUser.request, dispatch),
  getSessionA: bindActionCreators(getSession.request, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);
