import { automationPaginator } from '../paginators';

const automationList = (state = {}, action) => {
  const { meta } = action;
  if (meta && meta.name === 'automation') {
    return automationPaginator.itemsReducer(state, action);
  }
  return state;
};

export default automationList;
