import { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Divider } from 'semantic-ui-react';
import Creatable from 'react-select/creatable';
import { remove, forEach } from 'lodash';
import { BasicButton } from '../../components/Button';
import { notifyError, notifySuccess } from '../../actions/notificationActions';
import { required } from '../../utils/validator';
import { mapListsToOptions } from '../../utils/map';
import SegmentationItemsArray from '../../components/Analytics/Segmentation/SegmentationItemsArray';
import { messageSegmentGet } from '../../services/messageApi';
import { createMessage } from '../../actions/messageActions';
import { contactTagAdd, contactTagCreate } from '../../services/tagApi';
import { contactToListAdd, listCreate } from '../../services/contactApi';
import { Checkbox } from '../../components/Field';
import { findSurveyElement } from '../../utils/segmentationHelper';

const SegmentationModal = ({
  open,
  closeModalPortal,
  lists,
  tags,
  message: {
    id,
    name,
    schema: {
      trackingId,
      forms,
    },
  },
  createMessageA,
  notifySuccessA,
  notifyErrorA,
}) => {
  const [tagsOpen, setTagsOpen] = useState(false);
  const [listsOpen, setListsOpen] = useState(false);
  const [tagValues, setTagValues] = useState([]);
  const [listValues, setListValues] = useState([]);
  const [segmentation, setSegmentation] = useState([{
    type: '',
    question: '',
    answer: '',
    messageOption: '',
  }]);
  const [recipients, setRecipients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [optionsDisabled, setOptionsDisabled] = useState(false);

  const getSegmentedRecipients = (payload) => {
    setLoading(true);

    messageSegmentGet(payload).then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
      } else {
        setRecipients(res.response);
      }
      setLoading(false);
    }).catch(() => setLoading(false));
  };

  const prepareSegmentationPayload = (seg) => {
    const property = 'contactId';
    const filters = [];
    forEach(seg, (segment) => {
      const {
        type,
        question,
        answer,
        messageOption,
      } = segment;
      if (type) {
        if (messageOption) {
          filters.push({
            trackingId,
            type: 'metric',
            value: messageOption === 'open' ? '1' : '0',
            measure: 'open',
            operation: messageOption === 'open' ? 'atLeast' : 'equal',
          });
        }
        if (question && answer) {
          const surveyId = findSurveyElement(forms, 'displayName', type).trackingId;
          filters.push({
            trackingId: surveyId,
            type: 'survey',
            value: answer,
            question,
            operation: 'equal',
          });
        }
      }
    });

    const payload = {
      id,
      body: { property, filters },
    };
    let equals = true;
    if (currentFilters.length !== filters.length) {
      equals = false;
    } else {
      for (let i = 0; i < filters.length; i += 1) {
        if (currentFilters[i].type !== filters[i].type
          || currentFilters[i].trackingId !== filters[i].trackingId
          || currentFilters[i].value !== filters[i].value
          || (currentFilters[i].question && currentFilters[i].question !== filters[i].question)) {
          equals = false;
          break;
        }
      }
    }
    if (!equals) {
      getSegmentedRecipients(payload);
      setCurrentFilters(filters);
    }
  };

  const handleTagChange = (values) => {
    setTagValues(values);
  };

  const handleListChange = (values) => {
    setListValues(values);
  };

  const addNewSegment = () => {
    setSegmentation([...segmentation, {
      type: '',
      question: '',
      answer: '',
      messageOption: '',
    }]);
  };

  const removeSegment = (removeIndex) => {
    remove(segmentation, (item, index) => index === removeIndex);
    prepareSegmentationPayload(segmentation);
    setSegmentation([...segmentation]);
  };

  const changeSegmentValue = (value, index, field) => {
    let disabled = false;
    if (field === 'type') {
      segmentation[index] = {
        type: value,
        question: '',
        answer: '',
        messageOption: '',
      };
    } else if (field === 'question') {
      segmentation[index] = {
        ...segmentation[index],
        question: value,
        answer: '',
      };
    } else if (field === 'messageOption' && value === 'notOpen') {
      const filter = [
        {
          type: 'message',
          question: '',
          answer: '',
          messageOption: value,
        },
      ];
      setSegmentation(filter);
      prepareSegmentationPayload(filter);
      disabled = true;
    } else {
      segmentation[index][field] = value;
    }
    if (!disabled) {
      prepareSegmentationPayload(segmentation);
      setSegmentation([...segmentation]);
    }
    setOptionsDisabled(disabled);
  };

  const linkListToContact = (listId, listName, temporary, show) => {
    const payload = {
      id: listId,
      body: {
        ids: recipients,
        excluded: [],
        filterId: '',
        listId: 0,
      },
    };

    return contactToListAdd(payload).then((res) => {
      if (res.error && show) {
        notifyErrorA(res.error.message);
      } else if (!res.error) {
        let message = `Contacts added to ${listName} list`;
        if (temporary) {
          message = `Contacts added to ${listName} temporary list`;
        }
        notifySuccessA(message);
      }
    });
  };

  const linkTagToContact = (tagId, tagName, show) => contactTagAdd(tagId,
    {
      ids: recipients,
      filterId: '',
      listId: 0,
      excluded: [],
    },
  ).then((res) => {
    if (res.error && show) {
      notifyErrorA(res.error.message);
    } else if (!res.error) {
      notifySuccessA(`Contacts tagged ${tagName}`);
    }
  });

  const handleAddClick = (newMessage) => () => {
    const promises = [];
    let show = true;
    if (tagsOpen && tagValues.length > 0) {
      forEach(tagValues, (tag) => {
        const { value, label } = tag;
        if (typeof value === 'number') {
          promises.push(linkTagToContact(value, label, show));
          show = false;
        } else {
          promises.push(contactTagCreate({
            name: label,
          }).then(({ response }) => {
            promises.push(linkTagToContact(response.id, label, show));
            show = false;
          }));
        }
      });
    }

    let listId;
    if (listsOpen && listValues.length > 0) {
      forEach(listValues, (li) => {
        const { value, label } = li;

        if (typeof value === 'string') {
          promises.push(listCreate({ name: label }).then((res) => {
            if (res.response && res.response.id) {
              const { id: resId } = res.response;
              promises.push(linkListToContact(resId, label, false, show));
              show = false;
              if (!listId) {
                listId = resId;
              }
            }
          }));
        } else {
          if (!listId) {
            listId = value;
          }
          promises.push(linkListToContact(value, label, false, show));
          show = false;
        }
      });
    }
    Promise.all(promises).then(() => {
      if (newMessage) {
        if (listId) {
          createMessageA({
            name: '',
            listId,
            origin: 'default',
            deliveryMethod: 'sms',
          });
          closeModalPortal();
        } else {
          const listName = `${name} Temporary List`;
          listCreate({ name: listName, temporal: true }).then((res) => {
            if (res.response && res.response.id) {
              const { id: resId } = res.response;
              linkListToContact(resId, listName, true, show);
              createMessageA({
                name: '',
                listId: resId,
                temporalListId: resId,
                origin: 'default',
                deliveryMethod: 'sms',
                tempCount: recipients?.length,
              });
              closeModalPortal();
            }
          });
        }
      }
    });
  };

  const handleChangeOpen = (type) => () => {
    if (type === 'lists') {
      if (listsOpen) {
        setListValues([]);
      }
      setListsOpen(!listsOpen);
    } else if (type === 'tags') {
      if (tagsOpen) {
        setTagValues([]);
      }
      setTagsOpen(!tagsOpen);
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModalPortal}
    >
      <Modal.Header>
        <div className="segmentation-modal__header">
          <span className="segmentation-modal__header__row">Filter Contacts</span>
        </div>
      </Modal.Header>
      <Modal.Content className="two-column-form">
        <div>
          <div>
            <SegmentationItemsArray
              forms={forms}
              segmentation={segmentation}
              addNewSegment={addNewSegment}
              removeSegment={removeSegment}
              changeSegmentValue={changeSegmentValue}
              recipients={recipients}
              optionsDisabled={optionsDisabled}
            />
          </div>
          <Divider />
          <div className="segmentation-modal__lists">
            <div className="segmentation-modal__lists__row">
              <div className="segmentation-modal__lists__row__header">
                <span className="segmentation-modal__lists__row__header__title">
                  {`${recipients.length} CONTACTS FILTERED`}
                </span>
              </div>
            </div>
            <div className="segmentation-modal__lists__row__smaller">
              <Checkbox
                input={{ value: listsOpen, onChange: handleChangeOpen('lists') }}
                meta={{ touched: undefined, error: undefined }}
              />
              <span
                className="segmentation-modal__lists__row__title"
                onClick={handleChangeOpen('lists')}
              >
                Add to List
              </span>
            </div>
            {listsOpen && (
              <>
                <div className="segmentation-modal__lists__row__smaller">
                  <span
                    className="segmentation-modal__lists__row__smaller__info segmentation-modal__lists__row__smaller__lists"
                  >
                    Add filtered contacts to a current or new list
                  </span>
                </div>
                <div className="segmentation-modal__lists__row">
                  <Creatable
                    data-tip="Do not forget to press ↵ Enter after typing the tag"
                    id="tagsInput"
                    validate={required}
                    isMulti={true}
                    isSearchable={false}
                    simpleValues={true}
                    options={mapListsToOptions(lists)}
                    placeholder="Select or create new list"
                    className="react-select tag segmentation-modal__lists__row__select"
                    value={listValues}
                    onChange={handleListChange}
                    multi
                  />
                </div>
              </>
            )}
            <div className="segmentation-modal__lists__row__smaller">
              <Checkbox
                input={{ value: tagsOpen, onChange: handleChangeOpen('tags') }}
                meta={{ touched: undefined, error: undefined }}
              />
              <span
                className="segmentation-modal__lists__row__title"
                onClick={handleChangeOpen('tags')}
              >
                Tag contacts
              </span>
            </div>
            {tagsOpen && (
              <>
                <div className="segmentation-modal__lists__row__smaller">
                  <span
                    className="segmentation-modal__lists__row__smaller__info segmentation-modal__lists__row__smaller__tags"
                  >
                    Add existing or new tag to filtered contacts
                  </span>
                </div>
                <div className="segmentation-modal__lists__row">
                  <Creatable
                    name="tagsInput"
                    data-tip="Do not forget to press ↵ Enter after typing the tag"
                    id="tagsInput"
                    validate={required}
                    isMulti={true}
                    simpleValues={true}
                    multi
                    options={mapListsToOptions(tags)}
                    placeholder="Select or create new tag"
                    className="react-select tag segmentation-modal__lists__row__select"
                    value={tagValues}
                    onChange={handleTagChange}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <BasicButton
          content="Cancel"
          size="small"
          className="white"
          onClick={closeModalPortal}
        />
        <BasicButton
          disabled={loading || recipients.length === 0
          || (tagValues.length === 0 && listValues.length === 0)}
          loading={loading}
          content="Finish"
          size="small"
          className="segmentation-finish-button white"
          onClick={handleAddClick(false)}
        />
        <BasicButton
          disabled={loading || recipients.length === 0}
          loading={loading}
          content="Message Contacts"
          size="small"
          color="blue"
          className="segmentation-button"
          onClick={handleAddClick(true)}
        />
      </Modal.Actions>
    </Modal>
  );
};

SegmentationModal.propTypes = {
  open: PropTypes.bool,
  closeModalPortal: PropTypes.func,
  lists: PropTypes.array,
  tags: PropTypes.object,
  message: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    schema: PropTypes.shape({
      trackingId: PropTypes.string,
      forms: PropTypes.array,
    }),
  }),
  createMessageA: PropTypes.func,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  tags: state.tags,
  lists: state.lists.elements,
});

const mapDispatchToProps = (dispatch) => ({
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  createMessageA: bindActionCreators(createMessage.request, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SegmentationModal);
