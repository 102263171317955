// New editor backwards compatibility solution to load old messages
// with new editor. We're creating array of elements that is passed to
// new editor context state to edit old messages with new editor.
// Data is collected from HTML (parsing) and from API schema data (mapping).
import htmlToDraft from 'html-to-draftjs';
import {
  ContentState,
  convertToRaw,
} from 'draft-js';
import { v4 as uuidv4 } from 'uuid';

// Constants.
import {
  BUTTON,
  VIDEO,
  FILE,
  IMAGE,
  TEXT,
  OPT_IN,
  SURVEY,
} from '../constants/widgetTypes';
import { defaultDropdownOptions } from '../constants/defaultDropdownOptions';
import { EMPTY_IMG_SRC } from '../../config';
import { INITIAL_EDITOR_CONTENT_RAW_STATE } from '../constants/textEditorRawState';

const dropdownOptions = defaultDropdownOptions.reduce(
  (acc, cur) => (
    {
      ...acc,
      [cur.datatype]: {
        id: cur.id,
        name: cur.name,
        value: cur.value,
        type: cur.type,
      },
    }
  ), {},
);

export const mapEditorData = (messageData) => {
  // Elements array from DOM
  const elementsStructureArr = [];
  // HTML DOM hierarchy array
  const elementsHierarchyArr = [];
  // Structured by hierarchy elements array
  const compatibleDataArr = [];

  // needed message JSON from API to start creating new editor compatible JSON
  if (messageData) {
  // text and picture element objects are created from parsing HTML
  const messageViewTemplate = document.getElementById('message-template');

  const textElements = messageViewTemplate.querySelectorAll('div[id^="text-"]');

  const pictureElements = messageViewTemplate.querySelectorAll('img[id^="picture-"]');

  const videoElements = document.querySelectorAll('div[id^="video-"]');

  const messageViewNode = messageViewTemplate.querySelectorAll('#message-view-wrapper')[0];

  const htmlNodes = messageViewNode?.getElementsByClassName('delete-border');

  htmlNodes?.forEach(
    (node) => {
      const id = node?.childNodes[1]?.id;
      const name = node?.childNodes[1]?.name;
      elementsHierarchyArr.push({ id, name });
    },
  );

  // handles survey elements
  if (messageData.schema.forms) {
    // API Data question namings are different than editor.
    // We need this object to identify questions.
    const questionTypes = {
      OpenQuestion: 'openQuestion',
      Like: 'likeDislike',
      Choices: 'multipleAnswers',
      SingleChoice: 'oneAnswer',
      Rating: 'rating',
      NPS: 'nps',
    };

    // handling survey widgets
    messageData.schema.forms.forEach((form) => {
      const formElement = messageViewTemplate.querySelectorAll(`form[name="${form.displayName}"]`)[0];
      const formButton = formElement.querySelectorAll('input[type="submit"]')[0];

      const surveyFeedback = formElement?.getAttribute('success-text');
      const showResponseResultsValue = formElement?.getAttribute('data-feedback');
      const showResponseResults = (showResponseResultsValue === 'true');

      const buttonText = formButton?.value;
      const buttonColor = formButton?.style.backgroundColor || '#012';

      const questionsArr = form.elements.map((element) => {
        const answerOptionsArr = element.choices && element.choices.map((choice) => {
          const answerChoice = {
            answerId: uuidv4(),
            title: choice.label,
            isCorrect: choice.correctQuizAnswer,
          };

          return answerChoice;
        });

        const questionObj = {
          questionId: uuidv4(),
          answerRequired: element.isRequired,
          type: questionTypes[element.elementType],
          questionTitle: element.label,
          quizMode: element.isQuiz,
          answerExample: element.placeholder,
          ratingValue: element.maxValue.toString(),
          answersOptions: answerOptionsArr || [],
        };

        return questionObj;
      });

      const identifier = elementsHierarchyArr.find((item) => item?.name === form?.displayName)?.id;

      const formObj = {
        identifier: identifier || form.displayName,
        elementType: SURVEY,
        id: uuidv4(),
        surveyName: form.displayName,
        surveyFeedback,
        buttonText,
        buttonColor,
        showResponseResults,
        questions: questionsArr,
      };

      elementsStructureArr.push(formObj);
    });
  }

  // handles picture elements
  if (pictureElements.length) {
    pictureElements.forEach((picture) => {
      const pictureObj = {
        identifier: picture.id,
        elementType: IMAGE,
        id: uuidv4(),
        src: picture.src,
        width: picture.style.width,
        alt: picture.alt,
      };

      elementsStructureArr.push(pictureObj);
    });
  }

  // handles text elements
  if (textElements.length) {
    textElements.forEach((item) => {
      const itemDraft = htmlToDraft(item.innerHTML);
      const { contentBlocks, entityMap } = itemDraft;

      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

      const textObj = {
        identifier: item.id,
        content: convertToRaw(contentState),
        id: uuidv4(),
        elementType: TEXT,
      };

      elementsStructureArr.push(textObj);
    });
  }

  // handles opt in elements
  // opt-in, button, survey uses partialy API data & HTML markup data
  if (messageData.schema.optIns) {
    messageData.schema.optIns.forEach((optInData) => {
      const optInNode = document.getElementById(optInData.displayName);

      const optInInputs = optInData.fields.map((field) => (
        { ...dropdownOptions[field.name], placeholder: field.placeholder }
      ));

      if (optInNode) {
        // Getting HTML nodes for attributes and text content.
        const headerNode = optInNode?.getElementsByTagName('header')[0];
        const footerNode = optInNode?.getElementsByTagName('footer')[0];
        const buttonNode = optInNode?.getElementsByClassName('body__submit-button')[0];

        const headerTextNode = optInNode?.getElementsByClassName('header__headline')[0].innerHTML;
        const bodyTextNode = optInNode?.getElementsByClassName('body__text')[0].innerHTML;
        const footerTextNode = optInNode?.getElementsByClassName('footer__text')[0].innerHTML;

        const checkboxNode = optInNode?.getElementsByClassName('body__checkbox')[0];
        const checkboxTextNode = checkboxNode.getElementsByTagName('label')[0].innerHTML;

        const headerTextNodeDraft = htmlToDraft(headerTextNode);
        const headerContentState = ContentState.createFromBlockArray(
        headerTextNodeDraft.contentBlocks, headerTextNodeDraft.entityMap);

        const bodyTextNodeDraft = htmlToDraft(bodyTextNode);
        const bodyContentState = ContentState.createFromBlockArray(
          bodyTextNodeDraft.contentBlocks, bodyTextNodeDraft.entityMap);

        const checkboxTextNodeDraft = htmlToDraft(checkboxTextNode);
        const checkboxContentState = ContentState.createFromBlockArray(
          checkboxTextNodeDraft.contentBlocks, checkboxTextNodeDraft.entityMap);

        const footerTextNodeDraft = htmlToDraft(footerTextNode);
        const footerContentState = ContentState.createFromBlockArray(
          footerTextNodeDraft.contentBlocks, footerTextNodeDraft.entityMap);

        const hiddenListInputNodeValue = optInNode?.querySelector('#listId').value || 0;
        const hiddenTagInputNodeValue = optInNode?.querySelector('#tagsId').value.split(',') || [];

        const isCheckboxHidden = checkboxNode.classList.contains('hidden');
        const isHeaderHidden = headerNode.classList.contains('hidden');
        const isFooterHidden = footerNode.classList.contains('hidden');

        // we can't get tag label from HTML so we're adding name
        // 'undefined-tag' to label and adding correct tag label
        // in OptInTagsController.jsx component.
        const tagValues = JSON.parse(`[${hiddenTagInputNodeValue}]`).map((item) => ({ value: item, label: 'undefined-tag' }));

        const optinObj = {
          identifier: optInData.displayName,
          elementType: OPT_IN,
          id: uuidv4(),
          data: {
            hiddenInputData: {
              list: Number(hiddenListInputNodeValue),
              tags: tagValues,
            },
            header: {
              isVisible: !isHeaderHidden,
              img: {
                src: optInData.header.logo || EMPTY_IMG_SRC,
                alt: 'No content',
                width: '100%',
              },
              content: convertToRaw(headerContentState) || INITIAL_EDITOR_CONTENT_RAW_STATE,
            },
            body: {
              content: convertToRaw(bodyContentState) || INITIAL_EDITOR_CONTENT_RAW_STATE,
            },
            inputFields: optInInputs,
            checkboxInput: {
              id: uuidv4(),
              isVisible: !isCheckboxHidden,
              content: convertToRaw(checkboxContentState) || INITIAL_EDITOR_CONTENT_RAW_STATE,
            },
            button: {
              text: optInData.button.text,
              bgColor: buttonNode.style.backgroundColor || '#036',
              textColor: buttonNode.style.color || '#fff',
            },
            footer: {
              isVisible: !isFooterHidden,
              content: convertToRaw(footerContentState) || INITIAL_EDITOR_CONTENT_RAW_STATE,
            },
          },
        };

        elementsStructureArr.push(optinObj);
      }
    });
  }

  // handles button elements
  if (messageData.schema.buttons) {
    messageData.schema.buttons.forEach((item) => {
      const buttonFontSize = messageViewTemplate.querySelectorAll(`#${item.id}`)[0]?.style.fontSize;

      const buttonObj = {
        identifier: item.id,
        elementType: BUTTON,
        id: uuidv4(),
        bgColor: item.backgroundColor,
        link: item.linkTo,
        textColor: item.textColor,
        size: buttonFontSize,
        text: item.innerText,
      };

      elementsStructureArr.push(buttonObj);
    });
  }

  // handles video elements
  if (messageData.schema.videos) {
    const videoNodesIdArr = [];
    videoElements.forEach((node) => videoNodesIdArr.push(node.id));

    messageData.schema.videos.forEach((video, index) => {
      let videoData;
      if (video.location.includes('youtube')) {
        videoData = {
          changedKey: uuidv4(),
          sources: [
            {
              src: video.location,
              type: 'video/youtube',
            },
          ],
          techOrder: ['youtube'],
          youtube: { ytControls: 0 },
        };
      } else {
        videoData = {
          changedKey: uuidv4(),
          sources: [
            {
              src: video.location,
              type: 'video/mp4',
            },
          ],
        };
      }

      // Video data hierarchy from API is same as rendered in DOM
        const videoObj = {
          identifier: videoNodesIdArr[index],
          elementType: VIDEO,
          id: uuidv4(),
          data: videoData,
        };

        elementsStructureArr.push(videoObj);
      },
    );
  }
}

  elementsHierarchyArr.forEach((item) => {
    const foundItem = elementsStructureArr.find((el) => el.identifier === item?.id);
    const itemExists = compatibleDataArr.find((el) => el.identifier === item?.id);

    if (foundItem && !itemExists) {
      compatibleDataArr.push(foundItem);
    }
  });

  // schema.unsubscribe can be true/false or null if there's still no html saved for message
  const isUnsubscribe = !!messageData?.schema?.unsubscribe;


  return { compatibleDataArr, isUnsubscribe };
};

export const mapFileData = (messageData) => {
  if (messageData.schema.attachments) {
    const attachmentsJson = messageData.schema.attachments.map((file) => ({
        fileName: file.displayName,
        href: file.fileLocation,
        id: uuidv4(),
      }));

      const attachmentsObj = {
        files: attachmentsJson,
        id: uuidv4(),
        elementType: FILE,
      };

      return attachmentsObj;
  }

  return false;
};
