import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { mapSegmentationTypesToOptions } from '../../../utils/segmentationHelper';
import SegmentationItemOptions from './SegmentationItemOptions';

const SegmentationItem = ({
  forms,
  item,
  index,
  removeSegment,
  changeSegmentValue,
}) => (
  <div className="segmentation-row">
    <div
      className="segmentation-row__delete"
      onClick={() => removeSegment(index)}
    />
    <div className="segmentation-row__options">
      <Dropdown
        className="segmentation-row__options__first-column"
        placeholder="Select Data"
        value={item.type}
        options={mapSegmentationTypesToOptions(forms, index)}
        onChange={(event, data) => changeSegmentValue(data.value, index, 'type')}
      />
      {item.type && (
        <SegmentationItemOptions
          forms={forms}
          item={item}
          index={index}
          changeSegmentValue={changeSegmentValue}
        />
      )}
    </div>
  </div>
);

SegmentationItem.propTypes = {
  forms: PropTypes.array,
  item: PropTypes.object,
  index: PropTypes.number,
  removeSegment: PropTypes.func,
  changeSegmentValue: PropTypes.func,
};

export default SegmentationItem;
