import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v4 as uuidv4 } from 'uuid';

// Actions.
import { genericActions, notificationActions } from '../../../actions';

// Components.
import TextInput from '../../components/TextInput';
import WidgetControllerHeader from '../../components/WidgetControllerHeader';
import WidgetVideoUI from '../../widgetComponents/WidgetVideoUI';
import { BasicButton } from '../../../components/Button';

// Modals.
import { S3UploadModal, MediaSelectModal } from '../../../containers/Modal';

// Compositions.
import WidgetControllerFooter from '../../compositions/WidgetControllerFooter';

// Context.
import { EditorContext } from '../../context/EditorContext';

// Helpers.
import { getExtByUrl } from '../../../utils/helper';

// Services.
import { api } from '../../../services';

// Styles.
import './VideoWidgetController.scss';

const { openModalPortal, closeModalPortal } = genericActions;
const { notifyError } = notificationActions;

const { S3: { uploadS3Media, uploadSignedFile } } = api;

const VideoWidgetController = ({
  openModalPortalA,
  closeModalPortalA,
  notifyErrorA,
}) => {
  const {
    replaceElement,
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const setVideoProperties = (videoData, videoType) => {
    const dataObj = { ...activeElement };
    const changedKey = uuidv4();

    if (videoType === 'youtube') {
      const youtubeVideoDataObj = {
        changedKey,
        sources: [
          { src: videoData, type: 'video/youtube' },
        ],
        techOrder: ['youtube'],
        youtube: { ytControls: 0 },
        videoEmbeddable: 'true',
      };
      dataObj.data = youtubeVideoDataObj;
      setActiveElement(dataObj);
    } else {
      const { mediaUrl } = videoData;
      const validVideoFileExtensions = ['mp4', 'mov'];
      const mediaFileExtension = mediaUrl.split('.').pop();

      if (validVideoFileExtensions.includes(mediaFileExtension)) {
        const videoDataProperties = {
          changedKey,
          sources: [
            { src: mediaUrl },
          ],
        };
        dataObj.data = videoDataProperties;
        setActiveElement(dataObj);
      } else {
        notifyErrorA('Video must be .mp4 or .mov format');
      }
    }
  };

  const openMediaLibrary = () => {
    openModalPortalA({
      content: <MediaSelectModal />,
      contentProps: {
        closeModalPortal: () => {
          closeModalPortalA();
        },
        size: 'fullscreen',
        type: 'video',
        modalName: 'Upload Video',
        handleMediaSelect: setVideoProperties,
      },
    });
  };

  const uploadVideo = (fileBase64, fileName, type) => {
    const fileExt = getExtByUrl(fileName);

    uploadS3Media(fileExt).then((res) => {
      const url = new URL(res.response.url);
      const loc = url.origin + url.pathname;
      uploadSignedFile(fileBase64, res.response.url, fileExt).then(() => {
        setTimeout(() => setVideoProperties({ mediaUrl: loc }), 500);
        closeModalPortalA();
      }).catch(() => {
        closeModalPortalA();
        notifyErrorA('Error while uploading signed file');
      });
    }).catch(() => {
      closeModalPortalA();
      notifyErrorA('Error while signing file to s3');
    });
  };

  const openUploadModal = () => {
    openModalPortalA({
      content: <S3UploadModal />,
      contentProps: {
        closeModalPortal: () => {
          closeModalPortalA();
        },
        onUpload: uploadVideo,
        accept: ['video/mp4', 'video/quicktime'],
        modalName: 'Upload Video',
      },
    });
  };

  const onSaveButtonClick = () => {
    replaceElement(activeElement);
    setActiveElement(false);
  };

  const onCancelButtonClick = () => {
    setActiveElement(false);
  };

  return (
    <div className="VideoWidgetController">
      <WidgetControllerHeader title="Video settings" />
      <div className="VideoWidgetController__controller-body">
        <div className="VideoWidgetController__video-player-wrapper">
          <WidgetVideoUI
            index={0}
            isController
            {...activeElement}
          />
        </div>
        <div className="VideoWidgetController__input-wrapper">
          <TextInput
            name="link"
            type="text"
            label="Youtube link:"
            onChange={(e) => setVideoProperties(e.target.value, 'youtube')}
            placeholder="https://"
            value={activeElement.data.youtube ? activeElement.data.sources[0].src : ''}
          />
        </div>
        <div className="VideoWidgetController__button-wrapper">
          <p className="VideoWidgetController__label-text">or upload video</p>
          <BasicButton
            onClick={openUploadModal}
            size="small"
            content="Browse"
            color="blue"
            className="invert"
          />
          <p className="VideoWidgetController__label-text">
            mp4, mov
          </p>
        </div>
        <div className="VideoWidgetController__button-wrapper">
          <p>or choose from</p>
          <BasicButton
            onClick={openMediaLibrary}
            content="Media library"
            size="small"
            color="blue"
            className="invert"
          />
        </div>
      </div>
      <WidgetControllerFooter
        onSaveButtonClick={() => onSaveButtonClick()}
        onCancelButtonClick={() => onCancelButtonClick()}
      />
    </div>
  );
};

VideoWidgetController.propTypes = {
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  notifyErrorA: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default connect(null, mapDispatchToProps)(VideoWidgetController);
