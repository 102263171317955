import { put, call, takeLatest } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import { find, omit, isEmpty } from 'lodash';
import { notificationActions, genericActions } from '../actions';
import { asyncErrorMapper } from '../utils/map';

const { notifyError, notifySuccess } = notificationActions;
const {
  goToPage,
  GO_TO_PAGE,
  clearReduxStateEntity,
  clearPaginator,
} = genericActions;

function* formSubmissionErrorHandler(errors, formSubmission) {
  // Checking if error response structure if array, if it is object,
  // then we are pushing it to empty array and calling the same generator
  // function with formatted array object
  if (!Array.isArray(errors)) {
    const errorsArray = [];
    errorsArray.push(errors);
    yield call(formSubmissionErrorHandler, errorsArray, formSubmission);
  }

  if (errors && errors.code && errors.message) {
    yield put(notifyError(errors.message));
  } else {
    // Throwing submission error
    const formErrors = new SubmissionError({ ...asyncErrorMapper(errors) });
    yield put(formSubmission.failure(formErrors));
  }
}

function* apiCallResponseHandler(response, error, action, extraParam = '') {
  if (error === undefined) {
    yield put(action.success(response, extraParam));
  } else {
    yield put(action.failure(error));
    if (extraParam === 'userAuthorisation') {
      window.location.href = `${window.location.origin}/login`;
    }
  }
}

function* formSubmissionHandler(response, error, formSubmission, extraParam) {
  if (error === undefined) {
    yield put(formSubmission.success({ ...response, extraParam }));
  } else {
    yield call(formSubmissionErrorHandler, error, formSubmission);
  }
}

/**
 * @param actionObject - action object ( request, success, fail )
 * @param service - call method
 * @param data - params
 * @param formSubmissionObject - createFormSunmissionSaga
 * @extraParam - request parameter
 * @returns {IterableIterator<*>}
 */
export function* requestSaga(actionObject, service, data, formSubmissionObject, extraParam) {
  const body = omit(data, 'type');
  const { response, error } = yield call(service, body.payload);

  if (formSubmissionObject) {
    yield call(formSubmissionHandler, response, error, formSubmissionObject, extraParam);
  } else {
    yield call(apiCallResponseHandler, response, error, actionObject, extraParam);
  }
}

export function* showGlobalError(args) {
  const { error: serviceError, payload } = args;
  const globalError = find(serviceError, { property: '' });

  if (globalError) {
    yield put(notifyError(globalError?.message || 'Something went wrong... Please try again later.'));
  }

  if (serviceError && serviceError.code && serviceError.message) {
    yield put(notifyError(serviceError?.message || 'Something went wrong... Please try again later.'));
  }

  if (!globalError && !serviceError && isEmpty(payload)) {
    yield put(notifyError('Something went wrong... Please try again later.'));
  }
}

export function* showSuccessMessage(message) {
  yield put(notifySuccess(message));
}

export function* watchPageRedirection(history) {
  yield takeLatest(GO_TO_PAGE, goToPage, history);
}

export function* clearReduxStateEntitySaga(entityName) {
  yield put(clearReduxStateEntity(entityName));
}

export function* clearPaginatorSaga(paginatorName) {
  yield put(clearPaginator(paginatorName));
}
