import PropTypes from 'prop-types';

const Icon = ({
  name,
  onClick,
}) => (
  <span className={name} onClick={onClick} />
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default Icon;
