import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components.
import CreateElementButton from '../../components/CreateElementButton';

// Constants
import { FILE } from '../../constants/widgetTypes';

// Context.
import {
  EditorContext,
} from '../../context/EditorContext';

const FileUploadWidget = () => {
  const {
    setActiveElement,
    addAttachments,
    attachments,
  } = useContext(EditorContext);

  const createFileDownloadElement = () => {
    if (attachments) {
      setActiveElement(attachments);
    } else {
      const elementId = uuidv4();
      const defaultElementPropertiesObj = {
        elementType: FILE,
        id: elementId,
        files: [],
      };

      addAttachments(defaultElementPropertiesObj);
      setActiveElement(defaultElementPropertiesObj);
    }
  };

  return (
    <CreateElementButton
      title="FILE"
      iconName="attach_file"
      onClick={() => createFileDownloadElement()}
    />
  );
};

export default FileUploadWidget;
