import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Menu } from 'semantic-ui-react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import Icon from '../Icon/Icon';
import { TABLET_MAX_WIDTH } from '../../common';
import { genericActions } from '../../actions';
import { AccountSettingsModal } from '../../containers/Modal';
import { useFeature } from '../../hooks';
import { getPlanFeatures } from '../../utils/plans';
import { featuresKeys } from '../../common/plans';

const { openModalPortal, closeModalPortal } = genericActions;

const FooterMainFixed = ({
  user,
  openModalPortalA,
  closeModalPortalA,
  features,
}) => {
  const showAutomation = useFeature(featuresKeys.featureKeys.automation, features);
  const { push } = useHistory();

  const openUserSettingsModal = () => {
    openModalPortalA({
      content: <AccountSettingsModal />,
      contentProps: {
        handleFormModalState: () => closeModalPortalA(),
        size: window.innerWidth < TABLET_MAX_WIDTH ? 'fullscreen' : 'small',
      },
    });
  };

  const handleModal = () => {
    openUserSettingsModal();
  };

  const redirectUsers = () => {
    if (user.role === 'admin' || user.role === 'root') {
      push('/account-settings');
    } else if (user.role === 'user') {
      handleModal();
    }
  };

  return (
    <div className="main-fixed">
      <Menu
        widths={showAutomation ? 5 : 4}
        borderless
        size="massive"
        icon="labeled"
      >
        <Menu.Item
          name="messages"
          onClick={() => push('/messages')}
        >
          <Icon name="footer-icon messages" />
          Messages
        </Menu.Item>
        {showAutomation && (
          <Menu.Item
            name="automation"
            onClick={() => push('/automation')}
          >
            <Icon name="footer-icon automation" />
            Automation
          </Menu.Item>
        )}
        <Menu.Item
          name="contacts"
          onClick={() => push('/contacts')}
        >
          <Icon name="footer-icon contacts" />
          Contacts
        </Menu.Item>
        <Menu.Item
          name="mediaLibrary"
          onClick={() => push('/media-library')}
        >
          <Icon name="footer-icon media-library" />
          Media Library
        </Menu.Item>
        <Menu.Item
          name="account"
          onClick={() => redirectUsers()}
        >
          <Icon name="footer-icon account" />
          Account
        </Menu.Item>
      </Menu>
    </div>
  );
};

FooterMainFixed.propTypes = {
  user: PropTypes.object,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  features: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
});

const mapStateToProps = (state) => ({
  user: state.user,
  features: getPlanFeatures(state.plans.session),
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterMainFixed);
