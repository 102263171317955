import { Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { ContactTagForm } from '../Form';

const ContactTagModal = (props) => {
  const { open, closeModalPortal, type } = props;
  return (
    <Modal
      className="modal modal--tag"
      open={open}
      closeOnDocumentClick={true}
      onClose={() => {
        closeModalPortal();
      }}
      dimmer="inverted"
    >
      <Modal.Header>{type === 'remove' ? 'Remove tags' : 'Add tags'}</Modal.Header>
      <Modal.Content>
        <ContactTagForm {...props} />
      </Modal.Content>
    </Modal>
  );
};

ContactTagModal.propTypes = {
  closeModalPortal: PropTypes.func,
  props: PropTypes.array,
  type: PropTypes.string,
  open: PropTypes.bool,
};

export default ContactTagModal;
