import moment from 'moment';
import { ColumnComponent } from './index';
import { DATE } from '../../common';

const PaymentHistoryTableColumns = (onDownloadInvoiceClick) => ([
  {
    Header: 'Payment Date',
    accessor: 'paymentDate',
    Cell: (row) => (
      <ColumnComponent>
        <span>{moment(row.value).format(DATE)}</span>
      </ColumnComponent>
    ),
  },
  {
    Header: 'Purchase',
    accessor: 'purchase_type',
    Cell: (row) => {
      let type = '';
      switch (row.value) {
        case 'sms_credit':
          type = 'SMS credits';
          break;
        case 'user':
          type = 'Users';
          break;
        case 'subscription':
          type = 'Subscriptions';
          break;
        default:
          break;
      }
      return (
        <ColumnComponent>
          <span>{type}</span>
        </ColumnComponent>
      );
    },
  },
  {
    Header: 'Subscription period',
    accessor: 'subscriptionPeriod',
    Cell: (row) => {
      const dateFrom = row.original.subscriptionPeriodStart
        ? moment(row.original.subscriptionPeriodStart).format(DATE) : '';
      const dateTo = row.original.subscriptionPeriodEnd
        ? moment(row.original.subscriptionPeriodEnd).format(DATE) : '';
      let validDate = true;
      if (moment(row.original.subscriptionPeriodEnd).isBefore(moment('1900-01-01'))) {
        validDate = false;
      }
      return (
        <ColumnComponent>
          <span>{validDate ? `${dateFrom} - ${dateTo}` : '\u2015'}</span>
        </ColumnComponent>
      );
    },
  },
  {
    Header: 'Payment method',
    accessor: 'paymentMethod',
    Cell: (row) => (
      <ColumnComponent>
        <span>{row.value}</span>
      </ColumnComponent>
    ),
  },
  {
    Header: 'Card number',
    accessor: 'cardNumber',
    Cell: (row) => (
      <ColumnComponent>
        <span>{`Ending with ${row.value}`}</span>
      </ColumnComponent>
    ),
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: (row) => {
      let currencyMark = '$';
      if (row.original.currency !== 'usd') {
        currencyMark = row.original.currency;
      }
      return (
        <ColumnComponent>
          <span>{`${currencyMark} ${row.value / 100}`}</span>
        </ColumnComponent>
      );
    },
  },
  {
    Header: 'Status',
    accessor: 'paid',
    Cell: (row) => (
      <ColumnComponent>
        <span>{row.value ? 'Paid' : 'Not paid'}</span>
      </ColumnComponent>
    ),
  },
  {
    Header: 'Action',
    accessor: 'id',
    Cell: (row) => (
      <ColumnComponent>
        {row.original.s3Key && (
          <i
            className="material-icons"
            onClick={(e) => {
              e.stopPropagation();
              onDownloadInvoiceClick(row.original.s3Key);
            }}
          >
            file_download
          </i>
        )}
      </ColumnComponent>
    ),
  },
]);

export default PaymentHistoryTableColumns;
