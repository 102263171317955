import PropTypes from 'prop-types';

const EditorToolbarButton = ({
  icon,
  text,
  handleTap,
  isDragging,
  connectDragSource,
}) => (
  connectDragSource(
    <button type="button" onClick={handleTap} className={isDragging ? 'toolbar-item disabled dragging' : 'toolbar-item'} onDragEnd={handleTap}>
      <i className="material-icons widget-icon">{icon}</i>
      <p className="text">{text}</p>
    </button>,
  )
);


EditorToolbarButton.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  handleTap: PropTypes.func,
  isDragging: PropTypes.bool,
  connectDragSource: PropTypes.func,
};

export default EditorToolbarButton;
