import S3 from 'aws-sdk/clients/s3';
import AWS from 'aws-sdk/global';
import { callApi } from './callApi';

import {
  SECRET_ACCESS_KEY,
  ACCESS_KEY, TOKEN,
  AWS_REGION,
  S3_BUCKET,
} from '../config';
import { getUrlProperty, getServicePath, getFullServicePath } from '../utils/helper';

export const call = (endpoint, params, _schema) => callApi({ serviceName: getServicePath('fileproxy'), endpoint }, params, _schema);

export const initializeS3 = () => (new S3({
  region: AWS_REGION,
  credentials: new AWS.Credentials(ACCESS_KEY, SECRET_ACCESS_KEY, TOKEN),
}));

// get media
export const listS3Media = ({ type = 'video', page = 1, count = 10 }) => {
  const url = `/list?count=${count}&page=${page}&type=${type}`;
  const queryParams = getUrlProperty('query', url);

  return call('/list', {
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};

export const uploadSignedFile = (file, signedRequest, ext) => (
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest, true);

    if (ext === 'mp4') {
      xhr.setRequestHeader('Content-Type', 'video/mp4');
    }

    if (ext === 'mov') {
      xhr.setRequestHeader('Content-Type', 'video/quicktime');
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send(new Blob([file, { type: 'image/jpg' }]));
  })
);

export const uploadS3Media = (ext) => {
  const url = getFullServicePath('fileproxy', `/upload?extension=${ext}`);
  const queryParams = getUrlProperty('query', url);

  return call('/upload', {
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};


export const uploadFileToS3 = (file, fileName, type) => {
  const buffer = Buffer.from(file, 'base64');
  const params = {
    Body: buffer,
    Bucket: S3_BUCKET,
    Key: fileName,
    ContentEncoding: 'base64',
    ContentType: type,
  };
  const s3 = initializeS3();

  return s3.putObject(params).promise();
};

export const deleteObject = ({ title }) => call(`/delete/${title}`, {
  method: 'DELETE',
}, null);

export const deleteMultipleObjects = (body) => call('/delete', {
  method: 'POST',
  body,
}, null);

export const getS3FileHeadObject = (Bucket, Key) => {
  const params = {
    Bucket,
    Key,
  };
  const s3 = initializeS3();

  return s3.headObject(params).promise();
};
