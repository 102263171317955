import { get, omit } from 'lodash';
import { messagesPaginator } from '../paginators';
import {
  FETCH_MESSAGE,
  DELETE_MESSAGE,
  SAVE_MESSAGE_DELIVERY_DATA,
  UPDATE_MESSAGE_DESIGN,
  CLEAR_MESSAGES,
} from '../actions/messageActions';
import { SUCCESS } from '../actions/request';

const messages = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MESSAGE[SUCCESS]:
    case SAVE_MESSAGE_DELIVERY_DATA[SUCCESS]:
    case UPDATE_MESSAGE_DESIGN: {
      return {
        ...state,
        [get(action, 'response.id')]: action.response,
      };
    }
    case DELETE_MESSAGE[SUCCESS]: {
      const { editor: { payload } } = action;
      return omit(state, payload);
    }
    case CLEAR_MESSAGES: {
      return {};
    }
    default: {
      const { meta } = action;

      if (meta && meta.name === 'messages' && messagesPaginator) {
        return messagesPaginator.itemsReducer(state, action);
      }
      return state;
    }
  }
};

export default messages;
