import PropTypes from 'prop-types';
import { Accordion } from 'semantic-ui-react';
import StatsBlock from '../Stats/StatsBlock';
import Donut from '../Chart/Donut';

const ButtonInfo = ({
  downloadRate,
  numOfDownloads,
  numOfUniqueDownloads,
  activeFileIndex,
  onClick,
  index,
  displayName,
}) => (
  <Accordion fluid styled className="statistics-accordion">
    <Accordion.Title active={activeFileIndex[index]} onClick={onClick}>
      {displayName}
    </Accordion.Title>
    <Accordion.Content active={activeFileIndex[index]} className="message-statistics">
      <div className="overall-statistics__segment-container">
        <div className="overall-statistics__box overall-statistics__segment">
          <div className="overall-statistics__box__header">Clicked</div>
          <div className="overall-statistics__box__content">
            <StatsBlock
              title="Playbacks"
              stats={numOfDownloads}
              color="blue"
            />
          </div>

        </div>
        <div className="overall-statistics__box overall-statistics__segment">
          <div className="overall-statistics__box__header">Click Rate</div>
          <div className="overall-statistics__box__content">
            <>
              {numOfUniqueDownloads !== 0 ? (
                <Donut
                  label={[
                    'Unique clicks',
                    'Did not click',
                  ]}
                  percent={downloadRate * 100}
                  borderColor="#fff"
                  data={[
                    numOfUniqueDownloads,
                    (numOfUniqueDownloads / downloadRate) - numOfUniqueDownloads,
                  ]}
                  backgroundColor={[
                    '#1cb59f',
                    '#e0e0e0',
                  ]}
                  defaultFontColor="#000"
                />
              ) : (
                <div className="overall-statistics__box__content--disabled">
                  No data yet
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </Accordion.Content>
  </Accordion>
);

ButtonInfo.propTypes = {
  activeFileIndex: PropTypes.array,
  onClick: PropTypes.func,
  numOfDownloads: PropTypes.number,
  numOfUniqueDownloads: PropTypes.number,
  downloadRate: PropTypes.number,
  index: PropTypes.number,
  displayName: PropTypes.string,
};

export default ButtonInfo;
