import { memo } from 'react';
import PropTypes from 'prop-types';
import { capitalize, isEmpty, isEqual } from 'lodash';

import StatusBlock from './StatusBlock';
import { timestampToDate } from '../../../utils/helper';
import { getPlanStatus } from '../../../utils/plans';

const DisplayStatus = ({
  session,
}) => {
  if (isEmpty(session)) {
    return null;
  }

  const {
    cancelAtPeriodEnd,
    trialEndsAt,
    subscription: { activePricing, periodEnd },
    used: { monthlyParams: { sms } },
    quotePending,
    warning,
  } = session;
  const defaultSmsCount = activePricing[0].monthlyParams.sms;

  const status = getPlanStatus(session);
  const plan = capitalize(activePricing[0].product);

  switch (status) {
    case 'legacy':
      return <StatusBlock header="Your plan is outdated" body="Please select a new plan" headerWarning bodyWarning quotePending={quotePending} />;

    case 'free': {
      const trialEnds = timestampToDate(trialEndsAt * 1000);
      const body = [
        `Plan expires: ${trialEnds}`,
        'Upgrade your plan to fully use',
      ];

      return (
        <StatusBlock
          header="Plan type: Free trial"
          body={body}
          bodyWarning
          quotePending={quotePending}
          warning={warning}
        />
      );
    }

    case 'free_expired':
      return (
        <StatusBlock
          header="Free trial expired"
          body="Upgrade to continue using Rispons. Pick one the the plans below"
          headerWarning
          bodyWarning
          quotePending={quotePending}
          warning={warning}
        />
      );

    case 'canceled': {
      const planEnds = timestampToDate(cancelAtPeriodEnd * 1000);
      const body = (
        <div className="date">
          Billing cycle ends:
          {' '}
          <span>{planEnds}</span>
        </div>
      );

      return (
        <StatusBlock
          header={`Plan type: ${plan} (Canceled)`}
          body={body}
          sms={{ defaultSmsCount, sms }}
          quotePending={quotePending}
          warning={warning}
        />
      );
    }

    case 'active_plan': {
      const body = (
        <div className="date">
          Billing cycle ends:
          {' '}
          <span>{timestampToDate(periodEnd.substring(0, 10))}</span>
        </div>
      );

      return (
        <StatusBlock
          header={`Plan type: ${plan}`}
          body={body}
          sms={{ defaultSmsCount, sms }}
          quotePending={quotePending}
          warning={warning}
        />
      );
    }

    default:
      return (
        <StatusBlock
          header={`${plan} plan expired`}
          body="To continue using Rispons pick one the the plans below"
          quotePending={quotePending}
          warning={warning}
          headerWarning
          bodyWarning
        />
      );
  }
};

DisplayStatus.propTypes = {
  session: PropTypes.shape({
    cancelAtPeriodEnd: PropTypes.number,
    trialEndsAt: PropTypes.number,
    subscription: PropTypes.shape({
      activePricing: PropTypes.arrayOf(PropTypes.shape({
        product: PropTypes.string,
        monthlyParams: PropTypes.shape({
          sms: PropTypes.number,
        }),
      })),
      periodEnd: PropTypes.string,
    }),
    used: PropTypes.shape({
      monthlyParams: PropTypes.shape({
        sms: PropTypes.number,
      }),
    }),
    quotePending: PropTypes.bool,
    warning: PropTypes.string,
  }),
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps.session, nextProps.session);

export default memo(DisplayStatus, areEqual);
