import { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import { Notification } from '../../components/Notification';
import { ConfirmationModal, ModalPortal } from '../Modal';
import { HeaderEditor } from '../Header';
import { FooterEditor } from '../../components/Footer';
import { MessageContextProvider } from '../../context';
import { withMessage } from '../../context/messageEditor';
import { EditorContextProvider } from '../../editor/context/EditorContext';

const FooterWithContext = withMessage(FooterEditor);

const EditorLayout = (props) => {
  const [currentStep, setCurrentStep] = useState('design');

  const setCurrentStepLayout = (step) => setCurrentStep(step);

  return (
    <div className="app-main">
      <Notification />
      <ConfirmationModal />
      <div className="app-content__editor">
        <EditorContextProvider>
          <MessageContextProvider setCurrentStepLayout={setCurrentStepLayout}>
            <ModalPortal />
            <HeaderEditor />
            {props.children && cloneElement(props.children, props)}
            { currentStep !== 'contacts' ? <FooterWithContext currentStep={currentStep} /> : <div /> }
          </MessageContextProvider>
        </EditorContextProvider>
      </div>
    </div>
  );
};

EditorLayout.propTypes = {
  children: PropTypes.element,
};

export default EditorLayout;
