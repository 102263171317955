import { callApi } from './callApi';

export const call = (endpoint, params, _schema) => callApi({ serviceName: 'form', endpoint }, params, _schema);

export const fetchRecipientSurveys = (body) => {
  if (body && body.id) {
    return call(`/surveys/${body.id}/${body.filteredUserId}`, {
      method: 'GET',
    }, null);
  }
  return false;
};
