import {
  find,
  forEach,
  map,
  sortBy,
} from 'lodash';

export const mapSegmentationTypesToOptions = (forms, index) => {
  if (index === 0) {
    return [
      { value: 'message', text: 'Message' },
    ];
  }
  const array = [];
  forEach(forms, (form) => {
    if (find(form.elements, (item) => item.elementType === 'Like' || item.elementType === 'Choices'
      || item.elementType === 'SingleChoice' || item.elementType === 'NPS')) {
      array.push({ value: form.displayName, text: `Survey "${form.displayName}"` });
    }
  });
  return array;
};

export const mapSurveyQuestionsToOptions = (questions) => {
  const array = [];
  forEach(questions, (element) => {
    if (element.elementType === 'Like' || element.elementType === 'Choices' || element.elementType === 'SingleChoice' || element.elementType === 'NPS') {
      array.push({ value: element.inputName, text: `Question "${element.label}"` });
    }
  });
  return sortBy(array, (element) => Number(element.value.split('-')[0]));
};

export const mapQuestionChoicesToOptions = (choices) => map(choices,
  (choice) => ({ value: `${choice.inputValue}`, text: `Answer "${choice.label}"` }));

export const findSurveyElement = (elements, fieldName, name) => find(elements,
  (element) => element[fieldName] === name);
