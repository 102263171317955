import { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { notifyError } from '../../actions/notificationActions';
import BillingInformation from '../../components/Register/Billing/BillingInformation';
import { getCountryName, getOptionalLabelByCountry } from '../../utils/helper';

const BillingInformationModal = ({
  countryCode,
  registerWithBilling,
  closeModalSubmitting,
  closeModalPortal,
  open,
  notifyErrorA,
  formValues,
}) => {
  const [cardCompleted, setCardCompleted] = useState(false);
  const [getSecret, setGetSecret] = useState(false);
  const [clientSecret, setClientSecret] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(undefined);
  const [country, setCountry] = useState(getCountryName(countryCode));
  const [label, setLabel] = useState(getOptionalLabelByCountry(country));

  const onFormSubmit = (params) => {
    const updatedValues = params.country ? { ...params } : { ...params, country };
    if (clientSecret) {
      registerWithBilling(updatedValues, clientSecret, formValues);
      closeModalSubmitting();
    } else {
      setLoading(true);
      setGetSecret(true);
      setValues(updatedValues);
    }
  };

  const sendSecret = (clientSecretValue) => {
    registerWithBilling(values, clientSecretValue, formValues);
    closeModalSubmitting();
  };

  const checkCardValidity = () => setCardCompleted(true);

  const handleCountryChange = (newCountry, change) => {
    setCountry(newCountry);
    setLabel(getOptionalLabelByCountry(newCountry));
    change('country', newCountry);
  };

    return (
      <Modal
        open={open}
        onClose={closeModalPortal}
        size="small"
      >
        <Modal.Header>
          <div className="segmentation-modal__header">
            <span className="segmentation-modal__header__row">You&#39;re Almost There!</span>
            <span className="segmentation-modal__header__info">Enter your billing information to complete your registration</span>
          </div>
        </Modal.Header>
        <Modal.Content>
          <BillingInformation
            getSecret={getSecret}
            sendSecret={sendSecret}
            checkCardValidity={checkCardValidity}
            onFormSubmit={onFormSubmit}
            closeModalPortal={closeModalPortal}
            cardCompleted={cardCompleted}
            loading={loading}
            country={country}
            handleCountryChange={handleCountryChange}
            label={label}
            notifyErrorA={notifyErrorA}
          />
        </Modal.Content>
      </Modal>
    );
};

BillingInformationModal.propTypes = {
  open: PropTypes.bool,
  closeModalPortal: PropTypes.func,
  registerWithBilling: PropTypes.func,
  closeModalSubmitting: PropTypes.func,
  countryCode: PropTypes.string,
  notifyErrorA: PropTypes.func,
  formValues: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default connect(null, mapDispatchToProps)(BillingInformationModal);
