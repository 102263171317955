import PropTypes from 'prop-types';
import { map } from 'lodash';
import NPSSelected from './NPSSelected';
import HalfDonut from '../../Chart/HalfDonut';

const NPSAnalytics = ({
  metric: {
    value,
    numOfRespondents,
  },
}) => {
  let promoters = 0;
  let passives = 0;
  let detractors = 0;
  for (let i = 0; i < 11; i += 1) {
    if (value[i]) {
      if (i < 7) {
        detractors += value[i];
      } else if (i < 9) {
        passives += value[i];
      } else {
        promoters += value[i];
      }
    }
  }
  const promotersRating = numOfRespondents ? Math.round((promoters * 100) / numOfRespondents) : 0;
  const passivesRating = numOfRespondents ? Math.round((passives * 100) / numOfRespondents) : 0;
  const detractorsRating = numOfRespondents ? Math.round((detractors * 100) / numOfRespondents) : 0;
  const rating = numOfRespondents ? promotersRating - detractorsRating : 0;
  const dataRating = rating <= 0 ? 100 - Math.abs(rating) : 200 - (100 - rating);
  return (
    <div className="survey-stats__nps">
      <div className="survey-stats__nps__chart">
        <HalfDonut
          data={[
            dataRating,
            200 - dataRating,
          ]}
          backgroundColor={[
            '#1cb59f',
            '#e0e0e0',
          ]}
          borderColor="#fff"
        />
        <div className="survey-stats__nps__chart__details">
          <span className="survey-stats__nps__chart__details__-100">-100</span>
          <span className="survey-stats__nps__chart__details__100">100</span>
          <span className="survey-stats__nps__chart__details__-50">-50</span>
          <span className="survey-stats__nps__chart__details__0">0</span>
          <span className="survey-stats__nps__chart__details__50">50</span>
          <div className="survey-stats__nps__chart__details__information">
            <span>NPS</span>
            <span>{`${numOfRespondents} answers`}</span>
          </div>
          <div className="survey-stats__nps__chart__details__rating">
            {rating > 0 ? `+${rating}` : rating}
            {rating !== 0 && (
              <span className="survey-stats__nps__chart__details__rating--hidden">{rating > 0 ? '+' : '-'}</span>
            )}
          </div>
        </div>
      </div>
      <div className="survey-stats__nps__answers">
        <div className="survey-stats__nps__answers__detractors">
          <div className="survey-stats__nps__answers__rating">
            {`Detractors ${detractorsRating}%`}
          </div>
          <div className="survey-stats__nps__answers__choices">
            {map(Array(7).fill(0), (_, index) => (
              <NPSSelected value={value[index] || 0} index={index} key={index} />
            ))}
          </div>
        </div>
        <div className="survey-stats__nps__answers__passives">
          <div className="survey-stats__nps__answers__rating">
            {`Passives ${passivesRating}%`}
          </div>
          <div className="survey-stats__nps__answers__choices">
            {map(Array(2).fill(0), (_, index) => (
              <NPSSelected value={value[7 + index] || 0} index={7 + index} key={7 + index} />
            ))}
          </div>
        </div>
        <div className="survey-stats__nps__answers__promoters">
          <div className="survey-stats__nps__answers__rating">
            {`Promoters ${promotersRating}%`}
          </div>
          <div className="survey-stats__nps__answers__choices">
            {map(Array(2).fill(0), (_, index) => (
              <NPSSelected value={value[9 + index] || 0} index={9 + index} key={9 + index} />
            ))}
          </div>
        </div>
        <span className="survey-stats__nps__answers__info">answers</span>
      </div>
    </div>
  );
};

NPSAnalytics.propTypes = {
  metric: PropTypes.shape({
    value: PropTypes.object,
    numOfRespondents: PropTypes.number,
  }),
};

export default NPSAnalytics;
