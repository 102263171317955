import { normalize } from 'normalizr';
import { get } from 'lodash';

import {
  API_ROOT, EDGE_ROOT, buildPathTemplate, AWS_REGION,
} from '../config';

const apigClientFactory = require('aws-api-gateway-client').default;

/**
 * @param service {[object]} - { serviceName, endpoint, fullUrl(optional- for pagination) }
 * @param params {[object]} - { method, endpoint, body(optional) }
 * @param _schema {[object]} - schema(optional)
 * @param host {[string]} - string (optional)
 */
export const callApi = (service, params, _schema, host) => {
  const sessionTimeout = localStorage.getItem('sessionTimeout');
  const credentials = JSON.parse(get(localStorage, 'credentials', '{}'));

  if (sessionTimeout) {
    return { response: {} };
  }
  // Building path template
  const serviceName = get(service, 'serviceName', '');
  const endpoint = get(service, 'endpoint', '');
  let pathTemplate = get(params, 'pathTemplate', '');

  pathTemplate = buildPathTemplate(serviceName, endpoint, pathTemplate);
  // Defining API gateway client
  const apigClient = apigClientFactory.newClient({
    invokeUrl: host ? EDGE_ROOT : API_ROOT,
    accessKey: get(credentials, 'AccessKeyId', ''),
    secretKey: get(credentials, 'SecretAccessKey', ''),
    sessionToken: get(credentials, 'SessionToken', ''),
    region: AWS_REGION,
  });
  // Assigining additional params if defined
  const additionalParams = params.additionalParams ? { ...params.additionalParams } : {};

  return apigClient.invokeApi({}, pathTemplate, params.method, additionalParams, params.body)
    .then((_response) => {
      let response = _response.data;
      // Normalizing response if schema is defined
      if (_schema) {
        response = normalize(response, _schema);
      }
      // Resolving SUCCESS response
      return Promise.resolve(response);
    })
    // Rejecting FAILURE response
    .catch((error) => {
      if (error?.request?.response) {
        return Promise.reject(JSON.parse(get(error, 'request.response', '')));
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: 'Something went wrong... Please try again later.' });
  })
    .then(
      // Resolving final response:
      // response for resolved response
      // error for rejected response
      (response) => ({ response }),
      (error) => ({ error }),
    );
};
