import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

const PopupContent = ({
  options: propOptions,
  onChecked,
  surveysIndexes,
  buttonsIndexes,
  videosIndexes,
  attachmentsIndexes,
}) => {
  const [options, setOptions] = useState([...propOptions]);

  useEffect(() => {
    if (propOptions) {
      setOptions([...propOptions]);
    }
  }, [propOptions]);

  const indeterminateOptionSelected = (o, selected, startIndex, endIndex) => {
    const oo = o;

    for (let i = startIndex + 1; i < endIndex; i += 1) {
      oo[i].isSelected = selected;
    }

    return oo;
  };

  const checkSelection = (o, startIndex, endIndex) => {
    const selected = {
      allSelected: true,
      noneSelected: true,
    };

    for (let i = startIndex + 1; i < endIndex; i += 1) {
      if (o[i].isSelected) {
        selected.noneSelected = false;
      } else {
        selected.allSelected = false;
      }
    }

    return selected;
  };

  const contentChecked = (o, index, startIndex, endIndex) => {
    const oo = o;
    let selected = {
      allSelected: true,
      noneSelected: true,
    };
    if (index === startIndex) {
      selected.allSelected = !!oo[startIndex].isSelected;

      indeterminateOptionSelected(oo, oo[startIndex].isSelected,
        startIndex, endIndex);
    } else {
      selected = checkSelection(oo, startIndex, endIndex);
    }

    if (selected.allSelected) {
      oo[startIndex].isSelected = true;
      oo[startIndex].indeterminate = false;
    } else if (selected.noneSelected) {
      oo[startIndex].isSelected = false;
      oo[startIndex].indeterminate = false;
    } else {
      oo[startIndex].isSelected = false;
      oo[startIndex].indeterminate = true;
    }

    return options;
  };

  const checkboxClickedHandler = (index) => () => {
    options[index].isSelected = !options[index].isSelected;

    if (index >= surveysIndexes.start && index < surveysIndexes.end) {
      contentChecked(options, index, surveysIndexes.start, surveysIndexes.end);
    } else if (index >= videosIndexes.start && index < videosIndexes.end) {
      contentChecked(options, index, videosIndexes.start, videosIndexes.end);
    } else if (index >= buttonsIndexes.start && index < buttonsIndexes.end) {
      contentChecked(options, index, buttonsIndexes.start, buttonsIndexes.end);
    } else if (index >= attachmentsIndexes.start && index < attachmentsIndexes.end) {
      contentChecked(options, index, attachmentsIndexes.start, attachmentsIndexes.end);
    }

    let selected = {
      allSelected: true,
      noneSelected: true,
    };

    if (index === 0) {
      selected.allSelected = !!options[0].isSelected;

      indeterminateOptionSelected(options, options[0].isSelected,
        0, options.length);
      if (surveysIndexes.start !== -1) {
        options[surveysIndexes.start].indeterminate = false;
      }
      if (videosIndexes.start !== -1) {
        options[videosIndexes.start].indeterminate = false;
      }
      if (buttonsIndexes.start !== -1) {
        options[buttonsIndexes.start].indeterminate = false;
      }
      if (attachmentsIndexes.start !== -1) {
        options[attachmentsIndexes.start].indeterminate = false;
      }
    } else {
      selected = checkSelection(options, 0, options.length);
    }

    if (selected.allSelected) {
      options[0].isSelected = true;
      options[0].indeterminate = false;
    } else if (selected.noneSelected) {
      options[0].isSelected = false;
      options[0].indeterminate = false;
    } else {
      options[0].isSelected = false;
      options[0].indeterminate = true;
    }

    onChecked([...options]);
    setOptions([...options]);
  };

  return (
    options.map(
      (option, index) => (
        <div
          className={`checkbox-field ${option.className}`}
          key={index}
          onClick={checkboxClickedHandler(index)}
        >
          <Checkbox
            name={option.value}
            checked={option.isSelected}
            indeterminate={option.indeterminate}
          />
          <span>{option.label}</span>
        </div>
      ),
    )
  );
};

PopupContent.propTypes = {
  onChecked: PropTypes.func,
  options: PropTypes.array,
  surveysIndexes: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }),
  videosIndexes: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }),
  buttonsIndexes: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }),
  attachmentsIndexes: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }),
};

export default PopupContent;
