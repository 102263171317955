import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Notification } from '../../components/Notification';
import { HeaderAnonymous } from '../Header';
import { ModalPortal } from '../Modal';

const AnonymousLayout = (props) => {
  const { children } = props;
  return (
    <div className="app-main">
      <HeaderAnonymous />
      <Notification />
      <ModalPortal />
      <div className="app-content__anonymous">
        {children && cloneElement(children, props)}
      </div>
    </div>
  );
};

AnonymousLayout.propTypes = {
  children: PropTypes.element,
};

export default AnonymousLayout;
