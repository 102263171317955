import { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { BasicButton, IconButton } from '../../components/Button';
import { messageGetStaticStatus, messageTogglePublish } from '../../services/messageApi';
import { notifySuccess } from '../../actions/notificationActions';
import { MessageContext } from '../../context/messageEditor';


function DeliveryUrlForm({ changePublishedState }) {
  const [isPublished, setIsPublished] = useState(false);
  const [url, setUrl] = useState('');
  const [loading, setIsloading] = useState(false);
  const { handleSave } = useContext(MessageContext);

  const dataLoaded = url !== '';

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setIsloading(true);
      const res = await messageGetStaticStatus(id);
      setIsPublished(res.response.live);
      changePublishedState(res.response.live);
      setUrl(res.response.url);
      setIsloading(false);
    })();
  }, [id]);

  const sendTogglePublish = async () => {
    const action = isPublished ? 'unpublish' : 'publish';
    setIsloading(true);
    const res = await messageTogglePublish({ id, action });
    setIsPublished(res.response.live);
    setUrl(res.response.url);
    setIsloading(false);
    handleSave();
    changePublishedState(res.response.live);
  };

  const clipCurrentURL = () => {
    if (url) {
      navigator.clipboard.writeText(url);
      dispatch(notifySuccess('Static link copied succesfully'));
    }
  };

  return (
    <div className="delivery-method-url">
      <section className="delivery-settings">
        <div className={`status status${isPublished ? '--live' : '--draft'}`}>
          <div className="status__label">Status:</div>
          {!loading && dataLoaded && (
            <div className="status__badge">
              <div className="status__icon"></div>
              <div className="status__text">{isPublished ? 'LIVE' : 'DRAFT'}</div>
            </div>
          )}
        </div>
        <BasicButton
          className="tertiary no-focus"
          content={isPublished ? 'Unpublish link' : 'Publish link'}
          type="button"
          onClick={sendTogglePublish}
          loading={loading}
          disabled={loading}
        />
      </section>
      <section className="delivery-url-output">
        <div className="url__label">URL</div>
        <Input
          className="url__field"
          readOnly={isPublished}
          disabled={!isPublished}
          value={url}
          onChange={(e) => { setUrl(e.target.value); }}
          name="static-url"
          action={(
            <IconButton
              iconName="content_copy"
              text="Copy link"
              type="button"
              disabled={!isPublished}
              onClick={clipCurrentURL}
            />
            )}
        />
      </section>
    </div>
  );
}

DeliveryUrlForm.propTypes = {
  changePublishedState: PropTypes.func,
};

export default DeliveryUrlForm;
