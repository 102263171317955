import { createFormAction } from 'redux-form-saga';
import { paymentHistoryPaginator, usersPaginator, accountsPaginator } from '../paginators';
import { createRequestTypes } from './request';
import { DEFAULT_PAGE_SIZE } from '../containers/Table';
import { ENV } from '../config';

const { API_ENV } = ENV;

const action = (type, payload = {}) => ({ type, ...payload });

export const FETCH_PAYMENT_HISTORY = createRequestTypes('FETCH_PAYMENT_HISTORY');
export const FETCH_USER_ACCESS = createRequestTypes('FETCH_USER_ACCESS');
export const FETCH_ALL_USERS = createRequestTypes('FETCH_ALL_USERS');
export const FETCH_ACCOUNT = createRequestTypes('FETCH_ACCOUNT');
export const FETCH_ACCOUNT_BY_ID = createRequestTypes('FETCH_ACCOUNT_BY_ID');
export const FETCH_ACCOUNT_USER = createRequestTypes('FETCH_ACCOUNT_USER');
export const FETCH_PAYMENT_INVOICE = createRequestTypes('FETCH_PAYMENT_INVOICE');
export const BLOCK_ACCOUNT = createRequestTypes('BLOCK_ACCOUNT');
export const DELETE_ACCOUNT = createRequestTypes('DELETE_ACCOUNT');
export const INVITE_USER = createRequestTypes('INVITE_USER');
export const CREATE_ACCOUNT = createRequestTypes('CREATE_ACCOUNT');
export const COUNT_CREDITS = createRequestTypes('CREATE_ACCOUNT');

export const FETCH_IDENTITIES = 'FETCH_IDENTITIES';
export const DELETE_IDENTITY = 'DELETE_IDENTITY';
export const SET_BILLING = 'SET_BILLING';


export const updateAccount = createFormAction('UPDATE_ACCOUNT');
export const updateUser = createFormAction('UPDATE_USER');
export const updateBilling = createFormAction('UPDATE_BILLING');

export const getAccountUser = {
  request: () => action(FETCH_ACCOUNT_USER.REQUEST),
  success: (response) => action(FETCH_ACCOUNT_USER.SUCCESS, response),
  failure: (error) => action(FETCH_ACCOUNT_USER.FAILURE, error),
};

export const getAccount = {
  request: () => action(FETCH_ACCOUNT.REQUEST),
  success: (response) => action(FETCH_ACCOUNT.SUCCESS, response),
  failure: (error) => action(FETCH_ACCOUNT.FAILURE, error),
};

export const fetchAccountById = {
  request: (body) => action(FETCH_ACCOUNT_BY_ID.REQUEST, body),
  success: (response) => action(FETCH_ACCOUNT_BY_ID.SUCCESS, response),
  failure: (error) => action(FETCH_ACCOUNT_BY_ID.FAILURE, error),
};

export const fetchAllUsers = {
  request: () => action(FETCH_ALL_USERS.REQUEST),
  success: (response) => action(FETCH_ALL_USERS.SUCCESS, response),
  failure: (error) => action(FETCH_ALL_USERS.FAILURE, error),
};

export const requestPaymentHistoryPage = (pageNo, pageSize = DEFAULT_PAGE_SIZE, id) => (
  id
    ? paymentHistoryPaginator.payments.requestPage(pageNo, `plan/${API_ENV}/payment/invoice/history/${id}?count=${pageSize}`)
    : paymentHistoryPaginator.payments.requestPage(pageNo, `plan/${API_ENV}/payment/invoice/history?count=${pageSize}`)
);

export const requestUsersPage = (pageNo, pageSize = DEFAULT_PAGE_SIZE, id) => (
  id
    ? usersPaginator.users.requestPage(pageNo, `account/${API_ENV}/account/users/${id}?count=${pageSize}`)
    : usersPaginator.users.requestPage(pageNo, `account/${API_ENV}/account/users?count=${pageSize}`)
);

export const requestAccountsPage = (pageNo, pageSize = DEFAULT_PAGE_SIZE) => (
  accountsPaginator.accounts.requestPage(pageNo, `account/${API_ENV}/accounts?count=${pageSize}`)
);

export const requestModifiedUsersPage = (pageNo, pageSize = DEFAULT_PAGE_SIZE) => (
  usersPaginator.users.requestModifiedPage(pageNo, `account/${API_ENV}/account/users?count=${pageSize}`)
);

export const requestModifiedAccountsPage = (pageNo, pageSize = DEFAULT_PAGE_SIZE) => (
  accountsPaginator.accounts.requestModifiedPage(pageNo, `account/${API_ENV}/accounts?count=${pageSize}`)
);

export const blockAccount = {
  request: (body) => action(BLOCK_ACCOUNT.REQUEST, body),
  success: (response) => action(BLOCK_ACCOUNT.SUCCESS, response),
  failure: (error) => action(BLOCK_ACCOUNT.FAILURE, error),
};

export const deleteAccount = {
  request: (body) => action(DELETE_ACCOUNT.REQUEST, body),
  success: (response) => action(DELETE_ACCOUNT.SUCCESS, response),
  failure: (error) => action(DELETE_ACCOUNT.FAILURE, error),
};

export const inviteUser = {
  request: (body) => action(INVITE_USER.REQUEST, body),
  success: (response) => action(INVITE_USER.SUCCESS, response),
  failure: (error) => action(INVITE_USER.FAILURE, error),
};

export const createAccount = {
  request: (body) => action(CREATE_ACCOUNT.REQUEST, body),
  success: (response, body) => action(CREATE_ACCOUNT.SUCCESS, { response, body }),
  failure: (error) => action(CREATE_ACCOUNT.FAILURE, error),
};

export const smsCreditCount = {
  request: (body) => action(COUNT_CREDITS.REQUEST, body),
  success: (response, body) => action(COUNT_CREDITS.SUCCESS, { response, body }),
  failure: (error) => action(COUNT_CREDITS.FAILURE, error),
};

export const fetchIdentities = (body) => action(FETCH_IDENTITIES, body);

export const deleteIdentity = (body) => action(DELETE_IDENTITY, body);

export const setBilling = (payload) => action(SET_BILLING, payload);
