import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import CurvedPath from './CurvedPath';
import InsertNewNode from './InsertNewNode';
import {
  TEXT_HEIGHT,
  TEXT__WIDTH,
  CURVE_HEIGHT,
  CURVED_PATH_ENUM,
  ADD_ICON_HEIGHT,
  ADD_ICON_WIDTH,
} from '../../../common/automation';

const AutomationEdge = ({
  edge: {
    nodeId,
    nodeTop,
    nodeLeft,
    name,
    showName,
    parentId,
    parentTop,
    parentLeft,
    labelLeft,
    hasSiblings,
  },
  handleAddNode,
  editingDisabled,
}) => {
  const getPath = () => {
    const node = hasSiblings ? nodeTop - ADD_ICON_HEIGHT : nodeTop;
    const parent = hasSiblings ? parentTop : parentTop + ADD_ICON_HEIGHT;
    if (nodeLeft === parentLeft) {
      return (
        <path
          className="edge__path"
          d={`M ${nodeLeft} ${node} L ${parentLeft} ${parent}`}
        />
      );
    }
    if (nodeLeft > parentLeft) {
      return (
        <>
          <CurvedPath
            x={parentLeft}
            y={parent}
            type={CURVED_PATH_ENUM.BOTTOM_RIGHT}
          />
          <path
            className="edge__path"
            d={`M ${nodeLeft - CURVE_HEIGHT} ${node - CURVE_HEIGHT} L ${parentLeft + CURVE_HEIGHT} ${parent + CURVE_HEIGHT}`}
          />
          <CurvedPath
            x={nodeLeft - CURVE_HEIGHT}
            y={node - CURVE_HEIGHT}
            type={CURVED_PATH_ENUM.TOP_LEFT}
          />
        </>
      );
    }
    return (
      <>
        <CurvedPath
          x={parentLeft - CURVE_HEIGHT}
          y={parent}
          type={CURVED_PATH_ENUM.BOTTOM_LEFT}
        />
        <path
          className="edge__path"
          d={`M ${nodeLeft + CURVE_HEIGHT} ${node - CURVE_HEIGHT} L ${parentLeft - CURVE_HEIGHT} ${parent + CURVE_HEIGHT}`}
        />
        <CurvedPath
          x={nodeLeft}
          y={node - CURVE_HEIGHT}
          type={CURVED_PATH_ENUM.TOP_RIGHT}
        />
      </>
    );
  };

  const {
    x,
    y,
    addX,
    addY,
    Path,
  } = useMemo(() => {
    const middleX = labelLeft - ((TEXT__WIDTH) / 2);
    const middleY = ((nodeTop + parentTop - TEXT_HEIGHT) / 2);
    const addIconX = hasSiblings
      ? nodeLeft - (ADD_ICON_WIDTH / 2) : parentLeft - (ADD_ICON_WIDTH / 2);
    const addIconY = hasSiblings ? nodeTop - ADD_ICON_HEIGHT : parentTop;

    return {
      x: middleX,
      y: hasSiblings ? middleY - (ADD_ICON_HEIGHT / 2) : middleY + (ADD_ICON_HEIGHT / 2),
      addX: addIconX,
      addY: addIconY,
      Path: getPath(),
    };
  }, [
    nodeTop,
    nodeLeft,
    parentTop,
    parentLeft,
    labelLeft,
    hasSiblings,
  ]);

  return (
    <>
      {Path}
      {showName && (
        <foreignObject
          x={x}
          y={y}
          width={TEXT__WIDTH}
          height={TEXT_HEIGHT}
        >
          <div className="edge__text">
            <Popup
              trigger={(
                <span>{name}</span>
              )}
              on={['click', 'hover']}
              position="right center"
              style={{ textAlign: 'center' }}
              basic
              inverted
            >
              <Popup.Content>
                <span>{name}</span>
              </Popup.Content>
            </Popup>
          </div>
        </foreignObject>
      )}
      <foreignObject
        x={addX}
        y={addY}
        width={ADD_ICON_WIDTH}
        height={ADD_ICON_HEIGHT}
      >
        {editingDisabled ? (
          <span className="edge__insert__path edge__insert__path--disabled" />
        ) : (
          <InsertNewNode
            hasSiblings={hasSiblings}
            parentId={parentId}
            childId={nodeId}
            handleAddNode={handleAddNode}
          />
        )}
      </foreignObject>
    </>
  );
};

AutomationEdge.propTypes = {
  edge: PropTypes.shape({
    nodeId: PropTypes.number,
    nodeTop: PropTypes.number,
    nodeLeft: PropTypes.number,
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    showName: PropTypes.bool,
    parentId: PropTypes.number,
    parentTop: PropTypes.number,
    parentLeft: PropTypes.number,
    labelLeft: PropTypes.number,
    hasSiblings: PropTypes.bool,
  }),
  handleAddNode: PropTypes.func,
  editingDisabled: PropTypes.bool,
};

export default AutomationEdge;
