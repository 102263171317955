import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import ValidationInstructions from '../IdentityItem/ValidationInstructions';
import { domainTypeOptions } from '../../../../common/options';

const VerifyDomainType = ({
  handleTypeChange,
  type,
  selectDisabled,
  element,
}) => (
  <div className="verify-domain-type">
    <div className="verify-domain-type__label">
      Set up domain for:
    </div>
    <Dropdown
      options={domainTypeOptions}
      onChange={(param, data) => handleTypeChange(data.value)}
      placeholder="Select domain type"
      className="app-form__field verify-domain-type__select"
      value={type}
      disabled={selectDisabled}
    />
    <div className="verify-domain-type__info">
      To verify your domain you need to add two DNS records in your domain
      provider&apos;s cPanel or Zone Editor. After you created the record,
      press &quot;Finish&quot;. The validation can take up to 48 h.
    </div>
    {element && (
      <ValidationInstructions element={element} modal />
    )}
  </div>
);

VerifyDomainType.propTypes = {
  handleTypeChange: PropTypes.func,
  type: PropTypes.string,
  selectDisabled: PropTypes.bool,
  element: PropTypes.shape({
    identityType: PropTypes.string,
  }),
};

export default VerifyDomainType;
