import PropTypes from 'prop-types';
import { Accordion } from 'semantic-ui-react';
import { find } from 'lodash';
import StatsBlock from './StatsBlock';
import Donut from '../Chart/Donut';
import { sortQuestions } from '../../utils/sort';
import {
  LikeAnalytics,
  OpenQuestionAnalytics,
  ChoicesAnalytics,
  RatingAnalytics,
  NPSAnalytics,
} from './SurveyTypes';

const SurveyInfo = ({
  activeFormIndex,
  onClick,
  index,
  data,
  schema,
}) => (
  <Accordion fluid styled className="statistics-accordion">
    <Accordion.Title active={activeFormIndex[index]} onClick={onClick}>
      {schema.displayName}
    </Accordion.Title>
    <Accordion.Content active={activeFormIndex[index]} className="message-statistics">
      <div className="overall-statistics__segment-container">
        <div className="overall-statistics__box overall-statistics__segment">
          <div className="overall-statistics__box__header">
            Responses
          </div>
          <div className="overall-statistics__box__content">
            <StatsBlock
              title="Playbacks"
              stats={data.numOfRespondents}
              color="blue"
            />
          </div>
        </div>
        <div className="overall-statistics__box overall-statistics__segment">
          <div className="overall-statistics__box__header">
            Response rate
          </div>
          <div className="overall-statistics__box__content">
            <>
              {data.numOfRespondents !== 0 ? (
                <Donut
                  label={[
                    'Unique responses',
                    'Did not respond',
                  ]}
                  percent={data.responseRate * 100}
                  borderColor="#fff"
                  data={[
                    data.numOfRespondents,
                    (data.numOfRespondents / data.responseRate) - data.numOfRespondents,
                  ]}
                  backgroundColor={[
                    '#1cb59f',
                    '#e0e0e0',
                  ]}
                  not={data.numOfRespondents - data.numOfRespondents}
                />
              ) : (
                <div className="overall-statistics__box__content--disabled">
                  No data yet
                </div>
              )}
            </>
          </div>
        </div>
      </div>
      {sortQuestions(schema.elements)
        .map((element, i) => {
          if ((element.elementType === 'Choices' || element.elementType === 'SingleChoice')
            && element.choices.length < 2) {
            return null;
          }
          const { inputName } = element;
          const metric = find(data.metrics, (m) => m.inputName === inputName);
          return (
            <div key={i} className="overall-statistics__box survey__container survey-stats">
              <div className="survey-stats__header">
                <span className="overall-statistics__box__header">
                  {`Question ${i + 1}`}
                </span>
                <span className="survey-stats__header--total">
                  {`${metric.numOfRespondents} responses`}
                </span>
              </div>
              <div className="survey-stats__question-head">
                {element.label}
              </div>
              <div className="overall-statistics__box__content">
                {element.elementType === 'OpenQuestion' && (
                  <OpenQuestionAnalytics value={metric.value} />
                )}
                {element.elementType === 'Like' && (
                  <LikeAnalytics values={metric.value} />
                )}
                {(element.elementType === 'Choices' || element.elementType === 'SingleChoice') && element.choices.length > 1 && (
                  <ChoicesAnalytics element={element} metric={metric} />
                )}
                {element.elementType === 'Rating' && (
                  <RatingAnalytics metric={metric} element={element} />
                )}
                {element.elementType === 'NPS' && (
                  <NPSAnalytics metric={metric} />
                )}
              </div>
            </div>
          );
        })}
    </Accordion.Content>
  </Accordion>
);

SurveyInfo.propTypes = {
  activeFormIndex: PropTypes.array,
  onClick: PropTypes.func,
  index: PropTypes.number,
  data: PropTypes.shape({
    numOfRespondents: PropTypes.number.isRequired,
    responseRate: PropTypes.number.isRequired,
    metrics: PropTypes.arrayOf(PropTypes.shape({
      inputName: PropTypes.string,
      numOfRespondents: PropTypes.number,
      value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.object,
      ]),
    })).isRequired,
  }),
  schema: PropTypes.object,
};

export default SurveyInfo;
