import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Grid, Header } from 'semantic-ui-react';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import { LoginForm, MultipleAccountsForm } from '../Form';
import { accountAccept } from '../../services/accountsApi';
import { decodeJwtToken, getUrlProperty } from '../../utils/helper';
import { authentication } from '../../services/authApi';
import { authActions, notificationActions, accountActions } from '../../actions';

const { verify } = authActions;
const { getAccountUser } = accountActions;
const { notifyError, notifySuccess } = notificationActions;

const Login = ({
  verifyA,
  notifySuccessA,
  notifyErrorA,
  getAccountUserA,
  multipleAccounts,
  loginFormValues,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const queryParams = getUrlProperty('query');
    const accept = get(queryParams, 'accept');
    const email = get(queryParams, 'email');
    const token = get(queryParams, 'token');
    const sessionTimeout = localStorage.getItem('sessionTimeout');

    if (email && token) {
      const payload = { email, token };
      verifyA({ payload });
    }

    if (accept) {
      const decodedToken = accept ? decodeJwtToken(accept) : undefined;
      const { companyName } = decodedToken;
      accountAccept(accept).then(() => {
        notifySuccessA(`You have successfully accepted invite from ${companyName}`);
      }).catch(() => 'Invitation accept failed.');
    }

    if (sessionTimeout) {
      notifyErrorA('Your session has expired');
      setTimeout(() => localStorage.removeItem('sessionTimeout'), 2000);
    }
  }, []);

  useEffect(() => {
    const count = multipleAccounts ? Object.keys(multipleAccounts).length : 0;

    if (count === 1) {
      setLoading(true);
      const account = Object.keys(multipleAccounts)[0];

      authentication({ ...loginFormValues, account }).then((res) => {
        if (!res.error) {
          localStorage.setItem('credentials', res.response.credentials);
          getAccountUserA();
        } else {
          setLoading(false);
          notifyErrorA(res.error.message);
        }
      });
    }
  }, [multipleAccounts, loginFormValues]);

  return (
    <Grid>
      <Grid.Column className="ris-form">
        <Grid.Row>
          <Header size="large" className="app-form__header centered">
            Login
          </Header>
        </Grid.Row>
        {multipleAccounts && Object.keys(multipleAccounts).length > 1 ? (
          <MultipleAccountsForm accounts={multipleAccounts} />
        ) : (
          <LoginForm loading={loading} />
        )}
      </Grid.Column>
    </Grid>
  );
};

Login.propTypes = {
  multipleAccounts: PropTypes.object,
  loginFormValues: PropTypes.object,
  verifyA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  notifySuccessA: PropTypes.func,
  getAccountUserA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  multipleAccounts: get(state, 'user.multipleAccounts', undefined),
  loginFormValues: getFormValues('login')(state),
});

const mapDispatchToProps = (dispatch) => ({
  verifyA: bindActionCreators(verify.request, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  getAccountUserA: bindActionCreators(getAccountUser.request, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
