import { Menu } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router';

const HeaderNavigation = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  return (
    <Menu
      className="header-navigation"
      widths={3}
      borderless
      size="massive"
    >
      <Menu.Item
        name="Users"
        active={pathname === '/admin/accounts' || pathname === '/'}
        onClick={() => push('/admin/accounts')}
      >
        Users
      </Menu.Item>
      <Menu.Item
        name="Plans"
        active={pathname === '/admin/plans'}
        onClick={() => push('/admin/plans')}
      >
        Plans
      </Menu.Item>
    </Menu>
  );
};

export default HeaderNavigation;
