import { map } from 'lodash';
import { DOMAIN_INFO_CONTENT } from '../../../common/domainInfoContent';

const DomainInfo = () => (
  <div className="domain__info">
    <div className="domain__info__header">
      <div className="domain__info__header__title">DOMAIN</div>
      <div className="domain__info__header__domain">Default: rispons.com</div>
    </div>
    {map(DOMAIN_INFO_CONTENT, (block, index) => (
      <div key={index} className="domain__info__section">
        <div className="domain__info__section__title">
          {block.header}
        </div>
        <div className="domain__info__section__content">
          {block.content}
        </div>
      </div>
    ))}
  </div>
);

export default DomainInfo;
