import { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { Button, Modal, Dropdown } from 'semantic-ui-react';
import { notifyError, notifySuccess } from '../../actions/notificationActions';
import { automationNodeDelete, automationFilterNodeDelete } from '../../services/automationApi';
import { AutomationContext } from '../../context/AutomationContext';
import { SUBJECT_TYPES } from '../../common/automation';

const DeleteNodeModal = ({
  open,
  close,
  node,
  notifyErrorA,
  notifySuccessA,
}) => {
  const [branch, setBranch] = useState();

  const [loading, setLoading] = useState(false);
  const { getNodes } = useContext(AutomationContext);

  const additionalChoices = useMemo(() => node.actionType === 'filter' && node.pathList.length > 1, [node]);
  const pathOptions = useMemo(() => map(node.pathList,
    (path) => ({ text: path.name, value: path.name })), [node]);

  const successfulDelete = () => {
    notifySuccessA('Node successfully deleted');
    getNodes(true);
    close();
  };

  const callback = (res) => {
    if (res.error) {
      notifyErrorA(res.error.message);
      setLoading(false);
    } else {
      successfulDelete();
    }
  };

  const confirmDelete = () => {
    setLoading(true);
    if (additionalChoices) {
      automationFilterNodeDelete({ id: node.id, branch }).then(callback);
    } else {
      automationNodeDelete(node.id).then(callback);
    }
  };

  const handleKeepNodeChange = (e, { value }) => setBranch(value);

  return (
    <Modal
      open={open}
      onClose={close}
      dimmer="inverted"
      className="delete-node-modal"
    >
      <Modal.Header>
        Delete node
      </Modal.Header>
      <Modal.Description>{`Are you sure you want to delete '${node.name}' node?`}</Modal.Description>
      {additionalChoices && (
        <Modal.Content>
          <>
            <div>
              Choose a path to keep. Other paths will be deleted.
            </div>
            <Dropdown
              className="node-type-select"
              options={pathOptions}
              placeholder="Select a path to keep"
              value={branch}
              onChange={handleKeepNodeChange}
            />
          </>
        </Modal.Content>
      )}
      <Modal.Actions>
        <Button
          className="white"
          onClick={close}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={confirmDelete}
          disabled={loading || (additionalChoices && !branch)}
          className="red"
          loading={loading}
        >
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

DeleteNodeModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  node: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    actionType: PropTypes.string,
    actionSubject: PropTypes.string,
    inputs: PropTypes.object,
    pathList: PropTypes.array,
  }),
  notifyErrorA: PropTypes.func,
  notifySuccessA: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
});

export default connect(null, mapDispatchToProps)(DeleteNodeModal);
