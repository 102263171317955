import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

const Toggle = ({
  input,
  meta: { touched, error },
  label,
  disabled,
  placeholderValue,
  checked,
}) => {
  let { value } = input;

  if (typeof value === 'string') {
    value = Number(value);
  }

  if (typeof checked !== 'undefined') {
    value = checked;
  }

  return (
    <div className="field input">
      <Checkbox
        toggle
        {...input}
        label={label}
        disabled={disabled}
        value={value ? 1 : 0}
        checked={placeholderValue || !!value}
        onChange={(e, data) => input.onChange(data.checked)}
      />
      {touched && error && <small className="error-message">{error}</small>}
    </div>
  );
};

Toggle.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    touched: PropTypes.bool,
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  placeholderValue: PropTypes.bool,
};

export default Toggle;
