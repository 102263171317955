import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

// Components.
import TextInput from '../../../../../../components/TextInput';

// Local components.
import LikeDislikeQuestionController from './components/LikeDislikeQuestionController';
import OpenQuestionController from './components/OpenQuestionController';
import RatingQuestionController from './components/RatingQuestionController';

// Constants.
import {
  OPEN_QUESTION,
  RATING,
  LIKE_DISLIKE,
} from '../../../../../../constants/answerTypes';

// Context.
import { EditorContext } from '../../../../../../context/EditorContext';

// Styles.
import './SingleAnswerQuestionController.scss';

const SingleAnswerQuestionController = ({
  questionTitle,
  questionId,
  answerExample,
  type,
  ratingValue,
  index,
}) => {
  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const questionIndex = useMemo(() => activeElement.questions.findIndex(
    (question) => question.questionId === questionId), [activeElement]);

  const questionControllers = {
    // eslint-disable-next-line max-len
    [OPEN_QUESTION]: <OpenQuestionController answerExample={answerExample} questionId={questionId} index={index} />,
    [RATING]: <RatingQuestionController ratingValue={ratingValue} questionId={questionId} />,
    [LIKE_DISLIKE]: <LikeDislikeQuestionController />,
  };

  const setQuestionTitle = (value) => {
    const dataObj = { ...activeElement };
    const { questions } = dataObj;

    questions[questionIndex].questionTitle = value;

    setActiveElement(dataObj);
  };

  return (
    <div className="SingleAnswerQuestionController">
      <TextInput
        name={`question[${index}].questionElements.question.value`}
        type="text"
        value={questionTitle}
        placeholder="Type your question here..."
        onChange={(e) => setQuestionTitle(e.target.value)}
      />

      {questionControllers[type]}

    </div>
  );
};

SingleAnswerQuestionController.propTypes = {
  index: PropTypes.number.isRequired,
  ratingValue: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  answerExample: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  questionTitle: PropTypes.string.isRequired,
};

export default SingleAnswerQuestionController;
