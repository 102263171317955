import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import LayoutButton from '../../components/LayoutButton';

import { MessageContext } from '../../../context/messageEditor';

import './EditorViewHeader.scss';

const EditorViewHeader = ({ layoutButtons, layoutType, isPreview }) => {
  const {
    handleMessageNameChange,
    messageName,
  } = useContext(MessageContext);

  return (
    <div className="EditorViewHeader">
      <div className="EditorViewHeader__input-container">
        <input
          className="EditorViewHeader__project-name-input"
          onChange={(e) => {
            handleMessageNameChange(e.target);
          }}
          value={messageName}
          placeholder="Project title"
          disabled={isPreview}
        />
        {!isPreview && <i className="material-icons">create</i>}
      </div>
      <div className="EditorViewHeader__buttons-container">
        {layoutButtons.map((button, index) => (
          <LayoutButton
            className={`LayoutButton__${button.value}`}
            key={index}
            onClick={button.onClick}
            isActive={layoutType === button.value}
          >
            {button.title}
          </LayoutButton>
        ))}
      </div>
    </div>
  );
};

EditorViewHeader.propTypes = {
  isPreview: PropTypes.bool,
  layoutButtons: PropTypes.arrayOf(PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
  })).isRequired,
  layoutType: PropTypes.string.isRequired,
};

export default EditorViewHeader;
