import {
  merge,
  omit,
  findKey,
} from 'lodash';
import { contactActions } from '../actions';

const { DELETE_PROPERTY, UPDATE_PROPERTY_LABEL } = contactActions;

export default function entities(state = {}, action) {
  if (action.type === DELETE_PROPERTY) {
    const { id } = action;
    const { contactProperties } = state;
    const key = findKey(contactProperties, (property) => property.id === id);
    return {
      ...state,
      contactProperties: omit(contactProperties, key),
    };
  }
  if (action.type === UPDATE_PROPERTY_LABEL) {
    const { id, label } = action;
    const { contactProperties } = state;
    const updatedProperties = { ...contactProperties };
    const propertyKey = findKey(updatedProperties, (prop) => prop.id === id);
    updatedProperties[propertyKey].label = label;
    return {
      ...state,
      contactProperties: updatedProperties,
    };
  }
  if (action.response && action.response.entities) {
    return merge({}, state, action.response.entities);
  }

  return state;
}
