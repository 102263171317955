import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { isEqual } from 'lodash';

/* eslint-disable */
// const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
// Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
//   draw() {
//     originalDoughnutDraw.apply(this, arguments);
//
//     const chart = this.chart.chart;
//     const { ctx, width, height } = chart;
//
//     const fontSize = (height / 114).toFixed(2);
//     ctx.font = `${fontSize}em Verdana`;
//     ctx.fillStyle = "#000000";
//     ctx.textBaseline = 'middle';
//
//     const text = chart.config.data.text,
//       textX = Math.round((width - ctx.measureText(text).width) / 2),
//       textY = height / 2;
//
//     ctx.fillText(text, textX, textY);
//   },
// });
/* eslint-enable */

const options = {
  segmentShowStroke: true,
  segmentStrokeColor: '#fff',
  segmentStrokeWidth: 2,
  percentageInnerCutout: 50,
  animationSteps: 100,
  animationEasing: 'easeOutBounce',
  animateRotate: true,
  animateScale: false,
  responsive: true,
  maintainAspectRatio: true,
  showScale: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: ({ label, formattedValue }) => `   ${label}: ${formattedValue}`,
      },
      padding: 10,
    },
  },
  elements: {
    labels: {
      fontColor: 'black',
    },
    center: {
      text: '90%',
      color: '#FF6384', // Default is #000000
      fontStyle: 'Arial', // Default is Arial
      sidePadding: 20, // Default is 20 (as a percentage)
    },
  },
  legend: false,
};

const Donut = ({
  data,
  percent,
  not,
  label,
  backgroundColor,
  borderColor,
}) => {
  const d = {
    text: percent ? `${Math.round(percent * 100) / 100}%` : '',
    color: '#36A2EB',
    labels: label,
    datasets: [{
      data: data[0] ? data : [0, not],
      backgroundColor,
      borderWidth: 10,
      hoverBorderWidth: 10,
      borderColor,
      hoverBorderColor: '#fff',
    }],
    options: {
      elements: {
        center: {
          text: 'Desktop',
          color: '#36A2EB', // Default black
          fontStyle: 'Helvetica', // Default Arial
          sidePadding: 15, // Default 20 (as a percentage)
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'middle',
        },
      },
      // global: {
      //   color: '#36A2EB', // Default black
      // },
      title: {
        display: false,
        text: '75%',
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    },
  };

  return (
    <div className="donut-wrapper">
      <div className={`donut-percentage ${d.text.length > 4 ? 'donut-percentage--long' : ''}`}>{d.text}</div>
      <Doughnut
        data={d}
        options={options}
      />
    </div>
  );
};

Donut.propTypes = {
  data: PropTypes.array,
  percent: PropTypes.number,
  not: PropTypes.number,
  label: PropTypes.arrayOf(PropTypes.string),
  backgroundColor: PropTypes.arrayOf(PropTypes.string),
  borderColor: PropTypes.string,
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data)
  && prevProps.percent === nextProps.percent && prevProps.not === nextProps.not
  && isEqual(prevProps.label, nextProps.label)
  && isEqual(prevProps.backgroundColor, nextProps.backgroundColor)
  && prevProps.borderColor === nextProps.borderColor;

export default memo(Donut, areEqual);
