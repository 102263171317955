import { memo, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  filter,
  includes,
  map,
  orderBy,
} from 'lodash';
import moment from 'moment';
import { AutomationContext } from '../../../context/AutomationContext';
import AutomationMessageNew from './AutomationMessageNew';
import AutomationMessageItem from './AutomationMessageItem';
import {
  AutomationMessageRemoveModal,
  MessageDuplicateModal,
} from '../../../containers/Modal';
import { genericActions } from '../../../actions';

const { openModalPortal, closeModalPortal } = genericActions;

const AutomationMessageList = ({
  messages,
  currentStep,
  setCurrentStep,
  setMessageName,
  openModalPortalA,
  closeModalPortalA,
}) => {
  const { messageIds, removeMessageFromList, messageDelete } = useContext(AutomationContext);

  const automationMessages = useMemo(() => orderBy(filter(messages,
    (m) => includes(messageIds, m.id)),
    [(message) => moment(message.createdAt).valueOf()], ['desc']),
    [messages, messageIds]);

  const handleRemoveClick = (id) => () => {
    openModalPortalA({
      content: <AutomationMessageRemoveModal />,
      contentProps: {
        close: closeModalPortalA,
        handleRemove: () => {
          closeModalPortalA();
          removeMessageFromList(id);
        },
        handleDelete: () => {
          closeModalPortalA();
          messageDelete(id);
        },
      },
    });
  };

  const openMessageNameEditModal = (name) => {
    openModalPortalA({
      content: <MessageDuplicateModal />,
      contentProps: {
        handleDuplicateModalState: closeModalPortalA,
        onFormSubmit: (values) => {
          setMessageName(values.name);
          closeModalPortalA();
        },
        initialValues: { name },
        title: 'Update message name.',
        description: 'Enter the new message name to continue',
        label: 'Enter a new name for the message',
        buttonText: 'Save',
      },
    });
  };

  return (
    <div className="automation-sidebar__messages">
      <AutomationMessageNew setCurrentStep={setCurrentStep} />
      {map(automationMessages, (message) => (
        <AutomationMessageItem
          message={message}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleRemoveClick={handleRemoveClick(message.id)}
          openMessageNameEditModal={openMessageNameEditModal}
          key={message.id}
        />
      ))}
    </div>
  );
};

AutomationMessageList.propTypes = {
  messages: PropTypes.object,
  currentStep: PropTypes.string,
  setCurrentStep: PropTypes.func,
  setMessageName: PropTypes.func,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  messages: state.messages,
});

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(AutomationMessageList));
