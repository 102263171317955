import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const BasicButton = ({
  fluid,
  content,
  color = 'blue',
  size = 'small',
  className,
  disabled,
  floated,
  onClick,
  reference,
  type,
  loading,
  spinner,
}) => (
  <Button
    fluid={fluid}
    disabled={disabled}
    content={content}
    color={color}
    size={size}
    className={`${className || ''}${spinner ? ' spinner' : ''}`}
    floated={floated}
    onClick={onClick}
    ref={reference}
    type={type}
    loading={loading}
  />
);

BasicButton.propTypes = {
  fluid: PropTypes.bool,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]),
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  floated: PropTypes.string,
  onClick: PropTypes.func,
  reference: PropTypes.func,
  type: PropTypes.string,
  loading: PropTypes.bool,
  spinner: PropTypes.bool,
};

export default BasicButton;
