import React from 'react';
import PropTypes from 'prop-types';

// Local components.
import MultipleAnswerQuestionUI from './components/MultipleAnswerQuestionUI';
import SingleAnswerQuestionUI from './components/SingleAnswerQuestionUI';

// Constants.
import {
  ONE_ANSWER,
  MULTIPLE_ANSWERS,
  NPS,
  OPEN_QUESTION,
  RATING,
  LIKE_DISLIKE,
} from '../../constants/answerTypes';

// Styles.
import './WidgetSurveyUI.scss';

const defineQuestion = (question, questionIndex, formIndex) => {
  const { type } = question;
  const questionsObj = {
  [ONE_ANSWER]:
  <MultipleAnswerQuestionUI
    {...question}
    formIndex={formIndex}
    index={questionIndex}
    key={questionIndex}
  />,
  [MULTIPLE_ANSWERS]:
  <MultipleAnswerQuestionUI
    {...question}
    formIndex={formIndex}
    index={questionIndex}
    key={questionIndex}
  />,
  [NPS]:
  <SingleAnswerQuestionUI
    {...question}
    index={questionIndex}
    formIndex={formIndex}
    key={questionIndex}
  />,
  [OPEN_QUESTION]:
  <SingleAnswerQuestionUI
    {...question}
    index={questionIndex}
    formIndex={formIndex}
    key={questionIndex}
  />,
  [RATING]:
  <SingleAnswerQuestionUI
    {...question}
    index={questionIndex}
    formIndex={formIndex}
    key={questionIndex}
  />,
  [LIKE_DISLIKE]:
  <SingleAnswerQuestionUI
    {...question}
    index={questionIndex}
    formIndex={formIndex}
    key={questionIndex}
  />,
};

  return questionsObj[type];
};

const WidgetSurveyUI = ({
  questions,
  buttonText,
  buttonColor,
  surveyName,
  showResponseResults,
  surveyFeedback,
  index,
}) => (
  <form
    className="device-form__survey"
    id={`survey-${index}`}
    name={surveyName}
    data-feedback={showResponseResults}
    success-text={surveyFeedback}
    method="post"
    action=""
  >
    {questions.map((question, questionIndex) => defineQuestion(question, questionIndex, index))}
    <input
      id={`survey-${index}-submit`}
      className="submit-button"
      type="submit"
      value={buttonText}
      style={{ backgroundColor: buttonColor }}
    />
  </form>
  );

WidgetSurveyUI.propTypes = {
  index: PropTypes.number.isRequired,
  surveyFeedback: PropTypes.string.isRequired,
  showResponseResults: PropTypes.bool.isRequired,
  surveyName: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
};

export default WidgetSurveyUI;
