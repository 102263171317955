import React from 'react';
import PropTypes from 'prop-types';

// Local components.
import QuestionLayout from '../QuestionBody';

// Styles.
import './MultipleAnswerQuestionUI.scss';

const MultipleAnswerQuestionUI = ({
  answersOptions,
  index,
  questionTitle,
  type,
  answerRequired,
  quizMode,
}) => {
// defining input type whether we can choose one or few answers according to question type.
  const inputType = (type === 'oneAnswer') ? 'radio' : 'checkbox';

  return (
    <QuestionLayout
      // used for display
      questionNumber={index + 1}
      title={questionTitle}
      type={type}
      questionIndex={index}
      answerRequired={answerRequired}
      quiz={`${quizMode}`}
    >
      {answersOptions.map((answer, answerIndex) => {
          const { answerId } = answer;
          return (
            <div
              id={`${index}-${type}-answers-${answerIndex}`}
              key={answer.answerId}
              className="checkboxes-alignment"
            >
              <label
                htmlFor={`${answerId}-${index}-${type}-${answerIndex}-input`}
              >
                <input
                  type={inputType}
                  id={`${answerId}-${index}-${type}-${answerIndex}-input`}
                  name={`${index}-${type}-answers`}
                  value={answerIndex}
                  data-required={answerRequired}
                />
                <span
                  id={`${index}-${type}-answers-${answerIndex}-text`}
                  className="checkboxes-alignment__label"
                >
                  {answer.title || 'choice #'}
                </span>
                <span></span>
                <br />
              </label>
            </div>
          );
        })}
    </QuestionLayout>
  );
};

MultipleAnswerQuestionUI.propTypes = {
  answersOptions: PropTypes.array.isRequired,
  answerRequired: PropTypes.bool,
  quizMode: PropTypes.bool,
  index: PropTypes.number.isRequired,
  questionTitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default MultipleAnswerQuestionUI;
