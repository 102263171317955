import React from 'react';
import PropTypes from 'prop-types';
import { Input as SemanticInput, Popup } from 'semantic-ui-react';
import classNames from 'classnames';
import { PasswordValidity } from '../Register';

const PasswordInput = ({
  input,
  meta: { touched, error },
  label,
  labelPosition,
  disabled,
  placeholder,
  autoComplete,
  min,
  className = '',
}) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const { value } = input;

  const changeInputVisibility = () => {
    setIsVisible((prevState) => !prevState);
  };

  return (
    <div
      className={`${className} field input`}
    >
      <SemanticInput
        {...input}
        label={label}
        labelPosition={labelPosition}
        type={isVisible ? 'text' : 'password'}
        disabled={disabled}
        placeholder={placeholder}
        error={touched && !!error}
        autoComplete={autoComplete}
        min={min}
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {touched && error && (
        <Popup
          className="error-popup"
          trigger={<i className="material-icons input-error-icon">error</i>}
          position="top right"
          content={error}
        />
      )}
      <i
        className={classNames('material-icons password-input-icon', {
        'password-input-icon__error': touched && error,
      })}
        onClick={changeInputVisibility}
      >
        {isVisible ? 'visibility_off' : 'visibility'}
      </i>
      {isFocused && (
      <PasswordValidity
        password={value}
      />
       )}
    </div>
  );
};
PasswordInput.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    touched: PropTypes.bool,
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  }),
  label: PropTypes.string,
  autoComplete: PropTypes.bool,
  placeholder: PropTypes.string,
  labelPosition: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  className: PropTypes.string,
};

export default PasswordInput;
