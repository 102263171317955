import { memo } from 'react';
import PropTypes from 'prop-types';

import { useFeature } from '../../hooks';
import { featuresKeys } from '../../common/plans';

const SelectBar = ({
  selectedTab,
  handleTabChange,
  userRole,
  features,
}) => {
  const showDomain = useFeature(featuresKeys.featureKeys.customDomain, features);

  return (
    <div className="select-bar">
      <div
        className={selectedTab === 'account' ? 'selected-tab' : ''}
        onClick={() => handleTabChange('account')}
      >
        Account
      </div>
      <div
        className={(selectedTab === 'plans' || selectedTab === '')
          ? 'selected-tab' : ''}
        onClick={() => handleTabChange('plans')}
      >
        Plans
      </div>
      {((userRole !== 'expired') && showDomain) && (
        <div
          className={selectedTab === 'domain' ? 'selected-tab' : ''}
          onClick={() => handleTabChange('domain')}
        >
          Domain
        </div>
      )}
      <div
        className={selectedTab === 'billing' ? 'selected-tab' : ''}
        onClick={() => handleTabChange('billing')}
      >
        Billing
      </div>
      {userRole !== 'expired' && (
        <div
          className={selectedTab === 'users' ? 'selected-tab' : ''}
          onClick={() => handleTabChange('users')}
        >
          Users
        </div>
      )}
      <div
        className={selectedTab === 'payments' ? 'selected-tab' : ''}
        onClick={() => handleTabChange('payments')}
      >
        Payments
      </div>
    </div>
  );
};

SelectBar.propTypes = {
  selectedTab: PropTypes.string,
  handleTabChange: PropTypes.func,
  userRole: PropTypes.string,
  features: PropTypes.object,
};

export default memo(SelectBar);
