import { map, forEach } from 'lodash';
import { contactActions } from '../actions';

const { FETCH_LISTS, UPDATE_LIST_NAME, DELETE_LIST } = contactActions;

const lists = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LISTS.SUCCESS: {
      return {
        ...action.response,
      };
    }
    case UPDATE_LIST_NAME: {
      const { elements } = state;
      const { id, name } = action;
      const updatedLists = map(elements, (list) => {
        if (list.id === id) {
          return {
            ...list,
            name,
          };
        }

        return list;
      });
      return {
        ...state,
        elements: updatedLists,
      };
    }
    case DELETE_LIST: {
      const { elements } = state;
      const { id } = action;
      const updatedLists = [];
      forEach(elements, (el) => {
        if (el.id !== id) {
          updatedLists.push(el);
        }
      });
      return {
        ...state,
        elements: updatedLists,
      };
    }
    default:
      return state;
  }
};

export default lists;
