import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import {
  mapSurveyQuestionsToOptions,
  mapQuestionChoicesToOptions,
  findSurveyElement,
} from '../../../utils/segmentationHelper';

const SegmentationItemOptions = ({
  forms,
  item,
  index,
  changeSegmentValue,
}) => (
  <>
    <Dropdown
      className="segmentation-row__options__second-column"
      placeholder={item.type === 'message' ? 'Select Message Data' : 'Select a Question'}
      value={item.type === 'message' ? item.messageOption : item.question}
      options={item.type === 'message'
        ? [
          { value: 'open', text: 'Message was opened' },
          { value: 'notOpen', text: 'Message was not opened' },
        ]
        : mapSurveyQuestionsToOptions(findSurveyElement(forms, 'displayName', item.type).elements)}
      onChange={(event, data) => changeSegmentValue(data.value, index, `${item.type === 'message' ? 'messageOption' : 'question'}`)}
    />
    {item.question && (
      <Dropdown
        className="segmentation-row__options__third-column"
        placeholder="Select an Answer"
        value={item.answer}
        options={item.question.split('-')[1] === 'likeDislike'
          ? [
            { value: '1', text: 'Answer "Like"' },
            { value: '0', text: 'Answer "Dislike"' },
          ]
          : mapQuestionChoicesToOptions(findSurveyElement(findSurveyElement(forms, 'displayName', item.type).elements, 'inputName', item.question).choices)}
        onChange={(event, data) => changeSegmentValue(data.value, index, 'answer')}
      />
    )}
  </>
);

SegmentationItemOptions.propTypes = {
  forms: PropTypes.array,
  item: PropTypes.object,
  index: PropTypes.number,
  changeSegmentValue: PropTypes.func,
};

export default SegmentationItemOptions;
