import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popup, Divider } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IconButton } from '../../../components/Button';
import { DataTable } from '../../Table';
import PopupContent from '../../../components/Analytics/PopupContent';
import { openModalPortal, closeModalPortal } from '../../../actions/genericActions';
import { ExportAnalyticsDataModal } from '../../Modal';

const MsgStatsTable = ({
  data,
  columns,
  message,
  onChecked,
  options,
  surveysIndexes,
  videosIndexes,
  buttonsIndexes,
  attachmentsIndexes,
  page,
  total,
  pageSize,
  surveyDetails,
  handleSurveyClick,
  surveyName,
  loading: propsLoading,
  recipientsMetrics,
  onPageChange,
  openModalPortalA,
  closeModalPortalA,
}) => {
  const [tablePopupOpen, setTablePopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [recipientsMetrics]);

  const openExportModal = () => {
    openModalPortalA({
      content: <ExportAnalyticsDataModal />,
      contentProps: {
        close: () => closeModalPortalA(),
      },
    });
  };

  const handleTablePopup = () => {
    setTablePopupOpen(!tablePopupOpen);
    document.getElementById('root').addEventListener('click', () => {
      tablePopupOpen(false);
      document.getElementById('root').removeAttribute('onClick');
    }, { once: true });
  };

  const pageChangeHandler = (p) => {
    setLoading(true);
    onPageChange(p);
  };

  return (
    <>
      <div className="messageStats__header">
        {surveyDetails && (
          <span className="messageStats__header__back" onClick={() => handleSurveyClick()}>Back</span>
        )}
        {surveyDetails && (
          <Divider className="messageStats__header__divider" vertical fitted />
        )}
        <span className="messageStats__header__text">{`${surveyName || 'Sending'} Data Details`}</span>
        <div className="messageStats__header__buttons">
          <IconButton
            iconName="file_upload"
            text="Export"
            size="small"
            color="blue"
            className="invert"
            onClick={openExportModal}
          />
          {!surveyDetails && (
            <Popup
              basic
              trigger={(
                <IconButton
                  iconName="view_week"
                  text="Change data"
                  size="small"
                  color="blue"
                  className="invert"
                  onClick={handleTablePopup}
                />
              )}
              open={tablePopupOpen}
              position="bottom center"
            >
              <Popup.Content>
                <PopupContent
                  onChecked={onChecked}
                  message={message}
                  options={options}
                  surveysIndexes={surveysIndexes}
                  videosIndexes={videosIndexes}
                  buttonsIndexes={buttonsIndexes}
                  attachmentsIndexes={attachmentsIndexes}
                />
              </Popup.Content>
            </Popup>
          )}
        </div>
      </div>
      <DataTable
        total={total}
        data={data}
        className="messageStats__table"
        pageSize={pageSize === data.length ? pageSize : data.length}
        page={page}
        options={options}
        columns={columns}
        onPageChange={pageChangeHandler}
        pageNumbers={pageSize}
        loading={loading || propsLoading}
      />
    </>
  );
};


MsgStatsTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  message: PropTypes.object,
  onChecked: PropTypes.func,
  options: PropTypes.array,
  surveysIndexes: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }),
  videosIndexes: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }),
  buttonsIndexes: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }),
  attachmentsIndexes: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }),
  page: PropTypes.number,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  surveyDetails: PropTypes.string,
  handleSurveyClick: PropTypes.func,
  surveyName: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  loading: PropTypes.bool,
  recipientsMetrics: PropTypes.object,
};

const mapStateToProps = (state) => ({
    recipientsMetrics: state.recipientsMetrics,
  });

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MsgStatsTable));
