import { v4 as uuidv4 } from 'uuid';

export const defaultDropdownOptions = [
  {
    datatype: 'name',
    id: uuidv4(),
    value: 'contact.name',
    text: 'First name',
    type: 'text',
    name: 'contact.name',
    placeholder: 'Type your text here',
  },
  {
    datatype: 'lastname',
    id: uuidv4(),
    value: 'contact.surname',
    text: 'Last name',
    type: 'text',
    name: 'contact.surname',
    placeholder: 'Type your text here',
  },
  {
    datatype: 'email',
    id: uuidv4(),
    value: 'contact.email',
    text: 'Email',
    type: 'email',
    name: 'contact.email',
    placeholder: 'Type your text here',
  },
  {
    datatype: 'tel',
    id: uuidv4(),
    value: 'contact.phoneNumber',
    text: 'Phone number',
    type: 'tel',
    name: 'contact.phoneNumber',
    placeholder: 'Type your text here',
  },
];
