import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { mediaLibraryFilterOptions } from '../../common/options';

const MediaLibraryFilter = ({
  changeFilterValue,
  filterValue,
}) => (
  <Dropdown
    defaultValue="image"
    pointing="top right"
    options={mediaLibraryFilterOptions}
    onChange={(e, data) => {
      if (data.value !== filterValue) {
        changeFilterValue(data.value);
      }
    }}
    className="media-library-filter filter-option"
  />
);

MediaLibraryFilter.propTypes = {
  changeFilterValue: PropTypes.func,
  filterValue: PropTypes.string,
};

export default MediaLibraryFilter;
