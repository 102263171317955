import { get } from 'lodash';
import { callApi } from './callApi';
import { getUrlProperty, getServicePath, getFullServicePath } from '../utils/helper';
import { API_ROOT } from '../config';

export const call = (endpoint, params, _schema) => callApi({ serviceName: getServicePath('analytics'), endpoint }, params, _schema);

export const openAnswersMetricsGet = ({
  id,
  form,
  fieldName,
  count = 10,
  nextToken = '',
}) => {
  let query = `/metrics/${id}/${form}/${fieldName}/values?c=${count}`;
  if (nextToken) {
    query = `${query}&t=${nextToken}`;
  }
  const url = getFullServicePath('analytics', query);
  let pathTemplate = getUrlProperty('pathname', url);
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);


  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};

export const recipientsMetricsGet = (id, count = 10, nextToken = '') => {
  let query = `/metrics/recipient?c=${count}&m=${id}`;
  if (nextToken) {
    query = `${query}&t=${nextToken}`;
  }
  const url = getFullServicePath('analytics', query);
  let pathTemplate = get(new URL(url, API_ROOT), 'pathname');
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);

  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};

export const recipientMetricsSearch = ({ id, searchQuery = '' }) => {
  const url = getFullServicePath('analytics', `/metrics/search/recipients?m=${id}&q=${searchQuery}`);
  let pathTemplate = getUrlProperty('pathname', url);
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);

  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};

export const getDashboardMetrics = ({
  startDate = '2018-08-10',
  endDate = '2018-08-20',
  deliveryMethod = 'email',
  recipientId = null,
}) => {
  let query = `/series?dateStart=${startDate}&dateEnd=${endDate}&deliveryMethod=${deliveryMethod}`;
  if (recipientId) {
    query = `${query}&uid=${recipientId}`;
  }
  const url = getFullServicePath('analytics', query);
  let pathTemplate = get(new URL(url, API_ROOT), 'pathname');
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);
  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};
