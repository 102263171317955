import { createPaginator } from '../modules/redux-paginator';
import { api } from '../services';
import { API_ROOT } from '../config';

const { automation: { automationListGet } } = api;

const automationPaginator = createPaginator(API_ROOT, ['automation'], {
  resultsKey: 'elements',
  countKey: 'total',
  idKey: 'id',
  pageArgName: 'page',
}, automationListGet);

export default automationPaginator;
