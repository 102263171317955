import { Grid, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { ResetPasswordForm } from '../Form';

const ResetPassword = ({ history }) => (
  <Grid>
    <Grid.Column className="ris-form">
      <Grid.Row>
        <Header size="large" className="app-form__header centered">
          Reset your password
        </Header>
      </Grid.Row>
      <ResetPasswordForm
        history={history}
        invert={false}
      />
    </Grid.Column>
  </Grid>
);

ResetPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default withRouter(ResetPassword);
