import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  map,
  capitalize,
  isEqual,
} from 'lodash';

import { featuresKeys } from '../../../../common/plans';
import { renderUserFeature, calculatePrice } from '../../../../utils/plans';

const PlansMobile = ({
  plans,
  openPlanSubscriptionModal,
}) => {
  const [planFeaturesOpen, setPlanFeaturesOpen] = useState('');

  return (
    <div className="ris-pricing-mobile">
      {map(plans, (plan, index) => {
        const {
          product,
          cents,
          period,
          features,
          support,
          monthlyParams: { sms },
          params: { email },
        } = plan;

        return (
          <div className={`ris-pricing-mobile-item ris-feature-inner-box--${product}`}>
            <div className="mobile-item-title">
              {capitalize(product)}
            </div>
            <div className="mobile-item-price-box">
              {cents === -1 ? (
                <p className="ris-pricing-inner-price-value get-quote">Contact us</p>
              ) : (
                <>
                  <div className="mobile-item-price-value">
                    {calculatePrice(cents, period)}
                  </div>
                  <div className="mobile-item-price-label">
                    <div className="price-label-first">
                      user / month
                    </div>
                    <div className="price-label-last">
                      {`paid ${period === 'month' ? 'monthly' : 'annually'}`}
                    </div>
                  </div>
                </>
              )}
            </div>
            <button
              onClick={() => openPlanSubscriptionModal(plan)}
              type="button"
              className="ris-pricing-button-start ris-pricing-button-start--mobile"
            >
              {`Get ${product === 'enterprise' ? 'a quote' : capitalize(product)}`}
            </button>
            <div className="ris-pricing-mobile-main-features">
              <div>{`${featuresKeys.headerFeatures.sms}: ${sms}/${period}`}</div>
              {email === -1 ? <div>Unlimited email</div> : null}
              <div>{renderUserFeature(plans, index)}</div>
            </div>
            <div
              onClick={() => setPlanFeaturesOpen(planFeaturesOpen === product ? '' : product)}
              className="ris-pricing-mobile-all-features"
            >
              All features
            </div>
            <div
              className={`ris-pricing-mobile-all-features-section${planFeaturesOpen === product
                ? ' ris-pricing-mobile-all-features-section--open' : ''}`}
            >
              <div className="feature-section-title">
                Features
              </div>
              {map(features, (isActive, feature) => (
                isActive ? (
                  <div className="feature-section-row">
                    <div className="ris-checkbox-black" />
                    {featuresKeys.features[feature]}
                  </div>
                ) : null
              ))}
              <div className="feature-section-title">
                Support
              </div>
              {map(support, (isActive, feature) => (
                isActive ? (
                  <div className="feature-section-row">
                    <div className="ris-checkbox-black" />
                    {featuresKeys.supportFeatures[feature]}
                  </div>
                ) : null
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

PlansMobile.propTypes = {
  plans: PropTypes.array,
  openPlanSubscriptionModal: PropTypes.func,
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps.plans, nextProps.plans);

export default memo(PlansMobile, areEqual);
