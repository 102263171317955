import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectStripe, CardElement } from 'react-stripe-elements';
import PropTypes from 'prop-types';
import { BasicButton } from '../../components/Button/index';
import { api } from '../../services';
import Card from '../../components/Stripe/Card';
import { notificationActions } from '../../actions';
import { clientSecretGet } from '../../services/accountsApi';

const { notifySuccess, notifyError } = notificationActions;

const { postStripeCreditCard, getStripeCreditCards } = api.accounts;

const CheckoutForm = ({
  stripe,
  addedCard,
  notifyErrorA,
  showSuccessMsg,
}) => {
  const [card, setCard] = useState(stripe.elements().create('card'));
  const [cards, setCards] = useState({});
  const [showAddCard, setShowAddCard] = useState(false);

  const element = useRef(null);

  useEffect(() => {
    handleCreditCards();
  }, []);

  const handleCreditCards = () => {
    getStripeCreditCards().then(({ response }) => {
      setCards(response);
      setShowAddCard(!response);
    });

    // Handle real-time validation errors from the card Element.
    card.addEventListener('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  };

  const handleReady = (updatedElement) => {
    element.current = updatedElement;
  };

  const handleSubmit = (e) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    e.preventDefault();

    clientSecretGet().then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
      } else {
        const { response: { clientSecret } } = res;
        stripe.handleCardSetup(clientSecret, element.current).then((result) => {
          if (result.error) {
            notifyErrorA(result.error.message);
          } else {
            postStripeCreditCard({ clientSecret }).then((r) => {
              if (r.error) {
                notifyErrorA(r.error.message);
              } else if (r.message) {
                notifyErrorA(r.message);
              } else {
                getStripeCreditCards().then(({ response }) => {
                  showSuccessMsg('Card was added successfully');
                  setCards(response);
                  setShowAddCard(!response);
                  if (addedCard) {
                    addedCard();
                  }
                });
              }
            });
          }
        });
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="cards">
        {cards && (
          <>
            <Card
              card={cards}
              key={`${cards.number}-${Math.random()}`}
            />
            {!showAddCard && (
              <BasicButton
                content="Change Card"
                size="small"
                color="blue"
                className="invert inline"
                onClick={() => setShowAddCard(true)}
              />
            )}
          </>
        )}
      </div>
      {showAddCard && (
        <>
          <div className="card-flex">
            <span className="billing-input">
              <CardElement
                id="stripe-form"
                onReady={handleReady}
              />
            </span>
            <BasicButton
              content="Add"
              size="small"
              color="blue"
              className="inline"
            />
          </div>
        </>
      )}
    </form>
  );
};

CheckoutForm.propTypes = {
  stripe: PropTypes.shape({
    handleCardSetup: PropTypes.func,
    elements: PropTypes.func,
  }),
  showSuccessMsg: PropTypes.func,
  addedCard: PropTypes.func,
  notifyErrorA: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  showSuccessMsg: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default connect(null, mapDispatchToProps)(injectStripe(CheckoutForm));
