import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Notifications, { removeAll } from 'react-notification-system-redux';

const Notification = ({
  notifications,
  removeAllNotifications,
}) => {
  useEffect(() => () => {
    removeAllNotifications();
  }, []);

  return <Notifications notifications={notifications} />;
};

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  removeAllNotifications: bindActionCreators(removeAll, dispatch),
});

Notification.propTypes = {
  notifications: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  removeAllNotifications: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
