import { CHANGE_MESSAGE_FILTER, CREATE_MESSAGE_FILTER } from '../actions/messageActions';

const messageFilter = (
  state = {
    typeFilter: 'all',
    distributionFilter: 'all',
    nameFilterValue: '',
    originFilter: 'all',
  }, action,
) => {
  switch (action.type) {
    case CHANGE_MESSAGE_FILTER: {
      const {
        typeFilter,
        distributionFilter,
        nameFilterValue,
        originFilter,
      } = action;
      return {
        typeFilter,
        distributionFilter,
        nameFilterValue,
        originFilter,
      };
    }
    case CREATE_MESSAGE_FILTER:
      return {
        typeFilter: 'all',
        distributionFilter: 'all',
        nameFilterValue: '',
        originFilter: 'all',
      };
    default:
      return state;
  }
};

export default messageFilter;
