import React from 'react';
import PropTypes from 'prop-types';

const RatingAnswerUI = ({
  index,
  type,
  ratingValue,
  answerRequired,
}) => (
  <>
    <label
      htmlFor={`${index}-${type}-range-input-id`}
    >
      <div className="rating-input-label">Rating: 1</div>
    </label>
    <div>
      <div className="rating-info" id={`${index}-${type}-answer`}></div>
      <div className="rating-info" id={`${index}-${type}-average`}></div>
    </div>
    <div>
      <input
        type="range"
        id={`${index}-${type}-range-input-id`}
        name={`${index}-${type}-range`}
        min="1"
        max={ratingValue}
        defaultValue={1}
        data-required={`${answerRequired}`}
      />
    </div>
  </>
);

RatingAnswerUI.propTypes = {
  answerRequired: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  ratingValue: PropTypes.string.isRequired,
};

export default RatingAnswerUI;
