import {
  memo,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Sidebar, Segment } from 'semantic-ui-react';
import {
  MessageContextProvider,
} from '../../../context';
import { MessageCreationWizard } from '../../Wizard';
import { AutomationContext } from '../../../context/AutomationContext';
import AutomationMessageList from './AutomationMessageList';
import { EditorContextProvider } from '../../../editor/context/EditorContext';

const AutomationMessageEditor = () => {
  const {
    splitScreenOpen,
    selectedMessageId,
    setSelectedMessageId,
    keepEditorOpen,
    setKeepEditorOpen,
    getNodes,
  } = useContext(AutomationContext);
  const [currentStep, setCurrentStep] = useState('design');
  const [tempCurrentStep, setTempCurrentStep] = useState('design');
  const [editorOpen, setEditorOpen] = useState(!!selectedMessageId);
  const [messageName, setMessageName] = useState(undefined);

  const timer = useRef();

  useEffect(() => {
    if (selectedMessageId) {
      if (timer.current || timer.current === 0) {
        clearTimeout(timer.current);
      }
      setEditorOpen(true);
      setKeepEditorOpen(false);
    } else {
      // for animation, we render editor for 1s after closing event
      timer.current = setTimeout(() => setEditorOpen(false), 1000);
    }
  }, [selectedMessageId]);

  const segmentClassName = useMemo(() => {
    if (splitScreenOpen) {
      if (selectedMessageId) {
        return 'automation-sidebar open';
      }
      return 'automation-sidebar only-list';
    }
    return 'automation-sidebar';
  }, [selectedMessageId, splitScreenOpen]);
  const editorClassName = useMemo(() => {
    if (selectedMessageId || keepEditorOpen) {
      return 'automation-sidebar__message-editor';
    }
    return 'automation-sidebar__message-editor closed';
  }, [selectedMessageId]);

  const handleMessageClose = () => setSelectedMessageId(undefined);

  const handleStepChange = (step) => {
    setTempCurrentStep(step);
    setCurrentStep(step);
  };

  const handleNameChange = (name) => {
    setMessageName(name);
    getNodes(true, true);
  };

  return (
    <Sidebar
      visible
      as={Segment}
      animation="push"
      direction="right"
      className={segmentClassName}
    >
      <AutomationMessageList
        currentStep={currentStep}
        setCurrentStep={setTempCurrentStep}
        setMessageName={setMessageName}
      />
      <div className={editorClassName}>
        {editorOpen && (
          <EditorContextProvider>
            <MessageContextProvider
              isAutomation
              automationMessageId={selectedMessageId}
              automationCurrentStep={tempCurrentStep}
              setAutomationCurrentStep={handleStepChange}
              automationMessageName={messageName}
              setAutomationMessageName={handleNameChange}
              setCurrentStepLayout={handleStepChange}
            >
              <MessageCreationWizard handleMessageClose={handleMessageClose} />
            </MessageContextProvider>
          </EditorContextProvider>
        )}
      </div>
    </Sidebar>
  );
};

export default memo(AutomationMessageEditor);
