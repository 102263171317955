import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { messageActions } from '../actions';

const { createMessage } = messageActions;


const MessageWizzardComplete = ({ id, createMessageA }) => {
  const { push } = useHistory();
  return (
    <div className="ct success-background">
      <div className="success-border">
        <div className="success-nest">
          <div className="success-page-message-check">
            <span className="success-gif" />
          </div>
          <div className="success-page-message-prompt">
            <span className="both-texts">
              <div className="success-text">GREAT JOB!</div>
              <div className="smaller-text">Your message was successfully sent...</div>
              <div className="smaller-text">Responses are coming in!</div>
            </span>
          </div>
          <div className="centered-content">
            <div className="buttons-alignment">
              <div className="second-arrow-margins--arrow arrow-success-page">
                <span />
              </div>
              <Button
                onClick={() => push({ pathname: `/message/${id}/stats` })}
                className="blue analytics-button"
              >
                <p className="empty-messages-button-text">See analytics for this message</p>
              </Button>
              <Button
                className="blue another-message-button"
                onClick={() => createMessageA({ name: '', origin: 'default', deliveryMethod: 'sms' })}
              >
                <p className="another-text">Create another message</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MessageWizzardComplete.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  id: PropTypes.string,
  createMessageA: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  createMessageA: bindActionCreators(createMessage.request, dispatch),
});

export default connect(null, mapDispatchToProps)(MessageWizzardComplete);
