import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'semantic-ui-react';
import { submit, getFormSyncErrors } from 'redux-form';
import { isEmpty } from 'lodash';
import { MessageDuplicateForm } from '../Form';

const MessageDuplicateModal = ({
  handleDuplicateModalState,
  open,
  onFormSubmit,
  submitForm,
  formErrors,
  initialValues,
  title = 'Message has been successfully copied.',
  description = 'Change the message name to continue',
  label = 'Enter name for copied message',
  buttonText = 'Copy',
  isAutomation,
}) => (
  <Modal
    open={open}
    onClose={() => handleDuplicateModalState()}
    className="message-modal"
    dimmer="inverted"
  >
    <Modal.Content>
      <div className="copy-modal-header">
        {title}
      </div>
      <Modal.Description className="copy-modal-subheader">
        {description}
      </Modal.Description>
      <MessageDuplicateForm
        onFormSubmit={onFormSubmit}
        initialValues={initialValues}
        label={label}
        isAutomation={isAutomation}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button
        className="white"
        onClick={() => handleDuplicateModalState()}
      >
        Cancel
      </Button>
      <Button
        onClick={() => submitForm('messageCopyForm')}
        disabled={!isEmpty(formErrors)}
        className="blue"
      >
        {buttonText}
      </Button>
    </Modal.Actions>
  </Modal>
);

MessageDuplicateModal.propTypes = {
  handleDuplicateModalState: PropTypes.func,
  open: PropTypes.bool,
  onFormSubmit: PropTypes.func,
  submitForm: PropTypes.func,
  formErrors: PropTypes.object,
  initialValues: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  isAutomation: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('messageCopyForm')(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitForm: bindActionCreators(submit, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageDuplicateModal);
