import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './NPSAnswerUI.scss';

const NPSAnswerUI = ({ index, answerRequired }) => {
  const npsOptionsArray = Array.from(Array(11).keys());
  return (
    <>
      {npsOptionsArray.map((option, optionIndex) => (
        <div
          className="nps-alignment"
          key={optionIndex}
        >
          <label
            key={optionIndex}
            htmlFor={`${optionIndex}-radio-button-${index}`}
          >
            <input
              id={`${optionIndex}-radio-button-${index}`}
              type="radio"
              value={optionIndex}
              name={`${index}-nps-answers`}
              data-required={`${answerRequired}`}
            />
            <span className="nps-alignment__label">
              {option}
            </span>
          </label>
        </div>
      ))}
    </>
  );
};

NPSAnswerUI.propTypes = {
  answerRequired: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};

export default NPSAnswerUI;
