import PropTypes from 'prop-types';

const LikeAnalytics = ({
  values,
}) => {
  let thumbup = false;
  let thumbdown = false;
  if ((typeof values[1] === 'number' && typeof values[0] === 'undefined')
    || (typeof values[1] === 'number' && typeof values[0] === 'number'
      && values[1] > values[0])) {
    thumbup = true;
  } else if ((typeof values[0] === 'number' && typeof values[1] === 'undefined')
    || (typeof values[1] === 'number' && typeof values[0] === 'number'
      && values[1] < values[0])) {
    thumbdown = true;
  } else if (typeof values[0] === 'number' && typeof values[1] === 'number'
    && values[0] === values[1]) {
    thumbup = true;
    thumbdown = true;
  }
  return (
    <div className="survey-stats__like">
      <div className="survey-stats__like__up">
        <div className={`survey-stats__like__up--thumb${thumbup ? '' : '--inactive'}`} />
        <span>
          {typeof (values[1]) === 'undefined' ? 0 : values[1]}
            &nbsp;answers
        </span>
      </div>
      <div className="survey-stats__like__down">
        <div className={`survey-stats__like__down--thumb${thumbdown ? '' : '--inactive'}`} />
        <span>
          {typeof (values[0]) === 'undefined' ? 0 : values[0]}
          &nbsp;answers
        </span>
      </div>
    </div>
  );
};

LikeAnalytics.propTypes = {
  values: PropTypes.object,
};

export default LikeAnalytics;
