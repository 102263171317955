import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, map, remove } from 'lodash';

const AnswerSelect = ({
  message,
  survey,
  question,
  answers,
  setAnswers,
  messages,
  disabled,
}) => {
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const answerOptions = useMemo(() => {
    const m = find(messages, (el) => el.id === message);
    if (m) {
      const { schema: { forms } } = m;
      const form = find(forms, (f) => f.displayName === survey);
      if (form) {
        const q = find(form.elements, (element) => element.inputName === question);
        if (q) {
          setLoading(false);
          return map(q.choices, (choice) => ({ text: choice.label, value: choice.label }));
        }
      }
    }
    setLoading(true);
    return [];
  }, [message, survey, question, messages]);

  const handleClear = () => setAnswers([]);

  const handleSelect = (value) => () => {
    if (!disabled) {
      if (find(answers, (answer) => answer === value)) {
        remove(answers, (answer) => answer === value);
        setAnswers([...answers]);
      } else {
        setAnswers((a) => [...a, value]);
      }
    }
  };

  const handleVisibility = () => {
    if (!loading) {
      setVisible((v) => !v);
    }
  };

  return (
    <div className="answers-select">
      <div className="answers-select__title">
        <span className="answers-select__title__container">
          <span
            className={`answers-select__title__container__toggle${visible && !loading ? '' : ' answers-select__title__container__toggle--hidden'}`}
            onClick={handleVisibility}
          />
          <span className="answers-select__title__container__text">
            What answers should have condition paths?
          </span>
          <span className="answers-select__title__container__constraint">
            {`(${answers.length} out of ${answerOptions.length > 10 ? 10 : answerOptions.length} selected)`}
          </span>
        </span>
        <span
          className="answers-select__title__clear"
          onClick={handleClear}
        >
          Clear all
        </span>
      </div>
      {visible && (
        <div className="answers-select__choices">
          {map(answerOptions, (option) => {
            const optionFound = find(answers, (answer) => answer === option.value);
            const selected = !!optionFound || optionFound === 0;
            return (
              <div
                className={`answers-select__choices__row${disabled ? ' answers-select__choices__row--disabled' : ''}`}
                onClick={handleSelect(option.value)}
                key={option.value}
              >
                <span className={`answers-select__choices__row__checkbox${selected ? '--selected' : ''}`} />
                <span className="answers-select__choices__row__text">{option.text}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

AnswerSelect.propTypes = {
  message: PropTypes.string,
  survey: PropTypes.string,
  question: PropTypes.string,
  answers: PropTypes.array,
  setAnswers: PropTypes.func,
  messages: PropTypes.object,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  messages: state.messages,
});

export default connect(mapStateToProps)(AnswerSelect);
