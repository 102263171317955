import { useState } from 'react';
import { bindActionCreators } from 'redux';
import { Message, Label } from 'semantic-ui-react';
import Creatable from 'react-select/creatable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormSyncErrors, reduxForm } from 'redux-form';
import { get, map, isEmpty } from 'lodash';
import { notifyError, notifySuccess } from '../../actions/notificationActions';
import DeleteFromListForm from './DeleteFromListForm';
import { contactToListAdd, listCreate } from '../../services/contactApi';
import { required } from '../../utils/validator';
import {
  fetchModifiedContactsPage,
  fetchLists,
} from '../../actions/contactActions';
import { BasicButton } from '../../components/Button';
import { mapListsToOptions } from '../../utils/map';

const ListActionsForm = ({
  closeModalPortal,
  requestModifiedContactViewPageA,
  fetchListsA,
  selected,
  notifyErrorA,
  list,
  handleUnselect,
  contactFilter,
  excluded,
  propertyId,
  orderBy,
  notifySuccessA,
  type,
  formErrors,
  handleSubmit,
  contacts,
  currentPageResults,
  lists,
}) => {
  const [values, setValues] = useState([]);

  const handleOnChange = (newValues) => {
    setValues(newValues);
  };

  const handleListSubmit = () => {
    const filterId = get(contactFilter, 'id', undefined);

    if (values.length > 0) {
      const requests = map(values, (li) => {
        const { value, label } = li;

        if (typeof value === 'string') {
          return listCreate({ name: label }).then((res) => {
            if (res.response && res.response.id) {
              const { id } = res.response;

              const payload = {
                id,
                body: {
                  ids: typeof selected === 'string' ? [] : selected,
                  excluded,
                  filterId,
                  listId: typeof list === 'number' ? list : 0,
                },
              };

              contactToListAdd(payload).then(() => {
                notifySuccessA(`Contacts added to ${label} list`);
              }).catch((e) => {
                notifyErrorA(e);
              });
            }
          });
        }

        const payload = {
          id: value,
          body: {
            ids: typeof selected === 'string' ? [] : selected,
            excluded,
            filterId,
            listId: typeof list === 'number' ? list : 0,
          },
        };

        return contactToListAdd(payload).then(() => {
          notifySuccessA(`Contacts added to ${label} list`);
        }).catch((e) => {
          notifyErrorA(e);
        });
      });

      return Promise.all(requests).then(() => {
        fetchListsA();
        requestModifiedContactViewPageA({
          list,
          filterId,
          propertyId,
          orderBy,
        });
        handleUnselect();
        closeModalPortal();
      });
    }
    return values;
  };

  return (
    <form onSubmit={handleSubmit(handleListSubmit)}>
      {(selected.length < 1)
      && (
      <Message
        error
        header="No Contacts Selected"
        content="First select the contacts you want to add to lists. Please go back and select the contacts you want to add"
      />
      )}
      <div className="tag">
        {type === 'add' ? (
          <div>
            <div className="modal--tag__content">
              <Creatable
                data-tip="Do not forget to press ↵ Enter after typing the tag"
                id="tagsInput"
                validate={required}
                isMulti={true}
                isSearchable={false}
                simpleValues={true}
                options={mapListsToOptions(lists)}
                placeholder="Select or create new list"
                className="react-select tag"
                value={values}
                onChange={handleOnChange}
                multi
              />
              <input id="ids" type="hidden" value={selected} />
              <br className="clear" />
              <Label htmlFor="tagsInput" className="label">
                You can add to multiple lists
              </Label>
            </div>
            <div className="actions modal--tag__actions">
              <BasicButton
                content="Cancel"
                color="white"
                size="small"
                onClick={closeModalPortal}
              />
              <BasicButton
                content="Add to List"
                size="small"
                color="blue"
                type="submit"
                disabled={!isEmpty(formErrors) || !selected.length}
              />
            </div>
          </div>
        ) : (
          selected && (
            <DeleteFromListForm
              closeModalPortal={closeModalPortal}
              contacts={contacts}
              selected={selected}
              currentPageResults={currentPageResults}
              lists={lists}
              list={list}
              excluded={excluded}
              handleUnselect={handleUnselect}
              propertyId={propertyId}
              orderBy={orderBy}
              fetchListsA={fetchListsA}
              notifyErrorA={notifyErrorA}
              notifySuccessA={notifySuccessA}
              requestModifiedContactViewPageA={requestModifiedContactViewPageA}
            />
          )
        )}
      </div>
    </form>
  );
};

ListActionsForm.propTypes = {
  closeModalPortal: PropTypes.func,
  contacts: PropTypes.array,
  currentPageResults: PropTypes.array.isRequired,
  formErrors: PropTypes.object,
  fetchListsA: PropTypes.func,
  handleSubmit: PropTypes.func,
  lists: PropTypes.array,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  requestModifiedContactViewPageA: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  type: PropTypes.string,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleUnselect: PropTypes.func,
  contactFilter: PropTypes.func,
  excluded: PropTypes.array,
  propertyId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  orderBy: PropTypes.string,
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('ListActions')(state),
  contactFilter: state.contactFilter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchListsA: bindActionCreators(fetchLists.request, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  requestModifiedContactViewPageA: bindActionCreators(fetchModifiedContactsPage.request, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'ListActions' })(ListActionsForm));
