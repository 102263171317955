export const generateQuizPayload = (elementsStructureArray) => {
  const surveyElements = elementsStructureArray.filter((element) => element.elementType === 'survey');
  const quizSurveyElements = [];
  const quizPayload = [];

  // looking for quiz mode enabled
  surveyElements.forEach((quizElement) => {
    const isQuizQuestionExists = quizElement.questions.some(
      (question) => question.quizMode === true);

    if (isQuizQuestionExists) {
      quizSurveyElements.push(quizElement);
    }
  });

  // generating survey payload
  if (quizSurveyElements.length) {
    quizSurveyElements.forEach((surveyElement) => {
      const correctAnswers = [];

      surveyElement.questions.forEach((question, questionIndex) => {
        const answersValues = [];
        question.answersOptions.forEach((answer, answerIndex) => {
          if (answer.isCorrect) {
            answersValues.push(answerIndex);
          }
        });
        const answerObj = {
          input: `${questionIndex}-${question.type}-answers`,
          values: answersValues,
        };

           correctAnswers.push(answerObj);
      });


      const surveyObj = {
        name: surveyElement.surveyName,
        forms: correctAnswers,
      };

      quizPayload.push(surveyObj);
    });
  }

  return quizPayload;
};
