import PropTypes from 'prop-types';
import { Dropdown, Input } from 'semantic-ui-react';
import { DELAY_INTERVALS } from '../../../common/automation';

const DelaySelect = ({
  delay,
  delayInterval,
  setDelay,
  setDelayInterval,
  disabled,
}) => (
  <span className="subject-input-select">
    <span>
      delay the next step for
    </span>
    <span className="ris-form">
      <Input
        type="number"
        value={delay}
        onChange={(e, { value }) => setDelay(value)}
        min={1}
        placeholder="Enter delay"
        className="subject-input-select__delay"
        disabled={disabled}
      />
    </span>
    <Dropdown
      className="subject-input-select__delay"
      options={DELAY_INTERVALS}
      placeholder="Select delay interval"
      value={delayInterval}
      onChange={(e, { value }) => setDelayInterval(value)}
      disabled={disabled}
    />
  </span>
);

DelaySelect.propTypes = {
  delay: PropTypes.string,
  delayInterval: PropTypes.number,
  setDelay: PropTypes.func,
  setDelayInterval: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DelaySelect;
