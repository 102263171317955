import { request } from '../actions';
import { FETCH_OPEN_ANSWERS_FORM_METRICS } from '../actions/analyticsActions';

const { SUCCESS } = request;

const openQuestions = (state = {}, action) => {
  switch (action.type) {
    case FETCH_OPEN_ANSWERS_FORM_METRICS[SUCCESS]: {
      return {
        ...state, ...{ [action.fieldName]: action.response.Items },
      };
    }
    default:
      return state;
  }
};

export default openQuestions;
