export const textTypeOperators = [
  {
    text: 'contains',
    value: 'contains',
  },
  {
    text: 'equals',
    value: 'equals',
  },
  {
    text: 'is empty',
    value: 'isEmpty',
  },
  {
    text: 'is not empty',
    value: 'isNotEmpty',
  },
  {
    text: 'has tags',
    value: 'hasTag',
  },
];

export const integerTypeOperators = [
  {
    text: 'equals',
    value: 'equals',
  },
  {
    text: 'is empty',
    value: 'isEmpty',
  },
  {
    text: 'is not empty',
    value: 'isNotEmpty',
  },
  {
    text: 'greater than',
    value: 'greaterThan',
  },
  {
    text: 'lower than',
    value: 'lowerThan',
  },
];

export const selectTypeOperators = [
  {
    text: 'is member of',
    value: 'isMemberOf',
  },
  {
    text: 'is not member of',
    value: 'isNotMemberOf',
  },
];
