import React, { useState } from 'react';
import parse from 'parse-color';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import PropTypes from 'prop-types';

// Hooks.
import { useOutsideClick } from '../../hooks/useOutsideClick';

// Styles.
import './ColorSelector.scss';

const COLOR_PICKER_PRESET_COLORS = [
  '#61BD6D',
  '#41A85F',
  '#00A885',
  '#19BC9C',
  '#54ACD2',
  '#2C82C9',
  '#3D8EB9',
  '#2969B0',
  '#475577',
  '#28324E',
  '#9365B8',
  '#553982',
  '#CCCCCC',
  '#4A4A4A',
  '#FFFFFF',
  '#000000',
  '#D1D5D8',
  '#EFEFEF',
  '#A38F84',
  '#F7DA64',
  '#FAC51C',
  '#FBA026',
  '#EB6B56',
  '#E25041',
  '#F37934',
  '#D14841',
  '#B8312F',
];

const ColorSelector = ({
  label,
  currentColor,
  onChange,
  hasInput,
}) => {
    const parsedColor = parse(currentColor).hex;
    const initialColorState = parsedColor.slice();
    const [isColorPaletteVisible, setColorPaletteVisibility] = useState(false);
    const [colorState, setColorState] = useState(parsedColor);

    const handleChange = (value) => {
      setColorState(value);
    };

    const handleSubmit = () => {
      onChange(colorState);
      setColorPaletteVisibility(false);
    };

    const handleOutsideClick = () => {
      setColorPaletteVisibility(false);
      setColorState(initialColorState);
    };

    const ref = useOutsideClick(() => handleOutsideClick());

    return (
      <div className="ColorSelector">
        <button
          type="button"
          onClick={() => setColorPaletteVisibility(true)}
          className="ColorSelector__color-selector-cta"
        >
          <p className="ColorSelector__label">{label}</p>
          <div className="ColorSelector__color-info-container">
            <div className="ColorSelector__current-color-display" style={{ backgroundColor: parsedColor }}>
            </div>
            <div className="ColorSelector__current-color-code">
              {parsedColor}
            </div>
          </div>
        </button>

        {isColorPaletteVisible && (
          <div className="ColorSelector__color-palette-container" ref={ref}>
            <HexColorPicker color={colorState || parsedColor} onChange={handleChange} />
            {hasInput && (
              <div className="ColorSelector__input-container">
                <HexColorInput
                  className="ColorSelector__color-input"
                  placeholder="HEX code here"
                  color={colorState || parsedColor}
                  onChange={handleChange}
                />
              </div>
            )}
            <button
              type="button"
              className="ColorSelector__cta-button"
              onClick={handleSubmit}
            >
              Confirm
            </button>
            <div className="ColorPicker__presets">
              {COLOR_PICKER_PRESET_COLORS.map((presetColor, index) => (
                <div
                  key={index}
                  className="ColorPicker__preset-color"
                  style={{ backgroundColor: presetColor }}
                  onClick={() => handleChange(presetColor)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
};

ColorSelector.propTypes = {
  label: PropTypes.string.isRequired,
  currentColor: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasInput: PropTypes.bool,
};

export default ColorSelector;
