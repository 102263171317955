import { cloneElement, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Contacts } from '../../containers/Page';
import { DeliveryMethodForm } from '../../containers/Form';
import { MessageContext } from '../../context/messageEditor';
import Editor from '../../editor';

const MessageCreationWizard = ({ handleMessageClose }) => {
  const contextData = useContext(MessageContext);
  const {
    currentStep,
    list,
    addNewList,
  } = contextData;

  const [Component, props] = useMemo(() => {
    switch (currentStep) {
      case 'contacts':
        return [
          <Contacts />,
          {
            messageEditor: true,
            list,
            addNewList,
          },
        ];
      case 'delivery':
        return [<DeliveryMethodForm />, { handleMessageClose }];
      default:
        return [<Editor />, { ...contextData, handleMessageClose }];
    }
  }, [contextData]);

  return cloneElement(Component, props);
};

MessageCreationWizard.propTypes = {
  handleMessageClose: PropTypes.func,
};

export default MessageCreationWizard;
