import {
  call,
  takeLatest,
  select,
  put,
} from 'redux-saga/effects';

import { find, get } from 'lodash';
import { genericActions, messageActions, notificationActions } from '../actions';

import { api } from '../services';
import {
  requestSaga,
  showSuccessMessage,
  showGlobalError,
  clearPaginatorSaga,
} from './genericSagas';
import * as selectors from './selectors';
import { getCurrentPageNumber } from '../modules/redux-paginator';

const { notifySuccess, notifyError } = notificationActions;


const {
  messages: {
    messageDuplicate,
    messageDeletion,
    messageUpdate,
    messageGet,
    messageCreate,
    messageSave,
    messageSend,
    recipientsMetadataGet,
  },
} = api;

const {
  duplicateMessage,
  deleteMessage,
  updateMessage,
  requestModifiedMessagesPage,
  fetchMessage,
  createMessage,
  createMessageAutomation,
  saveMessage,
  updateMessageEditorState,
  sendMessage,
  editMessage,
  saveMessageDeliveryData,
  getRecipientsMetadata,
  DELETE_MESSAGE,
  UPDATE_MESSAGE,
  FETCH_MESSAGE,
  CREATE_MESSAGE,
  CREATE_MESSAGE_AUTOMATION,
  SAVE_MESSAGE,
  SEND_MESSAGE,
  EDIT_MESSAGE,
  SAVE_MESSAGE_DELIVERY_DATA,
  GET_RECIPIENTS_METADATA,
} = messageActions;
const { GO_TO_PAGE } = genericActions;

function* fetchModifiedMessagesPageSaga() {
  const state = yield select();
  const msgFilter = state.messagesPaginator.currentPages.messages;

  const messagesPaginator = yield select(selectors.messagesPaginator);
  const currentPageNo = yield call(getCurrentPageNumber, messagesPaginator, 'messages');

  yield call(clearPaginatorSaga, 'messagesPaginator');
  yield put(requestModifiedMessagesPage(currentPageNo, msgFilter));
}

function* duplicateMessageSaga(args) {
  yield call(requestSaga, undefined, messageDuplicate, args, duplicateMessage);
}

function* duplicationSuccessSaga() {
  yield call(fetchModifiedMessagesPageSaga);
  yield call(showSuccessMessage, 'Message duplicated successfully');
}

function* deleteMessageSaga(args) {
  yield call(requestSaga, deleteMessage, messageDeletion, args, null, args);
}

function* deletionSuccessSaga(args) {
  yield call(fetchModifiedMessagesPageSaga);
  if (!args.editor.payload.editor) {
    yield call(showSuccessMessage, 'Message deleted successfully');
  }
}

function* updateMessageSaga(args) {
  yield call(requestSaga, updateMessage, messageUpdate, args);
}

function* updateMessageSuccessSaga() {
  yield call(fetchModifiedMessagesPageSaga);
  yield call(showSuccessMessage, 'Message updated successfully');
}

function* fetchMessageSaga(args) {
  yield call(requestSaga, fetchMessage, messageGet, args);
}

function* createMessageSaga(args) {
  yield call(requestSaga, createMessage, messageCreate, args);
}

function* createMessageAutomationSaga(args) {
  yield call(requestSaga, createMessageAutomation, messageCreate, args);
}

function* editMessageSaga(args) {
  yield call(requestSaga, editMessage, messageUpdate, args);
}

function* saveMessageSaga(args) {
  const {
 payload: {
    html,
    quiz,
    origin,
    messageEditor,
  },
} = args;
  const {
    s3Data: { key },
  } = messageEditor;
  yield call(requestSaga, saveMessage, messageSave, {
 payload: {
    html,
      quiz,
      s3Data: { key },
      origin,
    },
});
}

function* saveMessageSuccessSaga() {
  const messageEditor = yield select(selectors.messageEditor);
  const { autosave } = messageEditor;

  yield put(updateMessageEditorState({
    payload: {
      showAutosaveMsg: autosave,
      resolveUnderlyingStep: true,
      pendingError: undefined,
    },
  }));
}

function* saveMessageFailureSaga(args) {
  const err = get(args, 'error.message');

  yield put(updateMessageEditorState({
    payload: {
      pendingError: err,
      resolveUnderlyingStep: false,
    },
  }));
  yield call(showGlobalError, args);
}

function* saveMessageDeliveryDataSaga(args) {
  yield call(requestSaga, saveMessageDeliveryData, messageUpdate, args);
}

function* sendMessageRequestSaga(args) {
  yield call(requestSaga, sendMessage, messageSend, args);
}

function* sendMessageSuccessSaga() {
  const messageEditor = yield select(selectors.messageEditor);
  notifySuccess('Messages sent');
  yield put({ type: GO_TO_PAGE, route: `/successPage/${messageEditor.id}` });
}

function* sendMessageFailureSaga(args) {
  const { error: serviceError } = args;
  const globalError = find(serviceError, { property: '' });

  if (globalError) {
    yield put(notifyError(globalError?.message || 'Something went wrong... Please try again later.'));
  } else if (serviceError && serviceError.code && serviceError.message) {
    yield put(notifyError(serviceError.message));
  } else {
    yield put(notifyError('Something went wrong... Please try again later.'));
  }
}

function* getRecipientsMetadataSaga(args) {
  yield call(requestSaga, getRecipientsMetadata, recipientsMetadataGet, args);
}

function* redirectToEditor() {
  const messageEditor = yield select(selectors.messageEditor);
  yield put({ type: GO_TO_PAGE, route: `/message-editor/${messageEditor.id}` });
}

export default function* watchMessages() {
  yield takeLatest(duplicateMessage.REQUEST, duplicateMessageSaga);
  yield takeLatest(duplicateMessage.SUCCESS, duplicationSuccessSaga);

  yield takeLatest(DELETE_MESSAGE.REQUEST, deleteMessageSaga);
  yield takeLatest(DELETE_MESSAGE.SUCCESS, deletionSuccessSaga);
  yield takeLatest(DELETE_MESSAGE.FAILURE, showGlobalError);

  yield takeLatest(UPDATE_MESSAGE.REQUEST, updateMessageSaga);
  yield takeLatest(UPDATE_MESSAGE.SUCCESS, updateMessageSuccessSaga);
  yield takeLatest(UPDATE_MESSAGE.FAILURE, showGlobalError);

  yield takeLatest(FETCH_MESSAGE.REQUEST, fetchMessageSaga);
  yield takeLatest(FETCH_MESSAGE.FAILURE, showGlobalError);

  yield takeLatest(CREATE_MESSAGE.REQUEST, createMessageSaga);
  yield takeLatest(CREATE_MESSAGE.SUCCESS, redirectToEditor);
  yield takeLatest(CREATE_MESSAGE.FAILURE, saveMessageFailureSaga);

  yield takeLatest(CREATE_MESSAGE_AUTOMATION.REQUEST, createMessageAutomationSaga);
  yield takeLatest(CREATE_MESSAGE_AUTOMATION.FAILURE, saveMessageFailureSaga);

  yield takeLatest(EDIT_MESSAGE.REQUEST, editMessageSaga);
  yield takeLatest(EDIT_MESSAGE.SUCCESS, saveMessageSaga);
  yield takeLatest(EDIT_MESSAGE.FAILURE, saveMessageFailureSaga);

  yield takeLatest(SAVE_MESSAGE.REQUEST, saveMessageSaga);
  yield takeLatest(SAVE_MESSAGE.SUCCESS, saveMessageSuccessSaga);
  yield takeLatest(SAVE_MESSAGE.FAILURE, saveMessageFailureSaga);

  yield takeLatest(SEND_MESSAGE.REQUEST, sendMessageRequestSaga);
  yield takeLatest(SEND_MESSAGE.SUCCESS, sendMessageSuccessSaga);
  yield takeLatest(SEND_MESSAGE.FAILURE, sendMessageFailureSaga);

  yield takeLatest(SAVE_MESSAGE_DELIVERY_DATA.REQUEST, saveMessageDeliveryDataSaga);
  yield takeLatest(SAVE_MESSAGE_DELIVERY_DATA.SUCCESS, saveMessageSuccessSaga);
  yield takeLatest(SAVE_MESSAGE_DELIVERY_DATA.FAILURE, saveMessageFailureSaga);

  yield takeLatest(GET_RECIPIENTS_METADATA.REQUEST, getRecipientsMetadataSaga);
  yield takeLatest(GET_RECIPIENTS_METADATA.FAILURE, showGlobalError);
}
