import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  filter,
  get,
} from 'lodash';
import { ListSelection, ListView } from '../../components/Contacts';
import {
  contactActions,
  genericActions,
  accountActions,
} from '../../actions';
import {
  getCurrentPageNumber,
  getCurrentPageResults,
  getCurrentTotalResultsCount,
  isCurrentPageFetching,
} from '../../modules/redux-paginator';
import { DEFAULT_PAGE_SIZE, TABLET_MAX_WIDTH } from '../../common';
import { ContactTagModal, ListActionsModal } from '../Modal';

const {
  fetchContactProperties,
  fetchTags,
  mutateContactTag,
  deleteContact,
  requestContactsPage,
  clearContactFilter,
  fetchLists,
  fetchViews,
} = contactActions;
const { closeModalPortal, openModalPortal, openConfirmationModal } = genericActions;
const { getAccount } = accountActions;

const pageSizeOptions = [
  { key: 10, value: 10, text: 10 },
  { key: 20, value: 20, text: 20 },
  { key: 50, value: 50, text: 50 },
  { key: 100, value: 100, text: 100 },
  { key: 200, value: 200, text: 200 },
];

const Contacts = ({
  list,
  addNewList,
  clearContactFilterA,
  fetchContactPropertiesA,
  fetchTagsA,
  requestContactsPageA,
  pageSize,
  fetchListsA,
  fetchViewsA,
  contactPaginator,
  contactView,
  contactFilter,
  openConfirmationModalA,
  mutateContactTagA,
  deleteContactA,
  getAccountA,
  openModalPortalA,
  closeModalPortalA,
  lists,
  messageEditor,
  setContactsPageSize,
  message,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selected, setSelected] = useState([]);
  const [excluded, setExcluded] = useState([]);
  const [currentPageResults, setCurrentPageResults] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [loading, setLoading] = useState(true);
  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPageSizes, setCurrentPageSizes] = useState(pageSizeOptions);
  const [totalCount, setTotalCount] = useState(0);
  const [selectionVisible, setSelectionVisible] = useState(false);
  const [currentList, setCurrentList] = useState('all');
  const [unselect, setUnselect] = useState(false);
  const [currentAddNewList, setCurrentAddNewList] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [propertyId, setPropertyId] = useState(0);
  const [orderBy, setOrderBy] = useState('desc');

  useEffect(() => {
    if (list) {
      const listId = String(list) === 'none' || Number(list) === 0 ? 'all' : list;
      setCurrentList(listId);
    }
    if (addNewList) {
      setCurrentAddNewList(addNewList);
    }
    setSelectionVisible(window.innerWidth > 991);

    clearContactFilterA();
    fetchContactPropertiesA();
    fetchTagsA();
    window.addEventListener('resize', updateWindowDimensions);
    requestContactsPageA(1, undefined, pageSize, list, propertyId, orderBy);
    fetchListsA();
    fetchViewsA();

    return () => {
      clearContactFilterA();
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    const currentPage = getCurrentPageResults(contactView, contactPaginator, 'contact');

    const total = getCurrentTotalResultsCount(contactPaginator, 'contact');

    if (totalCount !== total && (total || total === 0)) {
      let sizes = [];
      if (total < 200) {
        sizes = filter(pageSizeOptions, (val) => val.value < total);
        sizes.push({ key: total, value: total, text: 'All' });
      }

      setCurrentPageSizes(sizes);
      setTotalCount(total);
    }

    setCurrentPageResults(currentPage);
    setCurrentPageNo(getCurrentPageNumber(contactPaginator, 'contact'));
    setLoading(isCurrentPageFetching(contactPaginator, 'contact'));
  }, [contactPaginator, contactView]);

  const onMenuItemClick = (itemName) => {
    switch (itemName) {
      case 'removeTags':
        setTimeout(() => openTagModal('remove'), 10);
        break;
      case 'addTags':
        // First we fire onDocumentClick event, then open dialog
        setTimeout(() => openTagModal('add'), 10);
        break;

      case 'delete':
        handleContactDelete('*');
        break;
      default:
        break;
    }
  };

  const onPageChange = (newPageNo, updatedPageSize) => {
    const filterId = get(contactFilter, 'id', undefined);

    if (updatedPageSize) {
      setCurrentPageSize(updatedPageSize);
    }
    const updatedPageNo = newPageNo + 1;
    setPageNo(updatedPageNo);
    requestContactsPageA(
      updatedPageNo,
      filterId,
      updatedPageSize || currentPageSize,
      currentList,
      propertyId,
      orderBy,
    );
  };

  const setSelectedAndExcluded = (newSelected = [], newExcluded = []) => {
    setSelected(newSelected);
    setExcluded(newExcluded);
  };

  const handleContactDelete = (id) => {
    const filterId = get(contactFilter, 'id', undefined);

    if (id === '*') {
      openConfirmationModalA({
        actionName: 'delete',
        type: 'remove',
        callbackFunction: () => {
          mutateContactTagA({
            payload: {
              type: 'DELETE',
              ids: typeof selected === 'string' ? [] : selected,
              listId: typeof currentList === 'number' ? currentList : 0,
              filterId,
              excluded,
              propertyId,
              orderBy,
            },
          });
          setSelected([]);
          setUnselect(true);
          getAccountA();
        },
        itemName: 'contacts',
      });
    } else {
      openConfirmationModalA({
        actionName: 'delete',
        type: 'remove',
        callbackFunction: () => {
          deleteContactA({
            payload: {
              id,
              currentList,
              filterId,
              propertyId,
              orderBy,
            },
          });
          const index = selected.indexOf(id);
          if (index > -1) {
            selected.splice(index, 1);
            setSelected(selected);
          }
          getAccountA();
        },
        onClose: closeModalPortal,
        itemName: 'contact',
      });
    }
  };

  const updateWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
    setSelectionVisible(window.innerWidth > 991);
  };

  const handleUnselect = () => {
    setUnselect(true);
  };

  const calculatePageSize = () => {
    if (totalCount - (currentPageNo * pageSize) >= 0) {
      return pageSize;
    }
    return totalCount - ((currentPageNo - 1) * pageSize);
  };

  const listAction = (type) => {
    openModalPortalA({
      content: <ListActionsModal
        selected={selected}
        currentPageResults={currentPageResults}
        closeModalPortal={closeModalPortalA}
        lists={lists}
      />,
      contentProps: {
        size: windowWidth < TABLET_MAX_WIDTH ? 'fullscreen' : 'small',
        closeModalPortal: () => {
          closeModalPortalA();
        },
        handleUnselect,
        type,
        dimmer: false,
        selected,
        windowWidth: window.innerWidth < TABLET_MAX_WIDTH,
        list: currentList,
        excluded,
        propertyId,
        orderBy,
      },
    });
  };

  const openTagModal = (type) => {
    const filterId = get(contactFilter, 'id', undefined);

    openModalPortalA({
      content: <ContactTagModal
        selected={selected}
        currentPageResults={currentPageResults}
        closeModalPortal={closeModalPortalA}
        lists={undefined}
      />,
      contentProps: {
        size: windowWidth < TABLET_MAX_WIDTH ? 'fullscreen' : 'small',
        closeModalPortal: () => {
          closeModalPortalA();
        },
        handleUnselect,
        type,
        dimmer: false,
        selected,
        windowWidth: window.innerWidth < TABLET_MAX_WIDTH,
        list: currentList,
        excluded,
        propertyId,
        filterId,
        orderBy,
      },
    });
  };

  const handleSelectionVisibility = () => {
    if (window.innerWidth <= 991) {
      setSelectionVisible(!selectionVisible);
    }
  };

  const changeView = (id) => {
    clearSort();
    setCurrentList(id);
    setUnselect(true);
    clearContactFilterA({ pageSize, list: id });
    handleSelectionVisibility();
  };

  const contactsUnselected = () => {
    setUnselect(false);
  };

  const listAdded = () => {
    setCurrentAddNewList(false);
  };

  const sortTable = (id) => {
    const filterId = get(contactFilter, 'id', undefined);

    let property;
    let order;

    if (id !== propertyId) {
      property = id;
      order = 'asc';
    } else if (orderBy === 'desc') {
      property = 0;
      order = 'desc';
    } else {
      property = id;
      order = 'desc';
    }

    setPropertyId(property);
    setOrderBy(order);
    requestContactsPageA(pageNo, filterId, currentPageSize, currentList, property, order);
  };

  const clearSort = () => {
    setPropertyId(0);
    setOrderBy('desc');
  };

  return (
    <>
      <div className="contact-page">
        <ListSelection
          visible={selectionVisible}
          changeView={changeView}
          currentList={currentList}
          windowWidth={windowWidth}
          addNewList={currentAddNewList}
          listAdded={listAdded}
          messageEditor={messageEditor}
          message={message}
        />
        <ListView
          messageEditor={messageEditor}
          setContactsPageSize={setContactsPageSize}
          windowWidth={windowWidth}
          handleContactDelete={handleContactDelete}
          currentPageNo={currentPageNo}
          loading={loading}
          pageSize={currentPageSize}
          currentPageSizes={currentPageSizes}
          calculatePageSize={calculatePageSize}
          onPageChange={onPageChange}
          totalCount={totalCount}
          currentPageResults={currentPageResults}
          handleSelectionVisibility={handleSelectionVisibility}
          setSelected={setSelectedAndExcluded}
          list={currentList}
          unselect={unselect}
          contactsUnselected={contactsUnselected}
          changeView={changeView}
          sortTable={sortTable}
          clearSort={clearSort}
          propertyId={propertyId}
          orderBy={orderBy}
          onMenuItemClick={onMenuItemClick}
          listAction={listAction}
        />
      </div>
    </>
  );
};

Contacts.propTypes = {
  messageEditor: PropTypes.bool,
  setContactsPageSize: PropTypes.func,
  openConfirmationModalA: PropTypes.func,
  mutateContactTagA: PropTypes.func,
  deleteContactA: PropTypes.func,
  getAccountA: PropTypes.func,
  fetchContactPropertiesA: PropTypes.func,
  fetchTagsA: PropTypes.func,
  contactView: PropTypes.object,
  contactPaginator: PropTypes.object,
  contactFilter: PropTypes.object,
  pageSize: PropTypes.number,
  requestContactsPageA: PropTypes.func,
  clearContactFilterA: PropTypes.func,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  fetchListsA: PropTypes.func,
  lists: PropTypes.array,
  list: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  addNewList: PropTypes.bool,
  message: PropTypes.object,
  fetchViewsA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  contactView: state.contactView,
  contactPaginator: state.contactPaginator,
  contactFilter: state.contactFilter,
  pageSize: state.contactFilter.pageSize || DEFAULT_PAGE_SIZE,
  lists: state.lists.elements,
  message: state.messageEditor,
});

const mapDispatchToProps = (dispatch) => ({
  openConfirmationModalA: bindActionCreators(openConfirmationModal, dispatch),
  mutateContactTagA: bindActionCreators(mutateContactTag.request, dispatch),
  deleteContactA: bindActionCreators(deleteContact.request, dispatch),
  getAccountA: bindActionCreators(getAccount.request, dispatch),
  fetchContactPropertiesA: bindActionCreators(fetchContactProperties.request, dispatch),
  fetchTagsA: bindActionCreators(fetchTags.request, dispatch),
  requestContactsPageA: bindActionCreators(requestContactsPage, dispatch),
  clearContactFilterA: bindActionCreators(clearContactFilter, dispatch),
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
  fetchListsA: bindActionCreators(fetchLists.request, dispatch),
  fetchViewsA: bindActionCreators(fetchViews.request, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
