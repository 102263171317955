import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components.
import CreateElementButton from '../../components/CreateElementButton';

// Constants
import { BUTTON } from '../../constants/widgetTypes';

// Context.
import {
  EditorContext,
} from '../../context/EditorContext';

const ButtonWidget = () => {
  const {
    addElement,
    setActiveElement,
  } = useContext(EditorContext);

  const createButtonElement = () => {
    const elementId = uuidv4();
    const defaultElementPropertiesObj = {
      elementType: BUTTON,
      id: elementId,
      text: 'Button',
      link: 'https://',
      bgColor: '#0052a1',
      textColor: '#fff',
      size: '24px',
    };

    addElement(defaultElementPropertiesObj);
    setActiveElement(defaultElementPropertiesObj);
  };

  return (
    <CreateElementButton
      title="BUTTON"
      iconName="radio_button_checked"
      onClick={() => createButtonElement()}
    />
  );
};

export default ButtonWidget;
