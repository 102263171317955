import { omit } from 'lodash';
import { accountsPaginator } from '../paginators';
import { FETCH_ACCOUNT_BY_ID } from '../actions/accountActions';
import { SUCCESS } from '../actions/request';

const accounts = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_BY_ID[SUCCESS]: {
      let newState = state;
      newState = {
        ...newState,
        [action.id]: omit(action, 'type'),
      };

      return newState;
    }
    default: {
      const { meta } = action;
      const accountsPagination = accountsPaginator.itemsReducer(state, action);
      if (meta && meta.name === 'accounts') {
        return accountsPagination;
      }
      return state;
    }
  }
};

export default accounts;
