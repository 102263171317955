export const DOMAIN_INFO_CONTENT = [
  {
    header: 'What are domains?',
    content: 'A domain is an address for your website. Similarly to your home address, a domain is where you can invite people over to see your website.',
  },
  {
    header: 'How are domains used in Rispons?',
    content: (
      <div>
        <div>
          Domains are added to increase credibility. By default all emails are sent
          from support@rispons.com and links are shown as m.rispons.com.
        </div>
        <div>
          Adding a domain will help you increase your brand awareness
          and message credibility to your contacts.
        </div>
      </div>
    ),
  },
  {
    header: 'What\'s the difference between email and email domain validation?',
    content: (
      <div>
        <div>
          Before sending an email with your email address you have to validate it.
          You&apos;ll only need to click a link and you&apos;re done.
        </div>
        <div>
          But to send links from your own domain and improve delivery,
          you&apos;ll need to validate your whole domain.
          You&apos;ll need some IT skills or contact us for help.
        </div>
      </div>
    ),
  },
  {
    header: 'What is SMS domain verification?',
    content: 'SMS domains help you send messages from your own domain. Set your domain as default, to assign the verified SMS domain as the default link for your messages.',
  },
  {
    header: 'Still need help?',
    content: 'We understand that it\'s complicated. If you need help contact us support@rispons.com',
  },
];
