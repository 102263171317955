import PropTypes from 'prop-types';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';

const Dropdown = ({
  input,
  options,
  disabled,
  placeholder,
  className,
  search,
  selection,
  direction,
  inline,
  id,
  onSearchChange,
  allowAdditions,
  onAddItem,
  meta: { touched, error },
}) => (
  <div>
    <SemanticDropdown
      search={search}
      selection={selection}
      onSearchChange={onSearchChange}
      {...input}
      options={options}
      onChange={(param, data) => input.onChange(data.value)}
      disabled={disabled}
      placeholder={placeholder}
      className={className}
      direction={direction}
      inline={inline}
      error={touched && !!error}
      id={id}
      allowAdditions={allowAdditions}
      onAddItem={(e, { value }) => onAddItem(value)}
    />
    { touched && error && <small className="error-message">{error}</small> }
  </div>
);

Dropdown.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  }),
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  direction: PropTypes.string,
  inline: PropTypes.bool,
  id: PropTypes.string,
  search: PropTypes.bool,
  selection: PropTypes.bool,
  onSearchChange: PropTypes.func,
  allowAdditions: PropTypes.bool,
  onAddItem: PropTypes.func,
};

export default Dropdown;
