import PropTypes from 'prop-types';
import { has } from 'lodash';
import moment from 'moment';
import { calculateDuration, convertFromBytes } from '../../utils/helper';

const MediaItemData = ({
  item,
}) => {
  const {
    filename,
    date,
    size,
    width,
    height,
    duration,
    type,
    notification,
  } = item;

  const getItemType = () => {
    let text = filename.split('.')[1].toLocaleUpperCase();

    text = text === 'JPG' ? 'JPEG' : text;

    return `${text} ${type}`;
  };

  return (
    <div className="media-library__preview__data">
      {has(item, 'notification') ? (
        <div className="media-library__preview__data__row">
          <div className="media-library__preview__data__row__notification">
            {notification}
          </div>
        </div>
      ) : (
        <>
          <div className="media-library__preview__data__row">
            <div className="media-library__preview__data__row__title">
              File type:
            </div>
            <div className="media-library__preview__data__row__value">
              {getItemType()}
            </div>
          </div>
          <div className="media-library__preview__data__row">
            <div className="media-library__preview__data__row__title">
              Uploaded on:
            </div>
            <div className="media-library__preview__data__row__value">
              {moment(date).format('YYYY/MM/DD')}
            </div>
          </div>
          <div className="media-library__preview__data__row">
            <div className="media-library__preview__data__row__title">
              File size:
            </div>
            <div className="media-library__preview__data__row__value">
              {convertFromBytes(size)}
            </div>
          </div>
          {width && height && (
            <div className="media-library__preview__data__row">
              <div className="media-library__preview__data__row__title">
                Dimensions:
              </div>
              <div className="media-library__preview__data__row__value">
                {`${width} x ${height}`}
              </div>
            </div>
          )}
        </>
      )}
      {has(item, 'duration') && type === 'video' && (
        <div className="media-library__preview__data__row">
          <div className="media-library__preview__data__row__title">
            Duration:
          </div>
          <div className="media-library__preview__data__row__value">
            {calculateDuration(duration)}
          </div>
        </div>
      )}
    </div>
  );
};

MediaItemData.propTypes = {
  item: PropTypes.object,
};

export default MediaItemData;
