import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, reduxForm, Field } from 'redux-form';
import { withRouter } from 'react-router';
import {
  filter,
  find,
  forEach,
  map,
  orderBy,
} from 'lodash';
import { IconButton } from '../../components/Button';
import { analyticsDataExport } from '../../services/eventsApi';
import { notifyError } from '../../actions/notificationActions';
import { RenderSelect } from '../../components/Field';

const exportDelimiter = ';';

const prepareSelectValueForPayload = (values) => {
  if (Array.isArray(values)) {
    return map(values, (value) => value.value);
  }
  if (typeof values === 'string') {
    if (values === '') {
      return [];
    }
    return values.split(exportDelimiter);
  }
  return [];
};

const ExportAnalyticsDataForm = ({
  properties,
  surveys,
  notifyErrorA,
  handleSubmit,
  match: {
    params: { id },
  },
}) => {
  const [exportLink, setExportLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [isGenerateDisabled, setGenerateDisabled] = useState(false);

  const resetForm = () => {
    setGenerateDisabled(false);
    setExportLink('');
  };

  const handleExport = (values) => {
    setLoading(true);
    setGenerateDisabled(true);
    const { contact, survey } = values;
    const body = {};
    if (contact) {
      body.properties = prepareSelectValueForPayload(contact);
    }
    if (survey) {
      body.surveys = prepareSelectValueForPayload(survey);
    }
    analyticsDataExport({ id, body }).then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
        setLoading(false);
        setExportLink('');
      } else {
        setExportLink(res.response.url);
        setLoading(false);
      }
    });
  };

  const openNewTab = () => {
    if (exportLink) {
      window.open(exportLink);
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleExport)} className="ris-form export-data-form">
      <span className="export-data-form__label">Export contact data</span>
      <Field
        component={RenderSelect}
        placeholder="Select contact data"
        name="contact"
        className="export-data-form__select"
        onChange={resetForm}
        options={properties}
        delimiter={exportDelimiter}
        multi
        simpleValue
      />
      <span className="export-data-form__label">Export analytical data</span>
      <Field
        component={RenderSelect}
        placeholder="Select analytical data"
        name="survey"
        className="export-data-form__select"
        onChange={resetForm}
        options={surveys}
        delimiter={exportDelimiter}
        multi
        simpleValue
      />
      <span className="export-data-form__info">All survey analytical data will be exported</span>
      <div className="export-data-form__actions">
        <div className="export-data-form__action">
          {loading ? (
            <span className="export-data-form__action__label">Please wait while your file generates</span>
          ) : (
            <span className="export-data-form__action__label">{isGenerateDisabled ? 'Your file is generated' : 'Generate your file'}</span>
          )}
          <IconButton
            className="export-data-form__action__button"
            iconName="build"
            text="Generate"
            type="submit"
            disabled={loading || isGenerateDisabled}
            loading={loading}
          />
        </div>
        <div className="export-data-form__action">
          {loading ? (
            <span className="export-data-form__action__label">Please wait while your file generates</span>
          ) : (
            <span className="export-data-form__action__label">{exportLink ? 'Download your generated file' : 'You need to generate a file before downloading it'}</span>
          )}
          <IconButton
            className="export-data-form__action__button"
            iconName="download"
            text="Download"
            type="button"
            disabled={!exportLink || loading}
            loading={loading}
            onClick={openNewTab}
          />
        </div>
      </div>
    </Form>
  );
};

const formConfig = {
  form: 'exportAnalyticsData',
};

ExportAnalyticsDataForm.propTypes = {
  properties: PropTypes.array,
  surveys: PropTypes.array,
  notifyErrorA: PropTypes.func,
  handleSubmit: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

const mapStateToProps = (state, props) => {
  const {
    analyticsData,
    messages,
    entities: { contactProperties },
  } = state;
  const message = messages[props.match.params.id];

  const surveyOptions = [];
  if (analyticsData && message) {
    const { forms: surveys } = analyticsData;
    const { schema: { forms } } = message;
    forEach(surveys, (survey) => {
      const { trackingId } = survey;
      const form = find(forms, (f) => f.trackingId === trackingId);
      if (form) {
        const { displayName } = form;
        surveyOptions.push({
          value: displayName,
          label: displayName,
        });
      }
    });
  }
  const properties = map(orderBy(contactProperties, ['weight'], ['asc']), (property) => ({
    value: property.internalName,
    label: property.label,
  }));
  const initialPropertiesValue = filter(properties,
    (property) => property.value === 'contact.name' || property.value === 'contact.surname');
  return {
    surveys: surveyOptions,
    properties,
    initialValues: {
      contact: initialPropertiesValue,
      survey: surveyOptions,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  reduxForm(formConfig)(ExportAnalyticsDataForm)));
