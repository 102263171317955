import React, { useContext, useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { Divider } from 'semantic-ui-react';
import {
  EditorState,
  Modifier,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// Components.
import WidgetControllerHeader from '../../components/WidgetControllerHeader';

// Compositions.
import WidgetControllerFooter from '../../compositions/WidgetControllerFooter';

// Context.
import { EditorContext } from '../../context/EditorContext';

// Constants.
import { toolbarConfig } from '../../constants/toolbarConfig';

// Utils.
import { visibleContactProperties } from '../../../utils/map';

// Styles.
import './TextWidgetController.scss';

const TextWidgetController = ({ contactProperties }) => {
  const personalizationProperties = visibleContactProperties(orderBy(contactProperties, 'weight'));

  const {
    replaceElement,
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const {
    content,
  } = activeElement;

  const [editorState, setEditorState] = useState(
    content
      ? EditorState.createWithContent(convertFromRaw(content))
      : EditorState.createEmpty(),
  );

  useEffect(() => {
    if (activeElement?.content) {
      setEditorState(EditorState.createWithContent(convertFromRaw(activeElement.content)));
    }
  }, [activeElement.id]);

  const appendHashtag = (hashtag) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    const newContentState = Modifier.replaceText(contentState, selection, hashtag);
    const newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');

    onEditorStateChange(newEditorState);
  };

  const onEditorStateChange = (state) => {
    const dataObj = { ...activeElement };

    const contentState = state.getCurrentContent();
    const contentRawState = convertToRaw(contentState);
    dataObj.content = contentRawState;

    setActiveElement(dataObj);
    setEditorState(state);
  };

  const onSaveButtonClick = () => {
    replaceElement(activeElement);
    setActiveElement(false);
  };

  const onCancelButtonClick = () => {
    setActiveElement(false);
  };

  return (
    <div className="TextWidgetController">
      <WidgetControllerHeader title="Text settings" />
      <div className="TextWidgetController__controller-body">
        <Editor
          editorState={editorState}
          toolbar={toolbarConfig}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editor-toolbox-form__textarea"
          placeholder="Type your text here"
          onEditorStateChange={onEditorStateChange}
          hashtag={{ hashCharacter: '#', separator: ' ' }}
          stripPastedStyles={true}
        />
        <Divider />
        <p className="TextWidgetController__label">
          <i>
            Add #keywords to personalize your message
          </i>
        </p>
        <p className="TextWidgetController__label TextWidgetController__label--alternative">
          <i>
            Available keywords
          </i>
        </p>
        <div className="TextWidgetController__keywords-container">

          {personalizationProperties
            ? personalizationProperties.map((prop, index) => (
              <span
                className="TextWidgetController__keyword"
                key={index}
                onClick={() => appendHashtag('#'.concat(prop.name, ' '))}
              >
                {`#${prop.label}`}
              </span>
            ))
            : <div>Loading personalization properties...</div>}
        </div>
      </div>

      <WidgetControllerFooter
        onSaveButtonClick={() => onSaveButtonClick()}
        onCancelButtonClick={() => onCancelButtonClick()}
      />
    </div>
  );
};

TextWidgetController.propTypes = {
  contactProperties: PropTypes.object,
};

const mapStateToProps = (state) => ({
  contactProperties: state.entities.contactProperties,
});

export default connect(mapStateToProps, null)(TextWidgetController);
