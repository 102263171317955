import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Dropdown } from 'semantic-ui-react';
import { Input } from '../../../Field';
import { BasicButton } from '../../../Button';
import { domainTypeOptions } from '../../../../common/options';

const VerifyDomain = ({
  changeIdentity,
  closeModalPortal,
  checkValidity,
  delivery,
  error,
  showEmailError,
  value,
  element,
  showValidationError,
  handleTypeChange,
  type,
  selectDisabled,
}) => {
  const [focused, setFocused] = useState(false);
  const [touched, setTouched] = useState(false);

  const { push } = useHistory();

  const handleChange = (e, { value: val }) => {
    changeIdentity(val);
  };

  const handleFocus = () => {
    setTouched(true);
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const redirectDomain = () => {
    push('/account-settings#domain');
    closeModalPortal();
  };

  return (
    <div className="verify-domain">
      <div className="verify-domain__text">
        {
          delivery
            ? 'Verify that you have access to this email address. We’ll send you a verification email. Please check your email and click the link to complete the verification.'
            : 'By verifying your domain messages will appear to be sent from your organisation.'
        }
      </div>
      {!element && (
        <>
          <div className="verify-domain-type__label">
            Set up domain for:
          </div>
          <Dropdown
            options={domainTypeOptions}
            onChange={(param, data) => handleTypeChange(data.value)}
            placeholder="Select domain type"
            className="app-form__field verify-domain-type__select"
            value={type}
            disabled={selectDisabled}
          />
        </>
      )}
      {delivery && (
        <div
          onClick={redirectDomain}
          className="verify-domain__domain"
        >
          Verify your domain
        </div>
      )}
      <div className="verify-domain__verification">
        <div className="ris-form verify-domain__verification__input">
          <Input
            input={{
              value,
              onChange: handleChange,
              onFocus: handleFocus,
              onBlur: handleBlur,
            }}
            label={delivery ? 'Email address' : 'Domain'}
            placeholder={delivery ? 'Enter the email you wish to verify' : 'domainexample.com'}
            meta={{ touched: !focused && touched, error }}
            className="ui"
          />
        </div>
        {delivery && element && (
          <BasicButton
            type="button"
            content="Check"
            className="white segmentation-finish-button verify-domain__verification__button"
            onClick={() => checkValidity(value)}
          />
        )}
      </div>
      {!delivery && (
        <>
          <div className="verify-domain__domain-note verify-domain__domain-note--margin">
            Note: If you have a functioning website on your domain,
            you need to create a subdomain such as:
            m.yourdomain.com, hello.yourdomain.com, etc.
          </div>
          <div className="verify-domain__domain-note">
            If you don&apos;t have or intend to have a working website on your domain,
            you may verify your domain instead.
          </div>
        </>
      )}
      {showEmailError && (
        <div className="verify-domain__error">
          Your email has not been validated.&nbsp;
          {showValidationError ? 'Please check your email and click the link to verify your email address!' : 'Please validate your email by clicking "Send" and click the link in your email to verify your email address!'}
        </div>
      )}
      {delivery && element && (
        <>
          <div className="verify-domain__disclaimer">
            Didn&apos;t receive a verification email?
            Try again by clicking &quot;Send&quot; one more time
          </div>
          <div className="verify-domain__disclaimer">
            Unsure if your verification is successful?
            Click &quot;Check&quot;.
          </div>
        </>
      )}
    </div>
  );
};

VerifyDomain.propTypes = {
  value: PropTypes.string,
  changeIdentity: PropTypes.func,
  checkValidity: PropTypes.func,
  delivery: PropTypes.bool,
  error: PropTypes.string,
  showEmailError: PropTypes.bool,
  element: PropTypes.object,
  closeModalPortal: PropTypes.func,
  showValidationError: PropTypes.bool,
  handleTypeChange: PropTypes.func,
  type: PropTypes.string,
  selectDisabled: PropTypes.bool,
};

export default VerifyDomain;
