import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { map, find } from 'lodash';

const ContactListDropdown = ({
  lists,
  input: {
    value,
    onChange,
  },
  recipientsTouched,
  totalContacts,
  addNewList,
  messageEditor,
}) => {
  const selectedText = () => {
    if (value === 'none') {
      return 'Select List of Contacts';
    }
    if (value > 0 && lists) {
      const foundListName = find(lists, (li) => li.id === value)?.name;
      if (foundListName) {
        return foundListName;
      }
      return 'Temporary List';
    }

    return 'All Contacts';
  };

  return (
    <Dropdown text={selectedText()}>
      <Dropdown.Menu>
        {!recipientsTouched && (value === 'none' && value !== 0) && (
          <Dropdown.Item
            value="none"
            content={(
              <span className="contact-list-selection-item">
                <span className={value === 'none' ? 'contact-list-selection-item__title--active' : 'contact-list-selection-item__title'}>
                  Select List of Contacts
                </span>
              </span>
            )}
            onClick={(param, data) => onChange(data.value)}
          />
        )}
        <Dropdown.Item
          value={0}
          content={(
            <span className="contact-list-selection-item">
              <span className={value === 0 ? 'contact-list-selection-item__title--active' : 'contact-list-selection-item__title'}>
                All Contacts
              </span>
              <span className="contact-list-selection-item__count">
                {`(${totalContacts} Contacts)`}
              </span>
            </span>
          )}
          onClick={(param, data) => onChange(data.value)}
        />
        {lists && map(lists, (list) => {
          const {
            id,
            name,
            count,
            temporal,
          } = list;
          if (!temporal || (messageEditor
            && messageEditor.temporalListId && messageEditor.temporalListId === id)) {
            return (
              <Dropdown.Item
                value={id}
                content={(
                  <span className="contact-list-selection-item">
                    <span className={value === list.id ? 'contact-list-selection-item__title--active' : 'contact-list-selection-item__title'}>
                      {name}
                    </span>
                    <span className="contact-list-selection-item__count">
                      {`(${count} Contacts)`}
                    </span>
                  </span>
                )}
                key={id}
                onClick={(param, data) => onChange(data.value)}
              />
            );
          }
          return null;
        })}
        <Dropdown.Divider />
        <Dropdown.Item
          value="new"
          content={(
            <span className="contact-list-selection-item__new">New List</span>
          )}
          onClick={() => addNewList()}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

ContactListDropdown.propTypes = {
  lists: PropTypes.array,
  recipientsTouched: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  totalContacts: PropTypes.number,
  addNewList: PropTypes.func,
  messageEditor: PropTypes.object,
};

export default ContactListDropdown;
