import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  forEach,
  map,
  find,
  sortBy,
} from 'lodash';
import { withRouter } from 'react-router';
import { MessageStatsTable } from '../TableColumns';
import MsgStatsTable from '../../containers/Page/Admin/MsgStatsTable';

const defaultOptions = [
  {
    label: 'All',
    value: 'all',
    isSelected: true,
    className: 'checkbox--all',
    indeterminate: false,
  },
  {
    label: 'Sent',
    value: 'sentAt',
    isSelected: true,
    className: 'checkbox--body',
  },
  {
    label: 'Delivered',
    value: 'currentDeliveryStatus',
    isSelected: true,
    className: 'checkbox--body',
  },
  {
    label: 'Opened',
    value: 'numOfOpens',
    isSelected: true,
    className: 'checkbox--body',
  },
  {
    label: 'Last Opened',
    value: 'lastOpenedAt',
    isSelected: true,
    className: 'checkbox--body',
  },
  {
    label: 'Time to Open',
    value: 'timeToOpen',
    isSelected: true,
    className: 'checkbox--body',
  },
];

const Details = ({
  forms,
  message,
  recipientsMetrics,
  onPageChange,
}) => {
  const [openSurveys, setOpenSurveys] = useState([]);
  const [isOverview, setIsOverview] = useState(true);
  const [options, setOptions] = useState([...defaultOptions]);
  const [surveysIndexes, setSurveysIndexes] = useState({ start: -1, end: -1 });
  const [videosIndexes, setVideosIndexes] = useState({ start: -1, end: -1 });
  const [buttonsIndexes, setButtonsIndexes] = useState({ start: -1, end: -1 });
  const [attachmentsIndexes, setAttachmentsIndexes] = useState({ start: -1, end: -1 });
  const [surveyDetails, setSurveyDetails] = useState('');
  const [surveyName, setSurveyName] = useState('');
  const [form, setForm] = useState();

  const trackingEnabled = (contents, enabled) => {
    let trackable = false;
    contents.forEach((c) => {
      if (c && c.trackingPolicy[enabled]) {
        trackable = true;
      }
    });
    return trackable;
  };

  const getIndexes = (type) => {
    switch (type) {
      case 'surveys':
        return surveysIndexes;
      case 'videos':
        return videosIndexes;
      case 'buttons':
        return buttonsIndexes;
      case 'attachments':
        return attachmentsIndexes;
      default:
        return undefined;
    }
  };

  const setIndexes = (type, value) => {
    switch (type) {
      case 'surveys':
        setSurveysIndexes(value);
        break;
      case 'videos':
        setVideosIndexes(value);
        break;
      case 'buttons':
        setButtonsIndexes(value);
        break;
      case 'attachments':
        setAttachmentsIndexes(value);
        break;
      default:
    }
  };

  const generateOptions = (type, contents, displayName, tracking, opts) => {
    const o = opts;
    if (contents && trackingEnabled(contents, tracking)) {
      o[0].indeterminate = false;
      o[0].isSelected = true;
      const indexes = getIndexes(type.toLocaleLowerCase());
      if (indexes) {
        indexes.start = o.length;
        o.push({
          label: type,
          value: type.toLocaleLowerCase(),
          isSelected: true,
          className: 'checkbox--body',
          indeterminate: false,
        });

        forEach(contents, (c) => {
          if (c.trackingPolicy[tracking]) {
            o.push({
              label: c[displayName],
              value: c.trackingId,
              isSelected: true,
              className: 'checkbox--content',
            });
          }
        });
        indexes.end = o.length;
        setIndexes(type.toLocaleLowerCase(), indexes);
      }
    }

    return o;
  };

  const getOptions = () => {
    const o = generateOptions('Surveys', message.schema.forms, 'displayName',
      'submitTrackingEnabled', options);
    // for now only surveys
    // o = generateOptions('Videos', videos, 'displayName',
    //   'playbackTrackingEnabled', o);
    // o = generateOptions('Buttons', buttons, 'innerText',
    //   'clickTrackingEnabled', o);
    // o = generateOptions('Attachments', attachments,
    //   'displayName', 'downloadTrackingEnabled', o);

    setOptions([...o]);
  };

  useEffect(() => {
    if (message?.schema) {
      getOptions();
    }
  }, [message?.schema]);

  useEffect(() => {
    if (forms) {
      const surveysIds = openSurveys;
      map(forms, (f) => {
        surveysIds.push(f.id);
      });
      setOpenSurveys(surveysIds);
    }
  }, [forms]);

  const handleDetailClick = (value) => {
    setIsOverview(value);
  };

  const handleSurveyClick = (id) => {
    let f;
    const survey = find(message.schema.forms, (ff) => ff.trackingId === id);
    const name = survey ? survey.displayName : '';
    if (survey) {
      f = sortBy(survey.elements, (el) => Number(el.inputName.split('-')[0]));
    }
    setSurveyDetails(id);
    setSurveyName(name);
    setForm(f);
  };

  const recipientsMetricsContainsData = () => {
    const { elements } = recipientsMetrics;
    if (recipientsMetrics && elements && elements.length) {
      for (let i = 0; i < elements.length; i += 1) {
        if (!elements[i]) {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  const {
    elements,
    page,
    total,
    count: pageSize,
  } = recipientsMetrics;

  const containsData = recipientsMetricsContainsData();

  return (
    <div className="message-statistics">
      <MsgStatsTable
        type={isOverview}
        data={containsData ? elements : []}
        columns={MessageStatsTable({
          onNameClick: () => {},
          options,
          forms: message?.schema.forms,
          handleSurveyClick,
          form,
          trackingId: surveyDetails,
        })}
        handleDetailClick={handleDetailClick}
        onChecked={setOptions}
        options={options}
        surveysIndexes={surveysIndexes}
        videosIndexes={videosIndexes}
        buttonsIndexes={buttonsIndexes}
        attachmentsIndexes={attachmentsIndexes}
        total={total}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        surveyDetails={surveyDetails}
        handleSurveyClick={handleSurveyClick}
        surveyName={surveyName}
        loading={!containsData}
      />
    </div>
  );
};

Details.propTypes = {
  forms: PropTypes.object,
  message: PropTypes.object,
  recipientsMetrics: PropTypes.object,
  onPageChange: PropTypes.func,
};

const
  mapStateToProps = (state, props) => ({
    message: state.messages[props.match.params.id],
    contactTableHeaders: state.entities.contactProperties,
    forms: state.forms,
    recipientsMetrics: state.recipientsMetrics,
  });

export default withRouter(connect(mapStateToProps)(Details));
