import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const IconButton = ({
  className,
  onClick,
  iconName,
  inverted,
  disabled = false,
  basic,
  size = 'small',
  color = 'blue',
  type,
  text,
  content,
  before = true,
  loading,
}) => (
  <Button
    className={`icon${className ? ` ${className}` : ''}`}
    text={text}
    size={size}
    color={color}
    onClick={onClick}
    inverted={inverted}
    basic={basic}
    type={type}
    disabled={disabled}
    loading={loading}
  >
    {before && <i className="material-icons">{iconName}</i>}
    {content || text}
    {!before && <i className="material-icons material-icons--after">{iconName}</i>}
  </Button>
);

IconButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  inverted: PropTypes.bool,
  basic: PropTypes.bool,
  content: PropTypes.string,
  before: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default IconButton;
