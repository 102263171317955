import PropTypes from 'prop-types';

const AddNodeTypeItem = ({
  iconClassName,
  title,
  text,
  handleClick,
}) => (
  <div className="edge__new-node-modal__list__item" onClick={handleClick}>
    <span className={`edge__new-node-modal__list__item--icon ${iconClassName}`} />
    <div className="edge__new-node-modal__list__item--text-container">
      <div className="edge__new-node-modal__list__item--type">{title}</div>
      <div>{text}</div>
    </div>
  </div>
);

AddNodeTypeItem.propTypes = {
  iconClassName: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  handleClick: PropTypes.func,
};

export default AddNodeTypeItem;
