import { StripeProvider } from 'react-stripe-elements';
import PropTypes from 'prop-types';

import { ENV } from '../../config';
import MyStoreCheckout from './MyStoreCheckout';

const { STRIPE_API_KEY } = ENV;

const CreditCard = ({
  stripe,
  addedCard,
}) => {
  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (stripe) {
      stripe
        .createToken()
        .then((payload) => console.log('[token]', payload));
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  return (
    <div className="ris-form account-settings-form">
      <div className="account-settings__credit-info">
        <StripeProvider apiKey={STRIPE_API_KEY}>
          <MyStoreCheckout addedCard={addedCard} />
        </StripeProvider>
      </div>
    </div>
  );
};

CreditCard.propTypes = {
  stripe: PropTypes.object,
  addedCard: PropTypes.func,
};

export default CreditCard;
