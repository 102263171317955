import { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { TABLET_MAX_WIDTH } from '../../common';
import { BasicButton } from '../Button';
import { notifySuccess, notifyError } from '../../actions/notificationActions';
import { convertFromBytes } from '../../utils/helper';

const FileUpload = ({
  accept,
  maxSize,
  fileName,
  onDrop,
  notifySuccessA,
  notifyErrorA,
  maxVideoSize,
}) => {
  const [name, setFileName] = useState('');

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();

    let selectRejected = false;

    reader.onload = () => {
      setFileName(acceptedFiles[0].name);
      onDrop(reader.result, acceptedFiles[0].name, acceptedFiles[0].type);
    };
    if (rejectedFiles.length) {
      if (rejectedFiles[0].file.size > maxSize) {
        if (maxVideoSize && rejectedFiles[0].file.type.split('/')[0] === 'video') {
          if (rejectedFiles[0].file.size <= maxVideoSize) {
            selectRejected = true;
            reader.onload = () => {
              setFileName(rejectedFiles[0].file.name);

              onDrop(reader.result, rejectedFiles[0].file.name, rejectedFiles[0].file.type);
            };
            notifySuccessA('File was chosen successfully');

            reader.readAsArrayBuffer(rejectedFiles[0].file);
          } else {
            notifyErrorA(`The size of the file you are trying to upload exceeds our ${convertFromBytes(maxVideoSize)} limit. Please pick a smaller file`);
            return;
          }
        } else {
          notifyErrorA(`The size of the file you are trying to upload exceeds our ${convertFromBytes(maxSize)} limit. Please pick a smaller file`);
          return;
        }
      } else {
        notifyErrorA(rejectedFiles?.[0]?.errors?.[0]?.message || 'Something went wrong');
        return;
      }
    }
    if (!selectRejected) {
      notifySuccessA('File was chosen successfully');

      reader.readAsArrayBuffer(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    accept,
    maxSize,
    onDrop: handleDrop,
    noClick: true,
    noKeyboard: true,
  });

  const { innerWidth } = window;

  const displayName = name || fileName;

  const availableFormatString = () => {
    if (accept && Array.isArray(accept)) {
      const stringArray = [];
      accept.forEach((chunk) => {
        if (chunk === 'image/*') {
          stringArray.push('.png, .jpg');
        }
        if (chunk === 'video/mp4') {
          stringArray.push('.mp4');
        }
        if (chunk === 'video/quicktime') {
          stringArray.push('.mov');
        }
      });

      return stringArray.join(', ');
    }

    if (typeof accept === 'string') return accept;

    return '.png, .jpg, .mp4, .mov';
  };

  return (
    <Grid>
      <Grid.Row>
        <div {...getRootProps({ className: 'contacts-upload' })}>
          <input {...getInputProps()} />
          {displayName ? (
            <div className="uploaded-filename">{displayName}</div>
          ) : (
            <i className="material-icons">file_upload</i>
          )}
          {innerWidth > TABLET_MAX_WIDTH && (
            <span>
              <span>Drag and drop files here</span>
              <span>or</span>
            </span>
          )}
          <BasicButton
            content="Browse"
            size="small"
            color="blue"
            className="invert"
            onClick={open}
          />
          <span className="available-format">{availableFormatString()}</span>
        </div>
      </Grid.Row>
    </Grid>
  );
};

FileUpload.propTypes = {
  onDrop: PropTypes.func,
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  maxSize: PropTypes.number,
  fileName: PropTypes.string,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  maxVideoSize: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => ({
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default connect(null, mapDispatchToProps)(FileUpload);
