import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, submit } from 'redux-form';
import { Segment } from 'semantic-ui-react';
import SelectField from './SelectField';
import { required } from '../../utils/validator';

const ContactFilterItemMobile = ({
  fields,
  submitFormA,
  contactFilter,
  name,
  index,
  propertyOptions,
  operatorOptions,
  InputComponent,
  inputComponentProps,
}) => {
  const onRemove = (idx) => {
    fields.remove(idx);

    if (contactFilter.id && contactFilter.length !== fields.length) {
      submitFormA('contactFilter');
    }
  };

  return (
    <Segment>
      <div className="contact-filter-form--mobile__where">
        Where
        <i className="material-icons" onClick={() => setTimeout(() => onRemove(index), 10)}>close</i>
      </div>
      <div className="contact-filter-form--mobile__where">
        <Field
          name={`${name}.property`}
          component={SelectField}
          options={propertyOptions}
          placeholder="Choose column"
          validate={required}
        />
      </div>
      <div className="contact-filter-form--mobile__where">
        <Field
          name={`${name}.operator`}
          component={SelectField}
          options={operatorOptions}
          validate={required}
        />
      </div>
      <div className="contact-filter-form--mobile__input">
        {InputComponent && (
          <Field
            name={`${name}.value`}
            component={InputComponent}
            {...inputComponentProps}
          />
        )}
      </div>
    </Segment>
  );
};

ContactFilterItemMobile.propTypes = {
  name: PropTypes.string,
  propertyOptions: PropTypes.arrayOf(PropTypes.object),
  operatorOptions: PropTypes.arrayOf(PropTypes.object),
  InputComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]),
  inputComponentProps: PropTypes.shape({
    validate: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.array,
    ]),
    type: PropTypes.string,
  }),
  fields: PropTypes.shape({
    remove: PropTypes.func,
    length: PropTypes.number,
  }),
  index: PropTypes.number,
  onRemoveButtonClick: PropTypes.func,
  submitFormA: PropTypes.func,
  contactFilter: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  submitFormA: bindActionCreators(submit, dispatch),
});

const mapStateToProps = (state) => ({
  contactFilter: state.contactFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactFilterItemMobile);
