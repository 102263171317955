import { Container } from 'semantic-ui-react';

const AntiSpamPolicy = () => (
  <Container className="policy-page">
    <h1>Anti-Spam Policy</h1>
    <p className="last-updated">Updated August 10th, 2023</p>
    <p className="read-carefully">
      Please read carefully before using the
      {' '}
      <a target="_blank" href="http://www.rispons.com" rel="noreferrer">
        http://www.rispons.com
      </a>
      {' '}
      website (the “Service”)
    </p>

    <h2>1. PREAMBLE</h2>
    <p>
      This Anti-Spam Policy is by reference to Appendix 2 incorporated in the
      Terms & Conditions (the “Agreement”) posted at
      {' '}
      <a target="_blank" href="http://www.rispons.com" rel="noreferrer">
        http://www.rispons.com
      </a>
      .
    </p>
    <p>
      Our Services comprises primarily of a web-based technology platform
      through which content is delivered once a customer sends such content made
      by the customer or by someone for whom the customer is responsible. While
      each customer utilises Our Services to assist with the transmittal of
      content to its recipients We do not actually act as sender on Your behalf,
      but merely as a provider of parts of the technological infrastructure
      through which You yourself send Your content to your recipients. You are
      thus the responsible sender of the e-mails, SMS and other electronic
      messages. As the sender of e-mails, SMS and other electronic messages, You
      are required to comply with the terms set forth in the Agreement with
      Appendices and to comply with all applicable laws.
    </p>
    <p>
      Generally, spam is defined as unsolicited mass communications to legal
      entities or persons with whom You do not have a relationship or who have
      not requested (opted-in to) Your communications. Your account will be
      immediately frozen or terminated if You send spam using Our Services or
      system.
    </p>
    <p>
      Please report without any delay any suspected abuse to
      {' '}
      <a href="mailto:support@rispons.com">support@rispons.com</a>
      . Please unsubscribe, if possible, from the
      communication of any spam sender and forward the complete communication in
      question to
      {' '}
      <a href="mailto:support@rispons.com">support@rispons.com</a>
      {' '}
      so that We may take appropriate action
      against the sender in question.
    </p>

    <h2>
      2. INTERNET SERVICE PROVIDERS (ISPs) AND BLACKLIST ADMINISTRATOR RELATIONS
    </h2>
    <p>
      We have and aim to build good relations with third party internet service
      providers (ISPs) and blacklist administrators and invite them to contact
      us at
      {' '}
      <a href="mailto:support@rispons.com">support@rispons.com</a>
      {' '}
      in case of any concerns or questions.
    </p>

    <h2>3. POLICY ENFORCEMENT</h2>
    <p>
      We have a no tolerance spam policy and enforce it strictly. We provide Our
      Services only to those who follow our strict anti-spam policy.
    </p>
    <p>
      If You use Our Services to send an SMS or e-mail or other electronic
      message or campaign to a recipient whom You cannot document has consented
      to receiving that communication or with whom You cannot document to have a
      previous relationship authorising the communication,You may incur a
      penalty equal to $100 (one hundred Dollars) per substantiated incident
      (i.e per communication) or any larger amount that may be imposed under
      applicable law, with a minimum of the actual damages We would likely
      suffer from any abuse of Our system or violation of the Agreement or the
      laws that regulate sending out and the content of electronic
      communications.
    </p>
    <p>
      Measures We take to enforce Our Anti-SpamPolicy include, but are not
      limited to:
    </p>
    <ul>
      <li>
        reviewing and monitoring large list imports, including a review of the
        list source, list age, collection methods.
      </li>
      <li>
        utilising MD5 hash record to screen for characteristics of commonly
        harvested lists.
      </li>
      <li>
        utilising a content-checking utility to screen and flag any message that
        contains words common to unsolicited emails.
      </li>
    </ul>
    <p>
      We require each User to initial the following statement prior to using our
      Services:
    </p>
    <ul>
      <li>
        “I certify with my initials that all recipients consented to receiving
        the communication send out by me or that You had such previous
        relationship with the recipient that I am allowed to send out
        communications to the recipient. My usage of the Services complies with
        all data protection and privacy laws and regulations applicable to the
        countries in which I am sending any form of communications via the
        Services. I represent and warrant that any and all of my communications
        outside my own entity will contain a working mandatory
        unsubscribe/opt-out link at the bottom of the message.”
      </li>
      <li>
        Any User found to be using Our Services in violation of Our
        Anti-SpamPolicy will be immediately cut-off from use of the Service.
      </li>
      <li>
        It is mandatory that any and all communications outside my own entity
        via Our Services contains an unsubscribe/opt-out link at the bottom of
        the message and that this link cannot be removed from the communication.
      </li>
    </ul>

    <h2>4. PROCEDURE FOR HANDLING COMPLAINTS</h2>
    <h3>a. General</h3>
    <p>
      Our Service may be used only by legal entities (businesses and
      organisations) and only in connection with their established list of
      permission-based opt-in recipients or with recipients with whom You have
      such previous relationship that You are authorised to use Our Service to
      communicate with them.
    </p>
    <p>To determine whether You have sent spam we may:</p>
    <ul>
      <li>Review the content of the message or campaign in question.</li>
      <li>
        Review your subscriber list for patterns common to harvested lists.
      </li>
      <li>Review any spam complaint.</li>
      <li>
        Review the records to verify when the recipient subscribed to your
        subscriber list and the subscriber’s IP address.
      </li>
    </ul>

    <h3>b. Procedure for Handling False Positive Complaints</h3>
    <p>
      We realise that it is possible that You are falsely accused of sending out
      spam by persons who have forgotten that they have subscribed to Your
      communications. For this reason, We might review Your contact list to
      check for opt-in and the date the recipient opted-in according to Your
      contact list. If We have record of the recipient opting-in and the content
      of Your communications containing the content the recipient subscribed to,
      then We will notify such recipient of the date they subscribed to
      receiving Your communications and provide the recipient a link to
      unsubscribe from Your list.
    </p>
    <p>
      Upon such complaint, We will notify You of the complaint, inform You about
      the action taken and potentially suggest changes to the notifications or
      descriptions You provide when subscribers sign up for your lists.
    </p>

    <h3>c. Transfer of Recipients</h3>
    <p>
      Please note that a recipient subscribing to one of Your lists does not
      allow You to transfer that recipient to other of Your subscriber lists or
      to send the recipient content that differs from the content the recipient
      has subscribed to.
    </p>

    <h2>5. PROCEDURE FOR HANDLING SUBPOENAS</h2>
    <p>
      In case someone wants to request information of one of Our customers in
      connection with a civil case said person or legal entity must serve Us
      with a subpoena or court order that is valid and enforceable according to
      applicable law, and agree to Our terms of compensation set forth herein.
      All civil subpoenas should be directed to Our legal representative.
      Generally, it will take approximately three (3) weeks after the receipt of
      a valid subpoena or court order to produce any information; unless a
      formal objection is filed by You or We are legally required to do so. We
      charge $250 (two hundred and fifty Dollars) per hour for research (plus
      additional fees if testimony or deposition is required), $0.25 (twenty
      five cents) per page and $65 (sixty five Dollars) to respond via Express
      post.
    </p>
  </Container>
);

export default AntiSpamPolicy;
