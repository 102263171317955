import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, reduxForm, Field } from 'redux-form';
import { email, phone, required } from '../../utils/validator';
import { Input, PhoneInputField, Textarea } from '../../components/Field';

const GetQuoteForm = ({ handleSubmit, onFormSubmit }) => (
  <Form
    onSubmit={handleSubmit(onFormSubmit.bind(this))}
    className="nobackground-flex quote-form ris-form"
  >
    <Grid>
      <Grid.Row>
        <Grid.Column className="centered" computer={10} tablet={12} mobile={14}>
          <Field
            label="Full name"
            component={Input}
            name="name"
            validate={required}
            placeholder="Enter your name"
            disabled={true}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="centered" computer={10} tablet={12} mobile={14}>
          <Field
            label="Company name"
            component={Input}
            name="companyName"
            validate={required}
            placeholder="Enter your company name"
            disabled={true}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="centered" computer={10} tablet={12} mobile={14}>
          <Field
            label="Email"
            component={Input}
            name="email"
            validate={[email, required]}
            placeholder="Enter your email"
            disabled={true}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="centered" computer={10} tablet={12} mobile={14}>
          <Field
            labelClassName=""
            label="Phone number"
            component={PhoneInputField}
            name="phone"
            validate={[phone, required]}
            placeholder="Phone number"
            disabled={true}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="centered " computer={10} tablet={12} mobile={14}>
          <Field
            label="Comments"
            component={Textarea}
            name="comment"
            validate={required}
            placeholder="Write the comments about your needs"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
);

const formConfig = {
  form: 'GetQuoteForm',
  destroyOnUnmount: false,
};

GetQuoteForm.propTypes = {
  handleSubmit: PropTypes.func,
  onFormSubmit: PropTypes.func,
};

const mapStateToProps = (state) => ({
  initialValues: {
    name: state.user.fullName,
    companyName: state.account.companyName,
    email: state.user.email,
    phone: state.user.phone,
  },
});

export default connect(mapStateToProps)(reduxForm(formConfig)(GetQuoteForm));
