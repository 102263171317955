import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { map } from 'lodash';
import { IconButton } from '../../components/Button';
import { TablePager } from '../../components/Pager';
import { DEFAULT_PAGE_SIZE } from '../../common';
import { Spinner } from '../../components/Spinner';
import { automationActions, notificationActions, genericActions } from '../../actions';
import {
  getCurrentPageNumber,
  getCurrentPageResults,
  getCurrentTotalResultsCount,
  isCurrentPageFetching,
  clearPaginatorPromiseArray,
} from '../../modules/redux-paginator';
import AutomationItem from '../../components/Automation/List/AutomationItem';
import { automationItemDelete, automationItemCreate } from '../../services/automationApi';
import { NewAutomationModal, NodeAddEditModal } from '../Modal';

const { requestAutomationListPage } = automationActions;
const { notifySuccess, notifyError } = notificationActions;
const { openConfirmationModal, openModalPortal, closeModalPortal } = genericActions;

const AutomationList = ({
  automationList,
  automationPaginator,
  requestAutomationListPageA,
  notifySuccessA,
  notifyErrorA,
  openConfirmationModalA,
  openModalPortalA,
  closeModalPortalA,
}) => {
  const [{
    currentPageResults,
    currentPageNo,
    totalCount,
    loading,
  }, setState] = useState({
    currentPageResults: undefined,
    currentPageNo: 1,
    totalCount: 0,
    loading: true,
  });
  const { push } = useHistory();

  const fetchListPage = (pageNo) => {
    requestAutomationListPageA(pageNo);
  };

  useEffect(() => {
    fetchListPage(1);
  }, []);

  useEffect(() => {
    setState({
      currentPageResults: getCurrentPageResults(automationList, automationPaginator, 'automation'),
      currentPageNo: getCurrentPageNumber(automationPaginator, 'automation'),
      totalCount: getCurrentTotalResultsCount(automationPaginator, 'automation'),
      loading: isCurrentPageFetching(automationPaginator, 'automation'),
    });
  }, [automationList]);

  const handlePageChange = (pageNo) => fetchListPage(pageNo + 1);

  const handleAutomationEdit = (id) => push(`/automation-editor/${id}`);

  const handleAutomationDelete = (id) => {
    automationItemDelete(id).then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
      } else {
        notifySuccessA('Automation item deleted successfully');
        fetchListPage(currentPageNo);
      }
    });
  };

  const openAutomationDeleteConfirmation = (id) => {
    openConfirmationModalA({
      actionName: 'delete',
      callbackFunction: () => {
        clearPaginatorPromiseArray();
        handleAutomationDelete(id);
      },
      itemName: 'automation item',
      id,
    });
  };

  const openAddInitialNode = (id) => {
    openModalPortalA({
      content: <NodeAddEditModal />,
      contentProps: {
        close: closeModalPortalA,
        automationId: id,
        type: 'filter',
        add: true,
        initial: true,
      },
    });
  };

  const handleAutomationCreate = (values) => {
    automationItemCreate(values).then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
      } else {
        clearPaginatorPromiseArray();
        fetchListPage(currentPageNo);
        closeModalPortalA();
        handleAutomationEdit(res.response.id);
        openAddInitialNode(res.response.id);
      }
    });
  };

  const openNewAutomationModal = () => {
    openModalPortalA({
      content: <NewAutomationModal />,
      contentProps: {
        close: closeModalPortalA,
        onFormSubmit: handleAutomationCreate,
      },
    });
  };

  return (
    <div className="automation-list">
      <div className="automation-list__header">
        <span className="automation-list__header__title">Automation</span>
        {!totalCount ? null : (
          <IconButton
            content="New Automation"
            iconName="add"
            onClick={openNewAutomationModal}
          />
)}
      </div>
      {!totalCount ? (
        <div className="automation-list__empty">
          <p className="automation-list__empty__text">No Automations have been created</p>
          <IconButton
            content="Create Your First Automation"
            onClick={openNewAutomationModal}
            className="automation-list__empty__button"
          />
        </div>
)
      : (
        <div className="automation-list__container">
          <Spinner loaded={!loading}>
            {map(currentPageResults, (automationItem) => (
              <AutomationItem
                key={automationItem.id}
                item={automationItem}
                handleEdit={handleAutomationEdit}
                handleDelete={openAutomationDeleteConfirmation}
              />
            ))}
            <div className="pagination-bottom">
              <TablePager
                total={totalCount}
                page={currentPageNo}
                pageSize={DEFAULT_PAGE_SIZE}
                onPageChange={handlePageChange}
              />
            </div>
          </Spinner>
        </div>
)}
    </div>
  );
};

AutomationList.propTypes = {
  automationList: PropTypes.object,
  automationPaginator: PropTypes.object,
  requestAutomationListPageA: PropTypes.func,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  openConfirmationModalA: PropTypes.func,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  automationList: state.automationList,
  automationPaginator: state.automationPaginator,
});

const mapDispatchToProps = (dispatch) => ({
  requestAutomationListPageA: bindActionCreators(requestAutomationListPage, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  openConfirmationModalA: bindActionCreators(openConfirmationModal, dispatch),
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutomationList);
