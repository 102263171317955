import React, { useContext } from 'react';
import { Divider } from 'semantic-ui-react';

// Components.
import WidgetControllerHeader from '../../components/WidgetControllerHeader';

// Local components.
import OptInContactsController from './components/OptInContactsController';
import OptInTagsController from './components/OptInTagsController';
import OptInHeaderController from './components/OptInHeaderController';
import OptInBodyController from './components/OptInBodyController';
import OptInButtonController from './components/OptInButtonController';
import OptInFooterController from './components/OptInFooterController';
import OptInCheckboxController from './components/OptInCheckboxController';
import OptInInputsController from './components/OptInInputsController';

// Compositions.
import WidgetControllerFooter from '../../compositions/WidgetControllerFooter';

// Context.
import { EditorContext } from '../../context/EditorContext';

// Styles.
import './OptInWidgetController.scss';

const OptInWidgetController = () => {
  const {
    replaceElement,
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const onSaveButtonClick = () => {
    replaceElement(activeElement);
    setActiveElement(false);
  };

  const onCancelButtonClick = () => {
    setActiveElement(false);
  };

  return (
    <div className="OptInWidgetController">
      <WidgetControllerHeader title="Optin settings" />
      <div className="OptInWidgetController__controller-body">
        <OptInContactsController />
        <Divider />
        <OptInTagsController />
        <Divider />
        <OptInHeaderController />
        <Divider />
        <OptInBodyController />
        <Divider />
        <OptInInputsController />
        <Divider />
        <OptInCheckboxController />
        <Divider />
        <OptInButtonController />
        <Divider />
        <OptInFooterController />
        <Divider />
      </div>
      <WidgetControllerFooter
        onSaveButtonClick={() => onSaveButtonClick()}
        onCancelButtonClick={() => onCancelButtonClick()}
      />
    </div>
  );
};

export default OptInWidgetController;
