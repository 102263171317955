import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';

// Actions.
import { genericActions, notificationActions } from '../../../../../actions';

// Components.
import { BasicButton } from '../../../../../components/Button';

// Modals.
import { S3UploadModal, MediaSelectModal } from '../../../../../containers/Modal';

// Context.
import { EditorContext } from '../../../../context/EditorContext';

// Constants.
import { toolbarConfig } from '../../../../constants/toolbarConfig';

// Services.
import { api } from '../../../../../services';

// Styles.
import './OptInHeaderController.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const { openModalPortal, closeModalPortal } = genericActions;
const { S3: { uploadS3Media, uploadSignedFile } } = api;
const { notifyError } = notificationActions;

const OptInHeaderController = ({
  openModalPortalA,
  closeModalPortalA,
}) => {
  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const { data } = activeElement;

  const {
    data: {
        header: { content },
      },
    } = activeElement;

  const [editorState, setEditorState] = useState(
    content
      ? EditorState.createWithContent(convertFromRaw(content))
      : EditorState.createEmpty(),
  );

  const uploadPicture = (fileBase64, fileName, type) => {
    const fileType = type.split('/')[1] === 'jpeg' ? 'jpg' : 'png';

    uploadS3Media(fileType).then((res) => {
      const url = new URL(res.response.url);
      const loc = url.origin + url.pathname;
      uploadSignedFile(fileBase64, res.response.url, fileType).then(() => {
        setTimeout(() => handleMediaSelect({ mediaUrl: loc, title: fileName }), 500);
        closeModalPortalA();
      }).catch(closeModalPortalA);
    }).catch(closeModalPortalA);
  };

  const openMediaLibrary = () => {
    openModalPortalA({
      content: <MediaSelectModal />,
      contentProps: {
        closeModalPortal: () => {
          closeModalPortalA();
        },
        size: 'fullscreen',
        type: 'image',
        selectedMedia: { mediaUrl: data.header.img.src, title: data.header.img.alt },
        modalName: 'Upload Picture',
        handleMediaSelect,
      },
    });
  };

  const openUploadModal = () => {
    openModalPortalA({
      content: <S3UploadModal />,
      closeModalPortal: closeModalPortalA,
      contentProps: {
        closeModalPortal: () => {
          closeModalPortalA();
        },
        onUpload: (fileBase64, fileName, type) => uploadPicture(fileBase64, fileName, type),
        modalName: 'Upload Image',
        accept: ['image/*'],
        maxSize: 3145728, // 3mb for images
      },
    });
  };

  const handleMediaSelect = (media) => {
    const dataObj = { ...activeElement };

    dataObj.data.header.img.src = media.mediaUrl;
    dataObj.data.header.img.alt = media.title;

    setActiveElement(dataObj);
  };

  const onEditorStateChange = (state) => {
    const dataObj = { ...activeElement };
    const contentState = state.getCurrentContent();
    const contentRawState = convertToRaw(contentState);
    dataObj.data.header.content = contentRawState;

    setActiveElement(dataObj);
    setEditorState(state);
  };

  const setElementVisibility = () => {
    const dataObj = { ...activeElement };

    dataObj.data.header.isVisible = !data.header.isVisible;

    setActiveElement(dataObj);
  };

  return (
    <div className="OptInHeaderController">
      <div className="OptInHeaderController__header">
        <p className="OptInHeaderController__header-title">Header</p>
        <Checkbox toggle checked={data.header.isVisible} onClick={setElementVisibility} />
      </div>

      {data.header.isVisible && (
        <div>
          <div className="OptInHeaderController__image-data-controller">
            <img
              className="OptInHeaderController__image"
              src={data.header.img.src}
              alt={data.header.img.alt}
            />
            <div className="OptInHeaderController__image-cta-section">
              <p className="OptInHeaderController__image-cta-section-label">
                Choose and option to add an image
              </p>
              <div className="OptInHeaderController__image-cta-buttons-container">
                <BasicButton
                  color="blue"
                  size="small"
                  content="Browse"
                  onClick={openUploadModal}
                  className="invert"
                />
                <p className="OptInHeaderController__image-cta-section-label">or</p>
                <BasicButton
                  color="blue"
                  size="small"
                  content="Media Library"
                  onClick={openMediaLibrary}
                  className="invert"
                />
              </div>
            </div>
          </div>
          <div className="OptInHeaderController__text-editor-wrapper">
            <Editor
              editorState={editorState}
              toolbar={toolbarConfig}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              placeholder="Type your text here"
              onEditorStateChange={onEditorStateChange}
              hashtag={{ hashCharacter: '#', separator: ' ' }}
              stripPastedStyles={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

OptInHeaderController.propTypes = {
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default connect(null, mapDispatchToProps)(OptInHeaderController);
