import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MessageItemDesktop from './MessageItemDesktop';
import MessageItemTablet from './MessageItemTablet';
import MessageItemMobile from './MessageItemMobile';

const MessageItemGenerator = (props) => {
  const [size, setSize] = useState('desktop');

  useEffect(() => {
    window.addEventListener('resize', resize);
    resize();

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  const resize = () => {
    let windowSize;
    if (window.innerWidth <= 1199 && window.innerWidth > 835) {
      windowSize = 'tablet';
    } else if (window.innerWidth <= 835) {
      windowSize = 'mobile';
    } else {
      windowSize = 'desktop';
    }
    if (windowSize !== size) {
      setSize(windowSize);
    }
  };

    const { id, finalValues } = props;
    const analyticsValues = finalValues[id];
    const openRate = !analyticsValues ? 0 : analyticsValues.openRate;
    const numOfRecipients = !analyticsValues ? 0 : analyticsValues.numOfRecipients;

    if (size === 'desktop') {
      return (
        <MessageItemDesktop
          {...props}
          openRate={openRate}
          numOfRecipients={numOfRecipients}
        />
      );
    }
    if (size === 'tablet') {
      return (
        <MessageItemTablet
          {...props}
          openRate={openRate}
          numOfRecipients={numOfRecipients}
        />
      );
    }
    return (
      <MessageItemMobile
        {...props}
        openRate={openRate}
        numOfRecipients={numOfRecipients}
      />
    );
};

MessageItemGenerator.propTypes = {
  finalValues: PropTypes.object,
  id: PropTypes.string,
};

export default MessageItemGenerator;
