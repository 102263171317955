export const actionTypes = {
  ADD_PROJECT_ELEMENTS_DATA: 'ADD_PROJECT_ELEMENTS_DATA',
  ADD_PROJECT_ID: 'ADD_PROJECT_ID',
  CHANGE_PROJECT_TITLE: 'CHANGE_PROJECT_TITLE',
  ADD_ELEMENT: 'ADD_ELEMENT',
  REMOVE_ELEMENT: 'REMOVE_ELEMENT',
  SET_ACTIVE_ELEMENT: 'SET_ACTIVE_ELEMENT',
  REPLACE_ELEMENT: 'REPLACE_ELEMENT',
  ADD_ATTACHMENTS: 'ADD_ATTACHMENTS',
  SET_BACKGROUND_COLOR: 'SET_BACKGROUND_COLOR',
  CHANGE_UNSUBSCRIBE: 'CHANGE_UNSUBSCRIBE',
};
