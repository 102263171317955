import { FETCH_ACCOUNT } from '../actions/accountActions';

const account = (state = {}, action) => {
  if (action.type === FETCH_ACCOUNT.SUCCESS) {
    return {
      ...state,
      ...action,
    };
  }
  return state;
};

export default account;
