import { success, error, warning } from 'react-notification-system-redux';

const notify = (type, message, autoDismiss = 3) => {
  const options = {
    message,
    autoDismiss,
    position: 'tc',
  };
  switch (type) {
    case 'success': {
      return success(options);
    }
    case 'error': {
      return error(options);
    }
    case 'warning': {
      return warning(options);
    }
    case 'credits': {
      return success(options);
    }
    default:
      return undefined;
  }
};

export const notifySuccess = (message, autoDismiss) => notify('success', message, autoDismiss);
export const notifyError = (message) => notify('error', message);
export const notifyWarning = (message) => notify('warning', message);
export const notifyCreditsSuccess = (message) => notify('credits', message, 6);
