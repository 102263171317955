import React, { useContext } from 'react';
import { includes } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v4 as uuidv4 } from 'uuid';

// Components.
import WidgetControllerHeader from '../../components/WidgetControllerHeader';
import { BasicButton } from '../../../components/Button';

// Compositions.
import WidgetControllerFooter from '../../compositions/WidgetControllerFooter';

// Context.
import { EditorContext } from '../../context/EditorContext';

// Modals.
import { S3UploadModal } from '../../../containers/Modal';

// Services.
import { api } from '../../../services';

// Actions.
import { genericActions } from '../../../actions';

// Constants.
import fileExtensions from '../../../../fileExtensions.json';

// Styles.
import './FileUploadWidgetController.scss';

const { S3: { uploadS3Media, uploadSignedFile } } = api;
const { openModalPortal, closeModalPortal } = genericActions;

const FileUploadWidgetController = ({
  openModalPortalA,
  closeModalPortalA,
}) => {
  const {
    activeElement,
    setActiveElement,
    addAttachments,
  } = useContext(EditorContext);

  const isFilesUploaded = activeElement.files.length;

  const uploadFile = (fileBase64, fileName, type) => {
    const fileType = type.split('/')[0];
    let fileExt = type.split('/')[1];
    const id = uuidv4();

    if (fileType === 'image') {
      if (fileExt === 'jpeg') {
        fileExt = 'jpg';
      } else {
        fileExt = 'png';
      }
    } else if (fileType === 'application' || fileType === 'text') {
      const nameParts = fileName.split('.');
      const nameExt = nameParts[nameParts.length - 1];
      if (includes(fileExtensions, nameExt)) {
        fileExt = nameExt;
      }
    }

    uploadS3Media(fileExt).then((res) => {
      uploadSignedFile(fileBase64, res.response.url, fileExt).then(() => {
        const url = new URL(res.response.url);
        const loc = url.origin + url.pathname;
        const fileDataObj = {
          id,
          fileName,
          href: loc,
        };
        addUploadedFilesData(fileDataObj);
        closeModalPortalA();
      }).catch(closeModalPortalA);
    }).catch(closeModalPortalA);
  };

  const handleUploadButton = () => {
    openModalPortalA({
      content: <S3UploadModal />,
      contentProps: {
        closeModalPortal: () => closeModalPortalA(),
        onUpload: (fileBase64, fileName, type) => uploadFile(fileBase64, fileName, type),
        modalName: 'Upload File',
      },
    });
  };

  const addUploadedFilesData = (value) => {
    const dataObj = { ...activeElement };
    const fileListArray = [...activeElement.files, value];
    dataObj.files = fileListArray;

    setActiveElement(dataObj);
  };

  const handleFileDelete = (file) => {
    const dataObj = { ...activeElement };
    const filteredElement = dataObj.files.filter(
      (element) => element.id !== file.id,
    );

    dataObj.files = filteredElement;
    setActiveElement(dataObj);
  };

  const onSaveButtonClick = () => {
    if (isFilesUploaded) {
      addAttachments(activeElement);
      setActiveElement(false);
    } else {
      addAttachments(false);
      setActiveElement(false);
    }
  };

  const onCancelButtonClick = () => {
    if (isFilesUploaded) {
      setActiveElement(false);
    } else {
      addAttachments(false);
      setActiveElement(false);
    }
  };

  return (
    <div className="FileUploadWidgetController">
      <WidgetControllerHeader title="File settings" />
      <div className="FileUploadWidgetController__controller-body">
        <div className="FileUploadWidgetController__button-wrapper">
          <p className="FileUploadWidgetController__label-text">Upload file</p>
          <BasicButton
            onClick={handleUploadButton}
            size="small"
            content="Browse"
            color="blue"
            className="invert"
          />
        </div>
        <div className="FileUploadWidgetController__file-content-container">

          {activeElement.files.map((file) => (
            <button
              key={file.id}
              onClick={() => handleFileDelete(file)}
              type="button"
              className="FileUploadWidgetController__delete-file-button"
            >
              <p className="FileUploadWidgetController__file-action-name">DELETE</p>
              <p className="FileUploadWidgetController__file-name">{file.fileName}</p>
            </button>
          ))}

        </div>
      </div>

      <WidgetControllerFooter
        onSaveButtonClick={() => onSaveButtonClick()}
        onCancelButtonClick={() => onCancelButtonClick()}
      />
    </div>
  );
};

FileUploadWidgetController.propTypes = {
  openModalPortalA: PropTypes.func.isRequired,
  closeModalPortalA: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
});

export default connect(null, mapDispatchToProps)(FileUploadWidgetController);
