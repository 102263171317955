import { useMemo } from 'react';
import {
  forEach,
  map,
  sortBy,
  sortedUniq,
} from 'lodash';
import moment from 'moment';
import 'moment-timezone';

export const useTimeZoneOptions = () => useMemo(() => {
  let timeZones = [];
  forEach(moment.tz.countries(), (country) => {
    timeZones.push(...moment.tz.zonesForCountry(country));
  });
  timeZones = sortedUniq((sortBy(timeZones)));
  return map(timeZones, (tz) => ({
    value: tz,
    text: tz.replace(/_/g, ' '),
  }));
}, []);
