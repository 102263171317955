import React, { useContext } from 'react';
import classNames from 'classnames';

// widgets
import ButtonWidget from '../../widgets/ButtonWidget';
import VideoWidget from '../../widgets/VideoWidget';
import FileUploadWidget from '../../widgets/FileUploadWidget';
import PictureWidget from '../../widgets/PictureWidget';
import TextWidget from '../../widgets/TextWidget';
import OptInWidget from '../../widgets/OptInWidget';
import SurveyWidget from '../../widgets/SurveyWidget';

// widgets controllers
import ButtonWidgetController from '../../widgetControllers/ButtonWidgetController';
import VideoWidgetController from '../../widgetControllers/VideoWidgetController';
import FileUploadWidgetController from '../../widgetControllers/FileUploadWidgetController';
import PictureWidgetController from '../../widgetControllers/PictureWidgetController';
import TextWidgetController from '../../widgetControllers/TextWidgetController';
import OptInWidgetController from '../../widgetControllers/OptInWidgetController';
import SurveyWidgetController from '../../widgetControllers/SurveyWidgetController';

// Constants.
import {
  BUTTON,
  VIDEO,
  FILE,
  IMAGE,
  TEXT,
  OPT_IN,
  SURVEY,
} from '../../constants/widgetTypes';

import {
  EditorContext,
} from '../../context/EditorContext';
import {
  MessageContext,
} from '../../../context/messageEditor';

import './WidgetsButtons.scss';

const CONTROLLER_LAYOUTS = {
  [BUTTON]: <ButtonWidgetController />,
  [VIDEO]: <VideoWidgetController />,
  [FILE]: <FileUploadWidgetController />,
  [IMAGE]: <PictureWidgetController />,
  [TEXT]: <TextWidgetController />,
  [OPT_IN]: <OptInWidgetController />,
  [SURVEY]: <SurveyWidgetController />,
};

const WIDGET_BUTTONS = [
  <VideoWidget />,
  <PictureWidget />,
  <SurveyWidget />,
  <TextWidget />,
  <ButtonWidget />,
  <FileUploadWidget />,
  <OptInWidget />,
];

const WidgetsButtons = () => {
  const { activeElement } = useContext(EditorContext);
  const { elementType } = activeElement;
  const {
    isMessageLoading,
  } = useContext(MessageContext);

  return (
    <>
      {!activeElement && (
        <div
          className={classNames('WidgetsButtons',
            { 'WidgetsButtons--disabled': isMessageLoading },
          )}
        >
          {WIDGET_BUTTONS.map((button, index) => (
            <React.Fragment key={index}>
              {button}
            </React.Fragment>
          ))}
        </div>
      )}
      {CONTROLLER_LAYOUTS[elementType]}
    </>
  );
};

export default WidgetsButtons;
