import React, { useContext } from 'react';
import { Checkbox } from 'semantic-ui-react';

// Components.
import TextInput from '../../../../components/TextInput';
import ColorSelector from '../../../../components/ColorSelector';

// Context.
import { EditorContext } from '../../../../context/EditorContext';

import './SurveyInfoController.scss';

const SurveyInfoController = () => {
  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const {
    surveyName,
    buttonText,
    surveyFeedback,
    buttonColor,
    showResponseResults,
  } = activeElement;

  const changeSurveyProperties = (value, property) => {
    const dataObj = { ...activeElement };
    dataObj[property] = value;

    setActiveElement(dataObj);
  };

  return (
    <div className="SurveyInfoController">
      <div className="SurveyInfoController__input-wrapper">
        <p>Survey name:*</p>
        <TextInput
          name="name"
          placeholder="Survey name"
          type="text"
          onChange={(e) => changeSurveyProperties(e.target.value, 'surveyName')}
          value={surveyName}
        />
      </div>
      <div className="SurveyInfoController__input-wrapper">
        <p>Button text:</p>
        <TextInput
          name="button"
          placeholder="Button text"
          type="text"
          onChange={(e) => changeSurveyProperties(e.target.value, 'buttonText')}
          value={buttonText}
        />
      </div>
      <div className="SurveyInfoController__input-wrapper">
        <p>Feedback:</p>
        <TextInput
          name="successText"
          placeholder="Survey feedback"
          type="text"
          onChange={(e) => changeSurveyProperties(e.target.value, 'surveyFeedback')}
          value={surveyFeedback}
        />
      </div>
      <div className="SurveyInfoController__input-wrapper-alternative">
        <ColorSelector
          label="Button color:"
          currentColor={buttonColor}
          hasInput
          onChange={(value) => changeSurveyProperties(value, 'buttonColor')}
        />
      </div>
      <div className="SurveyInfoController__toggle-input-wrapper">
        <Checkbox
          toggle
          checked={showResponseResults}
          onClick={(e, data) => changeSurveyProperties(data.checked, 'showResponseResults')}
        />
        <p className="SurveyInfoController__toggle-input-label">Show Response Results</p>
      </div>
    </div>
  );
};

export default SurveyInfoController;
