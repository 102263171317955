import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { map, find, get } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spinner } from '../../components/Spinner';
import { contactTagRemove, tagsFetchContacts } from '../../services/tagApi';
import { notifyError, notifySuccess } from '../../actions/notificationActions';
import { fetchModifiedContactsPage, fetchTags } from '../../actions/contactActions';
import { multiDimensionalUnique } from '../../utils/contactsMapping';
import { generateColorFromString, setContactsType, setListId } from '../../utils/helper';

const RemoveTagsForm = ({
  list,
  selected,
  filterId,
  notifyErrorA,
  currentPageResults,
  closeModalPortal,
  requestModifiedContactViewPageA,
  fetchTagsA,
  contacts,
  notifySuccessA,
  contactFilter,
  excluded,
  propertyId,
  orderBy,
}) => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (typeof selected === 'string') {
      const filter = filterId ? `&filter=${filterId}` : '';
      const type = setContactsType(list);
      const listId = setListId(list);

      tagsFetchContacts(filter, listId, type)
        .then((res) => {
          if (res.error) {
            notifyErrorA(res.error.message);
          } else {
            setLoading(false);
            setTags(map(res.response.tags, (tag) => ({ id: tag.id, text: tag.name })));
          }
        }).catch((err) => notifyErrorA(err));
    } if (currentPageResults) {
      const selectedContacts = currentPageResults.filter(
        (con) => (selected.includes(con['contact.id'])));
      const listArray = map(selectedContacts, (contact) => map(contact['contact.tagObjects'], (tag) => ({ id: tag.id, text: tag.name })));

      // flatten and remove duplicates
      if (listArray.length > 0) {
        setLoading(false);
        setTags(multiDimensionalUnique(listArray.flat()));
      }
    }
  }, []);

  const handleDelete = (id) => {
    const tagId = id;
    const tagName = find(tags, (tag) => tag.id === id).text;
    const newFilterId = get(contactFilter, 'id', undefined);

    if (contacts) {
      contacts.includes((row) => {
        selected.contains(row.id);
      });
    }

    closeModalPortal();
    const body = {
      ids: typeof selected === 'string' ? [] : selected,
      excluded,
      listId: typeof list === 'number' ? list : 0,
      filterId: newFilterId,
    };
    contactTagRemove(tagId, body).then(() => {
      notifySuccessA(`Contacts have been untagged ${tagName}`);
      requestModifiedContactViewPageA({
        list,
        filterId: newFilterId,
        propertyId,
        orderBy,
      });
      fetchTagsA();
    }).catch(() => notifyErrorA('Error while deleting tags'));

    setTags(tags.splice(tagId, 1));
  };

  return (
    <div className="tag modal--tag__content">
      <Spinner loaded={!loading}>
        {tags && map(tags, (tag) => {
          const color = generateColorFromString(String(tag.text));
          return (
            <div
              className="ReactTags__tag__delete"
              key={tag.id}
              style={{
                backgroundColor: color,
                borderColor: color,
              }}
            >
              <span className="ReactTags__tag__delete__text">
                {tag.text}
              </span>
              <span className="ReactTags__tag__delete__icon" onClick={() => handleDelete(tag.id)} />
            </div>
          );
        })}
      </Spinner>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contactFilter: state.contactFilter,
});

const mapDispatchToProps = (dispatch) => ({
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  requestModifiedContactViewPageA: bindActionCreators(fetchModifiedContactsPage.request, dispatch),
  fetchTagsA: bindActionCreators(fetchTags.request, dispatch),
});


RemoveTagsForm.propTypes = {
  requestModifiedContactViewPageA: PropTypes.func,
  contacts: PropTypes.array,
  closeModalPortal: PropTypes.func,
  currentPageResults: PropTypes.array,
  notifySuccessA: PropTypes.func,
  filterId: PropTypes.string,
  notifyErrorA: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  fetchTagsA: PropTypes.func,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  contactFilter: PropTypes.object,
  excluded: PropTypes.array,
  propertyId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  orderBy: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveTagsForm);
