import { contactActions } from '../actions';

const { FETCH_VIEWS, ADD_VIEW } = contactActions;

const views = (state = {}, action) => {
  switch (action.type) {
    case FETCH_VIEWS.SUCCESS: {
      return {
        ...action.response,
      };
    }
    case ADD_VIEW: {
      return state;
    }
    default:
      return state;
  }
};

export default views;
