import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

function MessageEmpty({ createMessageA }) {
  return (
    <div className="empty-messages">
      <div className="empty-messages__text-wrapper">
        <span as="h1" className="empty-messages__text">No Message has been created</span>
      </div>
      <Button onClick={() => createMessageA({ name: '', origin: 'default', deliveryMethod: 'sms' })} className="blue">
        <p className="empty-messages__button-text">Create your first message</p>
      </Button>
    </div>
  );
}

MessageEmpty.propTypes = {
  createMessageA: PropTypes.func,
};

export default withRouter(MessageEmpty);
