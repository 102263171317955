import {
  useMemo,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FlowFinalNode } from './index';
import { AutomationNodeContactsPopup } from '../../../containers/Popup';
import { AutomationContext } from '../../../context/AutomationContext';

const AutomationNode = ({
  node,
  message,
  editNode,
  deleteNode,
  notifyWarning,
  isFirstNode,
}) => {
  const {
    id,
    name,
    actionType,
    actionSubject,
    objects,
    top,
    left,
    done,
    inputs: { messageId },
  } = node;

  const {
    setSelectedMessageId,
    setSplitScreenOpen,
    selectedMessageId,
    setKeepEditorOpen,
    editingDisabled,
  } = useContext(AutomationContext);

  if (actionType === 'exit') {
    return <FlowFinalNode node={node} />;
  }

  const [messageChanged, setMessageChanged] = useState(false);

  useEffect(() => {
    if (!selectedMessageId && messageChanged) {
      setSelectedMessageId(messageId);
      setMessageChanged(false);
    }
  }, [selectedMessageId, messageChanged]);

  const className = useMemo(() => {
    let cn = '';
    if (message) {
      const { deliveryMethod, id: mId } = message;
      if (actionSubject === 'send_message') {
        cn = `${cn} send-${deliveryMethod}`;
      }
      if (mId === selectedMessageId) {
        cn = `${cn} selected`;
      }
    }
    return cn;
  }, [actionSubject, message, selectedMessageId]);

  const hasMessage = useMemo(() => !!messageId, [messageId]);

  const actionsClassName = useMemo(() => {
    if (hasMessage) {
      return 'automation-node__container__actions';
    }
    return 'automation-node__container__actions no-message';
  }, [hasMessage]);

  const handleMessageEdit = () => {
    if (hasMessage) {
      if (!selectedMessageId) {
        setSplitScreenOpen(true);
        setSelectedMessageId(messageId);
      } else if (selectedMessageId !== messageId) {
        setMessageChanged(true);
        setKeepEditorOpen(true);
        setSelectedMessageId(undefined);
      }
    }
  };

  const handleDelete = () => {
    if (editingDisabled) {
      notifyWarning('Cannot delete nodes while automation is active');
    } else {
      deleteNode(node);
    }
  };

  return (
    <div
      id={`automation-node-${id}`}
      className="automation-node"
      style={{ top: `${top}px`, left: `${left}px` }}
    >
      <div className={`automation-node__container ${actionType}${className}`}>
        <span className="automation-node__container__icon" />
        <div
          className="automation-node__container__content"
          onClick={() => editNode(isFirstNode, actionType, node)}
        >
          <div className="automation-node__container__content__title">
            {name}
          </div>
          <div className="automation-node__container__content__info">
            <div className="automation-node__container__content__info__column">
              <div className="automation-node__container__content__info__column__type">CURRENT</div>
              <div className="automation-node__container__content__info__column__count">{`${objects} CONTACTS`}</div>
            </div>
            <div className="automation-node__container__content__info__column">
              <div className="automation-node__container__content__info__column__type">DONE</div>
              <div className="automation-node__container__content__info__column__count">{`${done} CONTACTS`}</div>
            </div>
          </div>
        </div>
        <div className={actionsClassName}>
          <span
            className="automation-node__container__actions__delete"
            onClick={handleDelete}
          />
          {hasMessage && (
            <span
              className="automation-node__container__actions__message"
              onClick={handleMessageEdit}
            />
          )}
          <AutomationNodeContactsPopup id={id} />
        </div>
      </div>
    </div>
  );
};

AutomationNode.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    actionType: PropTypes.string,
    actionSubject: PropTypes.string,
    objects: PropTypes.number,
    top: PropTypes.number,
    left: PropTypes.number,
    done: PropTypes.number,
    inputs: PropTypes.shape({
      messageId: PropTypes.string,
    }),
  }),
  message: PropTypes.object,
  editNode: PropTypes.func,
  deleteNode: PropTypes.func,
  notifyWarning: PropTypes.func,
  isFirstNode: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  const { node: { inputs } } = props;
  const { messageId } = inputs;
  if (messageId) {
    return { message: state.messages[messageId] };
  }
  return {};
};

export default connect(mapStateToProps)(AutomationNode);
