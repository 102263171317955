import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import { required } from '../../utils/validator';
import { mapListsToOptions } from '../../utils/map';

const ContactsUploadHeaderActions = ({
  lists,
  listValues,
  handleListChange,
  tags,
  tagValues,
  handleTagChange,
}) => (
  <div className="contact-upload-modal__select-header content">
    <div className="contact-upload-modal__select-header__tags">
      <span className="contact-upload-modal__select-header__tags--text">Upload to List: </span>
      <Creatable
        data-tip="Do not forget to press ↵ Enter after typing the tag"
        id="listInput"
        validate={required}
        isMulti={true}
        isSearchable={false}
        simpleValues={true}
        options={mapListsToOptions(lists)}
        placeholder="Select or create new list"
        className="react-select tag contact-upload-modal__select-header__tags--input"
        value={listValues}
        onChange={handleListChange}
        multi
      />
    </div>
    <div className="contact-upload-modal__select-header__tags">
      <span className="contact-upload-modal__select-header__tags--text">Tag contacts: </span>
      <Creatable
        name="tagsInput"
        data-tip="Do not forget to press ↵ Enter after typing the tag"
        id="tagsInput"
        validate={required}
        isMulti={true}
        simpleValues={true}
        multi
        options={mapListsToOptions(tags)}
        placeholder="Select or create new tag"
        className="react-select tag contact-upload-modal__select-header__tags--input"
        value={tagValues}
        onChange={handleTagChange}
      />
    </div>
  </div>
);

ContactsUploadHeaderActions.propTypes = {
  lists: PropTypes.array,
  listValues: PropTypes.array,
  handleListChange: PropTypes.func,
  tags: PropTypes.array,
  tagValues: PropTypes.array,
  handleTagChange: PropTypes.func,
};

export default ContactsUploadHeaderActions;
