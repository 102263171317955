import { forEach } from 'lodash';
import { accountActions } from '../actions';

const { FETCH_IDENTITIES, DELETE_IDENTITY } = accountActions;

const identities = (state = {}, action) => {
  switch (action.type) {
    case FETCH_IDENTITIES: {
      const { elements } = action;
      return {
        ...state,
        elements,
      };
    }
    case DELETE_IDENTITY: {
      const { id } = action;
      const { elements } = state;
      const updatedElements = [];
      forEach(elements, (element) => {
        if (element.id !== id) {
          updatedElements.push(element);
        }
      });
      return {
        ...state,
        elements: updatedElements,
      };
    }
    default:
      return state;
  }
};

export default identities;
