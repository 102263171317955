import React, { useContext } from 'react';
import { Divider } from 'semantic-ui-react';

// Components.
import TextInput from '../../components/TextInput';
import SelectInput from '../../components/SelectInput';
import ColorSelector from '../../components/ColorSelector';
import WidgetControllerHeader from '../../components/WidgetControllerHeader';

// Compositions.
import WidgetControllerFooter from '../../compositions/WidgetControllerFooter';

// Context.
import { EditorContext } from '../../context/EditorContext';

// Styles.
import './ButtonWidgetController.scss';

const BUTTON_SIZE_OPTIONS = [
  { text: '9px', value: '9px' },
  { text: '10px', value: '10px' },
  { text: '11px', value: '11px' },
  { text: '12px', value: '12px' },
  { text: '13px', value: '13px' },
  { text: '14px', value: '14px' },
  { text: '16px', value: '16px' },
  { text: '18px', value: '18px' },
  { text: '24px', value: '24px' },
  { text: '30px', value: '30px' },
  { text: '36px', value: '36px' },
  { text: '48px', value: '48px' },
  { text: '60px', value: '60px' },
  { text: '72px', value: '72px' },
];

const ButtonWidgetController = () => {
  const {
    replaceElement,
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const {
    bgColor,
    textColor,
    size,
    text,
    link,
  } = activeElement;

  const changeElementProperties = (value, property) => {
    const dataObj = { ...activeElement };
    dataObj[property] = value;

    setActiveElement(dataObj);
  };

  const onSaveButtonClick = () => {
    replaceElement(activeElement);
    setActiveElement(false);
  };

  const onCancelButtonClick = () => {
    setActiveElement(false);
  };

  return (
    <div className="ButtonWidgetController">
      <WidgetControllerHeader title="Button settings" />

      <div className="ButtonWidgetController__controller-body">
        <div className="ButtonWidgetController__input-wrapper">
          <TextInput
            name="text"
            placeholder="Button text"
            type="text"
            label="Button text:"
            onChange={(e) => changeElementProperties(e.target.value, 'text')}
            value={text}
          />
        </div>
        <div className="ButtonWidgetController__input-wrapper">
          <TextInput
            name="link"
            placeholder="Link"
            type="text"
            label="Link to:"
            onChange={(e) => changeElementProperties(e.target.value, 'link')}
            value={link}
          />
        </div>
        <Divider />
        <div className="ButtonWidgetController__input-wrapper">
          <ColorSelector
            label="Background color:"
            currentColor={bgColor}
            hasInput
            onChange={(value) => changeElementProperties(value, 'bgColor')}
          />
        </div>
        <div className="ButtonWidgetController__input-wrapper">
          <ColorSelector
            label="Text color:"
            currentColor={textColor}
            hasInput
            onChange={(value) => changeElementProperties(value, 'textColor')}
          />
        </div>
        <div className="ButtonWidgetController__input-wrapper">
          <SelectInput
            label="Size:"
            defaultValue={size}
            options={BUTTON_SIZE_OPTIONS}
            onChange={(e, selection) => changeElementProperties(selection.value, 'size')}
          />
        </div>
      </div>

      <WidgetControllerFooter
        onSaveButtonClick={() => onSaveButtonClick()}
        onCancelButtonClick={() => onCancelButtonClick()}
      />
    </div>
  );
};

export default ButtonWidgetController;
