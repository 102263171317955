import { GET_BROWSER_INFO } from '../actions/genericActions';

const device = (state = {}, action) => {
  switch (action.type) {
    case GET_BROWSER_INFO:
      return {
        ...state,
        ...action.response,
      };
    default:
      return state;
  }
};

export default device;
