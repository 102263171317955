import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFormSyncErrors, isSubmitting, submit } from 'redux-form';
import { isEmpty } from 'lodash';
import { Modal } from 'semantic-ui-react';
import { ContactFilterForm } from '../Form';
import { BasicButton } from '../../components/Button';
import { notificationActions } from '../../actions';
import { MOBILE_MAX_WIDTH } from '../../common';

const { notifySuccess } = notificationActions;

const ContactFilterModalContent = ({
  submitFormA,
  notifySuccessA,
  open,
  handleFilterModalState,
  size,
  formErrors,
  submitting,
  windowWidth,
  changeFilter,
  list,
  clearSort,
}) => {
  const onFilterClick = () => {
    if (window.innerWidth < MOBILE_MAX_WIDTH) {
      notifySuccessA('Contacts have been successfully filtered');
    }

    submitFormA('contactFilter');
  };

  return (
    <Modal
      open={open}
      className="contact-filter-modal"
      dimmer="inverted"
      size={size}
      closeOnDocumentClick={true}
      onClose={handleFilterModalState}
    >
      <Modal.Header className="contact-modal__header">
        {windowWidth && <div className="close modal-close-icon-for-tablet" onClick={handleFilterModalState} />}
      </Modal.Header>
      <ContactFilterForm changeFilter={changeFilter} list={list} clearSort={clearSort} />
      <Modal.Actions>
        <BasicButton
          content="Filter"
          size="small"
          color="blue"
          disabled={!isEmpty(formErrors) || submitting}
          onClick={onFilterClick}
        />
      </Modal.Actions>
    </Modal>
  );
};

ContactFilterModalContent.propTypes = {
  open: PropTypes.bool,
  handleFilterModalState: PropTypes.func,
  size: PropTypes.string,
  formErrors: PropTypes.object,
  submitFormA: PropTypes.func,
  submitting: PropTypes.bool,
  windowWidth: PropTypes.bool,
  notifySuccessA: PropTypes.func,
  changeFilter: PropTypes.func,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  clearSort: PropTypes.func,
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('contactFilter')(state),
  submitting: isSubmitting('contactFilter')(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitFormA: bindActionCreators(submit, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactFilterModalContent);
