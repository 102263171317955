import React from 'react';
import PropTypes from 'prop-types';

// Constants.
import { SAVE_WIDGET_BUTTON_ID } from '../../../common/constants';

// Styles
import './WidgetControllerFooter.scss';

const WidgetControllerFooter = ({ onSaveButtonClick, onCancelButtonClick }) => (
  <div className="WidgetControllerFooter">
    <button
      id={SAVE_WIDGET_BUTTON_ID}
      onClick={onSaveButtonClick}
      type="button"
      className="WidgetControllerFooter__save-button"
    >
      Save
    </button>
    <button
      onClick={onCancelButtonClick}
      type="button"
      className="WidgetControllerFooter__cancel-button"
    >
      Cancel
    </button>
  </div>
);

WidgetControllerFooter.propTypes = {
  onSaveButtonClick: PropTypes.func.isRequired,
  onCancelButtonClick: PropTypes.func.isRequired,
};

export default WidgetControllerFooter;
