import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Grid } from 'semantic-ui-react';
import SelectField from './SelectField';

const PropertyMatch = ({ field, options, header }) => (
  <Grid.Row>
    <Grid.Column width={7}>
      <div>
        {header}
      </div>
    </Grid.Column>
    <Grid.Column>
      <div className="rispons-arrow-right" />
    </Grid.Column>
    <Grid.Column width={7}>
      <Field
        component={SelectField}
        name={`${field}.match.internalName`}
        placeholder="Choose column"
        options={options}
      />
    </Grid.Column>
  </Grid.Row>
);

PropertyMatch.propTypes = {
  field: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  header: PropTypes.string,
};

export default PropertyMatch;
