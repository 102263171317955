import { useMemo } from 'react';
import PropTypes from 'prop-types';

function OverlaySpinner({
  loaded,
  options,
}) {
  // Merge default options with incoming
  const {
    loadedClassName,
    notLoadedClassName,
    size,
    type,
  } = { ...OverlaySpinner.defaultProps.options, ...options };

  const spinnerSize = useMemo(() => {
    if (size && type) {
      return `spinner--${type}-${size}`;
    }
    return '';
  }, [size, type]);

  return loaded ? null : (
    <div className="overlay-spinner">
      <div className={`${loaded ? loadedClassName : `${notLoadedClassName}`}`}>
        <div className={`spinner spinner--position spinner--heart ${spinnerSize}`} />
      </div>
    </div>
  );
}

OverlaySpinner.propTypes = {
  loaded: PropTypes.bool,
  options: PropTypes.object,
};

OverlaySpinner.defaultProps = {
  loaded: true,
  options: {
    type: 'heart',
    size: 'big',
    loadedClassName: 'loaded-content',
    notLoadedClassName: 'spinner__content',
  },
};

export default OverlaySpinner;
