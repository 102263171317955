import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  reduxForm,
  getFormSyncErrors,
  Field,
} from 'redux-form';
import { isEmpty } from 'lodash';
import { BasicButton } from '../../components/Button';
import { email, required } from '../../utils/validator';
import { authActions } from '../../actions';
import { Input } from '../../components/Field';

const { forgotPassword } = authActions;

const ForgotPasswordForm = ({ handleSubmit, formErrors }) => (
  <Form onSubmit={handleSubmit(forgotPassword.bind(this))} className="nobackground-flex">
    <Grid>
      <Grid.Row>
        <Grid.Column className="centered" computer={5} tablet={8} mobile={14}>
          <Field
            label="Email"
            component={Input}
            name="email"
            validate={[email, required]}
            placeholder="Enter your email"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="centered" computer={5} tablet={8} mobile={14}>
          <BasicButton
            fluid
            content="Send"
            color="blue"
            className="small-14"
            disabled={!isEmpty(formErrors)}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

  </Form>
);

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('forgotPassword')(state),
});

const formConfig = {
  form: 'forgotPassword',
};

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  formErrors: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ])),
};

export default connect(mapStateToProps)(reduxForm(formConfig)(ForgotPasswordForm));
