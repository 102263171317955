import { omit } from 'lodash';
import { SET_BILLING } from '../actions/accountActions';

const billing = (state = {}, action) => {
  if (action.type === SET_BILLING) {
    return {
      ...state,
      ...omit(action, 'type'),
    };
  }
  return state;
};

export default billing;
