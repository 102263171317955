import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { Form, Field, reduxForm } from 'redux-form';
import { required, sameValues, uniqueCheck } from '../../utils/validator';
import { Input } from '../../components/Field';

const MessageDuplicateForm = ({
  handleSubmit,
  onFormSubmit,
  label,
  isAutomation,
}) => (
  <Form className="message-form ris-form" onSubmit={handleSubmit(onFormSubmit.bind(this))}>
    <Grid>
      <Grid.Row>
        <Grid.Column className="centered" computer={8} tablet={10} mobile={12}>
          <Field
            className="app-form__field"
            component={Input}
            name="name"
            label={label}
            validate={isAutomation ? [required] : [required, sameValues, uniqueCheck]}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
);

const formConfig = {
  form: 'messageCopyForm',
};

MessageDuplicateForm.propTypes = {
  handleSubmit: PropTypes.func,
  onFormSubmit: PropTypes.func,
  label: PropTypes.string,
  isAutomation: PropTypes.bool,
};

export default reduxForm(formConfig)(MessageDuplicateForm);
