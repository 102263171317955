import { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown, Grid } from 'semantic-ui-react';
import {
  Form,
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  Field,
} from 'redux-form';
import { isEmpty } from 'lodash';
import { Input } from '../../components/Field';
import { BasicButton } from '../../components/Button';
import { required } from '../../utils/validator';
import { accountActions, notificationActions } from '../../actions';
import { mapCountriesToOptions } from '../../utils/map';
import countriesNames from '../../../countriesNames.json';
import { getOptionalLabelByCountry } from '../../utils/helper';
import { fetchBilling } from '../../services/accountsApi';

const { notifySuccess } = notificationActions;
const { getAccount, setBilling } = accountActions;

const BillingForm = ({
  getAccountA,
  setBillingA,
  handleSubmit,
  formErrors,
  change,
  billingInfo,
  user,
  showSuccessMsg,
  country,
}) => {
  const [errors, setErrors] = useState(false);
  const [currentCountry, setCurrentCountry] = useState('');
  const [initial, setInitial] = useState(true);
  const [label, setLabel] = useState('');

  useEffect(() => {
    fetchBilling().then((res) => {
      setBillingA(res.response);
    });

    getAccountA();
  }, []);

  useEffect(() => {
    if (initial && country) {
      setCurrentCountry(country);
      setLabel(getOptionalLabelByCountry(country));
      setInitial(false);
    }
  }, [country]);

  useEffect(() => {
    if (billingInfo && formErrors && !errors) {
      billingInfo(false);
      setErrors(true);
    }
  }, [formErrors, billingInfo]);

  const onSubmit = (values, dispatch) => {
    switch (user.role) {
      case 'root': {
        const { updateBilling } = accountActions;
        updateBilling(values, dispatch).then(() => {
          showSuccessMsg('Billing settings updated successfully');
          if (billingInfo) {
            billingInfo(true);
          }
          fetchBilling().then((res) => {
            setBillingA(res.response);
          });
          getAccountA();
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleCountryChange = (updatedCountry) => {
    setCurrentCountry(updatedCountry);
    setLabel(getOptionalLabelByCountry(updatedCountry));
    change('country', updatedCountry);
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="nobackground-flex ris-form account-settings-form"
    >
      <Grid className="no-margin">
        <Grid.Row>
          <Field
            className="ui"
            label="Billing Address"
            component={Input}
            name="billingAddress"
            size="large"
            placeholder="e.g 8398 Glenridge St."
          />
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Field
              className="ui"
              label="City"
              component={Input}
              name="city"
              validate={required}
              placeholder="e.g Los Angeles"
            />
          </Grid.Column>
          <Grid.Column className="billing_margin">
            <Field
              className="ui"
              label="State"
              component={Input}
              name="state"
              placeholder="e.g California"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Field
              className="app-form__field"
              label="Zip"
              component={Input}
              name="zip"
              validate={required}
              placeholder="e.g 90038"
            />
          </Grid.Column>
          <Grid.Column className="billing_margin">
            <div className="ui labeled input">
              <div className="ui label">Country</div>
              <Dropdown
                search
                options={mapCountriesToOptions(countriesNames)}
                onChange={(param, data) => handleCountryChange(data.value)}
                placeholder="Select Country"
                className="app-form__field"
                value={currentCountry}
                scrolling
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Field
            className="app-form__field ui"
            label={`Company ID${label}`}
            component={Input}
            name="taxId"
            placeholder="e.g DK12345678"
            size="large"
          />
        </Grid.Row>
        <div className="account-settings__submit-column">
          <BasicButton
            content="Update"
            size="small"
            color="blue"
            className="invert"
            disabled={!isEmpty(formErrors) || !currentCountry}
          />
        </div>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const tempValues = {
    billingAddress: state.billing.billingAddress,
    city: state.billing.city,
    state: state.billing.state,
    zip: state.billing.zip,
    country: state.billing.country,
    taxId: state.billing.taxId,
  };
  return ({
    formErrors: getFormSyncErrors('billing')(state),
    formValues: getFormValues('billing')(state),
    user: state.user,
    account: state.account,
    initialValues: tempValues,
    country: state.billing.country,
  });
};

const mapDispatchToProps = (dispatch) => ({
  showSuccessMsg: bindActionCreators(notifySuccess, dispatch),
  getAccountA: bindActionCreators(getAccount.request, dispatch),
  setBillingA: bindActionCreators(setBilling, dispatch),
});

const validate = (values) => {
  const errors = {};
  if (values.country && values.country === 'Denmark' && !values.taxId) {
    errors.taxId = 'This field is required';
  }
  return errors;
};

const formConfig = {
  form: 'billing',
  enableReinitialize: true,
  validate,
};

BillingForm.propTypes = {
  handleSubmit: PropTypes.func,
  formErrors: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ])),
  user: PropTypes.object,
  showSuccessMsg: PropTypes.func,
  billingInfo: PropTypes.func,
  country: PropTypes.string,
  change: PropTypes.func,
  getAccountA: PropTypes.func,
  setBillingA: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm(formConfig)(BillingForm));
