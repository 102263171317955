import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'semantic-ui-react';
import { submit, getFormSyncErrors } from 'redux-form';
import { isEmpty } from 'lodash';
import { NewAutomationForm } from '../Form';

const NewAutomationModal = ({
  close,
  open,
  onFormSubmit,
  submitForm,
  formErrors,
}) => (
  <Modal
    open={open}
    onClose={close}
    className="message-modal"
    dimmer="inverted"
    size="tiny"
  >
    <Modal.Header>
      Name your automation
    </Modal.Header>
    <Modal.Content>
      <NewAutomationForm
        onFormSubmit={onFormSubmit}
        initialValues={{ title: 'Untitled' }}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button
        className="white"
        onClick={close}
      >
        Cancel
      </Button>
      <Button
        onClick={() => submitForm('newAutomationForm')}
        disabled={!isEmpty(formErrors)}
        className="blue"
      >
        Create
      </Button>
    </Modal.Actions>
  </Modal>
);

NewAutomationModal.propTypes = {
  close: PropTypes.func,
  open: PropTypes.bool,
  onFormSubmit: PropTypes.func,
  submitForm: PropTypes.func,
  formErrors: PropTypes.object,
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('newAutomationForm')(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitForm: bindActionCreators(submit, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAutomationModal);
