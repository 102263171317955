import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components.
import { ContactListDropdown } from '../../../../../components/Field';

// Services.
import {
  contactsMetadataGet,
} from '../../../../../services/contactApi';

// Redux actions.
import {
  contactActions,
} from '../../../../../actions';

// Context.
import { EditorContext } from '../../../../context/EditorContext';
import { MessageContext } from '../../../../../context/messageEditor';

import './OptInContactsController.scss';

const { fetchLists } = contactActions;

const OptInContactsController = ({
  fetchListsA,
  lists,
}) => {
  const [totalContacts, setTotalContacts] = useState(0);
  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const {
    setCurrentStep,
  } = useContext(MessageContext);

  useEffect(() => {
    contactsMetadataGet().then((res) => setTotalContacts(res.response.total));
    fetchListsA();
  }, []);

  const input = {
    value: activeElement.data.hiddenInputData.list,
    onChange: (value) => setContactValue(value),
  };

  const setContactValue = (value) => {
    const dataObj = { ...activeElement };
    const { data } = dataObj;

    data.hiddenInputData.list = value;

    setActiveElement(dataObj);
  };

  return (
    <div className="OptInContactsController delivery-list-select">
      <p className="OptInContactsController__label">Add to list:</p>
      <ContactListDropdown
        input={input}
        value={activeElement.data.hiddenInputData.list}
        addNewList={() => setCurrentStep('contacts', 0, true, 'design')}
        name="listId"
        id="listId"
        lists={lists}
        recipientsTouched={true}
        totalContacts={totalContacts}
        messageEditor={{}}
      />
    </div>
  );
};

OptInContactsController.propTypes = {
  fetchListsA: PropTypes.func.isRequired,
  lists: PropTypes.array,
};

const mapStateToProps = (state) => ({
  lists: state.lists.elements,
});

const mapDispatchToProps = (dispatch) => ({
  fetchListsA: bindActionCreators(fetchLists.request, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptInContactsController);
