import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import { setValidityIconColor } from '../../utils/helper';
import { setValidityIconsNames } from '../../utils/validator';

const PasswordValidity = ({ password }) => {
  const [icons, setIcons] = useState({
    lengthIcon: 'radio',
    uppercaseIcon: 'radio',
    lowercaseIcon: 'radio',
  });

  useEffect(() => {
    setIcons((prevIcons) => setValidityIconsNames(password, prevIcons));
  }, [password]);

  const { lengthIcon, uppercaseIcon, lowercaseIcon } = icons;

  return (
    <List>
      <List.Item>
        <List.Icon className={setValidityIconColor(lengthIcon)} name={lengthIcon} />
        <List.Content className={setValidityIconColor(lengthIcon)}>
          At least 8 characters long
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Icon className={setValidityIconColor(lowercaseIcon)} name={lowercaseIcon} />
        <List.Content className={setValidityIconColor(lowercaseIcon)}>
          One lowercase character
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Icon className={setValidityIconColor(uppercaseIcon)} name={uppercaseIcon} />
        <List.Content className={setValidityIconColor(uppercaseIcon)}>
          One uppercase character
        </List.Content>
      </List.Item>
    </List>
  );
};

PasswordValidity.propTypes = {
  password: PropTypes.string,
};

export default PasswordValidity;
