import { omit, remove } from 'lodash';
import {
  CLEAR_MESSAGE_EDITOR_STATE,
  CREATE_MESSAGE,
  CREATE_MESSAGE_AUTOMATION,
  UPDATE_MESSAGE_EDITOR_STATE,
  SAVE_MESSAGE,
  EDIT_MESSAGE,
  CLEAR_RECIPIENTS,
  REMOVE_SINGLE_RECIPIENT,
  GET_RECIPIENTS_METADATA,
} from '../actions/messageActions';

const messageEditor = (state = {}, action) => {
  switch (action.type) {
    case CREATE_MESSAGE.REQUEST:
    case CREATE_MESSAGE_AUTOMATION.REQUEST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CREATE_MESSAGE.SUCCESS:
    case CREATE_MESSAGE_AUTOMATION.SUCCESS: {
      return {
        ...state,
        ...action.response,
      };
    }
    case EDIT_MESSAGE.REQUEST: {
      const { id, body } = action.payload;

      return {
        ...state,
        ...body,
        id,
      };
    }
    case SAVE_MESSAGE.SUCCESS: {
      return {
        ...state,
        ...omit(action.response, ['s3Data']),
      };
    }
    case UPDATE_MESSAGE_EDITOR_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CLEAR_MESSAGE_EDITOR_STATE: {
      return {};
    }
    case CLEAR_RECIPIENTS: {
      return {
        ...state,
        recipients: [],
      };
    }
    case REMOVE_SINGLE_RECIPIENT: {
      const { recipients } = state;
      const { payload: { recipientId } } = action;
      remove(recipients, (recipient) => recipient.contact === recipientId);

      return {
        ...state,
        recipients,
      };
    }
    case GET_RECIPIENTS_METADATA.SUCCESS: {
      const { response: { total } } = action;

      return {
        ...state,
        selectedRecipients: total,
      };
    }
    default: {
      return state;
    }
  }
};

export default messageEditor;
