import {
  forEach,
  map,
  some,
  get,
  filter,
  find,
  includes,
  forOwn,
  omit,
  orderBy,
} from 'lodash';
import { checkPropertyAvailability } from './helper';
import { NOT_VALID_CONTACT_PROPERTIES, contactsProperties } from '../common/constants';

// Contact Property mapper function
const mapContactProperty = (property) => {
  const { name, label, required } = property;
  const text = required ? `${label}*` : label;
  return ({ text, value: name });
};

export const mapContactsToDataTable = (contacts) => (
  map(contacts, (contact) => {
    const newContactsList = {};
    forEach(contact, (value, key) => {
      if (value) {
        const realKey = key.split('.')[1];
        newContactsList[realKey] = value;
      }
    });
    return newContactsList;
  })
);

export const mapContactsProperties = (contact) => (
  map(contact, (row) => ({
    name: row.name.split('.')[1],
    value: row.value,
  }),
  )
);

// Maps Contact Properties names to options
export const mapContactPropertiesToOptions = (properties, contactFormValues, currentIndex) => {
  if (!properties) {
    return [];
  }
  const formValues = get(contactFormValues, 'properties', []);
  const current = get(formValues, `[${currentIndex}]`, undefined);
  const currentProperty = find(properties, { name: current.name });
  const allowedProperties = filter(properties, { readOnly: false, hidden: false, formField: true });
  const availableProperties = filter(allowedProperties, (p) => !some(formValues, { name: p.name }),
  );

  if (currentProperty) {
    availableProperties.push(currentProperty);
  }

  return map(Object.values(availableProperties), mapContactProperty);
};

// Contact filter property mapper
export const mapContactFilterProperty = (property) => ({
  text: property.label,
  value: property.internalName,
});

// Contact filter property mapper
export const mapIdToOptions = (list) => {
  const options = [];
  list.forEach((li) => options.push({
    label: li.name,
    value: li.id,
    name: li.name,
    text: li.name,
  }));
  return options;
};

// Maps Contact filter Properties to options & excludes taken properties except current
export const mapFilterPropertiesToOptions = (properties, filterFormValues, currentIndex) => {
  if (!properties) {
    return [];
  }
  const formValues = get(filterFormValues, 'filter', []);
  const currentFilter = get(formValues, `[${currentIndex}]`, undefined);
  const currentFilterProperty = find(properties, { internalName: currentFilter.property });
  const availableProperties = filter(properties, (p) => !some(formValues,
    { property: p.internalName }) || p.internalName === 'contact.contact',
  );

  if (currentFilterProperty) {
    availableProperties.push(currentFilterProperty);
  }
  return map(Object.values(availableProperties), mapContactFilterProperty);
};

// Maps Contact list headers to options & excludes taken properties except current
export const mapHeadersToOptions = (properties, formValues, currentIndex, valueName) => {
  if (!properties || !formValues) {
    return [];
  }
  const values = get(formValues, valueName);
  const currentValue = values[currentIndex];
  const currentProperty = find(properties, { internalName: get(currentValue, 'match.internalName') });
  const allowedProperties = filter(filter(properties, (property) => property.dataType !== 'custom'),
    { readOnly: false, hidden: false, formField: true });
  const availableProperties = filter(allowedProperties,
    (p) => checkPropertyAvailability(p, values, values.length));
  availableProperties.push({ label: 'None', internalName: null });
  if (currentProperty && checkPropertyAvailability(currentProperty, values, currentIndex)) {
    availableProperties.push(currentProperty);
  }
  return map(Object.values(availableProperties), mapContactFilterProperty);
};

// Maps optionList to options
export const mapOptionListToOptions = (optionList) => optionList.map(
  (v) => ({ text: v.name, value: v.value }),
);

// Maps error's properties with messages
export const asyncErrorMapper = (errors) => {
  let mappedErrors = {};
  forEach(errors, (error) => {
    mappedErrors = {
      ...mappedErrors,
      [error.property]: [error.message],
    };
  });
  return mappedErrors;
};

// Maps only required options
export const mapRequiredContactPropertiesToOptions = (properties) => (
  map(Object.values(filter(properties, { required: true })), mapContactProperty)
);

export const mapRequiredCustomFields = (properties, contactProperties) => {
  const newProperties = [...properties];

  forEach(contactProperties, (property) => {
    if (!find(newProperties, { name: property.name })) {
      if (property.required && property.type === 'custom') {
        newProperties.push({
          name: property.name,
          value: null,
        });
      }
    }
  });

  return { properties: newProperties };
};

// Preparation of contact edit
export const mapContactViewToProperties = (contact, contactProperties) => {
  if (!contact) {
    return mapRequiredCustomFields(contactsProperties, contactProperties);
  }

  const removeProperties = ['id', 'name', 'surname', 'email', 'phoneNumber', 'tag', 'list'];
  const firstName = contact['contact.name'];
  const surname = contact['contact.surname'];
  const email = contact['contact.email'];
  const phone = contact['contact.phoneNumber'];
  let properties = map(contact, (property, key) => {
    const name = key.split('.')[1];
    return ({
      name,
      value: property,
    });
  });
  // Filtering out not required (email and phoneNumber) properties
  properties = filter(properties, (p) => !includes(removeProperties, p.name));
  // Filtering out allowed properties
  properties = filter(properties, (_p) => {
    const p = find(contactProperties, { name: _p.name });
    return p && !p.readOnly && !p.hidden && p.formField;
  });
  // Adding existing required properties(email/phone) as the first array elements
  if (email) {
    properties.unshift({ name: 'email', value: email });
  }
  if (phone) {
    properties.unshift({ name: 'phoneNumber', value: phone });
  }
  if (surname) {
    properties.unshift({ name: 'surname', value: surname });
  }
  if (firstName) {
    properties.unshift({ name: 'name', value: firstName });
  }

  return mapRequiredCustomFields(properties, contactProperties);
};

// Preparation of contact edit
export const mapContactProperties = (contact) => {
  if (contact && contact.properties) {
    return mapContactsProperties([...contact.properties]);
  }
  return [];
};

export const mapContactPropertiesToPayload = (values, properties) => map(values.properties,
  (_p) => {
    const p = find(properties, { name: _p.name });
    return { ..._p, name: p.internalName };
  });

export const mapUsersToList = (users) => {
  const usersList = [];
  forOwn(users, (value) => {
    usersList.push({ value: value.id, text: value.fullName });
  });
  return usersList;
};

export const mapItemsToList = (users, searchQuery) => {
  const usersList = [];

  forOwn(users, (value) => {
    if (value && value.ContactName
      && (!searchQuery || value.ContactName.includes(searchQuery))) {
      usersList.push({
        value: value.ContactKey,
        text: `${value.ContactName} ${value.ContactSurname}`,
      });
    }

    if (value && value.fullName
      && (!searchQuery || value.fullName.includes(searchQuery))) {
      usersList.push({
        value: value.id,
        text: `${value.fullName}`,
      });
    }
  });
  return usersList;
};

export const visibleContactProperties = (properties) => {
  const visibleProperties = [];
  forEach(properties, (prop) => {
    if (prop.type === 'default' && prop.name !== 'tag' && prop.name !== 'list') {
      if (prop.alias) {
        visibleProperties.push({
          ...prop,
          name: prop.alias,
        });
      } else {
        visibleProperties.push(prop);
      }
    }
  });
  return visibleProperties;
};

const mapContactsHashtags = (contact, contactProperties) => map(contactProperties,
  (property) => {
    const { name, alias } = property;
    return {
      name,
      alias,
      value: contact[`contact.${name}`] || '',
    };
  });

export const mapSelectedContactsToPayload = (ids, contactView, contactProperties) => map(ids,
  (id) => {
    const r = contactView[id];

    return {
      contact: id,
      phone: r['contact.phoneNumber'],
      email: r['contact.email'],
      contactName: r['contact.name'],
      contactSurname: r['contact.surname'],
      hashtags: mapContactsHashtags(r, omit(contactProperties, NOT_VALID_CONTACT_PROPERTIES)),
    };
  });

export const mapListsToOptions = (lists) => map(lists,
  (list) => ({ value: list.id, label: list.name }));

export const mapCountriesToOptions = (countries) => map(countries,
  (country) => ({ value: country, text: country }));

export const mapIdentitiesToOptions = (identities) => {
  const options = [];
  forEach(identities, (identity) => {
    if (identity.identityType === 'email') {
      options.push({ value: identity.identity, text: identity.identity });
    }
  });
  return options;
};

export const mapPropertiesToKeywords = (properties) => filter(orderBy(properties, 'weight'),
  (property) => property.type === 'default' && property.name !== 'list' && property.name !== 'tag');

export const mapMessagesToOptions = (messages) => map(messages,
  (message) => ({ value: message.id, text: message.name }));

export const mapPlanCountriesToOptions = (countries) => map(countries,
  ({ iso, name }) => ({ value: name, text: iso }));
