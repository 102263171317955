import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Compositions.
import EditorView from '../EditorView';
import WidgetsController from '../WidgetsController';

// Styles.
import './RegularEditorView.scss';

const RegularEditorView = ({
  activeElement,
  isTablet,
  displayResponsiveToolbar,
  setDisplayResponsiveToolbar,
}) => (
  <div className="RegularEditorView">
    <div className="RegularEditorView__editor-layout">
      <div className="RegularEditorView__editor-preview-side">
        <EditorView
          setDisplayResponsiveToolbar={setDisplayResponsiveToolbar}
          displayResponsiveToolbar={displayResponsiveToolbar}
        />
      </div>

      {isTablet ? (
        <div
          className={
            classNames(
              'RegularEditorView__widget-controller-side',
              { 'RegularEditorView__widget-controller-side--active-element': !!activeElement },
            )
          }
        >

          {(displayResponsiveToolbar)
          && (
            <>
              {!activeElement && (
                <button
                  className="RegularEditorView__hide-toolbar-button"
                  type="button"
                  onClick={setDisplayResponsiveToolbar}
                >
                  <div className="close" />
                </button>
              )}

              <div className={classNames({ 'RegularEditorView__toolbar-wrapper': !!activeElement })}>
                <WidgetsController />
              </div>
            </>
          )}

          {!displayResponsiveToolbar && (
            <button
              className="RegularEditorView__display-toolbar-button"
              type="button"
              onClick={setDisplayResponsiveToolbar}
            >
              <p>+</p>
            </button>
          )}
        </div>
      ) : (
        <div className="RegularEditorView__widget-controller-side">
          <WidgetsController />
        </div>
      )}
    </div>
  </div>
);

RegularEditorView.propTypes = {
  activeElement: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.bool,
  ]),
  isTablet: PropTypes.bool,
  displayResponsiveToolbar: PropTypes.bool,
  setDisplayResponsiveToolbar: PropTypes.func,
};

export default RegularEditorView;
