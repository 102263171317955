import { useState, useEffect, useContext } from 'react';
import { Input, Popup } from 'semantic-ui-react';
import { BasicButton } from '../../Button';
import { AutomationContext } from '../../../context/AutomationContext';

const AutomationTitle = () => {
  const [{
    title,
    touched,
    error,
    edit,
  }, setState] = useState({
    title: '',
    touched: false,
    error: undefined,
    edit: false,
  });
  const { automation, handleChangeTitle } = useContext(AutomationContext);

  useEffect(() => {
    if (automation && automation.title && automation.title !== title) {
      setState((state) => ({ ...state, title: automation.title }));
    }
  }, [automation]);

  const onChange = ({ value }) => {
    let err;
    if (value.length === 0) {
      err = 'This field is required';
    } else if (value.length > 256) {
      err = 'Automation title should be less than 256 characters';
    }
    setState((state) => ({ ...state, title: value, error: err }));
  };

  const handleSave = () => {
    handleChangeTitle(title);
    setState((state) => ({
      ...state,
      edit: false,
      touched: false,
      error: undefined,
    }));
  };

  const handleCancel = () => {
    setState((state) => ({
      ...state,
      title: automation.title,
      edit: false,
      touched: false,
      error: undefined,
    }));
  };

  if (edit) {
    return (
      <div className="list-name__header--input ris-form">
        <div className="field input list-name__header--input__wrapper">
          <Input
            type="text"
            placeholder="Automation Title"
            value={title}
            onChange={(e, data) => onChange(data)}
            onFocus={() => setState((state) => ({ ...state, touched: true }))}
            className="list-name__input"
          />
          {
            touched && error && (
              <Popup
                className="error-popup"
                trigger={<i className="material-icons input-error-icon">error</i>}
                position="top right"
                content={error}
              />
            )
          }
        </div>
        <BasicButton
          content="Save"
          color="white"
          size="small"
          className="automation-button--save"
          onClick={() => handleSave()}
          disabled={!!error}
        />
        <BasicButton
          content="Cancel"
          color="white"
          size="small"
          className="automation-button--cancel"
          onClick={() => handleCancel()}
        />
      </div>
    );
  }
  return (
    <span
      className="app-header__automation__heading__title"
      onClick={() => setState((state) => ({ ...state, edit: true }))}
    >
      {automation ? automation.title : ''}
    </span>
  );
};

export default AutomationTitle;
