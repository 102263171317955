import { memo, useState } from 'react';
import PropTypes from 'prop-types';

const AutomationIssues = ({ automation }) => {
  const [open, setState] = useState(false);

  const issuesCount = automation?.issues?.length || 0;

  return (
    <div className="automation-issues">
      <div className={`automation-issues__header${issuesCount === 0 ? ' disabled' : ''}`} onClick={() => setState(!open)}>
        ISSUES FOUND
        {' ('}
        {issuesCount}
        )
      </div>
      {automation && automation.issues && automation.issues.length > 0 && (
        <div className={`automation-issues__issues-box${open ? ' automation-issues__issues-box--opened' : ''}`}>
          <div className="automation-issues__rows">
            {automation.issues.map(({ id, message, url }) => (
              <div key={id} className="automation-issues__row">
                <span className="automation-issues__row__error-icon" />
                <div className="automation-issues__row__message">{message}</div>
                <a className="automation-issues__row__url" href={url}>How to fix it</a>
              </div>
            ))}
          </div>
          <div className="automation-issues__footer" onClick={() => setState(false)}>
            Close issue view
          </div>
        </div>
      )}
    </div>
  );
};

AutomationIssues.propTypes = {
  automation: PropTypes.object,
};

export default memo(AutomationIssues);
