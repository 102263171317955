import { genericActions } from '../actions';

const { CLOSE_CONFIRMATION_MODAL, OPEN_CONFIRMATION_MODAL } = genericActions;

const confirmationModal = (state = {}, action) => {
  switch (action.type) {
    case OPEN_CONFIRMATION_MODAL: {
      return {
        ...action.properties,
        open: true,
      };
    }
    case CLOSE_CONFIRMATION_MODAL: {
      return {
        open: false,
      };
    }
    default:
      return state;
  }
};

export default confirmationModal;
