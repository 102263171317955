import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import { useLocation } from 'react-router';

// Widget UI components.
import WidgetButtonUI from '../../widgetComponents/WidgetButtonUI';
import WidgetVideoUI from '../../widgetComponents/WidgetVideoUI';
import WidgetFileUI from '../../widgetComponents/WidgetFileUI';
import WidgetPictureUI from '../../widgetComponents/WidgetPictureUI';
import WidgetTextUI from '../../widgetComponents/WidgetTextUI';
import WidgetOptInUI from '../../widgetComponents/WidgetOptInUI';
import WidgetSurveyUI from '../../widgetComponents/WidgetSurveyUI';

// Components.
import { Spinner } from '../../../components/Spinner';

// Constants.
import {
  BUTTON,
  VIDEO,
  FILE,
  IMAGE,
  TEXT,
  OPT_IN,
  SURVEY,
} from '../../constants/widgetTypes';

// Context.
import {
  EditorContext,
} from '../../context/EditorContext';
import { MessageContext } from '../../../context/messageEditor';

// Styles.
import './MessageElementsDistributor.scss';

const MessageElementsDistributor = ({
  messages,
  setDisplayResponsiveToolbar,
  setDisplayAutomationToolbar,
  displayAutomationToolbar,
  displayResponsiveToolbar,
}) => {
  const { pathname } = useLocation();
  const [{ canDrop }, drop] = useDrop(() => ({
    accept: 'element',
    drop: () => ({ name: 'Preview element container' }),
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  }));

  const {
    setActiveElement,
    removeElement,
    elementsStructureArray,
    activeElement,
    addElementsData,
    attachments,
    backgroundColor,
  } = useContext(EditorContext);

  const {
    messageEditor: { status },
    messageId,
    isMessageLoading,
  } = useContext(MessageContext);

  const getIsPreview = () => pathname.split('/').pop() === 'preview'
    || (status === 'sent')
    || status === 'active-sending';

  const deleteElement = (e, element) => {
    e.stopPropagation();
    if (element.id === activeElement.id) {
      setActiveElement(false);
    }
    removeElement(element);
  };

  const reorderElement = (e, value, direction) => {
    e.stopPropagation();
    const dataArr = [...elementsStructureArray];
    const replaceElement = (arr, from, to) => arr.splice(to, 0, arr.splice(from, 1)[0]);

    if (direction === 'up') {
      replaceElement(dataArr, value, value - 1);
    }

    if (direction === 'down') {
      replaceElement(dataArr, value, value + 1);
    }

    addElementsData(dataArr);
  };

  const uploadedFiles = attachments?.files || [];

  const renderElement = (element, index) => {
    const { elementType } = element;

    const getElement = (type, props) => {
      const elementsObj = {
        [BUTTON]: type === BUTTON && <WidgetButtonUI {...props} />,
        [VIDEO]: type === VIDEO && <WidgetVideoUI {...props} />,
        [IMAGE]: type === IMAGE && <WidgetPictureUI {...props} />,
        [TEXT]: type === TEXT && <WidgetTextUI {...props} />,
        [OPT_IN]: type === OPT_IN && <WidgetOptInUI {...props} />,
        [SURVEY]: type === SURVEY && <WidgetSurveyUI {...props} />,
      };

      return (
        elementsObj[type]
      );
    };

    const handleToolbarOpen = (widgetElement) => {
      if (activeElement.id !== widgetElement.id) {
        setActiveElement(widgetElement);
      }
      if (setDisplayResponsiveToolbar && !displayResponsiveToolbar) {
        setDisplayResponsiveToolbar();
      }
      if (setDisplayAutomationToolbar && !displayAutomationToolbar) {
        setDisplayAutomationToolbar();
      }
    };

      return (
        <div
          key={element.id}
          onClick={() => handleToolbarOpen(element)}
          className={classNames(
            'MessageElementsDistributor__element-wrapper',
            { 'MessageElementsDistributor__element-wrapper--is-preview': getIsPreview() },
          )}
        >
          <div className="MessageElementsDistributor__element-cta-section">
            {(index !== 0)
              && (
              <button
                type="button"
                className="MessageElementsDistributor__element-reorder-up"
                aria-label="reorder-up"
                onClick={(e) => reorderElement(e, index, 'up')}
              />
            )}
            {(index !== elementsStructureArray.length - 1)
              && (
              <button
                type="button"
                className="MessageElementsDistributor__element-reorder-down"
                aria-label="reorder-down"
                onClick={(e) => reorderElement(e, index, 'down')}
              />
            )}
            <button
              type="button"
              onClick={(e) => deleteElement(e, element)}
              className="MessageElementsDistributor__element-remove-button"
              aria-label="delete element"
            />
          </div>
          <div className="MessageElementsDistributor__element-additional-wrapper">
            {/* displaying editable element or element from array */}
            {(activeElement.id === element.id)
          ? <>{getElement(elementType, { ...activeElement, index })}</>
          : <>{getElement(elementType, { ...element, index })}</>}
          </div>
        </div>
      );
  };

  return (
    <div
      className="MessageElementsDistributor"
      ref={drop}
      style={{ backgroundColor }}
    >
      <div className="MessageElementsDistributor__elements-section">
        {/* checking if message loaded and adding loading placeholder */}
        {!messages[messageId]
          ? <Spinner loaded={!!messages[messageId]} />
          : (
            <>
              {/* checking if we have not empty message */}
              {(!elementsStructureArray.length && !isMessageLoading)
                ? (
                  <div className={classNames(
                      'MessageElementsDistributor__dnd-container',
                      { 'MessageElementsDistributor__dnd-container--can-drop': canDrop })}
                  >
                    <i className="material-icons">add</i>
                    <p>Drag and drop content here!</p>
                  </div>
                )
                : elementsStructureArray.map(renderElement)}
            </>
          )}
      </div>

      {attachments
        && (
        <>
          {!!uploadedFiles.length && (activeElement.elementType !== FILE) && (

            <div onClick={() => setActiveElement(attachments)}>
              <WidgetFileUI
                fileData={attachments}
              />
            </div>
          )}

          {(activeElement.elementType === FILE) && (
            <div>
              <WidgetFileUI
                fileData={activeElement}
              />
            </div>
          )}
        </>
      )}
    </div>

  );
};

MessageElementsDistributor.propTypes = {
  setDisplayAutomationToolbar: PropTypes.func,
  setDisplayResponsiveToolbar: PropTypes.func,
  displayAutomationToolbar: PropTypes.bool,
  displayResponsiveToolbar: PropTypes.bool,
  messages: PropTypes.object,
};

const mapStateToProps = (state) => ({
  messages: state.messages,
});

export default connect(mapStateToProps, null)(MessageElementsDistributor);
