import React, { useState, useContext } from 'react';

// Compositions.
import WidgetsButtons from '../WidgetsButtons';
import WidgetsDesign from '../WidgetsDesign';
import ContentAndDesignSwitch from '../ContentAndDesignSwitch';

// Context.
import { EditorContext } from '../../context/EditorContext';

const LAYOUT_TYPES = {
  content: 'content',
  design: 'design',
};

const LAYOUTS = {
  content: <WidgetsButtons />,
  design: <WidgetsDesign />,
};

const WidgetsController = () => {
  const [layoutType, setLayoutType] = useState(LAYOUT_TYPES.content);
  const { activeElement } = useContext(EditorContext);

  const layoutButtonsArr = [
    {
      title: 'Content',
      value: 'content',
      onClick: () => setLayoutType('content'),
    },
    {
      title: 'Design',
      value: 'design',
      onClick: () => setLayoutType('design'),
    },
  ];

  return (
    <>
      {!activeElement && (
        <ContentAndDesignSwitch layoutButtons={layoutButtonsArr} layoutType={layoutType} />
      )}
      {LAYOUTS[layoutType]}
    </>
  );
};

export default WidgetsController;
