import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { map, isEmpty } from 'lodash';
import { ListItem } from './index';
import { TABLET_MAX_WIDTH } from '../../common';
import { ListCreateModal } from '../../containers/Modal';
import { ListCreatePopup } from '../../containers/Popup';
import { genericActions, notificationActions, contactActions } from '../../actions';
import { listDelete } from '../../services/contactApi';

const { openModalPortal, closeModalPortal } = genericActions;
const { notifyError, notifySuccess } = notificationActions;
const {
  deleteList,
} = contactActions;

const ListSelection = ({
  openModalPortalA,
  closeModalPortalA,
  notifyErrorA,
  notifySuccessA,
  windowWidth,
  addNewList,
  listAdded,
  deleteListA,
  visible,
  changeView,
  currentList,
  lists,
  messageEditor,
  message,
}) => {
  const [addPopupOpen, setAddPopupOpen] = useState(false);

  useEffect(() => () => {
    document.getElementById('root').removeEventListener('click', () => {
      if (!addNewList) {
        setAddPopupOpen(false);
      }
      handleListAdded();
    }, { once: true });
  }, []);

  const openCreateList = () => {
    // If window width is less than 767 we dispatching action to
    // open contact filter modal although the event is firing from
    // the POPUP trigger. Popup is not showing because it is controled
    // element(open prop remains false)
    if (windowWidth <= TABLET_MAX_WIDTH) {
      openModalPortalA({
        content: <ListCreateModal />,
        contentProps: {
          size: windowWidth < TABLET_MAX_WIDTH ? 'fullscreen' : 'small',
          handleModalState: () => {
            closeModalPortalA();
            handleListAdded();
          },
          windowWidth: window.innerWidth < TABLET_MAX_WIDTH,
        },
      });
      // We handle popup state
    } else {
      if (addNewList) {
        handleListAdded();
      } else {
        setAddPopupOpen(!addPopupOpen);
      }
      // Handling popup state when clicking outside popup
      document.getElementById('root').addEventListener('click', () => {
        if (!addNewList) {
          setAddPopupOpen(false);
        }
        handleListAdded();
      }, { once: true });
    }
  };

  const handleListAdded = () => {
    if (listAdded) {
      listAdded();
    }
  };

  const handleListDelete = (listId) => {
    listDelete({ id: listId }).then((res) => {
      if (res.error) {
        notifyErrorA('Something went wrong... Please try again later.');
      } else {
        changeView('all');
        const getListName = (id) => {
          let list;
          for (let i = 0; i < lists.length; i += 1) {
            if (lists[i].id === id) {
              list = lists[i];
              break;
            }
          }
          return list?.name;
        };
        const listName = getListName(listId);
        notifySuccessA(`List ${listName} successfully deleted`);
        deleteListA({ id: listId });
      }
    });
  };

  return (
    <div className="contact-lists">
      {(visible || addNewList) && (
        <div className="contact-lists__content">
          <div className="contact-lists__content__header">
            <span className="contact-lists__content__header__title">
              Lists
            </span>
            <ListCreatePopup
              trigger={(
                <span
                  className="contact-lists__content__header__icon"
                  onClick={() => openCreateList()}
                />
              )}
              handlePopupState={openCreateList}
              open={addPopupOpen || addNewList}
            />
          </div>
          <div
            className={`contact-lists__content__list-item ${currentList === 'all' ? 'contact-lists__content__list-item--active' : ''}`}
            onClick={() => changeView('all')}
          >
            <span className="contact-lists__content__list-item__title">
              All Contacts
            </span>
          </div>
          {!isEmpty(lists) && map(lists, (listObj) => {
            const { id, temporal } = listObj;

            if (!temporal || (messageEditor && message && message.temporalListId === id)) {
              return (
                <ListItem
                  key={id}
                  list={listObj}
                  changeView={changeView}
                  currentList={currentList}
                  handleListDelete={handleListDelete}
                />
              );
            }
            return null;
          })}
          <div
            className={`contact-lists__content__list-item ${currentList === 'unsubscribed' ? 'contact-lists__content__list-item--active' : ''}`}
            onClick={() => changeView('unsubscribed')}
          >
            <span className="contact-lists__content__list-item__title">
              Unsubscribed
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

ListSelection.propTypes = {
  visible: PropTypes.bool,
  lists: PropTypes.array,
  changeView: PropTypes.func,
  currentList: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  windowWidth: PropTypes.number,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  deleteListA: PropTypes.func,
  notifySuccessA: PropTypes.func,
  addNewList: PropTypes.bool,
  listAdded: PropTypes.func,
  messageEditor: PropTypes.bool,
  message: PropTypes.object,
};

const mapStateToProps = (state) => ({
  lists: state.lists.elements,
});

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  deleteListA: bindActionCreators(deleteList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListSelection);
