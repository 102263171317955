import React from 'react';
import PropTypes from 'prop-types';

// Hooks.
import { useVideoJS } from '../../hooks/useVideoJS';

// Styles.
import './WidgetVideoUI.scss';

const WidgetVideoUI = ({
  data,
  index,
  isController = false,
}) => {
  const videoId = `vid-${index + 1}${isController ? '-controller' : ''}`;
  const isVideoSourceExists = data?.sources?.[0]?.src;

  if (!isVideoSourceExists) {
    return (
      <div
        className="WidgetVideoUI device-form__video"
        id={videoId}
      >
        <div className="WidgetVideoUI__video-player-placeholder" />
      </div>
    );
  }
  const src = data?.sources?.[0]?.src;
  const type = data?.sources?.[0]?.type;
  const isYoutubeUrl = src?.includes('youtube.com').toString();

  const { Video } = useVideoJS({
    controls: true,
    autoplay: false,
    preload: 'auto',
    resizeManager: false,
    autoSetup: false,
    ...data,
  }, src, type, videoId, isYoutubeUrl);

  return (
    <div
      className="WidgetVideoUI device-form__video"
      id={`video-${index}`}
      youtube={isYoutubeUrl}
      key={videoId}
    >
      <Video playsInline />
    </div>
  );
};

WidgetVideoUI.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.shape({
    changedKey: PropTypes.string.isRequired,
    sources: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      src: PropTypes.string,
    })),
  }).isRequired,
  src: PropTypes.string,
  type: PropTypes.string,
  isController: PropTypes.bool,
};

export default React.memo(WidgetVideoUI);
