import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

const Color = ({
  labelContent,
  labelClassName,
  input,
  onClick,
}) => (
  <div className="color-field" onClick={onClick}>
    {labelContent && <Label className={labelClassName} content={labelContent} />}
    <span className="color-block colorPopup" style={{ backgroundColor: [input.value] }} />
    <input {...input} readOnly type="text" className="color-input colorPopup" />
  </div>
);

Color.propTypes = {
  input: PropTypes.object,
  labelContent: PropTypes.string,
  labelClassName: PropTypes.string,
  onClick: PropTypes.func,
};

export default Color;
