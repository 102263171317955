import { useEffect } from 'react';
import { Route as ReactRoute } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import CookieConsent from 'react-cookie-consent';
import { isEmpty } from 'lodash';
import 'semantic-ui-css/semantic.min.css';
import '../../assets/styles/main.scss';
import { Authorization } from '../Authorization';
import { genericActions } from '../../actions';
import { getBrowserInfo } from '../../utils/browserInfo';

const { browserInfo } = genericActions;

const Route = ({
  component,
  layout,
  name,
  path,
  exact,
  allowedRoles,
  selectedFeature,
  browserInfoA,
  device,
}) => {
  useEffect(() => {
    if (isEmpty(device)) {
      browserInfoA(getBrowserInfo());
    }
  }, []);

  return (
    <div>
      <ReactRoute
        name={name}
        path={path}
        exact={exact}
        render={() => (
          <Authorization
            allowedRoles={allowedRoles}
            selectedFeature={selectedFeature}
            component={component}
            layout={layout}
          />
        )}
      />
      <CookieConsent
        containerClasses="cookie-policy--container"
        contentClasses="cookie-policy--content"
        buttonClasses="cookie-policy--button"
        buttonText="OK"
      >
        We use cookies to ensure we give you the best experience on our website.
        You can change your&nbsp;
        <a
          href="/privacy-policy"
          className="clickable-underline"
        >
          cookie settings
        </a>
        &nbsp;at any time. Otherwise we’ll assume you are OK to continue
      </CookieConsent>
    </div>
  );
};

Route.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any,
  name: PropTypes.string,
  path: PropTypes.string,
  exact: PropTypes.bool,
  allowedRoles: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  device: PropTypes.object,
  browserInfoA: PropTypes.func,
  selectedFeature: PropTypes.string,
};

const mapStateToProps = (state) => ({
  device: state.device,
});

const mapDispatchToProps = (dispatch) => ({
  browserInfoA: bindActionCreators(browserInfo, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Route);
