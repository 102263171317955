import { cloneElement } from 'react';
import PropTypes from 'prop-types';

export const ColumnComponent = (props) => {
  const { children } = props;
  return (
    <div>
      {children && cloneElement(children, props)}
    </div>
  );
};

ColumnComponent.propTypes = {
  children: PropTypes.element,
};

export { UserAccessCols } from './UserAccess';
export { default as ContactTableCols } from './ContactTable';
export { default as PaymentHistoryCols } from './PaymentHistory';
export { default as MessageStatsTable } from './MessageStatsTable';
export { default as MessageVideoTable } from './MessageVideoTable';
