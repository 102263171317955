import { call } from './contactApi';
import { getUrlProperty } from '../utils/helper';
import { ENV } from '../config';

const { API_ENV } = ENV;

export const contactTagAdd = (id, params) => call(`/contact/tag/${id}/add`, {
  method: 'POST',
  body: params,
}, null);

export const contactTagCreate = (body) => call('/contact/tag', {
  method: 'POST',
  body,
}, null);

export const tagsFetchAll = () => call('/contact/tags', {
  method: 'GET',
}, null);

export const tagsFetchContacts = (filter, listId, type) => {
  const url = `contact/${API_ENV}/contacts/tags?type=${type}${filter}&list=${listId}`;
  let pathTemplate = getUrlProperty('pathname', url);
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);

  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};

export const contactTagRemove = (tagId, body) => call(`/contact/tag/${tagId}/remove`, {
  method: 'POST',
  body,
}, null);
