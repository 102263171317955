import { Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { ListActionsForm } from '../Form';

const ListActionsModal = (props) => {
  const { open, type } = props;
  return (
    <Modal
      className="modal modal--tag"
      open={open}
      closeOnDocumentClick={true}
      onClose={() => {
        props.closeModalPortal();
      }}
      dimmer="inverted"
    >
      <Modal.Header>{type === 'delete' ? 'Delete from List' : 'Add to List'}</Modal.Header>
      <Modal.Content>
        <ListActionsForm {...props} />
      </Modal.Content>
    </Modal>
  );
};

ListActionsModal.propTypes = {
  closeModalPortal: PropTypes.func,
  props: PropTypes.array,
  type: PropTypes.string,
  open: PropTypes.bool,
};

export default ListActionsModal;
