import './bootstrap';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Switch } from 'react-router';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import { Route } from './components/Route';
import {
  AccountSettings,
  Messages,
  MessageUnavailable,
  MsgStats,
  Contacts,
  NotFound,
  ForgotPassword,
  Login,
  ResetPassword,
  Register,
  SuccessPage,
  SuccessValidation,
  ErrorValidation,
  MediaLibrary,
  AutomationList,
  AutomationEditor,
  TermsAndConditions,
  PrivacyPolicy,
  AntiSpamPolicy,
} from './containers/Page';
import { MessageCreationWizard } from './components/Wizard';
import {
  AnonymousLayout,
  MainLayout,
  EditorLayout,
  AutomationLayout,
} from './containers/Layouts';
import root from './sagas';
import { featuresKeys } from './common/plans';

const history = createBrowserHistory();
const store = configureStore();
store.runSaga(root, history);

const meta = document.createElement('meta');
meta.name = 'viewport';
meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0';

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route
          exact
          name="accountSettings"
          path="/account-settings"
          component={AccountSettings}
          layout={MainLayout}
          allowedRoles={['admin', 'root', 'expired']}
        />
        <Route
          exact
          name="analytics"
          path="/message/:id/stats"
          component={MsgStats}
          layout={MainLayout}
          allowedRoles={['admin', 'root', 'user']}
          selectedFeature={featuresKeys.featureKeys.realTimeAnalytics}
        />
        <Route
          exact
          name="default"
          path="/"
          component={Messages}
          layout={MainLayout}
          allowedRoles={['admin', 'root', 'user']}
        />
        <Route
          exact
          name="contacts"
          path="/contacts"
          component={Contacts}
          layout={MainLayout}
          allowedRoles={['admin', 'root', 'user']}
        />
        <Route
          exact
          name="forgotPassword"
          path="/forgot-password"
          component={ForgotPassword}
          layout={AnonymousLayout}
          allowedRoles="*"
        />
        <Route
          exact
          name="login"
          path="/login"
          component={Login}
          layout={AnonymousLayout}
          allowedRoles="*"
        />
        <Route
          exact
          name="messageEditor"
          path="/message-editor/:id?/:preview?"
          component={MessageCreationWizard}
          layout={EditorLayout}
          allowedRoles={['admin', 'root', 'user']}
        />
        <Route
          exact
          name="messages"
          path="/messages"
          component={Messages}
          layout={MainLayout}
          allowedRoles={['admin', 'root', 'user']}
        />
        <Route
          exact
          name="messageUnavailable"
          path="/messages/unavailable"
          component={MessageUnavailable}
          layout={AnonymousLayout}
          allowedRoles={['admin', 'root', 'user']}
        />
        <Route
          exact
          name="register"
          path="/register"
          component={Register}
          layout={AnonymousLayout}
          allowedRoles="*"
        />

        {/* Policy Pages */}
        <Route
          exact
          name="termsAndConditions"
          path="/terms-and-conditions"
          component={TermsAndConditions}
          layout={AnonymousLayout}
          allowedRoles="*"
        />
        <Route
          exact
          name="antiSpamPolicy"
          path="/anti-spam-policy"
          component={AntiSpamPolicy}
          layout={AnonymousLayout}
          allowedRoles="*"
        />
        <Route
          exact
          name="privacyPolicy"
          path="/privacy-policy"
          component={PrivacyPolicy}
          layout={AnonymousLayout}
          allowedRoles="*"
        />

        <Route
          exact
          name="resetPassword"
          path="/reset-password"
          component={ResetPassword}
          layout={AnonymousLayout}
          allowedRoles="*"
        />
        <Route
          exact
          name="successPage"
          path="/successPage/:id"
          component={SuccessPage}
          layout={MainLayout}
          allowedRoles={['admin', 'root', 'user']}
        />
        <Route
          exact
          name="successValidation"
          path="/success-validation"
          component={SuccessValidation}
          layout={AnonymousLayout}
          allowedRoles="*"
        />
        <Route
          exact
          name="errorValidation"
          path="/error-validation"
          component={ErrorValidation}
          layout={AnonymousLayout}
          allowedRoles="*"
        />
        <Route
          exact
          name="mediaLibrary"
          path="/media-library"
          component={MediaLibrary}
          layout={MainLayout}
          allowedRoles={['admin', 'root', 'user']}
          selectedFeature={featuresKeys.featureKeys.mediaLibrary}
        />
        <Route
          exact
          name="automationList"
          path="/automation"
          component={AutomationList}
          layout={MainLayout}
          allowedRoles={['admin', 'root', 'user']}
          selectedFeature={featuresKeys.featureKeys.automation}
        />
        <Route
          exact
          name="automationEditor"
          path="/automation-editor/:id"
          component={AutomationEditor}
          layout={AutomationLayout}
          allowedRoles={['admin', 'root', 'user']}
          selectedFeature={featuresKeys.featureKeys.automation}
        />
        <Route name="404" path="*" component={NotFound} layout={AnonymousLayout} />
      </Switch>
    </Router>
  </Provider>, document.getElementById('root'),
);
