import PropTypes from 'prop-types';

const EmptyMediaItem = ({ openUploadModal }) => (
  <div
    className="empty-media-item"
    onClick={() => openUploadModal()}
  >
    <span className="empty-media-item__icon" />
    <span className="empty-media-item__text">Add more media</span>
  </div>
);

EmptyMediaItem.propTypes = {
  openUploadModal: PropTypes.func,
};

export default EmptyMediaItem;
