import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Grid, Button } from 'semantic-ui-react';
import { getAccountUser } from '../../actions/accountActions';
import { accountGet } from '../../services/accountsApi';

const SuccessValidation = ({ getAccountUserA }) => {
  const { push } = useHistory();
  useEffect(() => {
    accountGet().then((res) => {
      if (!res.error) {
        getAccountUserA(res.response);
      }
    });
  }, []);

  const handleRedirection = () => {
    push('/messages');
  };

  return (
    <div>
      <div className="notfound__validation__header">
        <Grid>
          <Grid.Row centered className="notfound__validation__header--big">
            Email validated successfully
          </Grid.Row>
          <Grid.Row centered className="notfound__validation__header--small">
            You can now send messages using this email address
          </Grid.Row>
        </Grid>
      </div>
      <div className="notfound__validation__content">
        <Grid>
          <Grid.Row centered>
            <i className="arrow-down-blue" />
          </Grid.Row>
          <Grid className="notfound__validation__flexable">
            <Grid.Column computer={16} tablet={16} mobile={16}>
              <Button fluid onClick={handleRedirection} className="blue">
                Return to Messages
              </Button>
            </Grid.Column>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAccountUserA: bindActionCreators(getAccountUser.success, dispatch),
});

SuccessValidation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  getAccountUserA: PropTypes.func,
};

export default withRouter(connect(null, mapDispatchToProps)(SuccessValidation));
