import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './LayoutButton.scss';

const LayoutButton = ({
  isActive,
  children,
  onClick,
  className,
}) => (
  <button
    type="button"
    className={classNames('LayoutButton', className, {
        LayoutButton__active: isActive,
      })}
    onClick={onClick}
  >
    {children}
  </button>
  );

LayoutButton.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LayoutButton;
