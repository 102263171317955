import {
  cloneElement,
  useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import {
  remove,
  find,
  get,
  filter,
  includes,
  isEmpty,
  forEach,
} from 'lodash';
import { mapTags } from '../../utils/contactsMapping';
import {
  ContactFormModal,
  ContactUploadModal,
  ChangeDataModal,
} from '../../containers/Modal';
import { TABLET_MAX_WIDTH } from '../../common';
import ContactEmpty from '../ContactEmpty';
import { Spinner } from '../Spinner';
import { mapContactsToDataTable } from '../../utils/map';
import { ContactTableCols } from '../TableColumns';
import { TablePager } from '../Pager';
import {
  contactActions,
  genericActions,
} from '../../actions';
import { ContactsHeader } from './index';

const {
  setPageSize,
} = contactActions;
const { openModalPortal, closeModalPortal } = genericActions;

function fixContacts(currentPageResults) {
  currentPageResults.forEach((cont) => {
    /* eslint-disable */
    cont['contact.tag'] = mapTags(cont['contact.tagObjects']);
    /* eslint-enable */
  });
  return currentPageResults;
}

const tBodyComponent = (data) => {
  const updatedData = data;
  for (let i = 0; i < updatedData.children[0].length; i += 1) {
    if (updatedData.children) {
      updatedData.children[0][i] = cloneElement(updatedData.children[0][i], {
        minWidth: updatedData.style.minWidth,
      });
    }
  }

  return <div className="rt-tbody">{updatedData.children}</div>;
};

const trGroupComponent = (data) => (
  <div
    className="rt-tr-group"
    role="rowgroup"
    style={{ minWidth: data.minWidth }}
  >
    {data.children}
  </div>
);

const ListView = ({
  unselect,
  contactsUnselected,
  propertyId,
  list,
  views,
  lists,
  setSelected,
  currentPageResults,
  setContactsPageSize,
  onPageChange,
  openModalPortalA,
  closeModalPortalA,
  orderBy,
  windowWidth,
  currentPageNo,
  pageSize,
  contactView,
  messageEditor,
  contactFilter,
  handleContactDelete,
  calculatePageSize,
  loading,
  currentPageSizes,
  totalCount,
  handleSelectionVisibility,
  changeView,
  sortTable,
  clearSort,
  onMenuItemClick,
  listAction,
}) => {
  const [currentSelected, setCurrentSelected] = useState([]);
  const [excluded, setExcluded] = useState([]);
  const [view, setView] = useState({});

  const tBodyComponentRef = useRef(null);

  useEffect(() => {
    tBodyComponentRef.current = document.querySelector('.rt-tbody');
    if (tBodyComponentRef.current) {
      tBodyComponentRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tBodyComponentRef.current) {
        tBodyComponentRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (views && !isEmpty(views) && lists) {
      let viewId = 0;
      if (typeof list === 'number') {
        ({ viewId } = find(lists, (li) => li.id === list));
      }
      const updatedView = find(views, (v) => {
        if (viewId === 0) {
          return v.default;
        }
        return v.id === viewId;
      });

      setView(updatedView || {});
    }

    if (unselect) {
      setCurrentSelected([]);
      setSelected([]);
      contactsUnselected();
    }

    if (propertyId || propertyId === 0) {
      recolourHeaders();
    }
  }, [
    unselect,
    contactsUnselected,
    propertyId,
    list,
    views,
    lists,
  ]);

  const onContactEditClick = (_id) => {
    const initialContact = find(currentPageResults, { 'contact.id': _id });
    openContactFormModal(initialContact);
  };

  const setNewPageSize = (ee, options) => {
    setContactsPageSize(options.value);
    onPageChange(0, options.value);
  };

  const openContactFormModal = (initialContact) => {
    openModalPortalA({
      content: <ContactFormModal />,
      contentProps: {
        handleFormModalState: closeModalPortalA,
        size: window.innerWidth < TABLET_MAX_WIDTH ? 'fullscreen' : 'small',
        initialContact,
        windowWidth: window.innerWidth < TABLET_MAX_WIDTH,
        list,
        propertyId,
        orderBy,
      },
    });
  };

  const openContactUploadModal = () => {
    openModalPortalA({
      content: <ContactUploadModal />,
      contentProps: {
        handleUploadModalState: closeModalPortalA,
        size: windowWidth < TABLET_MAX_WIDTH ? 'fullscreen' : 'small',
        windowWidth: window.innerWidth < TABLET_MAX_WIDTH,
        list,
        propertyId,
        orderBy,
      },
    });
  };

  const openColumnsModal = () => {
    openModalPortalA({
      content: <ChangeDataModal />,
      contentProps: {
        closeModalPortal: closeModalPortalA,
        list,
        pageNo: currentPageNo,
        pageSize,
        propertyId,
        order: orderBy,
      },
    });
  };

  const handleContactSelection = (id) => {
    const selectedContact = filter(currentPageResults, { 'contact.id': id });
    const itemIncludes = typeof currentSelected === 'string' ? includes(excluded, id) : includes(currentSelected, id);
    let selectedContacts = typeof currentSelected === 'string' ? excluded : currentSelected;

    if (id === '*') {
      handleSelectAll();
    } else if (typeof currentSelected !== 'string') {
      if (itemIncludes) {
        selectedContacts = remove(selectedContacts, (_id) => _id !== id);
        setCurrentSelected(selectedContacts);
        setSelected(selectedContacts);
      }
      if (!itemIncludes) {
        if (!isEmpty(selectedContact[0])) {
          selectedContacts.push(id);
          setCurrentSelected(selectedContacts);
          setSelected(selectedContacts);
        }
      }
    } else {
      if (itemIncludes) {
        selectedContacts = remove(selectedContacts, (_id) => _id !== id);
        setExcluded(selectedContacts);
        setSelected('*', excluded);
      }
      if (!itemIncludes) {
        if (!isEmpty(selectedContact[0])) {
          selectedContacts.push(id);
          setExcluded(selectedContacts);
          setSelected('*', excluded);
        }
      }
    }
  };

  const handleSelectAll = () => {
    if (typeof currentSelected === 'string' && excluded.length === 0) {
      handleUnselect();
    } else {
      setCurrentSelected('*');
      setExcluded([]);
      setSelected('*');
    }
  };

  const handleScroll = (event) => {
    const headers = document.getElementsByClassName('rt-thead');
    for (let i = 0; i < headers.length; i += 1) {
      headers[i].scrollLeft = event.target.scrollLeft;
    }
  };

  const handleUnselect = () => {
    setCurrentSelected([]);
    setExcluded([]);
    setSelected();
  };

  const recolourHeaders = () => {
    const headers = document.getElementsByClassName('rt-th rt-resizable-header -cursor-pointer');
    forEach(headers, (header, index) => {
      if (header.getElementsByClassName('contacts-table-header-column').length === 1) {
        headers[index].style.backgroundColor = '#012';
      } else {
        headers[index].style.backgroundColor = '#34414F';
      }
    });
  };

  const { properties } = view;

  const filterId = get(contactFilter, 'id', undefined);

  const current = [...currentSelected];

  const currentPageSelected = remove(current, (_id) => !!find(currentPageResults, (c) => c['contact.id'] === _id));

  return (
    <div>
      {!totalCount && !filterId && contactView.length ? (
        <ContactEmpty
          openContactFormModal={openContactFormModal}
          openContactUploadModal={openContactUploadModal}
        />
      ) : (
        <div className={`contacts-container${messageEditor && currentSelected.length > 0 ? ' contacts-container__editor' : ''} table-page-container table-row-hover`}>
          <ContactsHeader
            openContactFormModal={openContactFormModal}
            openContactUploadModal={openContactUploadModal}
            pageSize={pageSize}
            currentPageSizes={currentPageSizes}
            setPageSize={setNewPageSize}
            totalCount={totalCount}
            windowWidth={windowWidth}
            handleSelectionVisibility={handleSelectionVisibility}
            list={list}
            handleUnselect={handleUnselect}
            changeView={changeView}
            clearSort={clearSort}
            openColumnsModal={openColumnsModal}
            onDeleteClick={() => handleContactDelete('*')}
            visible={currentSelected.length > 0}
            selected={currentSelected}
            excluded={excluded}
            onMenuItemClick={onMenuItemClick}
            listAction={listAction}
          />
          <Spinner loaded={!!(!loading || totalCount || properties)}>
            {lists && currentPageResults && (
              <ReactTable
                loading={loading}
                data={mapContactsToDataTable(fixContacts(currentPageResults))}
                columns={
                  ContactTableCols(
                    properties,
                    handleContactSelection,
                    onContactEditClick,
                    handleContactDelete,
                    currentSelected,
                    (currentPageSelected.length !== 0 || typeof currentSelected === 'string') && (currentPageSelected.length === currentPageResults.length || (typeof currentSelected === 'string' && excluded.length === 0)),
                    lists,
                    excluded,
                    propertyId,
                    orderBy,
                    sortTable,
                    list,
                  )
                }
                pageSize={calculatePageSize()}
                className="-striped contacts-table-class"
                PaginationComponent={() => (
                  <div className="contacts-pager">
                    <TablePager
                      pageSize={calculatePageSize()}
                      pageNumbers={pageSize}
                      onPageChange={onPageChange}
                      page={currentPageNo}
                      total={totalCount}
                    />
                  </div>
                )}
                getTrProps={(state, rowInfo) => ({
                  onClick: () => {
                    handleContactSelection(rowInfo.original.id);
                  },
                })}
                TbodyComponent={tBodyComponent}
                TrGroupComponent={trGroupComponent}
              />
            )}
          </Spinner>
        </div>
      )}
    </div>
  );
};

ListView.propTypes = {
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  contactView: PropTypes.object,
  contactFilter: PropTypes.object,
  pageSize: PropTypes.number,
  messageEditor: PropTypes.bool,
  setContactsPageSize: PropTypes.func,
  windowWidth: PropTypes.number,
  handleContactDelete: PropTypes.func,
  currentPageNo: PropTypes.number,
  loading: PropTypes.bool,
  currentPageSizes: PropTypes.array,
  calculatePageSize: PropTypes.func,
  onPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  currentPageResults: PropTypes.array,
  handleSelectionVisibility: PropTypes.func,
  setSelected: PropTypes.func,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  unselect: PropTypes.bool,
  contactsUnselected: PropTypes.func,
  changeView: PropTypes.func,
  lists: PropTypes.array,
  sortTable: PropTypes.func,
  clearSort: PropTypes.func,
  propertyId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  orderBy: PropTypes.string,
  views: PropTypes.object,
  onMenuItemClick: PropTypes.func,
  listAction: PropTypes.func,
};

const mapStateToProps = (state) => ({
  contactView: state.contactView,
  contactPaginator: state.contactPaginator,
  contactFilter: state.contactFilter,
  lists: state.lists.elements,
  views: state.views,
});

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
  setContactsPageSize: bindActionCreators(setPageSize, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
