import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const Card = ({ card }) => (
  <div className="card inline">
    <Icon name="credit card alternative" />
    &nbsp;**** **** ****&nbsp;
    {card.number}
  </div>
);

Card.propTypes = {
  card: PropTypes.object.isRequired,
};

export default Card;
