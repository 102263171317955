import React, { useContext } from 'react';

import { EditorContext } from '../../context/EditorContext';
import ColorSelector from '../../components/ColorSelector';

// Styles.
import './WidgetsDesign.scss';

const WidgetsDesign = () => {
  const { backgroundColor, setBackgroundColor } = useContext(EditorContext);

  return (
    <div className="WidgetsDesign">
      <ColorSelector
        label="Background color:"
        currentColor={backgroundColor}
        hasInput
        onChange={(value) => setBackgroundColor(value)}
      />
    </div>
  );
};

export default WidgetsDesign;
