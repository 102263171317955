import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { BasicButton } from '../../components/Button';

const DelayExplanationModal = ({
  open,
  close,
  handleAddDelay,
}) => (
  <Modal
    open={open}
    onClose={close}
    className="modal"
    dimmer="inverted"
  >
    <Modal.Header>
      Add delay?
    </Modal.Header>
    <Modal.Content>
      <div className="delete-column-modal__content--column">
        <span className="delete-column-modal__content__disclaimer">
          NB: Give recipients enough time to open and respond to your
          message by adding a delay before your next automation actions.
        </span>
        <span className="delete-column-modal__content__disclaimer">
          If you do not add a delay, your automation actions will
          run immediately and before any data is captured.
        </span>
        <span className="delete-column-modal__content__disclaimer">
          Would you like to add delay node?
        </span>
      </div>
    </Modal.Content>
    <Modal.Actions>
      <div className="delete-column-modal__actions">
        <BasicButton
          content="No"
          size="small"
          className="white"
          onClick={close}
        />
        <BasicButton
          content="Yes"
          size="small"
          color="blue"
          onClick={handleAddDelay}
        />
      </div>
    </Modal.Actions>
  </Modal>
  );

DelayExplanationModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  handleAddDelay: PropTypes.func,
};

export default DelayExplanationModal;
