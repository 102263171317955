import { paymentHistoryPaginator } from '../paginators';

const paymentHistory = (state = {}, action) => {
  switch (action.type) {
    default: {
      const { meta } = action;
      const paymentHistoryPagination = paymentHistoryPaginator.itemsReducer(state, action);
      if (meta && meta.name === 'payments') {
        return paymentHistoryPagination;
      }
      return state;
    }
  }
};

export default paymentHistory;
