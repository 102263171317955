import { mediaActions } from '../actions';
import { parseS3Response } from '../utils/helper';

const { FETCH_MEDIA, DELETE_MEDIA } = mediaActions;

const media = (state = { video: [], image: [] }, action) => {
  switch (action.type) {
    case FETCH_MEDIA.SUCCESS: {
      const { mediaType: { type }, s3Media } = action;
      return {
        ...state,
        [type]: parseS3Response(s3Media.response),
      };
    }

    case DELETE_MEDIA.SUCCESS: {
      const { extraParam: { mediaType, title } } = action;

      return {
        ...state,
        [mediaType]: state[mediaType].filter((item) => item.title !== title),
      };
    }

    default: {
      return state;
    }
  }
};

export default media;
