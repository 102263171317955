import PropTypes from 'prop-types';

const NPSSelected = ({
  value,
  index,
}) => (
  <div className="nps-answer">
    <div className="nps-answer__choice">{index}</div>
    <div className="nps-answer__responses">{value}</div>
  </div>
);

NPSSelected.propTypes = {
  value: PropTypes.number,
  index: PropTypes.number,
};

export default NPSSelected;
