import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

// Styles.
import './SelectInput.scss';

const SelectField = ({
  options,
  disabled = false,
  placeholder,
  className,
  direction,
  defaultValue,
  upward,
  tabIndex,
  onChange,
  id,
  label,
}) => (
  <div className="SelectInput">
    {label && <p className="SelectInput__label">{label}</p>}
    <Dropdown
      id={id}
      className={className}
      defaultValue={defaultValue}
      disabled={disabled}
      direction={direction}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      selectOnBlur={false}
      scrolling
      upward={upward}
      tabIndex={tabIndex}
    />
  </div>
  );

SelectField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  defaultValue: PropTypes.string,
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  upward: PropTypes.bool,
  tabIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
};

export default SelectField;
