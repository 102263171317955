import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HeaderNavigation, AccountNavigationDesktop } from '../../components/Header';
import { createMessage } from '../../actions/messageActions';
import { useScreenWidth } from '../../hooks';

const HeaderMain = ({ createMessageA }) => {
  const { isDesktop } = useScreenWidth();
  return (
    <div className="app-header app-header__main">
      <div className="site-logo__container">
        <Link to="/" href="/" className="site-logo" />
      </div>
      {isDesktop && (
        <HeaderNavigation />
      )}
      <div className="app-header__main__navigation">
        {isDesktop && (
          <AccountNavigationDesktop />
        )}
        <div onClick={() => createMessageA({ name: '', origin: 'default', deliveryMethod: 'sms' })} className="header-message-button header-message" />
      </div>
    </div>
  );
};

HeaderMain.propTypes = {
  createMessageA: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  createMessageA: bindActionCreators(createMessage.request, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(HeaderMain));
