import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Divider } from 'semantic-ui-react';
import {
  includes,
  remove,
  forEach,
  find,
  findIndex,
  map,
  get,
} from 'lodash';
import arrayMove from 'array-move';
import { ColumnsSelector, ColumnOrder } from '../../components/Contacts/ChangeData';
import { contactActions, notificationActions } from '../../actions';
import { BasicButton } from '../../components/Button';
import {
  contactPropertyDelete,
  propertyLabelUpdate,
  contactViewUpdate,
  contactViewCreate,
  listEdit,
  contactViewPropertyAdd,
  contactViewPropertyRemove,
} from '../../services/contactApi';
import DeletePropertyModal from './DeletePropertyModal';
import { isArrayCountValid } from '../../utils/validator';

const {
  fetchContactProperties,
  fetchViews,
  addView,
  deleteProperty,
  updatePropertyLabel,
  fetchLists,
  requestContactsPage,
} = contactActions;
const { notifySuccess, notifyError } = notificationActions;

const ChangeDataModal = ({
  fetchContactPropertiesA,
  contactProperties,
  views,
  lists,
  list,
  fetchViewsA,
  fetchListsA,
  closeModalPortal,
  notifySuccessA,
  notifyErrorA,
  requestContactsPageA,
  pageNo,
  contactFilter,
  pageSize,
  propertyId,
  order,
  deletePropertyA,
  open,
  updatePropertyLabelA,
  addViewA,
}) => {
  const [columns, setColumns] = useState([]);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [addColumn, setAddColumn] = useState(false);
  const [editing, setEditing] = useState(undefined);
  const [initial, setInitial] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [id, setId] = useState(undefined);
  const [name, setName] = useState(undefined);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(undefined);

  useEffect(() => {
    fetchContactPropertiesA();
  }, []);

  useEffect(() => {
    if (list && lists && views && contactProperties) {
      let viewId = 0;
      if (typeof list === 'number') {
        ({ viewId } = find(lists, (li) => li.id === list));
      }
      const newView = find(views, (v) => {
        if (viewId === 0) {
          return v.default;
        }
        return v.id === viewId;
      });
      const { properties } = newView;
      const newColumns = map(contactProperties, (property) => property);
      const newSelected = [];
      if (initial) {
        forEach(properties, (prop) => {
          newSelected.push(findIndex(newColumns, (col) => col.id === prop.id));
        });
        setSelected(newSelected);
      }

      setColumns(newColumns);
      setInitial(false);
      setView(newView);
    }
  }, [contactProperties, views, lists, list]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      setSelected(arrayMove(selected, oldIndex, newIndex));
    }
  };

  const getAddedAndRemovedProperties = () => {
    const { properties } = view;
    const currentIds = map(properties, (property) => property.id);
    const newIds = map(selected, (s) => columns[s].id);
    const added = [];
    const removed = [];
    forEach(newIds, (_id) => {
      if (_id && !includes(currentIds, _id)) {
        added.push(_id);
      }
    });
    forEach(currentIds, (_id) => {
      if (_id && !includes(newIds, _id)) {
        removed.push(_id);
      }
    });
    return ({ added, removed });
  };

  const setWeights = (index, newView) => {
    if (newView) {
      listEdit({
        id: list,
        body: {
          viewId: index,
        },
      }).then(() => {
        fetchListsA();
      });
    }

    const payload = {
      id: index,
      body: {
        weights: prepareReorderPayload(),
      },
    };
    contactViewUpdate(payload).then((res) => {
      if (res.error) {
        setLoading(false);
        notifyErrorA(res.error.message);
      } else {
        setLoading(false);
        const filterId = get(contactFilter, 'id', undefined);
        requestContactsPageA(pageNo, filterId, pageSize, list, propertyId, order);
        notifySuccessA('Column order saved successfully');
        fetchViewsA();
        closeModalPortal();
      }
    });
  };

  const addRemoveProperties = (index) => {
    const { added, removed } = getAddedAndRemovedProperties();

    const promises = [];
    if (added.length) {
      promises.push(contactViewPropertyAdd({ id: index, body: { ids: added } }));
    }
    if (removed.length) {
      promises.push(contactViewPropertyRemove({ id: index, body: { ids: removed } }));
    }

    return promises;
  };

  const handleColumnEdit = (index) => setEditing(index);

  const handleColumnDelete = (columnId, columnName, columnSelectedIndex) => {
    setShowDelete((prevState) => !prevState);
    setId(columnId);
    setName(columnName);
    setSelectedIndex(columnSelectedIndex);
  };

  const onDeleteProperty = () => {
    setDeleteLoading(true);
    contactPropertyDelete(id).then((res) => {
      if (res.error) {
        notifyErrorA(res.error.message);
        setDeleteLoading(false);
      } else {
        if (includes(selected, selectedIndex)) {
          handleColumnSelect(selectedIndex);
        }
        const filterId = get(contactFilter, 'id', undefined);
        requestContactsPageA(pageNo, filterId, pageSize, list, propertyId, order);
        notifySuccessA(`Column ${name} deleted`);
        deletePropertyA({ id });
        handleColumnDelete();
        setDeleteLoading(false);
        setSelectedIndex(undefined);
      }
    });
  };

  const handleColumnSelect = (index) => {
    const selectedColumns = [...selected];
    if (includes(selectedColumns, index)) {
      remove(selectedColumns, (val) => val === index);
    } else {
      selectedColumns.push(index);
    }
    setSelected(selectedColumns);
  };

  const handleSearchInput = ({ value }) => {
    setSearch(value);
  };

  const openCreateColumn = () => {
    setAddColumn((prevState) => !prevState);

    // Handling popup state when clicking outside popup
    document.getElementById('root').addEventListener('click', () => {
      setAddColumn(false);
    }, { once: true });
  };

  const handleUpdateProperty = (label, index) => {
    setLoading(true);
    const { id: columnId } = columns[index];
    const payload = {
      id: columnId,
      body: {
        label,
      },
    };
    propertyLabelUpdate(payload).then((res) => {
      if (res.error) {
        setLoading(false);
        notifyErrorA(res.error.message);
      } else {
        setLoading(false);
        notifySuccessA('Column name updated successfully');
        updatePropertyLabelA({ id: columnId, label });
      }
    });
  };

  const prepareReorderPayload = () => {
    const selectedColumns = [...selected];
    return map(selectedColumns, (column, index) => ({
      propertyId: columns[column].id,
      weight: index,
    }));
  };

  const reorderTable = () => {
    setLoading(true);

    if (typeof list === 'string') {
      waitPropertiesAddRemove(view.id);
    } else {
      const selectedList = find(lists, (li) => li.id === list);
      const { default: defaultView } = view;
      if (defaultView) {
        const body = {
          name: `${selectedList.name} View`,
        };
        contactViewCreate(body).then((res) => {
          if (res.error) {
            setLoading(false);
            notifyErrorA(res.error.message);
          } else {
            const { response } = res;
            addViewA(response);
            waitPropertiesAddRemove(response.id, true);
          }
        });
      } else {
        waitPropertiesAddRemove(view.id);
      }
    }
  };

  const waitPropertiesAddRemove = (index, newView) => {
    Promise.all(addRemoveProperties(index)).then(() => {
      setWeights(index, newView);
    });
  };

  const isArrayValid = isArrayCountValid(selected, 1, 99);

  if (columns.length) {
    return (
      <Modal
        open={open}
        onClose={() => closeModalPortal()}
        className="modal"
        dimmer="inverted"
      >
        <Modal.Header>Change Data</Modal.Header>
        <Modal.Content>
          <div className="column-order">
            <DeletePropertyModal
              open={showDelete}
              close={handleColumnDelete}
              deleteProperty={onDeleteProperty}
              loading={deleteLoading}
            />
            <ColumnsSelector
              columns={columns}
              handleColumnSelect={handleColumnSelect}
              selected={selected}
              search={search}
              handleSearchInput={handleSearchInput}
              addNewColumn={addColumn}
              openCreatePopup={openCreateColumn}
              handleColumnDelete={handleColumnDelete}
              editing={editing}
              handleColumnEdit={handleColumnEdit}
              handleUpdateProperty={handleUpdateProperty}
            />
            <div className="column-order__divider">
              <Divider vertical />
            </div>
            <ColumnOrder
              columns={columns}
              selected={selected}
              onSortEnd={onSortEnd}
              handleColumnSelect={handleColumnSelect}
              useDragHandle
            />
          </div>
          {!isArrayValid
          && (
            <div className="properties-error">
              Must at least be between 1 and 99 properties
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <div className="column-order__modal-actions">
            <BasicButton
              content="Cancel"
              size="small"
              className="white"
              onClick={closeModalPortal}
            />
            <BasicButton
              disabled={loading || !isArrayValid}
              loading={loading}
              content="Change"
              size="small"
              color="blue"
              className="segmentation-button"
              onClick={reorderTable}
            />
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
  return null;
};

ChangeDataModal.propTypes = {
  open: PropTypes.bool,
  closeModalPortal: PropTypes.func,
  fetchContactPropertiesA: PropTypes.func,
  contactProperties: PropTypes.object,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  views: PropTypes.object,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  lists: PropTypes.array,
  deletePropertyA: PropTypes.func,
  updatePropertyLabelA: PropTypes.func,
  fetchViewsA: PropTypes.func,
  addViewA: PropTypes.func,
  fetchListsA: PropTypes.func,
  requestContactsPageA: PropTypes.func,
  pageNo: PropTypes.number,
  contactFilter: PropTypes.object,
  pageSize: PropTypes.number,
  propertyId: PropTypes.number,
  order: PropTypes.string,
};

const mapStateToProps = (state) => ({
  contactProperties: state.entities.contactProperties,
  views: state.views,
  lists: state.lists.elements,
  contactFilter: state.contactFilter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContactPropertiesA: bindActionCreators(fetchContactProperties.request, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  deletePropertyA: bindActionCreators(deleteProperty, dispatch),
  updatePropertyLabelA: bindActionCreators(updatePropertyLabel, dispatch),
  fetchViewsA: bindActionCreators(fetchViews.request, dispatch),
  addViewA: bindActionCreators(addView, dispatch),
  fetchListsA: bindActionCreators(fetchLists.request, dispatch),
  requestContactsPageA: bindActionCreators(requestContactsPage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDataModal);
