import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components.
import WidgetControllerHeader from '../../components/WidgetControllerHeader';
import { BasicButton } from '../../../components/Button';

// Local components.
import SurveyInfoController from './components/SurveyInfoController';
import SurveyQuestionsController from './components/SurveyQuestionsController';

// Compositions.
import WidgetControllerFooter from '../../compositions/WidgetControllerFooter';

// Context.
import { EditorContext } from '../../context/EditorContext';

// Styles.
import './SurveyWidgetController.scss';


const SurveyWidgetController = () => {
  const {
    replaceElement,
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const onSaveButtonClick = () => {
    replaceElement(activeElement);
    setActiveElement(false);
  };

  const onCancelButtonClick = () => {
    setActiveElement(false);
  };

  const createQuestion = () => {
    const dataObj = { ...activeElement };
    const { questions } = dataObj;

    const defaultQuestionObj = {
        questionId: `${uuidv4()}-oneAnswer`,
        type: 'oneAnswer',
        answerRequired: false,
        questionTitle: 'question #',
        quizMode: false,
        answerExample: '',
        ratingValue: '10',
        answersOptions: [
          {
            answerId: uuidv4(),
            title: 'choice #',
          },
          {
            answerId: uuidv4(),
            title: 'choice #',
          },
        ],
    };

    questions.push(defaultQuestionObj);

    setActiveElement(dataObj);
  };

  return (
    <div className="SurveyWidgetController">
      <WidgetControllerHeader title="Survey settings" />
      <div className="SurveyWidgetController__controller-body">
        <SurveyInfoController />
        <SurveyQuestionsController />
        <div className="SurveyWidgetController__button-wrapper">
          <BasicButton
            size="small"
            content="+ Next Question"
            color="blue"
            className="invert"
            onClick={() => createQuestion()}
          />
        </div>
      </div>
      <WidgetControllerFooter
        onSaveButtonClick={() => onSaveButtonClick()}
        onCancelButtonClick={() => onCancelButtonClick()}
      />
    </div>
  );
};

export default SurveyWidgetController;
