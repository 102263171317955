import agent from 'superagent';
import qs from 'query-string';
/* eslint-disable */

// based on http://stackoverflow.com/a/7616484/1836434
const hashUrl = (url) => {
  let hash = 0, i, chr, len;
  if (url.length === 0) return hash
  for (i = 0, len = url.length; i < len; i++) {
    chr   = url.charCodeAt(i)
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0;  //  Convert to 32bit integer
  }
  return hash;
}

let _promises = {};

export const FROM_CACHE_FLAG = '@@redux-paginator/FROM_CACHE_FLAG';

export const buildSuffix = (pageArgName, page, params) => {
  const parsedParams = qs.parse(params)
  let finalParsedParams = {}
  let startString = ''
  for (let key of Object.keys(parsedParams)) {
    if (parsedParams[key] == null) {
      startString += key
    }
    else {
      finalParsedParams = {
        ...finalParsedParams,
        [key]: parsedParams[key]
      }
    }
  }
  startString = startString === '' ? '?' : startString + '?';

  if (Object.keys(finalParsedParams).length > 0 && qs.extract(params) !== '') {
    startString = params.replace(qs.extract(params), '')
  }
  return startString+qs.stringify({
    ...finalParsedParams,
    [pageArgName]: page
  }, { encode: false }).replace(startString, '')
}

export const fetchPage = (endpoint, pageArgName, page, params, callApi, modified) => {
  const suffix = buildSuffix(pageArgName, page, params);
  const url = endpoint + suffix;
  const hash = hashUrl(url);
  let fromCache = true;
  let promise = _promises[hash];
  if (typeof promise === 'undefined' || modified) {
    fromCache = false;
    if (callApi) {
      promise = callApi(url);
    } else {
      promise = new Promise((resolve, reject) =>
        agent
          .get(url)
          .end((err, res) => err ? reject(err) : resolve(res))
      )
    }

    if (modified) {
      _promises = {
        [hash]: promise,
      };
    } else {
      _promises[hash] = promise;
    }
  }

  return promise.then(res => fromCache ? {
    response: res.response,
    [FROM_CACHE_FLAG]: null
  } : { response: res.response })
}

export const clearPromises = () => _promises = {};

/* eslint-enable */
