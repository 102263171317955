import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Segment } from 'semantic-ui-react';
import { BasicButton } from '../../components/Button';
import { FileUpload } from '../../components/FileUpload';
import { Spinner } from '../../components/Spinner';

const S3UploadModal = ({
  onUpload,
  closeModalPortal,
  open,
  maxSize,
  accept,
  fileName: propsFileName,
  modalName,
  maxVideoSize,
}) => {
  const [fileBase64, setFileBase64] = useState('');
  const [fileName, setFileName] = useState('');
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);

  const handleUpload = () => {
    if (fileName) {
      setLoading(true);
      onUpload(fileBase64, fileName, type);
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModalPortal}
    >
      <Modal.Header>
        {modalName || 'Upload File'}
      </Modal.Header>
      <Modal.Content className="contact-upload-modal">
        <Spinner loaded={!loading} modal={true}>
          <Segment>
            <FileUpload
              onDrop={(newFileBase64, name, newType) => {
                setFileBase64(newFileBase64);
                setFileName(name);
                setType(newType);
              }}
              accept={accept}
              maxSize={maxSize}
              fileName={propsFileName}
              maxVideoSize={maxVideoSize}
            />
          </Segment>
        </Spinner>
      </Modal.Content>
      <Modal.Actions>
        <BasicButton
          content="Cancel"
          className="small tertiary"
          onClick={closeModalPortal}
        />
        <BasicButton
          content="Upload"
          size="small"
          color="blue"
          disabled={loading}
          onClick={handleUpload}
        />
      </Modal.Actions>
    </Modal>
  );
};

S3UploadModal.propTypes = {
  open: PropTypes.bool,
  onUpload: PropTypes.func,
  closeModalPortal: PropTypes.func,
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  maxSize: PropTypes.number,
  fileName: PropTypes.string,
  modalName: PropTypes.string,
  maxVideoSize: PropTypes.number,
};

export default S3UploadModal;
