import PropTypes from 'prop-types';
import { CURVE_HEIGHT, CURVED_PATH_DRAW } from '../../../common/automation';

// view box is 2px larger (1px in every direction in order not to crop paths near the edge
const CurvedPath = ({ x, y, type }) => (
  <svg
    x={x - 1}
    y={y - 1}
    width={CURVE_HEIGHT + 2}
    height={CURVE_HEIGHT + 2}
    viewBox={`0 0 ${CURVE_HEIGHT + 2} ${CURVE_HEIGHT + 2}`}
  >
    <path
      className="edge__path"
      d={CURVED_PATH_DRAW[type]}
    />
  </svg>
);

CurvedPath.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  type: PropTypes.string,
};

export default CurvedPath;
