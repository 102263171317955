import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Compositions.
import EditorViewHeader from '../EditorViewHeader';
import MessageElementsDistributor from '../MessageElementsDistributor';

// Styles.
import './EditorView.scss';

const LAYOUT_TYPES = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
};

const EditorView = ({
  isPreview,
  setDisplayResponsiveToolbar,
  setDisplayAutomationToolbar,
  displayAutomationToolbar,
  displayResponsiveToolbar,
}) => {
  const { mobile } = LAYOUT_TYPES;
  const [layoutType, setLayoutType] = useState(mobile);

  const layoutButtonsArr = [
    {
      title: 'Mobile',
      value: 'mobile',
      onClick: () => setLayoutType('mobile'),
    },
    {
      title: 'Tablet',
      value: 'tablet',
      onClick: () => setLayoutType('tablet'),
    },
    {
      title: 'Desktop',
      value: 'desktop',
      onClick: () => setLayoutType('desktop'),
    },
  ];

  return (
    <div className="EditorView">
      <EditorViewHeader
        layoutButtons={layoutButtonsArr}
        layoutType={layoutType}
        isPreview={isPreview}
      />
      <div className={`EditorView__preview-${LAYOUT_TYPES[layoutType]}`}>
        <MessageElementsDistributor
          setDisplayResponsiveToolbar={setDisplayResponsiveToolbar}
          setDisplayAutomationToolbar={setDisplayAutomationToolbar}
          displayAutomationToolbar={displayAutomationToolbar}
          displayResponsiveToolbar={displayResponsiveToolbar}
        />
      </div>
    </div>
  );
};

EditorView.propTypes = {
  setDisplayAutomationToolbar: PropTypes.func,
  setDisplayResponsiveToolbar: PropTypes.func,
  displayAutomationToolbar: PropTypes.bool,
  displayResponsiveToolbar: PropTypes.bool,
  isPreview: PropTypes.bool,
};

export default EditorView;
