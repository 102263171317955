import { callApi } from './callApi';
import { getUrlProperty, getServicePath, getFullServicePath } from '../utils/helper';

export const call = (endpoint, params, _schema) => callApi({ serviceName: getServicePath('invoice'), endpoint }, params, _schema);

export const fetchInvoice = (filename) => {
  const url = getFullServicePath('invoice', `/invoice?filename=${filename}`);
  let pathTemplate = getUrlProperty('pathname', url);
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);

  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};
