import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import {
  Form,
  reduxForm,
  Field,
} from 'redux-form';

import { Input, PhoneInputField, PasswordInput } from '../../components/Field';
import { BasicButton } from '../../components/Button';
import {
  required,
  email,
  password,
  phone,
  passwordsMatch,
} from '../../utils/validator';

const RegisterForm = ({
  handleSubmit,
  onFormSubmit,
  initialValues,
  submitting,
  loading,
}) => {
  const isDisabled = loading || submitting;

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)} className="app-form nobackground-flex">
      <Grid>
        <Grid.Row>
          <Grid.Column className="centered" computer={6} tablet={8} mobile={14}>
            <Field
              className="app-form__field"
              label="Company name"
              labelPosition="left"
              component={Input}
              name="companyName"
              size="large"
              validate={required}
              disabled={!!initialValues}
              placeholder="Enter your company name"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="centered" computer={6} tablet={8} mobile={14}>
            <Field
              className="app-form__field"
              label="Full name"
              component={Input}
              name="fullName"
              size="large"
              validate={required}
              placeholder="Enter your full name"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="centered" computer={6} tablet={8} mobile={14}>
            <Field
              className="app-form__field"
              label="Email"
              component={Input}
              name="email"
              size="large"
              validate={[email, required]}
              disabled={!!initialValues}
              placeholder="Enter your email"
              type="email"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="centered" computer={6} tablet={8} mobile={14}>
            <Field
              className="app-form__field"
              labelClassName=""
              label="Phone number"
              component={PhoneInputField}
              name="phone"
              validate={[phone, required]}
              placeholder="+45 XXX XXXXX"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="centered" computer={6} tablet={8} mobile={14}>
            <Field
              className="app-form__field"
              label="Password"
              component={PasswordInput}
              name="password"
              size="large"
              validate={password}
              type="password"
              showPasswordToggle
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="registration-padding">
          <Grid.Column className="centered" computer={6} tablet={8} mobile={14}>
            <div>
              By clicking, you agree to&nbsp;
              <Link
                to="/terms-and-conditions"
                className="clickable-underline"
              >
                Terms & Conditions,
              </Link>
              <span>
                &nbsp;
              </span>
              <Link
                to="/anti-spam-policy"
                className="clickable-underline"
              >
                anti SPAM policy
              </Link>
              &nbsp;and&nbsp;
              <Link
                to="/privacy-policy"
                className="clickable-underline"
              >
                privacy policy
              </Link>
              .
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="centered" computer={6} tablet={8} mobile={14}>
            <BasicButton
              type="submit"
              fluid
              content="Register"
              color="blue"
              className="small-14"
              disabled={isDisabled}
              loading={loading}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-spacing">
          <Grid.Column
            className="app-form__already centered"
            width={16}
          >
            <p>
              Already have an account?
              <a href="/login" className="app-form__already-link">Login</a>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const formConfig = {
  form: 'register',
  validate: passwordsMatch,
};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func,
  onFormSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  loading: PropTypes.bool,
};

export default withRouter(reduxForm(formConfig)(RegisterForm));
