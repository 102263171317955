import { callApi } from './callApi';
import { getUrlProperty, getServicePath } from '../utils/helper';
import { ENV } from '../config';

const { API_ENV } = ENV;

export const call = (endpoint, params, _schema) => callApi({ serviceName: getServicePath('events'), endpoint }, params, _schema);

export const analyticsDataGet = ({ messageId }) => {
  const url = `/events/${API_ENV}/initial?mid=${messageId}`;
  let pathTemplate = getUrlProperty('pathname', url);
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);
  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};

export const analyticsDataPost = (messageId) => {
  const url = `/events/${API_ENV}/initial`;
  let pathTemplate = getUrlProperty('pathname', url);
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);
  return call('', {
    pathTemplate,
    method: 'POST',
    body: messageId,
    additionalParams: { queryParams },
  }, null);
};

export const analyticsDataExport = ({ id, body }) => {
  const url = `/events/${API_ENV}/details/${id}/export`;
  let pathTemplate = getUrlProperty('pathname', url);
  pathTemplate = pathTemplate.slice(1);
  return call('', {
    pathTemplate,
    method: 'POST',
    body,
  }, null);
};


export const recipientsDataGet = ({ messageId, page, pageSize }) => {
  const url = `/events/${API_ENV}/details/${messageId}?page=${page}&count=${pageSize}`;
  let pathTemplate = getUrlProperty('pathname', url);
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);
  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};
