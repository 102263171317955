import { createRequestTypes } from './request';

export const FETCH_MEDIA = createRequestTypes('LIST_MEDIA');
export const DELETE_MEDIA = createRequestTypes('DELETE_MEDIA');

export const fetchMedia = (mediaType) => ({ type: FETCH_MEDIA.REQUEST, mediaType });

export const deleteMedia = {
  request: (payload) => ({ type: DELETE_MEDIA.REQUEST, payload }),
  success: (response, extraParam) => ({ type: DELETE_MEDIA.SUCCESS, extraParam }),
  failure: (error) => ({ type: DELETE_MEDIA.FAILURE, error }),
};
