import PropTypes from 'prop-types';

import Spinner from './Spinner';

function WidgetSpinner({ children, loaded, options }) {
  return (
    <div className="widget-spinner__wrapper">
      <Spinner
        loaded={loaded}
        options={options}
      />
      {children}
    </div>
  );
}

WidgetSpinner.propTypes = {
  children: PropTypes.node.isRequired,
  loaded: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    size: PropTypes.string.isRequired,
    notLoadedClassName: PropTypes.string.isRequired,
  }),
};

WidgetSpinner.defaultProps = {
  options: {
    size: 'big',
    notLoadedClassName: 'widget-spinner',
  },
};

export default WidgetSpinner;
