import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import Icon from '../Icon/Icon';
import FooterMainFixed from './FooterMainFixed';

const FooterMain = ({ userRole }) => {
  const isExpired = userRole === 'expired';

  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <div className="app-footer main">
      <div className={`main-nofix${isExpired ? ' expired-footer' : ''}`}>
        <Icon name="footer-logo" />
        <div className="main-nofix__meta">
          <Container>
            <span>
              {`©  Copyright ${year} Rispons`}
            </span>
            <a
              href="/terms-and-conditions"
              className="terms"
            >
              Terms of service
            </a>
          </Container>
        </div>
      </div>
      {!isExpired && <FooterMainFixed />}
    </div>
  );
};

FooterMain.propTypes = {
  userRole: PropTypes.string,
};

export default FooterMain;
