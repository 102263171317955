import PropTypes from 'prop-types';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(
  ({ disabled, label }) => (
    <div className="column-order__order__container__item__left">
      <span className={`column-order__order__container__item__left__draggable${disabled ? '--disabled' : ''}`} />
      <span className={`column-order__order__container__item__left__text${disabled ? '--disabled' : ''}`}>{label}</span>
    </div>
  ),
);

const ColumnSortableItem = ({
  item,
  disabledRemove,
  id,
  handleColumnSelect,
  disabled,
}) => (
  <div className="column-order__order__container__item">
    <DragHandle disabled={disabled} label={item.label} />
    {!disabledRemove && (
      <span
        className="column-order__order__container__item__remove"
        onClick={() => handleColumnSelect(id)}
      />
    )}
  </div>
);

ColumnSortableItem.propTypes = {
  item: PropTypes.object,
  disabledRemove: PropTypes.bool,
  id: PropTypes.number,
  handleColumnSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SortableElement(ColumnSortableItem);
