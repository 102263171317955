import {
  forEach,
  get,
  capitalize,
  filter,
  includes,
  omit,
  findIndex,
} from 'lodash';
import moment from 'moment';
import { DATE_TIME } from '../common';

export const formatMappings = (values) => {
  const mappings = [];
  forEach(values, (value) => {
    mappings.push({
      label: value.header,
      property: get(value, 'match.internalName', '') !== null ? get(value, 'match.internalName', '') : '',
    });
  });
  return mappings;
};

export const formatMessageHeader = (message) => {
  const messageStats = get(message, 'stats[0]', null);
  let actionPrefix = null;
  let actionTime = null;
  let contacts = null;
  let openRate = null;

  if (message.status === 'draft') {
    actionPrefix = 'Scheduled at';
    actionTime = moment(message.scheduledAt).format(DATE_TIME);
  } else if (message.status === 'sent' || message.status === 'frozen') {
    actionPrefix = 'Sent at';
    actionTime = moment(message.scheduledAt).format(DATE_TIME);
  }

  if (messageStats) {
    contacts = get(messageStats, 'value', '0');
    openRate = get(messageStats, 'percent', '0').concat(' %');
  }
  return {
    actionPrefix,
    actionTime,
    contacts,
    openRate,
  };
};

export const formatMessageMainContent = (message, users) => {
  const createdByUserId = get(message, 'createdBy', undefined);
  const createdByUser = users && createdByUserId && users[createdByUserId];
  const deliveryMethod = capitalize(get(message, 'deliveryMethod', ''));
  const backgroundImg = get(message, 's3Data.screenshotLocation', '');
  const createdBy = createdByUser ? createdByUser.fullName : '';
  return {
    createdBy,
    deliveryMethod,
    backgroundImg,
  };
};

export const formatMessageSecondaryContent = (message) => {
  const messageName = get(message, 'name', '');
  const messageOrigin = get(message, 'origin', 'default');
  const messageStatus = capitalize(get(message, 'status', ''));
  let expirationDate = '0001/Jan/01';
  if (message.expiresAt && message.expiresAt !== '0001-01-01T00:00:00Z') {
    expirationDate = moment(message.expiresAt).format(DATE_TIME);
  }

  return {
    messageName,
    messageStatus,
    expirationDate,
    messageOrigin,
  };
};

export const formatDataSetupForYoutube = (src) => ({
  techOrder: src ? ['youtube'] : [''],
  sources: src ? [{ type: 'video/youtube', src }] : [],
  youtube: src ? { ytControls: 0 } : {},
});

export const formatRecipientsPayload = (selected, contacts) => filter(contacts, (c) => (includes(selected, c['contact.id'])))
  .map((_c) => ({
    contactId: _c['contact.id'],
    phone: _c['contact.phoneNumber'],
    email: _c['contact.email'],
  }));

export const formatArrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const formatVideoAttachments = (msg) => {
  const attachments = [];
  const videoAttachments = msg.filter((element) => element.elementType === 'video');

  if (videoAttachments) {
    videoAttachments.forEach((videoAttachment) => {
      const youtubeSrc = !!videoAttachment.data.youtube;
      if (youtubeSrc) {
        let ref = videoAttachment.data.sources[0].src.replace('https://www.youtube.com/embed/', 'https://www.youtube.com/watch?v=');
        const endIdx = ref.indexOf('?controls');
        ref = ref.slice(0, endIdx);

        attachments.push({
          type: 'video',
          refType: 'link',
          ref,
        });
      } else {
        attachments.push({
          type: 'video',
          refType: 'id',
          ref: videoAttachment.data.sources[0].src.split('/').pop(),
        });
      }
    });
  }

  return attachments;
};

export const formatDeliveryMethodInitialValues = (messageEditor) => {
  const {
    deliveryMethod,
    from,
    subjectLine,
    plainText,
    background,
    buttonBackground,
    buttonText,
    expiresAt,
    logo,
    listId,
    nameFrom,
    schema: {
      unsubscribe,
    },
  } = messageEditor;
  const initialEmpty = {
    method: 'email',
    messageText: 'Enter your text here - this will be the message your contacts will receive. <link>',
    expiresAt: '',
    background: '#ffffff',
    buttonBackground: '#000000',
    buttonText: '',
    listId: !!listId || listId === 0 ? listId : 'none',
    nameFrom: '',
  };

  let expires = moment(expiresAt);
  if (!expiresAt || expiresAt === '0001-01-01T00:00:00Z') {
    expires = moment().add(1, 'hour').set('minute', 0);
  }

  return {
    method: deliveryMethod || initialEmpty.method,
    messageText: plainText || (plainText === '' && deliveryMethod === 'email') ? plainText : initialEmpty.messageText,
    background: background || initialEmpty.background,
    expiration: !!expiresAt && expiresAt !== '0001-01-01T00:00:00Z',
    expiresAt: expires.toDate(),
    from,
    subject: subjectLine,
    logo,
    buttonBackground: buttonBackground || initialEmpty.buttonBackground,
    buttonText: buttonText || initialEmpty.buttonText,
    listId: listId || initialEmpty.listId,
    nameFrom: nameFrom || initialEmpty.nameFrom,
    unsubscribe: !!unsubscribe,
  };
};

export const formatDeliveryDataSavePayload = (values) => ({
  ...omit(values, 'method', 'subject', 'messageText', 'expiration'),
  deliveryMethod: values.method,
  subjectLine: values.subject,
  plainText: values.messageText,
  expiresAt: values.expiration
    ? moment(values.expiresAt).toISOString() : undefined,
  listId: values.listId === 'none' ? null : values.listId,
});

const addQuizQuestion = (quiz, name, input, values) => {
  const formIndex = findIndex(quiz, (form) => form.name === name);
  if (formIndex >= 0) {
    quiz[formIndex].forms.push({
      input,
      values,
    });
  } else {
    quiz.push({
      name,
      forms: [
        {
          input,
          values,
        },
      ],
    });
  }

  return quiz;
};

export const formatQuizData = (forms) => {
  let quiz = [];

  forEach(forms, (form) => {
    const { elements, displayName } = form;
    forEach(elements, (element) => {
      const {
        elementType,
        isQuiz,
        answers,
        inputName,
      } = element;
      if (elementType === 'Choices' || elementType === 'SingleChoice') {
        if (isQuiz) {
          const values = [...answers];
          quiz = addQuizQuestion(quiz, displayName, inputName, values);
        }
      }
    });
  });

  return quiz;
};

export const formatOptInInputs = (msg) => {
  const inputs = [];
  if (!msg) {
    return inputs;
  }
  const optIns = msg.querySelectorAll('.device-form__opt-in');
  if (!optIns) {
    return inputs;
  }
  optIns.forEach((optInNode) => {
    const inputNodes = optInNode.querySelectorAll('.body__input-fields input');
    if (!inputNodes) {
      return false;
    }
    inputs.push(...Array.from(inputNodes));
    return inputs;
  });
  return inputs;
};

export const formatButtonLinks = (msg) => {
  const inputs = [];
  if (!msg) {
    return inputs;
  }

  const buttons = msg.querySelectorAll('.device-form__button');
  if (!buttons) {
    return inputs;
  }

  buttons.forEach((buttonNode) => {
    const buttonLink = buttonNode.getAttribute('link');
    if (!buttonLink) {
      inputs.push('');
    }
    inputs.push(buttonLink);
  });

  return inputs;
};
