import { call, takeLatest } from 'redux-saga/effects';
import { requestSaga, showSuccessMessage } from './genericSagas';
import { planActions } from '../actions';
import { api } from '../services';

const {
  FETCH_PLANS,
  FETCH_COUNTRIES,
  addQuote,
  getCountries,
  updatePlan,
  savePlan,
  deletePlan,
  FETCH_SESSION,
  getSession,
  getPlans,
} = planActions;

const {
  plans:
    {
      planUpdate,
      planSave,
      planDelete,
      quoteSave,
      fetchPlans,
      fetchCountries,
      fetchSession,
    },
} = api;

function* fetchSessionSaga() {
  yield call(requestSaga, getSession, fetchSession);
}

function* fetchCountriesSaga() {
  yield call(requestSaga, getCountries, fetchCountries);
}

function* fetchPlansSaga() {
  yield call(requestSaga, getPlans, fetchPlans);
}

function* planUpdateSaga(args) {
  yield call(requestSaga, undefined, planUpdate, args, { ...updatePlan });
}

function* addQuoteSaga(args) {
  yield call(requestSaga, undefined, quoteSave, args, { ...addQuote });
}

function* planSaveSaga(args) {
  yield call(requestSaga, undefined, planSave, args, { ...savePlan });
}

function* planDeletionSaga(args) {
  yield call(requestSaga, undefined, planDelete, args, { ...deletePlan });
}

export default function* watchPlans() {
  yield takeLatest(FETCH_SESSION.REQUEST, fetchSessionSaga);
  yield takeLatest(FETCH_COUNTRIES.REQUEST, fetchCountriesSaga);
  yield takeLatest(FETCH_PLANS.REQUEST, fetchPlansSaga);
  yield takeLatest(updatePlan.REQUEST, planUpdateSaga);
  yield takeLatest(savePlan.REQUEST, planSaveSaga);
  yield takeLatest(deletePlan.REQUEST, planDeletionSaga);
  yield takeLatest(addQuote.REQUEST, addQuoteSaga);
  yield takeLatest(addQuote.SUCCESS, showSuccessMessage, 'You successfully submitted a quote. We will shortly contact you');
}
