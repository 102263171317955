import { createPaginator } from '../modules/redux-paginator';
import { api } from '../services';
import { API_ROOT } from '../config';

const { accounts: { usersGet } } = api;

const usersPaginator = createPaginator(API_ROOT, ['users'], {
  resultsKey: 'elements',
  countKey: 'total',
  idKey: 'id',
  pageArgName: 'page',
}, usersGet);

export default usersPaginator;
