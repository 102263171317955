import * as contactApi from './contactApi';
import * as accountsApi from './accountsApi';
import * as analyticsApi from './analyticsApi';
import * as authApi from './authApi';
import * as formApi from './formApi';
import * as messageApi from './messageApi';
import * as planApi from './planApi';
import * as tagApi from './tagApi';
import * as S3Api from './S3';
import * as eventsApi from './eventsApi';
import * as automationApi from './automationApi';

export const api = {
  contact: contactApi,
  analytics: analyticsApi,
  accounts: accountsApi,
  auth: authApi,
  form: formApi,
  messages: messageApi,
  plans: planApi,
  tags: tagApi,
  S3: S3Api,
  events: eventsApi,
  automation: automationApi,
};
