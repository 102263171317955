import { map } from 'lodash';
import { contactViewPaginator } from '../paginators';

function fixContacts(currentPageResults) {
  const contactsWithTags = currentPageResults.map((cont) => [...cont.properties,
    {
      name: 'contact.id',
      value: cont.id,
    },
    {
      name: 'contact.tagObjects',
      value: cont.tags || [],
    },
    {
      name: 'contact.status',
      value: cont.status,
    },
    {
      name: 'contact.emailEnabled',
      value: cont.subscriptionChannels.email.enabled,
    },
    {
      name: 'contact.smsEnabled',
      value: cont.subscriptionChannels.sms.enabled,
    },
    {
      name: 'contact.listIds',
      value: cont.lists ? map(cont.lists, (list) => list.id) : [],
    },
    {
      name: 'contact.listTimestamp',
      value: cont.listTimestamp,
    },
  ]);

  const contacts = [];

  contactsWithTags.forEach((entities) => {
    const contact = [];
    entities.forEach((con) => {
      contact[con.name] = con.value;
    },
    );
    contacts.push(contact);
  });

  return contacts;
}

const contactView = (state = {}, action) => {
  switch (action.type) {
    default: {
      const { meta } = action;
      const newAction = action;
      if (meta && meta.name === 'contact') {
        if (newAction.payload.items) {
          newAction.payload.items = fixContacts(action.payload.items);
        }
        return contactViewPaginator.itemsReducer(state, newAction);
      }
      return state;
    }
  }
};

export default contactView;
