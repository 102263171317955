import { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  reduxForm,
  getFormValues,
  Field,
  change,
  destroy,
} from 'redux-form';
import { map } from 'lodash';
import { RenderRadio } from '../../components/Field';
import { BasicButton } from '../../components/Button';
import { authActions } from '../../actions';

const { login } = authActions;

const MultipleAccountsForm = ({
  dispatch,
  destroyForm,
  accounts,
  handleSubmit,
  pristine,
  submitting,
  loginFormValues,
  multipleAccountsFormValues,
  changeValue,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => () => {
    destroyForm('login');
  }, []);

  const handleLogin = () => {
    setLoading(true);

    login({ ...loginFormValues, ...multipleAccountsFormValues }, dispatch).then((res) => {
      if (res.error) {
        setLoading(false);
      }
    }).catch(() => {
      setLoading(false);
    });
  };

  return (
    <Form
      onSubmit={handleSubmit(() => handleLogin(),
      )}
      className="login-company"
    >
      <Grid>
        <Grid.Row centered className="login-company__subheader">
          {`You have multiple companies registered to ${loginFormValues.email}`}
          <br />
          Select with witch companies account you would like to currently use the system
        </Grid.Row>
        <div className="company-list">
          {map(accounts, (account, key) => (
            <Field
              key={key}
              name={multipleAccountsFormValues
                && multipleAccountsFormValues.account === key
                ? 'account' : 'account-'.concat(key)}
              component={RenderRadio}
              labelText={account.companyName}
              onChange={() => changeValue('multipleAccounts', 'account', key)}
              checked={multipleAccountsFormValues && multipleAccountsFormValues.account === key}
            />
          ))}
        </div>
        <Grid.Row>
          <Grid.Column className="centered" computer={4} tablet={5} mobile={12}>
            <BasicButton
              fluid
              content="Login"
              color="blue-light"
              disabled={loading || pristine || submitting}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  loginFormValues: getFormValues('login')(state),
  multipleAccountsFormValues: getFormValues('multipleAccounts')(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeValue: bindActionCreators(change, dispatch),
  destroyForm: bindActionCreators(destroy, dispatch),
});

const formConfig = {
  form: 'multipleAccounts',
};

MultipleAccountsForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  loginFormValues: PropTypes.object,
  multipleAccountsFormValues: PropTypes.object,
  accounts: PropTypes.object,
  changeValue: PropTypes.func,
  destroyForm: PropTypes.func,
  dispatch: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm(formConfig)(MultipleAccountsForm));
