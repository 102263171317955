import PropTypes from 'prop-types';

const Checkbox = ({
  htmlFor,
  label,
  input,
  meta: { touched, error },
}) => (
  <span className="checkbox-span">
    <label className="checkbox-label" htmlFor={htmlFor}>
      <input
        checked={input.value}
        onChange={input.onChange}
        type="checkbox"
      />
      <span />
    </label>
    <span>
      {label}
      {touched && error && <small className="error-message">{error}</small>}
    </span>
  </span>
);

Checkbox.propTypes = {
  htmlFor: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    touched: PropTypes.bool,
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.bool,
    ]),
    required: PropTypes.bool,
  }),
  label: PropTypes.string,
};

export default Checkbox;
