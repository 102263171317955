import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { Menu, Dropdown, Input } from 'semantic-ui-react';

import { distributionOptions, typeOptions, originOptions } from '../../common/options';

const MessageFilter = (
  {
    distributionFilterValue,
    typeFilterValue,
    changeNameFilterValue,
    changeOriginFilterValue,
    messageFilter,
  }) => {
  const [dropdownPointingState, setDropdownPointingState] = useState('top right');

  // https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Testing_media_queries
  const mediaQuery = window.matchMedia('(min-width: 480px)');
  const handleMobileChange = (query) => {
    if (query.matches) {
      setDropdownPointingState('top right');
    } else setDropdownPointingState('top left');
  };

  useEffect(() => {
    handleMobileChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMobileChange);
    return () => mediaQuery.removeEventListener('change', handleMobileChange);
  }, []);

  if (messageFilter && !isEmpty(messageFilter)) {
    const {
      distributionFilter,
      typeFilter,
      nameFilterValue,
      originFilter,
    } = messageFilter;
    const setSearchBarValue = (e) => {
      if (e.target.value === '' || e.target.value.length < 3) {
        changeNameFilterValue('');
      } else {
        changeNameFilterValue(e.target.value);
      }
    };
    const nameFilterText = () => {
      if (nameFilterValue === '') {
        return typeOptions.find((option) => option.value === typeFilter).text;
      }
      if (typeOptions.find((option) => option.value === typeFilter).text === 'All messages' && !!nameFilterValue && nameFilterValue.length > 2) {
        return nameFilterValue;
      }
      return `${nameFilterValue} ${typeOptions.find((option) => option.value === typeFilter).text}`;
    };

    return (
      <div className="messages-filters-wrapper">
        <Menu>
          <Dropdown
            defaultValue="all"
            pointing="top left"
            options={distributionOptions}
            onChange={(e, data) => {
              if (data.value !== distributionFilter) {
                distributionFilterValue(data.value);
              }
            }}
            className={
              classNames(
                'firstMessagesFilter filter-option',
                {
                  'filter-option--green': distributionFilter !== 'all',
                },
              )
            }
          />
          <Dropdown
            defaultValue="all"
            pointing="top left"
            options={originOptions}
            onChange={(e, data) => {
              if (data.value !== originFilter) {
                changeOriginFilterValue(data.value);
              }
            }}
            className={
              classNames(
                'secondMessagesFilter filter-option',
                {
                  'filter-option--green': originFilter !== 'all',
                },
              )
            }
          />
          <Dropdown
            defaultValue="all"
            text={nameFilterText()}
            pointing={dropdownPointingState}
            defaultOpen={false}
            className={
              classNames(
                'thirdMessagesFilter filter-option',
                {
                  'filter-option--green': typeFilter !== 'all',
                },
              )
            }
          >
            <Dropdown.Menu className="all-messages-filter ris-form">
              <Input
                onClick={(e) => e.stopPropagation()}
                name="search"
                placeholder="Enter"
                onChange={setSearchBarValue}
              />
              <Dropdown.Header content="Or choose by type:" />
              <Dropdown.Divider />
              <Dropdown.Item
                text={typeOptions[0].text}
                value={typeOptions[0].value}
                onClick={(e, data) => typeFilterValue(data.value)}
              />
              <Dropdown.Item
                text={typeOptions[1].text}
                value={typeOptions[1].value}
                onClick={(e, data) => typeFilterValue(data.value)}
              />
              <Dropdown.Item
                text={typeOptions[2].text}
                value={typeOptions[2].value}
                onClick={(e, data) => typeFilterValue(data.value)}
              />
              <Dropdown.Item
                text={typeOptions[3].text}
                value={typeOptions[3].value}
                onClick={(e, data) => typeFilterValue(data.value)}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      </div>
    );
  }

  return null;
};

MessageFilter.propTypes = {
  distributionFilterValue: PropTypes.func,
  typeFilterValue: PropTypes.func,
  changeNameFilterValue: PropTypes.func,
  changeOriginFilterValue: PropTypes.func,
  messageFilter: PropTypes.shape({
    distributionFilter: PropTypes.string,
    typeFilter: PropTypes.string,
    nameFilterValue: PropTypes.string,
    originFilter: PropTypes.string,
  }),
};

export default MessageFilter;
