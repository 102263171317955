import { memo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import {
  PlansHeader,
  PlansFeatures,
  PlansSupport,
} from './Components';

const PlansDesktop = ({
  plans,
  openPlanSubscriptionModal,
}) => (
  <>
    <PlansHeader
      openPlanSubscriptionModal={openPlanSubscriptionModal}
      plans={plans}
    />
    <PlansFeatures plans={plans} />
    <PlansSupport plans={plans} />
  </>
);

PlansDesktop.propTypes = {
  plans: PropTypes.array,
  openPlanSubscriptionModal: PropTypes.func,
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps.plans, nextProps.plans);

export default memo(PlansDesktop, areEqual);
