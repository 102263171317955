import PropTypes from 'prop-types';
import { map, includes, has } from 'lodash';
import { Input, Divider } from 'semantic-ui-react';
import ColumnItem from './ColumnItem';
import { ContactsColumnCreatePopup } from '../../../containers/Popup';

const ColumnsSelector = ({
  columns,
  handleColumnSelect,
  selected,
  search,
  handleSearchInput,
  addNewColumn,
  openCreatePopup,
  handleColumnDelete,
  editing,
  handleColumnEdit,
  handleUpdateProperty,
}) => (
  <div className="column-order__selector">
    <Input
      type="text"
      value={search}
      placeholder="Search for column"
      onChange={(e, data) => handleSearchInput(data)}
      className="column-order__selector__search"
    />
    <div className="column-order__selector__container">
      {map(columns, (col, index) => {
        const { required, label, type } = col;
        if (includes(label.toLocaleLowerCase(), search.toLocaleLowerCase())) {
          return (
            <ColumnItem
              key={index}
              handleColumnSelect={handleColumnSelect}
              column={col}
              index={index}
              checked={includes(selected, index)}
              disabled={(required && type !== 'custom') || !has(col, 'id')}
              custom={type && type === 'custom'}
              handleColumnDelete={handleColumnDelete}
              editing={editing}
              handleColumnEdit={handleColumnEdit}
              handleUpdateProperty={handleUpdateProperty}
            />
          );
        }
        return null;
      })}
    </div>
    <Divider />
    <ContactsColumnCreatePopup
      trigger={(
        <span
          className="column-order__selector__add"
          onClick={() => openCreatePopup}
        >
          Add New Column
        </span>
      )}
      handlePopupState={openCreatePopup}
      open={addNewColumn}
    />
  </div>
);

ColumnsSelector.propTypes = {
  columns: PropTypes.array,
  handleColumnSelect: PropTypes.func,
  selected: PropTypes.array,
  search: PropTypes.string,
  handleSearchInput: PropTypes.func,
  addNewColumn: PropTypes.bool,
  openCreatePopup: PropTypes.func,
  handleColumnDelete: PropTypes.func,
  editing: PropTypes.number,
  handleColumnEdit: PropTypes.func,
  handleUpdateProperty: PropTypes.func,
};

export default ColumnsSelector;
