export const GO_TO_PAGE = 'GO_TO_PAGE';
export const CLEAR_STATE_ENTITY = 'CLEAR_STATE_ENTITY';
export const CLEAR_PAGINATOR = 'CLEAR_PAGINATOR';
export const CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const CLOSE_MODAL_PORTAL = 'CLOSE_MODAL_PORTAL';
export const OPEN_MODAL_PORTAL = 'OPEN_MODAL_PORTAL';
export const CLEAR_REDUX_STATE = 'CLEAR_REDUX_STATE';
export const GET_BROWSER_INFO = 'GET_BROWSER_INFO';

export function goToPage(history, args) {
  history.push(args.route);
}

export const clearReduxStateEntity = (entityName) => ({
  type: CLEAR_STATE_ENTITY,
  entityName,
});
export const clearPaginator = (paginatorName) => ({
  type: CLEAR_PAGINATOR,
  paginatorName,
});
export const closeConfirmationModal = () => ({ type: CLOSE_CONFIRMATION_MODAL });
export const openConfirmationModal = (properties) => ({
  type: OPEN_CONFIRMATION_MODAL,
  properties,
});
export const closeModalPortal = () => ({ type: CLOSE_MODAL_PORTAL });
export const openModalPortal = (properties) => ({
  type: OPEN_MODAL_PORTAL,
  properties,
});
export const browserInfo = (properties) => ({
  type: GET_BROWSER_INFO,
  response: properties,
});
