import PropTypes from 'prop-types';
import { forEach } from 'lodash';
import HalfDonut from '../../Chart/HalfDonut';

const RatingAnalytics = ({
  metric: {
    value,
    numOfRespondents,
  },
  element: {
    maxValue,
  },
}) => {
  let sum = 0;
  forEach(value, (val, key) => {
    sum += parseInt(key, 10) * val;
  });
  const avg = Math.round((sum / numOfRespondents) * 100) / 100;

  return (
    <div className="survey-stats__nps">
      <div className="survey-stats__nps__chart">
        <HalfDonut
          data={[
            avg,
            (maxValue + 1) - avg,
          ]}
          backgroundColor={[
            '#1cb59f',
            '#e0e0e0',
          ]}
          borderColor="#fff"
        />
        <div className="survey-stats__nps__chart__details">
          <span className="survey-stats__nps__chart__details__-100--rating">1</span>
          <span className="survey-stats__nps__chart__details__100--rating">{maxValue}</span>
          <div className="survey-stats__nps__chart__details__information">
            <span>AVERAGE</span>
            <span>{`${numOfRespondents} answers`}</span>
          </div>
          <div className="survey-stats__nps__chart__details__rating">
            {avg}
          </div>
        </div>
      </div>
    </div>
  );
};

RatingAnalytics.propTypes = {
  metric: PropTypes.shape({
    value: PropTypes.object,
    numOfRespondents: PropTypes.number,
  }),
  element: PropTypes.shape({
    maxValue: PropTypes.number,
  }),
};

export default RatingAnalytics;
