import {
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { map } from 'lodash';
import ScrollContainer from 'react-indiana-drag-scroll';
import { AutomationContext } from '../../context/AutomationContext';
import {
  AutomationNode,
  AutomationEdge,
  InitialNode,
  AutomationIssues,
  AutomationMessageEditor,
} from '../../components/Automation/Editor';
import { OverlaySpinner } from '../../components/Spinner';
import { NodeAddEditModal, DeleteNodeModal } from '../Modal';
import { genericActions } from '../../actions';
import { useScreenWidth } from '../../hooks';
import { EDITOR_PADDING, MESSAGE_LIST_WIDTH, MESSAGE_EDITOR_WIDTH } from '../../common/automation';
import { notifyWarning } from '../../actions/notificationActions';

const { openModalPortal, closeModalPortal } = genericActions;

const AutomationEditor = ({
  openModalPortalA,
  closeModalPortalA,
  notifyWarningA,
}) => {
  const [initial, setInitial] = useState(true);
  const scrollContainer = useRef(null);
  const { id } = useParams();
  const {
    automation,
    loading,
    editorDimensions,
    splitScreenOpen,
    setSplitScreenOpen,
    selectedMessageId,
    setSelectedMessageId,
    editingDisabled,
  } = useContext(AutomationContext);

  const { isDesktop } = useScreenWidth();

  useEffect(() => {
    if (initial && editorDimensions && scrollContainer && scrollContainer.current) {
      const x = ((editorDimensions.width + 80 - window.innerWidth) / 2);
      // eslint-disable-next-line react/no-find-dom-node
      ReactDOM.findDOMNode(scrollContainer.current).scrollTo(x, 0);
      setInitial(false);
    }
  }, [editorDimensions, scrollContainer, scrollContainer.current]);

  const scrollWidthBool = useMemo(() => {
    if (editorDimensions && editorDimensions.width) {
      if (splitScreenOpen) {
        if (selectedMessageId) {
          return editorDimensions.width > window.innerWidth - MESSAGE_LIST_WIDTH;
        }
        return editorDimensions.width > window.innerWidth - MESSAGE_EDITOR_WIDTH;
      }
      return editorDimensions.width > window.innerWidth;
    }
    return false;
  }, [editorDimensions, window.innerWidth, splitScreenOpen, selectedMessageId]);

  const openAddEditNode = (initialNode, type, node, parent, child) => {
    openModalPortalA({
      content: <NodeAddEditModal />,
      contentProps: {
        close: closeModalPortalA,
        automationId: Number(id),
        type,
        add: !node,
        initial: initialNode,
        node,
        parent,
        child,
        editingDisabled,
      },
    });
  };

  const openDeleteModal = (node) => {
    openModalPortalA({
      content: <DeleteNodeModal />,
      contentProps: {
        close: closeModalPortalA,
        node,
      },
    });
  };

  const spiltScreenClassName = useMemo(() => {
    if (isDesktop && splitScreenOpen) {
      if (selectedMessageId) {
        return ' automation-editor-area--split-screen';
      }
      return ' automation-editor-area--split-screen only-list';
    }
    return '';
  }, [splitScreenOpen, selectedMessageId, isDesktop]);

  const handleSplitScreen = () => {
    setSplitScreenOpen(!splitScreenOpen);
    if (splitScreenOpen) {
      setSelectedMessageId(undefined);
    }
  };

  const toggleClassName = useMemo(() => {
    if (splitScreenOpen) {
      if (selectedMessageId) {
        return 'automation-editor-area--split-screen--toggle open';
      }
      return 'automation-editor-area--split-screen--toggle open only-list';
    }
    return 'automation-editor-area--split-screen--toggle';
  }, [splitScreenOpen, selectedMessageId]);

  return (
    <>
      <OverlaySpinner loaded={!loading} />
      <AutomationIssues automation={automation} />
      {isDesktop && (
        <>
          <span onClick={handleSplitScreen} className={toggleClassName} />
          <AutomationMessageEditor />
        </>
      )}
      <ScrollContainer
        className={`automation-editor-area${(editorDimensions && !scrollWidthBool) || loading ? ' automation-editor-area--no-drag' : ''}${spiltScreenClassName}`}
        id="automation-editor-area"
        ref={scrollContainer}
        hideScrollbars={false}
        ignoreElements=".automation-node__container__actions,.edge__insert__icon,.initial-node__container,.automation-node,.automation-editor-area--split-screen--toggle"
        nativeMobileScroll
        horizontal={editorDimensions && scrollWidthBool && !loading}
        vertical={editorDimensions
          && editorDimensions.height > window.innerHeight - EDITOR_PADDING && !loading}
      >
        <>
          <div
            className="automation-editor-area__container"
            style={{
              height: `${editorDimensions ? editorDimensions.height : 0}px`,
              width: `${editorDimensions ? editorDimensions.width : 0}px`,
            }}
          >
            {!loading && automation && automation.nodes && automation.nodes.length > 0
            && map(automation.nodes, (node, index) => (
              <AutomationNode
                node={node}
                contacts={automation.objects}
                editNode={openAddEditNode}
                deleteNode={openDeleteModal}
                notifyWarning={notifyWarningA}
                key={node.id}
                isFirstNode={index === 0}
              />
            ))}
            {!loading && editorDimensions
            && (!automation || !automation.nodes || !automation.nodes.length) && (
              <InitialNode addNode={openAddEditNode} />
            )}
            <svg
              className="edge"
              style={{
                height: `${editorDimensions ? editorDimensions.height : 0}px`,
                width: `${editorDimensions ? editorDimensions.width : 0}px`,
              }}
            >
              {!loading && automation && automation.edges && automation.edges.length
              && map(automation.edges, (edge, index) => (
                <AutomationEdge
                  edge={edge}
                  handleAddNode={openAddEditNode}
                  editingDisabled={editingDisabled}
                  key={index}
                />
              ))}
            </svg>
          </div>
        </>
      </ScrollContainer>
    </>
  );
};

AutomationEditor.propTypes = {
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
  notifyWarningA: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
  notifyWarningA: bindActionCreators(notifyWarning, dispatch),
});

export default connect(null, mapDispatchToProps)(AutomationEditor);
