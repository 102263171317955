import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'semantic-ui-react';
import { submit, getFormSyncErrors } from 'redux-form';
import { isEmpty } from 'lodash';
import { GetQuoteForm } from '../Form';

const GetQuoteModal = ({
  closeModalPortal,
  open,
  onFormSubmit,
  submitForm,
  formErrors,
}) => (
  <Modal
    open={open}
    onClose={() => closeModalPortal()}
    className="quote-modal"
  >
    <Modal.Header>
      <div>Need another plan?</div>
      <div>Contact us!</div>
    </Modal.Header>
    <Modal.Content>
      <GetQuoteForm
        onFormSubmit={onFormSubmit}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button
        onClick={() => submitForm('GetQuoteForm')}
        disabled={!isEmpty(formErrors)}
        fluid
        className="blue-light"
      >
        Submit
      </Button>
    </Modal.Actions>
  </Modal>
);

GetQuoteModal.propTypes = {
  closeModalPortal: PropTypes.func,
  open: PropTypes.bool,
  onFormSubmit: PropTypes.func,
  submitForm: PropTypes.func,
  formErrors: PropTypes.object,
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('GetQuoteForm')(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitForm: bindActionCreators(submit, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetQuoteModal);
