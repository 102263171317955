import { bindActionCreators } from 'redux';
import {
  Form,
  reduxForm,
  FieldArray,
  getFormValues,
  change,
} from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { PropertyArray } from '../FieldArray';
import { removeTagAndListProperties } from '../../utils/helper';

const ContactForm = ({
  changeFieldValue,
  handleSubmit,
  contactProperties,
  onFormSubmit,
}) => {
  const clearValue = (name) => {
    changeFieldValue('contact', name, null);
  };

  return (
    <Grid className="no-spacing">
      <Form className="ris-form ris-form--white" onSubmit={handleSubmit(onFormSubmit)}>
        {contactProperties && Object.keys(contactProperties).length > 0
        && (
        <FieldArray
          name="properties"
          component={PropertyArray}
          props={{ properties: removeTagAndListProperties(contactProperties) }}
          clearValue={clearValue}
        />
        )}
      </Form>
    </Grid>
  );
};

const formConfig = {
  form: 'contact',
};

ContactForm.propTypes = {
  contactProperties: PropTypes.object,
  handleSubmit: PropTypes.func,
  onFormSubmit: PropTypes.func,
  changeFieldValue: PropTypes.func,
};

const mapStateToProps = (state, props) => ({
  formValues: getFormValues(formConfig.form)(state),
  contactProperties: state.entities.contactProperties,
  initialValues: props.initialValues,
});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: bindActionCreators(change, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(formConfig)(ContactForm));
