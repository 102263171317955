// raw content state
// to initialize `react-draft-wysisyg` editor state.
export const INITIAL_EDITOR_CONTENT_RAW_STATE = {
  blocks: [
    {
        key: 'dkvpa',
        text: 'Type your text here',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
    },
  ],
  entityMap: {},
};
