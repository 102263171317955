import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './WidgetFileUI.scss';

const WidgetFileUI = ({ fileData }) => {
  const { files } = fileData;
  return (
    <div className="WidgetFileUI " id="message-view-attachments-block">
      <div className="attachment-block" id="file">
        <p className="WidgetFileUI__section-title" id="header">
          Attachments:
        </p>
        <div className="WidgetFileUI__attachments-container attachment-block__box">

          {!!files.length && files.map((file, index) => (
            <a
              className="WidgetFileUI__attachments-button attachment-block__item"
              href={file.href}
              key={file.id}
            >
              <p className="WidgetFileUI__attachment-action-title attachment-block__download">TAP TO DOWNLOAD</p>
              <p className="WidgetFileUI__attachment-title attachment" id={`file-${index}-name`}>{file.fileName}</p>
            </a>
        ))}
        </div>
      </div>
    </div>
  );
};

WidgetFileUI.propTypes = {
  fileData: PropTypes.shape({
    files: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default WidgetFileUI;
