import MediaLibraryContainer from '../../components/MediaLibrary/MediaLibraryContainer';

const MediaLibrary = () => (
  <MediaLibraryContainer
    modal={false}
    type="image"
  />
);

export default MediaLibrary;
