export const featuresKeys = {
  headerFeatures: {
    sms: 'Free sms to target market',
    email: 'Unlimited email',
    users: 'Users',
  },
  features: {
    personalizedMessaging: 'Personal messaging',
    landingPageBuilder: 'Landingpage builder (WYSIWYG)',
    realTimeAnalytics: 'Real-time analytics',
    contactManagement: 'Contact management',
    mediaLibrary: 'Media library',
    surveyEngagement: 'Survey & video engagement',
    responsiveLandingPage: 'Responsive landing pages',
    unsubscriptionManagement: 'Unsubscription management',
    smsSenderName: 'SMS sender name',
    messageExpiration: 'Message expiration',
    individualBehaviouralTracking: 'Individual behavioral tracking',
    resultBasedSegmentation: 'Result-based segmentation',
    integrations: 'Integrations',
    automation: 'Automation',
    customDomain: 'Custom domain sending',
    apiAccess: 'API access',
    internationalSms: 'International SMS',
  },
  supportFeatures: {
    email: 'Email',
    priority: 'Priority',
  },
  featureKeys: {
    personalizedMessaging: 'personalizedMessaging',
    landingPageBuilder: 'landingPageBuilder',
    realTimeAnalytics: 'realTimeAnalytics',
    contactManagement: 'contactManagement',
    mediaLibrary: 'mediaLibrary',
    surveyEngagement: 'surveyEngagement',
    responsiveLandingPage: 'responsiveLandingPage',
    unsubscriptionManagement: 'unsubscriptionManagement',
    smsSenderName: 'smsSenderName',
    messageExpiration: 'messageExpiration',
    individualBehaviouralTracking: 'individualBehaviouralTracking',
    resultBasedSegmentation: 'resultBasedSegmentation',
    integrations: 'integrations',
    automation: 'automation',
    customDomain: 'customDomain',
    apiAccess: 'apiAccess',
    internationalSms: 'internationalSms',
  },
};
