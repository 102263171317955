import { memo } from 'react';
import PropTypes from 'prop-types';
import { map, isEqual } from 'lodash';

import { featuresKeys } from '../../../../../common/plans';

const PlansSupport = ({ plans }) => (
  <div className="ris-features-support">
    <div className="ris-pricing-section-title">
      SUPPORT
    </div>
    {map(featuresKeys.supportFeatures, (feature, key) => (
      <div
        key={key}
        className="ris-row"
      >
        <div className="ris-feature">{feature}</div>
        <div className="ris-feature-inner">
          {map(plans, ({ product, support }) => (
            <div
              key={product}
              className={`ris-feature-inner-box ris-feature-inner-box--${product}`}
            >
              {support[key] ? <div className="ris-checkbox" /> : null}
            </div>
        ))}
        </div>
      </div>
    ))}
  </div>
);

PlansSupport.propTypes = {
  plans: PropTypes.array,
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps.plans, nextProps.plans);

export default memo(PlansSupport, areEqual);
