export const DEFAULT_MESSAGES_PAGE_SIZE = 20;
export const MOBILE_MAX_WIDTH = 768;
export const TABLET_MAX_WIDTH = 991;
export const PAGE_LIMIT = 200;
export const MESSAGE_VIEW_ID = 'message-view';
export const MESSAGE_VIEW_WRAPPER_ID = 'message-view-wrapper';
export const MESSAGE_VIEW_BLOCK = 'message-view-block';
export const MESSAGE_VIEW_ATTACHMENTS_BLOCK = 'message-view-attachments-block';
export const MESSAGE_VIEW_UNSUBSCRIBE_BLOCK = 'message-view-unsubscribe-block';
export const NOT_VALID_CONTACT_PROPERTIES = ['Tags'];
export const DEFAULT_BUTTON_LINK = 'https://';
export const COLOR_PICKER_COLORS = [
  '#61BD6D',
  '#41A85F',
  '#00A885',
  '#19BC9C',
  '#54ACD2',
  '#2C82C9',
  '#3D8EB9',
  '#2969B0',
  '#475577',
  '#28324E',
  '#9365B8',
  '#553982',
  '#CCCCCC',
  '#4A4A4A',
  '#FFFFFF',
  '#000000',
  '#D1D5D8',
  '#EFEFEF',
  '#A38F84',
  '#F7DA64',
  '#FAC51C',
  '#FBA026',
  '#EB6B56',
  '#E25041',
  '#F37934',
  '#D14841',
  '#B8312F',
];
export const CREDITS_SUCCESS_MESSAGE = 'Success! SMS Credits added';

export const contactsProperties = [
  { name: 'name', value: null },
  { name: 'surname', value: null },
  { name: 'phoneNumber', value: null },
  { name: 'email', value: null },
];

export const DELIVERY_STEP = 'delivery';
export const CONTACTS_STEP = 'contacts';
export const DESIGN_STEP = 'design';
export const SAVE_WIDGET_BUTTON_ID = 'save-widget-button';
