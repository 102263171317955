import { callApi } from './callApi';
import { call as callAccount } from './accountsApi';

export const call = (endpoint, params, _schema) => callApi({ serviceName: 'plan', endpoint }, params, _schema);

export const fetchPlans = (countryCode = 'dk') => call(`/price/${countryCode}`, {
  method: 'GET',
}, null);

export const fetchCountries = () => call('/countries', {
  method: 'GET',
}, null);

export const fetchPlansWeights = () => call('/product', {
  method: 'GET',
}, null);

export const fetchSession = () => call('/session', {
  method: 'GET',
}, null);

export const setPlanForAccount = (accountId, planID) => call(`/plan/${planID}/account/${accountId}/subscribe`, {
  method: 'POST',
}, null);

export const planUpdate = (body) => call('/plan', {
  method: 'PUT',
  body,
}, null);

// body: { "amount": 20, "userLimit": 500, "contactLimit": 500, "smsLimit": 300  }
export const createEnteprisePlanForID = (userId, body) => call(`/plan/${userId}/enterprise`, {
  method: 'POST',
  body: {
    amount: parseInt(body.amount, 10),
    userLimit: parseInt(body.userLimit, 10),
    contactLimit: parseInt(body.contactLimit, 10),
    smsLimit: parseInt(body.contactLimit, 10),
  },
}, null);

export const planSave = (body) => call('/plan', {
  method: 'POST',
  body,
}, null);

export const cancelPlan = () => call('/subscription/cancel', {
  method: 'PUT',
});

export const planDelete = (body) => call(`/plan/${body.payload.id}`, {
  method: 'DELETE',
  body,
}, null);

export const quoteSave = (body) => callAccount('/quote', {
  method: 'POST',
  body,
}, null);

export const subscribeToPlan = (body, countryCode = 'dk') => call(`/price/${countryCode}/subscribe`, {
  method: 'POST',
  body,
}, null);

export const fetchSubscriptionPrice = (body, countryCode = 'dk') => call(`/price/${countryCode}/proration`, {
  method: 'POST',
  body,
}, null);
