import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { map, find, get } from 'lodash';
import { connect } from 'react-redux';
import { Spinner } from '../../components/Spinner';
import { contactFromListRemove, listsFetchUnique } from '../../services/contactApi';
import { multiDimensionalUnique } from '../../utils/contactsMapping';
import { generateColorFromString, setContactsType, setListId } from '../../utils/helper';

const DeleteFromListForm = ({
  currentPageResults,
  contactFilter,
  notifyErrorA,
  selected,
  lists,
  closeModalPortal,
  requestModifiedContactViewPageA,
  fetchListsA,
  contacts,
  notifySuccessA,
  list,
  excluded,
  propertyId,
  orderBy,
}) => {
  const [loading, setLoading] = useState(true);
  const [_lists, setLists] = useState([]);

  useEffect(() => {
    if (typeof selected === 'string') {
      const filterId = get(contactFilter, 'id', undefined);
      const filter = filterId ? `&filter=${filterId}` : '';
      const listId = setListId(list);
      const type = setContactsType(list);

      listsFetchUnique(filter, listId, type)
        .then((res) => {
          if (res.error) {
            notifyErrorA(res.error.message);
          } else {
            setLoading(false);
            setLists(map(res.response.lists, (_list) => ({ id: _list.id, text: _list.name })));
          }
        }).catch((err) => notifyErrorA(err));
    } if (currentPageResults) {
      const selectedContacts = currentPageResults.filter(
        (con) => (selected.includes(con['contact.id'])));
      const listArray = map(selectedContacts, (contact) => map(contact['contact.listIds'], (id) => {
        const newList = find(lists, (li) => li.id === id);
        return { id, text: newList.name };
      }));

      // flatten and remove duplicates
      if (listArray.length > 0) {
        setLoading(false);
        setLists(multiDimensionalUnique(listArray.flat()));
      }
    }
  }, []);

  const handleDelete = (id) => {
    const listName = find(_lists, (li) => li.id === id).text;
    const filterId = get(contactFilter, 'id', undefined);

    if (contacts) {
      contacts.includes((row) => {
        selected.contains(row.id);
      });
    }

    const payload = {
      id,
      body: {
        ids: typeof selected === 'string' ? [] : selected,
        excluded,
        listId: typeof list === 'number' ? list : 0,
        filterId,
      },
    };

    closeModalPortal();
    contactFromListRemove(payload).then(() => {
      notifySuccessA(`Contacts removed from ${listName} list`);
      requestModifiedContactViewPageA({
        list,
        filterId,
        propertyId,
        orderBy,
      });
      fetchListsA();
    }).catch(() => notifyErrorA('Error while deleting from list'));

    setLists(_lists.splice(id, 1));
  };

  return (
    <div className="tag modal--tag__content">
      <Spinner loaded={!loading}>
        {_lists && map(_lists, (_list) => {
          const color = generateColorFromString(String(_list.text));
          return (
            <div
              className="ReactTags__tag__delete"
              key={_list.id}
              style={{
                backgroundColor: color,
                borderColor: color,
              }}
            >
              <span className="ReactTags__tag__delete__text">
                {_list.text}
              </span>
              <span className="ReactTags__tag__delete__icon" onClick={() => handleDelete(_list.id)} />
            </div>
          );
        })}
      </Spinner>
    </div>
  );
};


DeleteFromListForm.propTypes = {
  requestModifiedContactViewPageA: PropTypes.func,
  contacts: PropTypes.array,
  closeModalPortal: PropTypes.func,
  currentPageResults: PropTypes.array,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  fetchListsA: PropTypes.func,
  lists: PropTypes.array,
  list: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  contactFilter: PropTypes.object,
  excluded: PropTypes.array,
  propertyId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  orderBy: PropTypes.string,
};

const mapStateToProps = (state) => ({
  contactFilter: state.contactFilter,
  lists: state.lists.elements,
});

export default connect(mapStateToProps)(DeleteFromListForm);
