import { Grid } from 'semantic-ui-react';
import { withRouter } from 'react-router';

import { AccountNavigationDesktop } from '../../components/Header';

const HeaderExpired = () => (
  <Grid columns={4} className="app-header app-header__expired">
    <Grid.Column width={2} tablet={2} mobile={5} computer={2} className="site-logo navigation-header-logo" />
    <Grid.Column
      width={3}
      only="computer"
      className="account-navigation-container"
    >
      <AccountNavigationDesktop />
    </Grid.Column>
  </Grid>
);

export default withRouter(HeaderExpired);
