import { memo } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { omit } from 'lodash';
import { DATE_TIME } from '../../common';
import 'react-datepicker/dist/react-datepicker.css';

const RenderDatePicker = ({
  input,
  meta: { touched, error },
  showTimeSelect,
  dateFormat = DATE_TIME,
  className,
  position,
  readOnly,
  disabled,
}) => {
  let { value } = input;
  if (!value) {
    value = moment();
  } else if (typeof value === 'string') {
    value = moment(value, DATE_TIME);
  } else if (moment.isMoment(value) && !value.isValid()) {
    value = moment();
  } else {
    value = moment(value, DATE_TIME);
  }
  value = value.toDate();

  return (
    <div>
      <DatePicker
        {...omit(input, 'value')}
        selected={value}
        showTimeSelect={showTimeSelect}
        dateFormat={dateFormat}
        className={className}
        minDate={moment()}
        popperPlacement={position}
        readOnly={readOnly}
        disabled={disabled}
      />
      { touched && error && <small className="error-message">{error}</small> }
    </div>
  );
};

RenderDatePicker.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  }),
  showTimeSelect: PropTypes.bool,
  dateFormat: PropTypes.string,
  className: PropTypes.string,
  position: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default memo(RenderDatePicker);
