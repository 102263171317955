import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFormSyncErrors, isSubmitting, submit } from 'redux-form';
import { Popup } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { BasicButton } from '../../components/Button';
import { ListAddForm } from '../Form';

const ListCreatePopup = ({
  submitFormA,
  open,
  trigger,
  handlePopupState,
  formErrors,
  submitting,
}) => {
  const onFilterClick = () => {
    submitFormA('addList');
  };

  return (
    <Popup
      trigger={trigger}
      on="click"
      open={open}
      onClose={handlePopupState}
      onOpen={handlePopupState}
      position="bottom right"
      basic={true}
      size="tiny"
      className="popup-lists-add"
    >
      <Popup.Content>
        <ListAddForm close={handlePopupState} />
      </Popup.Content>
      <div className="add-list-form__actions">
        <BasicButton
          content="Add"
          size="small"
          color="blue"
          disabled={!isEmpty(formErrors) || submitting}
          onClick={onFilterClick}
        />
      </div>
    </Popup>
  );
};

ListCreatePopup.propTypes = {
  open: PropTypes.bool,
  handlePopupState: PropTypes.func,
  formErrors: PropTypes.object,
  submitFormA: PropTypes.func,
  trigger: PropTypes.object,
  submitting: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('addList')(state),
  submitting: isSubmitting('addList')(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitFormA: bindActionCreators(submit, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListCreatePopup);
