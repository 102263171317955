import PropTypes from 'prop-types';

const OpenQuestionAnalytics = ({
  value,
}) => (
  <div className="survey-stats__open">
    {value && value.map((val, j) => (
      <div key={j} className="survey-stats__open--answer">
        {val}
      </div>
    ))}
  </div>
);

OpenQuestionAnalytics.propTypes = {
  value: PropTypes.array,
};

export default OpenQuestionAnalytics;
