import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { getFormValues, change, unregisterField } from 'redux-form';
import { getName } from 'country-list';
import { findIndex, forEach } from 'lodash';
import { Property } from '../../components/Field';
import { mapContactPropertiesToOptions, mapRequiredContactPropertiesToOptions } from '../../utils/map';
import { setPropertyValueComponent, setPropertyValueProps } from '../../utils/helper';
import { Icon } from '../../components/Icon';
import { isArrayCountValid } from '../../utils/validator';

const PropertyArray = ({
  fields,
  unregisterFieldA,
  formValues,
  changeFormValue,
  properties,
  clearValue,
}) => {
  const phoneEmailRequired = (newProperties) => {
    let required = false;

    newProperties.forEach((p) => {
      if (p.name === 'phoneNumber' || p.name === 'email') {
        required = !required;
      }
    });

    return required;
  };

  const removeField = (index) => {
    unregisterFieldA('contact', `properties[${index}].name`);
    unregisterFieldA('contact', `properties[${index}].value`);
    fields.remove(index);
  };

  const pushPreselectedCountry = (countryCode) => {
    const countryName = getName(countryCode);
    const countryPropertyId = findIndex(formValues.properties, { name: 'country' });
    if (properties && !properties.country) {
      return;
    }
    if (countryPropertyId >= 0) {
      changeFormValue('contact', `properties[${countryPropertyId}].value`, countryName);
    } else {
      fields.push({ name: 'country', value: countryName });
    }
  };

  if (formValues) {
    let numberOfFields = 0;
    const { properties: props } = formValues;
    let count = 0;
    let i = 0;
    forEach(props, (field, index) => {
      if (field.name === 'country') {
        count += 1;
        i = index;
      }
    });
    if (count > 1) {
      removeField(i);
    }

    let required = true;

    if (formValues && formValues.properties) {
      required = phoneEmailRequired(formValues.properties);
    }

    return (
      <div className="property-array">
        {fields.map((field, index) => {
          numberOfFields = index;
          let options;
          if (fields.get(index).name === 'name') {
            const text = `${properties.name.label}*`;
            options = [{ text, value: properties.name.name }];
          } else if ((fields.get(index).name === 'phoneNumber' && required)
            || (fields.get(index).name === 'email' && required)) {
            options = mapRequiredContactPropertiesToOptions(properties);
          } else {
            options = mapContactPropertiesToOptions(properties, formValues, index);
          }
          return (
            <Property
              key={index}
              field={field}
              options={options}
              valueComponent={setPropertyValueComponent(properties, fields.get(index))}
              valueComponentProps={setPropertyValueProps(
                properties,
                fields.get(index),
                pushPreselectedCountry,
              )}
              onRemove={() => removeField(index)}
              removable={fields.get(index).name !== 'name'
              && (fields.get(index).name !== 'phoneNumber'
                || (fields.get(index).name === 'phoneNumber' && !required))
              && (fields.get(index).name !== 'email'
                || (fields.get(index).name === 'email' && !required))}
              clearValue={clearValue}
              text={properties[fields.get(index).name]
              && properties[fields.get(index).name].label}
            />
          );
        })}
        {!isArrayCountValid(formValues && formValues.properties, 3, 99)
        && (
          <div className="properties-error">
            Must at least be between 3 and 99 items
          </div>
        )}
        {numberOfFields < Object.keys(properties).length - 1 && (
          <Grid>
            <Grid.Row className="add-more--desktop" only="computer tablet" columns={1} centered>
              <Grid.Column width={4}>
                <button className="link" onClick={(e) => { e.preventDefault(); fields.push({}); }} type="button">
                  <Icon name="add" />
                  <span>Add more</span>
                </button>
              </Grid.Column>
              <Grid.Column width={8} />
            </Grid.Row>
            <Grid.Row only="mobile">
              <div className="add-more--mobile">
                <button className="link " onClick={(e) => { e.preventDefault(); fields.push({}); }} type="button">
                  <Icon name="add" />
                  <span>Add more</span>
                </button>
              </div>
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
  return null;
};

PropertyArray.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func,
    get: PropTypes.func,
    remove: PropTypes.func,
    map: PropTypes.func,
  }),
  properties: PropTypes.object,
  formValues: PropTypes.shape({
    properties: PropTypes.array,
  }),
  changeFormValue: PropTypes.func,
  clearValue: PropTypes.func,
  unregisterFieldA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues('contact')(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeFormValue: bindActionCreators(change, dispatch),
  unregisterFieldA: bindActionCreators(unregisterField, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyArray);
