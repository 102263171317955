import PropTypes from 'prop-types';

function Spinner({
  loaded,
  children,
  options,
  modal,
  option,
}) {
  // Merge default options with incoming
  const {
    loadedClassName,
    notLoadedClassName,
    size,
    type,
  } = { ...Spinner.defaultProps.options, ...options };

  const renderChild = () => {
    if (loaded) {
      return children;
    }

    const spinnerSize = size && type ? `spinner--${type}-${size}` : '';
    return <div className={`spinner spinner--position spinner--heart ${spinnerSize}`} />;
  };

  return (
    <div className={`${loaded ? loadedClassName : `${notLoadedClassName}${modal ? ' upload-modal' : ''}${option ? ' option' : ''}`}`}>
      {renderChild()}
    </div>
  );
}

Spinner.propTypes = {
  loaded: PropTypes.bool,
  options: PropTypes.object,
  children: PropTypes.node,
  modal: PropTypes.bool,
  option: PropTypes.bool,
};

Spinner.defaultProps = {
  loaded: true,
  options: {
    type: 'heart',
    size: 'medium',
    loadedClassName: 'loaded-content',
    notLoadedClassName: 'spinner__content',
  },
};

export default Spinner;
