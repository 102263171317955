import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useFeature } from '../../hooks';
import { featuresKeys } from '../../common/plans';
import { getPlanFeatures } from '../../utils/plans';

const HeaderNavigation = ({ features }) => {
  const [pathname, setPathname] = useState(window.location.pathname);
  const showAutomation = useFeature(featuresKeys.featureKeys.automation, features);
  const { listen } = useHistory();

  const changePathnameListener = (location) => {
    setPathname(location.pathname);
  };

  useEffect(() => {
    const unsubscribe = listen(changePathnameListener);
    return () => unsubscribe();
  }, []);

  return (
    <Menu
      className="header-navigation"
      widths={showAutomation ? 4 : 3}
      borderless
      size="massive"
    >
      <Menu.Item
        name="messages"
        active={pathname === '/messages' || pathname === '/'}
        href="/messages"
      >
        Messages
      </Menu.Item>
      {showAutomation && (
        <Menu.Item
          name="automation"
          active={pathname === '/automation'}
          href="/automation"
        >
          Automation
        </Menu.Item>
      )}
      <Menu.Item
        name="contacts"
        active={pathname === '/contacts'}
        href="/contacts"
      >
        Contacts
      </Menu.Item>
      <Menu.Item
        name="mediaLibrary"
        active={pathname === '/media-library'}
        href="/media-library"
      >
        Media Library
      </Menu.Item>
    </Menu>
  );
};

HeaderNavigation.propTypes = {
  features: PropTypes.object,
};

const mapStateToProps = (state) => ({
  features: getPlanFeatures(state.plans.session),
});

export default connect(mapStateToProps)(HeaderNavigation);
