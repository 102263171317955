export const AUTOMATION_STATUS_TEXT = {
  waiting: 'Draft',
  in_progress: 'Active',
  paused: 'Paused',
  stopped: 'Stopped',
  failed: 'Failed',
  completed: 'Done',
};

export const AUTOMATION_STATUS = {
  PAUSE: 'pause',
  START: 'start',
};

// node size and gap constants
export const NODE_WIDTH = 600;
export const NODE_HEIGHT = 94;
export const HORIZONTAL_GAP = 100;
export const VERTICAL_GAP = 100;
export const ADD_ICON_HEIGHT = 44;
export const ADD_ICON_WIDTH = 28;
export const TEXT_HEIGHT = 33;
export const TEXT__WIDTH = 300;
export const CURVE_HEIGHT = (VERTICAL_GAP - ADD_ICON_HEIGHT) / 2;
export const EDITOR_PADDING = 128;
export const MESSAGE_LIST_WIDTH = 328;
export const MESSAGE_EDITOR_WIDTH = 1000;

export const CURVED_PATH_ENUM = {
  TOP_LEFT: 'TOP_LEFT',
  TOP_RIGHT: 'TOP_RIGHT',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
};

export const CURVED_PATH_DRAW = {
  TOP_LEFT: `M 1 1 C ${CURVE_HEIGHT + 1} 1 ${CURVE_HEIGHT + 1} 1 ${CURVE_HEIGHT + 1} ${CURVE_HEIGHT + 1}`,
  TOP_RIGHT: `M ${CURVE_HEIGHT + 1} 1 C 1 1 1 1 1 ${CURVE_HEIGHT + 1}`,
  BOTTOM_LEFT: `M ${CURVE_HEIGHT + 1} 1 C ${CURVE_HEIGHT + 1} ${CURVE_HEIGHT + 1} ${CURVE_HEIGHT + 1} ${CURVE_HEIGHT + 1} 1 ${CURVE_HEIGHT + 1}`,
  BOTTOM_RIGHT: `M 1 1 C 1 ${CURVE_HEIGHT + 1} 1 ${CURVE_HEIGHT + 1} ${CURVE_HEIGHT + 1} ${CURVE_HEIGHT + 1}`,
};

export const SUBJECT_TYPES = {
  OPEN: 'open_message',
  CONTAINS_TAG: 'contains_tag',
  IN_LIST: 'is_subscribed',
  SURVEY: 'survey_submitted',
  QUESTION: 'question_answered',
  SMS: 'send_sms',
  EMAIL: 'send_message',
  ADD_TO_LIST: 'subscribe',
  REMOVE_FROM_LIST: 'unsubscribe',
  ADD_TAG: 'add_tag',
  REMOVE_TAG: 'remove_tag',
  DELAY: 'delay',
  TIME: 'time',
};

export const NODE_TYPES = {
  initial: [
    { text: 'Message opened', value: SUBJECT_TYPES.OPEN },
    { text: 'Contains tag', value: SUBJECT_TYPES.CONTAINS_TAG },
    { text: 'Added to list', value: SUBJECT_TYPES.IN_LIST },
    { text: 'Survey submitted', value: SUBJECT_TYPES.SURVEY },
  ],
  filter: [
    { text: 'Message opened', value: SUBJECT_TYPES.OPEN },
    { text: 'Contains tag', value: SUBJECT_TYPES.CONTAINS_TAG },
    { text: 'Added to list', value: SUBJECT_TYPES.IN_LIST },
    { text: 'Survey submitted', value: SUBJECT_TYPES.SURVEY },
    { text: 'Question answered', value: SUBJECT_TYPES.QUESTION },
  ],
  action: [
    { text: 'Send SMS', value: SUBJECT_TYPES.SMS },
    { text: 'Send email', value: SUBJECT_TYPES.EMAIL },
    { text: 'Add to list', value: SUBJECT_TYPES.ADD_TO_LIST },
    { text: 'Remove from list', value: SUBJECT_TYPES.REMOVE_FROM_LIST },
    { text: 'Add tag', value: SUBJECT_TYPES.ADD_TAG },
    { text: 'Remove tag', value: SUBJECT_TYPES.REMOVE_TAG },
  ],
  other: [
    { text: 'Delay', value: SUBJECT_TYPES.DELAY },
    { text: 'Time', value: SUBJECT_TYPES.TIME },
  ],
};

export const MINUTES_IN_HOUR = 60;
export const MINUTES_IN_DAY = 1440;
export const MINUTES_IN_WEEK = 10080;

export const DELAY_INTERVALS = [
  { text: 'Minutes', value: 1 },
  { text: 'Hours', value: MINUTES_IN_HOUR },
  { text: 'Days', value: MINUTES_IN_DAY },
  { text: 'Weeks', value: MINUTES_IN_WEEK },
];

export const EIDITING_DISABLED_STATUS = [
  'in_progress',
];
