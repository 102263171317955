import { useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COLOR_PICKER_COLORS } from '../../common';

const ColorPicker = ({
  color,
  onChange,
  hasInput = true,
  isAbsolute = true,
  onClose,
}) => {
  const [colorState, setColorState] = useState(color);
  const presetColors = COLOR_PICKER_COLORS;

  const handleChange = (stuff) => {
    setColorState(stuff);
  };

  const handleSubmit = () => {
    onChange(colorState);
  };

  return (
    <div
      className={classNames('ColorPicker', {
      'ColorPicker--absolute': isAbsolute,
    })}
    >
      <HexColorPicker color={colorState || color} onChange={handleChange} />
      {hasInput && (
        <div className="ColorPicker__input-container">
          <HexColorInput
            className="ColorPicker__input"
            placeholder="HEX code here"
            color={colorState || color}
            onChange={handleChange}
          />
        </div>
      )}
      <div className="ColorPicker__actions">
        {onClose && (
          <button
            type="button"
            className="ColorPicker__submit ui button white"
            onClick={onClose}
          >
            Cancel
          </button>
        )}
        <button
          type="button"
          className="ColorPicker__submit ui button primary"
          onClick={handleSubmit}
        >
          Confirm
        </button>
      </div>
      <div className="ColorPicker__presets">
        {presetColors.map((presetColor) => (
          <div
            key={presetColor}
            className="ColorPicker__preset-color"
            style={{ backgroundColor: presetColor }}
            onClick={() => handleChange(presetColor)}
          />
        ))}
      </div>
    </div>
);
};

ColorPicker.propTypes = {
  color: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  hasInput: PropTypes.bool,
  isAbsolute: PropTypes.bool,
};

export default ColorPicker;
