import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, reduxForm, Field } from 'redux-form';
import { bindActionCreators } from 'redux';
import { contactActions, notificationActions } from '../../actions';
import { clearPaginatorPromiseArray } from '../../modules/redux-paginator';
import { Input } from '../../components/Field';
import { required, maxLength50 } from '../../utils/validator';
import { listCreate } from '../../services/contactApi';

const { fetchLists } = contactActions;
const { notifySuccess, notifyError } = notificationActions;

const ListAddForm = ({
  notifySuccessA,
  notifyErrorA,
  fetchListsA,
  close,
  handleSubmit,
}) => {
  useEffect(() => () => {
    clearPaginatorPromiseArray();
  }, []);

  const onSubmit = (values) => {
    listCreate(values).then((res) => {
      if (res.error) {
        notifyErrorA(`List ${values.name} already exists`);
      } else {
        notifySuccessA(`List ${values.name} created`);
        fetchListsA();
        close();
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="add-list-form ris-form">
      <span className="add-list-form__title">Add List</span>
      <Field
        component={Input}
        placeholder="Enter list name"
        name="name"
        size="large"
        validate={[required, maxLength50]}
        className="add-list-form__input ui input"
      />
    </Form>
  );
};

const formConfig = {
  form: 'addList',
};

ListAddForm.propTypes = {
  handleSubmit: PropTypes.func,
  notifySuccessA: PropTypes.func,
  notifyErrorA: PropTypes.func,
  fetchListsA: PropTypes.func,
  close: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  fetchListsA: bindActionCreators(fetchLists.request, dispatch),
});

export default connect(null, mapDispatchToProps)(reduxForm(formConfig)(ListAddForm));
