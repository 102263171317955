import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Grid, Button } from 'semantic-ui-react';

const NotFound = () => {
  const { goBack } = useHistory();
  const handleRedirection = () => {
    goBack();
  };

  return (
    <div>
      <div className="notfound__header">
        <Grid>
          <Grid.Row centered className="notfound__header--big">
            There&#39;s no response
          </Grid.Row>
          <Grid.Row centered className="notfound__header--small">
            You have stumbled on a page that does not exist
          </Grid.Row>
        </Grid>
      </div>
      <div className="notfound__content">
        <Grid>
          <Grid.Row centered>
            <i className="arrow-down-white" />
          </Grid.Row>
          <Grid className="notfound__flexable">
            <Grid.Column computer={5} tablet={10} mobile={12}>
              <p className="notfound__content--text">Know what you were looking for?</p>
              <Button fluid onClick={handleRedirection} className="blue">
                Contact our support center
              </Button>
            </Grid.Column>
            <Grid.Column computer={5} tablet={10} mobile={12}>
              <br />
              <Button fluid onClick={handleRedirection} className="blue">
                Go back to the main page
              </Button>
            </Grid.Column>
          </Grid>
        </Grid>
      </div>
      <div className="notfound__footer" />
    </div>
  );
};

NotFound.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }),
};

export default withRouter(NotFound);
