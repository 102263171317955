import { memo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { DatePicker } from '../../Field';
import { DATE_TIME } from '../../../common';
import { useTimeZoneOptions } from '../../../hooks';

const TimeSelect = ({
  time,
  timeZone,
  setTime,
  setTimeZone,
  disabled,
}) => {
  const timeZones = useTimeZoneOptions();

  return (
    <span className="subject-input-select">
      <span className="subject-input-select__time-label">
        resume on
      </span>
      <div className="ui fluid search scrolling dropdown subject-input-select__dropdown subject-input-select__dropdown--time">
        <DatePicker
          dateFormat={DATE_TIME}
          className="time-input"
          position="bottom"
          showTimeSelect
          input={{
            value: time,
            onChange: (date) => setTime(date),
          }}
          meta={{}}
          disabled={disabled}
        />
        <i aria-hidden="true" className="dropdown icon" />
      </div>
      <Dropdown
        className="subject-input-select__dropdown"
        options={timeZones}
        placeholder="Select delay interval"
        value={timeZone}
        onChange={(e, { value }) => setTimeZone(value)}
        search
        fluid
        scrolling
        disabled={disabled}
      />
    </span>
  );
};

TimeSelect.propTypes = {
  time: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  timeZone: PropTypes.string,
  setTime: PropTypes.func,
  setTimeZone: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(TimeSelect);
