import { forEach } from 'lodash';
import { formatDataSetupForYoutube } from './format';
import { MESSAGE_VIEW_ID } from '../common';

export const appendScript = (doc, src) => {
  const script = doc.createElement('script');
  script.src = src;
  script.type = 'text/javascript';
  doc.head.appendChild(script);

  return doc;
};

export const appendCss = (doc, src) => {
  const link = doc.createElement('link');
  link.rel = 'stylesheet';
  link.href = src;
  doc.getElementsByTagName('head')[0].appendChild(link);

  return doc;
};

export const appendIco = (doc, src) => {
  const link = doc.createElement('link');
  link.rel = 'shortcut icon';
  link.href = src;
  doc.getElementsByTagName('head')[0].appendChild(link);

  return doc;
};

export const createInitAndVideoScript = (nodes) => {
  const script = document.createElement('script');
  let type = 'gallery';

  const players = nodes.map((n) => {
    const { id, src } = n;
    let setup = { controls: true, autoplay: false, preload: 'auto' };
    if (n.getAttribute('youtube') === 'true') { // because true/false comes as string
      setup = {
        ...setup,
        ...formatDataSetupForYoutube(src),
      };
      type = 'youtube';
    } else {
      type = 'gallery';
    }

    return {
      setup,
      id,
      type,
    };
  });


  const inlineScript = document.createTextNode(
    'function init() {\n'
    + ' generateTransition();\n'
    + ' getSubmittedForms();\n'
    + ' getSubmittedOptInForms();\n'
    + ' attachLinksToButtons();\n'
    + ' initRating();\n'
    + ' initFormSubmission();\n'
    + ' initOptInFormSubmission();\n'
    + ' appendTrackingPixel();\n'
    + ` const playersConfigArray = ${JSON.stringify(players)};`
    + ' const players = initPlayers(playersConfigArray);\n'
    + '};\n'
    + '\n'
    + 'window.addEventListener("DOMContentLoaded", init);\n',
  );

  script.appendChild(inlineScript);

  return script;
};

export const prepareMsgBlocks = (wrapper) => {
  const msg = wrapper.querySelector(`#${MESSAGE_VIEW_ID}`);
  const { childNodes } = msg;

  const congratsMessage = document.createElement('div');
  congratsMessage.setAttribute('class', 'success-message-box survey-success-box');
  const congratsGif = document.createElement('span');
  congratsGif.setAttribute('class', 'survey-success-gif');
  congratsMessage.appendChild(congratsGif);
  congratsMessage.style.display = 'none';

  childNodes.forEach((node) => {
    node.setAttribute('draggable', false);
    node.firstChild.setAttribute('draggable', false);
    const form = node.querySelector('form');

    if (form) {
      const formWrapper = form.parentNode;
      const congratsMessageClone = congratsMessage.cloneNode(true);
      const successText = form.getAttribute('success-text');
      if (successText) {
        const congratsMessageSpan = document.createElement('span');
        congratsMessageSpan.setAttribute('class', 'success-message-text');
        const congratsText = document.createTextNode(successText);

        congratsMessageSpan.appendChild(congratsText);
        congratsMessageClone.appendChild(congratsMessageSpan);
      }

      congratsMessageClone.setAttribute('id', form.id);
      formWrapper.appendChild(congratsMessageClone);
    }
  });

  return wrapper;
};

export const removeSubmits = (_msg) => {
  const msg = _msg.querySelector(`#${MESSAGE_VIEW_ID}`);
  const { childNodes } = msg;

  childNodes.forEach((node) => {
    const form = node.querySelector('form');

    if (form && form.lastChild.type === 'submit') {
      const formWrapper = form.parentNode;
      const successDiv = formWrapper.querySelector('.success-message-box');

      if (successDiv) {
        formWrapper.removeChild(successDiv);
      }
    }
  });

  return _msg;
};

export const addWeights = (message) => {
  const forms = message.getElementsByTagName('FORM');
  if (forms && forms.length) {
    forEach(forms, (form) => {
      forEach(form.children, (question, index) => {
        if (!question.firstChild) {
          return;
        }
        if (index !== form.children.length - 1 && !question.firstChild.hasAttribute('weight')) {
          question.firstChild.setAttribute('weight', index);
        }
      });
    });
  }
  return message;
};
