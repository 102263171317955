import PropTypes from 'prop-types';
import { map, find } from 'lodash';
import { Button } from 'semantic-ui-react';
import MediaItem from './MediaItem';
import EmptyMediaItem from './EmptyMediaItem';

const MediaList = ({
  media,
  modal,
  handleSelect,
  selected,
  filterValue,
  handlePreview,
  openUploadModal,
  showEmpty,
  handleAdd,
  close,
}) => {
  const isSelected = (index) => {
    const choice = find(selected, (i) => i === index);
    return typeof choice !== 'undefined';
  };

  const checkMediaType = (type) => (filterValue === 'all') || (filterValue === type);

  return (
    <div className="media-library__main__container__list">
      {media.length > 0 ? map(media, (item, index) => {
        const { type } = item;

        if (checkMediaType(type)) {
          return (
            <MediaItem
              key={index}
              item={item}
              modal={modal}
              index={index}
              handleSelect={handleSelect}
              selected={isSelected(index)}
              handlePreview={handlePreview}
              handleAdd={handleAdd}
              close={close}
            />
          );
        }
        return null;
      }) : (
        <div className="empty-media-list">
          <div>
            <span
              as="h1"
              className="empty-media-list__text"
            >
              No Media has been uploaded
            </span>
          </div>
          <br />
          <Button
            onClick={() => openUploadModal()}
            className="blue"
          >
            <p className="empty-messages-button-text">Upload your first Media</p>
          </Button>
        </div>
      )}
      {showEmpty && media.length > 0 && <EmptyMediaItem openUploadModal={openUploadModal} />}
    </div>
  );
};

MediaList.propTypes = {
  media: PropTypes.array,
  modal: PropTypes.bool,
  handleSelect: PropTypes.func,
  selected: PropTypes.arrayOf(
    PropTypes.number,
  ),
  filterValue: PropTypes.string,
  handlePreview: PropTypes.func,
  openUploadModal: PropTypes.func,
  showEmpty: PropTypes.bool,
  handleAdd: PropTypes.func,
  close: PropTypes.func,
};

export default MediaList;
