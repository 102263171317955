import { schema } from 'normalizr';
import { omit } from 'lodash';
import { callApi } from './callApi';
import { getUrlProperty } from '../utils/helper';
import { ENV } from '../config';

const { API_ENV } = ENV;

export const call = (endpoint, params, _schema) => callApi({ serviceName: 'contact', endpoint }, params, _schema);

const contactPropertiesSchema = new schema.Entity('contactProperties', undefined, { idAttribute: 'name' });
const contactPropertiesSchemaArray = new schema.Array(contactPropertiesSchema);

// API Services
export const contactProperties = () => call('/contacts/properties', {
  method: 'GET',
}, contactPropertiesSchemaArray);

export const contactView = (url) => {
  let pathTemplate = getUrlProperty('pathname', url);
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);
  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};

export const contactListMutation = (body) => call('/mutation', {
  method: 'POST',
  body: omit(body, ['propertyId', 'orderBy']),
}, null);

export const contactDeletion = (body) => call(`/contact/${body.id}`, {
  method: 'DELETE',
}, null);

export const contactCreation = ({ body }) => call('/contact', {
  method: 'POST',
  body: omit(body, 'filterId', 'propertyId', 'orderBy'),
}, null);

export const contactEdit = (body) => call(`/contact/${body.id}`, {
  method: 'PUT',
  body: omit(body, ['list', 'filterId', 'propertyId', 'orderBy']),
}, null);

export const contactListPreprocessing = (body) => call('/import/preprocess', {
  method: 'POST',
  body,
}, null);

export const contactListImport = (body) => call('/import', {
  method: 'POST',
  body: {
    ...omit(body, ['list', 'lists', 'filterId', 'propertyId', 'orderBy']),
    list: body.lists,
  },
}, null);

export const contactFilterCreation = (body) => call('/contact/filter', {
  method: 'POST',
  body: omit(body, 'list'),
}, null);


export const contactFilterUpdate = (body) => call(`/contact/filter/${body.id}`, {
  method: 'PUT',
  body: omit(body, ['orderBy', 'id', 'list', 'extraParam']),
}, null);

export const contactsSelectAllInfo = (body) => call('/contacts/export', {
  method: 'POST',
  body,
}, null);

export const contactsSearch = (body) => call('/contacts/search', {
  method: 'POST',
  body,
}, null);

export const listCreate = (body) => call('/contact/list', {
  method: 'POST',
  body,
}, null);

export const contactToListAdd = (payload) => call(`/contact/list/${payload.id}/add`, {
  method: 'POST',
  body: payload.body,
}, null);

export const contactFromListRemove = (payload) => call(`/contact/list/${payload.id}/remove`, {
  method: 'POST',
  body: payload.body,
}, null);

export const listInfoGet = (payload) => call(`/contact/list/${payload.id}`, {
  method: 'GET',
}, null);

export const listsFetch = () => call('/contact/list/view', {
  method: 'GET',
}, null);

export const listsFetchUnique = (filter, listId, type) => {
  const url = `contact/${API_ENV}/contact/lists?type=${type}${filter}&list=${listId}`;
  let pathTemplate = getUrlProperty('pathname', url);
  const queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);

  return call('', {
    pathTemplate,
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};

export const listEdit = (payload) => call(`/contact/list/${payload.id}`, {
  method: 'PUT',
  body: payload.body,
}, null);

export const listDelete = (payload) => call(`/contact/list/${payload.id}`, {
  method: 'DELETE',
}, null);

export const contactsMetadataGet = () => call('/contacts/metadata', {
  method: 'GET',
}, null);

export const contactsFetch = (additionalParams) => {
  const queryParams = additionalParams;
  return call('/contacts', {
    method: 'GET',
    additionalParams: { queryParams },
  }, null);
};

export const contactPropertyCreate = (body) => call('/contacts/property', {
  method: 'POST',
  body,
}, null);

export const contactPropertyDelete = (id) => call(`/contacts/property/${id}`, {
  method: 'DELETE',
}, null);

export const singlePropertyGet = (id) => call(`/contacts/property/${id}`, {
  method: 'GET',
}, null);

export const propertyLabelUpdate = (payload) => call(`/contacts/property/${payload.id}`, {
  method: 'PUT',
  body: payload.body,
}, null);

export const conatctViewsFetch = () => call('/contact/views', {
  method: 'GET',
}, null);

export const contactViewCreate = (body) => call('/contact/view', {
  method: 'POST',
  body,
}, null);

export const contactViewUpdate = (payload) => call(`/contact/view/${payload.id}/weight`, {
  method: 'POST',
  body: payload.body,
}, null);

export const contactViewPropertyAdd = ({ id, body }) => call(`/contact/view/${id}/add`, {
  method: 'POST',
  body,
}, null);

export const contactViewPropertyRemove = ({ id, body }) => call(`/contact/view/${id}/remove`, {
  method: 'POST',
  body,
}, null);

export const contactViewDelete = (id) => call(`/contact/view/${id}`, {
  method: 'DELETE',
}, null);
