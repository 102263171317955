import { omit, get } from 'lodash';
import { callApi } from './callApi';
import { getUrlProperty } from '../utils/helper';

export const call = (endpoint, params, _schema) => callApi({ serviceName: 'message', endpoint }, params, _schema);

export const messageDuplicate = (data) => call(`/message/${data.id}/copy`, {
  method: 'POST',
  body: data.body,
}, null);

export const messageUpdate = (data) => call(`/message/${data.id}`, {
  method: 'PUT',
  body: omit(data.body, 'html', 'messageEditor', 'autosave'),
}, null);

export const messageDeletion = (data) => {
  let idValue = data;
  if (typeof data === 'object') {
    idValue = data.id;
  }
  return (
    call(`/message/${idValue}`, {
      method: 'DELETE',
    }, null)
  );
};

export const messagesGet = (url) => {
  let pathTemplate = getUrlProperty('pathname', url);
  let queryParams = getUrlProperty('query', url);
  pathTemplate = pathTemplate.slice(1);
  const conjunction = get(queryParams, 'conjunction', null);
  const filters = get(queryParams, 'filters', JSON.stringify([]));
  const ids = get(queryParams, 'ids', JSON.stringify([]));
  queryParams = omit(queryParams, ['conjunction', 'filters', 'ids']);
  const parsedIds = JSON.parse(ids);
  if (parsedIds.length > 0) {
    queryParams.count = `${parsedIds.length}`;
  }
  const body = {
    conjunction,
    filters: JSON.parse(filters),
    ids: parsedIds,
  };

  return call('', {
    pathTemplate,
    method: 'POST',
    additionalParams: { queryParams },
    body,
  }, null);
};

export const messageGet = (id) => call(`/message/${id}`, {
  method: 'GET',
}, null);

export const messageGetStaticStatus = (id) => call(`/message/${id}/static/status`, {
  method: 'GET',
}, null);

export const messageTogglePublish = ({ id, action }) => call(`/message/${id}/static/${action}`, {
  method: 'POST',
}, null);

export const messageCreate = (data) => call('/message', {
  method: 'POST',
  body: data,
}, null);

export const messageSave = (data) => call('/message/html', {
  method: 'POST',
  body: data,
}, null);

export const messageSend = (data) => call(`/message/${data.id}/send`, {
  method: 'POST',
  body: data.body,
}, null);

export const messageSendTest = (data) => call(`/message/${data.id}/send/test`, {
  method: 'POST',
  body: data.body,
}, null);

export const getRecommendedCredits = (body) => call('/credit/estimate', {
  method: 'POST',
  body,
}, null);

export const contactUnsubscribeNumber = (data) => call(
  `/message/${data.id}/recipients/unsubscribed`, {
    method: 'POST',
    body: data.body,
  }, null);

export const messageSelectAllRecipients = (data) => call(
  `/message/${data.id}/recipients/import`, {
    method: 'POST',
    body: data.body,
  }, null);

export const recipientsMetadataGet = (data) => call(
  `/message/${data.id}/recipients/metadata`, {
    method: 'GET',
  }, null);

export const messageRecipientsDeleteAll = (id) => call(`/message/${id}/recipients`, {
  method: 'DELETE',
}, null);

export const messageSegmentGet = (data) => call(`/message/${data.id}/recipients/segment`, {
  method: 'POST',
  body: data.body,
}, null);
