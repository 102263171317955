import React from 'react';
import PropTypes from 'prop-types';

import './TextInput.scss';

const TextInput = ({
  label,
  onChange,
  placeholder,
  value,
  type,
  name,
  min,
  className,
}) => (
  <div className={`TextInput ${className}`}>
    {label && <label className="TextInput__input-label">{label}</label>}
    <input
      min={min}
      className="TextInput__input-field"
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      type={type}
      name={name}
    />
  </div>
);

TextInput.defaultProps = {
  className: '',
};

TextInput.propTypes = {
  className: PropTypes.string,
  min: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default TextInput;
