import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './WidgetButtonUI.scss';

const WidgetButtonUI = ({
  text,
  link,
  bgColor,
  textColor,
  size,
  index,
}) => (
  <button
    className="device-form__button"
    type="button"
    link={link}
    style={{ color: textColor, fontSize: size, backgroundColor: bgColor }}
    id={`button-${index}`}
  >
    {text || 'Button'}
  </button>
);

WidgetButtonUI.propTypes = {
    index: PropTypes.number.isRequired,
    text: PropTypes.string,
    link: PropTypes.string,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    size: PropTypes.string,
};

export default WidgetButtonUI;
