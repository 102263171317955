import moment from 'moment';
import { forEach, find, orderBy } from 'lodash';

import { ColumnComponent } from './index';
import { DATE_TIME } from '../../common';
import { BasicButton } from '../Button';
import { getAnswerText } from '../../utils/analyticsHelper';

const MessageStatsTable = ({
  onNameClick,
  options,
  forms,
  handleSurveyClick,
  form,
  trackingId,
}) => {
  const columns = [];

  columns.push({
    Header: 'Full name',
    accessor: 'contactName',
    sortable: true,
    Cell: (row) => (
      <ColumnComponent width="200">
        <span onClick={() => onNameClick(row.original.id)}>
          {`${row.value} ${row.original.contactSurname}`}
        </span>
      </ColumnComponent>
    ),
  });

  if (trackingId) {
    forEach(orderBy(form, ['weight'], ['asc']), (f, index) => {
      columns.push({
        Header: `Q${index + 1} "${f.label}"`,
        accessor: f.inputName,
        sortable: false,
        Cell: (row) => {
          const {
            original: {
              surveys,
            },
          } = row;
          const text = getAnswerText(surveys, trackingId, f, forms);
          return (
            <ColumnComponent>
              <span className="details-column-text">{text}</span>
            </ColumnComponent>
          );
        },
      });
    });
  } else {
    forEach(options, (option) => {
      if (option.isSelected) {
        if (option.value === 'sentAt') {
          columns.push({
            Header: option.label,
            accessor: option.value,
            sortable: true,
            Cell: (row) => (
              <ColumnComponent>
                <span>{moment(row.value * 1000).format(DATE_TIME)}</span>
              </ColumnComponent>
            ),
          });
        } else if (option.value === 'lastOpenedAt') {
          columns.push({
            Header: option.label,
            accessor: option.value,
            sortable: true,
            Cell: (row) => (
              <ColumnComponent>
                <span>
                  {row.value ? moment(row.value * 1000).format(DATE_TIME) : '-'}
                </span>
              </ColumnComponent>
            ),
          });
        } else if (option.value === 'timeToOpen') {
          columns.push({
            Header: option.label,
            accessor: option.value,
            sortable: true,
            Cell: (row) => (
              <ColumnComponent>
                <span>
                  {row.value ? moment.utc(row.value * 1000).format('HH:mm:ss') : '-'}
                </span>
              </ColumnComponent>
            ),
          });
        } else if (option.value !== 'all' && option.value !== 'surveys' && option.value !== 'videos'
          && option.value !== 'buttons' && option.value !== 'attachments') {
          columns.push({
            Header: option.label,
            accessor: option.value,
            sortable: true,
            Cell: (row) => {
              let details = false;
              if (!row.value) {
                const surv = find(forms, (f) => f.trackingId === option.value);
                if (surv && find(row.original.surveys,
                  (survey) => survey.TrackingID === option.value).Answers) {
                  details = true;
                }
              }
              if (details) {
                return (
                  <ColumnComponent>
                    <BasicButton
                      content="View"
                      onClick={() => handleSurveyClick(option.value)}
                      color="blue"
                      type="button"
                    />
                  </ColumnComponent>
                );
              }
              return (
                <ColumnComponent>
                  <span>{row.value || row.value === 0 ? row.value : '-'}</span>
                </ColumnComponent>
              );
            },
          });
        }
      }
    });
  }

  return columns;
};

export default MessageStatsTable;
