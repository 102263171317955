import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import { ContactFilterItem, ContactFilterItemMobile } from '../../components/Field';
import { mapFilterPropertiesToOptions } from '../../utils/map';
import {
  setOperatorOptions,
  setFilterInputComponent,
  setFilterInputProps,
} from '../../utils/helper';
import { isArrayCountValid } from '../../utils/validator';

const emptyFilterItem = {
  operator: 'contains',
  property: null,
  value: null,
};

const ContactFilterItemsArray = ({
  fields,
  contactProperties,
  formValues,
  change,
}) => (
  <div>
    {window.innerWidth > 767
      ? fields.map((field, index) => (
        <ContactFilterItem
          name={field}
          key={index}
          index={index}
          contactProperties={contactProperties}
          propertyOptions={mapFilterPropertiesToOptions(
            contactProperties,
            formValues,
            index,
          )}
          operatorOptions={setOperatorOptions(contactProperties, fields.get(index))}
          InputComponent={setFilterInputComponent(contactProperties, fields.get(index))}
          inputComponentProps={setFilterInputProps(contactProperties, fields.get(index))}
          fields={fields}
          formValues={formValues}
          change={change}
        />
      ))
      : (
        <div className="contact-filter-form--mobile__segment-box">
          {fields.map((field, index) => (
            <ContactFilterItemMobile
              name={field}
              key={index}
              index={index}
              contactProperties={contactProperties}
              propertyOptions={mapFilterPropertiesToOptions(
                contactProperties,
                formValues,
                index,
              )}
              operatorOptions={setOperatorOptions(
                contactProperties,
                fields.get(index),
              )}
              InputComponent={setFilterInputComponent(
                contactProperties,
                fields.get(index),
              )}
              inputComponentProps={setFilterInputProps(
                contactProperties,
                fields.get(index),
              )}
              fields={fields}
            />
          ))}
        </div>
      )}
    <div className="add-filter-row">
      <span className="add-filter" onClick={() => fields.push(emptyFilterItem)}>
        <i className="material-icons">add</i>
        Add filter
      </span>
      {!isArrayCountValid(formValues && formValues.filter, 1, 20)
      && (
        <div className="properties-error">
          Must at least be between 1 and 20 filters
        </div>
      )}
    </div>
  </div>
);

ContactFilterItemsArray.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func,
    pop: PropTypes.func,
    map: PropTypes.func,
    get: PropTypes.func,
  }),
  formValues: PropTypes.shape({
    filter: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
    ]),
  }),
  contactProperties: PropTypes.objectOf(PropTypes.object),
  change: PropTypes.func,
};


const mapStateToProps = (state) => ({
  formValues: getFormValues('contactFilter')(state),
});

export default connect(mapStateToProps)(ContactFilterItemsArray);
