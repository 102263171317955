import React from 'react';

// Styles.
import './LikeDislikeQuestionController.scss';

const LikeDislikeQuestionController = () => (
  <div className="LikeDislikeQuestionController">
    <div className="LikeDislikeQuestionController__thumb-up" />
    <div className="LikeDislikeQuestionController__thumb-down" />
  </div>
);

export default LikeDislikeQuestionController;
