import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, forEach } from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import SurveySelect from './SurveySelect';

const QuestionSelect = ({
  subject,
  message,
  survey,
  question,
  setMessage,
  setSurvey,
  setQuestion,
  messages,
  disabled,
}) => {
  const [loading, setLoading] = useState(true);
  const questionOptions = useMemo(() => {
    const m = find(messages, (el) => el.id === message);
    if (m) {
      const { schema: { forms } } = m;
      const form = find(forms, (f) => f.displayName === survey);
      if (form) {
        const options = [];
        forEach(form.elements, (element) => {
          if (element.elementType === 'Choices' || element.elementType === 'SingleChoice') {
            options.push({ text: element.label, value: element.inputName });
          }
        });
        setLoading(false);
        return options;
      }
    }
    setLoading(true);
    return [];
  }, [message, survey, messages]);

  return (
    <>
      <SurveySelect
        subject={subject}
        message={message}
        survey={survey}
        setMessage={setMessage}
        setSurvey={setSurvey}
        multi
        disabled={disabled}
      />
      {!!message && !!survey && (
        <span className="subject-input-select--three-column">
          <Dropdown
            className="subject-input-select__dropdown"
            options={questionOptions}
            placeholder="Select a question"
            value={question}
            onChange={(e, { value }) => setQuestion(value)}
            loading={loading}
            disabled={disabled}
          />
        </span>
      )}
    </>
  );
};

QuestionSelect.propTypes = {
  subject: PropTypes.string,
  message: PropTypes.string,
  survey: PropTypes.string,
  question: PropTypes.string,
  setMessage: PropTypes.func,
  setSurvey: PropTypes.func,
  setQuestion: PropTypes.func,
  messages: PropTypes.object,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  messages: state.messages,
});

export default connect(mapStateToProps)(QuestionSelect);
