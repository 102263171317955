import { useDrag } from 'react-dnd';
import React from 'react';
import PropTypes from 'prop-types';

import './CreateElementButton.scss';

const CreateElementButton = ({ onClick, iconName, title }) => {
  const [collected, drag] = useDrag(() => ({
    type: 'element',
    item: { name },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onClick();
      }
    },
  }));

  return (
    <button ref={drag} className="CreateElementButton" type="button" onClick={onClick} {...collected}>
      <i className="material-icons widget-icon">{iconName}</i>
      <p className="text">{title}</p>
    </button>
  );
};

CreateElementButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CreateElementButton;
