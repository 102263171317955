import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Breadcrumb } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { MOBILE_MAX_WIDTH } from '../../common';
import { MessageContext } from '../../context/messageEditor';
import { EditorContext } from '../../editor/context/EditorContext';
import { useScreenWidth } from '../../hooks';

import { genericActions } from '../../actions';

const { openModalPortal, closeModalPortal } = genericActions;


const BackwardStep = ({ currentStep, setCurrentStep, previousStep }) => {
  switch (currentStep) {
    case 'contacts': {
      return (
        <div
          className="editor-backward-step"
          onClick={() => {
            if (previousStep === 'delivery') {
              setCurrentStep('delivery', true);
            } else setCurrentStep('design', true);
          }}
        >
          <span className="editor-header-nav editor-header-nav--enable">
            Back to
            {' '}
            {previousStep === 'delivery' ? 'Delivery' : 'Editor'}
          </span>
        </div>
      );
    }
    case 'delivery': {
      return (
        <div className="editor-backward-step" onClick={() => location.reload()}>
          <span className="editor-header-nav editor-header-nav--enable">Back to Editor</span>
        </div>
      );
    }
    default: {
      return (
        <Grid.Column width={4} className="editor-backward-step" style={{ display: 'none' }} />
      );
    }
  }
};

BackwardStep.propTypes = {
  currentStep: PropTypes.string.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
};

const ForwardStep = ({
 currentStep, setCurrentStep, previousStep, handleSaveAndGoToDelivery,
}) => {
  const { windowWidth } = useScreenWidth();

  switch (currentStep) {
    case 'design': {
      return (
        <div
          className={window.location.pathname.split('/').pop() === 'preview' ? 'none' : 'editor-button'}
          onClick={handleSaveAndGoToDelivery}
        >
          {windowWidth > MOBILE_MAX_WIDTH ? 'Save and go to Delivery' : 'Delivery'}
        </div>
      );
    }
    case 'contacts': {
      if (windowWidth <= MOBILE_MAX_WIDTH) {
        return <></>;
      }
      return (
        <Grid.Column
          className="editor-contacts-back"
          width={5}
          onClick={() => {
            if (previousStep === 'design') {
              setCurrentStep('design');
            } else {
              setCurrentStep('delivery');
            }
          }}
        >
          Back to
          {previousStep === 'design' ? ' Editor' : ' Delivery Method'}
        </Grid.Column>
      );
    }
    default: {
      return <div />;
    }
  }
};

ForwardStep.propTypes = {
  handleSaveAndGoToDelivery: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
};

const HeaderEditor = () => {
  const {
    setCurrentStep,
    currentStep,
    handleLogoClick,
    previousStep,
  } = useContext(MessageContext);

  const { getCurrentProject } = useContext(EditorContext);

  const handleSaveAndGoToDelivery = () => {
    const projectData = getCurrentProject();
    setCurrentStep('delivery', undefined, undefined, undefined, projectData); // TODO refactor this mess
  };

  return (
    <Grid className="app-header app-header__editor">
      <Grid.Row columns={3} className="header-content-row">
        <BackwardStep
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          previousStep={previousStep}
        />
        <div className="site-logo-white" onClick={() => handleLogoClick(window.location.pathname.split('/').pop() === 'preview')} />
        <Grid.Column width={5} only="computer tablet" textAlign="center" verticalAlign="middle" className={window.location.pathname.split('/').pop() === 'preview' ? 'none' : ''}>
          {currentStep !== 'contacts' && (
            <Breadcrumb>
              <Breadcrumb.Section
                onClick={() => location.reload()}
                active={true}
              >
                Design
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="chevron right" style={{ color: 'white' }} />
              <Breadcrumb.Section
                onClick={() => handleSaveAndGoToDelivery()}
                active={currentStep === 'delivery'}
              >
                Delivery Method
              </Breadcrumb.Section>
            </Breadcrumb>
          )}
        </Grid.Column>
        <ForwardStep
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          previousStep={previousStep}
          handleSaveAndGoToDelivery={() => handleSaveAndGoToDelivery()}
        />
      </Grid.Row>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(HeaderEditor));
