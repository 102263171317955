import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DATE_TIME } from '../../common';
import {
  isFrozen,
  isDraft,
  getMessageContacts,
  getMessageStatusText,
  isActiveAnalytics,
  canFreeze,
} from '../../utils/helper';

const MessageItemTablet = ({
  id,
  header,
  mainContent,
  secondaryContent,
  actions,
  message,
  openRate,
  numOfRecipients,
}) => {
  const messageIsSent = secondaryContent.messageStatus === 'Sent';
  const messageIsDraft = secondaryContent.messageStatus === 'Draft';
  const messageIsUrl = secondaryContent.messageStatus === 'Url';
  const messageIsEditable = messageIsDraft || messageIsUrl;

  const recipientValue = getMessageContacts(
    secondaryContent,
    numOfRecipients,
  );

  return (
    <div className="message-tablet">
      <div
        className="message-tablet__image"
        style={{
          backgroundImage: `url(${mainContent.backgroundImg})`,
        }}
        onClick={messageIsEditable
          ? () => actions.edit(id)
          : () => actions.preview(id)}
      />
      <div className="message-tablet__message-width">
        <div className="message-tablet__description">
          <div
            className="message-tablet__description__name"
            onClick={messageIsEditable
              ? () => actions.edit(id)
              : () => actions.preview(id)}
          >
            {secondaryContent.messageName}
          </div>
          <div className="message-tablet__description__tag-and-name">
            <span
              className={`message-tablet__description__tag message-tablet__description__tag--${mainContent.deliveryMethod === 'Sms'
                ? 'sms' : 'email'}`}
            >
              {mainContent.deliveryMethod || 'SMS'}
            </span>
            <span className="message-tablet__description__tag message-tablet__description__tag--black">
              {mainContent.createdBy}
            </span>
          </div>
        </div>
        <div className="message-tablet__content-wrapper">
          <div className={`message-tablet--status message-tablet--status__${secondaryContent.messageStatus}`}>
            {getMessageStatusText(secondaryContent)}
          </div>
          <div className="message-tablet__stats-wrapper">
            <div className="message-tablet__date">
              <div className="message-tablet__date__name">
                {header.actionPrefix === 'Sent at' ? header.actionPrefix : 'Created at'}
              </div>
              <div className="message-tablet__date__time">
                {header.actionPrefix === 'Sent at'
                  ? header.actionTime
                  : moment(message.createdAt).format(DATE_TIME)}
              </div>
            </div>
            <div className="message-tablet__date">
              <div className="message-tablet__date__name">
                {secondaryContent.expirationDate !== '0001/Jan/01' && 'Expiry Date'}
              </div>
              <div className="message-tablet__date__time">
                {secondaryContent.expirationDate !== '0001/Jan/01'
                  ? secondaryContent.expirationDate
                  : ''}
              </div>
            </div>
            <div className="message-tablet__date message-tablet__date--small">
              <div className="message-tablet__date__name">
                {messageIsSent ? 'Contacts' : ''}
              </div>
              <div className="message-tablet__date__time">
                {recipientValue}
              </div>
            </div>
          </div>
          <div className="icons-wrapper">
            <span>
              <i
                className={messageIsEditable
                  ? 'message-icon message-icon__edit'
                  : 'message-icon message-icon__preview'}
                onClick={messageIsEditable
                  ? () => actions.edit(id)
                  : () => actions.preview(id)}
                title={messageIsEditable ? 'Edit' : 'Preview'}
              />
            </span>
            <span>
              {
                isActiveAnalytics(secondaryContent)
                  ? (
                    <Link href={`message/${id}/stats`} to={`message/${id}/stats`}>
                      <i
                        className="message-icon message-icon__chart"
                        title="Message analytics"
                      />
                    </Link>
                  )
                  : (
                    <div>
                      <i
                        className="message-icon message-icon__chart_disabled"
                        title="Message analytics"
                      />
                    </div>
                  )
              }
            </span>
            <span>
              <i
                className="message-icon message-icon__email"
                onClick={() => actions.duplicate(id)}
                title="Duplicate message"
              />
            </span>
            <span>
              <i
                className={canFreeze(secondaryContent)
                  ? `message-icon message-icon__link${isFrozen(secondaryContent.messageStatus)}`
                  : 'message-icon message-icon__link-draft'}
                onClick={canFreeze(secondaryContent)
                  ? () => actions.freeze(id, secondaryContent.messageStatus)
                  : () => {
                  }}
                title={isDraft(secondaryContent.messageStatus)}
              />
            </span>
            <span>
              <i
                className="message-icon message-icon__delete"
                onClick={() => actions.delete(id)}
                title="Delete message"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

MessageItemTablet.propTypes = {
  header: PropTypes.object,
  mainContent: PropTypes.object,
  secondaryContent: PropTypes.object,
  actions: PropTypes.object,
  id: PropTypes.string,
  message: PropTypes.object,
  openRate: PropTypes.number,
  numOfRecipients: PropTypes.number,
};

export default MessageItemTablet;
