import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  reduxForm,
  getFormSyncErrors,
  Field,
} from 'redux-form';
import { BasicButton } from '../../components/Button';
import { authActions } from '../../actions';
import { email, required } from '../../utils/validator';
import { Input } from '../../components/Field';

const { login } = authActions;

const LoginForm = ({ handleSubmit, submitting, loading }) => (
  <div>
    <Form onSubmit={handleSubmit(login.bind(this))} className="nobackground-flex">
      <Grid>
        <Grid.Row>
          <Grid.Column className="centered" computer={5} tablet={8} mobile={14} id="100a">
            <Field
              className="app-form__field"
              label="Email"
              component={Input}
              name="email"
              validate={[email, required]}
              placeholder="Enter your email"
              type="email"
              autoFocus
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="centered" computer={5} tablet={8} mobile={14}>
            <Field
              className="app-form__field"
              label="Password"
              component={Input}
              name="password"
              validate={required}
              type="password"
              placeholder="Enter your password"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-spacing">
          <Grid.Column className="centered" computer={5} tablet={8} mobile={14}>
            <a
              href="/forgot-password"
              className="app-form__already-link right-floated"
            >
              Forgot your password?
            </a>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="centered" computer={5} tablet={8} mobile={14}>
            <BasicButton
              fluid
              content="Login"
              color="blue"
              className="small-14"
              disabled={loading || submitting}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-spacing">
          <Grid.Column
            className="app-form__already centered"
            width={16}
          >
            <div>Dont have an account?</div>
            <div>
              <a href="/register" className="app-form__already-link">Register here</a>
              to start your free trial
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  </div>
);
const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('login')(state),
});

const formConfig = {
  form: 'login',
  destroyOnUnmount: false,
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  loading: PropTypes.bool,
};

export default connect(mapStateToProps)(reduxForm(formConfig)(LoginForm));
