import Select from 'react-select';
import PropTypes from 'prop-types';

// import 'react-select/dist/react-select.css';

const RenderSelect = ({
  clearable = true,
  input,
  multi,
  name,
  options,
  placeholder,
  simpleValue,
  validate = null,
  selectedOption,
  handleOnChange,
  className,
  delimiter = ',',
}) => (
  <Select
    className={`react-select${className ? ` ${className}` : ''}`}
    clearable={clearable}
    labelKey="name"
    name={name}
    isMulti={multi}
    options={options}
    onChange={(value) => {
      input.onChange(value);
      if (handleOnChange) {
        handleOnChange(value);
      }
    }}
    placeholder={placeholder}
    simpleValue={simpleValue}
    validate={validate}
    value={selectedOption || input.value}
    delimiter={delimiter}
  />
);


RenderSelect.propTypes = {
  input: PropTypes.object,
  clearable: PropTypes.bool,
  name: PropTypes.string,
  selectedOption: PropTypes.string,
  handleOnChange: PropTypes.func,
  multi: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  simpleValue: PropTypes.bool,
  validate: PropTypes.func,
  className: PropTypes.string,
  delimiter: PropTypes.string,
};

export default RenderSelect;
