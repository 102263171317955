import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BasicButton } from '../../components/Button/index';
import { AccountSettingsForm } from '../Form';
import { notificationActions, authActions } from '../../actions';
import { TABLET_MAX_WIDTH } from '../../common';

const { logout } = authActions;

const { notifySuccess } = notificationActions;


const AccountSettingsModal = ({
  open,
  handleFormModalState,
  size,
  history,
  logoutA,
}) => {
  const handleResetPasswordClick = () => {
    handleFormModalState();
    history.push('/reset-password');
  };

  return (
    <Modal
      open={open}
      onClose={() => handleFormModalState()}
      className="account-settings-modal"
      size={size}
      closeOnDocumentClick={true}
      dimmer="inverted"
    >
      <div className="modal-close-row">
        <div className="close modal-close-icon" onClick={() => handleFormModalState()} />
      </div>
      <Modal.Header className="contact-modal__header">
        <span className="logout-span">
          Account settings
          {window.innerWidth < TABLET_MAX_WIDTH
            && (
            <button
              type="button"
              onClick={() => logoutA()}
              className="logout-button ui button blue"
            >
              Log out
            </button>
            )}
        </span>
      </Modal.Header>
      <Modal.Content>
        <div className="flex-between">
          <span>Update Account Information</span>
          <BasicButton
            content="Reset password"
            color="blue"
            size="small"
            onClick={() => handleResetPasswordClick()}
          />
        </div>
        <AccountSettingsForm />
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  showSuccessMsg: bindActionCreators(notifySuccess, dispatch),
  logoutA: bindActionCreators(logout, dispatch),
});

AccountSettingsModal.propTypes = {
  open: PropTypes.bool,
  history: PropTypes.object,
  handleFormModalState: PropTypes.func,
  size: PropTypes.string,
  logoutA: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountSettingsModal));
