import React from 'react';
import PropTypes from 'prop-types';

// Widget UI components.
import WidgetButtonUI from '../../widgetComponents/WidgetButtonUI';
import WidgetVideoUI from '../../widgetComponents/WidgetVideoUI';
import WidgetFileUI from '../../widgetComponents/WidgetFileUI';
import WidgetPictureUI from '../../widgetComponents/WidgetPictureUI';
import WidgetTextUI from '../../widgetComponents/WidgetTextUI';
import WidgetOptInUI from '../../widgetComponents/WidgetOptInUI';
import WidgetSurveyUI from '../../widgetComponents/WidgetSurveyUI';
import UnsubscribeLinkUI from '../../widgetComponents/UnsubscribeLinkUI';

// Constants.
import {
  BUTTON,
  VIDEO,
  IMAGE,
  TEXT,
  OPT_IN,
  SURVEY,
} from '../../constants/widgetTypes';

// Styles.
import './MessagePreview.scss';

const renderElement = (element, index) => {
  const { elementType, id } = element;

  const getElement = (type, props) => {
    const elementsObj = {
      [BUTTON]: type === BUTTON && <WidgetButtonUI {...props} />,
      [VIDEO]: type === VIDEO && <WidgetVideoUI {...props} />,
      [IMAGE]: type === IMAGE && <WidgetPictureUI {...props} />,
      [TEXT]: type === TEXT && <WidgetTextUI {...props} />,
      [OPT_IN]: type === OPT_IN && <WidgetOptInUI {...props} />,
      [SURVEY]: type === SURVEY && <WidgetSurveyUI {...props} />,
    };

    return (
      <div id={`message-view-block-${index + 1}`} className="device-form__block content-row" key={id}>
        {elementsObj[type]}
      </div>
    );
  };

  return getElement(elementType, { ...element, index });
};

const MessagePreview = ({ projectData }) => {
  const {
    elementsStructureArray,
    backgroundColor,
    attachments,
    unsubscribe,
  } = projectData;

  const uploadedFiles = attachments?.files || [];

  return (
    <div
      id="message-view"
      style={{ backgroundColor }}
      className="MessagePreview__elements-wrapper"
    >
      <div id="message-view-block-0" className="device-form__block" style={{ display: 'none' }}>
        <data-element>
          {JSON.stringify(projectData)}
        </data-element>
      </div>
      {elementsStructureArray.map(renderElement)}
      {unsubscribe && <UnsubscribeLinkUI />}
      {!!uploadedFiles.length
        && (
          <div className="MessagePreview__attachments-block">
            <WidgetFileUI
              fileData={attachments}
            />
          </div>
      )}
    </div>
  );
};

MessagePreview.propTypes = {
  projectData: PropTypes.shape({
    elementsStructureArray: PropTypes.arrayOf(PropTypes.object),
    backgroundColor: PropTypes.string.isRequired,
    attachments: PropTypes.oneOfType([
      PropTypes.bool, // because initial value is false
      PropTypes.shape({
        elementType: PropTypes.string,
        files: PropTypes.arrayOf(PropTypes.shape({
          fileName: PropTypes.string,
          href: PropTypes.string,
          id: PropTypes.string,
        })),
        id: PropTypes.string,
    })]),
    unsubscribe: PropTypes.bool,
  }).isRequired,
};

export default MessagePreview;
