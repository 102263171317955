import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router';
import { authActions, genericActions } from '../../actions';
import { AccountSettingsModal } from '../../containers/Modal';
import { useScreenWidth } from '../../hooks';

const { openModalPortal, closeModalPortal } = genericActions;


const { logout } = authActions;

const AccountNavigationDesktop = ({
  logoutA,
  user,
  openModalPortalA,
  closeModalPortalA,
}) => {
  const [modalOpen, setModalOpen] = useState(true);

  const { isDesktop } = useScreenWidth();

  const { push } = useHistory();
  const { pathname } = useLocation();

  const trigger = () => (
    <span className="app-header__account">{user && user.fullName}</span>
  );

  const openUserSettingsModal = () => {
    openModalPortalA({
      content: <AccountSettingsModal />,
      contentProps: {
        handleFormModalState: closeModalPortalA,
        size: isDesktop ? 'small' : 'fullscreen',
      },
    });
  };

  const handleModal = () => {
    setModalOpen(!modalOpen);
    openUserSettingsModal();
  };

  let userRole;
  if (user.role === 'admin' || user.role === 'root' || user.role === 'expired') {
    userRole = true;
  }

  return (
    <Menu className="account-navigation-menu-desktop">
      <Dropdown
        pointing="top right"
        className="dropdown-header-nav"
        trigger={trigger()}
      >
        {userRole ? (
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => push('/account-settings#account')} active={pathname === '/account-settings#account'}>
              Account
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => push('/account-settings#plans')}
              active={pathname === '/account-settings#plans'}
            >
              Plans
            </Dropdown.Item>
            <Dropdown.Item onClick={() => push('/account-settings#domain')} active={pathname === '/account-settings#domain'}>
              Domain
            </Dropdown.Item>
            <Dropdown.Item onClick={() => push('/account-settings#billing')} active={pathname === '/account-settings#billing'}>
              Billing
            </Dropdown.Item>
            <Dropdown.Item onClick={() => push('/account-settings#users')} active={pathname === '/account-settings#users'}>
              Users
            </Dropdown.Item>
            <Dropdown.Item onClick={() => push('/account-settings#payments')} active={pathname === '/account-settings#payments'}>
              Payments
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className="dropdown-logout" onClick={logoutA}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleModal} active={pathname === '/account-settings'}>
              Account Settings
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-logout" onClick={logoutA}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </Menu>
  );
};

AccountNavigationDesktop.propTypes = {
  user: PropTypes.object,
  logoutA: PropTypes.func,
  openModalPortalA: PropTypes.func,
  closeModalPortalA: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  logoutA: bindActionCreators(logout, dispatch),
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
});

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountNavigationDesktop);
