import {
  REQUEST,
  SUCCESS,
  FAILURE,
  createRequestTypes,
  action,
} from './request';

export const FETCH_RECIPIENTS_METRICS = createRequestTypes('FETCH_RECIPIENTS_METRICS');
export const FETCH_OPEN_ANSWERS_FORM_METRICS = createRequestTypes('FETCH_OPEN_ANSWERS_FORM_METRICS');
export const SEARCH_RECIPIENT_METRICS = createRequestTypes('SEARCH_RECIPIENT_METRICS');
export const FETCH_DASHBOARD_METRICS = createRequestTypes('FETCH_DASHBOARD_METRICS');
export const FETCH_ANALYTICS_DATA = createRequestTypes('FETCH_ANALYTICS_DATA');
export const CLEAR_ANALYTICS_DATA = createRequestTypes('CLEAR_ANALYTICS_DATA');

export const fetchAnalyticsData = {
  request: (payload) => action(FETCH_ANALYTICS_DATA[REQUEST], { payload }),
  success: (response) => action(FETCH_ANALYTICS_DATA[SUCCESS], { response }),
  failure: (error) => action(FETCH_ANALYTICS_DATA[FAILURE], { error }),
};

export const fetchRecipientsMetrics = {
  request: (payload) => action(FETCH_RECIPIENTS_METRICS[REQUEST], { payload }),
  success: (response) => action(FETCH_RECIPIENTS_METRICS[SUCCESS], { response }),
  failure: (error) => action(FETCH_RECIPIENTS_METRICS[FAILURE], { error }),
};

export const fetchOpenAnswersForms = {
  request: (payload) => action(FETCH_OPEN_ANSWERS_FORM_METRICS[REQUEST], { payload }),
  success: (response, fieldName) => action(FETCH_OPEN_ANSWERS_FORM_METRICS[SUCCESS], {
    response,
    fieldName,
  }),
  failure: (error) => action(FETCH_OPEN_ANSWERS_FORM_METRICS[FAILURE], { error }),
};

export const searchRecipientMetrics = {
  request: (payload) => action(SEARCH_RECIPIENT_METRICS[REQUEST], { payload }),
  success: (response) => action(SEARCH_RECIPIENT_METRICS[SUCCESS], { response }),
  failure: (error) => action(SEARCH_RECIPIENT_METRICS[FAILURE], { error }),
};

export const fetchDashboardMetrics = {
  request: (payload) => action(FETCH_DASHBOARD_METRICS[REQUEST], { payload }),
  success: (response, deliveryMethod) => action(FETCH_DASHBOARD_METRICS[SUCCESS], {
    response,
    deliveryMethod,
  }),
  failure: (error) => action(FETCH_DASHBOARD_METRICS[FAILURE], { error }),
};

export const clearAnalyticsData = {
  request: () => action(CLEAR_ANALYTICS_DATA[REQUEST]),
};

export const clearDashboardStatus = () => ({
  type: 'CLEAR_DASHBOARD_STATUS',
});
