import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components.
import CreateElementButton from '../../components/CreateElementButton';

// Constants
import { VIDEO } from '../../constants/widgetTypes';

// Context.
import {
  EditorContext,
} from '../../context/EditorContext';

const VideoWidget = () => {
  const {
      addElement,
      setActiveElement,
    } = useContext(EditorContext);

  const createVideoElement = () => {
    const elementId = uuidv4();
    const defaultElementPropertiesObj = {
    elementType: VIDEO,
    id: elementId,
    data: {
            changedKey: elementId,
            sources: [
                {
                    type: undefined,
                    src: undefined,
                },
            ],
          },
    };
    addElement(defaultElementPropertiesObj);
    setActiveElement(defaultElementPropertiesObj);
  };

  return (
    <CreateElementButton
      title="VIDEO"
      iconName="play_arrow"
      onClick={() => createVideoElement()}
    />
  );
};

export default VideoWidget;
