import React, {
  createContext,
  useReducer,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import EditorReducer from './EditorReducer';
import { actionTypes } from './EditorActionTypes';
import { FILE } from '../constants/widgetTypes';

const initialState = {
  project: {
    projectTitle: 'untitled',
    activeElement: false,
    elementsStructureArray: [],
    attachments: false,
    backgroundColor: '#ffffff',
    unsubscribe: false,
  },
};

const EditorContext = createContext(initialState);

const EditorContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(EditorReducer, initialState);

  // using this state to detect if something is changed in our editor
  // added, modified, removed or reordered elements.
  const [isChangesDetected, setChangesDetected] = useState(false);

  useEffect(() => () => {
      resetEditorState();
    }, []);

  useEffect(() => {
    let prevStateElementValue;
    if (state.project.activeElement) {
      prevStateElementValue = state.project.elementsStructureArray.find(
        (element) => element.id === state.project.activeElement.id);
    }
    if (state.project.activeElement) {
      const isStateElementChanged = !isEqual(prevStateElementValue, state.project.activeElement);
      if (isStateElementChanged && !isChangesDetected) {
        setChangesDetected(true);
      }
    }
  }, [state.project.activeElement]);

  // editor actions
  const setBackgroundColor = (color) => {
    dispatch({
      type: actionTypes.SET_BACKGROUND_COLOR,
      payload: color,
    });
  };

  const addAttachments = (attachments) => {
    dispatch({
      type: actionTypes.ADD_ATTACHMENTS,
      payload: attachments,
    });
  };

  const addElementsData = (elementsData) => {
    dispatch({
      type: actionTypes.ADD_PROJECT_ELEMENTS_DATA,
      payload: elementsData,
    });
  };

  const changeProjectTitle = (projectTitle) => {
    dispatch({
      type: actionTypes.CHANGE_PROJECT_TITLE,
      payload: projectTitle,
    });
  };

  const addElement = (element) => {
    if (!isChangesDetected) {
      setChangesDetected(true);
    }
    dispatch({
      type: actionTypes.ADD_ELEMENT,
      payload: element,
    });
  };

  const setActiveElement = (element) => {
    dispatch({
      type: actionTypes.SET_ACTIVE_ELEMENT,
      payload: element,
    });
  };

  const removeElement = (element) => {
    if (!isChangesDetected) {
      setChangesDetected(true);
    }
    dispatch({
      type: actionTypes.REMOVE_ELEMENT,
      payload: element,
    });
  };

  const replaceElement = (element) => {
    if (!isChangesDetected) {
      setChangesDetected(true);
    }
    dispatch({
      type: actionTypes.REPLACE_ELEMENT,
      payload: element,
    });
  };

  const setUnsubscribe = (unsubscribe) => {
    dispatch({
      type: actionTypes.CHANGE_UNSUBSCRIBE,
      payload: unsubscribe,
    });
  };

  const resetEditorState = () => {
    setActiveElement(false);
    setChangesDetected(false);
    addElementsData([]);
    addAttachments(false);
    setBackgroundColor('#ffffff');
  };

  const getCurrentProject = () => {
    const { activeElement, elementsStructureArray } = state.project;
    let updatedProjectData = { ...state.project };
    if (activeElement) {
      if (activeElement.elementType === FILE) {
        updatedProjectData = {
          ...state.project,
          attachments: activeElement,
        };
      } else {
        const modifiedArr = elementsStructureArray.map(
          (dataArrItem) => (dataArrItem.id !== activeElement.id ? dataArrItem : activeElement),
        );
        updatedProjectData = {
          ...state.project,
          elementsStructureArray: modifiedArr,
        };
      }
    }

    return updatedProjectData;
  };

  return (
    <EditorContext.Provider
      value={{
        projectTitle: state.project.projectTitle,
        elementsStructureArray: state.project.elementsStructureArray,
        activeElement: state.project.activeElement,
        projectId: state.project.projectId,
        projectData: state.project,
        attachments: state.project.attachments,
        backgroundColor: state.project.backgroundColor,
        isChangesDetected,
        addAttachments,
        setChangesDetected,
        addElementsData,
        changeProjectTitle,
        addElement,
        setActiveElement,
        removeElement,
        replaceElement,
        resetEditorState,
        setBackgroundColor,
        getCurrentProject,
        setUnsubscribe,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

EditorContextProvider.propTypes = {
  children: PropTypes.node,
};

export { EditorContext, EditorContextProvider };
