import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { find } from 'lodash';

const SelectField = ({
  input,
  options,
  disabled = false,
  placeholder,
  className,
  direction,
  defaultValue,
  upward,
  tabIndex,
}) => {
  const displayOption = find(options, (o) => o.value === input.value);

  return (
    <>
      <Dropdown
        className={className}
        defaultValue={defaultValue}
        disabled={disabled}
        direction={direction}
        options={options}
        onChange={(ev, data) => input.onChange(data.value)}
        placeholder={placeholder}
        selectOnBlur={false}
        scrolling
        upward={upward}
        text={displayOption && displayOption.text}
        tabIndex={tabIndex}
      />
    </>
  );
};

SelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  }),
  options: PropTypes.arrayOf(PropTypes.object),
  defaultValue: PropTypes.string,
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  upward: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export default SelectField;
