import { omit } from 'lodash';
import { authActions, accountActions, request } from '../actions';

const { MULTIPLE_COMPANIES_LOGIN } = authActions;
const { FETCH_ACCOUNT_USER } = accountActions;
const { SUCCESS } = request;

const auth = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_USER[SUCCESS]: {
      return {
        ...omit(action, 'type'),
      };
    }
    case MULTIPLE_COMPANIES_LOGIN: {
      return {
        multipleAccounts: { ...action.payload },
      };
    }
    default:
      return state;
  }
};

export default auth;
