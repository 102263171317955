import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Components.
import TextInput from '../../../../../../../../components/TextInput';

// Context.
import { EditorContext } from '../../../../../../../../context/EditorContext';

// Styles.
import './RatingQuestionController.scss';

const RatingQuestionController = ({
  ratingValue,
  questionId,
}) => {
  const {
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const setRatingValue = (value) => {
    const dataObj = { ...activeElement };
    const { questions } = dataObj;
    const questionIndex = questions.findIndex((question) => question.questionId === questionId);

    if (parseInt(value, 10) < 1) {
      if (parseInt(value, 10) === 0) {
        questions[questionIndex].ratingValue = '1';
      } else {
        questions[questionIndex].ratingValue = `${Math.abs(parseInt(value, 10))}`;
      }
    } else {
      questions[questionIndex].ratingValue = value;
    }

    setActiveElement(dataObj);
  };

  return (
    <div className="RatingQuestionController">
      <div className="RatingQuestionController__rating-data-container">
        <p className="RatingQuestionController__rating-data-label">Maximum value:</p>
        <TextInput
          type="number"
          min="1"
          value={ratingValue}
          placeholder="Type rating here..."
          onChange={(e) => setRatingValue(e.target.value)}
        />
      </div>
      <i className="RatingQuestionController__disclaimer-text">
        The rating will always start at 1
      </i>
    </div>
  );
};

RatingQuestionController.propTypes = {
  ratingValue: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
};

export default RatingQuestionController;
