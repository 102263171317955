import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'videojs-youtube';
import 'video.js/dist/video-js.css';

export const useVideoJS = (videoJsOptions, src, type, videoId, isYoutubeUrl) => {
  const { changedKey } = videoJsOptions;
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    playerRef.current = videojs(videoRef.current, videoJsOptions);

    return () => {
      playerRef.current.dispose();
    };
  }, [videoJsOptions]);


  const Video = (props) => (
    <div data-vjs-player key={changedKey}>
      <video
        {...props}
        youtube={isYoutubeUrl}
        src={src}
        id={videoId}
        ref={videoRef}
        className="video-js"
      >
        {src && <source src={src} type={type} />}
        <track
          kind="captions"
          src="sampleCaptions.vtt"
          srcLang="en"
        />
      </video>
    </div>
    );
  return { Video, player: playerRef.current };
};
