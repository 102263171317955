import React from 'react';
import PropTypes from 'prop-types';

// Compositions.
import EditorView from '../EditorView';

// Styles.
import './SentMessageEditorPreview.scss';

const SentMessageEditorPreview = ({ preview }) => (
  <div className="SentMessageEditorPreview">
    <div className="SentMessageEditorPreview__editor-layout">
      <div className="SentMessageEditorPreview__editor-preview-side">
        <EditorView isPreview={preview} />
      </div>
    </div>
  </div>
);

SentMessageEditorPreview.propTypes = {
  preview: PropTypes.bool,
};

export default SentMessageEditorPreview;
