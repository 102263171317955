import PropTypes from 'prop-types';
import { includes } from 'lodash';

const ChoicesAnalytics = ({
  element,
  metric: {
    value,
    numOfRespondents,
  },
}) => {
  if ((element.elementType === 'Choices' || element.elementType === 'SingleChoice')
    && element.choices.length < 2) {
    return null;
  }
  const { answers } = element;
  return (
    <div className="survey-stats__multiple">
      {element.choices.map((val, j) => {
        const correctAnswer = includes(answers, j);
        return (
          <div key={j} className="survey-stats__multiple--answer">
            <div className="survey-stats__multiple--answer--label">
              {val.label}
              {correctAnswer && (
                <span className="survey-stats__multiple--answer--label--correct">
                  (Correct answer)
                </span>
              )}
            </div>
            <div className="survey-stats__multiple--answer--stats">
              {value[val.inputValue] ? (
                <div
                  className="survey-stats__multiple--answer--stats--bar"
                  style={{
                    width: `${((value[val.inputValue]
                      / numOfRespondents) * 100)}%`,
                  }}
                />
              ) : (
                <div className="survey-stats__multiple--answer--stats--bar-not" />
              )}
              <div className="survey-stats__multiple--answer--stats--count">
                <div className="survey-stats__multiple--answer--stats--percent">
                  {
                    isNaN(value[val.inputValue]
                      / numOfRespondents) ? 0
                      : Math.round((value[val.inputValue]
                      / numOfRespondents) * 10000) / 100
                  }
                  %
                </div>
                <div className="survey-stats__multiple--answer--stats--answers">
                  {value[val.inputValue] || 0}
                  &nbsp;answers
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

ChoicesAnalytics.propTypes = {
  element: PropTypes.object,
  metric: PropTypes.shape({
    value: PropTypes.object,
    numOfRespondents: PropTypes.number,
  }),
};

export default ChoicesAnalytics;
