import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions.
import { genericActions } from '../../../actions';

// Components.
import WidgetControllerHeader from '../../components/WidgetControllerHeader';
import { BasicButton } from '../../../components/Button';

// Modals.
import { S3UploadModal, MediaSelectModal } from '../../../containers/Modal';

// Compositions.
import WidgetControllerFooter from '../../compositions/WidgetControllerFooter';

// Context.
import { EditorContext } from '../../context/EditorContext';

// Services.
import { api } from '../../../services';

// Styles.
import './PictureWidgetController.scss';

const { openModalPortal, closeModalPortal } = genericActions;
const { S3: { uploadS3Media, uploadSignedFile } } = api;

const PictureWidgetController = ({
  openModalPortalA,
  closeModalPortalA,
}) => {
  const {
    replaceElement,
    activeElement,
    setActiveElement,
  } = useContext(EditorContext);

  const { src, alt, width } = activeElement;

  const uploadPicture = (fileBase64, fileName, type) => {
    const fileType = type.split('/')[1] === 'jpeg' ? 'jpg' : 'png';

    uploadS3Media(fileType).then((res) => {
      const url = new URL(res.response.url);
      const loc = url.origin + url.pathname;
      uploadSignedFile(fileBase64, res.response.url, fileType).then(() => {
        setTimeout(() => handleMediaSelect({ mediaUrl: loc, title: fileName }), 500);
        closeModalPortalA();
      }).catch(closeModalPortalA);
    }).catch(closeModalPortalA);
  };

  const openMediaLibrary = () => {
    openModalPortalA({
      content: <MediaSelectModal />,
      contentProps: {
        closeModalPortal: () => {
          closeModalPortalA();
        },
        size: 'fullscreen',
        type: 'image',
        selectedMedia: { mediaUrl: src, title: alt },
        modalName: 'Upload Picture',
        handleMediaSelect,
      },
    });
  };

  const openUploadModal = () => {
    openModalPortalA({
      content: <S3UploadModal />,
      closeModalPortal: closeModalPortalA,
      contentProps: {
        closeModalPortal: () => {
          closeModalPortalA();
        },
        onUpload: (fileBase64, fileName, type) => uploadPicture(fileBase64, fileName, type),
        modalName: 'Upload Image',
        accept: ['image/*'],
        maxSize: 3145728, // 3mb for images
      },
    });
  };

  const handleImageSize = (value) => {
    const dataObj = { ...activeElement };
    dataObj.width = `${value}%`;

    setActiveElement(dataObj);
  };

  const handleMediaSelect = (media) => {
    const dataObj = { ...activeElement };
    dataObj.src = media.mediaUrl;
    dataObj.alt = media.title;
    setActiveElement(dataObj);
  };

  const onSaveButtonClick = () => {
    replaceElement(activeElement);
    setActiveElement(false);
  };

  const onCancelButtonClick = () => {
    setActiveElement(false);
  };

  return (
    <div className="PictureWidgetController">
      <WidgetControllerHeader title="Picture settings" />

      <div className="PictureWidgetController__controller-body">
        <div className="PictureWidgetController__image-data-controller">
          <div className="PictureWidgetController__image-wrapper">
            <img
              src={activeElement.src}
              alt=""
              className="PictureWidgetController__image"
            />
          </div>

          <div className="PictureWidgetController__options-container">
            <p className="PictureWidgetController__label-text">Choose an option to add an image</p>
            <p className="PictureWidgetController__label-text-alternative">png, jpg</p>
            <div className="PictureWidgetController__cta-container">
              <BasicButton
                color="blue"
                size="small"
                content="Browse"
                onClick={openUploadModal}
                className="invert"
              />
              <p className="PictureWidgetController__label-text">or</p>
              <BasicButton
                content="Media Library"
                size="small"
                color="blue"
                onClick={openMediaLibrary}
                className="invert"
              />
            </div>
          </div>
        </div>
        <div className="PictureWidgetController__slider-container">
          <p className="PictureWidgetController__label-text-alternative">Image size:</p>
          <input
            type="range"
            min={1}
            max={100}
            value={activeElement.width && parseInt(width.split('%')[0], 10)}
            onChange={(e) => handleImageSize(e.target.value)}
          />
        </div>
      </div>

      <WidgetControllerFooter
        onSaveButtonClick={() => onSaveButtonClick()}
        onCancelButtonClick={() => onCancelButtonClick()}
      />
    </div>
  );
};

PictureWidgetController.propTypes = {
  openModalPortalA: PropTypes.func.isRequired,
  closeModalPortalA: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  openModalPortalA: bindActionCreators(openModalPortal, dispatch),
  closeModalPortalA: bindActionCreators(closeModalPortal, dispatch),
});

export default connect(null, mapDispatchToProps)(PictureWidgetController);
