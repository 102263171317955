import {
  createContactFilter,
  updateContactFilter,
  CLEAR_CONTACT_FILTER,
  SET_CONTACTS_PAGE_SIZE,
  UPDATE_CONTACT_FILTER_VALUES,
} from '../actions/contactActions';

const contactFilter = (
  state = { filters: [{ operator: 'contains', property: 'contact.surname', value: null }] }, action,
) => {
  switch (action.type) {
    case createContactFilter.SUCCESS: {
      return {
        ...action.payload,
      };
    }
    case updateContactFilter.SUCCESS: {
      const { filters } = action.payload;
      if (!filters.length) {
        return {
          ...action.payload,
          filters: [{ operator: 'contains', property: 'contact.surname', value: null }],
        };
      }
      return {
        ...action.payload,
      };
    }
    case CLEAR_CONTACT_FILTER: {
      return {
        pageSize: action.pageSize,
        filters: [{ operator: 'contains', property: 'contact.surname', value: null }],
      };
    }
    case SET_CONTACTS_PAGE_SIZE: {
      return { ...state, pageSize: action.payload };
    }
    case UPDATE_CONTACT_FILTER_VALUES: {
      const {
        id,
        conjunction,
        filters,
        orderBy,
      } = action;
      return {
        ...state,
        id,
        conjunction,
        filters,
        orderBy,
      };
    }

    default:
      return state;
  }
};

export default contactFilter;
