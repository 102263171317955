import PropTypes from 'prop-types';
import Pager from 'react-pager';

const TablePager = ({
  pageNumbers,
  total,
  page,
  onPageChange,
  pageSize,
}) => {
  let totalCount;
  if (pageNumbers) {
    totalCount = Math.ceil(total / pageNumbers);
  } else {
    totalCount = Math.ceil(total / pageSize);
  }
  return (
    <Pager
      total={totalCount}
      visiblePages={5}
      current={page - 1}
      titles={{
        first: <span className="pager pager--backward" />,
        prev: <span className="pager pager--previous" />,
        prevSet: '...',
        nextSet: '...',
        next: <span className="pager pager--next" />,
        last: <span className="pager pager--forward" />,
      }}
      onPageChanged={onPageChange}
    />
  );
};

TablePager.propTypes = {
  total: PropTypes.number,
  page: PropTypes.number,
  pageNumbers: PropTypes.number,
  onPageChange: PropTypes.func,
  pageSize: PropTypes.number,
};

export default TablePager;
