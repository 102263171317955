import { takeLatest, call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { authActions, genericActions, accountActions } from '../actions';
import { api } from '../services';
import { showSuccessMessage, showGlobalError, requestSaga } from './genericSagas';
import { clearPaginatorPromiseArray } from '../modules/redux-paginator';

const {
  verify,
  resetPassword,
  changePassword,
  login,
  forgotPassword,
  setCredentialsToLocalStorage,
  block,
  deleteUser,
  changeRole,
  MULTIPLE_COMPANIES_LOGIN,
  LOGOUT,
  VERIFY,
  BLOCK_USER,
  DELETE_USER,
  CHANGE_ROLE,
} = authActions;

const { GO_TO_PAGE, CLEAR_REDUX_STATE } = genericActions;
const {
  auth: {
    authentication,
    verification,
    forgotPasswordAuthentication,
    resetPasswordAuthentication,
    changePasswordAuthentication,
    userBlock,
    userDelete,
    roleChange,
  },
} = api;
const { FETCH_ACCOUNT_USER } = accountActions;

function* setUserCredentialsSaga(args) {
  const credentials = get(args, 'payload.credentials');
  yield call(setCredentialsToLocalStorage, credentials);
  yield put({ type: FETCH_ACCOUNT_USER.REQUEST });
}

function* loginSuccessSaga(args) {
  const multipleAccounts = get(args, 'payload.accounts', undefined);

  if (multipleAccounts) {
    yield put({ type: MULTIPLE_COMPANIES_LOGIN, payload: multipleAccounts });
  } else {
    yield call(setUserCredentialsSaga, args);
  }
}

function* loginSaga(args) {
  yield call(requestSaga, null, authentication, args, { ...login });
}

function* verifySaga(args) {
  yield call(requestSaga, verify, verification, args);
}

function* forgotPasswordSaga(args) {
  yield call(requestSaga, null, forgotPasswordAuthentication, args, { ...forgotPassword });
}

function* forgotPasswordSuccessSaga() {
  yield call(showSuccessMessage, 'Please check your email and follow further directions');
  yield put({ type: GO_TO_PAGE, route: '/login' });
}

function* resetPasswordSaga(args) {
  yield call(requestSaga, null, resetPasswordAuthentication, args, { ...resetPassword });
}

function* changePasswordSaga(args) {
  yield call(requestSaga, null, changePasswordAuthentication, args, { ...changePassword });
}

function* logoutSaga(args) {
  localStorage.clear();
  yield put({ type: GO_TO_PAGE, route: '/login' });
  yield put({ type: CLEAR_REDUX_STATE });
  yield call(clearPaginatorPromiseArray);

  if (args.sessionTimeout) {
    localStorage.setItem('sessionTimeout', true);
  }
}

function* blockUserSaga(args) {
  yield call(requestSaga, block, userBlock, args);
}

function* deleteUserSaga(args) {
  yield call(requestSaga, deleteUser, userDelete, args);
}

function* changeRoleSaga(args) {
  yield call(requestSaga, changeRole, roleChange, args);
}

export default function* watchLogin() {
  yield takeLatest(VERIFY.REQUEST, verifySaga);
  yield takeLatest(VERIFY.SUCCESS, showSuccessMessage, 'You have verified your email successfuly');
  yield takeLatest(VERIFY.FAILURE, showGlobalError);

  yield takeLatest(login.REQUEST, loginSaga);
  yield takeLatest(login.SUCCESS, loginSuccessSaga);

  yield takeLatest(forgotPassword.REQUEST, forgotPasswordSaga);
  yield takeLatest(forgotPassword.SUCCESS, forgotPasswordSuccessSaga);

  yield takeLatest(resetPassword.REQUEST, resetPasswordSaga);
  yield takeLatest(resetPassword.SUCCESS, showSuccessMessage, 'Password updated successfuly');

  yield takeLatest(changePassword.REQUEST, changePasswordSaga);
  yield takeLatest(changePassword.SUCCESS, showSuccessMessage, 'Password updated successfuly');

  yield takeLatest(LOGOUT, logoutSaga);

  yield takeLatest(BLOCK_USER.REQUEST, blockUserSaga);
  yield takeLatest(BLOCK_USER.FAILURE, showGlobalError);

  yield takeLatest(DELETE_USER.REQUEST, deleteUserSaga);
  yield takeLatest(DELETE_USER.FAILURE, showGlobalError);

  yield takeLatest(CHANGE_ROLE.REQUEST, changeRoleSaga);
  yield takeLatest(CHANGE_ROLE.FAILURE, showGlobalError);
}
