import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import ChartJsWrapper from './ChartJsWrapper';

const getData = (
  data,
  backgroundColor,
  borderColor,
) => ({
  text: '',
  color: '#36A2EB',
  labels: ['', ''],
  datasets: [{
    data,
    backgroundColor,
    borderWidth: 10,
    hoverBorderWidth: 10,
    borderColor,
    hoverBorderColor: '#fff',
  }],
  options: {
    responsive: true,
    legend: {
      display: false,
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  },
});

const options = {
  segmentShowStroke: true,
  segmentStrokeColor: '#fff',
  segmentStrokeWidth: 2,
  percentageInnerCutout: 50,
  animationSteps: 100,
  animationEasing: 'easeOutBounce',
  animateRotate: true,
  animateScale: false,
  responsive: true,
  maintainAspectRatio: true,
  showScale: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  hover: {
    mode: null,
  },
  cutout: 70,
  rotation: 270,
  circumference: 180,
};

const HalfDonut = ({
  data,
  backgroundColor,
  borderColor,
}) => (
  <ChartJsWrapper
    data={getData(data, backgroundColor, borderColor)}
    options={options}
    type="doughnut"
    component={Doughnut}
  />
);

HalfDonut.propTypes = {
  data: PropTypes.array,
  backgroundColor: PropTypes.array,
  borderColor: PropTypes.string,
};

export default HalfDonut;
