import { useState, useEffect } from 'react';
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from '../common';

export const useScreenWidth = () => {
  const [state, setState] = useState({
    isDesktop: true,
    isTablet: false,
    isMobile: false,
    windowWidth: 0,
  });

  const handleScreenSizeChange = () => {
    const { innerWidth } = window;
    const isDesktop = innerWidth > TABLET_MAX_WIDTH;
    const isTablet = innerWidth <= TABLET_MAX_WIDTH && innerWidth >= MOBILE_MAX_WIDTH;
    const isMobile = innerWidth < MOBILE_MAX_WIDTH;
    setState({
      isDesktop,
      isTablet,
      isMobile,
      windowWidth: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', () => handleScreenSizeChange());
    handleScreenSizeChange();

    return () => {
      window.removeEventListener('resize', () => handleScreenSizeChange());
    };
  }, []);

  return state;
};
