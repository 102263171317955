import { useState } from 'react';
import { PhoneInput } from 'react-international-phone';
// eslint-disable-next-line import/no-unresolved
import 'react-international-phone/style.css';
import { Label, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PhoneInputField = ({
  input: { onChange, value },
  label,
  meta: { touched, error },
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  return (
    <div className="ui field input">
      <div className={classNames('ui labeled input phone-input', {
        'error phone-input__error': touched && error,
        'phone-input__focused': isInputFocused,
      })}
      >
        {label
          && (
            <Label>
              {label}
            </Label>
          )}
        <PhoneInput
          defaultCountry="dk"
          value={value}
          onChange={onChange}
          inputProps={{
            onFocus: () => setIsInputFocused(true),
            onBlur: () => setIsInputFocused(false),
          }}
        />
        {
          touched && error && (
            <Popup
              trigger={<i className="material-icons input-error-icon">error</i>}
              position="top right"
              content={error}
            />
          )
        }
      </div>
    </div>
  );
};

PhoneInputField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    touched: PropTypes.bool,
  }),
};

export default PhoneInputField;
