import { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  reduxForm,
  getFormSyncErrors,
  getFormValues,
  Field,
} from 'redux-form';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { BasicButton } from '../../components/Button';
import { PasswordValidity } from '../../components/Register';
import { password, required, passwordsMatch } from '../../utils/validator';
import { getUrlProperty } from '../../utils/helper';
import { authActions } from '../../actions';
import { Input } from '../../components/Field';

const { resetPassword } = authActions;

const ResetPasswordForm = ({
  user,
  history,
  handleSubmit,
  formErrors,
  formValues,
  invert,
}) => {
  const [redirect, setRedirect] = useState(false);

  const onSubmit = (values, dispatch) => {
    let payload;
    const query = getUrlProperty('query');

    if (query.email && query.lost) {
      payload = {
        email: query.email,
        lost: query.lost,
        password: values.password,
      };
      resetPassword(payload, dispatch).then(() => {
        history.push('/login');
      });
    } else {
      payload = {
        email: user.email,
        password: values.password,
      };
      resetPassword(payload, dispatch);
    }
    setRedirect(true);
  };

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to="/" />;
    }

    return null;
  };

  return (
    <>
      { renderRedirect() }
      <Form
        className="nobackground-flex ris-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid className="no-margin">
          <Grid.Row>
            <Grid.Column className="centered" computer={8} tablet={8} mobile={12}>
              <Field
                className="app-form__field"
                label="New password"
                component={Input}
                name="password"
                validate={password}
                type="password"
                placeholder="Enter your new password"
                size="large"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Column className="centered" computer={8} tablet={8} mobile={12}>
            <Grid.Row className="no-spacing">
              <Field
                className="app-form__field"
                label="Repeat password"
                component={Input}
                name="passwordRepeat"
                validate={required}
                type="password"
                placeholder="Enter new password again"
                size="large"
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Row>
            <Grid.Column className="centered" computer={8} tablet={8} mobile={12}>
              <PasswordValidity
                password={formValues && formValues.password}
              />
            </Grid.Column>
          </Grid.Row>
          {invert === false ? (
            <Grid.Row centered>
              <Grid.Column
                className="account-settings__reset-password"
                computer={8}
                tablet={8}
                mobile={12}
                centered
              >
                <BasicButton
                  fluid
                  content="Reset password"
                  color="blue"
                  className="small-14"
                  disabled={!isEmpty(formErrors)}
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <div
              className="account-settings__reset-password"
            >
              <BasicButton
                content="Reset password"
                color="blue"
                size="small"
                className="invert no-margin"
                disabled={!isEmpty(formErrors)}
              />
            </div>
          )}
        </Grid>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors('resetPassword')(state),
  formValues: getFormValues('resetPassword')(state),
  user: state.user,
});

const formConfig = {
  form: 'resetPassword',
  validate: passwordsMatch,
};

ResetPasswordForm.propTypes = {
  invert: PropTypes.bool,
  handleSubmit: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  formErrors: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ])),
  formValues: PropTypes.shape({
    password: PropTypes.string,
  }),
  user: PropTypes.object,
};

export default withRouter(connect(mapStateToProps)(reduxForm(formConfig)(ResetPasswordForm)));
