import {
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { find, map } from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroller';
import { requestMessagesPage } from '../../../actions/messageActions';
import {
  getCurrentPageResults,
  getCurrentTotalResultsCount,
  getCurrentPageNumber,
  isCurrentPageFetching,
  clearPaginatorPromiseArray,
} from '../../../modules/redux-paginator';
import { mapMessagesToOptions } from '../../../utils/map';
import { DEFAULT_MESSAGES_PAGE_SIZE } from '../../../common';
import { Spinner } from '../../Spinner';
import { SUBJECT_TYPES } from '../../../common/automation';

const MessageSelect = ({
  subject,
  message,
  setMessage,
  multi,
  messages,
  messagesPaginator,
  requestMessagesPageA,
  disabled,
}) => {
  const [messageOptions, setMessageOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedOptionLoaded, setSelectedOptionLoaded] = useState(!message);
  const [hasMore, setHasMore] = useState(true);

  const queryChange = useRef(true);

  const query = useMemo(() => {
    switch (subject) {
      case SUBJECT_TYPES.OPEN:
        return '';
      case SUBJECT_TYPES.SMS:
        return '&distribution=sms&type=draft';
      case SUBJECT_TYPES.EMAIL:
        return '&distribution=email&type=draft';
      case SUBJECT_TYPES.SURVEY: {
        const filters = [{ contains: 'Form' }];
        return `&conjunction=and&filters=${JSON.stringify(filters)}`;
      }
      case SUBJECT_TYPES.QUESTION: {
        const filters = [{ contains: 'Choices' }, { contains: 'SingleChoice' }];
        return `&type=sent&conjunction=or&filters=${JSON.stringify(filters)}`;
      }
      default:
        return '';
    }
  }, [subject]);

  useEffect(() => {
    queryChange.current = true;
    clearPaginatorPromiseArray();
    setMessageOptions([]);
    requestMessagesPageA(1, query);
  }, [query]);

  const compareQuery = () => {
    if (messagesPaginator && messagesPaginator.currentPages
      && messagesPaginator.currentPages.messages) {
      if (query) {
        const queryParams = query.split('&');
        for (let i = 1; i < queryParams.length; i += 1) {
          const compare = messagesPaginator.currentPages.messages.includes(queryParams[i]);
          if (!compare) {
            return false;
          }
        }
        return true;
      }
      return !!messagesPaginator.currentPages.messages.match(/\/messages\?depth=minimal&count=([0-9]+)&page=([0-9]+)/);
    }
    return false;
  };

  useEffect(() => {
    const currentPageLoading = isCurrentPageFetching(messagesPaginator, 'messages');
    if (((queryChange.current && currentPageLoading) || !queryChange.current) && compareQuery()) {
      queryChange.current = false;
      const currentPageNumber = getCurrentPageNumber(messagesPaginator, 'messages');
      const totalCount = getCurrentTotalResultsCount(messagesPaginator, 'messages');
      setPage(currentPageNumber);
      setLoading(currentPageLoading);
      if (!currentPageLoading) {
        let optionsCount = currentPageNumber * DEFAULT_MESSAGES_PAGE_SIZE;
        if (totalCount < optionsCount) {
          optionsCount = totalCount;
          setHasMore(false);
        }
        if (messageOptions.length < optionsCount) {
          const newMessages = getCurrentPageResults(messages, messagesPaginator, 'messages');
          if (!selectedOptionLoaded) {
            if (find(newMessages, (m) => m.id === message)) {
              setSelectedOptionLoaded(true);
            } else {
              requestMessagesPageA(currentPageNumber + 1, query);
            }
          }
          setMessageOptions((options) => ([...options, ...mapMessagesToOptions(newMessages)]));
        }
      }
    }
  }, [messages, messagesPaginator, query]);

  const getDropDownText = () => {
    const selected = find(messages, (m) => m.id === message);
    return selected ? selected.name : '';
  };

  const text = getDropDownText();

  return (
    <span className={`subject-input-select${multi || ''}`}>
      <Dropdown
        className="subject-input-select__dropdown"
        placeholder="Select a message"
        text={text}
        scrolling
        disabled={disabled}
      >
        <Dropdown.Menu>
          <InfiniteScroll
            pageStart={1}
            loadMore={() => requestMessagesPageA(page + 1, query)}
            hasMore={!loading && hasMore}
            useWindow={false}
            initialLoad={false}
          >
            {map(messageOptions, (option) => (
              <Dropdown.Item
                text={option.text}
                value={option.value}
                onClick={(e, { value }) => setMessage(value)}
                selected={option.value === message}
                key={option.value}
              />
            ))}
          </InfiniteScroll>
          <Dropdown.Item
            className={!loading ? 'loading-option-invisible' : ''}
            disabled
            content={(
              <Spinner
                loaded={!loading}
                options={{ size: 'small' }}
                option
              />
            )}
          />
        </Dropdown.Menu>
      </Dropdown>
    </span>
  );
};

MessageSelect.propTypes = {
  subject: PropTypes.string,
  message: PropTypes.string,
  setMessage: PropTypes.func,
  multi: PropTypes.string,
  messages: PropTypes.object,
  messagesPaginator: PropTypes.object,
  requestMessagesPageA: PropTypes.func,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  messages: state.messages,
  messagesPaginator: state.messagesPaginator,
});

const mapDispatchToProps = (dispatch) => ({
  requestMessagesPageA: bindActionCreators(requestMessagesPage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageSelect);
