import { genericActions } from '../actions';

const { CLOSE_MODAL_PORTAL, OPEN_MODAL_PORTAL } = genericActions;

const modalPortal = (state = {}, action) => {
  switch (action.type) {
    case OPEN_MODAL_PORTAL: {
      return {
        ...action.properties,
        open: true,
      };
    }
    case CLOSE_MODAL_PORTAL: {
      return {
        open: false,
      };
    }
    default:
      return state;
  }
};

export default modalPortal;
