import { isValidPhoneNumber } from 'libphonenumber-js';
import { filter } from 'lodash';
import { DEFAULT_BUTTON_LINK } from '../common';

const setLengthIconName = (password, iconsNames) => {
  let iconsNamesReturn = { ...iconsNames };
  if (password.length >= 8) {
    iconsNamesReturn = {
      ...iconsNamesReturn,
      lengthIcon: 'check circle',
    };
  } else {
    iconsNamesReturn = {
      ...iconsNamesReturn,
      lengthIcon: 'remove circle',
    };
  }
  return iconsNamesReturn;
};

const setLowercaseIconName = (password, iconsNames) => {
  let iconsNamesReturn = { ...iconsNames };
  if (/[a-z]/.test(password)) {
    iconsNamesReturn = {
      ...iconsNamesReturn,
      lowercaseIcon: 'check circle',
    };
  } else {
    iconsNamesReturn = {
      ...iconsNamesReturn,
      lowercaseIcon: 'remove circle',
    };
  }
  return iconsNamesReturn;
};

const setUppercaseIconName = (password, iconsNames) => {
  let iconsNamesReturn = { ...iconsNames };
  if (/[A-Z]/.test(password)) {
    iconsNamesReturn = {
      ...iconsNamesReturn,
      uppercaseIcon: 'check circle',
    };
  } else {
    iconsNamesReturn = {
      ...iconsNamesReturn,
      uppercaseIcon: 'remove circle',
    };
  }
  return iconsNamesReturn;
};

export const setValidityIconsNames = (password, iconsNames) => {
  if (!password) {
    return {
      lengthIcon: 'radio',
      lowercaseIcon: 'radio',
      uppercaseIcon: 'radio',
    };
  }
  let iconsNamesReturn = setLengthIconName(password, iconsNames);
  iconsNamesReturn = setLowercaseIconName(password, iconsNamesReturn);
  iconsNamesReturn = setUppercaseIconName(password, iconsNamesReturn);
  return iconsNamesReturn;
};

export const required = (value) => {
  if (!value) {
    return 'This field is required';
  }
  return undefined;
};

export const linkValidate = (value) => {
  if (value && !value.startsWith(DEFAULT_BUTTON_LINK)) {
    return `Link must start with ${DEFAULT_BUTTON_LINK}`;
  }

  return undefined;
};

export const sameValues = (value, x, values) => {
  if (value === values.initialValues.name) {
    return 'The name must be changed';
  }
  return undefined;
};

export const uniqueCheck = (value, x, values) => {
  const messages = filter(values.initialValues.messages, { name: value });

  if (messages.length !== 0) {
    return 'The name must be unique';
  }
  return undefined;
};

export const email = (value) => {
  if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+(\.[A-Z]{2,})+$/i.test(value)) {
    return 'Invalid email address';
  }
  return undefined;
};

export const integer = (value) => {
  if (value && (isNaN(Number(value)) || value % 1 > 0)) {
    return 'Must be an integer';
  }

  return undefined;
};

export const number = (value) => {
  if (value && isNaN(Number(value))) {
    return 'Must be a number';
  }

  return undefined;
};

export const float = (value) => {
  if (value && (isNaN(Number(value)) || value % 1 === 0)) {
    return 'Must be a float type';
  }

  return undefined;
};

export const isArrayCountValid = (property, min, max) => {
  if (property) {
    if (property.length < min || property.length > max) {
      return false;
    }
  }

  return true;
};

const minValue = (min) => (value) => {
  if (value && value < min) {
    return `Must be at least ${min}`;
  }

  return undefined;
};

export const minValue0 = minValue(0);
export const minValue1 = minValue(1);

const maxValue = (max) => (value) => {
  if (value && value > max) {
    return `Must be equal or less than ${max}`;
  }

  return undefined;
};

export const maxValue24 = maxValue(24);
export const maxValue99 = maxValue(99);

const maxLength = (max) => (value) => {
  if (value && value.toString().length > max) {
    return `Length must be equal or less than ${max} characters`;
  }

  return undefined;
};

export const maxLength32 = maxLength(32);
export const maxLength50 = maxLength(50);
export const maxLength255 = maxLength(255);

export const password = (pass) => {
  if (!pass) {
    return 'This field is required';
  }
  const validityIconNames = setValidityIconsNames(pass);
  if (validityIconNames.lengthIcon === 'check circle'
      && validityIconNames.uppercaseIcon === 'check circle'
      && validityIconNames.lowercaseIcon === 'check circle') {
    return undefined;
  }
  return true;
};

export const passwordsMatch = (formValues) => {
  const errors = {};
  if (formValues && (formValues.password !== formValues.passwordRepeat)) {
    errors.passwordRepeat = 'Passwords must match';
  }
  return errors;
};

export const phone = (phoneNumber) => {
  if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
    return 'Invalid phone number';
  }
  return undefined;
};

export const domain = (value) => {
  if (value && !/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(value)) {
    return 'Invalid domain address';
  }
  return undefined;
};
